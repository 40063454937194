import { gql } from '@apollo/client';

export const setAssetStatus = gql`
	fragment setAssetStatus on SetAssetStatus {
		__typename
		message
		serial
		isActive
		status
	}
`;

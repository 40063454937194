import { useState } from 'react';
import { Formik } from 'formik';
import { TruckListForm as TruckListFormTemp } from 'components';
import { ISelectOptionList } from '../form-fields';
import { AssetList } from 'generate/types';
import { useTruckListForm } from 'hooks';

interface SubmitTruckFormProps {
	truckList: AssetList[];
	driverList?: ISelectOptionList | null;
	trailerList?: ISelectOptionList | null;
	onSubmit: (truckList: AssetList[]) => void;
	isLoading?: boolean;
	maxTruckLength?: number | null;
	withSearchFilter?: boolean;
	isSubmitting?: boolean;
}

export const TruckConfirmListForm = ({
	truckList = [],
	onSubmit,
	withSearchFilter,
	...props
}: SubmitTruckFormProps) => {
	const [filter, setFilter] = useState<string>();
	const { initialValues, validationSchema, handleSubmit, validateMinTrucks } = useTruckListForm({
    // @ts-ignore
		submittedTruckList: truckList,
    // @ts-ignore
		onSubmit,
	});

	return (
		<>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
				validate={validateMinTrucks}
			>
				{({ values, errors }) => {
					const filteredList = () => {
						if (!filter) return values;
						const filterValue = filter?.toLowerCase();

						return Object.fromEntries(
							Object.entries(values).filter((value) => value[0].toLowerCase().includes(filterValue))
						);
					};
					return (
						<TruckListFormTemp
							errors={errors}
							onChangeFilter={setFilter}
							truckListData={filteredList()}
							withSearchFilter={withSearchFilter}
							isConfirming
							{...props}
						/>
					);
				}}
			</Formik>
		</>
	);
};

import { makeStyles } from '@material-ui/core/styles';
import { theme } from 'utils';

export const useStyles = makeStyles({
	flexContainer: {
		display: 'flex',
		alignItems: 'center',
		boxSizing: 'border-box',
	},
	table: {
		// temporary right-to-left patch, waiting for
		// https://github.com/bvaughn/react-virtualized/issues/454
		'& .ReactVirtualized__Table__headerRow': {
			flip: false,
			paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined,
		},
	},
	tableRow: {
		cursor: 'pointer',
	},
	tableRowHover: {
		'&:hover': {
			backgroundColor: theme.palette.grey[200],
		},
	},
	tableCell: {
		flex: 1,
    // @ts-ignore
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '1rem',
	},
	tableHeader: {
		color: theme.palette.common.black,
		backgroundColor: theme.palette.grey[50],
	},
	noClick: {
		cursor: 'initial',
	},
});

import { makeStyles } from '@material-ui/core';
import { COLORS, theme } from 'utils';

export const useStyles = makeStyles({
	tableCell: {
		color: COLORS.black,
    // @ts-ignore
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '1rem',
	},
});

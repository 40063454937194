import React, { useMemo } from 'react';
import { makeStyles, Avatar } from '@material-ui/core';
import { COLORS } from 'utils';
import { useAuthContext } from 'containers';

const useStyles = makeStyles({
	avatar: {
		color: COLORS.primary,
		backgroundColor: 'transparent',
		border: `1px solid ${COLORS.gray}`,
	},
});

export const AdminUserAvatar = () => {
	const classes = useStyles();
	const auth = useAuthContext();

	const nameLetter = useMemo(() => {
		const email = auth?.user?.email;

		if (email)
			return email?.charAt(0).toUpperCase();

	}, [auth?.user?.email]);

	return <Avatar className={classes.avatar}>{nameLetter}</Avatar>;
};

import { IRoute } from 'models';
import { AppRouteNameList } from '../AppRouteNameList';

export const AdminRoute: IRoute = {
	name: AppRouteNameList.admin,
	nested: [
		{
			name: AppRouteNameList.transporters,
			nested: [
				{
					name: AppRouteNameList.activeTransporters,
				},
				{
					name: AppRouteNameList.newTransporters,
				},
				{
					name: AppRouteNameList.externalTransporters,
				},
				{
					name: AppRouteNameList.transporterDetails,
				},
			],
		},
		{
			name: AppRouteNameList.assets,
			nested: [
				{
					name: AppRouteNameList.trucks,
				},
				{
					name: AppRouteNameList.trailers,
				},
				{
					name: AppRouteNameList.drivers,
				},
			],
		},
	],
};

import { gql } from '@apollo/client';

export const cancelJob = gql`
	mutation cancelJob($id: Int!) {
		cancelJob(id: $id) {
			message
			status
		}
	}
`;

import { useTranslation } from 'react-i18next';
import {Box, IconButton} from '@material-ui/core';
import { CustomButton } from '../button';
import { AddAssetProps } from 'models';
import {Link} from "react-router-dom";
import {AppPathList} from "../../router";
import VerticalAlignTop from "@material-ui/icons/VerticalAlignTop";
import {useMixpanel} from "../../analytics";

interface DriverListTempProps extends AddAssetProps {}

export const DriverList = ({ onAddAsset }: DriverListTempProps) => {
	const { t } = useTranslation('buttons');
	const mixpanel = useMixpanel();

	return (
		<>
			<Box mt={2} textAlign="center">
				<Link
					to={AppPathList.addDriver}
					className="link flexGrow"
					onClick={() => {
						mixpanel.track('Add driver button clicked');
					}}>
					<CustomButton className="capitalizeText">
						{t('add_driver')}
					</CustomButton>
				</Link>
			</Box>
			<Box mt={2} textAlign="center" paddingBottom={2}>
				<IconButton size="small" color="primary" className="text-small" onClick={onAddAsset}>
					<Box mr={1}><VerticalAlignTop  className="subtitle"/></Box>
					{t('upload_details')}
				</IconButton>
			</Box>
			{/*<Box mt={1} mb={1} textAlign="center">*/}
			{/*	<CustomButton onClick={onAddAsset}>{t('add_driver')}</CustomButton>*/}
			{/*</Box>*/}
		</>
	);
};

import  { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DriverList, TrailerList, TruckList } from '../account-assets';
import { CustomTabs } from '../custom-tabs';
import { useStyles } from './MyAssetsTab.styles';
import { useReadUserInfo } from 'hooks';

export const MyAssetsTab = () => {
	const { t } = useTranslation('buttons');
	const classes = useStyles();
	const { isWA } = useReadUserInfo();

	const tabList = useMemo(() => {
		const tabListWA = [
			{
				label: t('trucks'),
				value: t('trucks'),
				Component: TruckList,
			},
			{
				label: t('drivers'),
				value: t('drivers'),
				Component: DriverList,
			},
		];

		if (isWA) {
			return tabListWA;
		}

		return [
			...tabListWA,
			{
				label: t('trailers'),
				value: t('trailers'),
				Component: TrailerList,
			},
		];
	}, [isWA, t]);

	return (
		<CustomTabs
			tabList={tabList}
			classes={{
				root: classes.tabs,
				indicator: classes.indicator,
			}}
			tabClasses={{
				root: classes.tab,
				selected: classes.tabSelected,
			}}
		/>
	);
};

import { useHistory } from 'react-router-dom';
import { AppPathList } from 'router';
import { useLogOutLazyQuery } from 'generate/typesAuth';
import { authContext, COMMON } from 'utils';
import { StatusCodes } from 'models/api/StatusCodes';
import { client } from 'graphqlapi';
import { useCallback } from 'react';
import { removeFromSessionStorage } from 'helpers';

export function useAuth() {
	const { push } = useHistory();

	const [logOutQuery] = useLogOutLazyQuery({
		context: authContext,
		onCompleted: ({ logout: res }) => {
			if (res?.status && res?.status === StatusCodes.NO_CONTENT) {
				logout();
			}
		},
	});

	const removeStoredData = useCallback(() => {
		removeFromSessionStorage(COMMON.countryCode);
	}, []);

	const logout = useCallback(() => {
		client.clearStore().then(() => {
			removeStoredData();
			push(AppPathList.login);
		});
	}, [push, removeStoredData]);

	return {
		logout,
		logOutQuery,
	};
}

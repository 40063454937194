import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import {COLORS} from 'utils/constants';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem',
        },
        paper: {
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(1.5),
            borderRadius: '10px',
            maxWidth: 400,

            '& .submitted-days': {
                color: COLORS.yellow,
                textAlign: 'start',
                fontSize: 11,
                fontWeight: 400,
                lineHeight: '16px'
            },
        },
        loadingDetails: {
            display: 'inline-block',
            whiteSpace: 'nowrap'
        },
        backdrop: {
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
        },
        commodity: {
            display: 'flex',
            width: '100%',
            marginBottom: '1rem',
            '& p': {
                color: '#2E466E'
            }
        },
        price: {
            display: 'flex',
            marginTop: 12,
            justifyContent: 'space-between',
        },
        priceValue: {
            color: COLORS.primary,
            fontSize: 16,
            whiteSpace: 'nowrap',
            fontWeight: 800
        },
        transporterRateType: {
            color: COLORS.primary,
            whiteSpace: 'nowrap',
            fontSize: 13,
            fontWeight: 400,
            lineHeight: '16px'
        },
        priceNewCard: {
            display: 'flex',
            marginTop: 12,
            justifyContent: 'flex-end',
        },
        confirmation: {
            textAlign: 'center',
            marginBottom: '0.5rem',
        },
        amount: {
            fontFamily: 'bold',
            fontSize: 20,
            marginRight: 5,
            color: '#263238',
        },
        truckDistanceType: {
            display: 'flex',
            alignItems: 'flex-end',
        },
        priceDetails: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
        },
        deadline: {
            color: '#999999',
            display: 'flex',
            justifyContent: 'center',
            whiteSpace: 'nowrap',
            fontSize: 10
        },
        deadlineMatchedCard: {
            color: '#999999',
            display: 'flex',
            justifyContent: 'center',
            padding: '0 1rem',
        },
        orderDisclaimer: {
            display: 'flex',
            flexDirection: 'column',
            paddingInlineStart: '2rem',
            marginBottom: '1rem',
            fontSize: 14,
            fontWeight: 800,

            '& li': {
                listStyleType: 'disc',
                color: COLORS.primary,

            },
        },
        submitted: {
            marginBottom: 2
        },
        btnBottom: {
            display: 'flex',
            justifyContent: 'flex-end',

            '& button:first-child': {
                marginRight: 10,
                minWidth: '50%'
            },
        },
    })
);

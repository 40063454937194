import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { ModalWrapper } from '../ModalWrapper';
import { useTranslation } from 'react-i18next';
import { useStyles } from './SendMessage.styles';
import { formatCamelToSnakeCase, mapToDriverMessageList, parseToDriverMessage } from 'helpers';
import { CustomButton } from 'components/button';
import { useDidUpdateEffect, useGetOrderDetails, useResultContext } from 'hooks';
import { useSendDriverTextMutation, Move } from 'generate/types';
import { MoveInfoList } from './MoveInfoList';
import { CustomInput } from '../../form-fields';
import { REGULARS } from 'utils';
import Typography from '@material-ui/core/Typography';
import {Box} from "@material-ui/core";

const driverMessageKeys: Array<keyof Move> = [
	'origin',
	'destination',
	'moveId',
	'cargoType',
	'etaLoadingPoint',
];

interface SendMessageProps {
	onClose: () => void;
}

export const SendMessage = ({ onClose }: SendMessageProps) => {
	const { t } = useTranslation('modals');
	const { t:tB } = useTranslation('buttons');
	const { t:tD } = useTranslation('driver');
	const classes = useStyles();

	const [phoneNumber, setPhoneNumber] = useState<string>('');
	const [phoneError, setPhoneError] = useState<string>();

	const { openResultCb, openErrorResult, isResultOpened } = useResultContext();

	const { order } = useGetOrderDetails();

  const driverMessageDetailsList = useMemo(() => {
    // @ts-ignore
    return order ? mapToDriverMessageList(order, driverMessageKeys) : {};
  }, [order]);

	useEffect(() => {
		if (order && typeof order?.phoneNumber === 'string' && order?.phoneNumber !== phoneNumber) {
			setPhoneNumber(order?.phoneNumber);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order]);

	const [textDriver, { error }] = useSendDriverTextMutation({
		onCompleted: openResultCb,
		onError: openErrorResult,
	});

	useDidUpdateEffect(() => {
		if (!isResultOpened && !error) {
			onClose();
		}
	}, [isResultOpened, error]);

	const sendMessage = useCallback(() => {
		if (order?.phoneNumber || phoneNumber) {
			textDriver({
				variables: {
					mobileNumber: order?.phoneNumber || `+${phoneNumber}`,
					text: parseToDriverMessage(
						Object.keys(driverMessageDetailsList).reduce(
							(res, k) => ({
								...res,
								[t(formatCamelToSnakeCase(k))]:
									driverMessageDetailsList[k as keyof typeof driverMessageDetailsList],
							}),
							{}
						)
					),
				},
			});
		} else {
			setPhoneError('Phone number is required');
		}
	}, [order, phoneNumber, textDriver, driverMessageDetailsList, t]);

	const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setPhoneError('');
		setPhoneNumber(e.target.value?.replace(REGULARS.nonDigits, ''));
	}, []);
	return (
		<ModalWrapper withCloseBtn={false} open onClose={onClose} className={classes.backdrop}>
			<>
				<Typography className={classes.title} variant="body1">
					{t('send_message')}
				</Typography>
				<MoveInfoList driverMessageDetails={driverMessageDetailsList} />
				<CustomInput
					type="tel"
					value={phoneNumber}
					onChange={handleChange}
					InputProps={{
						startAdornment: phoneNumber.startsWith('+') ? '' : '+',
					}}
					label={tD('driver_details')}
					error={!!phoneError}
					helperText={phoneError}
				/>
				<Box mt={4} mb={2}>
					<CustomButton
						className={classes.button}
						onClick={sendMessage}
						mixPanelEventName="Send driver message"
						trackMixpanel={false}
					>
						{tB('send_message')}
					</CustomButton>
				</Box>

				<CustomButton
					className={classes.button}
					onClick={onClose}
					mixPanelEventName="Cancel Send message to driver"
					trackMixpanel={false}
					variant={"outlined"}
				>
					{tB('cancel')}
				</CustomButton>
			</>
		</ModalWrapper>
	);
};

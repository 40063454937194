import { gql } from '@apollo/client';

export const uploadDocumentsDMS = gql`
	mutation uploadDocuments(
		$input: DocumentInput!
	) {
		uploadDocuments(
      input: $input
		) {
			success
		}
	}
`;

import React from 'react';
import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { FieldProps } from 'formik';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './CustomRadio.styles';

export interface RadioOption {
	label: string;
	value: string | number | boolean;
}

export interface RadioOptionList extends Array<RadioOption> {}

interface CustomRadioProps extends FieldProps {
	options: RadioOptionList;
	name: string;
	title?: string;
}

export function CustomRadio({
	field,
	form: { touched, errors },
	name,
	options,
	title,
	...props
}: CustomRadioProps) {
	const fieldName = name || field.name;
	const classes = useStyles();

	return (
		<React.Fragment>
			<Typography className={classes.title}>{title}</Typography>
			<RadioGroup {...field} {...props} name={fieldName}>
				{options.map(({ label, value }) => (
					<FormControlLabel key={label} value={value} control={<Radio />} label={label} />
				))}
			</RadioGroup>

			{touched[fieldName] && errors[fieldName] && (
				<span style={{ color: 'red' }}>{errors[fieldName]}</span>
			)}
		</React.Fragment>
	);
}

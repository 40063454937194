import { gql } from '@apollo/client';

export const getFuelRequests = gql`
	query getFuelRequests($moveId: Int, $transporterId: Int) {
		fuelRequests(woId: $moveId, transporterId: $transporterId) {
			edges {
				node {
					amountFuelled
					fuelRequestAmount
					fuelStation
					fuelStationId
					fuelType
					lroId
					moveId
					truckReg
					transporterId
					approvalState
					fuelreqtra
				}
			}
		}
	}
`;

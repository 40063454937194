import { gql } from '@apollo/client';

export const getBankDetails = gql`
	query getBankDetails ($countryCode: String) {
		banks(countryCode: $countryCode) {
            totalCount
			edges {
                node {
                    id
                    bankName
                    bankCode
                    country
                    active
                    uuid
                    clearingCode
                }
            }
		}
	}
`;

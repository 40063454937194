import { PropsWithChildren } from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import truckIcon from 'assets/svg/truck.svg';
import moveArrow from 'assets/svg/move_arrow.svg';
import addLocationIcon from 'assets/svg/addLocation.svg';
import mapLocation from 'assets/svg/mapLocation.svg';
import { useStyles } from './OrderCard.styles';
import { useTranslation } from 'react-i18next';
import { JobCategory, Move } from 'generate/types';
import { COMMON, DATES, formatCurrency } from 'utils';
import { Typography } from '@material-ui/core';
import { formatToDate } from 'helpers';
import { useLocation } from 'react-router-dom';
import { useMixpanel } from 'analytics';
import { CustomButton } from '../button';

interface CardMoveProps extends Partial<Move> {
	jobCategory: JobCategory | undefined;
	handleClick: (moveId: any) => void;
	handleAddNewLocation?: (moveId: any) => void;
	handleViewPreviousLocations?: (moveId: any) => void;
}

export function MoveCard(props: PropsWithChildren<CardMoveProps>) {
	const {
		commodity,
		destinationCity,
		originCity,
		deadlineToLift,
		currency,
		marketRate,
		transporterRateType,
		transportType,
		cargoType,
		lastKnownLocation,
		// weight,
		// truckType,
		moveId,
		jobCategory,
		handleClick,
		handleAddNewLocation,
		handleViewPreviousLocations,
	} = props;
	const { t } = useTranslation('job');
	const classes = useStyles();
	const { pathname } = useLocation();
	const mixpanel = useMixpanel();
	const jobType = pathname?.split('/')?.reverse()?.join(' ')?.trim() || 'My Job';
	return (
		<Paper className={classes.root} elevation={3}>
			<div className={classes.content}>
				<button
					className={classes.wrapperButton}
					onClick={() => {
						mixpanel.track(`Order clicked (${jobType} tab)`, {
							moveId,
							pathname,
						});
						handleClick(moveId);
					}}
					data-qa="qa-button"
					type="button"
				>
					<div>
						<div>
							<div className={classes.tripType}>
								<Typography className={classes.tripTypeSwatches}>{cargoType}</Typography>
								{transportType && (
									<Typography className={classes.tripTypeSwatches}>
										{transportType}&nbsp;Trip
									</Typography>
								)}
							</div>
							<div className={classes.commodity}>
								<Typography>{commodity}</Typography>
							</div>
							<div className={classes.tripRates}>
								<Typography component="p" className={classes.priceDetails}>
									{currency}&nbsp;{formatCurrency(marketRate)}&nbsp;
								</Typography>
								<Typography component="p" className={classes.truckDistanceType}>
									{transporterRateType}
								</Typography>
							</div>
							<div className={classes.tripCities}>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="center"
									className={classes.truckIconContainer}
								>
									<img className={classes.truckIcon} src={truckIcon} alt="truck-icon" />
								</Box>
								<Typography component="p" className={classes.departure}>
									{originCity}
								</Typography>
								<Box>
									<img className={classes.moveArrowImg} src={moveArrow} alt="move-arrow" />
								</Box>
								<Typography component="p" className={classes.destination}>
									{destinationCity}
								</Typography>
							</div>
						</div>
						{jobCategory === JobCategory.Confirmed && (
							<div>
								<div className={classes.deadline}>
									<Typography component="p" variant="body2" color="textSecondary">
										{t('last_loading_date')}:{' '}
										{formatToDate(deadlineToLift, DATES.loadDateFormatNoTime) ||
											COMMON.noDataProvided}
									</Typography>
								</div>
							</div>
						)}
					</div>
				</button>
				{jobCategory === JobCategory.OnJourney && (
					<div>
						{lastKnownLocation && <Box
							display="flex"
							flexDirection="row"
							alignItems="center"
							className={classes.lastKnownLocation}
							onClick={() => {
								if (handleViewPreviousLocations) {
									handleViewPreviousLocations(moveId);
								}
							}}
						>
							<img
								className={classes.lastKnownLocationIcon}
								src={mapLocation}
								alt="map-location-icon"
							/>
							<Box
								display="flex"
								flexDirection="column"
								className={classes.lastKnownLocation}
								marginLeft={1}
							>
								<Typography variant="caption" color="textSecondary">
									{lastKnownLocation}
								</Typography>
								<Typography variant="caption" className="textPrimary">
									{t('view_previous_locations')}
								</Typography>
							</Box>
						</Box>}
						<CustomButton
							variant="outlined"
							className={classes.addLocationButton}
							onClick={() => {
								if (handleAddNewLocation) {
									handleAddNewLocation(moveId);
								}
							}}
						>
							<img
								className={classes.addLocationIcon}
								src={addLocationIcon}
								alt="add-location-icon"
							/>
							{t('add_location')}
						</CustomButton>
					</div>
				)}
			</div>
		</Paper>
	);
}

import React, { useMemo, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useReadUserInfo } from 'hooks';
import * as Yup from 'yup';
import { getRequiredMessage } from 'helpers';

import { DropFileUploadField } from 'containers';
import {
  Source,
  useBulkImportDriversMutation,
  useBulkImportTrailersMutation,
  useBulkImportTrucksMutation,
} from 'generate/typesSupplyService';
import { useResultContext } from 'hooks';
import { supplyServiceClient } from 'graphqlapi/clients/supplyServiceClient';
import { useStyles } from './DropFileUpload.styles';
import { getSupplyDrivers, getSupplyTrailers, getSupplyTrucks } from 'graphqlapi';
import XLSX from 'xlsx';
import { MapUploadColumns } from 'containers/map-upload-columns';
import { ASSETS_BULK_IMPORT_COLUMNS } from 'containers/map-upload-columns/MatchColumnsModal.utils';

interface DropFileUploadProps {
  toggleModal: () => void;
  name: string;
  label: string;
  assetType: string;
}

export const DropFileUpload = ({
  toggleModal,
  name,
  label,
  assetType,
}: DropFileUploadProps) => {
  const { t: tA } = useTranslation('alert');
  const { t: tM } = useTranslation('modals');
	const { t: tE } = useTranslation('errors');
  const { toggleResult, openErrorResult } = useResultContext();
  const userInfo = useReadUserInfo();
  const [matchColumnsModalVisible, setMatchColumnsModalVisible] = useState(false);
  const [document, setDocument] = useState<Blob>();
  const [columnNames, setColumnNames] = useState([]);
  const [mappedColumns, setMappedColumns] = useState(new Map());
  const MANDATORY_FIELDS = ['truck_reg', 'trailer_reg', 'id_number'];

  //------------------------------ styles ----------------------------- //
  const classes = useStyles();

  const [bulkImportDriversMutation, { loading: isDriverLoading }] =
    useBulkImportDriversMutation({
      client: supplyServiceClient,
      onCompleted: () => {
        toggleModal();
        toggleResult({
          text: tM('asset_created'),
        });
      },
      onError: openErrorResult,
      refetchQueries: [
        {
          query: getSupplyDrivers,
          variables: { transporterId: userInfo?.transporter_id, first: 100 },
        },
      ],
      awaitRefetchQueries: true,
    });

  const [bulkImportTrailersMutation, { loading: isTrailerLoading }] =
    useBulkImportTrailersMutation({
      client: supplyServiceClient,
      onCompleted: () => {
        toggleModal();
        toggleResult({
          text: tM('asset_created'),
        });
      },
      onError: openErrorResult,
      refetchQueries: [
        {
          query: getSupplyTrailers,
          variables: { transporterId: userInfo?.transporter_id, first: 100 },
        },
      ],
      awaitRefetchQueries: true,
    });

  const [bulkImportTrucksMutation, { loading: isTruckLoading }] =
    useBulkImportTrucksMutation({
      client: supplyServiceClient,
      onCompleted: () => {
        toggleModal();
        toggleResult({
          text: tM('asset_created'),
        });
      },
      onError: openErrorResult,
      refetchQueries: [
        {
          query: getSupplyTrucks,
          variables: { transporterId: userInfo?.transporter_id, first: 100 },
        },
      ],
      awaitRefetchQueries: true,
    });

  const handleImport = () => {
    // swap key, value before posting to the backend
    const tempMappedColumns = Object.fromEntries(mappedColumns);    
    const swappedMappedColumns = Object.fromEntries(
      Object.entries(tempMappedColumns).map(([key, value]) => [value, key]),
    );

    try {
      if (assetType === 'trucks') {
        bulkImportTrucksMutation({
          variables: {
            file: document,
            transporterId: userInfo?.transporter_id!,
            country: userInfo?.country_iso_code!,
            source: Source.Lta,
            mappedColumns: swappedMappedColumns,
          },
        });
      }

      if (assetType === 'drivers') {
        bulkImportDriversMutation({
          variables: {
            file: document,
            transporterId: userInfo?.transporter_id!,
            country: userInfo?.country_iso_code!,
            source: Source.Lta,
            mappedColumns: swappedMappedColumns,
          },
        });
      }

      if (assetType === 'trailers') {
        bulkImportTrailersMutation({
          variables: {
            file: document,
            transporterId: userInfo?.transporter_id!,
            country: userInfo?.country_iso_code!,
            source: Source.Lta,
            mappedColumns: swappedMappedColumns,
          },
        });
      }
    } catch (error) {
      console.error({ error });
    }
  };

  const handleNext = () => {
    if (!document) {
      console.error({ error: 'Please select a file.' });
      return;
    }
    setColumnNames([]);
    const reader = new FileReader();
    reader.onload = e => {
      const data = e?.target?.result;
      const fileData = XLSX.read(data, { type: 'binary', sheetRows: 1 });
      const wsname = fileData.SheetNames[0];
      const ws = fileData.Sheets[wsname];
      const dataParse: any[] = XLSX.utils.sheet_to_json(ws, { header: 1 });
      setColumnNames(dataParse[0]);
      setMatchColumnsModalVisible(true);
    };
    reader.readAsBinaryString(document);
  };

	useEffect(() => {
		if (document && document['size'] > 0) {
			handleNext();
		}
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [document]);

  const handleCloseMatchColumnModal = async () => {
    setMatchColumnsModalVisible(false);
    toggleModal();
  };

  const isLoading = useMemo(
    () => isTruckLoading || isDriverLoading || isTrailerLoading,
    [isTruckLoading, isDriverLoading, isTrailerLoading]
  );

	const initialValues = useMemo(
		() => {
      const initialValuesMap = new Map(
        ASSETS_BULK_IMPORT_COLUMNS[assetType].map(col => {
          return [[col.value], ''];
        }),
      );
      return Object.fromEntries(initialValuesMap);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

	const validationSchema = useMemo(
		() => {
			const schema = new Map(
        ASSETS_BULK_IMPORT_COLUMNS[assetType].filter(function (col) {
          return MANDATORY_FIELDS.includes(col.value)
        }).map(col => {
          return [
            [col.value], Yup.string().required(
              getRequiredMessage(tE, col.label)
            ),
          ]
        })
      );
      return Yup.object().shape(Object.fromEntries(schema));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tE]
	);

  return (
    <Formik
      onSubmit={(values, actions) => {
        handleImport();
        actions.setSubmitting(false);
      }}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Form>
        <Box mb={2}>
          <Typography className={classes.dialogTitle}>
            {(assetType === 'trucks') && tA('upload_trucks')}
            {(assetType === 'drivers') && tA('upload_drivers')}
            {(assetType === 'trailers') && tA('upload_trailers')}
          </Typography>
        </Box>
        <DropFileUploadField
          name={name}
          label={label}
          onUpload={handleNext}
          accept='.xls, .xlsx, .csv'
          isUploading={isLoading}
          setColumnNames={setColumnNames}
          setDocument={setDocument}
          multiple={false}
        />
        <MapUploadColumns
          closeModal={handleCloseMatchColumnModal}
          columnNames={columnNames}
          isLoading={isLoading}
          mappedColumns={mappedColumns}
          matchColumnsModalVisible={matchColumnsModalVisible}
          matchingColumns={ASSETS_BULK_IMPORT_COLUMNS[assetType] || []}
          setColumnNames={setColumnNames}
          setMappedColumns={setMappedColumns}
          setDocument={setDocument}
        />
      </Form>
    </Formik>
  );
};

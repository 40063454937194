import React from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import Typography from '@material-ui/core/Typography';
import { useMixpanel } from 'analytics';

const useStyles = makeStyles({
	button: {
		border: 0,
		background: 'none',
		width: 'auto',
		padding: '10px 0',
		color: '#212121',
		display: 'flex',
		alignItems: 'center',
		'& svg': {
			fill: '#212121',
		},
	},
	icon: {
		width: 20,
		marginRight: 10,
	},
	disabled: {
		cursor: 'auto',
		pointerEvents: 'none',
	},
});

interface BackButtonProps {
	text?: string;
	hideBackArrow?: boolean;
}

export function BackButton({ text, hideBackArrow }: BackButtonProps) {
	const classes = useStyles();
	const history = useHistory();
	const mixpanel = useMixpanel();

	const handleClick = React.useCallback(() => {
		mixpanel.track(`User leaves ${text} page`);
		history.goBack();
	}, [history, mixpanel, text]);

	return (
		<button
			className={cn(classes.button, {
				[classes.disabled]: hideBackArrow,
			})}
			onClick={handleClick}
		>
			{!hideBackArrow && <ArrowBackIosIcon />}
			<Typography>{text}</Typography>
		</button>
	);
}

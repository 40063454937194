import React, { useCallback, useState } from 'react';
import { CustomCropper as CustomCropperTemp } from 'components';

interface CustomCropperProps {
	src?: string | null;
	onSubmit: (image: File) => void;
	isLoading: boolean;
}

export const CustomCropper = ({ src, onSubmit, isLoading }: CustomCropperProps) => {
	const [image, setImage] = useState<string>();
	const [cropper, setCropper] = useState<Cropper>();

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		let files: File[] = [];
		if (e.target.files) {
			files = Array.from(e.target.files);
		}

		const reader = new FileReader();
		reader.onload = () => {
			setImage(reader.result as string);
		};

		reader.readAsDataURL(files[0]);
	};

	const getCropData = useCallback(() => {
		if (cropper) {
			setImage(cropper.getCroppedCanvas().toDataURL());
		}
	}, [cropper]);

	const handleSubmit = useCallback(() => {
		if (cropper) {
			cropper.getCroppedCanvas().toBlob((blob) => {
				const formData = new FormData();

				// @ts-ignore
				formData.append('croppedImage', blob /*, 'example.png' */);
				onSubmit(formData.get('croppedImage') as File);
			});
			setImage(undefined);
		}
	}, [cropper, setImage, onSubmit]);

	const handleClose = useCallback(() => setImage(undefined), [setImage]);

	return (
		<CustomCropperTemp
			src={src || ''}
			image={image || ''}
			onCrop={getCropData}
			onSubmit={handleSubmit}
			onChange={onChange}
			setCropper={setCropper}
			onClose={handleClose}
			isLoading={isLoading}
		/>
	);
};

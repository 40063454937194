interface AssetColumns {
    label: string;
    value: string;
}

export const AssetTypes = {
    Driver: 'drivers',
    Trailer: 'trailers',
    Truck: 'trucks',
};

export const BULK_IMPORT_TRUCKS_COLUMNS: Array<AssetColumns> = [
    { label: 'Truck Registration', value: 'truck_reg' },
    { label: 'Truck Capacity', value: 'cargo_capacity' },
    { label: 'Truck Type', value: 'type' },
];

export const BULK_IMPORT_TRAILERS_COLUMNS: Array<AssetColumns> = [
    { label: 'Trailer Registration', value: 'trailer_reg' },
    { label: 'Trailer Type', value: 'trailer_type' },
];

export const BULK_IMPORT_DRIVERS_COLUMNS: Array<AssetColumns> = [
    { label: 'Driver ID', value: 'id_number' },
    { label: 'Driver Name', value: 'names' },
    { label: 'Driver Phone', value: 'phone' },
];

export const ASSETS_BULK_IMPORT_COLUMNS = {
    [AssetTypes.Truck]: BULK_IMPORT_TRUCKS_COLUMNS,
    [AssetTypes.Trailer]: BULK_IMPORT_TRAILERS_COLUMNS,
    [AssetTypes.Driver]: BULK_IMPORT_DRIVERS_COLUMNS,
};

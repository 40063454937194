import { IRoute, RoleTypes } from 'models';
import { AppRouteNameList } from '../AppRouteNameList';

const requiredRoles = [RoleTypes.admin];

export const AvailableJobsRoute: IRoute = {
	name: AppRouteNameList.available,
	requiredRoles,
	nested: [
		{
			name: AppRouteNameList.availableNew,
			requiredRoles,
			nested: [
				{
					name: AppRouteNameList.availableDetails,
					requiredRoles,
					nested: [
						{
							name: AppRouteNameList.submitTruck,
							requiredRoles,
						},
						{
							name: AppRouteNameList.suggestPrice,
							requiredRoles,
						},
					],
				},
			],
		},
		{
			name: AppRouteNameList.availableSubmitted,
			requiredRoles,
			nested: [
				{
					name: AppRouteNameList.availableSubmittedDetails,
					requiredRoles,
				},
			],
		},
		{
			name: AppRouteNameList.availableMatched,
			requiredRoles,
			nested: [
				{
					name: AppRouteNameList.availableMatchedDetails,
					requiredRoles,
				},
			],
		},
	],
};

import { Box, IconButton } from '@material-ui/core';
import React from 'react';
import { DeleteIcon, EditIcon } from 'components/icons';
import { useStyles } from './EditRecordButtons.styles';

interface EditRecordButtonsProps {
	onClickEdit?: () => void;
	onClickDelete?: () => void;
}

export function EditRecordButtons({ onClickEdit, onClickDelete }: EditRecordButtonsProps) {
	const classes = useStyles();
	return (
		<Box>
			<IconButton aria-label="Edit" className={classes.btn} onClick={onClickEdit}>
				<EditIcon />
			</IconButton>
			<IconButton aria-label="delete" className={classes.btn} onClick={onClickDelete}>
				<DeleteIcon />
			</IconButton>
		</Box>
	);
}

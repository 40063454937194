import {makeStyles} from '@material-ui/core';
import {COLORS} from 'utils';

export const useStyles = makeStyles({
    list: {
        display: 'flex',
        padding: 0,
        width: '100%',
        justifyContent: 'start',
    },
    listItem: {},
    dot: {
        width: 8,
        height: 8,
        border: 'none',
        backgroundColor: COLORS.secondary,
        margin: 4,
        padding: 0,
        borderRadius: '50%',
        outline: 'none',
    },
    dotActive: {
        backgroundColor: COLORS.black,
    },
    pointer: {
        cursor: 'pointer',
    },
    stepIndicator: {
        height: 32,
        width: 32,
        border: "0.881988px solid #FFC46C",
        borderRadius: 115,
        justifyContent: "center",
        display: "flex",
    },
    stepIndicatorActive: {
        background: "#FFC46C",
        color: "#FFFEFE"
    },
    stepsConnector: {
        width: 20,
    },
    stepIndicatorText:{
        color: "#FFC46C"
    },
    stepIndicatorTextActive:{
        color: "#FFFEFE"
    }
});

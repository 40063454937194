import React, { useEffect } from 'react';
import { useDidUpdateEffect, useLoaderContext, useResultContext } from 'hooks';
import { usePasswordResetLinkMutation } from 'generate/types';
import { ForgetPasswordForm } from 'containers';
import { AppPathList } from 'router';
import { useHistory } from 'react-router-dom';
import { authContext } from 'utils';
import { useTranslation } from 'react-i18next';

export const ForgetPassword = () => {
	const { t } = useTranslation('login');
	const { setIsLoading } = useLoaderContext();
	const { t: tM } = useTranslation('modals');

	const { toggleResult, openErrorResult, isResultOpened } = useResultContext();
	const { push } = useHistory();

	const [forgetPasword, { error, loading }] = usePasswordResetLinkMutation({
		context: authContext,
		onCompleted: ({ passwordResetLink }) => {
			passwordResetLink?.ok === true
				? toggleResult({
						title: t('forget_password_success_title'),
						text: t('forget_password_success_message'),
				  })
				: openErrorResult(error, {
						title: tM('oops'),
						text: passwordResetLink?.message || '',
						type: 'error',
				  });
		},
		onError: (error) => {
			openErrorResult(error, {
				title: tM('oops'),
				text: error?.message || '',
				type: 'error',
			});
		},
	});

	const handleSubmit = (variables: { email: string }) => {
		forgetPasword({ variables });
	};

	useDidUpdateEffect(() => {
		if (!isResultOpened && !error) {
			push(AppPathList.login);
		}
	}, [isResultOpened, push, error]);

	useEffect(() => {
		setIsLoading(loading);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	return <ForgetPasswordForm onSubmit={handleSubmit} loading={loading || false} />;
};

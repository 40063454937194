import { gql } from '@apollo/client';

export const completedJobDetails = gql`
	fragment completedJobDetails on Move {
	    ${/* loadedDate */ ''}
		moveId
		truckReg
		trailerNumber
		destination
	    ${/* etaProvided */ ''}
	    ${/* actualArrivalTime */ ''}
	    transporterRate
			transporterCurrency
		transporterRateType
	    
	    ${/* t1 */ ''}
	    ${/* interchange */ ''}
	    ${/* loriLoadingOrder */ ''}
	    ${/* pod */ ''}
	}
`;

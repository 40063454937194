import { useEffect, useMemo } from 'react';
import { useReadUserInfo } from './useReadUserInfo';
import {
	Truck,
	Trailer,
	Driver,
	useGetSupplyTrucksLazyQuery,
	useGetSupplyDriversLazyQuery,
	useGetSupplyTrailersLazyQuery,
  ActiveEnum,
  MatchEnum,
  LinkEnum,
} from 'generate/typesSupplyService';
import { getPaginatedDataNodeList } from '../helpers';
import { supplyServiceClient } from 'graphqlapi';

export function useGetAvailableAssets() {
	const { transporter: transname, transporter_id: transporterId } = useReadUserInfo();

  const [getSupplyTrucks, { data: truckList, loading: isTruckLoading }] =
		useGetSupplyTrucksLazyQuery({
			variables: {
				transporterId,
				first: 100,
        active: ActiveEnum.Active,
        status: MatchEnum.Unmatched
			},
			fetchPolicy: 'network-only',
			client: supplyServiceClient,
		});

    const [getSupplyTrailers, { data: trailerList, loading: isTrailerLoading }] =
		useGetSupplyTrailersLazyQuery({
			variables: {
				transporterId,
				first: 100,
        status: LinkEnum.Unlinked,
        active: ActiveEnum.Active,
			},
			fetchPolicy: 'network-only',
			client: supplyServiceClient,
		});

	const [getSupplyDrivers, { data: driverList, loading: isDriverLoading }] =
		useGetSupplyDriversLazyQuery({
			variables: {
				transporterId,
				first: 100,
        status: LinkEnum.Unlinked,
        active: ActiveEnum.Active,
			},
			fetchPolicy: 'network-only',
			client: supplyServiceClient,
		});

	const isAssetsLoading = useMemo(
		() => isTruckLoading || isTrailerLoading || isDriverLoading,
		[isTruckLoading, isTrailerLoading, isDriverLoading]
	);

	useEffect(() => {
		if (transname) {
			getSupplyTrucks();
			getSupplyTrailers();
			getSupplyDrivers();
		}
	}, [transname, getSupplyTrailers, getSupplyTrucks, getSupplyDrivers]);

	return {
		truckList: getPaginatedDataNodeList<Truck, any>(truckList?.trucks),
		trailerList: getPaginatedDataNodeList<Trailer, any>(trailerList?.trailers),
		driverList: getPaginatedDataNodeList<Driver, any>(driverList?.drivers),
		isAssetsLoading,
	};
}

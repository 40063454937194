import { Box } from '@material-ui/core';
import { useStyles } from './AssetListItem.styles';
import { Truck } from 'generate/typesSupplyService';
import { CustomButton } from 'components/button';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { RedButton } from 'components/button';

interface TruckListItemProps extends Truck {
  onActivateAsset: (value: Truck) => void;
}

export const TruckListItem = ({
  active,
  id,
  truckReg,
  transporterId,
  onActivateAsset,
}: TruckListItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation('buttons');

  return (
    <Box className={classes.wrapper}>
      <div className='capitalize'>
        <Typography>{truckReg}</Typography>
      </div>
      <div className='capitalize'>
        <Typography>{transporterId}</Typography>
      </div>
      <Box maxWidth='100px'>
        {active ? (
          <RedButton
            style={{ width: 100 }}
            onClick={() => onActivateAsset({ active, id, truckReg } as Truck)}
          >
						{t('suspend')}
          </RedButton>
        ) : (
          <CustomButton
            color='primary'
            onClick={() => onActivateAsset({ active, id, truckReg } as Truck)}
          >
						{t('activate')}
          </CustomButton>
        )}
      </Box>
    </Box>
  );
};

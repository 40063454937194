import { useMemo } from 'react';
import { TruckSubmitListForm } from '../truck-submit-list-form';
import { ConfirmAssetList } from 'generate/types';
import { useGetAvailableAssets } from 'hooks';
import { getOptionList, getOptionListValues, getTruckOptionList } from 'helpers';

export const TruckSubmitList = ({
	onSubmit,
	isLoading: isDataLoading,
	withSearchFilter,
	getAssetsHook = useGetAvailableAssets,
}: {
	onSubmit: (truckList: Partial<[ConfirmAssetList]>) => void;
	isLoading?: boolean;
	isSubmitting?: boolean;
	withSearchFilter?: boolean;
	getAssetsHook?: typeof useGetAvailableAssets;
}) => {
	const { driverList, trailerList, truckList, isAssetsLoading } = getAssetsHook();

	const isLoading = useMemo(
		() => isAssetsLoading || isDataLoading,
		[isAssetsLoading, isDataLoading]
	);

	const driverOptionList = useMemo(() => {
		return (
			driverList &&
			getOptionListValues(driverList, ['names', 'idNumber'], ['idNumber', 'names', 'phone'])
		);
	}, [driverList]);

	const trailerOptionList = useMemo(() => {
		return trailerList && getOptionList(trailerList, ['trailerReg', 'trailerType'], 'trailerReg');
	}, [trailerList]);

	const truckOptionList = useMemo(() => {
		return truckList && getTruckOptionList(truckList);
	}, [truckList]);
	return (
		<TruckSubmitListForm
			truckList={truckOptionList}
			trailerList={trailerOptionList}
			driverList={driverOptionList}
			onSubmit={onSubmit}
			isLoading={isLoading}
			withSearchFilter={withSearchFilter}
		/>
	);
};

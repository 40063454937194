import React from 'react';

export enum SubListTypes {
	transporters = 'transporters',
	assets = 'assets',
}

export type ISublistTypes = Record<SubListTypes, string>;

export interface INavItem {
	text: string;
	icon: React.ReactElement;
	to?: string;
	nested?: Array<INavItem>;
	sublistType?: string;
}

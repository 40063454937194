export function formatCurrency(params: any) {
	let currency: number;
	try {
		// @ts-ignore
		currency = Number(params)
			.toFixed(2)
			.replace(/\d(?=(\d{3})+\.)/g, '$&,');
	} catch (error) {
		currency = Number(params);
		console.error({ error });
	}
	return currency;
}

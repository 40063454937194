export enum COMMON {
	companyData = 'companyData',
	activeStep = 'activeStep',
	lastLocation = 'lastLocation',
	noDataProvided = '--',
	imperialAccountManager = 'dfe.operations@imperiallogistics.com',
	productionMode = 'production',
	typename = '__typename',
	supplyServiceStorageKey = 'supply-auth-token',
	countryCode = 'countryCode',
}

export const IS_PRODUCTION = process.env.NODE_ENV === COMMON.productionMode;

export function getHeight(height: number | undefined, rowCount: number) {
	if (height === undefined) {
		return 535;
	}
	const bodyPadding = 225;
	const rowHeight = 48;
	const rowsTotalHeight = rowCount * rowHeight + bodyPadding;
	const heightOnly = height - bodyPadding;
	return rowsTotalHeight < heightOnly && rowsTotalHeight !== 0 ? rowsTotalHeight : heightOnly;
}

export const supplyServiceStorageKey = 'supply-auth-token';

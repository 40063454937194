import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { DeleteOutlineRounded } from '@material-ui/icons';
import { IFuelCard } from 'models';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { DATES, formatCustomNumber } from 'utils';
import { useStyles } from './FuelCard.styles';
import { useCancelFuelRequestMutation } from '../../generate/types';
import { useResultContext } from '../../hooks';
import liaSupplyClient from '../../graphqlapi/clients/liaSupplyClient';
import { CancelFuelRequest } from '../modals/cancel-fuel-request';

export const FuelCard = ({
	fuelreqtra,
	amountFuelled,
	truckReg,
	moveId,
	// fsCity = '',
	// fsCountry = '',
	fuelStation = '',
	// fuelStationPrice,
	approvalState,
	fuelRequestAmount = 0,
	refetchFuelRequests,
}: IFuelCard) => {
	const { t } = useTranslation('fuel-order');
	const { t: tM } = useTranslation('modals');
	const classes = useStyles();
	const date = format(new Date(fuelreqtra), DATES.dateInputFormat);
	const [open, setOpen] = useState(false);
	const { openErrorResult, toggleResult } = useResultContext();

	const handleCloseDialog = () => {
		setOpen(false);
	};
	const handleOpenDialog = () => {
		setOpen(true);
	};
	const handleCancelFuelRequestAction = () => {
		handleOpenDialog();
	};
	const handleCancelFuelRequest = () => {
		cancelFuelRequest();
	};
	const [cancelFuelRequest, { loading: isCanceling }] = useCancelFuelRequestMutation({
		client: liaSupplyClient,
		variables: {
			moveId: moveId,
		},
		onCompleted: () => {
			handleCloseDialog();
			toggleResult({
				title: tM('success'),
				text: t('fuel_request_canceled'),
			});
			refetchFuelRequests();
		},
		onError: (error) => {
			handleCloseDialog();
			openErrorResult(error);
		},
	});
	// Uncomment when currency field is added to the fueling table in registry
	// // @ts-ignore
	// const getFuelCost = (amountFuelled, fuelStationPrice) => {
	// 	if (typeof amountFuelled === 'number' && typeof fuelStationPrice === 'number') {
	// 		const total = amountFuelled * fuelStationPrice;
	// 		return formatCurrency(total);
	// 	} else {
	// 		return undefined;
	// 	}
	// };

	return (
		<Paper className={classes.card} elevation={3}>
			<Box display="flex" flexDirection="column">
				<Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
					<Typography className={classes.title}>{t('fueling_point')}</Typography>
					{approvalState === 'not_approved' && (
						<>
							<IconButton
								className={classes.cancelButton}
								onClick={handleCancelFuelRequestAction}
							>
								<DeleteOutlineRounded />
							</IconButton>
							<CancelFuelRequest
								open={open}
								isCanceling={isCanceling}
								onClose={handleCloseDialog}
								onCancelFuelRequest={handleCancelFuelRequest}
							/>
						</>
					)}
				</Box>
				<Box display="flex" justifyContent="space-between">
					<Box display="flex" flexDirection="column">
						<Typography className={classes.fuelStation}>{fuelStation}</Typography>
					</Box>
				</Box>
				<Box display="flex" justifyContent="space-between">
					<Box display="flex" flexDirection="row">
						<Typography className={classes.label}>{t('reg_number')}:&nbsp;</Typography>
						<Typography className={classes.field}>{truckReg}</Typography>
					</Box>
					<Box display="flex" flexDirection="column">
						<Typography className={classes.fuelingDate}>{date}</Typography>
					</Box>
				</Box>
				<Box display="flex" justifyContent="space-between">
					<Box display="flex" flexDirection="row">
						<Typography className={classes.label}>{t('requested_amount')}:&nbsp;</Typography>
						<Typography className={classes.field}>
							{formatCustomNumber(fuelRequestAmount, 0)} Litres
						</Typography>
					</Box>
				</Box>
				<Box display={amountFuelled ? 'flex' : 'None'} justifyContent="space-between">
					<Box display="flex" flexDirection="row">
						<Typography className={classes.label}>{t('amount_fuelled')}:&nbsp;</Typography>
						<Typography className={classes.field}>
							{formatCustomNumber(amountFuelled, 0)} Litres
						</Typography>
					</Box>
				</Box>
			</Box>
		</Paper>
	);
};

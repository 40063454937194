import React from 'react';
import cn from 'classnames';
import { IIconProps } from './IIconProps';

export const NotificationIcon = ({ className, title = '', pointer }: IIconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="Notification"
			viewBox="0 0 32 32"
			className={cn(className, {
				pointer: pointer,
			})}
		>
			<title>{title}</title>
			<path d="M21.313 22.688v-8q0-2.563-1.438-4.281t-3.875-1.719-3.875 1.719-1.438 4.281v8h10.625zM24 21.313l2.688 2.688v1.313h-21.375v-1.313l2.688-2.688v-6.625q0-3.125 1.594-5.438t4.406-3v-0.938q0-0.813 0.563-1.406t1.438-0.594 1.438 0.594 0.563 1.406v0.938q2.813 0.688 4.406 3t1.594 5.438v6.625zM16 29.313q-1.063 0-1.875-0.781t-0.813-1.844h5.375q0 1.063-0.813 1.844t-1.875 0.781z" />
		</svg>
	);
};

import { useCallback, useMemo } from 'react';
import { ItemListWithInfiniteScroll } from '../item-list-with-infinite-scroll';
import { FuelCard } from 'components';
import { IFuelCard } from 'models';
import { FuelCardFragment, useGetFuelRequestsQuery } from 'generate/types';
import { getPaginatedDataNodeList } from 'helpers';
import { useReadUserInfo, useResultContext } from 'hooks';
import liaSupplyClient from '../../graphqlapi/clients/liaSupplyClient';

interface FuelRequestsTabProps {
	approvalState: String;
}

export const FuelRequestsTab = ({ approvalState }: FuelRequestsTabProps) => {
	const { transporter_id: transporterId } = useReadUserInfo();
	const { openErrorResult } = useResultContext();
	const handleLoadMore = useCallback(() => [], []);

	const { data: fuelData, loading: isLoadingFuelRequest, refetch: refetchFuelRequests } = useGetFuelRequestsQuery({
		variables: {
			transporterId,
		},
		client: liaSupplyClient,
		onError: openErrorResult,
	});

	const items: IFuelCard[] = useMemo(
		() =>
			getPaginatedDataNodeList<FuelCardFragment, any>(fuelData && fuelData?.fuelRequests)
				.filter((item) => {
					if (item.approvalState === approvalState) {
						return item;
					}
					return false;
				})
				.map(
					({
						fuelreqtra,
						amountFuelled,
						cityLevy,
						truckReg,
						fsCity,
						fsCountry,
						fuelStation,
						moveId,
						fuelStationPrice,
						fuelRequestAmount,
						 approvalState,
					}) => ({
						fuelreqtra: fuelreqtra as string,
						amountFuelled: amountFuelled as number,
						cityLevy: cityLevy as number,
						fuelStationPrice: fuelStationPrice as number,
						fuelRequestAmount: fuelRequestAmount as number,
						truckReg: truckReg as string,
						fsCity: fsCity as string,
						fsCountry: fsCountry as string,
						fuelStation: fuelStation as string,
						approvalState: approvalState as string,
						moveId: moveId,
						refetchFuelRequests: refetchFuelRequests,
					})
				),
		[approvalState, fuelData, refetchFuelRequests]
	);
	return (
		<ItemListWithInfiniteScroll
			items={items}
			ItemTemplate={FuelCard}
			loading={isLoadingFuelRequest}
			hasNextPage={false}
			onLoadMore={handleLoadMore}
		/>
	);
};

export enum UploadDocumentsNames {
	t1 = 't1',
	interchange = 'interchange',
	loriLoadingOrder = 'loriLoadingOrder',
	pod = 'pod',
	proofOfDelivery = 'proofOfDelivery',
	palletSlip = 'palletSlip',
	other = 'other',
}

export enum OrderStages {
	completed = 'completed',
  loading = 'loading',
  offloading = 'offloading',
  endJourney = 'end-journey',
  emptyReturn = 'empty-return'
}

export interface UploadDocumentsFields {
	[UploadDocumentsNames.interchange]: string;
	[UploadDocumentsNames.t1]: string;
	[UploadDocumentsNames.loriLoadingOrder]: string;
	[UploadDocumentsNames.pod]: string;
	[UploadDocumentsNames.proofOfDelivery]: string;
	[UploadDocumentsNames.palletSlip]: string;
	[UploadDocumentsNames.other]: string;
}

import React, { useMemo } from 'react';
import { ErrorMessage, Field, FieldProps, FastField } from 'formik';
import { CustomInput, CustomInputProps } from 'components';

interface CustomInputFieldProps extends CustomInputProps {
	fast?: boolean;
}

export const CustomInputField = ({ fast, name = '', ...props }: CustomInputFieldProps) => {
	const Wrapper = useMemo(() => (fast ? FastField : Field), [fast]);

	return (
		<Wrapper name={name}>
			{({ field, meta }: FieldProps) => {
				return (
					<CustomInput
						{...props}
						{...field}
						name={name}
						error={!!meta.error && meta.touched}
						helperText={<ErrorMessage name={name} />}
					/>
				);
			}}
		</Wrapper>
	);
};

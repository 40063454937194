import React from 'react';
import { IDocumentTableColumns } from 'models';
import { UploadedDocumentsFragment } from 'generate/typesAdmin';
import { formatCamelToSnakeCase, generateColumns, getDocumentName } from 'helpers';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { COMMON } from 'utils';
import { Visibility } from '@material-ui/icons';
import {DocumentModal} from "../../containers/document-modal";
import Button from "@material-ui/core/Button";

export function useDocumentTable(data?: UploadedDocumentsFragment | null) {
	const { t } = useTranslation('form-fields');

	const columns = generateColumns(IDocumentTableColumns);
	const rows = generateRow(data);
	function renderCell(colName: keyof typeof IDocumentTableColumns, cellItem: unknown) {
		if (!cellItem) return <Typography align="center">{COMMON.noDataProvided}</Typography>;

		const cellData = cellItem as string;

		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [open, setOpen] = React.useState(false);
		const handleViewDocumentBtnClicked = () => {
			setOpen(true);
		};

		switch (colName) {
			case 'documentName':
				return <Typography>{t(`upload_documents.${cellData}`)}</Typography>;
			case 'fileName':
				return <Typography>{cellData}</Typography>;
			case 'viewLink':
				return (
					<div>
						<Button onClick={handleViewDocumentBtnClicked}>
							<Box display="flex" flexDirection="column" alignItems="center">
								<Visibility />
								<Typography>View</Typography>
							</Box>
						</Button>
						<DocumentModal
							open={open}
							setOpen={setOpen}
							documentLink={cellData}
						/>
					</div>

				);
			// case 'downloadLink':
			// 	return <Link href={cellData} download={cellData}>Download</Link>;
			default:
				return <></>;
		}
	}

	function generateRow(data?: UploadedDocumentsFragment | null) {
		if (!data) {
			return [];
		}
		return Object.entries(data)
			.filter(([k, v]) => k !== COMMON.typename && v)
			.map(([docFieldName, link]) => ({
				documentName: formatCamelToSnakeCase(docFieldName),
				fileName: getDocumentName(link),
				viewLink: link,
				// downloadLink: link,
			}));
	}

	return {
		columns,
		rows,
		renderCell,
	};
}

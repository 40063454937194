import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { CustomTextField } from 'containers';
import { Form, Formik } from 'formik';
import { formatCamelToSnakeCase, getRequiredMessage } from 'helpers';
import { Link } from 'react-router-dom';

import { FormIdList, ForgetPasswordFomNames } from 'models';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { CustomButton } from 'components/button';
import { useStyles } from './ExternalForm.styles';
import { AppPathList } from 'router';

export interface ForgetFormProps {
	onSubmit: (values: { email: string }) => void;
	loading: boolean;
}

const fields = [ForgetPasswordFomNames.email];

export const ForgetPasswordForm = ({ onSubmit, loading }: ForgetFormProps) => {
	const { t } = useTranslation('form-fields');
	const { t: tE } = useTranslation('errors');
	const { t: tB } = useTranslation('buttons');
	const { t: tL } = useTranslation('login');

	const classes = useStyles();

	const validationSchema = Yup.object({
		...fields.reduce(
			(res, name) => ({
				...res,
				[name]: Yup.string().required(
					getRequiredMessage(tE, t(`forget_password.${formatCamelToSnakeCase(name)}`))
				),
			}),
			{}
		),
	});

	return (
		<main className={classes.main}>
			<div className={classes.form}>
				<Typography variant="h5" component="h5" align="left" className="textPrimary subtitle">
					{tL('forget_password')}
				</Typography>
				<Typography variant="body1" align="left" className="textSecondary text-medium">
					{tL('forget_password_instruction')}
				</Typography>
				<Formik
					initialValues={{ email: '' }}
					onSubmit={onSubmit}
					validationSchema={validationSchema}
					enableReinitialize
				>
					<Form id={FormIdList.forgetPassword}>
						<CustomTextField
							name={ForgetPasswordFomNames.email}
							label={t('enter_email')}
							placeholder=""
							type="email"
							autoComplete="email"
						/>
						<Box display="flex" marginY={2} justifyContent="center">
							<CustomButton type="submit" disabled={loading} className="capitalizeText">
								{tB('submit')}
							</CustomButton>
						</Box>
						<Box mt={2} ml="1rem" display="flex" justifyContent="center">
							<Typography style={{ marginRight: 6 }}>{tL('remember_password')}</Typography>
							<Link to={AppPathList.login} className="link">
								<Typography>{tL('signin')}</Typography>
							</Link>
						</Box>
					</Form>
				</Formik>
			</div>
		</main>
	);
};

import {
	CreateBankDetailsForm,
	CompanyInformationForm,
	UploadDocumentationForm,
	// CreateCompanyDetailsForm,
	// CreatePersonaInformationForm,
	// CreateInsuranceForm,
	// CreateContactForm,
} from 'containers';

export function mapSignUpFormSlides(isSouthAfrica: boolean) {
	return isSouthAfrica
		? [
				CompanyInformationForm,
				// CreatePersonaInformationForm,
				// CreateCompanyDetailsForm,
				// UploadDocumentationForm,
				// CreateContactForm,
				// CreateInsuranceForm,
				// CreateBankDetailsForm,
		  ]
		: [CompanyInformationForm, UploadDocumentationForm, CreateBankDetailsForm];
}

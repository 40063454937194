import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
	navWrapper: {
		backgroundColor: '#F9F9F9',
		borderRadius: 5,
		height: 40,
	},
	navLink: {
		alignItems: 'center',
		color: '#2C3032',
		display: 'flex',
		fontSize: 16,
		fontWeight: 300,
		height: 38,
		justifyContent: 'center',
		letterSpacing: 0.15,
		minWidth: 67,
		padding: '0 12px',
		position: 'relative',
		textAlign: 'center',
		textTransform: 'capitalize',
	},
	navLinkActive: {
		backgroundColor: '#FFFEFE',
		borderRadius: 5,
		boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.04)',
		color: '#7FC0B5',
		fontWeight: 500,
		fontSize: 16,
		height: 34,
		letterSpacing: 0.15,
		minWidth: 67,
	},
});

import useInfiniteScroll, { UseInfiniteScrollArgs } from 'react-infinite-scroll-hook';
import React from 'react';
import { ItemList, ItemListProps } from 'components';

interface InfiniteJobList<T>
	extends ItemListProps<T>,
		UseInfiniteScrollArgs,
		Record<string, unknown> {
	List?: React.FunctionComponent<T>;
}

export function ItemListWithInfiniteScroll<T>({
	loading,
	hasNextPage,
	onLoadMore,
	...props
}: InfiniteJobList<T>) {
	const infiniteRef = useInfiniteScroll<HTMLUListElement>({
		loading,
		hasNextPage,
		onLoadMore,
	});

	return <ItemList ref={infiniteRef} {...props} isLoading={loading} />;
}

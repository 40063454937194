import { DriverCard, DriverList as DriverListTemp } from 'components';
import { DriverBulkUpload } from 'containers';
import { useGetSupplyDriversQuery } from 'generate/typesSupplyService';
import { useModalStateControls, useReadUserInfo } from 'hooks';
import { AssetQueryContainer } from './AssetQueryContainer';
import { supplyServiceClient } from 'graphqlapi/clients/supplyServiceClient';

export const DriverList = () => {
	const { isOpened, toggleModal } = useModalStateControls();
	const userInfo = useReadUserInfo();

	return (
		<>
			<AssetQueryContainer
				keyName="id"
				dataKey="drivers"
				useQuery={useGetSupplyDriversQuery}
				recordKeyList={['names', 'id', 'phone', 'active']}
				ItemTemplate={DriverCard}
				options={{
					client: supplyServiceClient,
					variables: {
						transporterId: userInfo?.transporter_id,
            first: 100,
					},
				}}
			>
				<DriverListTemp onAddAsset={toggleModal} />
				<DriverBulkUpload isOpened={isOpened} toggleModal={toggleModal} />
			</AssetQueryContainer>
		</>
	);
};

import { useMemo } from 'react';
import { AddTruck as AddTruckTemp, Loader } from 'components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AddTruckFomNames } from 'models';
import {
  formatCamelToSnakeCase,
  getRequiredMessage,
  getOptionListFromPrimitives,
} from 'helpers';
import { useTranslation } from 'react-i18next';
import {
  CreateTruckInput,
  TruckTypeConnection,
  TruckTypes,
  useGetSupplyTruckTypesQuery,
} from 'generate/typesSupplyService';
import { supplyServiceClient } from 'graphqlapi';
import { getPaginatedDataNodeList } from 'helpers';

interface AddTruckProps {
  onSubmit: (data: CreateTruckInput) => void;
  isSubmitting?: boolean;
}

export function AddTruck({ onSubmit, isSubmitting }: AddTruckProps) {
  const { t } = useTranslation('form-fields');
  const { t: tE } = useTranslation('errors');

  const { data, loading } = useGetSupplyTruckTypesQuery({
    client: supplyServiceClient,
  });

  const validationSchema = useMemo(
    () =>
      Yup.object({
        [AddTruckFomNames.truckReg]: Yup.string().required(
          getRequiredMessage(
            tE,
            t(`add_asset.${formatCamelToSnakeCase(AddTruckFomNames.truckReg)}`)
          )
        ),
      }),
    [tE, t]
  );

  const typeList = useMemo(() => {
    if (data?.truckTypes) {
      const nodeList = getPaginatedDataNodeList<
        TruckTypes,
        TruckTypeConnection
        // @ts-ignore
      >(data?.truckTypes);
      return getOptionListFromPrimitives(nodeList);
    }
    return [];
  }, [data]);

  return (
    <Loader isLoading={loading || isSubmitting} overflow={isSubmitting}>
      <Formik
        initialValues={{}}
        onSubmit={(v) => onSubmit(v as CreateTruckInput)}
        validationSchema={validationSchema}
      >
        <AddTruckTemp typeList={typeList} />
      </Formik>
    </Loader>
  );
}

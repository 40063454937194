import React, { useCallback } from 'react';
import { ContactForm } from '../ContactForm';
import {
	useUpdateContactDetailsMutation,
	useGetContactDetailsQuery,
	UpdateContactDetailsMutationVariables,
} from 'generate/types';
import { Loader } from 'components';
import { getContactDetails } from 'graphqlapi';
import { useResultContext } from 'hooks';

export const UpdateContactForm = () => {
	const { data, loading } = useGetContactDetailsQuery();
	const { openResultCb } = useResultContext();

	const [updateContact] = useUpdateContactDetailsMutation({
		onCompleted: openResultCb,
		refetchQueries: [{ query: getContactDetails }],
	});

	const onSubmit = useCallback(
		(variables: UpdateContactDetailsMutationVariables) => {
			updateContact({ variables });
		},
		[updateContact]
	);

	return (
		<Loader isLoading={loading}>
			<ContactForm onSubmit={onSubmit} data={data?.contact} />
		</Loader>
	);
};

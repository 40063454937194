import React, { useContext, useEffect, useMemo } from 'react';
import { FieldArray, Form, FormikErrors } from 'formik';
import { ISelectOptionList } from 'containers';
import { FormIdList, SubmitTruckFormData, SubmitTruckFormNames } from 'models';
import { TruckCard } from 'components/truck-card';
import { CustomSearch } from 'components/form-fields';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { Loader } from 'components/loader';
import { getPickedAssets } from 'helpers';
import { useStyles } from '../sign-up-form/SignUpForm.styles';
import { ConfirmTruckListContext } from 'context';
import Typography from '@material-ui/core/Typography';
import { useReadUserInfo } from '../../../hooks';

interface SubmitTruckFormProps {
	errors?: FormikErrors<SubmitTruckFormData>;
	onChangeFilter: (v?: string) => void;
	truckListData: SubmitTruckFormData;
	isLoading?: boolean;
	driverList?: ISelectOptionList | null;
	trailerList?: ISelectOptionList | null;
	withSearchFilter?: boolean;
	isConfirming?: boolean;
	isSubmitting?: boolean;
}

export const TruckListForm = ({
	isConfirming,
	errors,
	onChangeFilter,
	truckListData,
	isLoading,
	driverList,
	trailerList,
	withSearchFilter = true,
	isSubmitting,
}: SubmitTruckFormProps) => {
	const { t } = useTranslation('form-fields');
	const classes = useStyles();
	const { validateTruckList } = useContext(ConfirmTruckListContext);
	const { isWA } = useReadUserInfo();

  useEffect(() => {
		validateTruckList(truckListData);
	}, [truckListData, validateTruckList]);

	const { pickedDrivers, pickedTrailers, trucksAssets } = useMemo(
		() => getPickedAssets(truckListData),
		[truckListData]
	);

	const isDriversDisabled = useMemo(
		() => !!pickedDrivers.length && pickedDrivers.length === driverList?.length,
		[pickedDrivers, driverList]
	);

	const isTrailersDisabled = useMemo(
		() => !!pickedTrailers.length && pickedTrailers.length === trailerList?.length,
		[pickedTrailers, trailerList]
	);

	return (
		<>
			{withSearchFilter && (
				<CustomSearch placeholder={t('submit_track.search')} onChangeValue={onChangeFilter} />
			)}
			<Form id={FormIdList.truckList} className={classes.overflow}>
				<Loader isLoading={isLoading || isSubmitting} overflow={isSubmitting}>
					<Box overflow="auto">
						{errors?.truckListData && (
							<Box display="flex" alignItems="center" mt={1} flexDirection="column">
								<Typography className="error-text">{errors?.truckListData}</Typography>
							</Box>
						)}
						{truckListData ? (
							<FieldArray
								name={SubmitTruckFormNames.truckListData}
								render={() =>
									Object.keys(truckListData).map((truckReg) => (
										<TruckCard
											truckReg={truckReg}
											key={truckReg}
											isChecked={truckListData[truckReg].checked}
											isAssetsEditable={!truckListData['createdOnLia']}
											driverOptionList={driverList}
											trailerOptionList={trailerList}
											pickedDrivers={pickedDrivers}
											pickedTrailers={pickedTrailers}
											isDriversDisabled={isDriversDisabled}
											isTrailersDisabled={isTrailersDisabled}
											truckAssets={trucksAssets[truckReg]}
											isConfirming={isConfirming}
											hideTrailers={isWA}
										/>
									))
								}
							/>
						) : (
							<Typography>No trucks available...</Typography>
						)}
					</Box>
				</Loader>
			</Form>
		</>
	);
};

import React, { useCallback, useEffect, useMemo } from 'react';
import { state } from 'state';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import {
	Loader,
	UploadDocumentationFormEast,
	UploadDocumentationFormSouth,
	UploadDocumentationFormWest,
} from 'components';
import { UploadDocumentsMutationVariables, useUploadDocumentsMutation } from 'generate/typesAuth';
import { Document, useGetUploadedDocumentsLazyQuery } from 'generate/types';
import { authContext, DATES } from 'utils';
import { FormStepProps, RegionType, UploadDocumentationNames } from 'models';
import { formatToDate, getDocumentName } from 'helpers';
import { useLoaderContext, useReadUserInfo, useResultContext } from 'hooks';
import { useMixpanel } from 'analytics';

interface UploadDocumentationFormProps extends FormStepProps {}

export const UploadDocumentationForm = ({
	afterSubmit,
	...props
}: UploadDocumentationFormProps) => {
	const { openResultCb, openErrorResult } = useResultContext();
	const { setIsLoading } = useLoaderContext();
	const mixpanel = useMixpanel();
	const { t: tM } = useTranslation('modals');

	// Data for registered user on Account management page
	const { region: userRegion, company_id } = useReadUserInfo();
	// Data for new user on Sign Up flow
	const { region: companyRegion, companyId } = state;

	const [getUploadedDocuments, { data, loading, error }] = useGetUploadedDocumentsLazyQuery();

	useEffect(() => {
		if (userRegion) {
			getUploadedDocuments();
		}
	}, [userRegion, getUploadedDocuments]);

	const [uploadDocuments, { loading: isUploading }] = useUploadDocumentsMutation({
		context: authContext,
		onCompleted: () => {
			mixpanel.track('Upload document success');
			if (afterSubmit) {
				afterSubmit();
			} else {
				openResultCb();
			}
		},
		onError: (error) => {
			mixpanel.track('Upload document failure', {
				error: error?.message || 'No error message',
			});
			openErrorResult(error, {
				title: tM('oops'),
				text: error?.message || '',
				type: 'error',
			});
		},
	});

	useEffect(() => {
		setIsLoading(isUploading);
	}, [setIsLoading, isUploading]);

	const handleSubmit = useCallback(
		(data: unknown) => {
			const {
				carriersLiabilityExpiry,
				gitCoverExpiry,
				vatRegistrationNumber,
				isVatRegistered,
				hasQualityManagementCert,
			} = data as UploadDocumentsMutationVariables;

			uploadDocuments({
				variables: {
					companyId: company_id || companyId,
					carriersLiabilityExpiry: formatToDate(carriersLiabilityExpiry, DATES.BEDate),
					gitCoverExpiry: formatToDate(gitCoverExpiry, DATES.BEDate),
					vatRegistrationNumber,
					isVatRegistered,
					hasQualityManagementCert,
				},
			});
		},
		[uploadDocuments, company_id, companyId]
	);

	const UploadForm = useMemo(() => {
		const region = userRegion || companyRegion;
		switch (region) {
			case RegionType.EA:
				return UploadDocumentationFormEast;
			case RegionType.WA:
				return UploadDocumentationFormWest;
			case RegionType.SA:
				return UploadDocumentationFormSouth;
			default:
				return () => <></>;
		}
	}, [userRegion, companyRegion]);

	const formattedData: Partial<Record<UploadDocumentationNames, string | null | undefined>> =
		useMemo(() => {
			if (!data?.document) return {};
			return {
				...Object.keys(data.document).reduce((r, k) => {
					return {
						...r,
						[k]: getDocumentName((data.document as Document)[k as keyof typeof data.document]),
					};
				}, {}),
				[UploadDocumentationNames.hasQualityManagementCert]: (data.document.hasQualityManagementCert
					? 'true'
					: 'false') as string,
				[UploadDocumentationNames.isVatRegistered]: (!!data.document.isVatRegistered
					? 'true'
					: 'false') as string,
			};
		}, [data]);

	if (error) {
		return <Typography>Ooops, smth went wrong...</Typography>;
	}

	return (
		<Loader isLoading={loading}>
			<UploadForm {...props} onSubmit={handleSubmit} data={formattedData} />
		</Loader>
	);
};

import { useStyles } from './TruckCard.styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { Trailer } from 'generate/typesSupplyService';
import {Box} from "@material-ui/core";
import React from "react";

export const TrailerCard = ({ active, trailerReg }: Trailer) => {
	const { t } = useTranslation('form-fields');

	const classes = useStyles();
	const status = active === true || active === null;
	return (
		<ul className={classes.card}>
			<li className={classes.item}>
				<span>
					<Typography className={classes.title} variant="caption" component="p">
						{t(`request_fuel.truckReg`)}
					</Typography>
					<Typography className={classes.value} variant="body1" component="p">
						{trailerReg}
					</Typography>
				</span>
				<Box  className={classes.status} display="flex" flexDirection="column" alignItems="center">
					<Typography className={classes.statusText} variant="body1" component="p">
						{status ? 'Active' : 'Not Active'}
					</Typography>
					<span
						className={`${classes.statusIndicator} ${status ? classes.active : classes.inactive}`}
					></span>
				</Box>
			</li>
		</ul>
	);
};

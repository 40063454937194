import React, { useEffect, useRef } from 'react';
import Cropper from 'react-cropper';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';
import { ModalWrapper } from 'components/modals';
import { UserAvatar } from 'components/user-avatar';
import { useStyles } from './CustomCropper.styles';
import cn from 'classnames';
import { AddPhotoIcon } from 'components/icons';
import { CustomButton } from '../button';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

interface CustomCropperProps {
	src?: string;
	image?: string;
	onCrop: () => void;
	onSubmit: () => void;
	onClose: () => void;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	setCropper: (c: Cropper) => void;
	isLoading: boolean;
}

export const CustomCropper = ({
	src,
	image,
	setCropper,
	onCrop,
	onChange,
	onSubmit,
	onClose,
	isLoading,
}: CustomCropperProps) => {
	const { t } = useTranslation('buttons');
	const classes = useStyles();
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (!image && inputRef.current) {
			inputRef.current.value = '';
		}
	}, [image]);

	return (
		<>
			<label className={cn(classes.label, classes.withShadow)}>
				<input
					id="uploadAvatar"
					ref={inputRef}
					type="file"
					accept="image/*"
					hidden
					onChange={onChange}
					data-qa="qa-avatar"
				/>
				<UserAvatar
					src={src || image}
					className={classes.img}
					onClick={() => document.getElementById('uploadAvatar')?.click()}
					isLoading={isLoading}
					data-qa="qa-user-avatar"
				/>
				<AddPhotoIcon className={classes.addIcon} />
			</label>
			<ModalWrapper open={!!image} onClose={onClose} withCloseBtn={false}>
				<Box display="flex" flexDirection="column" overflow="hidden">
					<Cropper
						className={classes.wrapper}
						initialAspectRatio={1}
						aspectRatio={1}
						src={image}
						viewMode={1}
						background={false}
						responsive={true}
						autoCropArea={1}
						checkOrientation={false}
						onInitialized={(instance) => setCropper(instance)}
					/>
					<Box mt={2} display="flex" justifyContent="flex-end">
						<CustomButton onClick={onClose} variant="outlined" className={classes.btn} data-qa="qa-cancel">
							{t('cancel')}
						</CustomButton>
						<CustomButton onClick={onCrop} variant="outlined" className={classes.btn} data-qa="qa-crop">
							{t('crop')}
						</CustomButton>
						<CustomButton onClick={onSubmit} className={classes.btn} data-qa="qa-submit">
							{t('submit')}
						</CustomButton>
					</Box>
				</Box>
			</ModalWrapper>
		</>
	);
};

import { IPickedAssets, SubmitTruckFormData } from 'models';

export const getPickedAssets = (availableTruckList: SubmitTruckFormData) =>
	Object.values(availableTruckList).reduce<IPickedAssets>(
    // @ts-ignore
		(res, { trailerReg, driverData, truckReg }) => {
			/* eslint-disable */
			if (driverData) {
				res.pickedDrivers?.push(driverData);
			}

			if (trailerReg) {
				res.pickedTrailers?.push(trailerReg);
			}
      // @ts-ignore
			res.trucksAssets[truckReg] = { driverData, trailerReg };

			return res;
		},
		{ pickedDrivers: [], pickedTrailers: [], trucksAssets: {} }
	);

import cn from 'classnames';
import { useStyles } from './MyJobDetails.styles';
import { useTranslation } from 'react-i18next';
import { DetailsList } from '../details-list';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { CustomButton } from '../button';
import { SendMessage } from 'components/modals';
import { ConfirmedJobDetailsFragment } from 'generate/types';
import { formatToDate } from '../../helpers';
import { DATES } from '../../utils';
import { DestinationBlock } from '../destination-block';

export interface MyJobDetailsConfirmedProps extends ConfirmedJobDetailsFragment {
	title?: string;
	detailsList: Record<string, unknown>;
	isOpened: boolean;
	toggleModal: () => void;
}

export const MyJobDetailsConfirmed = ({
  title,
  origin,
  destination,
  cargoType,
  readyToLoad,
  detailsList,
  isOpened,
  toggleModal,
}: MyJobDetailsConfirmedProps) => {
	const classes = useStyles();
	const { t } = useTranslation('myjobs');

	return (
		<div className="overflow">
			{title && <h4 className={cn('title')}>{title}</h4>}
			<Box display="flex" mt={3} mb={1}>
				<Typography className={cn(classes.label, classes.marginRight)}>
					{cargoType}
				</Typography>
				<Typography className={classes.label}>
					{t('loading_date')}: {formatToDate(readyToLoad, DATES.loadDateFormatNoTime)}
				</Typography>
			</Box>
			<DestinationBlock from={origin} to={destination} showOrigin />
			<Box display="flex" mt={2} mb={1.5}>
			<DetailsList {...detailsList} />
			</Box>
			<Box display="flex" mt={8} justifyContent="center" className={classes.buttonWrapper}>
				<CustomButton
					data-qa="qa-send-to-driver"
					onClick={toggleModal}
					className={classes.customButton}
				>
					{t('send_to_driver')}
				</CustomButton>
			</Box>
			{isOpened && <SendMessage onClose={toggleModal} />}
		</div>
	);
};

import { gql } from '@apollo/client';

export const bulkUploadDrivers = gql`
	mutation bulkUploadDrivers($file: Upload!, $transporterId: Int!, $country: String!, $source: Source!) {
		bulkUploadDrivers(file: $file, transporterId: $transporterId, country: $country, source:  $source) {
			status
			message
		}
	}
`;

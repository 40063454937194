import React from 'react';
import { ModalWrapper } from '../ModalWrapper';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './CancelFuelRequest.styles';
import Box from '@material-ui/core/Box';
import closeIconSrc from '../../../assets/svg/close.svg';
import { CustomButton } from '../../button';
import cn from 'classnames';
import CircularProgress from "@material-ui/core/CircularProgress";

interface CancelFuelRequestProps {
	open: boolean;
	onCancelFuelRequest: () => void;
	onClose: () => void;
	isCanceling: boolean;
}

export const CancelFuelRequest = ({
	open,
	onCancelFuelRequest,
	onClose,
	isCanceling,
}: CancelFuelRequestProps) => {
	const { t } = useTranslation('fuel-order');
	const classes = useStyles();

	return (
		<ModalWrapper open={open} onClose={onClose} withCloseBtn={false}>
			<>
				{isCanceling ? (
					<Box textAlign="center" marginX="auto" marginBottom={2}>
						<CircularProgress />
					</Box>
				) : (
					<Box textAlign="center" marginX="auto" marginBottom={2}>
						<img src={closeIconSrc} alt="close" />
					</Box>
				)}
				<Typography className={classes.cancelFuelRequest}>
					{t('cancel_fuel_request_request_message')}
				</Typography>
				<Box display="flex" flexDirection="column" marginY={3}>
					<CustomButton
						className={cn(classes.btn, classes.modalDismissButton)}
						onClick={onClose}
						data-qa="qa-close"
						disabled={isCanceling}
					>
						{t('no')}
					</CustomButton>
					<CustomButton
						className={cn(classes.btn, classes.cancelBtn)}
						data-qa="qa-proceed"
						type="submit"
						variant="outlined"
						onClick={onCancelFuelRequest}
						disabled={isCanceling}
					>
						{t('yes')}
					</CustomButton>
				</Box>
			</>
		</ModalWrapper>
	);
};

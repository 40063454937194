import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	root: {
		textAlign: 'left',
	},
	dialogTitle: {
		fontSize: 14,
		fontWeight: 400,
	},
});

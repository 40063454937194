import { gql } from '@apollo/client';
import { moveCardDetails } from '../fragments';

export const getMoveList = gql`
	query getMoveList(
		$jobCategory: JobCategory!
		$searchStr: String
		$cargoType: CargoType
		$after: String
		$first: Int = 10
		$moveType: MoveType
	) {
		myjobList(
			jobCategory: $jobCategory
			moveType: $moveType
			searchStr: $searchStr
			cargoType: $cargoType

			first: $first
			after: $after
		) {
			pageInfo {
				hasNextPage
				endCursor
			}
			edges {
				node {
					...moveCardDetails
				}
			}
		}
	}
	${moveCardDetails}
`;

import React, { useMemo } from 'react';
import { InsuranceForm as InsuranceFormTemp } from 'components';
import { InsuranceFormNames } from 'models';
import * as Yup from 'yup';
import { CreateInsuranceMutationVariables } from 'generate/typesAuth';
import { mapFieldsToInitialValues } from 'helpers';
import { Insurance } from 'generate/types';

interface InsuranceFormProps {
	onSubmit: (d: CreateInsuranceMutationVariables) => void;
	data?: Partial<Insurance> | null;
}

const fields = [
	InsuranceFormNames.broker,
	InsuranceFormNames.brokerContactName,
	InsuranceFormNames.brokerTelNumber,
	InsuranceFormNames.brokerEmail,
	InsuranceFormNames.insuranceCompany,
	InsuranceFormNames.policyNumber,
	InsuranceFormNames.hasComprehensiveGitCover,
	InsuranceFormNames.gitCover,
	InsuranceFormNames.hasPublicLiabilityInsurance,
];

export const InsuranceForm = ({ onSubmit, data }: InsuranceFormProps) => {
	const initialValues = useMemo(() => mapFieldsToInitialValues(fields, data || {}), [data]);

	const validationSchema = useMemo(() => Yup.object({}), []);

	return (
		<InsuranceFormTemp
			onSubmit={onSubmit}
			validationSchema={validationSchema}
			initialValues={initialValues as CreateInsuranceMutationVariables}
		/>
	);
};

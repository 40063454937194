import { Theme, useMediaQuery, useTheme } from '@material-ui/core';

export function useWidth() {
	const theme: Theme = useTheme();

	const md = useMediaQuery(theme.breakpoints.down('md'));
	const sm = useMediaQuery(theme.breakpoints.down('sm'));

	return {
		md,
		sm,
	};
}

import { useEffect, useRef } from 'react';

export function useDidUpdateEffect(effect: Function, deps: unknown[]) {
	const ref = useRef(false);

	useEffect(() => {
		if (ref.current) {
			effect();
		} else {
			ref.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
}

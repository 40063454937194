import { makeStyles } from '@material-ui/core/styles';
import {COLORS, STYLES} from 'utils';

export const useStyles = makeStyles({
	title: {
		textAlign: 'center',
		fontSize: 16,
		color: COLORS.primary,
		textTransform: 'capitalize',
		fontWeight: 800,
		lineHeight: '16px'
	},
	button: {
		height: 40,
		textTransform: "capitalize",
		fontSize: 14,
		fontWeight: 500
	},
	submit: STYLES.modal.submit,
	backdrop: {
		backgroundColor: 'rgba(0, 0, 0, 0.4)',
		borderRadius: '10px !important'
	},
});

import { Driver, Drivers, Trailer, Trailers, Truck, Trucks_ } from 'generate/types';
import { getPaginatedDataNodeList } from 'helpers';
/**
 *
 * @param previousQueryResult
 * @param fetchMoreResult
 * @param assetSetter
 * @param setHasNextPage
 * @param setAfter
 * @param dataKey
 */
export function parseUpdateQuery(
	previousQueryResult: any,
	fetchMoreResult: any,
	assetSetter: (value: any) => void,
	setHasNextPage: (value: boolean) => void,
	setAfter: (value: string) => void,
	dataKey: string
) {
	const { hasNextPage, endCursor } =
		(fetchMoreResult && fetchMoreResult[dataKey]?.pageInfo) ||
		(previousQueryResult && previousQueryResult[dataKey]?.pageInfo) ||
		{};
	setHasNextPage(hasNextPage);
	setAfter(endCursor || '');
	const assetList = getPaginatedDataNodeList<
		Truck | Trailer | Driver,
		Trucks_ | Drivers | Trailers
	>(fetchMoreResult && fetchMoreResult[dataKey]);

	assetSetter((prevAssets: []) => [...prevAssets, ...assetList]);
}

import { useMemo } from 'react';
import { ReportIncidentNames } from 'models';
import * as Yup from 'yup';
import { getRequiredMessage, formatCamelToSnakeCase } from 'helpers';
import { useTranslation } from 'react-i18next';
import { ReportIncidentForm as ReportIncidentFormTemp } from 'components';

interface ReportIncidentProps {
	onClose: () => void;
	onSubmit: (data: Record<ReportIncidentNames, unknown>) => void;
}

export const ReportIncidentForm = ({ onClose, onSubmit }: ReportIncidentProps) => {
	const { t: tE } = useTranslation('errors');
	const { t: tF } = useTranslation('form-fields');

	const initialValues = useMemo(
		() => ({
			[ReportIncidentNames.assignTo]: '',
			[ReportIncidentNames.details]: '',
			[ReportIncidentNames.uploadPhoto]: '',
			[ReportIncidentNames.incidentType]: '',
			[ReportIncidentNames.typeId]: '',
		}),
		[]
	);

	const validationSchema = useMemo(
		() =>
			Yup.object({
				[ReportIncidentNames.incidentType]: Yup.string().required(
					getRequiredMessage(
						tE,
						tF(`report_incident.${formatCamelToSnakeCase(ReportIncidentNames.incidentType)}`)
					)
				),
				[ReportIncidentNames.typeId]: Yup.string().required(
					getRequiredMessage(
						tE,
						tF(`report_incident.${formatCamelToSnakeCase(ReportIncidentNames.typeId)}`)
					)
				),
				[ReportIncidentNames.details]: Yup.string().required(
					getRequiredMessage(
						tE,
						tF(`report_incident.${formatCamelToSnakeCase(ReportIncidentNames.details)}`)
					)
				),
			}),
		[tE, tF]
	);

	return (
		<ReportIncidentFormTemp
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			onClose={onClose}
		/>
	);
};

import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { AppPathList } from './AppPathList';
import { ExternalLayout, Main } from 'layout';

const AvailableJobsRouter = React.lazy(() => import('./pages/AvailableJobs.router'));
const MyJobsRouter = React.lazy(() => import('./pages/Jobs.router'));
const AccountRouter = React.lazy(() => import('./pages/Account.router'));
const LoginRouter = React.lazy(() => import('./pages/Login.router'));
const Services = React.lazy(() => import('./pages/AuxiliaryServices.router'));
const NotificationsRoute = React.lazy(() => import('./pages/Notifications.router'));

export default function AppRouter() {
	return (
		<Router>
			<Switch>
				<Route path={[AppPathList.login, AppPathList.signUp, AppPathList.forgetPassword]}>
					<ExternalLayout>
						<LoginRouter />
					</ExternalLayout>
				</Route>

				<Route>
					<Main>
						<Switch>
							<Route path={AppPathList.available} component={AvailableJobsRouter} />
							<Route path={AppPathList.jobs} component={MyJobsRouter} />
							<Route path={AppPathList.account} component={AccountRouter} />
							<Route path={AppPathList.auxiliaryServices} component={Services} />
							<Route path={AppPathList.notifications} component={NotificationsRoute} />
							<Redirect to={AppPathList.available} />
						</Switch>
					</Main>
				</Route>
			</Switch>
		</Router>
	);
}

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {COLORS, DATES, STYLES} from 'utils';
import { Move } from 'generate/types';
import {formatCamelToSnakeCase, formatToDate} from 'helpers';

const useStyles = makeStyles({
	list: {
		fontWeight: STYLES.bold,
		padding: 16,
	},
	title: {
		textAlign: 'center',
		fontSize: 10,
		color: COLORS.primary,
		fontWeight: 400,
		lineHeight: '16px'
	},
	value: {
		textAlign: 'center',
		fontSize: 10,
		color: COLORS.grey3,
		fontWeight: 700,
		lineHeight: '16px'
	},
});

interface IDriverMessage extends Partial<Move> {
	origin: string | null;
	destination: string;
	moveId: number;
	CargoType: string;
	readyToLoad: string;
}

interface MoveInfoListProps {
	driverMessageDetails: Partial<IDriverMessage>;
}

export function MoveInfoList({ driverMessageDetails }: MoveInfoListProps) {
	const { t } = useTranslation('modals');
	const { t: tJ } = useTranslation('job');
	const classes = useStyles();

  const item = useCallback(
		([k, v]) => {
			switch (k) {
				case 'origin':
					return (
						<Typography className={classes.value} key={v}>
							From: {v}
						</Typography>
					);
				case 'destination':
					return (
						<Typography className={classes.value} key={v}>
							To: {v}
						</Typography>
					);
				case 'etaLoadingPoint':
					return (
						<Typography className={classes.value} key={v}>
							{t('eta_loading_point')}: {formatToDate(v, DATES.loadDateFormat)}
						</Typography>
					);
				case 'moveId':
					return (
						<Typography className={classes.value} key={v}>
							{tJ('move_id')}: {v}
						</Typography>
					);
				default:
					return (
						<Typography className={classes.value} key={v}>{`${t(
							formatCamelToSnakeCase(k)
						)}: ${v}`}</Typography>
					);
			}
		},
		[classes.value, t, tJ]
	);

	return (
		<ul className={classes.list}>
			<Typography className={classes.title}>{t('driver_note_intro')} </Typography>
			{Object.entries(driverMessageDetails).map(item)}
		</ul>
	);
}

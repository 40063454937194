import {makeStyles} from '@material-ui/core/styles';
import {COLORS, STYLES} from 'utils';

export const useStyles = makeStyles({
    modal: {
        padding: '8px'
    },
    modalContent: {
        overflow: "auto"
    },
    container: {
        padding: 12,
        borderRadius: 4,
        border: '1px dashed #CCC',
        background: COLORS.grey1,
    },
    heading: {
        textAlign: 'left',
        fontSize: '1.2rem',
        color: COLORS.primary,
        textTransform: 'capitalize' as 'uppercase',
        fontWeight: 600,
    },
    title: {
        textAlign: 'left',
        fontSize: '1rem',
        color: COLORS.primary,
        textTransform: 'capitalize' as 'uppercase',
        fontWeight: 600,
    },
    label: {
        textAlign: 'left',
        fontSize: '16px',
        color: COLORS.textLabel,
        textTransform: 'none',
        fontWeight: 500,
        marginTop: 2
    },
    successLabel: {
        textAlign: 'left',
        fontSize: '14px',
        lineHeight:'20px',
        color: COLORS.green,
        textTransform: 'capitalize' as 'uppercase',
        fontWeight: 600,
    },
    icon: {
        cursor: 'pointer',
        display: 'flex',
        margin: 2
    },

    button: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        height: "auto",
        padding: 0,
        marginTop: 2
    },
    buttonIcon: STYLES.buttonIcon,
});

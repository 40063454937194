import { styled, darken } from '@material-ui/core';
import { CustomButton } from './CustomButton';
import { COLORS } from 'utils';

export const RedButton = styled(CustomButton)({
	backgroundColor: COLORS.danger,
	width: 'auto',
	'&:hover': {
		backgroundColor: darken(COLORS.danger, 0.2),
	},
});

import { TruckSubmitList, TruckSubmitListWA } from 'containers';
import { useDidUpdateEffect, useResultContext } from 'hooks';
import { useTranslation } from 'react-i18next';
import { AssetList, useAcceptJobMutation } from 'generate/types';
import { FormIdList } from 'models';
import { mapToTruckList } from 'helpers';
import { AppPathList } from 'router';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { CustomButton, Loader } from 'components';
import { useReadUserInfo } from 'hooks';
import { TruckListWA, DriverListWA } from 'containers/account-assets';
import { useMixpanel } from 'analytics';

export const AcceptWorkOrder = () => {
  const { t: tM } = useTranslation('modals');
  const { t: tB } = useTranslation('buttons');
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { isWA } = useReadUserInfo();
  const mixpanel = useMixpanel();
  const location: any = useLocation();

  const { openResult, isResultOpened, openErrorResult } = useResultContext();
  const [acceptJob, { error, loading: isSubmitting }] = useAcceptJobMutation({
    onCompleted: openResult({
      title: tM('job_submitted'),
      text: tM('job_submitted_text'),
    }),
    onError: openErrorResult,
  });

  const handleSubmitList = (truckListData: AssetList[]) => {
    if (location.state?.weight) {
      truckListData = truckListData.map((item) => {
        item.weight = location.state?.weight;
        return item;
      });
    }

    const truckList = mapToTruckList(truckListData);
    acceptJob({
      variables: {
        assetList: truckList,
        lroId: id as string,
      },
    });
    mixpanel.track('Submit truck list event', {
      'Submited Trucks Count': truckList.length || 0,
    });
  };

  useDidUpdateEffect(() => {
    if (!isResultOpened && !error) {
      push(AppPathList.availableNew);
    }
  }, [isResultOpened, push, error]);

  return (
    <Box pb={3} display='flex' flexDirection='column' overflow='auto'>
      <Loader isLoading={isSubmitting} overflow>
        {isWA ? (
          <TruckSubmitListWA onSubmit={handleSubmitList} isLoading={false} />
        ) : (
          // @ts-ignore
          <TruckSubmitList onSubmit={handleSubmitList} isLoading={false} />
        )}
        <Box display='flex' alignItems='center' mt={5} flexDirection='column'>
          <CustomButton type='submit' form={FormIdList.truckList} data-qa="qa-submit-list">
            {tB('submit_list')}
          </CustomButton>
          <TruckListWA />
          <DriverListWA />
        </Box>
      </Loader>
    </Box>
  );
};

import React, { useEffect, useMemo } from 'react';
import {
	Button,
	Paper,
	ClickAwayListener,
	MenuList,
	MenuItem,
	Popper,
	Grow,
} from '@material-ui/core';
import { useStyles } from './CustomInputGroup.styles';
import { ChevronIcon } from 'components';
import { IOption, IOptionList } from 'models';
import { CustomInputField } from 'containers/form-fields';

interface CustomInputGroupProps<T> {
	buttonText: string;
	name: string;
	type?: string;
	options?: IOptionList<T>;
	onChangeOption: (optionValue: T) => void;
	defaultOption?: IOption<T>['value'];
}

export function CustomInputGroup<T>({
	buttonText,
	name,
	type = 'number',
	options = [],
	onChangeOption,
	defaultOption,
}: CustomInputGroupProps<T>) {
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLButtonElement>(null);
	const [selectedIndex, setSelectedIndex] = React.useState<number>();

	const isDisabled = useMemo(() => !!options?.length, [options]);

	const classes = useStyles(open)();

	useEffect(() => {
		if (selectedIndex === undefined && defaultOption) {
			setSelectedIndex(options?.findIndex((option) => option.value === defaultOption));
		}
	}, [defaultOption, selectedIndex, options]);

	useEffect(() => {
		if (selectedIndex) {
			onChangeOption(options[selectedIndex]?.value);
		}
	}, [selectedIndex, options, onChangeOption]);

	const handleMenuItemClick = (
		event: React.MouseEvent<HTMLLIElement, MouseEvent>,
		index: number
	) => {
		setSelectedIndex(index);
		setOpen(false);
	};

	const handleToggle = () => {
		if (isDisabled) {
			setOpen((prevOpen) => !prevOpen);
		}
	};

	const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}

		setOpen(false);
	};

	return (
		<>
			<CustomInputField
				name={name}
				InputProps={{
					startAdornment: (
						<Button onClick={handleToggle} className={classes.button} ref={anchorRef}>
							{buttonText}
							<ChevronIcon className={classes.icon} />
						</Button>
					),
				}}
				variant="outlined"
				className={classes.input}
				type={type}
				disabled={isDisabled}
			/>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				transition
				placement="bottom-start"
				modifiers={{
					offset: {
						offset: '0, 1',
					},
				}}
				className={classes.popper}
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu">
									{options.map(({ value, title }, index) => (
										<MenuItem
											key={title}
											selected={index === selectedIndex}
											onClick={(event) => handleMenuItemClick(event, index)}
										>
											{title}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
}

import { gql } from '@apollo/client';

export const getWorkOrderList = gql`
	query getWorkOrderList(
		$jobCategory: JobCategory!
		$searchStr: String
		$moveType: MoveType
		$cargoType: CargoType
		$after: String
		$first: Int = 10
	) {
		jobList(
			jobCategory: $jobCategory
			searchStr: $searchStr
			moveType: $moveType
			cargoType: $cargoType
			first: $first
			after: $after
		) {
			pageInfo {
				hasNextPage
				endCursor
			}
			edges {
				node {
          id
          lroId
          cargoType
          commodity
          consignmentMetricTons
          createdOn
          currency
          deadlineToLift
          origin
          destination
          readyToLoad
          trucksNeeded
          totalRequested
          trucksShortlisted
          trucksMatched
          marketRate
          packagingType
          phoneNumber
          trailerType
          truckType
          isBid
          bidPrice
          weight
          weightToLoad
          destinationCity
          originCity
          transportType
          transporterRateType
          transporterPaymentTerms
				}
			}
		}
	}
`;

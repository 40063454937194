import { ISelectOptionList } from 'containers';
import { Truck, TruckTypes } from 'generate/typesSupplyService';

export function getActiveOptionList<T, K extends keyof T>(
	list: T[],
	titleKeys: K[],
	valueKey?: K
): ISelectOptionList {
	return (
		list
			// @ts-ignore
			.filter((item) => item.active || item.active === null)
			.map((item) => ({
				title: titleKeys.map((titleKey) => item[titleKey] as unknown as string).join(' '),
				value: (valueKey ? item[valueKey] : item[titleKeys[0]]) as unknown as string | number,
			}))
	);
}

export function getOptionList<T, K extends keyof T>(
	list: T[],
	titleKeys: K[],
	valueKey?: K
): ISelectOptionList {
	return list.map((item) => ({
		title: titleKeys.map((titleKey) => item[titleKey] as unknown as string).join(' '),
		value: (valueKey ? item[valueKey] : item[titleKeys[0]]) as unknown as string | number,
	}));
}

export function getTruckOptionList(list: Truck[]) {
	return (
		list
			// @ts-ignore
			.filter((item) => item.active || item.active === null)
	);
}

export function getOptionListValues<T, K extends keyof T>(
	list: T[],
	titleKeys: K[],
	valueKeys: K[]
): ISelectOptionList {
	return (
		list
			.map((item) => ({
				title: titleKeys.map((titleKey) => item[titleKey] as unknown as string).join(' '),
				value: JSON.stringify(
					valueKeys.reduce(
						(res, valueKey) => ({
							...res,
							[valueKey]: item[valueKey] as unknown as string,
						}),
						{}
					)
				),
			}))
	);
}

export function getOptionListFromPrimitives(list: TruckTypes[]): ISelectOptionList {
	return list
		.map(({ name, id }) => {
			if (name) {
				return {
					title: '' + name,
					value: id,
				};
			}
			return undefined;
		})
		.filter(Boolean) as ISelectOptionList;
}

export function getSupplyOptionListFromPrimitives(list: (string | null)[]): any {
	return list
		.map((item) => {
			if (item) {
				return {
					// @ts-ignore
					title: item?.name,
					value: item,
				};
			}
			return undefined;
		})
		.filter(Boolean) as any;
}

import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import {COLORS} from 'utils/constants';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '3rem',
        },
        container: {
            display: 'flex',
            overflow: 'auto',
            alignItems: 'end',
            justifyContent: 'center',
            width: '80%',
            background: '#ffffff',
            padding: 8,
            borderRadius: 8
        },
        iframe: {
            width: '100%',
            height: '800px',
            objectFit: "contain"
        },
        closeIcon: {
            right: '1.5rem',
            top: '1.5rem',
            width: '1.5rem',
            height: '1.5rem',
            fill: COLORS.gray,
            marginBottom: 10
        },
        paper: {
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(1.5),
            borderRadius: '10px',

            '& .submitted-days': {
                color: COLORS.yellow,
                textAlign: 'start',
                fontSize: 11,
                fontWeight: 400,
                lineHeight: '16px'
            },
        },
        backdrop: {
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
        },
    })
);

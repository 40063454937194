import { gql } from '@apollo/client';

export const getDocumentTypes = gql`
	query getDocumentTypes(
    $stage: String,
    $region: String
  ) {
		documentTypes(
      stage: $stage
      region: $region
    ) {
      id
      label
      region
      stages
		}
	}
`;

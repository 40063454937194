import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { API } from 'utils';

import { cache } from '../cache';
import { errorLink } from '../links/errorLink';

const { createUploadLink } = require("apollo-upload-client");

const httpLink = createUploadLink({
	uri: `${API.LFAClient.url}`,
});

const lfaClientLink = errorLink().concat(httpLink);

export const lfaClient = new ApolloClient<NormalizedCacheObject>({
	link: lfaClientLink,
	cache,
	queryDeduplication: false,
	connectToDevTools: true,
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'cache-and-network',
		},
		query: {
			fetchPolicy: 'network-only',
		},
	},
});

export default lfaClient;

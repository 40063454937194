import { gql } from '@apollo/client';

export const submittedJobDetails = gql`
	fragment submittedJobDetails on Move {
		cargoType
		origin
		destination
		readyToLoad

		trailerType
		transporterRate
		transporterRateType
		trucksNeeded
		trucksMatched
		weight
		commodity

		currency
	}
`;

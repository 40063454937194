import { gql } from '@apollo/client';

export const createAccount = gql`
	mutation createAccount(
		$bankName: String
		$accountName: String
		$accountNumber: String!
		$accountType: String
		$bankCode: String
		$branchName: String
		$swiftCode: String
		$bankAddress: String
		$beneficiaryName: String
		$beneficiaryAddress: String
		$companyId: Int!
	) {
		createAccount(
			input: {
				bankName: $bankName
				accountName: $accountName
				accountNumber: $accountNumber
				accountType: $accountType
				bankCode: $bankCode
				branchName: $branchName
				swiftCode: $swiftCode
				bankAddress: $bankAddress
				beneficiaryName: $beneficiaryName
				beneficiaryAddress: $beneficiaryAddress
				companyId: $companyId
			}
		) {
			account {
				id
			}
			ok
			message
		}
	}
`;

import { RecordList } from 'components';

const regions = {
	EA: 'East Africa',
	WA: 'West Africa',
	SA: 'South Africa',
};

export function getRecordList<T>(
	data: T | T[],
	keys: Array<keyof T> = Object.keys(data) as Array<keyof T>
): RecordList {
	if (Array.isArray(data)) {
		return data.map((item) => getRecordItem(item, keys)).flat();
	}

	return getRecordItem(data, keys);
}

function getRecordItem<T>(item: T, keys: Array<keyof T> = Object.keys(item) as Array<keyof T>) {
	return keys.map((k) => {
		if (k === 'region') {
			// @ts-ignore
			let value = item['region'];
			// @ts-ignore
			return { recordKey: k as string, value: regions[value] as unknown as string };
		}

		if (k === 'company_mobile') {
			return { recordKey: 'Phone number', value: item[k] as unknown as string };
		}

		if (k === 'phone') {
			return { recordKey: 'Phone number', value: item[k] as unknown as string };
		}

    if (k === 'email_verified') {
      const value = item[k] ? 'Yes' : 'No'
			return { recordKey: 'Verified', value: value as unknown as string };
		}

		return { recordKey: k as string, value: item[k] as unknown as string };
	});
}

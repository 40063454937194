import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: '#fff',
		},
		margin: {
			marginTop: theme.spacing(2),
		},
		textField: {
			width: '100%',
		},
		label: {
			color: theme.palette.primary.main,
      // @ts-ignore
			fontWeight: theme?.typography?.fontWeightBold,
			fontSize: theme?.typography?.fontSize,
		},
	})
);

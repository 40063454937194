import { gql } from '@apollo/client';

export const getAuthKey = gql`
  query getAuthKey(
    $app: AppEnum,
  ) {
    getAuthKey(
      app: $app,
    ) {
      AccessToken
      RefreshToken
      ExpiresIn
    }
  }
`;

import React from 'react';
import cn from 'classnames';
import { IIconProps } from './IIconProps';

export const ChevronIcon = ({ className, title = '', pointer, ...props }: IIconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="Chevron-icon"
			viewBox="0 0 32 32"
			className={cn(className, {
				pointer: pointer,
			})}
			{...props}
		>
			<title>{title}</title>
			<path d="M21.469 12.574c0.434-0.429 1.134-0.429 1.565 0 0.432 0.429 0.435 1.122 0 1.55l-6.253 6.128c-0.432 0.429-1.131 0.429-1.566 0l-6.253-6.128c-0.432-0.427-0.432-1.122 0-1.55 0.434-0.429 1.134-0.429 1.565 0l5.474 5.026 5.469-5.026z" />
		</svg>
	);
};

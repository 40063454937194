import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

export const cache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: {
				getUsers: relayStylePagination(['searchStr', 'role']),
				jobList: relayStylePagination(['jobCategory', 'searchStr', 'moveType', 'cargoType']),
				myjobList: relayStylePagination(['jobCategory', 'searchStr', 'cargoType']),
			},
		},
	},
});

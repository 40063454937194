import { useMemo } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import { CustomInputField, CustomSelectField } from 'containers';
import { ReportIncidentNames } from 'models';
import { formatCamelToSnakeCase } from 'helpers';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { CustomButton } from 'components/button';
import { useGetIssueTypesQuery } from 'generate/liaDemand';
import { Box } from '@material-ui/core';
import { IncidentTypes } from 'models';
import { liaSupplyClient } from 'graphqlapi';

interface ReportIncidentProps {
  onClose: () => void;
  onSubmit: (data: Record<ReportIncidentNames, unknown>) => void;
  initialValues: Record<ReportIncidentNames, unknown>;
  validationSchema?: unknown;
}

export const ReportForm = () => {
  const { t: tB } = useTranslation('buttons');
  const { t: tF } = useTranslation('form-fields');


  const { values } = useFormikContext();
  // @ts-ignore
  const issueTypeCategoryId = values?.incidentType;

  const { data: incidentTypesData } = useGetIssueTypesQuery({
    client: liaSupplyClient,
  });

  const incidentTypes = get(incidentTypesData, 'issueTypes', []);

  const incidentOptionList = useMemo(
    () =>
      Object.keys(IncidentTypes).map((key) => ({
        title: tF(`report_incident.${key.toLowerCase()}`),
        value: IncidentTypes[key as keyof typeof IncidentTypes],
      })),
    [tF]
  );

  const incidentTypeIds = useMemo(() => {
    if ([1, 2].includes(Number(issueTypeCategoryId))) {
      return incidentTypes
        ?.filter(
          // @ts-ignore
          (types: { issueTypeCategoryId: int }) =>
            types?.issueTypeCategoryId === Number(issueTypeCategoryId)
        )
        .map((typeId) => ({
          title: typeId?.issueType,
          value: typeId?.id,
          ...typeId,
        }));
    }
    return [];
  }, [incidentTypes, issueTypeCategoryId]);

  return (
    <Form>
      <CustomSelectField
        options={incidentOptionList}
        name={ReportIncidentNames.incidentType}
        label={tF(
          `report_incident.${formatCamelToSnakeCase(
            ReportIncidentNames.incidentType
          )}`
        )}
      />
      <CustomSelectField
        // @ts-ignore
        options={incidentTypeIds}
        name={ReportIncidentNames.typeId}
        // @ts-ignore
        disabled={
          !(
            typeof issueTypeCategoryId === 'string' &&
            issueTypeCategoryId.length > 0
          )
        }
        label={tF(
          `report_incident.${formatCamelToSnakeCase(
            ReportIncidentNames.typeId
          )}`
        )}
      />
      <CustomInputField
        name={ReportIncidentNames.details}
        label={tF(
          `report_incident.${formatCamelToSnakeCase(
            ReportIncidentNames.details
          )}`
        )}
      />
      <Box mx='auto' mt={2}>
        <CustomButton type='submit' autoWidth>
          {tB('submit')}
        </CustomButton>
      </Box>
    </Form>
  );
};

export const ReportIncidentForm = ({
  onSubmit,
  initialValues,
  validationSchema,
}: ReportIncidentProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <ReportForm />
    </Formik>
  );
};

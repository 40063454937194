import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils';

export const useStyles = makeStyles({
	wrapper: {
		borderRadius: 5,
		border: `1px solid ${COLORS.cardBorder}`,
		backgroundColor: COLORS.white,
		boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.03)',
		margin: '16px 0 8px',
		'&:first-of-type': {
			marginTop: 0,
		},
	},
	header: {
		padding: '0 13px',
		position: 'relative',
	},
	arrow: {
		width: '2rem',
		fill: COLORS.gray,
		position: 'absolute',
		right: 8,
		top: '50%',
		transform: 'translateY(-50%)',
		transition: 'transform .2s ease-out',
	},
	content: {
		padding: '0 13px 13px',
		overflow: 'hidden',
		transition: 'all .3s linear',
		maxHeight: 250,
	},
	formField: {
		height: '40px !important',
	},
	hide: {
		borderTop: `0 solid ${COLORS.footerBorder}`,
		maxHeight: 0,
		paddingBottom: 0,
	},
	up: {
		transform: 'translateY(-50%) rotate(180deg)',
	},
	input: {
		paddingRight: 2,
	},
	icon: {
		cursor: 'pointer',
	},
	selectInput: {
		marginBottom: 20,
		'& input': {
			boxSizing: 'border-box',
		},
	},
});

import { createContext, PropsWithChildren, useCallback, useState } from 'react';
import { SubmitTruckFormData } from 'models';

interface IConfirmTruckListContext {
	isCancelAllowed: boolean;
	isSubmitAllowed: boolean;
	validateTruckList: (data: SubmitTruckFormData) => void;
}

export const ConfirmTruckListContext = createContext<IConfirmTruckListContext>({
	isCancelAllowed: true,
	isSubmitAllowed: true,
	validateTruckList: () => {},
});

export const ConfirmTruckListProvider = ({ children }: PropsWithChildren<{}>) => {
	const [isCancelAllowed, setIsCancelAllowed] = useState(true);
	const [isSubmitAllowed, setIsSubmitAllowed] = useState(true);

	const validateTruckList = useCallback((truckListFormData: SubmitTruckFormData) => {
    const truckList = Object.values(truckListFormData);
		if (truckList.filter(({ checked }) => checked).length === 1) {
			setIsCancelAllowed(true);
		} else {
			setIsCancelAllowed(false);
		}

		if (truckList.some(({ checked }) => !checked)) {
			setIsSubmitAllowed(false);
		} else {
			setIsSubmitAllowed(true);
		}
	}, []);

	return (
		<ConfirmTruckListContext.Provider
			value={{
				validateTruckList,
				isSubmitAllowed: isSubmitAllowed,
				isCancelAllowed: isCancelAllowed,
			}}
		>
			{children}
		</ConfirmTruckListContext.Provider>
	);
};

import { makeStyles } from '@material-ui/core/styles';
import { STYLES } from 'utils';

export const useStyles = makeStyles({
	title: STYLES.modal.title,
	subTitle: {
		fontWeight: 400,
		textTransform: 'uppercase',
	},
});

import { makeStyles } from '@material-ui/core';
import { theme } from 'utils';

export const useStyles = makeStyles({
	main: {
		display: 'flex',
		justifyContent: 'center',
	},
	form: {
		marginTop: 24,
		maxWidth: 576,
		[theme.breakpoints.up(theme.breakpoints.values.sm)]: {
			textAlign: 'center',
		},
	},
	btn: {
		height: 36,
	},
});

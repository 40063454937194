import React, { useCallback, useContext, useState, useEffect } from 'react';
import cn from 'classnames';
import {
  Box,
  Dialog,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ConfirmAssetList, WorkOrderDetailsFragment } from 'generate/types';
import { useStyles } from './AvailableJobDetailsMatched.styles';
import { DestinationBlock } from '../destination-block';
import { CustomButton } from '../button';
import { TruckConfirmList } from 'containers';
import { FormIdList, SubmitActionTypes } from 'models';
import { ConfirmTruckListContext } from 'context';
import { useMixpanel } from 'analytics';
import closeIconSrc from 'assets/svg/close.svg';
export interface ConfirmationDialogRawProps {
  open: boolean;
  onClose: (value?: string) => void;
  handleCancelOrder: (value?: string) => void;
}

interface AvailableJobDetailsProps extends WorkOrderDetailsFragment {
  onConfirm: (truckListData: [ConfirmAssetList]) => void;
  onCancel: (truckListData: [ConfirmAssetList]) => void;
  isSubmitting?: boolean;
}

export function AvailableJobDetailsMatched({
  cargoType,
  origin,
  destination,
  deadlineToLift,
  onConfirm,
  onCancel,
  isSubmitting,
  trucksMatched,
  trucksNeeded,
  // @ts-ignore
  truckList = [],
}: AvailableJobDetailsProps) {
  const { t } = useTranslation('available');
  const { t: tB } = useTranslation('buttons');
  const { t: tM } = useTranslation('modals');

  const { isCancelAllowed, isSubmitAllowed } = useContext(
    ConfirmTruckListContext
  );
  const mixpanel = useMixpanel();
  const [submitActionType, setSubmitActionType] = useState<SubmitActionTypes>();
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    mixpanel.track('Matched Available Job Details Render', {
      message: 'Page renders successfully ',
    });
  }, [mixpanel]);

  const handleSubmit = useCallback(
    (truckList: [ConfirmAssetList]) => {
      if (submitActionType === SubmitActionTypes.confirm) {
        onConfirm(truckList);
      } else {
        onCancel(truckList);
      }
    },
    [onCancel, onConfirm, submitActionType]
  );

  const handleCancel = () => {
    setOpen(false);
  };

  const handleConfirm = useCallback(() => {
    setSubmitActionType(SubmitActionTypes.confirm);
  }, []);

  const handleOpenDialogue = () => {
    setOpen(true);
  };

  const handleCancelOrder = () => {
    setSubmitActionType(SubmitActionTypes.cancel);
  };

  return (
    <div className='overflow'>
      <Box textAlign='center' maxWidth={250} marginX='auto' marginBottom={4}>
        <Typography className={classes.confirmText}>{t('matched_job_text')}</Typography>
      </Box>
      <Typography  data-qa={`qa-${cargoType}`} className={classes.title}>{cargoType}</Typography>
      <DestinationBlock data-qa={`qa-${origin}`}  from={origin} to={destination} showOrigin />
      <Typography className={classes.trucksMatched}>
          {t('matched_truck', {
            truckCount: trucksMatched || 0,
            count: trucksNeeded || 0,
          })}
      </Typography>
      <TruckConfirmList
        // @ts-ignore
        onSubmit={handleSubmit}
        truckList={truckList}
        withSearchFilter={false}
        isSubmitting={isSubmitting}
      />
      <Box display='flex' flexDirection='column' marginTop={4}>
        <CustomButton
          type='submit'
          form={FormIdList.truckList}
          className={classes.btn}
          onClick={handleConfirm}
          disabled={!isSubmitAllowed}
          mixPanelEventName='Confirm Matched Job'
          mixPanelEventProperties={{ matchedTrucks: 0 }}
          data-qa="qa-confirm"
        >
          {tB('confirm_job')}
        </CustomButton>
        <CustomButton
            className={cn(classes.btn, classes.cancelBtn)}
            data-qa="qa-cancel"
            type='button'
            variant='outlined'
            onClick={handleOpenDialogue}
        >
          {tB('cancel_job')}
        </CustomButton>
        <Dialog open={open} className={classes.cancelJobDialog}>
          <DialogContent>
            <Box textAlign='center' marginX='auto' marginBottom={2}>
              <img src={closeIconSrc} alt="close"/>
            </Box>
            <Typography className={classes.cancelJob}>{tM('job_cancel_request_message')}</Typography>
            <Box display='flex' flexDirection='column' marginY={3}>
              <CustomButton
                  className={cn(classes.btn, classes.modalDismissButton)}
                  onClick={handleCancel}
                  data-qa="qa-close"
              >
                {tB('no')}
              </CustomButton>
              <CustomButton
                  className={cn(classes.btn, classes.cancelBtn)}
                  data-qa="qa-proceed"
                  type='submit'
                  variant='outlined'
                  form={FormIdList.truckList}
                  disabled={!isCancelAllowed}
                  onClick={handleCancelOrder}
              >
                {tB('yes')}
              </CustomButton>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { AssetList, ConfirmAssetList } from 'generate/types';

export const parseDriverData = (driverData: any) => {
	if (driverData && driverData?.value) {
		return JSON.parse(driverData?.value);
	} else {
		return JSON.parse(driverData);
	}
};

export const getExternalDriverTitle = (driverData?: string) => {
	if (driverData && driverData.length > 0) {
		const data = JSON.parse(driverData) || {};
		return `${data?.names || ''} ${data?.idNumber}`;
	}
	return '';
};

export function mapToTruckList(truckListData: AssetList[]): AssetList[] {
  // @ts-ignore
	return truckListData.map(({ truckReg, driverData, trailerReg, etaLoadingPoint, weight }) => {
		return {
			truckReg,
			trailerReg: trailerReg || undefined,
			driverIdNumber: parseDriverData(driverData).idNumber,
			etaLoadingPoint,
			weight,
		};
	});
}

export function mapToConfirmTruckList(truckListData: [ConfirmAssetList]) {
  // @ts-ignore
	return truckListData.map(({ truckReg, driverData, trailerReg, etaLoadingPoint, jobRequestId }) => ({
		truckReg,
		trailerReg,
		etaLoadingPoint,
		driverIdNumber: parseDriverData(driverData).idNumber,
		name: parseDriverData(driverData).name,
    jobRequestId,
	}));
}

export function mapToTruckRegList(truckListData: AssetList[]): string[] {
  // @ts-ignore
	return truckListData?.filter(({ checked }) => checked).map((truck) => truck?.truckReg!) ?? [];
}

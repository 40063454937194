import React from 'react';
import { CustomButton } from 'components';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import { COLORS } from 'utils';

interface AssetTab<T> {
	title: string;
	id: T;
}

interface AssetTabsProps<T = string | number> {
	tabs: AssetTab<T>[];
	onChangeTab: (tabId: AssetTab<T>['id']) => void;
	activeTabId: T;
}

const useStyles = makeStyles({
	tab: {
		fontSize: '20px',
		fontWeight: 'bold',
		textTransform: 'capitalize',
		color: COLORS.blue3,
	},
	activeTab: {
		textDecoration: 'underline',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
});

export function AssetTabs<T>({ tabs, onChangeTab, activeTabId }: AssetTabsProps<T>) {
	const classes = useStyles();

	return (
		<Box display="flex" flexWrap="wrap">
			{tabs.map(({ id, title }) => (
				<CustomButton
					key={id as unknown as string}
					variant="text"
					onClick={() => onChangeTab(id)}
					className={cn(classes.tab, {
						[classes.activeTab]: activeTabId === id,
					})}
					disableRipple
				>
					{title}
				</CustomButton>
			))}
		</Box>
	);
}

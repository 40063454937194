import { useMemo } from 'react';
import { ModalWrapper } from '../ModalWrapper';
import { useTranslation } from 'react-i18next';
import { CustomInputField } from 'containers';
import { Formik, Form } from 'formik';
import { RedButton } from 'components/button';
import { Box } from '@material-ui/core';
import * as Yup from 'yup';
import { useStyles } from './SuspendUser.styles';
import { getRequiredMessage } from 'helpers';
import { SuspenUserName } from './SuspenUserName';
import Typography from '@material-ui/core/Typography';

interface SuspendUserProps {
	onClose: () => void;
	onSubmit: (v: string) => void;
}

export const SuspendUser = ({ onClose, onSubmit }: SuspendUserProps) => {
	const { t: tB } = useTranslation('buttons');
	const { t } = useTranslation('form-fields');
	const { t: tE } = useTranslation('errors');

	const classes = useStyles();

	const validationSchema = useMemo(
		() =>
			Yup.object({
				// @ts-ignore
				[SuspenUserName.suspensionReason]: Yup.lazy(() => {
					return Yup.string().required(getRequiredMessage(tE, t('modals.suspension_reason')));
				}),
			}),
		[t, tE]
	);

	return (
		<ModalWrapper open={true} onClose={onClose}>
			<Formik
				onSubmit={({ suspensionReason }) => onSubmit(suspensionReason as string)}
				initialValues={{
					[SuspenUserName.suspensionReason]: '',
				}}
				enableReinitialize
				validationSchema={validationSchema}
			>
				<Form>
					<Typography className={classes.title}>{t('modals.suspend_user')}</Typography>
					<CustomInputField name={SuspenUserName.suspensionReason} label={t('suspension_reason')} />
					<Box marginTop={2}>
						<RedButton type="submit" autoWidth>
							{tB('suspend')}
						</RedButton>
					</Box>
				</Form>
			</Formik>
		</ModalWrapper>
	);
};

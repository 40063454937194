import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { STYLES } from 'utils';
import { DetailsListItem } from 'components/details-list-item';
import { formatCamelToSnakeCase } from 'helpers';

const useStyles = makeStyles({
	list: {
		fontWeight: STYLES.bold,
		marginTop: 20,
		width: '100%'
	},
});

interface DetailsListProps extends Record<string, unknown> {}

export function DetailsList(details: DetailsListProps = {}) {
	const { t } = useTranslation('job');
	const classes = useStyles();
	return (
		<ul className={classes.list}>
			{Object.keys(details).map((key) => (
				<DetailsListItem value={details[key]} name={t(formatCamelToSnakeCase(key))} key={key} />
			))}
		</ul>
	);
}

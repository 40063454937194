import { gql } from '@apollo/client';

export const getIssueTypeCategories = gql`
  query getIssueTypeCategories {
    issueTypeCategories {
      id
      name
    }
  }
`;

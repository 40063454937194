import React, { useState } from 'react';
import { useCaptureImage } from 'use-capture-image';
import Button from '@material-ui/core/Button';
import { ModalWrapper } from 'components/modals';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useTranslation } from 'react-i18next';
import { useResultContext } from 'hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import { blobToFile, getDataURL } from 'utils';
import { useStyles } from './styles';
import {Box} from "@material-ui/core";
import takePhotoSrc from 'assets/svg/take_photo.svg';


interface TakePhotoProps {
	onUpload: (name: string, file: File) => void;
	handlePopoverClose: () => void;
	name: string;
}

const TakePhoto = ({ onUpload, name = 'upload.jpeg', handlePopoverClose }: TakePhotoProps) => {
	const classes = useStyles();
	const [openModal, setOpenModal] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [capturedImage, setCapturedImage] = useState('');
	const [photoBlob, setPhotoBlob] = useState(null);
	const { openErrorResult } = useResultContext();

	const { t: tM } = useTranslation('modals');
	const { t: tB } = useTranslation('buttons');

	const { startCamera, stopCamera, captureImage, videoRef } = useCaptureImage({
		constraints: {
			video: {
				width: {
					max: 1200,
				},
				height: {
					max: 1200,
				},
				aspectRatio: { ideal: 1 },
				facingMode: 'environment',
			},
		},
	});

	const handleCapture = async () => {
		const imgBlob = await captureImage();
		await setPhotoBlob(imgBlob);
		const dataURL = await getDataURL(imgBlob).catch((error) => {
			openErrorResult(error, {
				title: tM('oops'),
				text: tM('upload_failed'),
				type: 'error',
			});
		});
		await setCapturedImage(dataURL || '');
		await stopCamera();
	};

	const handleModalOpen = async () => {
		await startCamera();
		await setOpenModal(true);
	};

	const handleModalClose = async () => {
		if (isUploading) return;
		await handlePopoverClose();
		await stopCamera();
		await setCapturedImage('');
		await setOpenModal(false);
	};

	const handleRetakePhoto = async () => {
		await startCamera();
		await setCapturedImage('');
	};

	const handleUploadPhoto = async () => {
		const imgFile = await blobToFile(photoBlob, `${name}.jpeg`);
		await setIsUploading(true);
		await onUpload(name, imgFile);
		await handleModalClose();
		await setIsUploading(false);
	};

	return (
		<>
			<Button color="primary" onClick={handleModalOpen}>
				<img src={takePhotoSrc} alt="" id="take-photo"/>
			</Button>
			<ModalWrapper open={openModal} onClose={handleModalClose} withCloseBtn={false}>
				<Box>
					{capturedImage.length > 0 ? (
						<Box>
							<img src={capturedImage} alt="" className={classes.capturedImage} />
							<Box display="flex" justifyContent="space-between" alignItems="center">
								<Box width="calc(50% - 8px)">
									<Button
										color="primary"
										size="small"
										startIcon={<PhotoCamera />}
										onClick={handleRetakePhoto}
										disabled={isUploading}
									>
										{tB('retake')}
									</Button>
								</Box>
								<Box width="calc(50% - 8px)" mb="">
									<Button
										onClick={handleUploadPhoto}
										variant="outlined"
										color="primary"
										disabled={isUploading}
										startIcon={isUploading ? <CircularProgress size={16} /> : <CloudUploadIcon />}
									>
										{tB('upload')}
									</Button>
								</Box>
							</Box>
						</Box>
					) : (
						<Box display="flex" flexDirection="column" alignItems="center">
							<video className={classes.video} autoPlay ref={videoRef} />
							<Box maxWidth="100px">
								<Button onClick={handleCapture} variant="outlined" color="primary">
									{tB('capture')}
								</Button>
							</Box>
						</Box>
					)}
				</Box>
			</ModalWrapper>
		</>
	);
};

export default TakePhoto;

import React, { useMemo, useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TruckSubmitListFormWA } from '../truck-submit-list-form';
import { AssetList } from 'generate/types';
import { useGetAllAvailableAssets } from 'hooks';
import { getOptionList, getOptionListValues, getTruckOptionList } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useStyles } from './TruckSubmitListWA.styles';
import { RegionEnum, Truck } from 'generate/typesSupplyService';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Checkbox from '@material-ui/core/Checkbox';
import toUpper from 'lodash/toUpper';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function useDebounce<T>(value: T, delay: number): T {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState<T>(value);
	useEffect(
		() => {
			// Update debounced value after delay
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);
			return () => {
				clearTimeout(handler);
			};
		},
		[value, delay] // Only re-call effect if value or delay changes
	);
	return debouncedValue;
}

export const TruckSubmitListWA = ({
	onSubmit,
	withSearchFilter,
	getAllAssetsHook = useGetAllAvailableAssets,
}: {
	onSubmit: (allTruckList: AssetList[]) => void;
	isLoading?: boolean;
	isSubmitting?: boolean;
	withSearchFilter?: boolean;
	getAllAssetsHook?: typeof useGetAllAvailableAssets;
}) => {
	//---------------------------------- state ---------------------------------//
	const [searchTruck, setSearchTruck] = useState('');
	const debouncedSearchTruck: string = useDebounce<string>(searchTruck, 1000);

	const [searchDriver, setSearchDriver] = useState('');
	const debouncedSearchDriver: string = useDebounce<string>(searchDriver, 1000);

	const [trucks, setTrucks] = useState<Truck[]>([]);
	const [open, setOpen] = useState(false);

	const { t } = useTranslation('form-fields');

	const classes = useStyles();

	//-------------------------------- queries ---------------------------------//
	const { allDriverList, allTruckList, isTruckLoading, isDriverLoading } = getAllAssetsHook(
		toUpper(debouncedSearchTruck),
		toUpper(debouncedSearchDriver),
    RegionEnum.Wa
	);

	//-------------------------------- handlers ---------------------------------//

	const handleSearchChange = (evt: { target: { value: React.SetStateAction<string> } }) => {
		setSearchTruck(evt.target.value);
	};

	const driverOptionList = useMemo(() => {
    return (!isDriverLoading && allDriverList) ? getOptionListValues(allDriverList, ['names', 'phone'], ['idNumber', 'names', 'phone']) : [] ;
	}, [allDriverList, isDriverLoading]);

  const truckOptionList = useMemo(() => {
		return allTruckList && getTruckOptionList(allTruckList);
	}, [allTruckList]);

	const allTruckOptionList = useMemo(() => {
		return allTruckList && getOptionList(allTruckList, ['truckReg'], 'truckReg');
	}, [allTruckList]);

  return (
		<>
			<Autocomplete
				disableCloseOnSelect
				multiple
				style={{ marginTop: 20 }}
				fullWidth
				options={allTruckOptionList}
				open={open}
				onChange={(_, optsTrucks) => {
					if (optsTrucks && trucks.length < 11) {
						// @ts-ignore
						setTrucks((prevTruck) => {
							return optsTrucks.map((truck) =>
								[...truckOptionList, ...prevTruck].find((opst) => opst.truckReg === truck.value)
							);
						});
					}
				}}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				loading={isTruckLoading || isDriverLoading}
				getOptionSelected={(option, value) => option.value === value.value}
				getOptionLabel={(option) => option.title}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder={t('submit_track.search')}
						className={classes.selectInput}
						variant="outlined"
						onChange={handleSearchChange}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{isTruckLoading ? <CircularProgress color="inherit" size={14} /> : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
				renderOption={(option, { selected }) => (
					<React.Fragment>
						<Checkbox
							icon={icon}
							checkedIcon={checkedIcon}
							style={{ marginRight: 8 }}
							checked={selected}
						/>
						{option.title}
					</React.Fragment>
				)}
			/>
			<TruckSubmitListFormWA
				truckList={trucks}
				driverList={driverOptionList}
        // @ts-ignore
				onSubmit={onSubmit}
				withSearchFilter={withSearchFilter}
				// @ts-ignore
				setSearchDriver={(value: string) => {
					setSearchDriver(value);
				}}
				isDriverLoading={isDriverLoading}
			/>
		</>
	);
};

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {useMixpanel} from 'analytics';
import {Order} from 'generate/types';
import {formatToDate} from 'helpers';
import {useReadUserInfo} from 'hooks';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {COMMON, DATES, formatCurrency} from 'utils';
import {useStyles} from './AvailableJobsModal.styles';

interface SubmittedAvailableJobsModalProps {
    item?: Order;
}

export function SubmittedAvailableJobsModal({item}: SubmittedAvailableJobsModalProps) {
    const {t} = useTranslation('available');
    const {isSA, isEA} = useReadUserInfo();
    const classes = useStyles();
    const mixpanel = useMixpanel();

    useEffect(() => {
        mixpanel.track('User arriving to the Submitted Available Jobs” page', {
            message: 'Page renders successfully ',
        });
    }, [mixpanel]);

    return (
        <div className={classes.paper}>
            <div>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <div className={classes.priceDetails}>
                        <div className={classes.truckDistanceType}>
                            <Typography className={classes.priceValue}>
                                {item?.currency}&nbsp;
                            </Typography>
                            <Typography className={classes.priceValue}>
                                {item?.isBid ? formatCurrency(item?.bidPrice) : formatCurrency(item?.marketRate)}
                            </Typography>
                        </div>
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.transporterRateType}>
                                {isEA && ' + VAT'}&nbsp;
                            </Typography>
                            <Typography className={classes.transporterRateType}>
                                {item?.transporterRateType}
                            </Typography>
                        </Box>
                    </div>
                    <div className={classes.deadline}>
                        <Typography className="textSecondary">
                            {t('ready_to_load')}: {formatToDate(item?.deadlineToLift, DATES.loadDateFormatNoTime)}
                        </Typography>
                    </div>
                </Box>
                <Box textAlign="center" marginX={2} marginY={2} className="submitted-days">
                    <Typography color="inherit">
                        {isSA ? t('job_details_note_start_digital_freight') : t('job_details_note_start')}
                        {item?.transporterPaymentTerms} {t('days')},{' '}
                        {t('job_details_note_end')}
                    </Typography>
                </Box>
            </div>
            <ul className={classes.orderDisclaimer}>
                <li className={classes.submitted}>
                    <Typography>
                        {t('shortlisted_trucks', {truckCount: item?.trucksShortlisted || 0})}
                    </Typography>
                </li>
                <li className={classes.submitted}>
                    <Typography>
                        {t('confirm_details_shortly')}
                    </Typography>
                </li>
                <li className={classes.submitted}>
                    {isSA ? (
                        <Typography>
                            {t('south_contact')}&nbsp;
                            <a
                                href={`mailto:${COMMON.imperialAccountManager}?subject=Query on WOID: ${item?.lroId}`}
                                onClick={() => {
                                    mixpanel.track('Submitted available jobs', {
                                        ID: item?.lroId,
                                        message: `User contacts ${COMMON.imperialAccountManager}`,
                                    });
                                }}
                            >
                                {t('operations')}
                            </a>
                            &nbsp;{t('team')}
                        </Typography>
                    ) : (
                        <Typography className="textPrimary">
                            {t('contact_manager')}
                        </Typography>
                    )}
                </li>
            </ul>
        </div>
    );
}

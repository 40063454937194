import {
	CustomSelectField,
	CustomTextField,
	ISelectOptionList,
	PhoneInputField,
} from 'containers/form-fields';
import {
	CompanyInformationFields,
	CompanyInformationNames,
	FormIdList,
	FormStepTempProps,
} from 'models';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { Box } from '@material-ui/core';
import { CustomButton } from 'components/button';
import { useReadUserInfo } from 'hooks';
import { useStyles } from './SignUpForm.styles';

export const testOptions: ISelectOptionList = [
	{
		title: 'Light Commercial',
		value: 'LIGHT_COMMERCIAL_VEHICLE',
	},
	{
		title: 'Heavy Commercial',
		value: 'HEAVY_COMMERCIAL_VEHICLE',
	},
];

interface CompanyInformationFormProps extends FormStepTempProps<CompanyInformationFields> {
	onChangeRegion?: (code?: string) => void;
	isSA?: boolean;
}

export const CompanyInformationForm = ({
	onSubmit,
	validationSchema,
	initialValues,
	onChangeRegion,
	isSA,
}: CompanyInformationFormProps) => {
	const { t } = useTranslation('form-fields');
	const { t: tB } = useTranslation('buttons');
	const { user_id } = useReadUserInfo();
	const classes = useStyles();

	return (
		<Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
			<Form id={FormIdList.companyInfo} className={classes.formWrapper}>
				<CustomTextField
					name={CompanyInformationNames.companyName}
					label={t('sign_up.company_name')}
					placeholder=""
					type="text"
				/>
				<CustomTextField
					name={CompanyInformationNames.companyEmail}
					label={t('sign_up.company_email')}
					placeholder=""
					type="email"
				/>
				<PhoneInputField
					name={CompanyInformationNames.companyMobile}
					label={t('sign_up.company_phone')}
					onChangeFlag={onChangeRegion}
				/>
				{!isSA && (
					<CustomSelectField
						name={CompanyInformationNames.fleet}
						label={t('sign_up.fleet_type')}
						options={testOptions}
					/>
				)}
				{user_id && (
					<Box display="flex" marginY={2} justifyContent="center">
						<CustomButton type="submit">{tB('submit')}</CustomButton>
					</Box>
				)}
			</Form>
		</Formik>
	);
};

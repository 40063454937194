import { gql } from '@apollo/client';

export const importDrivers = gql`
	mutation importDrivers($csvFile: Upload!) {
		importDrivers(csvFile: $csvFile) {
			ok
			message
			response
		}
	}
`;

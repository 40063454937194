import { gql } from '@apollo/client';

export const supplyServiceCreateTrailer = gql`
	mutation createTrailer(
		$cargoCapacity: Float!
		$trailerTypeId: Int!
		$country: String!
		$transporterId: Int!
		$trailerReg: String!
    $source: Source!
    $createdBy: String
	) {
		createTrailer(
      source: $source
			trailerData: {
				cargoCapacity: $cargoCapacity
				trailerTypeId: $trailerTypeId
				country: $country
				transporterId: $transporterId
				trailerReg: $trailerReg
				createdBy: $createdBy
			}
		) {
			status
			message
		}
	}
`;

import React, {useMemo} from 'react';
import {Form} from 'formik';
import {CustomInputField, CustomSelectField, ISelectOptionList} from 'containers';
import {AddTruckFomNames, RegionType} from 'models';
import {formatCamelToSnakeCase} from 'helpers';
import {useTranslation} from 'react-i18next';
import {Box} from '@material-ui/core';
import {CustomButton} from 'components/button';
import {useReadUserInfo} from 'hooks';
import {AppPathList} from "../../../router";
import {ButtonLink} from "../../custom-link";
import Typography from "@material-ui/core/Typography";

interface AddTruckProps {
    typeList: ISelectOptionList;
}

export const AddTruck: React.FC<AddTruckProps> = ({typeList}) => {
    const {t} = useTranslation('form-fields');
    const {t: tB} = useTranslation('buttons');
    const {region} = useReadUserInfo();

    const fields = useMemo(
        () =>
            Object.values(AddTruckFomNames).reduce(
                (res, name) => ({
                    ...res,
                    [name]: {
                        name,
                        label: formatCamelToSnakeCase(name),
                    },
                }),
                {} as Record<AddTruckFomNames, { name: string; label: string }>
            ),
        []
    );

    const isWA = useMemo(
        () => region === RegionType.WA,
        // eslint-disable-next-line
        [region]
    );

    return (
        <Form className="container-internal">
            <Box mt={2} mb={1}>
                <Typography className="subtitle--large textBlack">
                    {t('add_asset.add_truck')}
                </Typography>
            </Box>
            <CustomInputField
                required
                name={fields.truckReg.name}
                label={t(`add_asset.${fields.truckReg.label}`)}/>
            <CustomSelectField
                required
                name={fields.truckType.name}
                label={t(`add_asset.${fields.truckType.label}`)}
                options={typeList}/>
            {isWA ? (
                <>
                    <CustomInputField
                        name={fields.truckCapacity.name}
                        label={t(`add_asset.${fields.truckCapacity.label}`)}
                        type="number"
                    />
                </>
            ) : null}
            <Box mt="auto">
                <CustomButton  className="capitalizeText" type="submit">{tB('submit')}</CustomButton>
            </Box>
            <Box mt={2} paddingBottom={4}>
                <ButtonLink
                    to={AppPathList.assetManagement}
                    variant="outlined" color="primary"
                    autoWidth={true} className="capitalizeText link flexGrow">
                    {tB('cancel')}
                </ButtonLink>
            </Box>
        </Form>
    );
};

import React, {forwardRef} from 'react';
import Button from "@material-ui/core/Button";
import {ButtonProps} from "@material-ui/core";

export interface SkipButtonProps extends ButtonProps {
    component?: React.ElementType;
}

export const SkipButton = forwardRef<HTMLButtonElement, SkipButtonProps>(
    (
        {
            children,
            ...props
        },
        ref
    ) => {
        return (
            <Button
                {...props}
            >
                {children}
            </Button>
        );
    }
);

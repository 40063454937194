import React, { useMemo } from 'react';
import { PersonalInformationForm as PersonalInformationFormTemp } from 'components';
import { PersonalInformationNames } from 'models';
import { CreateProfileMutationVariables } from 'generate/typesAuth';
import { formatCamelToSnakeCase, getRequiredMessage, mapFieldsToInitialValues } from 'helpers';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Profile } from 'generate/types';

interface PersonaInformationFormProps {
	onSubmit: (d: CreateProfileMutationVariables) => void;
	data?: Partial<Profile> | null;
}

const fields = [
	PersonalInformationNames.vetting,

	PersonalInformationNames.firstName,
	PersonalInformationNames.lastName,
	PersonalInformationNames.ID,
];

export const PersonaInformationForm = ({ onSubmit, data = {} }: PersonaInformationFormProps) => {
	const { t } = useTranslation('form-fields');
	const { t: tE } = useTranslation('errors');

	const initialValues = useMemo(() => mapFieldsToInitialValues(fields, data || {}), [data]);

	const validationSchema = useMemo(
		() =>
			Yup.object(
				fields.reduce(
					(res, name) => ({
						...res,
						[name]: Yup.string().required(
							getRequiredMessage(tE, t(`personal_information.${formatCamelToSnakeCase(name)}`))
						),
					}),
					{}
				)
			),
		[tE, t]
	);

	return (
		<PersonalInformationFormTemp
			onSubmit={onSubmit}
			validationSchema={validationSchema}
			initialValues={initialValues as CreateProfileMutationVariables}
			isVettingConsentProvided={!!data?.vettingConsent}
		/>
	);
};

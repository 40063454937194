import React, { useCallback } from 'react';
import { BankDetailsForm } from '../BankDetailsForm';
import { useResultContext } from 'hooks';
import {
	useGetAccountDetailsQuery,
	useUpdateBankDetailsMutation,
	UpdateBankDetailsMutationVariables,
} from 'generate/types';
import { Loader } from 'components';
import { getAccountDetails } from 'graphqlapi';
import { useMixpanel } from 'analytics';
import { useTranslation } from 'react-i18next';

export const UpdateBankDetailsForm = () => {
	const { openErrorResult, toggleResult } = useResultContext();
	const { t: tF } = useTranslation('form-fields');
	const { t: tM } = useTranslation('modals');
	const { t: tC } = useTranslation('common');
	const mixpanel = useMixpanel();

	const { data, loading } = useGetAccountDetailsQuery();

	const [updateAccount] = useUpdateBankDetailsMutation({
		onCompleted: ({ updateAccount }) => {
			mixpanel.track('Update Bank details success', {
				message: updateAccount?.message || 'Bank account updated successfully',
			});
			toggleResult({
				title: tF('bank_update_success_title'),
				text: tF('bank_update_success_message'),
			});
		},
		onError: (error) => {
			mixpanel.track('Update bank account failure', {
				message: error?.message || 'No error message',
			});
			openErrorResult(error, {
				title: tM('oops'),
				text: error?.message || tC('generic_error_message'),
				type: 'error',
			});
		},
		refetchQueries: [{ query: getAccountDetails }],
	});

	const onSubmit = useCallback(
		(variables: unknown) => {
			mixpanel.track('Submit bank details event', {
				message: 'Submit bank details event',
			});
			return updateAccount({
				variables: variables as UpdateBankDetailsMutationVariables,
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[updateAccount]
	);

	return (
		<Loader isLoading={loading}>
			<BankDetailsForm onSubmit={onSubmit} data={data?.account as any} />
		</Loader>
	);
};

import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, FieldProps } from 'formik';
import { Divider, Grid, MenuItem, Modal, Typography } from '@material-ui/core';
import { useStyles } from "./MapUploadColumns.styles";
import { CustomButton, CustomInput } from 'components';

interface AssetColumns {
  label: string;
  value: string;
}

interface MapUploadColumnsProps {
	matchingColumns: Array<AssetColumns>;
  matchColumnsModalVisible: boolean;
  columnNames: never[];
  isLoading: boolean;
  mappedColumns: Map<any, any>;
  setColumnNames: (name: []) => void;
  setMappedColumns: (columns: any) => void;
  closeModal: () => void;
	setDocument: (file: Blob) => void;
}

export const MapUploadColumns = ({
  closeModal,
  columnNames,
  isLoading,
  mappedColumns,
  matchColumnsModalVisible,
  matchingColumns,
  setColumnNames,
  setMappedColumns,
	setDocument,
}: MapUploadColumnsProps) => {
  const classes = useStyles();
  const rootRef = React.useRef<HTMLDivElement>(null);
  const [headerNames, setHeaderNames] = useState([]);

  useEffect(() => {
    setHeaderNames(columnNames);
  }, [columnNames]);

  const closeHandler = () => {
    setMappedColumns(new Map());
    setHeaderNames([]);
    setColumnNames([]);
    setDocument(new Blob(undefined));
    closeModal();
  };

  const mapColumn = (key: string, value: string) => {
    setMappedColumns((prev: any) => new Map([...prev, [key, value]]))
  };

  const handleChange = (
    column: AssetColumns,
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const target = event.target as HTMLInputElement;
    if (target.value)
      mapColumn(column.value, target.value);
    else {
      mappedColumns.delete(column.value);
      setMappedColumns(mappedColumns);
    }
  };

  const SelectContainer = ((column: AssetColumns): JSX.Element => (
    <>
      <Grid item xs={12} sm={4} md={4} className={classes.gridLabel}>
        <Typography className={classes.label}>{column.label}</Typography>
      </Grid>
      <Grid item xs={12} sm={8} md={8} className={classes.gridField}>
        <Field>
          {({ field: { onBlur }, meta, form: { touched, errors, setFieldValue } }: FieldProps) => {
            return (
              <CustomInput
                className={classes.select}
                name={column.value}
                onBlur={onBlur}
                onChange={select => {
                  handleChange(column, select);
                  setFieldValue(column.value, select.target.value);
                }}
                defaultValue=""
                error={!!errors[column.value] && Boolean(touched[column.value])}
                helperText={
                  <ErrorMessage name={column.value} />
                }
                select
              >
                <MenuItem key="empty" value="">
                  <em>None</em>
                </MenuItem>
                {React.Children.toArray(
                  headerNames.map(name => (
                    <MenuItem value={name}>
                      {name}
                    </MenuItem>
                )))}
              </CustomInput>
            );
          }}
        </Field>
      </Grid>
    </>
  ));

  return (
    <div className={classes.root} ref={rootRef}>
      <Modal
        open={matchColumnsModalVisible}
        onClose={closeHandler}
        className={classes.modal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        container={() => rootRef.current}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <div className={classes.paper}>
          <div id="modal-title" className={classes.title}>Match Columns</div>
          <Divider />
          <div id="modal-description" className={classes.description}>
            To help us understand your data, please match the column names in 
            your file (on the drop down fields) to ours (highlighted in blue).
          </div>
          <Grid container className={classes.container}>
            {headerNames && React.Children.toArray(
              matchingColumns.map(column => SelectContainer(column))
            )}
          </Grid>
          <Divider />
          <Grid container spacing={3} justifyContent="flex-end" className={classes.buttonGroup}>
            <Grid item xs={12} sm={4}>
              <CustomButton
                key="close"
                onClick={closeHandler}
                variant="outlined"
              >
                CLOSE
              </CustomButton>
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomButton
                key="upload"
                type="submit"
                disabled={isLoading}
                disableRipple
              >
                UPLOAD
              </CustomButton>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}

import { Box } from '@material-ui/core';
import { useStyles } from './UserListItem.styles';
import { TransporterControlButtons } from 'containers/admin/transporter-control-buttons';
import { IUserListItem } from 'models';
import { AppPathList } from 'router';
import { getPathWithId } from 'helpers';
import { CustomLink } from 'components';
import Typography from '@material-ui/core/Typography';
import capitalize from 'lodash/capitalize';

export const UserListItem = ({ id, username, ...props }: IUserListItem) => {
	const classes = useStyles();

	const userDetailsPath = getPathWithId(AppPathList.transporterDetails, id || '');

	return (
		<Box className={classes.wrapper} justifyContent="space-between">
			<Typography>
				<CustomLink to={userDetailsPath} className={classes.link}>
					{capitalize(username || '')}
				</CustomLink>
			</Typography>
			<TransporterControlButtons classname={classes.controls} username={username} {...props} />
		</Box>
	);
};

import { useCallback, useState } from 'react';
import {
	useUpdateCompanyDetailsMutation,
	useGetCompanyDetailsQuery,
	UpdateCompanyDetailsMutationVariables,
	Director,
} from 'generate/types';
import { CompanyDetailsForm } from '../CompanyDetailsForm';
import { Loader } from 'components';
import { useResultContext } from 'hooks';
import { getCompanyDetails } from 'graphqlapi';
import { CompanyDetailsFields, CompanyDetailsNames } from 'models';
import { getPaginatedDataNodeList } from 'helpers';

export const UpdateCompanyDetailsForm = () => {
	const [normalizedData, setNormalizedData] = useState<Partial<CompanyDetailsFields>>();
	const { loading } = useGetCompanyDetailsQuery({
		onCompleted: ({ companyDetails }) => {
			const directorNodes = getPaginatedDataNodeList<Director, any>(
				companyDetails?.company?.directors
			);

			const directorDetails = directorNodes.map(
				({ directorName, directorNumber, directorTelNumber, directorResidentialAddress }) => ({
					directorName,
					directorNumber,
					directorTelNumber,
					directorResidentialAddress,
				})
			);

			setNormalizedData({
				...companyDetails,
				[CompanyDetailsNames.directorDetails]: directorDetails,
			} as Partial<CompanyDetailsFields>);
		},
	});
	const { openResultCb } = useResultContext();

	const [updateCompanyDetails] = useUpdateCompanyDetailsMutation({
		onCompleted: openResultCb,
		refetchQueries: [{ query: getCompanyDetails }],
	});

	const handleSubmit = useCallback(
		(variables: UpdateCompanyDetailsMutationVariables) => {
			updateCompanyDetails({ variables });
		},
		[updateCompanyDetails]
	);

	return (
		<Loader isLoading={loading}>
			<CompanyDetailsForm onSubmit={handleSubmit} data={normalizedData} />
		</Loader>
	);
};

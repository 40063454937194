import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { Link, LinkProps } from '@material-ui/core';
import { useMixpanel } from 'analytics';

interface CustomLinkProps extends LinkProps, Pick<RouterLinkProps, 'to'> {
	underline?: 'none' | 'hover' | 'always';
	hideUnderline?: boolean;
	disabled?: boolean;
	to: string;
}

export function CustomLink({
	children,
	underline,
	hideUnderline,
	disabled,
	to,
	...props
}: CustomLinkProps) {
	const mixpanel = useMixpanel();

	return (
		<Link
			{...props}
			to={to}
			component={RouterLink}
			underline={hideUnderline ? 'none' : underline}
			style={{
				pointerEvents: disabled ? 'none' : 'initial',
			}}
			onClick={() => {
				mixpanel.track(`Navigates to ${to}`);
			}}
		>
			{children}
		</Link>
	);
}

import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useReadUserInfo } from 'hooks';
import { AppPathList } from 'router';
import { IRoute } from 'models';
import { checkIsAdmin } from 'helpers';

export interface IPrivateRouteProps extends IRoute, RouteProps {
	Component: React.ElementType;
	path: string;
}

export function PrivateRoute({ Component, path, requiredRoles, ...restProps }: IPrivateRouteProps) {
	const { role, transporter_id } = useReadUserInfo();

	if (requiredRoles?.length && role && !checkIsAdmin(role) && transporter_id) {
		return <Redirect to={AppPathList.account} />;
	}

	if (!transporter_id) {
		return <Redirect to={AppPathList.login} />;
	}

	return <Route path={path} render={() => <Component {...restProps} />} />;
}

import React from 'react';
import { CustomSearch } from '../form-fields';
import { useTranslation } from 'react-i18next';

export interface SearchBlockProps {
	onChangeSearchString: (searchString: string) => void;
	placeholder?: string;
	name?: string;
	className?: string;
}

export const SearchBlock = ({
	onChangeSearchString,
	placeholder,
	className,
	name = 'search',
}: SearchBlockProps) => {
	const { t } = useTranslation('form-fields');

	return (
		<CustomSearch
			className={className}
			placeholder={placeholder || t('search_here')}
			name={name}
			onChangeValue={onChangeSearchString}
			data-qa="qa-search"
		/>
	);
};

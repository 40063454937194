import React, { useMemo } from 'react';
import { Form } from 'formik';
import { CustomInputField, PhoneInputField } from 'containers';
import { AddDriverFomNames } from 'models';
import { formatCamelToSnakeCase } from 'helpers';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../button';
import { Box } from '@material-ui/core';
import {ButtonLink} from "../../custom-link";
import {AppPathList} from "../../../router";
import Typography from "@material-ui/core/Typography";

export const AddDriver = () => {
	const { t } = useTranslation('form-fields');
	const { t: tB } = useTranslation('buttons');

	const fields = useMemo(() => {
		return Object.values(AddDriverFomNames).map((name) => ({
			name,
			label: t(`add_asset.${formatCamelToSnakeCase(name)}`),
		}));
	}, [t]);

	return (
		<Form  className="container-internal">
			<Box mt={2} mb={1}>
				<Typography className="subtitle--large textBlack">
					{t('add_asset.add_driver')}
				</Typography>
			</Box>
			{fields.map((field) =>
				field.name === AddDriverFomNames.driverPhone ? (
					<PhoneInputField key={field.name} {...field} />
				) : (
					<CustomInputField key={field.name} {...field} />
				)
			)}
			<Box mt="auto">
				<CustomButton  className="capitalizeText" type="submit">{tB('submit')}</CustomButton>
			</Box>
			<Box mt={2} paddingBottom={4}>
				<ButtonLink
					to={AppPathList.assetManagement}
					variant="outlined" color="primary"
					autoWidth={true} className="capitalizeText link flexGrow">
					{tB('cancel')}
				</ButtonLink>
			</Box>
		</Form>
	);
};

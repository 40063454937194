import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useMixpanel } from 'analytics';
import { Order } from 'generate/types';
import { formatToDate } from 'helpers';
import { useReadUserInfo } from 'hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DATES } from 'utils';
import { useStyles } from './AvailableJobsModal.styles';
import Button from '@material-ui/core/Button';

interface MatchedAvailableJobsProps {
  item?: Order;
  onEditWorkOrder: (lroId: string) => void;
}

export function MatchedAvailableJobs({
  item,
  onEditWorkOrder,
}: MatchedAvailableJobsProps) {
  const { t } = useTranslation('available');
  const { t: tBtn } = useTranslation('buttons');
  const mixpanel = useMixpanel();

  const { isSA, isEA } = useReadUserInfo();
  const classes = useStyles();

  useEffect(() => {
    mixpanel.track('User arriving to the “Matched Available Jobs” page', {
      message: 'Page renders successfully ',
    });
  }, [mixpanel]);

  return (
    <div className={classes.paper}>
      <div>
        <div className={classes.commodity}>
          <Typography variant='subtitle1' component='p' color='textPrimary'>
            {item?.commodity}
          </Typography>
        </div>
        <div className={classes.priceDetails}>
          <div className={classes.truckDistanceType}>
            <Typography
              variant='subtitle1'
              component='p'
              color='textPrimary'
              className='submitted'
            >
              {item?.currency}
            </Typography>
            <Typography variant='h5' component='h1' color='textPrimary'>
              <strong>
                {' '}
                {item?.isBid ? item?.bidPrice : item?.marketRate}&nbsp;
              </strong>
            </Typography>
            <Typography variant='subtitle1' component='p' color='textPrimary'>
              {isEA && ' + VAT'}&nbsp;
            </Typography>
          </div>
          <div>
            <Typography variant='subtitle1' component='p' color='textPrimary'>
              {item?.transporterRateType}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.deadlineMatchedCard}>
        <Typography variant='subtitle1' component='p' color='inherit'>
          {t('ready_to_load')} :{' '}
          {formatToDate(item?.deadlineToLift, DATES.loadDateFormatNoTime)}
        </Typography>
      </div>
      <Box textAlign='center' mt={2} className='submitted-days' mb='2rem'>
        <Typography variant='body2' component='p' color='inherit'>
          {isSA
            ? t('job_details_note_start_digital_freight')
            : t('job_details_note_start')}
          <strong>
            {' '}
            {item?.transporterPaymentTerms} {t('days')},{' '}
          </strong>
          {t('job_details_note_end')}
        </Typography>
      </Box>
      <div className={classes.btnBottom}>
        <Button
          data-qa="qa-edit-order"
          variant='contained'
          color='secondary'
          onClick={() => {
            onEditWorkOrder(item?.lroId || '');
            mixpanel.track('Edit order clicks', {
              message: 'edit order clicked',
              ID: item?.lroId,
              orderValue: item?.marketRate,
              currency: item?.currency,
            });
          }}
        >
          {tBtn('edit_order')}
        </Button>
      </div>
    </div>
  );
}

import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './UserDetails.styles';
import { CustomAccordion } from 'components/custom-accordion';
import { TransporterAccountList, TransporterDocumentTable } from 'containers';
import { UploadedDocumentsFragment, AccountDetailsFragment } from 'generate/typesAdmin';

interface UserDetailsProps {
	companyName: string;
	companyEmail: string;
	companyMobile: string;
	companyDocs?: UploadedDocumentsFragment | null;
	companyAccount?: AccountDetailsFragment | null;
}

export const UserDetails: React.FC<UserDetailsProps> = ({
	companyName,
	companyEmail,
	companyMobile,
	companyDocs,
	companyAccount,
}) => {
	const { t: tC } = useTranslation();
	const { t } = useTranslation('admin');
	const classes = useStyles();

	return (
		<>
			<Box className={classes.padded}>
				<Typography variant="h3" align="center">
					{companyName}
				</Typography>
				<Box display="flex" justifyContent="space-between" flexWrap="wrap">
					<Box display="flex" whiteSpace="nowrap" alignItems="baseline">
						<Typography variant="h5">{t('company_email')}:&nbsp;</Typography>
						<Typography>{companyEmail}</Typography>
					</Box>
					<Box display="flex" whiteSpace="nowrap" alignItems="baseline">
						<Typography variant="h5">{t('company_mobile')}:&nbsp;</Typography>
						<Typography>{companyMobile}</Typography>
					</Box>
				</Box>
			</Box>
			<CustomAccordion
				detailsFallback={
					<Typography variant="h6" className={classes.padded}>
						{tC('no_data_available')}
					</Typography>
				}
				accordionTabList={[
					{
						details: companyDocs && <TransporterDocumentTable data={companyDocs} />,
						id: '1',
						title: <Typography variant="h5">Documents</Typography>,
					},
					{
						details: companyAccount && <TransporterAccountList data={companyAccount} />,
						id: '2',
						title: <Typography variant="h5">Bank Details</Typography>,
					},
				]}
				accordionProps={{
					square: true,
					elevation: 0,
				}}
			/>
		</>
	);
};

import React, { PropsWithChildren } from 'react';
import { CircularProgress, Box } from '@material-ui/core';
import { ModalWrapper } from 'components';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	root: {
		outline: 'none',
	},
	svg: {
		outline: 'none',
	},
});

export const Loader = ({
	isLoading = false,
	children,
	overflow = false,
}: PropsWithChildren<{
	isLoading?: boolean;
	overflow?: boolean;
}>) => {
	const classes = useStyles();

	if (isLoading && !overflow) {
		return (
			<Box
				width="100%"
				height="100%"
				flexGrow={1}
				display="flex"
				justifyContent="center"
				alignItems="center"
				minHeight={60}
			>
				<CircularProgress
					classes={{
						svg: classes.svg,
						root: classes.root,
					}}
				/>
			</Box>
		);
	}

	return (
		<>
			{children}
			<ModalWrapper
				open={isLoading && overflow}
				withPaper={false}
				BackdropProps={{
					style: {
						backgroundColor: 'rgba(0, 0, 0, 0.2)',
					},
				}}
			>
				<CircularProgress
					classes={{
						svg: classes.svg,
						root: classes.root,
					}}
				/>
			</ModalWrapper>
		</>
	);
};

import { gql } from '@apollo/client';

export const bankDetails = gql`
	fragment bankDetails on Banks {
		id
        uuid
        bankCode
        bankName
        country
        clearingCode
        active
	}
`;

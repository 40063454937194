import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
	layout: {
		display: 'flex',
		flexGrow: 1,
		height: '100%',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		overflowX: 'hidden',
	},
});

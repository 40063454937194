import React, { forwardRef } from 'react';
import { useMixpanel } from 'analytics';
import { Box } from '@material-ui/core';
import { CustomButton } from '../../button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {AppPathList} from "../../../router";

interface FormFooterProps {
	stepCount: number;
	activeStep: number;
	submitBtnText?: string;
	formId?: string;
	setActiveStep?: (n: number) => void;
	text?: Array<{
		question: string;
		link: {
			text: string;
			url: string;
		};
	}>;
}

export const FormFooter = forwardRef<HTMLButtonElement, FormFooterProps>(
	({ stepCount, activeStep, submitBtnText, formId, text, setActiveStep }, ref) => {
		const { t } = useTranslation('buttons');

		const mixpanel = useMixpanel();

		return (
			<Box textAlign="center">
				<Box mt={3}>
					<CustomButton
						ref={ref as any}
						type="submit"
						form={formId}
						color="primary"
						className="capitalizeText"
					>
						{submitBtnText || t('next')}
					</CustomButton>
				</Box>
				<Box mt={3}>
					<Link
						to={AppPathList.login}
						className="link flexGrow"
						onClick={() => {
							mixpanel.track('Bottom navigation', { to: AppPathList.login });
						}}
					>
						<CustomButton color="primary" variant="outlined" autoWidth={true} className="capitalizeText">
							{t('signin')}
						</CustomButton>
					</Link>
				</Box>
			</Box>
		);
	}
);

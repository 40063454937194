import React from 'react';
import { JobFilter } from 'models';
import { useStyles } from './JobSearchBlock.styles';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CargoType } from 'generate/types';
import { FilterCargoTypeButton } from 'components/filter-cargo-type-button';
import { SearchBlock } from 'components/search-block';
import { useMixpanel } from 'analytics';
import { useLocation } from 'react-router-dom';
interface JobSearchBlockProps {
	filter: JobFilter;
	onChangeSearchString: (s: string) => void;
	onChangeCargoType: (type: CargoType) => void;
	handleClearFilters: () => void;
}

export const JobSearchBlock = ({
	filter,
	onChangeSearchString,
	onChangeCargoType,
	handleClearFilters,
}: JobSearchBlockProps) => {
	const { t } = useTranslation('form-fields');
	const classes = useStyles();
	const { pathname } = useLocation();
	const jobType = pathname?.split('/')?.reverse()?.join(' ')?.trim() || 'Job type';
	const mixpanel = useMixpanel();

	return (
		<Box display="flex">
			<SearchBlock
				placeholder={t('search')}
				className={classes.input}
				name="search"
				onChangeSearchString={(s: string) => {
					onChangeSearchString(s);
				}}
			/>
			<FilterCargoTypeButton
				title="Filter"
				currentType={filter.cargoType}
				onChange={(type) => {
					onChangeCargoType(type);
					mixpanel.track(`Filter ${jobType} jobs`, {
						string: filter?.searchStr,
						cargoType: type,
						pathname,
					});
				}}
				handleClearFilters={handleClearFilters}
			/>
		</Box>
	);
};

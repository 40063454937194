import React, {useState, useEffect} from 'react';
import {useReactPWAInstall} from 'react-pwa-install';
import quickTransportSrc from 'assets/images/quick_transport_logo.svg';
import {useStyles} from "./PwaUserPrompt.styles";
import {Box, Card} from "@material-ui/core";
import { getFromLocalStorage, saveToLocalStorage } from 'helpers';
import {CustomButton} from "../button";
import {useTranslation} from "react-i18next";
import {CloseIcon} from "../icons";


export const PWAUserPrompt = () => {
    const {pwaInstall, supported, isInstalled} = useReactPWAInstall();
    const pwaPromptKey = "pwaDeclined";
    const classes = useStyles();
    const [showInstallPrompt, setShowInstallPrompt] = useState(true);
    const [userDeclinedInstallPrompt, setUserDeclinedInstallPrompt] = useState(false);
    const {t} = useTranslation('buttons');
    useEffect(() => {
        if (getFromLocalStorage(pwaPromptKey) === "1"){
            setUserDeclinedInstallPrompt(true)
        }
        const handleAppInstalled = () => {
            setShowInstallPrompt(false);
        }
        window.addEventListener('appinstalled', handleAppInstalled);
        return () => {
            window.removeEventListener('appinstalled', handleAppInstalled);
        };
    }, []);

    const handleClick = () => {
        pwaInstall({
            logo: quickTransportSrc,
            title: "LTA",
            description: "Install the Lori Transporters Application for easy access",
        })
    };
    const handleDismiss = () => {
        setShowInstallPrompt(false);
    };
    const handleDecline = () => {
        saveToLocalStorage(pwaPromptKey, "1")
        setShowInstallPrompt(false);
    };

    return (
        <div>
            {supported() && !userDeclinedInstallPrompt && !isInstalled() && showInstallPrompt && (
                <Card className={classes.card} elevation={8}>
                    <Box display="flex" flexDirection="column" padding={.8} alignItems="end">
                        <CloseIcon className={classes.closeIcon} onClick={handleDismiss}/>
                        <CustomButton color="primary" className="capitalizeText"
                                      onClick={handleClick}>
                            {t('add_to_home_screen')}
                        </CustomButton>
                        <CustomButton color="primary" variant="text" className="capitalizeText" autoWidth={true}
                                      onClick={handleDecline}>
                            {t('dont_prompt')}
                        </CustomButton>
                    </Box>
                </Card>
            )}
        </div>
    );
};
import { useMemo } from 'react';
import { Loader, MyJobDetailsConfirmed as MyJobDetailsConfirmedTemp } from 'components';
import { useModalStateControls } from 'hooks';
import { useParams } from 'react-router-dom';
import { ConfirmedJobDetailsFragment } from 'generate/types';
import { formatToDate, mapToDetailsList } from 'helpers';
import { JobCategory, useGetWorkOrderQuery } from 'generate/types';

const confirmedDetailsList: Array<keyof ConfirmedJobDetailsFragment> = [
	'moveId',
	'truckType',
	//'category',
	'cargoType',
	'commodity',
	'weight',
	'readyToLoad',
	//'loadingDeadline',
];

export const MyJobDetailsConfirmed = () => {
	const { isOpened, toggleModal } = useModalStateControls();
	const { id } = useParams<{ id: string }>();

  const { data, loading } = useGetWorkOrderQuery({
    variables: {
      jobCategory: JobCategory.Confirmed,
      moveId: parseInt(id)
    },
  });

	const detailsListData = useMemo(() => {
		return mapToDetailsList(data?.workOrder as ConfirmedJobDetailsFragment, confirmedDetailsList, {
			readyToLoad: formatToDate,
		});
	}, [data]);

	return (
		<Loader isLoading={loading}>
      {/* @ts-ignore */}
			<MyJobDetailsConfirmedTemp
				detailsList={detailsListData as Record<string, unknown>}
				{...data?.workOrder}
				isOpened={isOpened}
				toggleModal={toggleModal}
			/>
		</Loader>
	);
};

import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils';

export const useStyles = makeStyles({
	btn: {
		height: '40px',
		textTransform: 'capitalize',
		fontSize: 14,
		fontWeight: 500,
	},
	confirmFuelRequest: {
		fontSize: 15,
		fontWeight: 500,
		textAlign: 'start',
		marginBottom: 2
	},
	modalDismissButton: {
		marginTop: 20,
		borderColor: COLORS.warning,
		color: COLORS.warning,
		'&:hover': {
			borderColor: COLORS.warning,
		},
	},
	confirmFuelRequestDialog: {
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		backdropFilter: 'blur(1px)',
	},
});

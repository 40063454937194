import React, { useMemo } from 'react';
import { FieldTypes, FormIdList, FormStepTempProps, InsuranceFormNames } from 'models';
import { Form, Formik } from 'formik';
import { CustomCheckboxField, CustomInputField } from 'containers';
import { formatCamelToSnakeCase } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../SignUpForm.styles';
import { Box } from '@material-ui/core';
import { CustomButton } from 'components/button';
import { useReadUserInfo } from 'hooks';
import { CreateInsuranceMutationVariables } from 'generate/typesAuth';

interface InsuranceFormProps extends FormStepTempProps<CreateInsuranceMutationVariables> {}

export const InsuranceForm = ({
	onSubmit,
	validationSchema,
	initialValues,
}: InsuranceFormProps) => {
	const { t } = useTranslation('form-fields');
	const { t: tB } = useTranslation('buttons');
	const { user_id } = useReadUserInfo();
	const classes = useStyles();

	const fields = useMemo(() => {
		const checkboxFields = [
			InsuranceFormNames.hasComprehensiveGitCover,
			InsuranceFormNames.hasPublicLiabilityInsurance,
		];

		return Object.values(InsuranceFormNames).map((name) => ({
			name,
			type: checkboxFields.includes(name) ? FieldTypes.checkbox : FieldTypes.text,
		}));
	}, []);

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validationSchema}
			enableReinitialize
		>
			<Form id={FormIdList.insuranceDetails} className={classes.formWrapper}>
				{fields.map(({ name, type }) =>
					type === FieldTypes.checkbox ? (
						<CustomCheckboxField
							labelClassname={classes.fullWidth}
							key={name}
							name={name}
							label={t(`sign_up.${formatCamelToSnakeCase(name)}`)}
						/>
					) : (
						<CustomInputField
							key={name}
							name={name}
							label={t(`sign_up.${formatCamelToSnakeCase(name)}`)}
						/>
					)
				)}
				{user_id && (
					<Box display="flex" marginY={2} justifyContent="center">
						<CustomButton type="submit">{tB('submit')}</CustomButton>
					</Box>
				)}
			</Form>
		</Formik>
	);
};

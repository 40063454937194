import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils';

export const useStyles = makeStyles({
	name: {
		fontWeight: 600,
		color: COLORS.primary,
		textTransform: 'capitalize',
	},
	btn: {
		marginLeft: 'auto',
		width: 'auto',
		textTransform: 'capitalize',
		fontWeight: 600,
	},
	linkIcon: {
		fontSize: 16
	},
	title: {
		fontSize: 24,
		fontWeight: 500,
		color: COLORS.textBlack,
	},
	subTitle: {
		fontSize: 20,
		fontWeight: 500,
		color: COLORS.textBlack,
	},
	titleSpacing: {
		marginTop: 20,
		marginBottom: 10,
	},
	needHelpBox: {
		background: '#FFC46C',
		padding: 5,
		borderBottomRightRadius: 5,
		borderBottomLeftRadius: 5,
		borderTopLeftRadius: 5,
		boxShadow: '-1px 2px 3px rgba(0, 0, 0, 0.15)'
	},
	needHelpText: {
		fontSize: 12,
		fontWeight: 600,
		color: "#FFFFFF",
		marginLeft: 4
	},
});

import React, { useMemo, useState } from 'react';
import { ErrorMessage, FastField, Field, FieldProps } from 'formik';
import {
	DatePicker,
	DatePickerProps,
	DateTimePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { QaType } from 'models';
import { makeStyles } from '@material-ui/core';
import calendarIconSrc from 'assets/icons/calendar.png';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DATES } from 'utils';

const useStyles = makeStyles({
	input: {
		paddingRight: 2,
	},
	icon: {
		cursor: 'pointer',
		marginRight: '1rem',
	},
});

interface CustomDatePickerFieldProps extends Partial<DatePickerProps> {
	qaType?: QaType;
	fast?: boolean;
	onlyYupValidation?: boolean;
	withTime?: boolean;
}

export const CustomDatePickerField = ({
	fast,
	onlyYupValidation,
	name = '',
	disablePast = true,
	disableFuture = false,
	withTime,
	...props
}: CustomDatePickerFieldProps) => {
	const { t } = useTranslation('form-fields');
	const classes = useStyles();

	const Wrapper = useMemo(() => (fast ? FastField : Field), [fast]);
	const [selectedDate, setSelectedDate] = useState(null);
	const handleDateChange = (date: any) => {
		setSelectedDate(date);
	};
	const Picker = useMemo(() => (withTime ? DateTimePicker : DatePicker), [withTime]);
	const format = useMemo(
		() => (withTime ? DATES.dateTimeInputFormat : DATES.dateInputFormat),
		[withTime]
	);

	return (
		<Wrapper name={name}>
			{({
				field,
				meta: { error, touched },
				form: { setFieldError, setFieldValue },
			}: FieldProps) => {
				return (
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<Picker
							key={field.value}
							autoOk
							clearable
							disablePast={disablePast}
							disableFuture={disableFuture}
							fullWidth
							showTodayButton={false}
							margin="normal"
							inputVariant="outlined"
							format={format}
							error={!!error && touched}
							helperText={<ErrorMessage name={name} />}
							onError={(e) => {
								if (e !== error && !onlyYupValidation) {
									setFieldError(field.name, e as string);
								}
							}}
							InputProps={{
								className: classes.input,
								endAdornment: (
									<img
										src={calendarIconSrc}
										alt={t('calendar')}
										width={15}
										className={classes.icon}
									/>
								),
							}}
							{...field}
							{...props}
							value={selectedDate}
							onChange={(date: MaterialUiPickersDate) => {
								setFieldValue(field.name, withTime ? date?.toISOString() : date);
								handleDateChange(date);
							}}
							inputProps={{
								// @ts-ignore
								'qa-type': 'text',
							}}
							DialogProps={{}}
						/>
					</MuiPickersUtilsProvider>
				);
			}}
		</Wrapper>
	);
};

import { IAppPathList } from '../models';
import { AppRouteNameList } from './AppRouteNameList';
import { ROUTING } from 'utils';

export const AppRoutePathList: IAppPathList = {
	[AppRouteNameList.root]: '',

	// Google Login Page
	[AppRouteNameList.oAuthlogin]: '/auth/login',

	// Login Page
	[AppRouteNameList.login]: '/login',
	[AppRouteNameList.signUp]: '/signup',
	[AppRouteNameList.resetPassword]: '/reset-password',
	[AppRouteNameList.forgetPassword]: '/forget-password',

	// Available Jobs Page
	[AppRouteNameList.available]: '/available',
	[AppRouteNameList.availableNew]: '/new',
	[AppRouteNameList.availableSubmitted]: '/submitted',
	[AppRouteNameList.availableMatched]: '/matched',

	[AppRouteNameList.availableDetails]: ROUTING.detailsPath,
	[AppRouteNameList.availableSubmittedDetails]: ROUTING.detailsPath,
	[AppRouteNameList.availableMatchedDetails]: ROUTING.detailsPath,

	[AppRouteNameList.submitTruck]: '/submit',
	[AppRouteNameList.suggestPrice]: '/bid',

	// My Jobs Page
	[AppRouteNameList.jobs]: '/jobs',
	[AppRouteNameList.jobsConfirmed]: '/confirmed',
	[AppRouteNameList.jobsConfirmedDetails]: ROUTING.detailsPath,
	[AppRouteNameList.jobsActive]: '/onjourney',
	[AppRouteNameList.jobsActiveDetails]: ROUTING.detailsPath,
	[AppRouteNameList.jobsCompleted]: '/completed',
	[AppRouteNameList.jobsCompletedDetails]: ROUTING.detailsPath,

	[AppRouteNameList.auxiliaryServices]: '/services',
	[AppRouteNameList.requestFuel]: '/fuel',
	[AppRouteNameList.addLocation]: ROUTING.addLocationPath,
	[AppRouteNameList.previousLocations]: ROUTING.previousLocations,

	// Payment Page
	[AppRouteNameList.payment]: '/payment',

	// More Page
	[AppRouteNameList.account]: '/account',
	[AppRouteNameList.availableTrucks]: '/trucks',
	[AppRouteNameList.bankDetails]: '/bank-details',
	[AppRouteNameList.updateBankDetails]: '/update-bank-details',
	[AppRouteNameList.assetManagement]: '/asset-management',
	[AppRouteNameList.addTruck]: '/add-truck',
	[AppRouteNameList.addDriver]: '/add-driver',
	[AppRouteNameList.addTrailer]: '/add-trailer',
	[AppRouteNameList.documents]: '/documents',

	// Admin Page
	[AppRouteNameList.admin]: '/admin',

	[AppRouteNameList.transporters]: '/transporters',
	[AppRouteNameList.newTransporters]: '/new',
	[AppRouteNameList.activeTransporters]: '/active',
	[AppRouteNameList.externalTransporters]: '/external',

	[AppRouteNameList.assets]: '/assets',
	[AppRouteNameList.trailers]: '/trailers',
	[AppRouteNameList.drivers]: '/drivers',
	[AppRouteNameList.trucks]: '/trucks',

	[AppRouteNameList.notifications]: '/notifications',

	[AppRouteNameList.transporterDetails]: ROUTING.detailsPath,
};

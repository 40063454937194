import { makeStyles } from '@material-ui/core';
import { COLORS, COMMON } from 'utils';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
	item: {
		border: `1px solid ${COLORS.white}`,
		backgroundColor: COLORS.white,
		borderRadius: 3,
		display: 'flex',
		justifyContent: 'space-between',
		padding: '8px 4px',
		fontSize: 12,
		fontWeight: 400,
		lineHeight: '14.4px',
		'& + &': {
			marginTop: 8,
		},
	},
	label: {
		color: COLORS.grey3,
		fontSize: 12,
		fontWeight: 400,
		lineHeight: '14.4px'
	},
	value: {
		color: COLORS.primary,
		textAlign: "right",
		fontSize: 12,
		fontWeight: 400,
		lineHeight: '14.4px'
	},
});

interface DetailsListItemProps {
	name: string;
	value?: unknown;
}

export function DetailsListItem({ name, value }: DetailsListItemProps) {
	const classes = useStyles();

	return (
		<li className={classes.item}>
			<Typography variant="body2" className={classes.label}>{name}:</Typography>
			<Typography variant="body2" className={classes.value}>
				{(value as string) || COMMON.noDataProvided}
			</Typography>
		</li>
	);
}

import { makeStyles } from '@material-ui/core';
import { COLORS, STYLES } from 'utils';

export const useStyles = makeStyles({
	container: {
		flexDirection: 'column',
	},
	header: {
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'space-between',
		color: '#FFFFFF',
	},
	headerName: {
		fontSize: 14,
	},
	headerGreeting: {
		fontSize: 19,
		fontWeight: STYLES.bold,
	},
	headerInfo: {
		display: 'flex',
		alignItems: 'center',
	},
	avatar: {
		marginLeft: 10,
	},
	adminHeader: {
		borderBottom: `1px solid ${COLORS.gray}`,
	},
});

import React, { useCallback } from 'react';
import { CustomInputFieldContainerProps, CustomUploadField } from './CustomUploadField';
import { authContext } from 'utils';
import { useUploadDocumentsMutation, UploadDocumentsMutationVariables } from 'generate/typesAuth';
import { useReadUserInfo, useResultContext } from 'hooks';
import { state } from 'state';
import { useMixpanel } from 'analytics';

export function CustomUploadFieldLta(props: CustomInputFieldContainerProps) {
	// Data for registered user on Account management page
	const { company_id } = useReadUserInfo();
	// Data for new user on Sign Up flow
	const { companyId } = state;
	const mixpanel = useMixpanel();

	const { openErrorResult } = useResultContext();

	const [uploadDocuments, { loading }] = useUploadDocumentsMutation({
		context: authContext,
		onCompleted: () => {
			mixpanel.track('Documents upload on the completed jobs tab (Success)');
		},
		onError: (error) => {
			mixpanel.track('Documents upload on the completed jobs tab (Failure)', { error });
			openErrorResult();
		},
	});

	const handleUpload = useCallback(
		(name: keyof UploadDocumentsMutationVariables, data: File | FileList) =>
			uploadDocuments({
				variables: {
					companyId: companyId || (company_id as number),
					[name]: data,
				},
			}),
		[uploadDocuments, company_id, companyId]
	);

	return <CustomUploadField {...props} onUpload={handleUpload} isUploading={loading} />;
}

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
	listContainer: {
		overflow: 'hidden auto'
	},
    list: {
		height: '100%',
		marginTop: '1rem',
        paddingRight: '3px',
        paddingLeft: '3px',
	},
	listItem: {
		marginBottom: '1rem',
		backgroundColor: '#fff',
	},
    caption: {
        color: '#9B9B9B',
        display: 'flex',
        fontSize: 12,
        fontWeight: 600,
        justifyContent: 'center',
        letterSpacing: 0.4,
        marginTop: 12,
    },
    container: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 111,
    },
    imgContainer: {
        background: '#FFF6E8',
        borderRadius: 541.62,
        height: 118,
        overflow: 'hidden',
        width: 118,
    },
    truckBg: {
        marginTop: 41,
        right: '0.49%',
    },
});

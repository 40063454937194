import { makeStyles } from '@material-ui/core';
import { theme } from 'utils';

export const useStyles = makeStyles({
	bg: {
		backgroundColor: '#FFFFFF',
	},
	container: {
		flexGrow: 1,
		height: '100%',
		flexDirection: 'column',
	},
	content: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',
	},
	swiper: {
		width: '100%',
		height: '100%',
		textAlign: 'center',
		display: 'flex',
		justifyContent: 'center',
	},
	slide: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
	imgWrapper: {
		display: 'flex',
		justifyContent: 'center',
		flexGrow: 1,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		marginTop: theme.spacing(5),
		minHeight: 250,
	},
	skipBtn: {
		position: 'absolute',
		top: 0,
		right: 0,
		width: 'auto',
	},
	title: {
		marginTop: theme.spacing(5),
	},
	stepper: {
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(5),
	},
});

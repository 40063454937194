import React from 'react';
import cn from 'classnames';
import { IIconProps } from './IIconProps';

export const DeleteIcon = ({ className, title = '', pointer }: IIconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="Delete-icon"
			viewBox="0 0 32 32"
			className={cn('icon', className, {
				pointer: pointer,
			})}
		>
			<title>{title}</title>
			<path d="M22.25 1.75h6.167v3.583h-24.833v-3.583h6.167l1.833-1.75h8.833zM8.917 10.667v17.75h14.167v-17.75h-14.167zM5.333 28.417v-21.333h21.333v21.333q0 1.417-1.083 2.5t-2.5 1.083h-14.167q-1.417 0-2.5-1.083t-1.083-2.5z" />
		</svg>
	);
};

import { gql } from '@apollo/client';

export const createIssue = gql`
  mutation createIssue(
    $assignee: String
    $businessDomainId: Int!
    $details: String
    $moveId: String!
    $reportedAt: DateTime
    $typeId: Int!
    $userId: String
    $userName: String
  ) {
    createIssue(
      issue: {
        assignee: $assignee
        businessDomainId: $businessDomainId
        details: $details
        moveId: $moveId
        reportedAt: $reportedAt
        typeId: $typeId
        userId: $userId
        userName: $userName
      }
    ) {
      message
      status
    }
  }
`;

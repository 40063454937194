import { gql } from '@apollo/client';

export const bulkUploadTrailers = gql`
	mutation bulkUploadTrailers($file: Upload!, $transporterId: Int!, $country: String!, $source: Source!) {
		bulkUploadTrailers(file: $file, transporterId: $transporterId, country: $country, source: $source) {
			status
			message
		}
	}
`;

import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { JobFilter } from 'models';
import { MoveTypeFilter, JobSearchBlock } from 'components';
import { MoveType, CargoType } from 'generate/types';

const useStyles = makeStyles({
	wrapper: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	type: {
		textAlign: 'center',
	},
});

interface JobListWithFilterProps {
	ListToRender: React.ElementType;
	withMoveType?: boolean;
}

export const JobListWithFilter = ({
	ListToRender,
	withMoveType = true,
}: JobListWithFilterProps) => {
	const classes = useStyles();

	const [filter, setFilter] = useState<JobFilter>(() => ({
		moveType: withMoveType ? MoveType.Local : undefined,
	}));

	const [searchString, setSearchString] = useState<string>();

	const handleChangeMoveType = useCallback(
		(moveType: MoveType) =>
			setFilter({
				...filter,
				moveType,
			}),
		[filter]
	);

	const handleChangeCargoType = useCallback(
		(cargoType: CargoType) =>
			setFilter({
				...filter,
				cargoType,
			}),
		[filter]
	);

	const handleClearFilters = () =>
		setFilter({ moveType: withMoveType ? MoveType.Local : undefined });

	return (
		<div className={classes.wrapper}>
			{withMoveType && (
				<MoveTypeFilter activeMoveType={filter?.moveType} onChange={handleChangeMoveType} />
			)}
			<JobSearchBlock
				filter={{ ...filter, searchStr: searchString }}
				onChangeSearchString={setSearchString}
				onChangeCargoType={handleChangeCargoType}
				handleClearFilters={handleClearFilters}
			/>
			<ListToRender filter={{ ...filter, searchStr: searchString }} />
		</div>
	);
};

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import clsx from 'clsx';
import { ErrorMessage, Field, FieldProps } from 'formik';
import { useStyles } from './styles';
import {InputLabel} from "@material-ui/core";

interface CustomTextFieldProps {
	name: string;
	placeholder?: string;
	label?: string;
	autoComplete?: string;
}

export function CustomPasswordField({
	name = '',
	placeholder = '',
	label,
	autoComplete = 'current-password',
	...props
}: CustomTextFieldProps) {
	const classes = useStyles();
	const [showPassword, setShowPassword] = React.useState<boolean>(false);

	const handleClickShowPassword = () => {
		setShowPassword((showPassword) => !showPassword);
	};

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	return (
		<Field name={name}>
			{({ field, meta }: FieldProps) => {
				const error = !!meta.error && meta.touched;
				return (
					<FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
						<InputLabel htmlFor={`input-${name}`}>{label}</InputLabel>
						<OutlinedInput
							{...field}
							{...props}
							id={`input-${name}`}
							type={showPassword ? 'text' : 'password'}
							placeholder={placeholder}
							required
							className={classes.root}
							error={error}
							autoComplete={autoComplete}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
							label={label}
						/>
						<FormHelperText error={error}>
							{error && (
								<ErrorMessage name={name} />
							)}
						</FormHelperText>
					</FormControl>
				);
			}}
		</Field>
	);
}

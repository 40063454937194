import React, { useCallback, useEffect } from 'react';
import { CreateAccountMutationVariables, useCreateAccountMutation } from 'generate/typesAuth';
import { authContext } from 'utils';
import { state } from 'state';
import { useLoaderContext, useResultContext } from 'hooks';
import { BankDetailsForm } from '../BankDetailsForm';
import { BankDetailsFields, FormStepProps } from 'models';
import { useMixpanel } from 'analytics';
import { useTranslation } from 'react-i18next';

interface CreateBankDetailsFormProps extends FormStepProps {}

export const CreateBankDetailsForm = ({ afterSubmit }: CreateBankDetailsFormProps) => {
	const { openErrorResult } = useResultContext();
	const { setIsLoading } = useLoaderContext();
	const { t: tM } = useTranslation('modals');
	const { t: tC } = useTranslation('common');
	const mixpanel = useMixpanel();

	const [postAccount, { loading }] = useCreateAccountMutation({
		context: authContext,
		onCompleted: () => {
			afterSubmit();
			mixpanel.track('Create bank account success', {
				message: 'Create bank account successfully',
			});
		},
		onError: (error) => {
			openErrorResult(error, {
				title: tM('oops'),
				text: error?.message || tC('generic_error_message'),
				type: 'error',
			});
			mixpanel.track('Create bank account failure', {
				message: error?.message || 'No error message',
			});
		},
	});

	useEffect(() => {
		setIsLoading(loading);
	}, [loading, setIsLoading]);

	const onSubmit = useCallback(
		(variables: unknown) => {
			// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
			const { terms, ...bankData } = variables as BankDetailsFields;

			const isEmpty = !Object.values(bankData).some(Boolean);

			if (isEmpty && afterSubmit) {
				afterSubmit();
			} else {
				postAccount({
					variables: {
						// @ts-ignore
						companyId: state.companyId,
						...(variables as CreateAccountMutationVariables),
					},
				});
			}
		},
		[afterSubmit, postAccount]
	);

	return <BankDetailsForm onSubmit={onSubmit} isNewUser />;
};

import { useEffect, useState } from 'react';
import {Box, Typography} from '@material-ui/core';
import { SplashScreenModal } from 'components';
import { CustomButton } from 'components/button';
import { CustomTextField, CustomPasswordField } from 'containers';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useEmailLoginLazyQuery } from 'generate/typesAuth';
import { getRequiredMessage, isEmail } from 'helpers';
import { useLoaderContext, useResultContext } from 'hooks';
import { LoginFormData, LoginFormNames, PasswordFormNames } from 'models';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppPathList } from 'router';
import { API, REGULARS, theme } from 'utils';
import * as Yup from 'yup';
import { useStyles } from '../ExternalForm.styles';
import { useMixpanel } from 'analytics';

export const LoginForm = () => {
	// -------------------------- Custom hooks ---------------------------------//
	const history = useHistory();
	const { openErrorResult } = useResultContext();
	const { setIsLoading } = useLoaderContext();
	const mixpanel = useMixpanel();
	// -------------------------- Transalation ---------------------------------//

	const { t } = useTranslation('login');
	const { t: tE } = useTranslation('errors');
	const { t: tM } = useTranslation('modals');

	// -------------------------- State manager --------------------------------//

	const [showSplashScreen, setShowSplashScreen] = useState(false);
	const [email, setEmail] = useState('');

	// -------------------------- styles --------------------------------//

	const classes = useStyles();

	// ---------------------------- Mixpanel -----------------------------------//
	useEffect(() => {
		mixpanel.track('Login page render (User arriving to LTA login page)', {
			message: 'Login page renders successfully ',
		});
	}, [mixpanel]);

	// ---------------------------- GQL Query -----------------------------------//
	const [emailLoginQuery, { loading }] = useEmailLoginLazyQuery({
		context: {
			clientName: API.LTAAuthClient.contextName,
		},
		onCompleted: ({ emailLogin }) => {
			mixpanel.identify(email);
			mixpanel.track('Login success (authentification success)', {
				message: 'Signin successfully',
			});
			const { first_login } = emailLogin || {};
			if (first_login) {
				setShowSplashScreen(true);
			} else {
				history.push(AppPathList.availableNew);
			}
		},
		onError: (error) => {
			mixpanel.track('Login failure', {
				errorMessage: error?.message || 'No error message',
			});
			openErrorResult(error, {
				title: tM('oops'),
				text: error?.message || '',
				type: 'error',
			});
		},
	});

	// ---------------------- Side Effects Handlers ----------------------------//
	useEffect(() => {
		setIsLoading(loading);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	// ---------------------- Form Validation ----------------------------//

	const validationSchema = Yup.object({
		[LoginFormNames.login]: Yup.lazy((value) => {
			if (typeof value === 'string' && isEmail(value)) {
				return Yup.string()
					.trim()
					.email()
					.required(getRequiredMessage(tE, t('email')));
			} else if (value) {
				return Yup.string().matches(REGULARS.phoneNumber, tE('invalid_value'));
			}
			return Yup.mixed().required(getRequiredMessage(tE, t('email')));
		}),
		[PasswordFormNames.password]:
			Yup.string().required(getRequiredMessage(tE, t('password'))) || Yup.string(),
	});

	// ---------------------- Events Handlers ----------------------------//

	const handleSubmit = (values: LoginFormData) => {
		const email = values.login?.trim();
		if (email) {
			setEmail(email);
			const variables = {
				email,
				password: values.Password,
			};
			emailLoginQuery({ variables });
		}
	};

	return (
		<>
			<main className={classes.main}>
				<div className={classes.form}>
					<div>
						<Typography variant="h5" component="h5" align="left" className="textPrimary subtitle">
							{t('welcome_back')}
						</Typography>
						<Typography variant="body1" align="left" className="textSecondary text-medium">
							{t('login_to_view_orders')}
						</Typography>
					</div>
					<Box maxWidth={theme.breakpoints.values.sm} marginX="auto" marginTop={3}>
						<Formik initialValues={{}} onSubmit={handleSubmit} validationSchema={validationSchema}>
							<Form>
								<CustomTextField
									name={LoginFormNames.login}
									label={LoginFormNames.email}
									labelWidth={110}
									placeholder={t('login_placeholder')}
									type="email"
									autoComplete="username"
								/>
								<CustomPasswordField
									name={PasswordFormNames.password}
									label={PasswordFormNames.password}
									placeholder={t('password_placeholder')}
									autoComplete="current-password"
								/>
								<Box textAlign="center">
									<Box mt={2} ml="1rem" display="flex" justifyContent="right">
										<Link
											to={AppPathList.forgetPassword}
											className="link"
											onClick={() => {
												mixpanel.track('Forgot Password button clicked');
											}}
										>
											<Typography className="text-medium">{t('forgot_password')}</Typography>
										</Link>
									</Box>
									<Box mt={3}>
										<CustomButton type="submit" color="primary"  className="capitalizeText">
											{t('signin')}
										</CustomButton>
									</Box>
									<Box mt={2} display="flex" justifyContent="center">
										<Link
											to={AppPathList.signUp}
											className="link flexGrow"
											onClick={() => {
												mixpanel.track('Create account button clicked');
											}}
										>
											<CustomButton color="primary" variant="outlined" autoWidth={true} className="capitalizeText">
												{t('create_account')}
											</CustomButton>
										</Link>
									</Box>
								</Box>
							</Form>
						</Formik>
					</Box>
				</div>
			</main>
			{showSplashScreen && (
				<SplashScreenModal onClose={() => history.push(AppPathList.availableNew)} />
			)}
		</>
	);
};

import React from 'react';
import { ModalWrapper } from '../ModalWrapper';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './ConfirmFuelRequest.styles';
import Box from '@material-ui/core/Box';
import { CustomButton } from '../../button';
import cn from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DetailsListItem } from '../../details-list-item';

interface ConfirmFuelRequestProps {
	open: boolean;
	onConfirmFuelRequest: () => void;
	onClose: () => void;
	loading: boolean;
	fuelRequest: Object | undefined;
}

export const ConfirmFuelRequest = ({
	open,
	onConfirmFuelRequest,
	onClose,
	loading,
	// @ts-ignore
	fuelRequest: { fuelRequestAmount, fuelingStation, fuelType, fuelreqtra, move },
}: ConfirmFuelRequestProps) => {
	const { t } = useTranslation('fuel-order');
	const { t: tF } = useTranslation('form-fields');
	const { t: tB } = useTranslation('buttons');
	const classes = useStyles();

	return (
		<ModalWrapper open={open} onClose={onClose} withCloseBtn={false}>
			<>
				<Typography className={classes.confirmFuelRequest}>
					{t('confirm_fuel_request_details')}
				</Typography>
				<DetailsListItem name={t('commodity')} value={move?.commodity} />
				<DetailsListItem name={t('from')} value={move?.origin} />
				<DetailsListItem name={t('to')} value={move?.destination} />
				<DetailsListItem
					name={tF('request_fuel.amount')}
					value={`${fuelRequestAmount} ${t('litres')}`}
				/>
				<DetailsListItem
					name={tF('request_fuel.fueling_point')}
					value={fuelingStation?.node.name}
				/>
				<DetailsListItem name={tF('request_fuel.fuel_type')} value={fuelType} />
				<DetailsListItem name={tF('request_fuel.date')} value={fuelreqtra} />

				<Box display="flex" flexDirection="column" marginTop={2}>
					{loading && (
						<Box textAlign="center" marginX="auto" marginBottom={2}>
							<CircularProgress />
						</Box>
					)}
					<CustomButton
						className={cn(classes.btn)}
						data-qa="qa-proceed"
						type="submit"
						onClick={onConfirmFuelRequest}
						disabled={loading}
					>
						{tB('confirm')}
					</CustomButton>
					<CustomButton
						className={cn(classes.btn, classes.modalDismissButton)}
						onClick={onClose}
						disabled={loading}
						data-qa="qa-close"
						variant="outlined"
					>
						{t('edit_fuel_request')}
					</CustomButton>
				</Box>
			</>
		</ModalWrapper>
	);
};

import { CustomButton } from 'components';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AddAssetProps } from 'models';

interface TruckListTempProps extends AddAssetProps {}

export const TruckListTempWA = ({ onAddAsset }: TruckListTempProps) => {
	const { t } = useTranslation('buttons');

	return (
		<>
			<Box mt={2} textAlign="center" style={{ width: '100%' }}>
				<CustomButton onClick={onAddAsset} variant="outlined">
					{t('add_trucks')}
				</CustomButton>
			</Box>
		</>
	);
};

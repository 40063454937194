import {useStyles} from './DriverCard.styles';
import {useTranslation} from 'react-i18next';
import {Driver} from 'generate/typesSupplyService';
import Typography from '@material-ui/core/Typography';
import {Box} from "@material-ui/core";
import React from "react";

export const DriverCard = ({names, idNumber, phone, active}: Driver) => {
    const {t} = useTranslation('driver');
    const classes = useStyles();
    const status = active === true || active === null;
    return (
        <ul className={classes.card}>
            <li className={classes.item}>
				<span>
					<Box display="flex" flexDirection="column">
						<Typography className={classes.title} variant="caption" component="p">
							{t('driver_name')}
						</Typography>
						<Typography className={classes.value} variant="body1" component="p">
							{names}
						</Typography>
					</Box>
					<Box display="flex" flexDirection="row" alignItems="center">
						<Typography className={classes.itemInfoLabel} color="inherit" variant="caption" component="p">
							{t('id_number')}
						</Typography>
						<Typography className={classes.itemInfoText} color="textPrimary" variant="body1" component="p">
							{idNumber}
						</Typography>
					</Box>
					<Box display="flex" flexDirection="row" alignItems="center">
						<Typography className={classes.itemInfoLabel} color="inherit" variant="caption" component="p">
							{t('phone_number')}
						</Typography>
						<Typography className={classes.itemInfoText} color="textPrimary" variant="body1" component="p">
							{phone}
						</Typography>
					</Box>
				</span>
                <Box className={classes.status} display="flex" flexDirection="column" alignItems="center">
                    <Typography className={classes.statusText} variant="body1" component="p">
                        {status ? 'Active' : 'Not Active'}
                    </Typography>
                    <span
                        className={`${classes.statusIndicator} ${status ? classes.active : classes.inactive}`}
                    ></span>
                </Box>
            </li>
        </ul>
    );
};

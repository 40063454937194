import { useState } from 'react';
import { Formik } from 'formik';
import { ConfirmAssetList } from 'generate/types';
import { TruckListFormWA } from 'components';
import { ISelectOptionList } from '../form-fields';
import { useTruckListForm } from 'hooks';
import { Truck } from 'generate/typesSupplyService';

interface SubmitTruckFormProps {
	truckList: Truck[];
	driverList?: ISelectOptionList | null;
	trailerList?: ISelectOptionList | null;
	onSubmit: (truckList: [ConfirmAssetList]) => void;
	maxTruckLength?: number | null;
	withSearchFilter?: boolean;
	setSearchDriver?: (value: string) => void;
  isDriverLoading: boolean;
}

export const TruckSubmitListFormWA = ({
	truckList = [],
	onSubmit,
	driverList,
	trailerList,
	withSearchFilter,
	setSearchDriver,
  isDriverLoading,
}: SubmitTruckFormProps) => {
	const [filter, setFilter] = useState<string>();
	const { initialValues, validationSchema, handleSubmit, validateMinTrucks } = useTruckListForm({
		truckList,
		onSubmit,
	});

  return (
		<>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
				validate={validateMinTrucks}
			>
				{({ values, errors }) => {
					const filteredList = () => {
						if (!filter) return values;

						const filterValue = filter.toLowerCase();
						return Object.fromEntries(
							Object.entries(values).filter((value) => value[0].toLowerCase().includes(filterValue))
						);
					};
          const truckListData = filteredList();
					return (
						<TruckListFormWA
							errors={errors}
							onChangeFilter={setFilter}
							truckListData={truckListData}
							driverList={driverList}
							trailerList={trailerList}
							withSearchFilter={withSearchFilter}
							// @ts-ignore
							setSearchDriver={setSearchDriver}
              isDriverLoading={isDriverLoading}
						/>
					);
				}}
			</Formik>
		</>
	);
};

import { Form, Formik, FormikConfig } from 'formik';
import Alert from '@material-ui/lab/Alert';
import {
  CurrencyTypes,
  SuggestPriceFormData,
  SuggestPriceFormNames,
} from 'models';
import { useTranslation } from 'react-i18next';
import { CustomButton, CustomInputGroup } from 'components';
import { Box, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  btn: {
    flex: '1 1 100%',
    '& + &': {
      marginLeft: '1rem',
    },
  },
});

interface SuggestPriceFormProps extends FormikConfig<SuggestPriceFormData> {}

export const SuggestPriceForm = (formikProps: SuggestPriceFormProps) => {
  const { t } = useTranslation('form-fields');
  const { t: tB } = useTranslation('buttons');
  const { goBack } = useHistory();
  const classes = useStyles();

  return (
    <>
      <Formik {...formikProps}>
        {({ values }) => (
          <Form>
            <CustomInputGroup<CurrencyTypes>
              name={SuggestPriceFormNames.price}
              buttonText={t('suggest_price.price_currency', {
                currency: values.currency,
              })}
              onChangeOption={() => {}}
            />
            {values.rate ? (
              <Alert variant='outlined' severity='info' icon={false}>
                {t('suggest_price.rate_type')} - {values.rate}
              </Alert>
            ) : null}
            <Box display='flex' mt={3}>
              <CustomButton
                className={classes.btn}
                onClick={goBack}
                variant='outlined'
              >
                {tB('cancel')}
              </CustomButton>
              <CustomButton type='submit' className={classes.btn}>
                {tB('choose_trucks')}
              </CustomButton>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

import React from 'react';
import { CustomButton, RedButton } from 'components';
import { useStyles } from './UserControlButtons.styles';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RoleEnum } from 'generate/typesAdmin';
import cn from 'classnames';

interface UserControlButtonsProps {
	classname?: string;
	onActivateUser: () => void;
	onActivateExternalTransporter: () => void;
	onSuspendUser: () => void;
	isSuspended?: boolean;
	role: RoleEnum;
}

export const UserControlButtons = ({
	classname,
	onActivateExternalTransporter,
	onActivateUser,
	onSuspendUser,
	isSuspended,
	role,
}: UserControlButtonsProps) => {
	const { t } = useTranslation('buttons');
	const classes = useStyles();

	const isUserActivated = role === RoleEnum.Active;
	const isUserExternal = role === RoleEnum.External;

	return (
		<Box className={classname}>
			{!isUserActivated && (
				<CustomButton
					onClick={isUserExternal ? onActivateExternalTransporter : onActivateUser}
					className={classes.btn}
				>
					{t('activate')}
				</CustomButton>
			)}
			{!isUserExternal && (
				<RedButton onClick={onSuspendUser} className={cn(classes.btn)}>
					{t(isSuspended ? 'unsuspend' : 'suspend')}
				</RedButton>
			)}
		</Box>
	);
};

export const COUNTRY_CODES = {
	southAfrica: [267, 266, 264, 27, 268, 260, 263],
	eastAfrica: [257, 269, 254, 261, 265, 230, 262, 258, 262, 250, 248, 255, 256],
	westAfrica: [
		247, 229, 226, 238, 225, 220, 233, 224, 245, 231, 223, 222, 227, 234, 290, 221, 232, 228, 290,
	],

	centralAfrica: [244, 237, 236, 235, 243, 242, 240, 241, 239],
	hornAfrica: [253, 291, 251, 252],
	northAfrica: [213, 20, 218, 212, 211, 249, 216],
};

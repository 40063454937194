import { ISelectOptionList } from 'containers';
import { IS_PRODUCTION } from 'utils';

export function checkAssetOptionList(
	optionList?: ISelectOptionList | null | [],
	confirm?: boolean,
	checkedValue: string = '',
	title: string = checkedValue
) {
	if (!confirm || !optionList || optionList?.some(({ value }) => value === checkedValue)) {
		return optionList;
	}

	return [
		{
			title: IS_PRODUCTION ? title : `${title} (LIA)`,
			value: checkedValue,
		},
		...optionList,
	];
}

/* eslint-disable */
// WARNING: This file is automatically generated. Do not edit.
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Upload: any;
};

export type Account = AccountNode & {
  __typename?: 'Account';
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  beneficiaryAddress?: Maybe<Scalars['String']>;
  beneficiaryName?: Maybe<Scalars['String']>;
  branchName?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  swiftCode?: Maybe<Scalars['String']>;
};

export type AccountNode = {
  id: Scalars['ID'];
};

export type AuthenticatedUser = {
  __typename?: 'AuthenticatedUser';
  accepted_terms?: Maybe<Scalars['Boolean']>;
  account_suspended?: Maybe<Scalars['Boolean']>;
  company_id?: Maybe<Scalars['Int']>;
  company_mobile?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  country_iso_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  email_verified?: Maybe<Scalars['Boolean']>;
  first_login?: Maybe<Scalars['Boolean']>;
  first_name?: Maybe<Scalars['String']>;
  id_number?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  preferred_username?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  role?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['Int']>;
  suspension_reason?: Maybe<Scalars['String']>;
  transporter?: Maybe<Scalars['String']>;
  transporter_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

export type AuthMutations = {
  __typename?: 'AuthMutations';
  createAccount?: Maybe<CreateAccountPayload>;
  createCompany?: Maybe<CreateCompany>;
  createCompanyDetails?: Maybe<CreateCompanyDetailsPayload>;
  createContact?: Maybe<CreateContactPayload>;
  createDocuments?: Maybe<CreateDocumentPayload>;
  createInsurance?: Maybe<CreateInsurancePayload>;
  createProfile?: Maybe<CreateProfilePayload>;
  createUser?: Maybe<CreateUser>;
  passwordResetLink?: Maybe<PasswordResetLink>;
  resetPassword?: Maybe<ResetPassword>;
};


export type AuthMutationsCreateAccountArgs = {
  input: CreateAccountInput;
};


export type AuthMutationsCreateCompanyArgs = {
  companyData: CreateCompanyInput;
};


export type AuthMutationsCreateCompanyDetailsArgs = {
  input: CreateCompanyDetailsInput;
};


export type AuthMutationsCreateContactArgs = {
  input: CreateContactInput;
};


export type AuthMutationsCreateDocumentsArgs = {
  input: CreateDocumentInput;
};


export type AuthMutationsCreateInsuranceArgs = {
  input: CreateInsuranceInput;
};


export type AuthMutationsCreateProfileArgs = {
  input: CreateProfileInput;
};


export type AuthMutationsCreateUserArgs = {
  input: UserInput;
};


export type AuthMutationsPasswordResetLinkArgs = {
  email: Scalars['String'];
};


export type AuthMutationsResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type AuthQuery = {
  __typename?: 'AuthQuery';
  account?: Maybe<Account>;
  accountById?: Maybe<Account>;
  companies?: Maybe<CompanyConnection>;
  company?: Maybe<Company>;
  companyDetails?: Maybe<CompanyDetail>;
  contact?: Maybe<Contact>;
  contactById?: Maybe<Contact>;
  contacts?: Maybe<ContactConnection>;
  directors?: Maybe<Director>;
  document?: Maybe<Document>;
  documentById?: Maybe<Document>;
  emailLogin?: Maybe<AuthenticatedUser>;
  enterpriseTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  insurance?: Maybe<Insurance>;
  insuranceById?: Maybe<Insurance>;
  insuranceList?: Maybe<InsuranceConnection>;
  logout?: Maybe<Response>;
  node?: Maybe<Node>;
  profile?: Maybe<Profile>;
  profileById?: Maybe<Profile>;
  profiles?: Maybe<ProfileConnection>;
  user?: Maybe<User>;
};


export type AuthQueryAccountByIdArgs = {
  id: Scalars['Int'];
};


export type AuthQueryCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<CompanySortEnum>>>;
};


export type AuthQueryCompanyArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type AuthQueryContactByIdArgs = {
  id: Scalars['Int'];
};


export type AuthQueryContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<ContactSortEnum>>>;
};


export type AuthQueryDocumentByIdArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type AuthQueryEmailLoginArgs = {
  input?: InputMaybe<UserInput>;
};


export type AuthQueryInsuranceByIdArgs = {
  id: Scalars['Int'];
};


export type AuthQueryInsuranceListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<InsuranceSortEnum>>>;
};


export type AuthQueryNodeArgs = {
  id: Scalars['ID'];
};


export type AuthQueryProfileByIdArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type AuthQueryProfilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<ProfileSortEnum>>>;
};


export type AuthQueryUserArgs = {
  accessToken?: InputMaybe<Scalars['String']>;
};

export type Company = CompanyNode & {
  __typename?: 'Company';
  account?: Maybe<Account>;
  companyEmail: Scalars['String'];
  companyMobile: Scalars['String'];
  companyName: Scalars['String'];
  countryCode: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  directors?: Maybe<DirectorConnection>;
  documents?: Maybe<Document>;
  fleet?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isExternal?: Maybe<Scalars['Boolean']>;
  isOnboardedTransporter?: Maybe<Scalars['Boolean']>;
  onboardedTransporterId?: Maybe<Scalars['Int']>;
  onboardingNotified?: Maybe<Scalars['Boolean']>;
  region: Scalars['String'];
  transporterId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CompanyDirectorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  edges: Array<Maybe<CompanyEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
};

export type CompanyDetail = CompanyDetailsNode & {
  __typename?: 'CompanyDetail';
  company?: Maybe<Company>;
  companyId: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  dateEstablished?: Maybe<Scalars['DateTime']>;
  enterpriseType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  numberOfDirectors?: Maybe<Scalars['Int']>;
  physicalAddress?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
  tradingName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CompanyDetailsNode = {
  id: Scalars['ID'];
};

export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  cursor: Scalars['String'];
  node?: Maybe<Company>;
  other?: Maybe<Scalars['String']>;
};

export type CompanyNode = {
  id: Scalars['ID'];
};

export enum CompanySortEnum {
  CompanyEmailAsc = 'COMPANY_EMAIL_ASC',
  CompanyEmailDesc = 'COMPANY_EMAIL_DESC',
  CompanyMobileAsc = 'COMPANY_MOBILE_ASC',
  CompanyMobileDesc = 'COMPANY_MOBILE_DESC',
  CompanyNameAsc = 'COMPANY_NAME_ASC',
  CompanyNameDesc = 'COMPANY_NAME_DESC',
  CountryCodeAsc = 'COUNTRY_CODE_ASC',
  CountryCodeDesc = 'COUNTRY_CODE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  FleetAsc = 'FLEET_ASC',
  FleetDesc = 'FLEET_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsExternalAsc = 'IS_EXTERNAL_ASC',
  IsExternalDesc = 'IS_EXTERNAL_DESC',
  IsOnboardedTransporterAsc = 'IS_ONBOARDED_TRANSPORTER_ASC',
  IsOnboardedTransporterDesc = 'IS_ONBOARDED_TRANSPORTER_DESC',
  OnboardedTransporterIdAsc = 'ONBOARDED_TRANSPORTER_ID_ASC',
  OnboardedTransporterIdDesc = 'ONBOARDED_TRANSPORTER_ID_DESC',
  OnboardingNotifiedAsc = 'ONBOARDING_NOTIFIED_ASC',
  OnboardingNotifiedDesc = 'ONBOARDING_NOTIFIED_DESC',
  RegionAsc = 'REGION_ASC',
  RegionDesc = 'REGION_DESC',
  TransporterIdAsc = 'TRANSPORTER_ID_ASC',
  TransporterIdDesc = 'TRANSPORTER_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type Contact = ContactNode & {
  __typename?: 'Contact';
  adminContactName?: Maybe<Scalars['String']>;
  adminEmail?: Maybe<Scalars['String']>;
  adminTelNumber?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  mobileNumber?: Maybe<Scalars['String']>;
  operationsContactName?: Maybe<Scalars['String']>;
  operationsEmail?: Maybe<Scalars['String']>;
  operationsTelNumber?: Maybe<Scalars['String']>;
};

export type ContactConnection = {
  __typename?: 'ContactConnection';
  edges: Array<Maybe<ContactEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
};

export type ContactEdge = {
  __typename?: 'ContactEdge';
  cursor: Scalars['String'];
  node?: Maybe<Contact>;
  other?: Maybe<Scalars['String']>;
};

export type ContactNode = {
  id: Scalars['ID'];
};

export enum ContactSortEnum {
  AdminContactNameAsc = 'ADMIN_CONTACT_NAME_ASC',
  AdminContactNameDesc = 'ADMIN_CONTACT_NAME_DESC',
  AdminEmailAsc = 'ADMIN_EMAIL_ASC',
  AdminEmailDesc = 'ADMIN_EMAIL_DESC',
  AdminTelNumberAsc = 'ADMIN_TEL_NUMBER_ASC',
  AdminTelNumberDesc = 'ADMIN_TEL_NUMBER_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MobileNumberAsc = 'MOBILE_NUMBER_ASC',
  MobileNumberDesc = 'MOBILE_NUMBER_DESC',
  OperationsContactNameAsc = 'OPERATIONS_CONTACT_NAME_ASC',
  OperationsContactNameDesc = 'OPERATIONS_CONTACT_NAME_DESC',
  OperationsEmailAsc = 'OPERATIONS_EMAIL_ASC',
  OperationsEmailDesc = 'OPERATIONS_EMAIL_DESC',
  OperationsTelNumberAsc = 'OPERATIONS_TEL_NUMBER_ASC',
  OperationsTelNumberDesc = 'OPERATIONS_TEL_NUMBER_DESC'
}

export type CreateAccountInput = {
  accountName?: InputMaybe<Scalars['String']>;
  accountNumber: Scalars['String'];
  accountType?: InputMaybe<Scalars['String']>;
  bankAddress?: InputMaybe<Scalars['String']>;
  bankCode?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  beneficiaryAddress?: InputMaybe<Scalars['String']>;
  beneficiaryName?: InputMaybe<Scalars['String']>;
  branchName?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['Int'];
  currency?: InputMaybe<Scalars['String']>;
  swiftCode?: InputMaybe<Scalars['String']>;
};

export type CreateAccountPayload = {
  __typename?: 'CreateAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateCompany = {
  __typename?: 'CreateCompany';
  company?: Maybe<Company>;
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Int']>;
};

export type CreateCompanyDetailsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['Int'];
  dateEstablished?: InputMaybe<Scalars['DateTime']>;
  directorDetails?: InputMaybe<Array<InputMaybe<DirectorDetailsInput>>>;
  enterpriseType?: InputMaybe<Scalars['String']>;
  numberOfDirectors?: InputMaybe<Scalars['Int']>;
  physicalAddress?: InputMaybe<Scalars['String']>;
  postalAddress?: InputMaybe<Scalars['String']>;
  registrationNumber?: InputMaybe<Scalars['String']>;
  tradingName?: InputMaybe<Scalars['String']>;
};

export type CreateCompanyDetailsPayload = {
  __typename?: 'CreateCompanyDetailsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  companyDetail?: Maybe<CompanyDetail>;
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Int']>;
};

export type CreateCompanyInput = {
  companyEmail: Scalars['String'];
  companyMobile: Scalars['String'];
  companyName: Scalars['String'];
  countryCode: Scalars['String'];
  fleet?: InputMaybe<Fleet>;
};

export type CreateContactInput = {
  adminContactName?: InputMaybe<Scalars['String']>;
  adminEmail?: InputMaybe<Scalars['String']>;
  adminTelNumber?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['Int'];
  mobileNumber?: InputMaybe<Scalars['String']>;
  operationsContactName?: InputMaybe<Scalars['String']>;
  operationsEmail?: InputMaybe<Scalars['String']>;
  operationsTelNumber?: InputMaybe<Scalars['String']>;
};

export type CreateContactPayload = {
  __typename?: 'CreateContactPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateDocumentInput = {
  bankLetter?: InputMaybe<Scalars['Upload']>;
  beeCertificate?: InputMaybe<Scalars['Upload']>;
  carrierResolution?: InputMaybe<Scalars['Upload']>;
  carriersLiability?: InputMaybe<Scalars['Upload']>;
  carriersLiabilityExpiry?: InputMaybe<Scalars['Date']>;
  certificateOfIncorporation?: InputMaybe<Scalars['Upload']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['Int'];
  companyLetterhead?: InputMaybe<Scalars['Upload']>;
  companyName?: InputMaybe<Scalars['String']>;
  directorIdentification?: InputMaybe<Scalars['Upload']>;
  directorsIds?: InputMaybe<Scalars['Upload']>;
  gitCoverConfirmation?: InputMaybe<Scalars['Upload']>;
  gitCoverExpiry?: InputMaybe<Scalars['Date']>;
  hasQualityManagementCert?: InputMaybe<Scalars['Boolean']>;
  insurancePolicies?: InputMaybe<Scalars['Upload']>;
  isVatRegistered?: InputMaybe<Scalars['Boolean']>;
  kraPin?: InputMaybe<Scalars['Upload']>;
  kraTcc?: InputMaybe<Scalars['Upload']>;
  letterOfGoodStanding?: InputMaybe<Scalars['Upload']>;
  proofOfDirectorship?: InputMaybe<Scalars['Upload']>;
  qualityManagementCertificate?: InputMaybe<Scalars['Upload']>;
  registrationCertificate?: InputMaybe<Scalars['Upload']>;
  taxClearanceCertificate?: InputMaybe<Scalars['Upload']>;
  tin?: InputMaybe<Scalars['Upload']>;
  vatCertificate?: InputMaybe<Scalars['Upload']>;
  vatRegistrationNumber?: InputMaybe<Scalars['String']>;
  vehicleRegistrationDocuments?: InputMaybe<Scalars['Upload']>;
};

export type CreateDocumentPayload = {
  __typename?: 'CreateDocumentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  documents?: Maybe<Document>;
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateInsuranceInput = {
  broker?: InputMaybe<Scalars['String']>;
  brokerContactName?: InputMaybe<Scalars['String']>;
  brokerEmail?: InputMaybe<Scalars['String']>;
  brokerTelNumber?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['Int'];
  gitCoverPerLoad?: InputMaybe<Scalars['String']>;
  hasComprehensiveGitCover?: InputMaybe<Scalars['Boolean']>;
  hasPublicLiabilityInsurance?: InputMaybe<Scalars['Boolean']>;
  insuranceCompany?: InputMaybe<Scalars['String']>;
  policyNumber: Scalars['String'];
};

export type CreateInsurancePayload = {
  __typename?: 'CreateInsurancePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  insurance?: Maybe<Insurance>;
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateProfileInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['Int']>;
  firstName: Scalars['String'];
  idNumber: Scalars['String'];
  lastName: Scalars['String'];
  vettingConsent?: InputMaybe<Scalars['Boolean']>;
};

export type CreateProfilePayload = {
  __typename?: 'CreateProfilePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  profile?: Maybe<Profile>;
};

export type CreateUser = {
  __typename?: 'CreateUser';
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Int']>;
};

export type Director = DirectorNode & {
  __typename?: 'Director';
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directorName?: Maybe<Scalars['String']>;
  directorNumber?: Maybe<Scalars['String']>;
  directorResidentialAddress?: Maybe<Scalars['String']>;
  directorTelNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectorConnection = {
  __typename?: 'DirectorConnection';
  edges: Array<Maybe<DirectorEdge>>;
  pageInfo: PageInfo;
};

export type DirectorDetailsInput = {
  directorName?: InputMaybe<Scalars['String']>;
  directorNumber?: InputMaybe<Scalars['String']>;
  directorResidentialAddress?: InputMaybe<Scalars['String']>;
  directorTelNumber?: InputMaybe<Scalars['String']>;
};

export type DirectorEdge = {
  __typename?: 'DirectorEdge';
  cursor: Scalars['String'];
  node?: Maybe<Director>;
};

export type DirectorNode = {
  id: Scalars['ID'];
};

export type Document = DocumentNode & {
  __typename?: 'Document';
  bankLetter?: Maybe<Scalars['String']>;
  beeCertificate?: Maybe<Scalars['String']>;
  carrierResolution?: Maybe<Scalars['String']>;
  carriersLiability?: Maybe<Scalars['String']>;
  carriersLiabilityExpiry?: Maybe<Scalars['DateTime']>;
  certificateOfIncorporation?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  companyLetterhead?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directorIdentification?: Maybe<Scalars['String']>;
  directorsIds?: Maybe<Scalars['String']>;
  gitCoverConfirmation?: Maybe<Scalars['String']>;
  gitCoverExpiry?: Maybe<Scalars['DateTime']>;
  hasQualityManagementCert?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  insurancePolicies?: Maybe<Scalars['String']>;
  isVatRegistered?: Maybe<Scalars['Boolean']>;
  kraPin?: Maybe<Scalars['String']>;
  kraTcc?: Maybe<Scalars['String']>;
  letterOfGoodStanding?: Maybe<Scalars['String']>;
  proofOfDirectorship?: Maybe<Scalars['String']>;
  qualityManagementCertificate?: Maybe<Scalars['String']>;
  registrationCertificate?: Maybe<Scalars['String']>;
  taxClearanceCertificate?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vatCertificate?: Maybe<Scalars['String']>;
  vatRegistrationNumber?: Maybe<Scalars['String']>;
  vehicleRegistrationDocuments?: Maybe<Scalars['String']>;
};

export type DocumentNode = {
  id: Scalars['ID'];
};

export enum Fleet {
  HeavyCommercialVehicle = 'HEAVY_COMMERCIAL_VEHICLE',
  LightCommercialVehicle = 'LIGHT_COMMERCIAL_VEHICLE'
}

export type Insurance = InsuranceNode & {
  __typename?: 'Insurance';
  broker?: Maybe<Scalars['String']>;
  brokerContactName?: Maybe<Scalars['String']>;
  brokerEmail?: Maybe<Scalars['String']>;
  brokerTelNumber?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  gitCoverPerLoad?: Maybe<Scalars['String']>;
  hasComprehensiveGitCover?: Maybe<Scalars['Boolean']>;
  hasPublicLiabilityInsurance?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  insuranceCompany?: Maybe<Scalars['String']>;
  policyNumber?: Maybe<Scalars['String']>;
};

export type InsuranceConnection = {
  __typename?: 'InsuranceConnection';
  edges: Array<Maybe<InsuranceEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
};

export type InsuranceEdge = {
  __typename?: 'InsuranceEdge';
  cursor: Scalars['String'];
  node?: Maybe<Insurance>;
  other?: Maybe<Scalars['String']>;
};

export type InsuranceNode = {
  id: Scalars['ID'];
};

export enum InsuranceSortEnum {
  BrokerAsc = 'BROKER_ASC',
  BrokerContactNameAsc = 'BROKER_CONTACT_NAME_ASC',
  BrokerContactNameDesc = 'BROKER_CONTACT_NAME_DESC',
  BrokerDesc = 'BROKER_DESC',
  BrokerEmailAsc = 'BROKER_EMAIL_ASC',
  BrokerEmailDesc = 'BROKER_EMAIL_DESC',
  BrokerTelNumberAsc = 'BROKER_TEL_NUMBER_ASC',
  BrokerTelNumberDesc = 'BROKER_TEL_NUMBER_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  GitCoverPerLoadAsc = 'GIT_COVER_PER_LOAD_ASC',
  GitCoverPerLoadDesc = 'GIT_COVER_PER_LOAD_DESC',
  HasComprehensiveGitCoverAsc = 'HAS_COMPREHENSIVE_GIT_COVER_ASC',
  HasComprehensiveGitCoverDesc = 'HAS_COMPREHENSIVE_GIT_COVER_DESC',
  HasPublicLiabilityInsuranceAsc = 'HAS_PUBLIC_LIABILITY_INSURANCE_ASC',
  HasPublicLiabilityInsuranceDesc = 'HAS_PUBLIC_LIABILITY_INSURANCE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InsuranceCompanyAsc = 'INSURANCE_COMPANY_ASC',
  InsuranceCompanyDesc = 'INSURANCE_COMPANY_DESC',
  PolicyNumberAsc = 'POLICY_NUMBER_ASC',
  PolicyNumberDesc = 'POLICY_NUMBER_DESC'
}

export type Node = {
  id: Scalars['ID'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PasswordResetLink = {
  __typename?: 'PasswordResetLink';
  email?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Int']>;
};

export type Profile = ProfileNode & {
  __typename?: 'Profile';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  idNumber?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vettingConsent?: Maybe<Scalars['Boolean']>;
};

export type ProfileConnection = {
  __typename?: 'ProfileConnection';
  edges: Array<Maybe<ProfileEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
};

export type ProfileEdge = {
  __typename?: 'ProfileEdge';
  cursor: Scalars['String'];
  node?: Maybe<Profile>;
  other?: Maybe<Scalars['String']>;
};

export type ProfileNode = {
  id: Scalars['ID'];
};

export enum ProfileSortEnum {
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IdNumberAsc = 'ID_NUMBER_ASC',
  IdNumberDesc = 'ID_NUMBER_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  VettingConsentAsc = 'VETTING_CONSENT_ASC',
  VettingConsentDesc = 'VETTING_CONSENT_DESC'
}

export type ResetPassword = {
  __typename?: 'ResetPassword';
  email?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Int']>;
};

export type ResetPasswordInput = {
  currentPassword: Scalars['String'];
  email: Scalars['String'];
  newPassword: Scalars['String'];
};

export type Response = {
  __typename?: 'Response';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  accepted_terms?: Maybe<Scalars['Boolean']>;
  account_suspended?: Maybe<Scalars['Boolean']>;
  company_id?: Maybe<Scalars['Int']>;
  company_mobile?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  country_iso_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  email_verified?: Maybe<Scalars['Boolean']>;
  first_login?: Maybe<Scalars['Boolean']>;
  first_name?: Maybe<Scalars['String']>;
  id_number?: Maybe<Scalars['String']>;
  is_oauth?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  preferred_username?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  role?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['Int']>;
  suspension_reason?: Maybe<Scalars['String']>;
  transporter?: Maybe<Scalars['String']>;
  transporter_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type UserInput = {
  companyId?: InputMaybe<Scalars['Int']>;
  companyName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  idNumber?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type CreateAccountMutationVariables = Exact<{
  bankName?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber: Scalars['String'];
  accountType?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  branchName?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
  beneficiaryName?: Maybe<Scalars['String']>;
  beneficiaryAddress?: Maybe<Scalars['String']>;
  companyId: Scalars['Int'];
}>;


export type CreateAccountMutation = { __typename?: 'AuthMutations', createAccount?: { __typename?: 'CreateAccountPayload', ok?: boolean | null | undefined, message?: string | null | undefined, account?: { __typename?: 'Account', id: string } | null | undefined } | null | undefined };

export type CreateCompanyMutationVariables = Exact<{
  companyName: Scalars['String'];
  companyEmail: Scalars['String'];
  companyMobile: Scalars['String'];
  fleet?: Maybe<Fleet>;
  countryCode: Scalars['String'];
}>;


export type CreateCompanyMutation = { __typename?: 'AuthMutations', createCompany?: { __typename?: 'CreateCompany', ok?: boolean | null | undefined, message?: string | null | undefined, company?: { __typename?: 'Company', id: string, companyName: string, region: string, transporterId?: number | null | undefined } | null | undefined } | null | undefined };

export type CreateCompanyDetailsMutationVariables = Exact<{
  companyId: Scalars['Int'];
  tradingName?: Maybe<Scalars['String']>;
  enterpriseType?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<Scalars['String']>;
  physicalAddress?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
  dateEstablished?: Maybe<Scalars['DateTime']>;
  numberOfDirectors?: Maybe<Scalars['Int']>;
  directorDetails?: Maybe<Array<Maybe<DirectorDetailsInput>> | Maybe<DirectorDetailsInput>>;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type CreateCompanyDetailsMutation = { __typename?: 'AuthMutations', createCompanyDetails?: { __typename?: 'CreateCompanyDetailsPayload', ok?: boolean | null | undefined, message?: string | null | undefined, companyDetail?: { __typename?: 'CompanyDetail', id: string } | null | undefined } | null | undefined };

export type CreateContactMutationVariables = Exact<{
  operationsContactName?: Maybe<Scalars['String']>;
  operationsTelNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  operationsEmail?: Maybe<Scalars['String']>;
  adminContactName?: Maybe<Scalars['String']>;
  adminTelNumber?: Maybe<Scalars['String']>;
  adminEmail?: Maybe<Scalars['String']>;
  companyId: Scalars['Int'];
}>;


export type CreateContactMutation = { __typename?: 'AuthMutations', createContact?: { __typename?: 'CreateContactPayload', ok?: boolean | null | undefined, message?: string | null | undefined, contact?: { __typename?: 'Contact', id: string } | null | undefined } | null | undefined };

export type CreateInsuranceMutationVariables = Exact<{
  broker?: Maybe<Scalars['String']>;
  brokerContactName?: Maybe<Scalars['String']>;
  brokerTelNumber?: Maybe<Scalars['String']>;
  brokerEmail?: Maybe<Scalars['String']>;
  insuranceCompany?: Maybe<Scalars['String']>;
  policyNumber: Scalars['String'];
  hasComprehensiveGitCover?: Maybe<Scalars['Boolean']>;
  hasPublicLiabilityInsurance?: Maybe<Scalars['Boolean']>;
  gitCoverPerLoad?: Maybe<Scalars['String']>;
  companyId: Scalars['Int'];
}>;


export type CreateInsuranceMutation = { __typename?: 'AuthMutations', createInsurance?: { __typename?: 'CreateInsurancePayload', ok?: boolean | null | undefined, message?: string | null | undefined, insurance?: { __typename?: 'Insurance', id: string } | null | undefined } | null | undefined };

export type CreateProfileMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  idNumber: Scalars['String'];
  vettingConsent?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['Int']>;
}>;


export type CreateProfileMutation = { __typename?: 'AuthMutations', createProfile?: { __typename?: 'CreateProfilePayload', ok?: boolean | null | undefined, message?: string | null | undefined, profile?: { __typename?: 'Profile', id: string } | null | undefined } | null | undefined };

export type LogOutQueryVariables = Exact<{ [key: string]: never; }>;


export type LogOutQuery = { __typename?: 'AuthQuery', logout?: { __typename?: 'Response', status?: number | null | undefined, message?: string | null | undefined } | null | undefined };

export type UploadDocumentsMutationVariables = Exact<{
  companyId: Scalars['Int'];
  directorIdentification?: Maybe<Scalars['Upload']>;
  certificateOfIncorporation?: Maybe<Scalars['Upload']>;
  kraPin?: Maybe<Scalars['Upload']>;
  tin?: Maybe<Scalars['Upload']>;
  kraTcc?: Maybe<Scalars['Upload']>;
  proofOfDirectorship?: Maybe<Scalars['Upload']>;
  carriersLiability?: Maybe<Scalars['Upload']>;
  carriersLiabilityExpiry?: Maybe<Scalars['Date']>;
  companyName?: Maybe<Scalars['String']>;
  carrierResolution?: Maybe<Scalars['Upload']>;
  gitCoverConfirmation?: Maybe<Scalars['Upload']>;
  gitCoverExpiry?: Maybe<Scalars['Date']>;
  insurancePolicies?: Maybe<Scalars['Upload']>;
  beeCertificate?: Maybe<Scalars['Upload']>;
  bankLetter?: Maybe<Scalars['Upload']>;
  isVatRegistered?: Maybe<Scalars['Boolean']>;
  vatRegistrationNumber?: Maybe<Scalars['String']>;
  vatCertificate?: Maybe<Scalars['Upload']>;
  registrationCertificate?: Maybe<Scalars['Upload']>;
  taxClearanceCertificate?: Maybe<Scalars['Upload']>;
  companyLetterhead?: Maybe<Scalars['Upload']>;
  directorsIds?: Maybe<Scalars['Upload']>;
  letterOfGoodStanding?: Maybe<Scalars['Upload']>;
  vehicleRegistrationDocuments?: Maybe<Scalars['Upload']>;
  hasQualityManagementCert?: Maybe<Scalars['Boolean']>;
  qualityManagementCertificate?: Maybe<Scalars['Upload']>;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UploadDocumentsMutation = { __typename?: 'AuthMutations', createDocuments?: { __typename?: 'CreateDocumentPayload', ok?: boolean | null | undefined, message?: string | null | undefined, documents?: { __typename?: 'Document', id: string } | null | undefined } | null | undefined };

export type EmailLoginQueryVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
}>;


export type EmailLoginQuery = { __typename?: 'AuthQuery', emailLogin?: { __typename?: 'AuthenticatedUser', first_login?: boolean | null | undefined } | null | undefined };


export const CreateAccountDocument = gql`
    mutation createAccount($bankName: String, $accountName: String, $accountNumber: String!, $accountType: String, $bankCode: String, $branchName: String, $swiftCode: String, $bankAddress: String, $beneficiaryName: String, $beneficiaryAddress: String, $companyId: Int!) {
  createAccount(input: {bankName: $bankName, accountName: $accountName, accountNumber: $accountNumber, accountType: $accountType, bankCode: $bankCode, branchName: $branchName, swiftCode: $swiftCode, bankAddress: $bankAddress, beneficiaryName: $beneficiaryName, beneficiaryAddress: $beneficiaryAddress, companyId: $companyId}) {
    account {
      id
    }
    ok
    message
  }
}
    `;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      bankName: // value for 'bankName'
 *      accountName: // value for 'accountName'
 *      accountNumber: // value for 'accountNumber'
 *      accountType: // value for 'accountType'
 *      bankCode: // value for 'bankCode'
 *      branchName: // value for 'branchName'
 *      swiftCode: // value for 'swiftCode'
 *      bankAddress: // value for 'bankAddress'
 *      beneficiaryName: // value for 'beneficiaryName'
 *      beneficiaryAddress: // value for 'beneficiaryAddress'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, options);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($companyName: String!, $companyEmail: String!, $companyMobile: String!, $fleet: Fleet, $countryCode: String!) {
  createCompany(companyData: {companyName: $companyName, companyEmail: $companyEmail, companyMobile: $companyMobile, fleet: $fleet, countryCode: $countryCode}) {
    company {
      id
      companyName
      region
      transporterId
    }
    ok
    message
  }
}
    `;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      companyName: // value for 'companyName'
 *      companyEmail: // value for 'companyEmail'
 *      companyMobile: // value for 'companyMobile'
 *      fleet: // value for 'fleet'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const CreateCompanyDetailsDocument = gql`
    mutation createCompanyDetails($companyId: Int!, $tradingName: String, $enterpriseType: String, $postalAddress: String, $physicalAddress: String, $registrationNumber: String, $dateEstablished: DateTime, $numberOfDirectors: Int, $directorDetails: [DirectorDetailsInput], $clientMutationId: String) {
  createCompanyDetails(input: {companyId: $companyId, tradingName: $tradingName, enterpriseType: $enterpriseType, postalAddress: $postalAddress, physicalAddress: $physicalAddress, registrationNumber: $registrationNumber, dateEstablished: $dateEstablished, numberOfDirectors: $numberOfDirectors, directorDetails: $directorDetails, clientMutationId: $clientMutationId}) {
    companyDetail {
      id
    }
    ok
    message
  }
}
    `;
export type CreateCompanyDetailsMutationFn = Apollo.MutationFunction<CreateCompanyDetailsMutation, CreateCompanyDetailsMutationVariables>;

/**
 * __useCreateCompanyDetailsMutation__
 *
 * To run a mutation, you first call `useCreateCompanyDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyDetailsMutation, { data, loading, error }] = useCreateCompanyDetailsMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      tradingName: // value for 'tradingName'
 *      enterpriseType: // value for 'enterpriseType'
 *      postalAddress: // value for 'postalAddress'
 *      physicalAddress: // value for 'physicalAddress'
 *      registrationNumber: // value for 'registrationNumber'
 *      dateEstablished: // value for 'dateEstablished'
 *      numberOfDirectors: // value for 'numberOfDirectors'
 *      directorDetails: // value for 'directorDetails'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCreateCompanyDetailsMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyDetailsMutation, CreateCompanyDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyDetailsMutation, CreateCompanyDetailsMutationVariables>(CreateCompanyDetailsDocument, options);
      }
export type CreateCompanyDetailsMutationHookResult = ReturnType<typeof useCreateCompanyDetailsMutation>;
export type CreateCompanyDetailsMutationResult = Apollo.MutationResult<CreateCompanyDetailsMutation>;
export type CreateCompanyDetailsMutationOptions = Apollo.BaseMutationOptions<CreateCompanyDetailsMutation, CreateCompanyDetailsMutationVariables>;
export const CreateContactDocument = gql`
    mutation createContact($operationsContactName: String, $operationsTelNumber: String, $mobileNumber: String, $operationsEmail: String, $adminContactName: String, $adminTelNumber: String, $adminEmail: String, $companyId: Int!) {
  createContact(input: {operationsContactName: $operationsContactName, operationsTelNumber: $operationsTelNumber, mobileNumber: $mobileNumber, operationsEmail: $operationsEmail, adminContactName: $adminContactName, adminTelNumber: $adminTelNumber, adminEmail: $adminEmail, companyId: $companyId}) {
    contact {
      id
    }
    ok
    message
  }
}
    `;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      operationsContactName: // value for 'operationsContactName'
 *      operationsTelNumber: // value for 'operationsTelNumber'
 *      mobileNumber: // value for 'mobileNumber'
 *      operationsEmail: // value for 'operationsEmail'
 *      adminContactName: // value for 'adminContactName'
 *      adminTelNumber: // value for 'adminTelNumber'
 *      adminEmail: // value for 'adminEmail'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<CreateContactMutation, CreateContactMutationVariables>;
export const CreateInsuranceDocument = gql`
    mutation createInsurance($broker: String, $brokerContactName: String, $brokerTelNumber: String, $brokerEmail: String, $insuranceCompany: String, $policyNumber: String!, $hasComprehensiveGitCover: Boolean, $hasPublicLiabilityInsurance: Boolean, $gitCoverPerLoad: String, $companyId: Int!) {
  createInsurance(input: {broker: $broker, brokerContactName: $brokerContactName, gitCoverPerLoad: $gitCoverPerLoad, brokerTelNumber: $brokerTelNumber, brokerEmail: $brokerEmail, insuranceCompany: $insuranceCompany, policyNumber: $policyNumber, hasComprehensiveGitCover: $hasComprehensiveGitCover, hasPublicLiabilityInsurance: $hasPublicLiabilityInsurance, companyId: $companyId}) {
    insurance {
      id
    }
    ok
    message
  }
}
    `;
export type CreateInsuranceMutationFn = Apollo.MutationFunction<CreateInsuranceMutation, CreateInsuranceMutationVariables>;

/**
 * __useCreateInsuranceMutation__
 *
 * To run a mutation, you first call `useCreateInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInsuranceMutation, { data, loading, error }] = useCreateInsuranceMutation({
 *   variables: {
 *      broker: // value for 'broker'
 *      brokerContactName: // value for 'brokerContactName'
 *      brokerTelNumber: // value for 'brokerTelNumber'
 *      brokerEmail: // value for 'brokerEmail'
 *      insuranceCompany: // value for 'insuranceCompany'
 *      policyNumber: // value for 'policyNumber'
 *      hasComprehensiveGitCover: // value for 'hasComprehensiveGitCover'
 *      hasPublicLiabilityInsurance: // value for 'hasPublicLiabilityInsurance'
 *      gitCoverPerLoad: // value for 'gitCoverPerLoad'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCreateInsuranceMutation(baseOptions?: Apollo.MutationHookOptions<CreateInsuranceMutation, CreateInsuranceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInsuranceMutation, CreateInsuranceMutationVariables>(CreateInsuranceDocument, options);
      }
export type CreateInsuranceMutationHookResult = ReturnType<typeof useCreateInsuranceMutation>;
export type CreateInsuranceMutationResult = Apollo.MutationResult<CreateInsuranceMutation>;
export type CreateInsuranceMutationOptions = Apollo.BaseMutationOptions<CreateInsuranceMutation, CreateInsuranceMutationVariables>;
export const CreateProfileDocument = gql`
    mutation createProfile($firstName: String!, $lastName: String!, $idNumber: String!, $vettingConsent: Boolean, $companyId: Int) {
  createProfile(input: {firstName: $firstName, lastName: $lastName, idNumber: $idNumber, vettingConsent: $vettingConsent, companyId: $companyId}) {
    profile {
      id
    }
    ok
    message
  }
}
    `;
export type CreateProfileMutationFn = Apollo.MutationFunction<CreateProfileMutation, CreateProfileMutationVariables>;

/**
 * __useCreateProfileMutation__
 *
 * To run a mutation, you first call `useCreateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfileMutation, { data, loading, error }] = useCreateProfileMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      idNumber: // value for 'idNumber'
 *      vettingConsent: // value for 'vettingConsent'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCreateProfileMutation(baseOptions?: Apollo.MutationHookOptions<CreateProfileMutation, CreateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProfileMutation, CreateProfileMutationVariables>(CreateProfileDocument, options);
      }
export type CreateProfileMutationHookResult = ReturnType<typeof useCreateProfileMutation>;
export type CreateProfileMutationResult = Apollo.MutationResult<CreateProfileMutation>;
export type CreateProfileMutationOptions = Apollo.BaseMutationOptions<CreateProfileMutation, CreateProfileMutationVariables>;
export const LogOutDocument = gql`
    query logOut {
  logout {
    status
    message
  }
}
    `;

/**
 * __useLogOutQuery__
 *
 * To run a query within a React component, call `useLogOutQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogOutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogOutQuery({
 *   variables: {
 *   },
 * });
 */
export function useLogOutQuery(baseOptions?: Apollo.QueryHookOptions<LogOutQuery, LogOutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LogOutQuery, LogOutQueryVariables>(LogOutDocument, options);
      }
export function useLogOutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LogOutQuery, LogOutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LogOutQuery, LogOutQueryVariables>(LogOutDocument, options);
        }
export type LogOutQueryHookResult = ReturnType<typeof useLogOutQuery>;
export type LogOutLazyQueryHookResult = ReturnType<typeof useLogOutLazyQuery>;
export type LogOutQueryResult = Apollo.QueryResult<LogOutQuery, LogOutQueryVariables>;
export const UploadDocumentsDocument = gql`
    mutation uploadDocuments($companyId: Int!, $directorIdentification: Upload, $certificateOfIncorporation: Upload, $kraPin: Upload, $tin: Upload, $kraTcc: Upload, $proofOfDirectorship: Upload, $carriersLiability: Upload, $carriersLiabilityExpiry: Date, $companyName: String, $carrierResolution: Upload, $gitCoverConfirmation: Upload, $gitCoverExpiry: Date, $insurancePolicies: Upload, $beeCertificate: Upload, $bankLetter: Upload, $isVatRegistered: Boolean, $vatRegistrationNumber: String, $vatCertificate: Upload, $registrationCertificate: Upload, $taxClearanceCertificate: Upload, $companyLetterhead: Upload, $directorsIds: Upload, $letterOfGoodStanding: Upload, $vehicleRegistrationDocuments: Upload, $hasQualityManagementCert: Boolean, $qualityManagementCertificate: Upload, $clientMutationId: String) {
  createDocuments(input: {directorIdentification: $directorIdentification, certificateOfIncorporation: $certificateOfIncorporation, kraPin: $kraPin, tin: $tin, kraTcc: $kraTcc, proofOfDirectorship: $proofOfDirectorship, carriersLiability: $carriersLiability, carriersLiabilityExpiry: $carriersLiabilityExpiry, companyId: $companyId, companyName: $companyName, carrierResolution: $carrierResolution, gitCoverConfirmation: $gitCoverConfirmation, gitCoverExpiry: $gitCoverExpiry, insurancePolicies: $insurancePolicies, beeCertificate: $beeCertificate, bankLetter: $bankLetter, isVatRegistered: $isVatRegistered, vatRegistrationNumber: $vatRegistrationNumber, vatCertificate: $vatCertificate, registrationCertificate: $registrationCertificate, taxClearanceCertificate: $taxClearanceCertificate, companyLetterhead: $companyLetterhead, directorsIds: $directorsIds, letterOfGoodStanding: $letterOfGoodStanding, vehicleRegistrationDocuments: $vehicleRegistrationDocuments, hasQualityManagementCert: $hasQualityManagementCert, qualityManagementCertificate: $qualityManagementCertificate, clientMutationId: $clientMutationId}) {
    documents {
      id
    }
    ok
    message
  }
}
    `;
export type UploadDocumentsMutationFn = Apollo.MutationFunction<UploadDocumentsMutation, UploadDocumentsMutationVariables>;

/**
 * __useUploadDocumentsMutation__
 *
 * To run a mutation, you first call `useUploadDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentsMutation, { data, loading, error }] = useUploadDocumentsMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      directorIdentification: // value for 'directorIdentification'
 *      certificateOfIncorporation: // value for 'certificateOfIncorporation'
 *      kraPin: // value for 'kraPin'
 *      tin: // value for 'tin'
 *      kraTcc: // value for 'kraTcc'
 *      proofOfDirectorship: // value for 'proofOfDirectorship'
 *      carriersLiability: // value for 'carriersLiability'
 *      carriersLiabilityExpiry: // value for 'carriersLiabilityExpiry'
 *      companyName: // value for 'companyName'
 *      carrierResolution: // value for 'carrierResolution'
 *      gitCoverConfirmation: // value for 'gitCoverConfirmation'
 *      gitCoverExpiry: // value for 'gitCoverExpiry'
 *      insurancePolicies: // value for 'insurancePolicies'
 *      beeCertificate: // value for 'beeCertificate'
 *      bankLetter: // value for 'bankLetter'
 *      isVatRegistered: // value for 'isVatRegistered'
 *      vatRegistrationNumber: // value for 'vatRegistrationNumber'
 *      vatCertificate: // value for 'vatCertificate'
 *      registrationCertificate: // value for 'registrationCertificate'
 *      taxClearanceCertificate: // value for 'taxClearanceCertificate'
 *      companyLetterhead: // value for 'companyLetterhead'
 *      directorsIds: // value for 'directorsIds'
 *      letterOfGoodStanding: // value for 'letterOfGoodStanding'
 *      vehicleRegistrationDocuments: // value for 'vehicleRegistrationDocuments'
 *      hasQualityManagementCert: // value for 'hasQualityManagementCert'
 *      qualityManagementCertificate: // value for 'qualityManagementCertificate'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUploadDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<UploadDocumentsMutation, UploadDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadDocumentsMutation, UploadDocumentsMutationVariables>(UploadDocumentsDocument, options);
      }
export type UploadDocumentsMutationHookResult = ReturnType<typeof useUploadDocumentsMutation>;
export type UploadDocumentsMutationResult = Apollo.MutationResult<UploadDocumentsMutation>;
export type UploadDocumentsMutationOptions = Apollo.BaseMutationOptions<UploadDocumentsMutation, UploadDocumentsMutationVariables>;
export const EmailLoginDocument = gql`
    query emailLogin($email: String, $password: String) {
  emailLogin(input: {email: $email, password: $password}) {
    first_login
  }
}
    `;

/**
 * __useEmailLoginQuery__
 *
 * To run a query within a React component, call `useEmailLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailLoginQuery({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useEmailLoginQuery(baseOptions?: Apollo.QueryHookOptions<EmailLoginQuery, EmailLoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailLoginQuery, EmailLoginQueryVariables>(EmailLoginDocument, options);
      }
export function useEmailLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailLoginQuery, EmailLoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailLoginQuery, EmailLoginQueryVariables>(EmailLoginDocument, options);
        }
export type EmailLoginQueryHookResult = ReturnType<typeof useEmailLoginQuery>;
export type EmailLoginLazyQueryHookResult = ReturnType<typeof useEmailLoginLazyQuery>;
export type EmailLoginQueryResult = Apollo.QueryResult<EmailLoginQuery, EmailLoginQueryVariables>;
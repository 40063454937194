import { makeStyles } from '@material-ui/core';
import { COLORS, STYLES } from 'utils';

export const useStyles = makeStyles({
	content: {
		maxWidth: 330,
	},
	img: {
		margin: '0 auto',
		width: 50,
		borderRadius: 50,
	},
	title: {
		fontSize: 19,
		color: COLORS.confirm,
		marginTop: '1rem',
	},
	close: {
		...STYLES.close,
	},
	text: {
		color: COLORS.primary,
		marginTop: '1rem',
		fontSize: 16,
		fontWeight: 600
	},
	error: {
		color: COLORS.danger,
	},
});

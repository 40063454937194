import { COLORS } from '../colors';

export const STYLES = {
	bold: 700,
	semiBold: 600,
	medium: 500,
	loadData: {
		fontSize: 12,
		margin: '10px 0 10px 16px',
	},
	close: {
		position: 'absolute' as 'absolute',
		right: '1rem',
		top: '1rem',
		width: '1rem',
		height: '1rem',
		fill: COLORS.gray,
	},
	modal: {
		title: {
			textAlign: 'center' as 'left',
			fontSize: '1rem',
			color: COLORS.primary,
			textTransform: 'capitalize' as 'uppercase',
			fontWeight: 600,
		},
		submit: {},
	},
	card: {
		borderRadius: 4,
		border: `1px solid ${COLORS.inputBorder}`,
		padding: '5px 10px',
	},
	buttonIcon: {
		width: '1rem',
		height: '1rem',
		fill: 'currentColor',
	},
	input: {
		maxWidth: 600,
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
	},
	overflow: {
		overflow: 'auto',
	},
	border: `1px solid ${COLORS.gray}`,
};

import { IRoute, RoleTypes } from 'models';
import { AppRouteNameList } from '../AppRouteNameList';

const requiredRoles = [RoleTypes.admin];

export const JobsRoute: IRoute = {
	name: AppRouteNameList.jobs,
	requiredRoles,
	nested: [
		{
			name: AppRouteNameList.jobsConfirmed,
			requiredRoles,
			nested: [
				{
					name: AppRouteNameList.jobsConfirmedDetails,
					requiredRoles,
					nested: [
						{
							name: AppRouteNameList.requestFuel,
							requiredRoles,
						},
					],
				},
			],
		},
		{
			name: AppRouteNameList.jobsActive,
			requiredRoles,
			nested: [
				{
					name: AppRouteNameList.jobsActiveDetails,
					requiredRoles,
				},
				{
					name: AppRouteNameList.addLocation,
					requiredRoles,
				},
				{
					name: AppRouteNameList.previousLocations,
					requiredRoles,
				},
			],
		},
		{
			name: AppRouteNameList.jobsCompleted,
			requiredRoles,
			nested: [
				{
					name: AppRouteNameList.jobsCompletedDetails,
					requiredRoles,
				},
			],
		},
	],
};

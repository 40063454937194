import './styles/common.scss';

import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { ThemeProvider } from '@material-ui/core/styles';

import { setViewportHeight, setViewportHeightThrottled } from 'helpers';
import { Loader } from 'components';
import { Providers } from 'containers';
import { theme } from 'utils';
import { MIXPANEL_TOKEN, MIXPANEL_DEBUG_MODE } from 'secrets';

const AppRouter = React.lazy(() => import('./router'));

function App() {
	useEffect(() => {
		if (MIXPANEL_TOKEN) {
			mixpanel.init(MIXPANEL_TOKEN, { batch_requests: true, debug: Boolean(MIXPANEL_DEBUG_MODE) });
		}
	}, []);

	useEffect(() => {
		setViewportHeight();

		window.addEventListener('resize', setViewportHeightThrottled);
		return () => {
			window.removeEventListener('resize', setViewportHeightThrottled);
		};
	}, []);
	return (
		<React.Suspense fallback={<Loader isLoading={true} />}>
			<Providers>
				<ThemeProvider theme={theme}>
					<AppRouter />
				</ThemeProvider>
			</Providers>
		</React.Suspense>
	);
}

export default App;

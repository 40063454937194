import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NetworkStatus } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { Box, makeStyles } from '@material-ui/core';
import { ContentWrapper, Header, JobSearchBlock, MoveTypeFilter, LoriLogo } from 'components';
import { OrderCard } from 'components/job-card';
import { ItemListWithInfiniteScroll } from 'containers';
import {
	JobCategory,
	CargoType,
	MoveType,
	useGetWorkOrderListQuery,
	Order,
	GetWorkOrderListQuery,
} from 'generate/types';
import { getPaginatedDataNodeList, getPathWithId } from 'helpers';
import { JobFilter } from 'models';
import { AvailableJobsModal } from 'containers';
import { AppPathList, AppRoutePathList, AppRouteNameList } from 'router';
import capitalize from 'lodash/capitalize';
import { useMixpanel } from 'analytics';

const useStyles = makeStyles({
	wrapper: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	type: {
		textAlign: 'center',
	},
	logo: {
		marginLeft: 20,
		width: 114,
	},
});
const { New, Submitted, Matched } = JobCategory;

interface AvailableJobsProps {
	jobCategory: typeof New | typeof Submitted | typeof Matched;
}

export function AvailableJobs({ jobCategory }: AvailableJobsProps) {
	const classes = useStyles();
	const history = useHistory();
	const mixpanel = useMixpanel();

	const [open, setOpen] = React.useState(false);
	const [workOrder, setWorkOrder] = React.useState<Order | undefined>();
	const [searchString, setSearchString] = useState<string>();

	const [filter, setFilter] = useState<JobFilter>(() => ({
		moveType: MoveType.Local,
	}));

	const dataKey: 'jobList' = 'jobList';

	const [paginationInfo, setPaginationInfo] = useState<{ hasNextPage?: boolean, after?: string| null | undefined }>({});

	const { data, loading, fetchMore, networkStatus, refetch } = useGetWorkOrderListQuery({
		fetchPolicy: 'network-only',
		variables: {
			...filter,
			searchStr: searchString,
			jobCategory,
		},
		onCompleted: (res) => {
			const pageInfo = res?.[dataKey]?.pageInfo;
			const edges = res?.[dataKey]?.edges;

			if (pageInfo && edges) {
				const { hasNextPage, endCursor } = pageInfo;
				const jobsCount = edges.length;

				setPaginationInfo({ hasNextPage, after: endCursor });
				const tab = capitalize(jobCategory) || 'Available';
				mixpanel.track(`${tab} Jobs Tab Renders`, {
					jobsCount,
					message: `${tab} page renders successfully`,
					jobCategory,
					filter,
					searchString,
				});
			}
		},
		notifyOnNetworkStatusChange: true,
	});


	useEffect(() => {
		refetch();
	}, [refetch]);

	const jobList = useMemo(
		// @ts-ignore
		() => getPaginatedDataNodeList<Order, any>(data && data[dataKey]),
		[data, dataKey]
	);

	useEffect(() => {
		setPaginationInfo({ hasNextPage: undefined, after: '' });
	}, [filter]);

	const onLoadMore = useCallback(() => {
		const { hasNextPage, after } = paginationInfo;

		if (hasNextPage && fetchMore) {
			fetchMore({
				variables: {
					after,
					...filter,
				},
				updateQuery: (prev: GetWorkOrderListQuery, { fetchMoreResult }): GetWorkOrderListQuery => {
					if (!fetchMoreResult) return prev;

					const newEdges = [
						...(prev[dataKey]?.edges || []),
						...(fetchMoreResult[dataKey]?.edges || [])
					].filter(Boolean);

					return {
						...fetchMoreResult,
						// @ts-ignore
						[dataKey]: {
							...fetchMoreResult[dataKey],
							edges: newEdges,
						},
					};
				}

			}).then((res) => {
				const pageInfo = res?.data?.[dataKey]?.pageInfo;

				if (pageInfo) {
					const { hasNextPage, endCursor } = pageInfo;
					setPaginationInfo({ hasNextPage, after: endCursor });
				}
			});
		}
	}, [fetchMore, paginationInfo, filter]);


	const handleWorkOrderModal = (props: any) => {
		if(jobCategory === JobCategory.New){
			const nextPage = `${window.location.pathname}${
				AppRoutePathList[AppRouteNameList.availableDetails]
			}`;
			history.push(getPathWithId(nextPage, props?.lroId), {
				weight: props?.weightToLoad
			});
		}
		if (jobCategory === JobCategory.Submitted) {
			setOpen(true);
			setWorkOrder(props);
		}
	};

	const handleSuggestBtnClicked = (lroId: string, weight: string) => {
		setOpen(false);
		history.push(getPathWithId(AppPathList.suggestPrice, lroId), { weight } );
	};

	const handlenAcceptBtnClicked = (lroId: string, weight: string) => {
		setOpen(false);
		history.push(getPathWithId(AppPathList.submitTruck, lroId), { weight });
	};

	const onEditWorkOrder = (lroId: string) => {
		let path = getPathWithId(
			`${window.location.pathname}${AppRoutePathList[AppRouteNameList.availableDetails]}`,
			lroId
		);
		let queryParams = '';

		const order = jobList.find((order) => order.id === lroId);

		if (order?.isBid) {
			queryParams = `?isBid=true&bidPrice=${order?.bidPrice}`;
		}

		setOpen(false);
		history.push(`${path}${queryParams}`);
	};

	const handleChangeMoveType = (moveType: MoveType) =>
		setFilter({
			...filter,
			moveType,
		});

	const handleChangeCargoType = (cargoType: CargoType) =>
		setFilter({
			...filter,
			cargoType,
		});

	const handleClearFilters = () => setFilter({ moveType: MoveType.Local });

	return (
		<>
			<Box className={classes.logo} mt={2}>
				<LoriLogo/>
			</Box>
			<Header />
			<ContentWrapper>
				<div className={classes.wrapper}>
					<MoveTypeFilter activeMoveType={filter?.moveType} onChange={handleChangeMoveType} />
					<JobSearchBlock
						filter={{ ...filter, searchStr: searchString }}
						onChangeSearchString={setSearchString}
						onChangeCargoType={handleChangeCargoType}
						handleClearFilters={handleClearFilters}
					/>
					<ItemListWithInfiniteScroll
						items={jobList}
						ItemTemplate={(props) => {
							// @ts-ignore
							return (
								<OrderCard
									{...props}
									handleClick={ (props) =>{
										handleWorkOrderModal(props)
									}}
									jobCategory={jobCategory}
									onAcceptBtnClicked={handlenAcceptBtnClicked}
									onSuggestBtnClicked={handleSuggestBtnClicked}
								/>
							);
						}}
						hasNextPage={!!paginationInfo?.hasNextPage}
						loading={loading || networkStatus === NetworkStatus.fetchMore}

						onLoadMore={onLoadMore}
					/>
				</div>
			</ContentWrapper>
			<AvailableJobsModal
				onSuggestBtnClicked={handleSuggestBtnClicked}
				onAcceptBtnClicked={handlenAcceptBtnClicked}
				open={open}
				setOpen={setOpen}
				item={workOrder}
				onEditWorkOrder={onEditWorkOrder}
			/>
		</>
	);
}
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { MoveType } from 'generate/types';
import { useReadUserInfo } from 'hooks';
import { useStyles } from './MoveTypeFilter.styles';
import cn from 'classnames';
import { Box } from '@material-ui/core';
import { useMixpanel } from 'analytics';
import { useLocation } from 'react-router-dom';
import lowerCase from 'lodash/lowerCase';
interface MoveTypeFilterProps {
	activeMoveType?: MoveType;
	onChange: (c: MoveType) => void;
}

export const MoveTypeFilter = ({ activeMoveType, onChange }: MoveTypeFilterProps) => {
	const { t } = useTranslation('buttons');
	const { pathname } = useLocation();
	const classes = useStyles();
	const mixpanel = useMixpanel();
	const jobType = pathname?.split('/')?.reverse()?.join(' ')?.trim() || 'Move type filter event';

	const { isSA } = useReadUserInfo();

	const filterList = isSA
		? [MoveType.Local]
		: Object.values(MoveType).filter((type) => type !== MoveType.Export);

	return (
		<Box justifyContent="center" display="flex">
			<Box className={classes.moveTypeWrapper} data-qa="qa-move-filter">
				<ul className={classes.list} data-qa="qa-filter-menu">
					{filterList.map((category) => {
						const isActive = category === activeMoveType;
						return (
							<li key={category} data-qa={`qa-${category}`}>
								<Button
									onClick={() => {
										onChange(category);
										mixpanel.track(`Click on ${lowerCase(category)} (${jobType} tab)`, {
											to: category,
											pathname,
											jobType,
											commonDenominator: 'MoveTypeFilter',
										});
									}}
									className={cn(classes.button, {
										[classes.active]: isActive,
									})}
									disableRipple
									data-qa={`qa-filter-button`}
								>
									{t(category.toLowerCase())}
								</Button>
							</li>
						);
					})}
				</ul>
			</Box>
		</Box>
	);
};

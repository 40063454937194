import {PropsWithChildren} from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {useMixpanel} from 'analytics';
import truckIcon from 'assets/svg/truck.svg';
import moveArrow from 'assets/svg/move_arrow.svg';
import {useStyles} from './OrderCard.styles';
import {useTranslation} from 'react-i18next';
import {JobCategory} from 'generate/types';
import {formatToDate, getPathWithId} from 'helpers';
import {COMMON, DATES, formatCurrency} from 'utils';
import { CustomButton } from 'components/button';

import {Order, Orders} from 'generate/types';
import {Typography} from '@material-ui/core';
import {AppRouteNameList, AppRoutePathList} from "../../router";
import {useHistory} from "react-router-dom";

interface OrderCardProps extends Partial<Orders> {
    jobCategory: JobCategory | undefined;
    handleClick: (props: Order | any) => void;
    onAcceptBtnClicked: (lroId: string, weight: string) => void;
    onSuggestBtnClicked: (lroId: string, weight: string) => void;
}

export function OrderCard(props: PropsWithChildren<OrderCardProps>) {
    const {
        lroId,
        trucksNeeded,
        trucksShortlisted,
        trucksMatched,
        commodity,
        destinationCity,
        originCity,
        deadlineToLift,
        currency,
        marketRate,
        transporterRateType,
        transportType,
        cargoType,
        packagingType,
        weightToLoad,
        // weight,
        // truckType,
        handleClick,
        jobCategory,
        onAcceptBtnClicked,
        onSuggestBtnClicked,
    } = props;
    const {t} = useTranslation('job');
    const {t: tBtn} = useTranslation('buttons');
    const classes = useStyles();
    const mixpanel = useMixpanel();
    const history = useHistory();

    const getNoOfTrucks = () => {
        let noOfTrucks: number | string = 0;
        if (jobCategory === JobCategory.Submitted)
            noOfTrucks = trucksShortlisted || 0;
        if (jobCategory === JobCategory.Matched) noOfTrucks = trucksMatched || 0;
        if (jobCategory === JobCategory.New) noOfTrucks = trucksNeeded || 0;
        if (noOfTrucks === 0) return noOfTrucks;
        const suffix = noOfTrucks === 1 ? ' Truck' : ' Trucks';
        return noOfTrucks + suffix;
    };

    const handleReviewDetailsBtnClicked = (lroId: string) => {
        let path = getPathWithId(
            `${window.location.pathname}${AppRoutePathList[AppRouteNameList.availableDetails]}`,
            lroId
        );
        history.push(`${path}`);
    };

    return (
        <Paper className={classes.root} elevation={3}>
            <button
                className={classes.wrapperButton}
                onClick={() => {
                    handleClick(props);
                }}

                type='button'
            >
                <div className={classes.content}>
                    <div>
                        <div className={classes.tripType}>
                            <Typography className={classes.tripTypeSwatches}>
                                {cargoType}
                            </Typography>
                            {
                                transportType && (
                                    <Typography className={classes.tripTypeSwatches}>
                                        {transportType}&nbsp;Trip
                                    </Typography>
                                )
                            }
                        </div>
                        <div className={classes.load}>
                            <Typography className={classes.commodity}>{commodity}</Typography>
                            <Typography className={classes.truckCount}>
                                {`${getNoOfTrucks() ?? COMMON.noDataProvided}`}
                            </Typography>
                        </div>
                        <div className={classes.packagingType}>
                            <Typography>
                                {packagingType}
                            </Typography>
                            {weightToLoad &&
                                <Typography>
                                    &nbsp;{`(${weightToLoad} tons)`}
                                </Typography>
                            }
                        </div>
                        <div className={classes.tripRates}>
                            <Typography className={classes.priceDetails}>
                                {currency}&nbsp;{formatCurrency(marketRate)}&nbsp;
                            </Typography>
                            <Typography className={classes.truckDistanceType}>
                                {transporterRateType}
                            </Typography>
                        </div>
                        <div className={classes.tripCities}>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                className={classes.truckIconContainer}
                            >
                                <img className={classes.truckIcon} src={truckIcon} alt="truck-icon"/>
                            </Box>
                            <Typography component='p' className={classes.departure}>
                                {originCity}
                            </Typography>
                            <Box>
                                <img className={classes.moveArrowImg} src={moveArrow} alt="move-arrow"/>
                            </Box>
                            <Typography component="p" className={classes.destination}>
                                {destinationCity}
                            </Typography>
                        </div>
                        {jobCategory !== JobCategory.Matched && (<div className={classes.deadline}>
                            <Typography component='p' variant='body2' color='textSecondary'>
                                {t('deadline_to_load')}:{' '}
                                {formatToDate(deadlineToLift, DATES.loadDateFormatNoTime) ||
                                    COMMON.noDataProvided}
                            </Typography>
                        </div>)}
                    </div>
                </div>
            </button>
            {
                jobCategory === JobCategory.New && (
                    <div className={classes.jobActions}>
                        <Button
                            data-qa="qa-accept"
                            className={classes.btnAccept}
                            disableRipple
                            onClick={() => {
                                onAcceptBtnClicked(lroId || '', weightToLoad || '');
                                mixpanel.track('Accept now clicks', {
                                    message: 'Accept order clicked',
                                    ID: lroId,
                                    orderValue: marketRate,
                                    currency: currency,
                                });
                            }}
                        >
                            {tBtn('accept')}
                        </Button>
                        <Button
                            data-qa="qa-suggest-price"
                            className={classes.btnSuggestPrice}
                            disableRipple
                            onClick={() => {
                                onSuggestBtnClicked(lroId || '', weightToLoad || '');
                                mixpanel.track('Suggest price clicks', {
                                    message: 'Suggest price clicked',
                                    ID: lroId,
                                    orderValue: marketRate,
                                    currency: currency,
                                });
                            }}
                        >
                            {tBtn('suggest_price')}
                        </Button>
                    </div>
                )
            }
            {
                jobCategory === JobCategory.Matched && (
                    <Box paddingX={2} paddingBottom={2} className={classes.jobActions}>
                        <CustomButton data-qa="qa-review" color="primary" className={classes.btnReview}
                                      onClick={() => {
                                          handleReviewDetailsBtnClicked(lroId || '');
                            mixpanel.track('Review Details', {
                                message: 'Review Details clicked',
                                ID: lroId
                            });
                        }}>
                            {tBtn('review_details')}
                        </CustomButton>
                    </Box>
                )
            }
        </Paper>
    );
}

import React, { createContext, PropsWithChildren, useCallback, useState } from 'react';
import { useModalStateControls } from 'hooks';
import { ResultModalDataProps } from 'components';
import { ApolloError } from '@apollo/client';
import { useMixpanel } from 'analytics';

interface IResultContext {
	toggleResult: (modalData?: ResultModalDataProps) => void;
	openResult: (modalData?: ResultModalDataProps) => () => void;
	openResultCb: () => void;
	closeResult: () => void;
	openErrorResult: (e?: ApolloError, modalData?: ResultModalDataProps) => void;
	resultData?: ResultModalDataProps;
	isResultOpened: boolean;
}

export const ResultContext = createContext<IResultContext>({
	isResultOpened: false,
	closeResult: () => {},
	openResult: () => () => {},
	openResultCb: () => {},
	openErrorResult: () => {},
	toggleResult: () => {},
});

export const ResultContextProvider = ({ children }: PropsWithChildren<{}>) => {
	const [resultData, setResultData] = useState<ResultModalDataProps>();
	const mixpanel = useMixpanel();
	const { pathname } = window.location;

	const { isOpened, toggleModal, openModal, closeModal } = useModalStateControls();

	const openResult = useCallback(
		(modalData?: ResultModalDataProps) => () => {
			setResultData(modalData);
			openModal();
			mixpanel.track(modalData?.title || 'Server response', {
				message: modalData?.text,
				type: modalData?.type,
				pathname,
			});
		},
		[openModal, mixpanel, pathname]
	);

	const openErrorResult = useCallback(
		(e?: ApolloError, modalData?: ResultModalDataProps) => {
			setResultData({
				type: 'error',
				text: modalData?.text || e?.message,
				title: modalData?.title,
			});
			openModal();
		},
		[openModal]
	);

	const closeResult = useCallback(() => {
		setResultData(undefined);
		closeModal();
	}, [closeModal]);

	const toggleResult = useCallback(
		(modalData?: ResultModalDataProps) => {
			setResultData(modalData);
			toggleModal();
			mixpanel.track(modalData?.title || 'Modal opened', {
				message: modalData?.text,
				type: modalData?.type,
				pathname,
			});
		},
		[toggleModal, mixpanel, pathname]
	);

	return (
		<ResultContext.Provider
			value={{
				isResultOpened: isOpened,
				closeResult,
				openResult,
				openResultCb: openModal,
				openErrorResult,
				toggleResult,
				resultData,
			}}
		>
			{children}
		</ResultContext.Provider>
	);
};

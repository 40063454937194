import { debounce } from 'lodash';

/*
 *   Fix mobile viewport height issue related to the search bar at the top of the mobile screen
 * */

export function setViewportHeight() {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}

export const setViewportHeightThrottled = debounce(setViewportHeight, 300);

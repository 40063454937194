import { theme } from 'utils/theme';
import { makeStyles } from '@material-ui/core/styles';
import { STYLES, COLORS } from 'utils';

export const useStyles = makeStyles({
	tabs: {
		marginTop: theme.spacing(1),
		padding: '4px 8px',
		background: '#F9F9F9',
		borderRadius: 5
	},
	indicator: {
		display: 'none',
	},
	tab: {
    // @ts-ignore
		...theme.typography.subtitle1,
		paddingLeft: 0,
		paddingRight: 0,
		fontSize: 18,
		fontWeight:300,
		'& + &': {
			marginLeft: theme.spacing(2),
		},
	},
	tabSelected: {
		fontWeight: 500,
		border: 'none !important',
		boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.04);',
		borderRadius: 5,
		background: '#FFFEFE',
		color: COLORS.primary
	},
	maxWidth: {
		maxWidth: 600,
		margin: '0 auto',
	},
	overflow: STYLES.overflow,
	navWrapper: {
		border: `1px solid ${COLORS.white}`,
		height: 45,
		borderRadius: 3,
		backgroundColor: COLORS.white,
	},
	navLink: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '1rem',
		color: COLORS.disabled,
		position: 'relative',
		textTransform: 'capitalize',
		padding: '0 12px',
		height: 38,
		textAlign: 'center',
	},
	navLinkActive: {
		color: COLORS.text,
		transform: 'scale(1.1)',
		fontWeight: STYLES.medium,
		backgroundColor: COLORS.activeLink,
		borderRadius: 21,
		'&::after': {
			opacity: 1,
		},
	},
});

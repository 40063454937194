import { gql } from '@apollo/client';

export const getFuelLimit = gql`
	query getFuelLimit($moveId: Int) {
		maxFuelLimit(moveId: $moveId) {
			waFuelOptions
			maxFuelAmount
		}
	}
`;

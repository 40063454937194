import React from 'react';
import { CustomButton, CustomButtonProps } from 'components/button';
import { CustomLink } from './CustomLink';
import { LinkProps as RouterLinkProps } from 'react-router-dom';

interface ButtonLinkProps extends CustomButtonProps, Pick<RouterLinkProps, 'to'> {}

export function ButtonLink({ children, ...props }: ButtonLinkProps) {
	return (
		<CustomButton {...props} component={(props) => <CustomLink {...props} hideUnderline />}>
			{children}
		</CustomButton>
	);
}

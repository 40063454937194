import { gql } from '@apollo/client';

export const userDetails = gql`
	fragment userDetails on User {
		id
		username
		email
		attributes {
			transporterId
			transporter
			companyId
			companyMobile
			region
			accountSuspended
		}
	}
`;

import { useEffect, useState } from 'react';
import { RequestFuelForm } from 'containers/forms';
import {
	ConfirmedJobDetailsFragment,
	CreateFuelRequestMutationVariables,
	FuelStation,
	JobCategory,
	Move,
	useCreateFuelRequestMutation,
	useGetFuelStationListQuery,
	useGetMoveListQuery,
} from 'generate/types';
import { useModalStateControls, useReadUserInfo, useResultContext } from 'hooks';
import { ResultModal } from 'components';
import { formatToDate, getErrorText, getOptionList, getPaginatedDataNodeList } from 'helpers';
import { RequestFuelFormNames, ResponseError } from 'models';
import { DATES, FUEL_TYPES } from 'utils';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import Typography from '@material-ui/core/Typography';
import { useMixpanel } from 'analytics';
import { useLocation } from 'react-router-dom';
import liaSupplyClient from '../../graphqlapi/clients/liaSupplyClient';
import { ConfirmFuelRequest } from '../../components/modals/confirm-fuel-request';

export const RequestFuelTab = () => {
	const { t } = useTranslation('errors');
	const { t: tM } = useTranslation('modals');
	const [error, setError] = useState<ResponseError>();
	// @ts-ignore
	const [move, setMove] = useState<Move | undefined>({});
	const [requestData, setRequestData] = useState<CreateFuelRequestMutationVariables>();
	const [isConfirmed, setIsConfirmed] = useState<boolean>();
	const { isOpened, toggleModal } = useModalStateControls();
	const { isOpened: isConfirmRequestOpened, toggleModal: toggleConfirmRequestModal } =
		useModalStateControls();
	const { openErrorResult, openResultCb } = useResultContext();

	const { pathname } = useLocation();
	const mixpanel = useMixpanel();

	const moveId = move?.moveId ?? move?.moveId;
	const { transporter: transporterName } = useReadUserInfo();

	// ---------------------------- Mixpanel -----------------------------------//
	useEffect(() => {
		mixpanel.track('Fuel Page Render', {
			message: 'Request fuel tab renders successfully',
			pathname,
		});
	}, [mixpanel, pathname]);

	const {
		data,
		loading: isMoveLoading,
		error: moveError,
	} = useGetMoveListQuery({
		variables: {
			first: undefined,
			jobCategory: JobCategory.Fueling,
		},
		notifyOnNetworkStatusChange: true,
	});

	const { data: stationData, loading: isStationLoading } = useGetFuelStationListQuery({
		variables: {
			moveId: moveId as number,
		},
		skip: typeof moveId !== 'number',
		onError: openErrorResult,
		onCompleted: () => {
			mixpanel.track('Fuel Request (select confirmed job)', { moveId });
		},
	});

	const [createFuelRequest, { loading: isCreateFuelRequestLoading }] = useCreateFuelRequestMutation(
		{
			client: liaSupplyClient,
			onCompleted: () => {
				mixpanel.track('Fuel Request (success)');
				openResultCb();
				setMove(undefined);
				setIsConfirmed(false);
			},
			onError: (error) => {
				mixpanel.track('Fuel Request (Failure)', {
					error: error?.message || 'failed to request fuel',
				});

				openErrorResult(error);
				setIsConfirmed(false);
			},
		}
	);

	useEffect(() => {
		if (error) {
			toggleModal();
		}
	}, [error, toggleModal]);

	useEffect(() => {
		if (!isOpened) {
			setError(undefined);
		}
	}, [isOpened]);

	const stationList = () => {
		if (stationData?.fuelStations) {
			// @ts-ignore
			return getOptionList(
				getPaginatedDataNodeList<FuelStation, any>(stationData.fuelStations),
				['name'],
				'id'
			);
		}
		return [];
	};
	const fuelTypesList = () => {
		return getOptionList(FUEL_TYPES, ['name'], 'value');
	};
	const getFuelRequestData = () => {
		const fuelRequestMove = move;
		const fuelingStation = stationData?.fuelStations?.edges.find(
			(edge) => edge?.node?.id === requestData?.input.fuelStationId
		);
		return { ...requestData?.input, move: fuelRequestMove, fuelingStation: fuelingStation };
	};

	const confirmRequestDetails = () => {
		toggleConfirmRequestModal();
		setIsConfirmed(true);
	};

	const handleCloseConfirmDialog = () => {
		toggleConfirmRequestModal();
		setIsConfirmed(false);
	};

	const onSubmit = (data: Partial<Record<RequestFuelFormNames, unknown>>) => {
		// add confirmation step

		const { amount, date, fuelingPoint, fuelType } = data;
		setRequestData({
			input: {
				fuelStationId: fuelingPoint as number,
				fuelRequestAmount: amount as number,
				fuelreqtra: formatToDate(date, DATES.BEDate),
				fuelType: fuelType as string,
				lroId: moveId,
				username: transporterName,
			},
		});
	};

	useEffect(() => {
		if (requestData) {
			toggleConfirmRequestModal();
		}
	}, [requestData, toggleConfirmRequestModal]);

	useEffect(() => {
		if (requestData && isConfirmed) {
			mixpanel.track('Fuel Request (submit fuel request)');
			createFuelRequest({
				variables: requestData,
			});
		}
	}, [createFuelRequest, isConfirmed, mixpanel, requestData]);

	const jobList = error ? [] : getPaginatedDataNodeList<Move, any>(data && data['myjobList']);

	if (moveError) {
		return <Typography>{moveError.message}</Typography>;
	}
	const isLoading = isMoveLoading || isStationLoading;

	return (
		<div>
			<RequestFuelForm
				onSubmit={onSubmit}
				move={move as ConfirmedJobDetailsFragment}
				stationList={stationList()}
				isSubmitting={isCreateFuelRequestLoading}
				jobList={jobList}
				fuelTypes={fuelTypesList()}
				loading={isLoading}
				setMove={setMove}
				fieldsDisabled={isEmpty(move)}
			/>
			<ConfirmFuelRequest
				open={isConfirmRequestOpened}
				onConfirmFuelRequest={confirmRequestDetails}
				onClose={handleCloseConfirmDialog}
				loading={isLoading}
				fuelRequest={getFuelRequestData()}
			/>
			<ResultModal
				type={error ? 'error' : 'success'}
				isOpened={isOpened}
				onClose={toggleModal}
				text={getErrorText(error, t) || tM('request_successful')}
			/>
		</div>
	);
};

import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { AppPathList } from 'router';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils';
import cn from 'classnames';

import homeSvg from 'assets/svg/home.svg';
import homeActiveSvg from 'assets/svg/homeActive.svg';
import profileActiveSvg from 'assets/svg/profile_active.svg';
import profileSvg from 'assets/svg/profile.svg';
import servicesSvg from 'assets/svg/services.svg';
import servicesActiveSvg from 'assets/svg/services_active.svg';
import jobsSvg from 'assets/svg/jobs.svg';
import jobsActiveSvg from 'assets/svg/jobs_active.svg';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';
import { IFooterButton, FooterButton } from './FooterButton';
import { useReadUserInfo } from 'hooks';
import { checkIsAdmin } from 'helpers';

const useStyles = makeStyles({
	footer: {
		display: 'flex',
		backgroundColor: '#7FC0B5',
	},
	footerButton: {
		padding: 5,
		textAlign: 'center',
		textDecoration: 'none',
		color: '#fff',
		fontSize: 12,
		whiteSpace: 'nowrap',
		transition: '.3s ease',
		'&:hover': {
			color: COLORS.active,
		},
		'&.active': {
			color: COLORS.active,
		},
	},
	container: {
		justifyContent: 'space-between',
		paddingTop: 5,
	},
});

export function Footer() {
	const { t } = useTranslation('buttons');
	const classes = useStyles();
	const { pathname } = useLocation();
	const { role } = useReadUserInfo();

	const isAdmin = useMemo(() => checkIsAdmin(role), [role]);
	const buttonList: IFooterButton[] = useMemo(
		() => [
			{
				link: AppPathList.available,
				icon: homeSvg,
				iconActive: homeActiveSvg,
				text: t('home'),
				isDisabled: !isAdmin,
			},
			{
				link: AppPathList.jobs,
				icon: jobsSvg,
				iconActive: jobsActiveSvg,
				text: t('jobs'),
				isDisabled: !isAdmin,
			},
			{
				link: AppPathList.auxiliaryServices,
				icon: servicesSvg,
				iconActive: servicesActiveSvg,
				text: t('services'),
				isDisabled: !isAdmin,
			},
			{
				link: AppPathList.account,
				icon: profileSvg,
				iconActive: profileActiveSvg,
				text: t('profile'),
				isDisabled: !isAdmin, // isGuest,
			},
		],
		[t, isAdmin]
	);

	return (
		<footer className={classes.footer}>
			<Container className={classes.container}>
				{buttonList.map((button) => {
					return (
						<FooterButton
							key={button.text}
							className={cn(classes.footerButton, {
								active: pathname.includes(button.link as string),
							})}
							isActive={pathname.includes(button.link as string)}
							src={pathname.includes(button.link as string) ? button.iconActive : button.icon}
							{...button}
						/>
					);
				})}
			</Container>
		</footer>
	);
}

import { useCallback, useContext } from 'react';
import { ModalWrapper } from '../ModalWrapper';
import { ReportIncidentForm } from 'containers';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';

import { useStyles } from './ReportIncident.styles';
import { useCreateIssueMutation } from 'generate/liaDemand'
import { liaSupplyClient } from 'graphqlapi';
import { ResultContext } from 'context';
import { ReportIncidentNames } from 'models';
import { useReadUserInfo } from 'hooks';

interface ReportIncidentProps {
	onClose: () => void;
	onSubmit: () => void;
	truckReg?: string | null;
}

export const ReportIncident = ({ onClose, truckReg }: ReportIncidentProps) => {
	const { id } = useParams<{ id: string }>();

	const { t } = useTranslation('modals');
	const classes = useStyles();
	const { email, transporter } = useReadUserInfo();

  const { openResultCb, openErrorResult } = useContext(ResultContext);

	const handleCompleted = useCallback(() => {
		onClose();
		openResultCb();
	}, [openResultCb, onClose]);

	const [createIssue] = useCreateIssueMutation({
		client: liaSupplyClient,
		onCompleted: handleCompleted,
		onError: openErrorResult,
	});

	const handleSubmit = useCallback(
		(data: Record<ReportIncidentNames, unknown>) => {
      createIssue({
        variables: {
          // @ts-ignore
          moveId: id as string,
          details: data.details as string,
          userId: email as string,
          userName: transporter as string,
          // @ts-ignore
          typeId: parseInt(data.typeId, 10) as number,
          // @ts-ignore
          businessDomainId: parseInt(data.incidentType, 10) as number,
        },
      });
		},
		[createIssue, id, email, transporter]
	);

	return (
		<ModalWrapper open={true} onClose={onClose} withCloseBtn={false}>
			<>
				<Typography className={classes.title}>{t('report_incident')}</Typography>
				<Typography className={classes.subTitle} component="p">
					{truckReg}
				</Typography>
				<ReportIncidentForm onClose={onClose} onSubmit={handleSubmit} />
			</>
		</ModalWrapper>
	);
};

import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { errorLink } from '../links';
import { API } from 'utils';
import { cache } from '../cache';

const { createUploadLink } = require("apollo-upload-client");

const httpLink = createUploadLink({
  uri: `${API.LIASupplyService.url}`,
});

const liaSupplyLink = errorLink().concat(httpLink);

export const liaSupplyClient = new ApolloClient<NormalizedCacheObject>({
	link: liaSupplyLink,
	cache,
	queryDeduplication: false,
	connectToDevTools: true,
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'cache-and-network',
		},
		query: {
			fetchPolicy: 'network-only',
		},
	},
});

export default liaSupplyClient;

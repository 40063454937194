import React from 'react';
import { CustomButton } from '../button';
import { TRUCK_LIST_DATA_EXTENSION_LIST } from 'utils';

interface UploadTruckListProps {
	onUploadData: (file: File) => void;
}

export const UploadTruckList = ({ onUploadData }: UploadTruckListProps) => {
	const handleChange = React.useCallback(
		({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
			if (files && files[0]) {
				onUploadData(files[0]);
			}
		},
		[onUploadData]
	);

	return (
		<CustomButton component="label" disabled>
			Upload CSV
			<input
				id={'data'}
				type="file"
				accept={TRUCK_LIST_DATA_EXTENSION_LIST}
				hidden
				onInput={handleChange}
			/>
		</CustomButton>
	);
};

import { useState } from 'react';
import { Formik } from 'formik';
import { TruckListForm as TruckListFormTemp } from 'components';
import { ISelectOptionList } from '../form-fields';
import { useTruckListForm } from 'hooks';
import { Truck } from 'generate/typesSupplyService';
import { ConfirmAssetList } from 'generate/types';

interface SubmitTruckFormProps {
	truckList: Truck[];
	driverList?: ISelectOptionList | null;
	trailerList?: ISelectOptionList | null;
	onSubmit: (truckList: [ConfirmAssetList]) => void;
	isLoading?: boolean;
	maxTruckLength?: number | null;
	withSearchFilter?: boolean;
}

export const TruckSubmitListForm = ({
	truckList = [],
	onSubmit,
	isLoading,
	driverList,
	trailerList,
	withSearchFilter,
}: SubmitTruckFormProps) => {
	const [filter, setFilter] = useState<string>();

	const { initialValues, validationSchema, handleSubmit, validateMinTrucks } = useTruckListForm({
		truckList,
		onSubmit,
	});

	return (
		<>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
				validate={validateMinTrucks}
			>
				{({ values, errors }) => {
					const filteredList = () => {
						if (!filter) return values;

						const filterValue = filter.toLowerCase();

						return Object.fromEntries(
							Object.entries(values).filter((value) => value[0].toLowerCase().includes(filterValue))
						);
					};
					return (
						<TruckListFormTemp
							errors={errors}
							onChangeFilter={setFilter}
							truckListData={filteredList()}
							isLoading={isLoading}
							driverList={driverList}
							trailerList={trailerList}
							withSearchFilter={withSearchFilter}
						/>
					);
				}}
			</Formik>
		</>
	);
};

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	selectInput: {
		marginBottom: 20,
		'& input': {
			boxSizing: 'border-box',
		},
	},
});

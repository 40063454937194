import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils';

export const useStyles = (open: boolean) =>
	makeStyles({
		button: {
			whiteSpace: 'nowrap',
			fontSize: 12,
			textTransform: 'none',
			flexShrink: 0,
			height: 43,
		},
		input: {
			display: 'flex',
			'& .MuiOutlinedInput-adornedStart': {
				paddingLeft: 0,
			},
			'& .MuiOutlinedInput-inputAdornedStart': {
				borderLeft: `1px solid ${COLORS.inputBorder}`,
				paddingLeft: 14,
			},
		},
		icon: {
			fill: COLORS.gray,
			width: '1rem',
			flexShrink: 0,
			transition: '.2s ease-out',
			transform: open ? 'rotate(180deg)' : '',
		},
		popper: {
			zIndex: 10,
		},
	});

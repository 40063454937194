import { gql } from '@apollo/client';

export const driverDetails = gql`
	fragment driverDetails on Driver {
		driverId
		driverName
		driverIdNumber
		name
		countryCode
		driverPhoneNumber
		transporterId
		transporter
		active
	}
`;

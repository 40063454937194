import { ConfirmAssetList } from "generate/types";

export enum SubmitTruckFormNames {
	truckListData = 'truckListData',
}
export interface ITruckFormData extends ConfirmAssetList {
	checked?: boolean;
}

export interface SubmitTruckFormData extends Record<string, ITruckFormData> {}

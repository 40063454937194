import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import {
  CustomCheckboxField,
  CustomDatePickerField,
  ISelectOptionList,
} from 'containers';
import { TruckType } from 'generate/typesLIA';
import { checkAssetOptionList, getExternalDriverTitle } from 'helpers';
import { IPickedAssets, ITruckAssets } from 'models';
import { useTranslation } from 'react-i18next';
import { ChevronIcon } from '../icons';
import { CustomSelectField } from './CustomSelectField';
import { useStyles } from './TruckCard.styles';

interface TruckCardProps extends Omit<IPickedAssets, 'trucksAssets'> {
  truckReg: TruckType['truckReg'];
  isOpen?: boolean;
  isChecked?: boolean;
  isAssetsEditable?: boolean;
  driverOptionList?: ISelectOptionList | null;
  trailerOptionList?: ISelectOptionList | null;
  isDriversDisabled?: boolean;
  isTrailersDisabled?: boolean;
  isConfirming?: boolean;
  hideTrailers?: boolean;
  truckAssets?: ITruckAssets;
  setSearchDriver: (v: string) => void;
  isDriverLoading?: boolean;
}

export const TruckCardWA = ({
  truckReg,
  isOpen = false,
  isChecked,
  isAssetsEditable,
  driverOptionList,
  trailerOptionList,
  pickedDrivers,
  isDriversDisabled,
  truckAssets,
  isConfirming,
  setSearchDriver,
  isDriverLoading,
}: TruckCardProps) => {
  const classes = useStyles();
  const { t } = useTranslation('form-fields');

  const [trailerList, setTrailerList] = useState<ISelectOptionList | null | []>([]);
  const [driverList, setDriverList] = useState<ISelectOptionList | null | []>([]);

  useEffect(() => {
    if (!trailerList) {
      setTrailerList(
        // @ts-ignore
        checkAssetOptionList(
          trailerOptionList,
          isConfirming,
          truckAssets?.trailerReg
        )
      );
    }
  }, [isConfirming, trailerList, trailerOptionList, truckAssets]);

  useEffect(() => {
    if (!isDriverLoading) {
      setDriverList(
        // @ts-ignore
        checkAssetOptionList(
          driverOptionList,
          isConfirming,
          truckAssets?.driverData,
          getExternalDriverTitle(truckAssets?.driverData)
        )
      );
    }
  }, [isConfirming, driverOptionList, truckAssets, isDriverLoading]);

  const [open, setOpen] = useState(() => isOpen);

  const handleClick = useCallback(() => setOpen(!open), [open]);
  useEffect(() => (isChecked ? setOpen(true) : setOpen(false)), [isChecked]);

  return (
    <div key={truckReg} className={classes.wrapper}>
      <div className={classes.header}>
        <CustomCheckboxField
          label={truckReg || ''}
          name={`${truckReg}.checked`}
        />
        <ChevronIcon
          className={cn(classes.arrow, {
            [classes.up]: open,
          })}
          pointer
          onClick={handleClick}
        />
      </div>
      <div
        className={cn(classes.content, {
          [classes.hide]: !open,
        })}
      >
        <CustomSelectField
          name={`${truckReg}.driverData`}
          label={t('submit_track.driver_details')}
          options={driverList}
          disabled={!isAssetsEditable || isDriversDisabled}
          disabledValues={pickedDrivers}
          setSearchDriver={setSearchDriver}
        />
        <CustomDatePickerField
          fast
          onlyYupValidation
          name={`${truckReg}.etaLoadingPoint`}
          label={t('submit_track.eta_to_loading')}
          disabled={isConfirming}
          withTime
        />
      </div>
    </div>
  );
};

import React, {  } from 'react';
import cn from 'classnames';
import { useStyles } from './Stepper.styles';
import {Box, Typography} from "@material-ui/core";

export interface IStepperProps {
	steps: number;
	activeStep: number;
	className?: string;
	onClick?: (idx: number) => void;
}

export const Stepper = ({ steps, activeStep, className, onClick }: IStepperProps) => {
	const classes = useStyles();

	return (
		<ul className={cn(className, classes.list)}>
			{Array.from(Array(steps), (_, i) => (
				<li key={i}>
					<Box display="flex" flexDirection="row" textAlign="left" justifyContent="center" alignItems="center">
						<Box className={cn(className, classes.stepIndicator, {
							[classes.stepIndicatorActive]: activeStep > i,
							[classes.pointer]: !!onClick,
						})}>
							<Typography variant="h6" component="h6" align="left" className={cn(className, classes.stepIndicatorText, {
								[classes.stepIndicatorTextActive]: activeStep > i,
							})}>{i+1}</Typography>
						</Box>
					{i < steps - 1 && <hr className={cn(className, classes.stepsConnector)} color="#FFC46C"/>}
					</Box>
				</li>
			))}
		</ul>
	);
};
import { gql } from '@apollo/client';

export const supplyServiceCreateTruck = gql`
	mutation createTruck(
		$truckReg: String!
		$transporterId: Int!
		$country: String!
		$truckTypeId: Int!
    $source: Source!
    $createdBy: String
	) {
		createTruck(
      source: $source
			truckData: {
				truckReg: $truckReg
				transporterId: $transporterId
				country: $country
				truckTypeId: $truckTypeId
				createdBy: $createdBy
			}
		) {
			status
			message
		}
	}
`;

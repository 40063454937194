import { gql } from '@apollo/client';

export const userInfo = gql`
	fragment userInfo on User {
		message
		status
		user_id
		first_name
		last_name
		id_number
		email
		role
		verified
		region
		company_id
		created_at
		preferred_username
		first_login
		email_verified
		accepted_terms
		transporter
		transporter_id
		company_mobile
		country_iso_code
	}
`;

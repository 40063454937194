import { gql } from '@apollo/client';

export const importTrailers = gql`
	mutation importTrailers($csvFile: Upload!) {
		importTrailers(csvFile: $csvFile) {
			ok
			message
			response
		}
	}
`;

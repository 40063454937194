export enum FormIdList {
	companyInfo = 'companyInfo',
	uploadDocumentation = 'uploadDocumentation',
	bankDetails = 'bankDetails',
	personalInformation = 'personalInformation',
	companyDetails = 'companyDetails',
	contactInformation = 'contactInformation',
	insuranceDetails = 'insuranceDetails',

	login = 'login',
	loginCode = 'loginCode',

	resetPassword = 'resetPassword',
	forgetPassword = 'forgetPassword',

	truckList = 'truckList',
}

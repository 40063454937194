import React from 'react';
import {
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
	AccordionDetailsProps,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { AccordionProps } from '@material-ui/core/Accordion/Accordion';

interface IAccordionTab {
	id: string;
	title: React.ReactElement | string;
	details?: React.ReactElement | string | null;
}

interface CustomAccordionProps {
	accordionTabList: IAccordionTab[];
	accordionProps?: Omit<AccordionProps, 'children'>;
	accordionDetailsProps?: Omit<AccordionDetailsProps, 'children'>;
	detailsFallback?: React.ReactElement;
}

export const CustomAccordion: React.FC<CustomAccordionProps> = ({
	accordionTabList,
	detailsFallback,
	accordionProps = {},
	accordionDetailsProps = {},
}) => {
	return (
		<>
			{accordionTabList.map(({ id, title, details }) => (
				<Accordion key={id} {...accordionProps}>
					<AccordionSummary
						expandIcon={<ExpandMore />}
						aria-controls={`${id}-content`}
						id={`${id}-header`}
					>
						{typeof title === 'string' ? <Typography>{title}</Typography> : title}
					</AccordionSummary>
					<AccordionDetails {...accordionDetailsProps}>
						{details || detailsFallback}
					</AccordionDetails>
				</Accordion>
			))}
		</>
	);
};

import { useMemo } from 'react';
import { MyJobDetailsCompleted as MyJobDetailsCompletedTemp, Loader } from 'components';
import { JobCategory, Order, useGetWorkOrderQuery } from 'generate/types';

import { useParams } from 'react-router-dom';
import { mapToDetailsList } from 'helpers';
import { useModalStateControls } from 'hooks';

const detailsList: Array<keyof Order> = [
	// 'loadedDate'

	'moveId',
	'truckReg',
	'trailerNumber',
	'destination',

	// 'etaProvided'
	// 'actualArrivalTime'

	'currency',
	'marketRate',
	'transporterRateType',

	// 't1'
	// 'interchange'
	// 'loriLoadingOrder'
	// 'pod'
];

export const MyJobDetailsCompleted = () => {
	const { isOpened, toggleModal } = useModalStateControls();
	const { id } = useParams<{ id: string }>();

  const { data, loading } = useGetWorkOrderQuery({
		fetchPolicy: 'network-only',
    variables: {
      jobCategory: JobCategory.OnJourney,
      moveId: parseInt(id)
    },
		onError: (error) => {
			return error;
		},
  });

  const detailsListData = useMemo(() => {
		return mapToDetailsList(data?.workOrder as Order, detailsList);
	}, [data]);

	return (
		<Loader isLoading={loading}>
			<MyJobDetailsCompletedTemp
				isOpened={isOpened}
				toggleModal={toggleModal}
				detailsList={detailsListData}
			/>
		</Loader>
	);
};
/*

return detailsOrder.reduce((res, key) => {
			let value: string | number | boolean | React.ReactElement = testData[key];
			if (key === 't1' || key === 'interchange' || key === 'loriLoadingOrder' || key === 'pod') {
				value = renderRecord(value);
			}
			return {
				...res,
				[key]: value,
			};
		}, {});

		function renderRecord(isUploaded: string | number | boolean) {
			if (typeof isUploaded !== 'boolean') return isUploaded;
			return (
				<span style={{ color: isUploaded ? COLORS.confirm : COLORS.danger }}>
					{isUploaded ? t('uploaded') : t('not_uploaded')}
				</span>
			);
		}

* */

import { gql } from '@apollo/client';

export const getPersonalDetails = gql`
	query getPersonalDetails {
		profile {
			firstName
			lastName
			idNumber
			id
			vettingConsent
		}
	}
`;

import React from 'react';
import { IAppPathList, IRoute } from 'models';
import { createAppRouteList } from './createAppRouteList';

export function createRouteListWithComponents(
	routeList: IRoute,
	pathList: IAppPathList,
	componentToRenderMap: Record<string, React.ElementType>
) {
	return createAppRouteList(routeList, pathList)
		.map((appRoute) => ({
			...appRoute,
			Component: componentToRenderMap[appRoute.name],
		}))
		.filter((routeWithComponent) => !!routeWithComponent.Component);
}

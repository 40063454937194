import React, { useCallback } from 'react';
import { useDidUpdateEffect, useResultContext } from 'hooks';
import { useResetPasswordMutation, ResetPasswordMutationVariables } from 'generate/types';
import { ResetPasswordForm } from 'containers';
import { AppPathList } from 'router';
import { useHistory } from 'react-router-dom';
import { authContext } from 'utils';
import { useTranslation } from 'react-i18next';
import { useMixpanel } from 'analytics';

export const ResetPassword = () => {
	const { openErrorResult, isResultOpened, toggleResult } = useResultContext();
	const { push } = useHistory();
	const { t: tM } = useTranslation('modals');
	const { t: tL } = useTranslation('login');
	const { t: tC } = useTranslation('common');
	const mixpanel = useMixpanel();

	const [resetPassword, { error, loading }] = useResetPasswordMutation({
		context: authContext,
		onCompleted: ({ resetPassword }) => {
			mixpanel.track('Reset password success ', {
				message: resetPassword?.message || 'Password reset successfully',
			});
			toggleResult({
				title: tL('reset_password_success_title'),
				text: tL('reset_password_success_message'),
			});
		},
		onError: (error) => {
			mixpanel.track('Reset password failure', {
				message: error?.message || 'No error message',
			});
			openErrorResult(error, {
				title: tM('oops'),
				text: error?.message || tC('generic_error_message'),
				type: 'error',
			});
		},
	});

	const handleSubmit = useCallback(
		(variables: ResetPasswordMutationVariables) => {
			resetPassword({ variables });
		},
		[resetPassword]
	);

	useDidUpdateEffect(() => {
		if (!isResultOpened && !error) {
			push(AppPathList.login);
		}
	}, [isResultOpened, push, error]);
	return <ResetPasswordForm onSubmit={handleSubmit} loading={loading} />;
};

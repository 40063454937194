import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils';

export const useStyles = makeStyles(
	{
		item: {
			'&:not(:last-child)::after': {
				content: '""',
				height: 1,
				width: '90%',
				display: 'block',
				position: 'absolute',
				backgroundColor: COLORS.gray,
				left: '6px',
				right: '6px',
				bottom: 0,
				margin: '0 auto',
			},
		},
	},
	{ name: 'Popover' }
);

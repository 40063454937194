import { format, isValid } from 'date-fns';
import { DATES } from 'utils';

export function formatToDate(d: unknown, f: string = DATES.loadDateFormatNoTime) {
	if (!d) return;

	const date = new Date(d as string);

	return isValid(date) ? format(date, f) : undefined;
}

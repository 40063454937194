import React, { useMemo } from 'react';
import { FieldArray, FieldArrayRenderProps, Form, Formik } from 'formik';
import {
	CompanyDetailsFields,
	CompanyDetailsNames,
	EnterpriseTypes,
	FieldTypes,
	FormIdList,
	FormStepTempProps,
} from 'models';
import { CustomDatePickerField, CustomInputField, CustomSelectField } from 'containers';
import { formatCamelToSnakeCase } from 'helpers';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { CustomButton } from 'components/button';
import { useReadUserInfo } from 'hooks';
import { useStyles } from './SignUpForm.styles';
import { TFunction } from 'i18next';
import { DirectorDetailsInput } from 'generate/types';

export interface CompanyDetailsFormProps extends FormStepTempProps<Partial<CompanyDetailsFields>> {
	directorDetailList: CompanyDetailsNames[];
	onChangeDirectorCount: (
		c: number,
		n: Array<DirectorDetailsInput>,
		cb: (field: string, value: unknown, shouldValidate?: boolean) => void
	) => void;
}

export const CompanyDetailsForm = ({
	onSubmit,
	initialValues,
	validationSchema,
	fields,
	onChangeDirectorCount,
}: CompanyDetailsFormProps) => {
	const { t } = useTranslation('form-fields');
	const { t: tB } = useTranslation('buttons');
	const { user_id } = useReadUserInfo();
	const classes = useStyles();

	const types = useMemo(
		() =>
			Object.keys(EnterpriseTypes).map((k) => ({
				title: t(`company_details.${formatCamelToSnakeCase(k)}`),
				value: t(k),
			})),
		[t]
	);

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validationSchema}
			enableReinitialize
		>
			{({ values, setFieldValue }) => {
				const directorCount = values[CompanyDetailsNames.numberOfDirectors];
				const directorDetails = values[CompanyDetailsNames.directorDetails];
				onChangeDirectorCount(Number(directorCount), directorDetails!, setFieldValue);

				return (
					<Form id={FormIdList.companyDetails} className={classes.formWrapper}>
						<CustomSelectField
							label={t(
								`company_details.${formatCamelToSnakeCase(CompanyDetailsNames.enterpriseType)}`
							)}
							name={CompanyDetailsNames.enterpriseType}
							options={types}
						/>
						{fields &&
							fields.map(({ name, type }, idx) => {
								if (idx === 0) return null;

								switch (type) {
									case FieldTypes.date:
										return (
											<CustomDatePickerField
												disableFuture={true}
												disablePast={false}
												key={name}
												name={name}
												label={t(`company_details.${formatCamelToSnakeCase(name)}`)}
												fast
											/>
										);
									case FieldTypes.number:
										return (
											<CustomInputField
												type="number"
												key={name}
												name={name}
												label={t(`company_details.${formatCamelToSnakeCase(name)}`)}
												fast
											/>
										);
									default:
										return (
											<CustomInputField
												key={name}
												name={name}
												label={t(`company_details.${formatCamelToSnakeCase(name)}`)}
												fast
											/>
										);
								}
							})}
						{
							<FieldArray
								name={CompanyDetailsNames.directorDetails}
								render={DirectorDetailsForm(t)}
							/>
						}
						{user_id && (
							<Box display="flex" marginY={2} justifyContent="center">
								<CustomButton type="submit">{tB('submit')}</CustomButton>
							</Box>
						)}
					</Form>
				);
			}}
		</Formik>
	);
};

const DirectorDetailsForm =
	(t: TFunction) =>
	({ form }: FieldArrayRenderProps) => {
		const directorDetailsFields = form.values[CompanyDetailsNames.directorDetails];

		return directorDetailsFields?.map(
			(director: Record<CompanyDetailsNames, string>, i: number) => (
				<div key={i}>
					<CustomInputField
						key={CompanyDetailsNames.directorName}
						name={`${CompanyDetailsNames.directorDetails}[${i}.${CompanyDetailsNames.directorName}]`}
						label={t(`company_details.${formatCamelToSnakeCase(CompanyDetailsNames.directorName)}`)}
						fast
					/>
					<CustomInputField
						key={CompanyDetailsNames.directorNumber}
						name={`${CompanyDetailsNames.directorDetails}[${i}.${CompanyDetailsNames.directorNumber}]`}
						label={t(
							`company_details.${formatCamelToSnakeCase(CompanyDetailsNames.directorNumber)}`
						)}
						fast
					/>
					<CustomInputField
						key={CompanyDetailsNames.directorResidentialAddress}
						name={`${CompanyDetailsNames.directorDetails}[${i}.${CompanyDetailsNames.directorResidentialAddress}]`}
						label={t(
							`company_details.${formatCamelToSnakeCase(
								CompanyDetailsNames.directorResidentialAddress
							)}`
						)}
						fast
					/>
					<CustomInputField
						key={CompanyDetailsNames.directorTelNumber}
						name={`${CompanyDetailsNames.directorDetails}[${i}.${CompanyDetailsNames.directorTelNumber}]`}
						label={t(
							`company_details.${formatCamelToSnakeCase(CompanyDetailsNames.directorTelNumber)}`
						)}
						fast
					/>
				</div>
			)
		);
	};

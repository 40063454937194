import React, { useCallback, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import { UserIcon } from 'components';
import { Link } from 'react-router-dom';
import { AppPathList } from '../../router';

const size = 25;

const useStyles = makeStyles({
	wrapper: {
		width: size,
		height: size,
		borderRadius: '50%',
		backgroundColor: '#E8F5FB',
		backgroundSize: 'cover',
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'center',
		overflow: 'hidden',
		borderColor: '#264653',
	},
	placeholder: {
		width: '100%',
	},
	img: {
		display: 'block',
		width: 70,
		height: 70,
	},
});

interface UserAvatarProps {
	className?: string;
	src?: string;
	onClick: () => void;
	isLoading: boolean;
}

export const UserAvatar = ({ className, src, onClick, isLoading }: UserAvatarProps) => {
	const classes = useStyles();

	const [isLoaded, setIsLoaded] = useState(false);

	const handleError = useCallback(() => setIsLoaded(false), []);
	const handleLoad = useCallback(() => setIsLoaded(true), []);

	const showAvatar = useMemo(() => (isLoaded && src) || '', [isLoaded, src]);

	return (
		<Link className={cn(classes.wrapper, className)} to={AppPathList.account} onClick={onClick}>
			{!isLoading && (
				<img
					src={`${src}?random=${Math.random()}`}
					alt="Avatar"
					onError={handleError}
					onLoad={handleLoad}
					className={classes.img}
				/>
			)}
			{!showAvatar && <UserIcon className={classes.placeholder} />}
		</Link>
	);
};

import { gql } from '@apollo/client';

export const workOrderCardDetails = gql`
	fragment workOrderCardDetails on Order {
		weight
		truckType
		lroId
		truckType
		transporterPaymentTerms
		cargoType
		commodity
		origin
		destination
		trucksNeeded
		trucksShortlisted
		readyToLoad
		isBid
		bidPrice
		deadlineToLift
		trailerType
		marketRate
		currency
		transporterRateType
		consignmentMetricTons
		packagingType
		weightToLoad
	}
`;

import React, { useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CustomButton } from 'components/button';
import { CustomDatePickerField, CustomInputField, CustomSelectField } from 'containers';
import { Form, Formik } from 'formik';
import { ConfirmedJobDetailsFragment, Move } from 'generate/types';
import {
	formatCamelToSnakeCase,
	getFieldType,
	getRequiredMessage,
	mapFieldsToInitialValues,
} from 'helpers';
import { FieldTypes, IOptionList, RequestFuelFormNames } from 'models';
import { useTranslation } from 'react-i18next';
import { theme } from 'utils';
import * as Yup from 'yup';
import {useStyles} from "./Forms.styles";

const fieldList = [
	RequestFuelFormNames.amount,
	RequestFuelFormNames.fuelingPoint,
	RequestFuelFormNames.fuelType,
	RequestFuelFormNames.date,
];

interface RequestFuelFormProps {
	move: ConfirmedJobDetailsFragment;
	stationList: IOptionList;
	fuelTypes: IOptionList;
	isSubmitting?: boolean;
	onSubmit: (v: Partial<Record<RequestFuelFormNames, unknown>>) => void;
	jobList: Move[];
	loading: boolean;
	setMove: (value: Move | undefined) => void;
	fieldsDisabled: boolean;
}

export const RequestFuelForm = ({
	move,
	isSubmitting,
	stationList,
	fuelTypes,
	onSubmit,
	jobList,
	loading,
	setMove,
	fieldsDisabled,
}: RequestFuelFormProps) => {
	const { t } = useTranslation('form-fields');
	const { t: tB } = useTranslation('buttons');
	const { t: tF } = useTranslation('fuel-order');
	const classes = useStyles();
	// @ts-ignore
	const getOptions = (name: RequestFuelFormNames) => {
		switch (name) {
			case RequestFuelFormNames.fuelingPoint:
				return stationList;
			case RequestFuelFormNames.fuelType:
				return fuelTypes;
			default:
				return [];
		}
	};

	const getFields = () => {
		const dateFields = [RequestFuelFormNames.date];
		const selectFields = [
			RequestFuelFormNames.fuelingPoint,
			RequestFuelFormNames.fuelType,
		];
		const disabledFields = [
			RequestFuelFormNames.truckReg,
			RequestFuelFormNames.origin,
			RequestFuelFormNames.destination,
		];

		return fieldList.map((name) => {
			return {
				name,
				type: getFieldType(name, {
					dateFields,
					selectFields,
				}),
				disabled: disabledFields.includes(name),
			};
		});
	};

	const fields = getFields();

	const requiredFields = useMemo(
		() => [RequestFuelFormNames.date, RequestFuelFormNames.fuelingPoint, RequestFuelFormNames.fuelType],
		[]
	);

	const initialValues = useMemo(() => mapFieldsToInitialValues(fieldList, move), [move]);

	const validationSchema = Yup.object().shape({
		[RequestFuelFormNames.amount]: Yup.number()
			.required(
				getRequiredMessage(
					t,
					t(`request_fuel.${formatCamelToSnakeCase(RequestFuelFormNames.amount)}`)
				)
			),
		...requiredFields.reduce(
			(res, name) => ({
				...res,
				[name]: Yup.string().required(
					getRequiredMessage(t, t(`request_fuel.${formatCamelToSnakeCase(name)}`))
				),
			}),
			{}
		),
	});

	return (
		<>
			<Box maxWidth={theme.breakpoints.values.sm} marginX="auto" overflow="auto">
				<Typography className={classes.title}>
					{tF('create_request')}
				</Typography>
				<Autocomplete
					className={classes.autocompleteField}
					id="my-jobs"
					options={jobList}
					loading={loading}
					getOptionLabel={(option) => option?.commodity || 'No label'}
					// @ts-ignore
					onChange={(_, value) => {
						// @ts-ignore
						setMove(value || {});
					}}
					renderOption={(option) => (
						<Box display="flex" flexDirection="column">
							<Typography component="p" variant="body1" color="primary">
								{option?.commodity}
							</Typography>
							<Typography component="p" variant="body2" color="textSecondary">
								{t('truck')}: {option?.truckReg}
							</Typography>
							<Typography component="p" variant="body2" color="textSecondary">
								{t('from')}: {option?.origin}
							</Typography>
							<Typography component="p" variant="body2" color="textSecondary">
								{t('to')}: {option?.destination}
							</Typography>
						</Box>
					)}
					renderInput={(params) => (
						<TextField {...params} label={t('request_fuel.select_job')} variant="outlined" />
					)}
				/>
			</Box>
			<Formik
				validationSchema={validationSchema}
				initialValues={initialValues}
				onSubmit={onSubmit}
				enableReinitialize
			>
				<Box maxWidth={theme.breakpoints.values.sm} marginX="auto" overflow="auto">
					<Form>
						{fields.map(({ name, disabled, type }) => {
							switch (type) {
								case FieldTypes.select:
									return (
										<CustomSelectField
											key={name}
											label={t(`request_fuel.${formatCamelToSnakeCase(name)}`)}
											name={name}
											options={getOptions(name)}
											disabled={fieldsDisabled || disabled}
										/>
									);
								case FieldTypes.date:
									return (
										<CustomDatePickerField
											key={name}
											label={t(`request_fuel.${name}`)}
											name={name}
											disabled={fieldsDisabled || disabled}
											onlyYupValidation
											clearable={false}
										/>
									);
								default:
									return (
										<CustomInputField
											key={name}
											label={t(`request_fuel.${name}`)}
											name={name}
											disabled={fieldsDisabled || disabled}
										/>
									);
							}
						})}
						<Box display="flex" marginTop="2rem" marginBottom="2rem">
							<CustomButton type="submit" disabled={isSubmitting} className="capitalizeText">
								{isSubmitting ? "Submitting..." : tB('submit')}
							</CustomButton>
						</Box>
					</Form>
				</Box>
			</Formik>
		</>
	);
};

import React, { useMemo } from 'react';
import { ContactFormNames, FormIdList, FormStepTempProps } from 'models';
import { Form, Formik } from 'formik';
import { CustomInputField } from 'containers';
import { formatCamelToSnakeCase } from 'helpers';
import { useTranslation } from 'react-i18next';
import { CreateContactMutationVariables } from 'generate/typesAuth';
import { Box } from '@material-ui/core';
import { CustomButton } from '../../../button';
import { useStyles } from '../SignUpForm.styles';
import { useReadUserInfo } from '../../../../hooks';

export const ContactForm = ({
	initialValues,
	onSubmit,
	validationSchema,
}: FormStepTempProps<CreateContactMutationVariables>) => {
	const { t } = useTranslation('form-fields');
	const { t: tB } = useTranslation('buttons');
	const classes = useStyles();

	const { user_id } = useReadUserInfo();
	const fields = useMemo(() => Object.values(ContactFormNames), []);

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validationSchema}
			enableReinitialize
		>
			<Form id={FormIdList.contactInformation} className={classes.formWrapper}>
				{fields.map((field) => (
					<CustomInputField
						key={field}
						name={field}
						label={t(`sign_up.${formatCamelToSnakeCase(field)}`)}
					/>
				))}
				{user_id && (
					<Box display="flex" marginY={2} justifyContent="center">
						<CustomButton type="submit">{tB('submit')}</CustomButton>
					</Box>
				)}
			</Form>
		</Formik>
	);
};

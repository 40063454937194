import React, { useCallback } from 'react';
import { PersonaInformationForm } from '../PersonaInformationForm';
import {
	useGetPersonalDetailsQuery,
	useUpdatePersonalInfoMutation,
	UpdatePersonalInfoMutationVariables,
} from 'generate/types';
import { Loader } from 'components';
import { useResultContext } from 'hooks';
import { getPersonalDetails } from 'graphqlapi';

export const UpdatePersonaInformationForm = () => {
	const { data, loading } = useGetPersonalDetailsQuery();
	const { openResultCb } = useResultContext();

	const [updatePersonalInfo] = useUpdatePersonalInfoMutation({
		onCompleted: openResultCb,
		refetchQueries: [{ query: getPersonalDetails }],
	});

	const handleSubmit = useCallback(
		(variables: UpdatePersonalInfoMutationVariables) => {
			updatePersonalInfo({ variables });
		},
		[updatePersonalInfo]
	);

	return (
		<Loader isLoading={loading}>
			<PersonaInformationForm onSubmit={handleSubmit} data={data?.profile} />
		</Loader>
	);
};

import { useCallback, useEffect, useState } from 'react';
import {
	CustomCheckboxField,
	CustomDatePickerField,
	CustomSelectField,
	ISelectOptionList,
} from 'containers';
import { ChevronIcon } from '../icons';
import { useStyles } from './TruckCard.styles';
import cn from 'classnames';
import { IS_PRODUCTION } from 'utils';
import { useTranslation } from 'react-i18next';
import { TruckType } from 'generate/typesLIA';
import { IPickedAssets, ITruckAssets } from 'models';
import { checkAssetOptionList, getExternalDriverTitle } from 'helpers';

interface TruckCardProps extends Omit<IPickedAssets, 'trucksAssets'> {
	truckReg: TruckType['truckReg'];
	isOpen?: boolean;
	isChecked?: boolean;
	isAssetsEditable?: boolean;
	driverOptionList?: ISelectOptionList | null;
	trailerOptionList?: ISelectOptionList | null;
	isDriversDisabled?: boolean;
	isTrailersDisabled?: boolean;
	isConfirming?: boolean;
	hideTrailers?: boolean;
	truckAssets?: ITruckAssets;
}

export const TruckCard = ({
	truckReg,
	isOpen = false,
	isChecked,
	isAssetsEditable,
	driverOptionList,
	trailerOptionList,
	pickedDrivers,
	pickedTrailers,
	isDriversDisabled,
	isTrailersDisabled,
	truckAssets,
	hideTrailers,
	isConfirming,
}: TruckCardProps) => {

  const classes = useStyles();
	const { t } = useTranslation('form-fields');

	const [trailerList, setTrailerList] = useState<ISelectOptionList | null>();
	const [driverList, setDriverList] = useState<ISelectOptionList | null>();

	useEffect(() => {
		if (!trailerList) {
			setTrailerList(
				checkAssetOptionList(trailerOptionList, isConfirming, truckAssets?.trailerReg)
			);
		}
	}, [isConfirming, trailerList, trailerOptionList, truckAssets]);

	useEffect(() => {
		if (!driverList) {
			setDriverList(
				checkAssetOptionList(
					driverOptionList,
					isConfirming,
					truckAssets?.driverData,
					getExternalDriverTitle(truckAssets?.driverData)
				)
			);
		}
	}, [isConfirming, driverList, driverOptionList, truckAssets]);

	const [open, setOpen] = useState(() => isOpen);

	const handleClick = useCallback(() => setOpen(!open), [open]);
	useEffect(() => (isChecked ? setOpen(true) : setOpen(false)), [isChecked]);

  return (
		<div key={truckReg} className={classes.wrapper}>
			<div className={classes.header}>
				{IS_PRODUCTION ? (
					<CustomCheckboxField label={truckReg || ''} name={`${truckReg}.checked`} />
				) : (
					<CustomCheckboxField
						label={truckReg && !isAssetsEditable ? `${truckReg} (LIA)` : truckReg || ''}
						name={`${truckReg}.checked`}
					/>
				)}

				<ChevronIcon
					className={cn(classes.arrow, {
						[classes.up]: open,
					})}
					pointer
					onClick={handleClick}
				/>
			</div>
			<div
				className={cn(classes.content, {
					[classes.hide]: !open,
				})}
			>
				{!hideTrailers && (
					<CustomSelectField
						className={classes.formField}
						name={`${truckReg}.trailerReg`}
						label={t('submit_track.trailer_registration')}
						options={trailerList}
						disabled={!isAssetsEditable || isTrailersDisabled}
						disabledValues={pickedTrailers}
					/>
				)}
				<CustomSelectField
					className={classes.formField}
					name={`${truckReg}.driverData`}
					label={t('submit_track.driver_details')}
					options={driverList}
					disabled={!isAssetsEditable || isDriversDisabled}
					disabledValues={pickedDrivers}
				/>
				<CustomDatePickerField
					fast
					onlyYupValidation
					className={classes.formField}
					name={`${truckReg}.etaLoadingPoint`}
					label={t('submit_track.eta_to_loading')}
					withTime
				/>
			</div>
		</div>
	);
};

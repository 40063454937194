import { Link } from 'react-router-dom';
import cn from 'classnames';
import React from 'react';
import { useStyles } from './FooterButton.styles';
import { Button, ButtonProps } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useMixpanel } from 'analytics';
export interface IFooterButton {
	link?: string;
	text: string;
	icon?: string;
	iconActive?: string;
	src?: string;
	isDisabled?: boolean;
}

interface FooterButtonProps extends IFooterButton, ButtonProps {
	className?: string;
	isActive: boolean;
}

export function FooterButton({
	link = '',
	icon,
	src,
	text,
	className,
	isActive,
	isDisabled,
	...buttonProps
}: FooterButtonProps) {
	const classes = useStyles();
	const mixpanel = useMixpanel();

	if (link) {
		return (
			<Link
				to={link}
				className={cn(className, classes.wrapper, {
					[classes.disabled]: isDisabled,
				})}
				onClick={() => {
					mixpanel.track(`Clicks on ${text}`, { pathname: link });
				}}
			>
				<div className={classes.imgWrapper} data-qa={`qa-${text}`}>
					<img
						src={src}
						alt={text}
						className={cn(classes.img, classes.imgActive, {
							active: isActive,
						})}
					/>
				</div>
				<Typography
					className={cn(classes.text, {
						active: isActive,
					})}
				>
					{text}
				</Typography>
			</Link>
		);
	}

	return (
		<Button
			className={cn(className, classes.wrapper, {
				[classes.disabled]: isDisabled,
			})}
			disableTouchRipple={true}
			{...buttonProps}
		>
			<div className={classes.imgWrapper}>
				<img
					src={src}
					alt={text}
					className={cn(classes.img, classes.imgActive, {
						active: isActive,
					})}
				/>
			</div>
			<Typography
				className={cn(classes.text, {
					active: isActive,
				})}
			>
				{text}
			</Typography>
		</Button>
	);
}

import React, { useCallback, useMemo } from 'react';
import { Modal, Paper } from '@material-ui/core';
import { ModalProps } from '@material-ui/core/Modal/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import cn from 'classnames';
import { useStyles } from './ModalWrapper.styles';
import { CloseIcon } from '../icons';

export interface ModalWrapperProps extends ModalProps {
	withPaper?: boolean;
	withCloseBtn?: boolean;
	contentClassName?: string;
}

export function ModalWrapper({
	children,
	onClose,
	className,
	withPaper = true,
	withCloseBtn = true,
	contentClassName,
	open,
	...props
}: ModalWrapperProps) {
	const classes = useStyles();

	const handleClose = useCallback(() => onClose && onClose({}, 'backdropClick'), [onClose]);

	const render = useMemo(
		() =>
			withPaper ? (
				<Paper className={cn(classes.modal, contentClassName)} elevation={6}>
					{withCloseBtn && <CloseIcon className={classes.closeIcon} onClick={handleClose} />}
					{children}
				</Paper>
			) : (
				children
			),
		[children, withPaper, classes, handleClose, withCloseBtn, contentClassName]
	);

	return (
		<Modal
			{...props}
			open={open}
			onClose={onClose}
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
				className: classes.backdrop,
			}}
			className={cn(classes.wrapper, className)}
		>
			<Fade in={open}>{render}</Fade>
		</Modal>
	);
}

import { makeStyles, createStyles, Theme } from '@material-ui/core';

interface UseStylesProps {
	emptyRows: number;
}

const TABLE_ROW_HEIGHT = 69;

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			height: (props: UseStylesProps) => TABLE_ROW_HEIGHT * props.emptyRows,
		},
	})
);

import { createTheme } from '@material-ui/core';
import { COLORS } from 'utils/constants';

export const theme = createTheme({
	// @ts-ignore
	external: {
		palette: {
			gray: {
				primary: COLORS.gray,
			},
		},
	},
	palette: {
		primary: {
			light: '#8eacbb',
			main: '#7FC0B5',
			dark: '#7FC0B5',
			contrastText: '#fff',
		},
		secondary: {
			light: '#fff350',
			main: '#ffc107',
			dark: '#c79100',
			contrastText: '#000000',
		},
		text: {
			primary: '#000000',
			secondary: '#666666',
		},
	},
	typography: {
		htmlFontSize: 16,
		fontSize: 14,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 400,
		fontWeightBold: 600,
		h3: {
			fontWeight: 'bold',
		},
		h4: {
			fontWeight: 'normal',
		},
		h5: {
			fontWeight: 'normal',
		},
		fontFamily: [
			'Lato',
			'sans-serif',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
		].join(','),
	},
	overrides: {
		MuiInputBase: {
			root: {
				backgroundColor: COLORS.white,
				fontSize: '1rem',
				height: 52,
			},
			input: {
				padding: '8px 16px !important',
				backgroundColor: COLORS.white,
			},
		},
		MuiTypography: {
			colorPrimary: {
				color: '#264653',
			},
			colorSecondary: {
				color: '#666666',
			},
			colorTextPrimary: {
				color: '#000000',
			},
			colorTextSecondary: {
				color: '#999999',
			},
			body2: {
				fontSize: '0.875rem',
			},
		},
		MuiAutocomplete: {
			root: {},
			input: {
				padding: '0px 16px !important',
			},
		},
		MuiOutlinedInput: {
			root: {
				borderRadius: 5,
				borderColor: COLORS.primary,

				'& $notchedOutline': {
					borderColor: COLORS.grey,
				},
				'& $focused $notchedOutline': {
					borderColor: COLORS.primary,
				},
			},
			input: {
				padding: '14px 18px !important',
				backgroundColor: COLORS.white,
			},
		},
		MuiFormLabel: {
			root: {
				lineHeight: 1.5,
				'&$focused': {
					color: COLORS.primary,
				},
			},
		},
		MuiInputLabel: {
			outlined: {
				transform: 'translate(14px, 15px) scale(1)',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				right: 44,
				textAlign: 'left',
				'&$shrink': {
					right: 0,
				},
				'&$formControl': {
					'&$focused': {
						top: 0,
					},
					'&$shrink': {
						top: 0,
					},
				},
			},
		},
		MuiSelect: {
			root: {
				textAlign: 'left',
			},
		},
		MuiChip: {
			root: {
				color: '#32C1B0',
				backgroundColor: 'rgba(0, 255, 87, 0.08)'
			},
		},
		MuiContainer: {
			root: {
				outline: 'none',
				display: 'flex',
        paddingLeft: 13,
        paddingRight: 13,
			},
		},
		MuiFormControlLabel: {
			root: {
				marginRight: 0,
				color: "red"
			},
			label: {
				color: COLORS.primary
			},
		},
		MuiButton: {
			root: {
				maxWidth: '100%',
				position: 'static',
				minWidth: 'fit-content',
				fontSize: '18px',
				fontWeight: 500,
				lineHeight: '28px',
				letterSpacing: '0.15px',
				padding: '4px 0.5rem',
				height: 52,
				borderRadius: 5,
				fontFamily: [
					'Lato',
					'sans-serif',
					'Roboto',
					'"Helvetica Neue"',
					'Arial',
					'"Apple Color Emoji"',
					'"Segoe UI Emoji"',
					'"Segoe UI Symbol"',
					'-apple-system',
					'BlinkMacSystemFont',
					'"Segoe UI"',
				].join(','),
			},
			contained: {
				width: '100%',
				background: '#264653',
				borderRadius: 5,
				border: 0,
				color: '#fff',
			},
			outlined: {
				width: '100%',

				'& primary': {
					backgroundColor: COLORS.white,
				},
			},
			outlinedPrimary: {
				width: '100%',
				backgroundColor: COLORS.white,
			},
			textPrimary: {
				color: COLORS.primary,
			},
		},
		MuiPaper: {
			outlined: {
				border: '1px solid #264653',
			},
			rounded: {
				borderRadius: 10,
			},
		},
		MuiTabs: {
			root: {
				minHeight: '1rem',
				flexShrink: 0,
				position: 'relative',
			},
			indicator: {
				backgroundColor: COLORS.primary,
			},
			scrollButtons: {
				position: 'absolute',
				top: 0,
				bottom: 0,
				left: 0,
				width: 20,
				'&:hover': {
					color: COLORS.primary,
				},
				'& ~ &': {
					left: 'auto',
					right: 0,
				},
			},
			flexContainer: {
				justifyContent: 'center',
			},
		},
		MuiTab: {
			root: {
				minHeight: '1rem',
			},
			textColorInherit: {
				textTransform: 'capitalize',
				color: COLORS.text,
				opacity: 1,
			},
			// @ts-ignore
			'&$selected': {
				color: COLORS.primary,
				fontWeight: 600,
			},
		},
		MuiRadio: {
			colorSecondary: {
				'&$checked': {
					color: COLORS.primary,
				},
			},
		},
		MuiFormGroup: {
			root: {
				alignItems: 'flex-start',
			},
		},
		MuiDivider: {
			root: {
				color: COLORS.inputBorder,
				marginTop: 16,
				marginBottom: 16,
			},
		},
		MuiListItem: {
			root: {
				'&$disabled': {
					display: 'none',
				},
			},
		},
		MuiDrawer: {
			paper: {
				backgroundColor: COLORS.primary,
			},
		},
		MuiAccordion: {
			root: {
				'&:last-of-type': {
					'& .MuiAccordionSummary-root': {
						borderBottom: `1px solid ${COLORS.gray}`,
					},
				},
				'&$expanded': {
					margin: 0,
					'&:last-of-type': {
						borderBottom: `0px solid ${COLORS.gray}`,
					},
				},
				'&::before': {
					display: 'none',
				},
			},
		},
		MuiAccordionSummary: {
			root: {
				borderTop: `1px solid ${COLORS.gray}`,
				'&$expanded': {
					borderBottom: `1px solid ${COLORS.gray}`,
				},
			},
			expandIcon: {
				color: COLORS.primary,
				'&.Mui-expanded': {
					color: COLORS.gray,
				},
			},
		},
		MuiAccordionDetails: {
			root: {
				padding: 0,
			},
		},
		MuiTable: {},
	},
});

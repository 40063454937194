import React, { PropsWithChildren, useMemo } from 'react';
import { useLoaderContext, useReadUserInfo, useResultContext } from 'hooks';
import { Redirect } from 'react-router-dom';
import { AppPathList } from 'router';
import { ResultModal, LoriLogo, Loader } from 'components';
import { checkIsAdmin } from 'helpers';

interface ExternalLayoutProps {
	isAdminDashboard?: boolean;
}

export function ExternalLayout({
	children,
	isAdminDashboard,
}: PropsWithChildren<ExternalLayoutProps>) {
	const { isResultOpened, closeResult, resultData } = useResultContext();
	const { isLoading } = useLoaderContext();
	const { role, transporter_id } = useReadUserInfo();
	const isAdmin = useMemo(() => checkIsAdmin(role), [role]);

	if (!isAdminDashboard && isAdmin && transporter_id) {
		return <Redirect to={AppPathList.available} />;
	}

	return (
		<>
			<div className="container-external">
				<LoriLogo />
				{children}
			</div>
			<ResultModal isOpened={isResultOpened} onClose={closeResult} {...resultData} />
			<Loader overflow isLoading={isLoading} />
		</>
	);
}

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	btn: {
		width: 120,
		'& + &': {
			marginLeft: 20,
		},
	},
});

import React from 'react';
import { useDocumentTable } from 'hooks';
import { CustomTable } from 'components';
import { UploadedDocumentsFragment } from 'generate/typesAdmin';
import { useStyles } from 'components/admin/user-details/UserDetails.styles';

interface UserDocumentTableProps {
	data?: UploadedDocumentsFragment | null;
}

export const TransporterDocumentTable = ({ data }: UserDocumentTableProps) => {
	const classes = useStyles();

	const { columns, rows, renderCell } = useDocumentTable(data);

	return (
		<CustomTable
			data={rows}
			columns={columns}
			renderCell={renderCell}
			tableCellProps={{
				className: classes.cell,
			}}
			tableRowProps={{
				className: classes.row,
			}}
		/>
	);
};

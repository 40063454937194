import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils';

export const useStyles = makeStyles({
	card: {
		minWidth: 275,
		boxShadow: '0px 4px 3px rgb(0 0 0 / 3%)',
		border: '1px solid #F9F9F9',
		borderRadius: 5,
	},
	primary: {
		color: COLORS.primary,
	},
	divider: {
		margin: '3px 0',
	},
	title: {
		color: '#2C3032',
		fontWeight: 400,
		fontSize: 16,
		letterSpacing: 0.4,
		marginLeft: 20,
		marginTop: 5,
	},
	fuelStation: {
		color: '#7FC0B5',
		fontSize: 18,
		fontWeight: 800,
		marginLeft: 20,
	},
	fuelingDate: {
		color: '#9B9B9B',
		fontSize: 12,
		fontWeight: 600,
		letterSpacing: 0.4,
		marginRight: 24,
		marginTop: -2,
		right: 24,
		textAlign: 'right',
	},
	label: {
		color: '#9B9B9B',
		fontSize: 12,
		fontWeight: 400,
		letterSpacing: 0.4,
		marginBottom: 6,
		marginLeft: 20,
	},
	field: {
		color: '#9B9B9B',
		fontSize: 12,
		fontWeight: 400,
		letterSpacing: 0.4,
		marginBottom: 6,
	},
	cancelButton: {
		color: COLORS.red,
	},
});

import { ApolloClient, NormalizedCacheObject, ApolloLink, InMemoryCache } from '@apollo/client';
import { API } from 'utils';
import { errorLink } from '../links/errorLink';

const { createUploadLink } = require("apollo-upload-client");

const httpLink = createUploadLink({
	uri: `${API.LTAClient.url}`,
});

const authLink = createUploadLink({
	uri: `${API.LTAAuthClient.url}`,
});

const clientLink = errorLink().concat(httpLink);
export const client = new ApolloClient<NormalizedCacheObject>({
	link: ApolloLink.split(
		(operation) => operation.getContext().clientName === API.LTAAuthClient.contextName,
		authLink,
		clientLink
	),
	cache: new InMemoryCache({
		typePolicies: {
			Orders: {
				keyFields: ["id", "weightToLoad"],
			},
			Order: {
				keyFields: ["id", "weightToLoad"],
			},
		},
	}),
	connectToDevTools: true,
	defaultOptions: {
		query: {
			fetchPolicy: 'network-only',
		},
	},
});

import { gql } from "@apollo/client";

export const supplyServiceCreateDriver = gql`
  mutation createDriver(
    $idNumber: String!
    $phone: String!
    $country: String!
    $names: String!
    $transporterId: Int!
    $source: Source!
    $createdBy: String
  ) {
    createDriver(
      source: $source
      driverData: {
        idNumber: $idNumber
        phone: $phone
        country: $country
        names: $names
        transporterId: $transporterId
        createdBy: $createdBy
      }
    ) {
      status
      message
    }
  }
`;

import { onError } from '@apollo/client/link/error';
import { API } from 'utils';
import { ErrorTypes } from 'models';
import { AppPathList } from 'router';

export const errorLink = (clientName: keyof typeof API = 'LTAClient') =>
	onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors) {
			graphQLErrors.every((graphQLError: any) => {
				const { error_code } = graphQLError;

				const path = clientName === 'LTAAdminClient' ? AppPathList.admin : AppPathList.login
				if (ErrorTypes.UNAUTHORIZED === error_code) {
					window.location.replace(path);
					return false;
				}
				return true;
			});
		}

		if (networkError) {
			console.warn(`[Network error]: ${networkError}`);
		}
	});

import React from 'react';
import { Box } from '@material-ui/core';
import { AdminUserAvatar } from 'components/user-avatar';
import { useStyles } from './Header.styles';

export const AdminHeader = () => {
	const classes = useStyles();

	return (
		<Box paddingY={2} display="flex" paddingX={3} className={classes.adminHeader}>
			<Box marginLeft="auto">
				<AdminUserAvatar />
			</Box>
		</Box>
	);
};

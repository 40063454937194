import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';
import { CustomDatePickerField, CustomInputField, CustomUploadFieldLta } from 'containers';
import {
	FieldTypes,
	FormIdList,
	UploadDocumentationFormProps,
	UploadDocumentationNames,
} from 'models';
import {
	formatCamelToSnakeCase,
	getFieldType,
	getRequiredMessage,
	mapFieldsToInitialValues,
} from 'helpers';
import { useTranslation } from 'react-i18next';
import { SIGN_UP_EXTENSION_LIST } from 'utils';
import * as Yup from 'yup';
import { useStyles } from '../SignUpForm.styles';
import { CustomRadioField } from 'containers';
import { useReadUserInfo } from 'hooks';
import { Box } from '@material-ui/core';
import { CustomButton } from 'components/button';

const fieldsList = [
	UploadDocumentationNames.carrierResolutionTest,
	UploadDocumentationNames.gitConfirmation,
	UploadDocumentationNames.gitCoverExpiry,
	UploadDocumentationNames.insurance,
	UploadDocumentationNames.bee,
	UploadDocumentationNames.bankingLetter,
	UploadDocumentationNames.companyRegistration,
	UploadDocumentationNames.taxClearance,
	UploadDocumentationNames.letterhead,
	UploadDocumentationNames.standingLetter,
	UploadDocumentationNames.ownerId,
	UploadDocumentationNames.vehiclesReg,

	UploadDocumentationNames.isVatRegistered,
	UploadDocumentationNames.vatRegistrationNumber,
	UploadDocumentationNames.vatCertificate,

	UploadDocumentationNames.hasQualityManagementCert,
	UploadDocumentationNames.qualityCertificate,
];

export const UploadDocumentationFormSouth = ({ data, onSubmit }: UploadDocumentationFormProps) => {
	const { t } = useTranslation('form-fields');
	const { t: tE } = useTranslation('errors');
	const { t: tB } = useTranslation('buttons');
	const { user_id } = useReadUserInfo();
	const classes = useStyles();

	const options = useMemo(
		() => [
			{
				value: 'true',
				label: t('upload_documents.yes'),
			},
			{
				value: 'false',
				label: t('upload_documents.no'),
			},
		],
		[t]
	);

	const fields = useMemo(() => {
		const radioFields = [
			UploadDocumentationNames.isVatRegistered,
			UploadDocumentationNames.hasQualityManagementCert,
		];

		const textFields = [UploadDocumentationNames.vatRegistrationNumber];

		const dateFields = [UploadDocumentationNames.gitCoverExpiry];

		return fieldsList.map((name) => ({
			name,
			type: getFieldType(name, { radioFields, textFields, dateFields }, FieldTypes.upload),
		}));
	}, []);

	const initialValues = useMemo(() => mapFieldsToInitialValues(fieldsList, data || {}), [data]);

	const requiredFields = useMemo(
		() =>
			user_id
				? {}
				: fields.reduce(
						(res, { name }) => ({
							...res,
							[name]: Yup.string().required(
								getRequiredMessage(tE, t(`upload_documents.${formatCamelToSnakeCase(name)}`))
							),
						}),
						{}
				  ),
		[fields, t, tE, user_id]
	);

	const validationSchema = useMemo(
		() =>
			Yup.object({
				...requiredFields,
				[UploadDocumentationNames.vatRegistrationNumber]: Yup.string().when(
					UploadDocumentationNames.isVatRegistered,
					{
						is: 'true',
						then: Yup.string().required('Required'),
					}
				),
				[UploadDocumentationNames.vatCertificate]: Yup.string().when(
					UploadDocumentationNames.isVatRegistered,
					{
						is: 'true',
						then: Yup.string().required('Required'),
					}
				),
				[UploadDocumentationNames.qualityCertificate]: Yup.string().when(
					UploadDocumentationNames.hasQualityManagementCert,
					{
						is: 'true',
						then: Yup.string().required('Required'),
					}
				),
			}),
		[requiredFields]
	);

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validationSchema}
			enableReinitialize
		>
			{({ values }) => {
				let list = [...fields];

				if (values.hasQualityManagementCert !== 'true') {
					list = list.filter(({ name }) => name !== UploadDocumentationNames.qualityCertificate);
				}

				if (values.isVatRegistered !== 'true') {
					list = list.filter(
						({ name }) =>
							name !== UploadDocumentationNames.vatCertificate &&
							name !== UploadDocumentationNames.vatRegistrationNumber
					);
				}

				return (
					<Form id={FormIdList.uploadDocumentation} className={classes.formWrapper}>
						{list.map(({ name, type }) => {
							switch (type) {
								case FieldTypes.radio:
									return (
										<CustomRadioField
											className={classes.radioGroup}
											key={name}
											name={name}
											options={options}
											title={t(`upload_documents.${formatCamelToSnakeCase(name)}`)}
										/>
									);
								case FieldTypes.upload:
									return (
										<CustomUploadFieldLta
											key={name}
											name={name}
											label={t(`upload_documents.${formatCamelToSnakeCase(name)}`)}
											accept={SIGN_UP_EXTENSION_LIST}
										/>
									);
								case FieldTypes.date:
									return (
										<CustomDatePickerField
											key={name}
											name={name}
											label={t(`upload_documents.${formatCamelToSnakeCase(name)}`)}
										/>
									);
								default:
									return (
										<CustomInputField
											key={name}
											name={name}
											label={t(`upload_documents.${formatCamelToSnakeCase(name)}`)}
										/>
									);
							}
						})}
						{user_id && (
							<Box display="flex" marginY={2} justifyContent="center">
								<CustomButton type="submit">{tB('submit')}</CustomButton>
							</Box>
						)}
					</Form>
				);
			}}
		</Formik>
	);
};

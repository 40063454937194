import { IRoute } from 'models';
import { AppRouteNameList } from '../AppRouteNameList';

export const LoginRoute: IRoute = {
	name: AppRouteNameList.root,
	nested: [
		{
			name: AppRouteNameList.login,
		},
		{
			name: AppRouteNameList.signUp,
		},
		{
			name: AppRouteNameList.forgetPassword,
		},
		{
			name: AppRouteNameList.admin,
		},
	],
};

export const COLORS = {
	logoPrimaryColor: '#2494d4',

	primary: '#7FC0B5',
	primaryLight: '#4f5b62',
	primaryDark: '#fe0000',

	secondary: '#e0b20e',
	secondaryLight: '#ffe44f',
	secondaryDark: '#aa8300',

	text: '#000',
	textLight: '#2E466E',
	textDark: '#aa8300',
	textBlack: '#2C3032',
	textLabel: '#5F738C',
	textLabelLight: '#848F9F',

	active: '#E8F5FB',
	disabled: '#AEA8A8',
	activeLink: '#D6E3E9',

	brown: '#846A6A',

	blue2: '#2194d2',
	blue3: '#0050b3',

	black: '#212121',
	gray: '#adacac',

	label: '#0000008a',

	footerBorder: '#ddd',
	inputBorder: 'rgba(0,0,0, .23)',

	danger: '#fe0000',
	warning: '#f6ae02',
	confirm: '#169c00',
	white: '#fff',

	green: '#28B772',

	grey: '#C4C4C4',
	grey1: '#F9FAFB',
	grey3: '#9B9B9B',

	yellow: '#FFC46C',

	red: '#FB7B7B',

	cardBorder: '#F9F9F9',
};

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Tab, Tabs, TabsClassKey, TabClassKey, TabsTypeMap } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { TabsActions } from '@material-ui/core/Tabs/Tabs';
import { useMixpanel } from 'analytics';
import capitalize from 'lodash/capitalize';

interface ITab {
	label: string;
	value: string;
	Component?: React.ElementType;
	props?: object;
}

interface CustomTabsProps extends Partial<TabsTypeMap['props']> {
	tabList: ITab[];
	classes?: Partial<ClassNameMap<TabsClassKey>>;
	tabClasses?: Partial<ClassNameMap<TabClassKey>>;
	tabWrapperClass?: string;
	contentWrapperClass?: string;
	onChangeValue?: (v: string) => void;
}

export const CustomTabs = ({
	tabList,
	tabClasses,
	tabWrapperClass,
	contentWrapperClass,
	onChangeValue,
	...props
}: CustomTabsProps) => {
	const [value, setValue] = React.useState<ITab['value']>(tabList[0].value);
	/*Start:    Fix indicator render on first load*/
	const ref = useRef<TabsActions>(null);
	const [isIndicatorFixed, setIsIndicatorFixed] = useState(false);
	const mixpanel = useMixpanel();

	// ---------------------------- Mixpanel -----------------------------------//
	useEffect(() => {
		mixpanel.track(`User arriving to the ${value} page`, {
			message: `${value} page renders successfully `,
		});
	}, [mixpanel, value]);

	useEffect(() => {
		const tabRef = ref?.current;
		if (tabRef && !isIndicatorFixed) {
			setTimeout(tabRef.updateIndicator, 0);
			setIsIndicatorFixed(true);
		}
	}, [value, isIndicatorFixed]);
	/*End:      Fix indicator render on first load*/

	useEffect(() => onChangeValue && onChangeValue(value), [value, onChangeValue]);
	useEffect(() => setValue(tabList[0].value), [tabList]);

	const handleChange = (e: React.ChangeEvent<{}>, newValue: string) => {
		mixpanel.track(`${capitalize(newValue)} tab`);
		setValue(newValue);
	};

	const Component = useMemo(
		() => tabList.find(({ value: tabValue }) => tabValue === value)?.Component || (() => null),
		[value, tabList]
	);

	const tabPros = useMemo(
		() => tabList.find(({ value: tabValue }) => tabValue === value)?.props || (() => null),
		[value, tabList]
	);

	return (
		<>
			<Tabs
				action={ref}
				value={value}
				onChange={handleChange}
				variant="fullWidth"
				scrollButtons="on"
				{...props}
			>
				{tabList.map(({ value, label }) => (
					<Tab key={value} label={label} value={value} classes={tabClasses} />
				))}
			</Tabs>
			{contentWrapperClass ? (
				<div className={contentWrapperClass}>
					<Component {...tabPros} />
				</div>
			) : (
				<Component {...tabPros} />
			)}
		</>
	);
};

import { useCallback } from 'react';
import { CustomInputFieldContainerProps, CustomUploadField } from './CustomUploadField';
import { dmsClient } from 'graphqlapi';
import { useResultContext } from 'hooks';
import { useParams } from 'react-router-dom';
import { useMixpanel } from 'analytics';
import { useUploadDocumentsMutation } from 'generate/typesDMS';
import { getDocuments } from 'graphqlapi';

export function CustomUploadFieldLfa(props: CustomInputFieldContainerProps) {
	const { id } = useParams<{ id: string }>();
	const { openErrorResult } = useResultContext();
	const mixpanel = useMixpanel();

  const [uploadDocumentsMutation, { loading }] = useUploadDocumentsMutation({
    client: dmsClient,
		onCompleted: () => {
      if(props && typeof props.refetch === 'function') {
        props.refetch();
      }
			mixpanel.track('Documents upload on the completed jobs tab (Success)');
		},
		onError: (error) => {
			mixpanel.track('Documents upload on the completed jobs tab (Failure)', { error });
			openErrorResult();
		},
    refetchQueries: [
      {
        query: getDocuments,
        variables: { moveId: parseInt(id) },
      },
    ],
    awaitRefetchQueries: true,
  });

  // @ts-ignore
	const handleUpload = useCallback(
		(documentTypeId, file: File | FileList) => {
      uploadDocumentsMutation({
        variables: {
          input: {
            moveId: parseInt(id),
            source: "LTA",
            files: [file],
            documentTypeId
          }
        }
      })
		},
		[id, uploadDocumentsMutation]
	);

  // @ts-ignore
	return <CustomUploadField {...props} onUpload={handleUpload} isUploading={loading} />;
}

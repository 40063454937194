import cn from "classnames";
import { useStyles } from "./MyJobDetails.styles";
import { useTranslation } from "react-i18next";
import { DetailsList } from "../details-list";
import { Box } from "@material-ui/core";
import { CustomButton } from "../button";
import { LinkToCall, ReportIncident } from "components";
import { Order } from "generate/types";
import { formatToDate} from "helpers";
import { DATES } from "utils";
import { useModalStateControls } from "hooks";
import { DestinationBlock } from "../destination-block";
import Typography from "@material-ui/core/Typography";
import {DetailsListItem} from "../details-list-item";
import React from "react";

export interface MyJobDetailsActiveProps extends Order {
  title?: string;
  detailsList: Record<string, string>;
}

export const MyJobDetailsActive = ({
  title,
  origin,
  destination,
  cargoType,
  readyToLoad,
  etaLoadingPoint,
  detailsList,
  truckReg,
}: MyJobDetailsActiveProps) => {
  const classes = useStyles();
  const { t } = useTranslation("myjobs");
  const { toggleModal, isOpened } = useModalStateControls();
  return (
    <div className="overflow">
      {title && <h4 className={cn("title")}>{title}</h4>}
      <Box display="flex" mt={3} mb={1}>
        <Typography className={cn(classes.label, classes.marginRight)}>
          {cargoType}
        </Typography>
        <Typography className={classes.label}>
          {t('loading_date')}: {formatToDate(readyToLoad, DATES.loadDateFormatNoTime)}
        </Typography>
      </Box>
      <DestinationBlock from={origin} to={destination} showOrigin />
      <Box display="flex" mt={2} mb={1.5}>
        <DetailsList {...detailsList} />
      </Box>
        <DetailsListItem name={t('loading_point_eta')} value={formatToDate(etaLoadingPoint, DATES.loadDateFormatNoTime)}/>
      <Box display="flex" mt={4} alignItems="center" flexDirection="column">
        <LinkToCall
          className={cn(classes.link, classes.buttonWrapper)}
          phoneNumber={"+342343243242"}
        >
          <CustomButton
            className={classes.customButton}
            data-qa="qa-call-driver"
          >
            {t("call_driver")}
          </CustomButton>
        </LinkToCall>
        <CustomButton hidden
          className={cn(classes.button, classes.buttonWrapper, classes.textCapitalized)}
          variant="outlined"
          onClick={toggleModal}
          data-qa="qa-report-incident"
        >
          {t("report_incident")}
        </CustomButton>
      </Box>
      {isOpened && (
        <ReportIncident
          truckReg={truckReg}
          onClose={toggleModal}
          onSubmit={() => {}}
        />
      )}
    </div>
  );
};

import { makeStyles } from '@material-ui/core/styles';
import {COLORS} from "../../utils";
export const useStyles = makeStyles({
	refreshIconButton: {
		position: 'absolute',
		right: '4%',
		top: '3.4%',
		transform: 'translateY(-50%)',
		zIndex: 10,
		color: COLORS.primary,
		background: '#F3F9F8',
	},
});

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: 'calc(5 * 69px)',
	},
});

import React from 'react';
import Typography from '@material-ui/core/Typography';
import { ModalWrapper } from '../ModalWrapper';
import { CustomButton } from '../../button';
import { Box } from '@material-ui/core';
import { useStyles } from './TermsModal.styles';
import { useTranslation } from 'react-i18next';

interface TermsModalProps {
	isOpened: boolean;
	onClose: () => void;
	onAccept: () => void;
}

export const TermsModal = ({ isOpened, onClose, onAccept }: TermsModalProps) => {
	const { t } = useTranslation('form-fields');
	const classes = useStyles();
	return (
		<ModalWrapper open={isOpened} onClose={onClose}>
			<>
				<Box textAlign="center" mt={2}>
					<h4 className="title">{t('sign_up.terms')}</h4>
				</Box>
				<div className={classes.text}>
					<Typography>
						These terms of service constitute a legally binding agreement (the “Agreement”) between
						you (hereinafter “you,” or “your”) and Lori Systems Corporation (hereinafter “Lori,”
						“we,” “us” or “our”), a United States corporation and any of its affiliates, governing
						your use of the Lori Client application, Lori WhatsApp Groups, website, and technology
						platform (collectively, the “Lori Platform”). PLEASE READ THESE TERMS CAREFULLY BEFORE
						ACCESSING AND USING THE services. In these Terms, the words "including" and "include"
						mean "including, but not limited to." Your access and use of the services constitutes
						your agreement to be bound by these Terms, which establishes a contractual relationship
						between you and Lori. If you do not agree to these Terms, you may not access the the
						Lori Platform or any of its services. These Terms expressly supersede prior agreements
						or arrangements with you, other than with respect to any Consignor or Transporter
						agreement with Lori Systems to the extent that such agreement is inconsistent with these
						Terms. Your use of the services may also be governed by additional terms and conditions
						provided in any Consignor or Transporter agreement with Lori. Lori may, at any time for
						any reason, without notice to you, cease offering you access to the services or any
						portion thereof or deny you access to the services or any portion thereof. Supplemental
						terms may apply to certain services, such as policies for a particular event, program,
						activity or promotion, and such supplemental terms will be disclosed to you in separate
						disclosures (e.g., a particular promotional webpage or document) or in connection with
						the applicable Service(s). Supplemental terms are in addition to, and shall be deemed a
						part of, the Terms for the purposes of the applicable Service(s). Supplemental terms
						shall prevail over these Terms in the event of a conflict with respect to the applicable
						services. Lori Systems may amend the Terms related to the services from time to time.
						Amendments will be effective upon Lori posting of such updated Terms at this location:
						www.lorisystems.com Your continued access or use of the services after such posting
						constitutes your consent to be bound by the Terms, as amended. The Lori Platform
						provides a marketplace where clients (“Consignor”) seeking transportation of commodities
						or consignments to certain destinations can be matched with (“TRANSPORTER”) driving to
						or through those destinations. TRANSPORTER and Consignor are collectively referred to
						herein as “Users” and each User shall have a user account that enables such User to
						access the Lori Platform. For purposes of this Agreement, the TRANSPORTATION services
						provided by TRANSPORTER to Consignor-that are matched through the Platform shall be
						referred to collectively as the “services”. Lori does not provide transportation
						services, and Lori is not a transportation carrier. It is upto to the transporter to
						decide whether or not to offer transportation service to a Consignor contacted through
						the Lori platform, and it is upto the Consignor to decide whether or not to accept
						transportation from any Transporter contacted through the Lori Platform. Any decision by
						a user to offer or accept services once such user is matched through the Lori Platform
						is a decision made in such user’s sole discretion.Each Transportation services provided
						by a Transporter to a Consignor shall constitute a separate agreement between such
						parties. By accepting to use the Lori Platform or any of its services, you expressly
						acknowledge that you understand this Agreement and accept all of its terms. If you do
						not agree to be bound by the terms and conditions of this agreement, you may not use or
						access the Lori Platform or its services.
					</Typography>
					<h4>Lori Communication</h4>
					<Typography>
						By becoming a Transporter or Consignor on the Lori Platform, you expressly consent and
						agree to accept and receive communication from us, including via e-mail, text message,
						calls, and push notifications to the cellular number and email address you provided to
						us. By consenting to being contacted by Lori, you understand and agree that you may
						receive communication generated by our computer servers and/or which will deliver
						messages sent by or on behalf of Lori, its affiliated companies and/or Employees,
						including but not limited to: operational communications concerning your User account or
						use of the Lori Platform or services, updates concerning new and existing features on
						the Lori Platform, communications concerning promotions run by us or our third party
						partners, and news concerning Lori and industry developments.
					</Typography>
					<h4>Consignor’s and Transporter’s Information</h4>
					<Typography>
						Your Information is any information you provide, publish or post to or through the Lori
						Platform (including any profile information you provide via in-application feedback,
						Google Response Forms, any email feature, or through any Lori-related social media
						posting) (your “Information”). You consent to us using your Information to create a User
						account that will allow you to use the Lori Platform and participate in the services.
						Our collection and use of personal information in connection with the Lori Platform and
						services is as provided in Lori’s Privacy Policy located at https://www.lori.com You are
						solely responsible for your Information as we only use it as a conduit to effectively
						engage you as a Legal business entity. You agree to provide and maintain accurate,
						current and complete information and that we and other stakeholders in the industry may
						rely on your Information as accurate, current and complete. You warrant and represent to
						us that you are the sole owner of the documents you share with us. To enable Lori use
						your Information, you grant to us a non-exclusive, worldwide, perpetual, irrevocable,
						royalty-free, sub-licensable (through multiple tiers) right and license to exercise the
						copyright, publicity, and database rights you have in your Information, and to use,
						copy, perform, display and distribute such Information to prepare derivative works, or
						incorporate into other works, such Information, in any media now known or not currently
						known. Lori does not assert any ownership over your Information; rather, as between us
						and you, subject to the rights granted to us in this Agreement, you retain full
						ownership of all of your documents and any intellectual property rights or other
						proprietary rights associated with your Information. You are the sole authorized user of
						your account and responsible for maintaining the confidentiality of any password
						provided by you or Lori for accessing the Lori Platform. You are solely and fully
						responsible for all activities that occur under your User account, and Lori expressly
						disclaims any liability arising from the unauthorized use of your User account. Should
						you suspect that any unauthorized party may be using your User account or you suspect
						any other breach of security, you agree to notify us immediately.
					</Typography>
					<h4>Transporter Representations and Warranties</h4>
					<Typography>
						By providing services as a Transporter on the Lori Platform, you represent, warrant, and
						agree that: You possess legal and valid documents and are qualified to operate as a
						haulage Transporter and have all appropriate licenses, approvals and authority to
						provide transportation to Consignors in all jurisdictions in which you operate. You own,
						or have the legal right to operate, the truck(s) you use when providing services, and
						that such vehicles are in good operating condition and meet the industry safety
						standards and all applicable statutory and state agency’s regulations. You will only
						provide services using the vehicles that have been registered to us and for which we
						have proper and scrutinized documents, and you will not transport cargo that is outside
						the permitted legal loading weight for your trucks, in the jurisdictions you operate.
						You have a valid policy of Carriers liability insurance (in coverage amounts consistent
						with all applicable legal requirements) or Goods in Transit that names or schedules you
						for the operation of the vehicles you use to provide services. You have a valid policy
						of Fidelity Guarantee (in coverage amounts consistent with all applicable legal
						requirements) that names or schedules you for the employees that operate your vehicles.
						You will be solely responsible for any and all liability that results from or is alleged
						as a result of your provision of services, including, but not limited to personal
						injuries, death and loss of property or damages (however, this provision shall not limit
						the scope of Lori ’s insurance policies referenced on https://www.lori.com). In the
						event of a truck accident, you will be solely responsible for compliance with any
						applicable statutory or agencies governing operation of motor vehicles, for reporting
						the accident to Lori and your insurer in a timely manner and seek insurance payout if
						not at fault You will comply with all applicable laws, rules and regulations while
						providing services, and you will be solely responsible for any violations of such
						provisions. You will pay all applicable national, state and local taxes based on your
						provision of services and any payments received by you. You will not make any
						misrepresentation regarding Lori, the Lori Platform, the services or your status as a
						Transporter, or, while providing the services, operate as an unauthorized transporter
						and accept to transport un-authorized consignments in a manner that is inconsistent with
						your obligations under this Agreement. You will not attempt to defraud Lori in
						connection with your provision of services. If we suspect that you have engaged in
						fraudulent activity we may withhold applicable transportation Fees or other payments for
						transportation services offered.
					</Typography>
					<h4>Indemnity</h4>
					<Typography>
						Both Transporter and Consignor shall defend, indemnify, and hold us and our affiliates
						and each of our respective officers, directors, employees, agents, shareholders and
						suppliers harmless from any claims, actions, suits, losses, costs, liabilities and
						expenses (including reasonable attorneys’ fees) relating to or arising out of your use
						of the Lori Platform.
					</Typography>
					<h4>Term and Termination</h4>
					<Typography>
						This Agreement is effective upon you signing our standard contract and/or continued
						business engagements with us. You may however discontinue use of the Lori Platform,
						services and engagements in a manner prescribed in our standard contract. Lori may also
						suspend or discontinue you as our TRANSPORTER, or revoke your permission to access the
						Lori Platform, at any time, for any reason, upon notice to you. Either party may
						terminate the Agreement for any reason upon written notice to the other party,
						consistent with the terms spelled out in the standard contract.
					</Typography>
					<h4>Proprietary Rights and Trademark License</h4>
					<Typography>
						All intellectual property rights in the Lori Platform shall be owned by us absolutely
						and in their entirety. These rights include and are not limited to database rights,
						copyright, design rights (whether registered or unregistered), trademarks (whether
						registered or unregistered) and other similar rights wherever existing in the world
						together with the right to apply for protection of the same. All other trademarks,
						logos, service marks, company or product names set forth in the Lori Platform are the
						property of their respective owners. You acknowledge and agree that any questions,
						comments, suggestions, ideas, feedback or other information (“Submissions”) provided by
						you to us are non-confidential and shall become the sole property of Lori. Lori shall
						own exclusive rights, including all intellectual property rights, and shall be entitled
						to the unrestricted use and dissemination of these Submissions for any purpose,
						commercial or otherwise, without acknowledgment or compensation to you. “Lori”, the Lori
						logo, designs, graphics, icons, scripts and service names are registered trademarks or
						trade dress of Lori in Kenya and/or other countries, where Lori has operations
						(collectively, the “Lori Marks”). If you provide services as a Transporter, Lori grants
						to you, during the term of this Agreement, and subject to your compliance with the terms
						and conditions of this Agreement, a limited, revocable, non-exclusive license to display
						and use the Lori Marks solely in connection with providing the services through the Lori
						Platform (“License”). The License is non-transferable and non-assignable, and you shall
						not grant to any third party any right, permission, license or sublicense with respect
						to any of the rights granted hereunder without Lori’s prior written permission, which it
						may withhold in its sole discretion. The Lori Marks may not be used in any manner that
						is likely to cause confusion. You acknowledge that Lori is the owner and licensor of the
						LoriMarks, and that your use of the Lori Marks will confer no additional interest in or
						ownership of the Lori Marks in you but rather inures to the benefit of Lori. You agree
						to use the Lori Marks strictly in accordance with Lori’s Trademark Usage Guidelines, as
						may be provided to you and revised from time to time, and to immediately cease any use
						that Lori determines to nonconforming or otherwise unacceptable. You agree that you will
						not: Create any materials that incorporate the Lori Marks or any derivatives of the Lori
						Marks other than as expressly approved by Lori in writing; use the Lori Marks in any way
						that tends to impair their validity as proprietary trademarks, service marks, trade
						names or trade dress, or use the Lori Marks other than in accordance with the terms,
						conditions and restrictions herein; take any other action that would jeopardize or
						impair Lori’s rights as owner of the Lori Marks or the legality and/or enforceability of
						the Lori Marks, including, without limitation, challenging or opposing Lori’s ownership
						in the Lori Marks; apply for trademark registration or renewal of trademark registration
						of any of the Lori Marks, any derivative of the Lori Marks, any combination of the Lori
						Marks and any other name, or any trademark, service mark, trade name, symbol or word
						which is similar to the Lori Marks; use the Lori Marks on or in connection with any
						product, service or activity that is in violation of any law, statute, government
						regulation or standard. Violation of any provision of this License may result in
						immediate termination of the License, in Lori’s sole discretion. If you create any
						materials bearing the Lori Marks (in violation of this Agreement or otherwise), you
						agree that upon their creation Lori exclusively owns all right, title and interest in
						and to such materials, including without limitation any modifications to the Lori Marks
						or derivative works based on the Lori Marks. You further agree to assign any interest or
						right you may have in such materials to Lori , and to provide information and execute
						any documents as reasonably requested by Lori to enable Lori to formalize such
						assignment.
					</Typography>
					<h4>Confidentiality</h4>
					<Typography>
						You agree not to use any technical, financial, strategic and other proprietary and
						confidential information relating to Lori’s business, operations and properties,
						including User information (“Confidential Information”) disclosed to you by Lori for
						your own use or for any purpose other than as contemplated herein. You shall not
						disclose or permit disclosure of any Confidential Information to third parties. You
						agree to take all reasonable measures to protect the secrecy of and avoid disclosure or
						use of Confidential Information of Lori in order to prevent it from falling into the
						public domain. Notwithstanding the above, you shall not have liability to Lori with
						regard to any Confidential Information which you can prove: was in the public domain at
						the time it was disclosed by Lori or has entered the public domain through no fault of
						yours; was known to you, without restriction, at the time of disclosure, as demonstrated
						by files in existence at the time of disclosure; is disclosed with the prior written
						approval of Lori; becomes known to you, without restriction, from a source other than
						Lori without breach of this Agreement by you and otherwise not in violation of Lori ’s
						rights; or is disclosed pursuant to the order or requirement of a court, administrative
						agency, or other governmental body; provided, however, that You shall provide prompt
						notice of such court order or requirement to Lori to enable Lori to seek a protective
						order or otherwise prevent or restrict such disclosure and you shall assist Lori as
						reasonably necessary to obtain such protective order.
					</Typography>
					<h4>Transporter Responsibility - Overland Road Transportation </h4>
					<Typography>
						The Transporter undertakes to perform in their own name to procure performance of
						transportation of consignment from the Place of Receipt, warehouse or the Port of
						Loading, whichever is applicable, to the Port of Discharge, warehouse or the Place of
						Delivery, whichever is applicable. Lori shall have no liability whatsoever for loss or
						damage to the Goods occurring before acceptance by the Transporter of custody of such
						Goods or after the Transporter tendering the Goods for delivery at the applicable
						points, and, the Transporter shall be liable for loss or damage occurring during
						transportation of consignment only to the extent set out below: (a) The Transporter and
						Lori shall be relieved of liability for any loss or damage where such loss or damage was
						caused by: (i) an act or omission of the Consignor or Person acting on behalf of the
						Consignor other than the Transporter, their servant, agent or Subcontractor; (ii)
						compliance with instructions of any Person entitled to give them; (iii) insufficient or
						defective condition of packing or marks; (iv) handling, loading, stowage or unloading of
						the Goods by the Consignor or any Person acting on his behalf; (v) inherent vice of the
						Goods; (vi) strike, lock out, stoppage or restraint of labour, from whatever cause,
						whether partial or general; (vii) Force Majeure: Events such as natural catastrophes,
						mutiny, government directives, change in law and trade embargoes occurring outside of
						Lori control, which cause consignor to suffer business losses will not be borne by Lori.
						(viii) any cause or event which the Transporter could not avoid and the consequences
						whereof he could not prevent by the exercise of reasonable diligence. (b) The burden of
						proof that the loss or damage was due to a cause(s) or event(s) specified in the above
						clause shall rest on the Transporter, but if there is any evidence the loss or damage is
						attributable to one or more cause or event specified in the former clauses (iii), (iv)
						or (v), it shall be presumed that it was so caused. The Consignor shall, however, be
						entitled to prove that the loss or damage was not, in fact, caused either wholly or
						partly by one or more of these causes or events. Where the stage of transportation where
						the loss or damage occurred is known notwithstanding anything provided for in the above
						clauses, the liability of the Transporter in respect of such loss or damage shall be
						determined: if the loss or damage is known to have occurred during any overland road
						transportation not in jurisdictions Lori operates in accordance with the contract of
						transportation or tariffs of any inland transporter in whose custody the loss or damage
						occurred or in accordance with former clauses, whichever imposes lesser liability on the
						Transporter; or Transporter is obliged to share correct ETA’s(Estimated Time of
						Arrivals) of all inbound and outbound trucks and if Lori has reason to believe that
						Transporter shared misleading information that leads to incurrence and/or imposition of
						unwarranted fines, fees or penalties, such costs shall be borne by the Transporter. If
						Transporter shares a trucklist with Lori and later withdraws or declines to offer
						transportation and without prior communication, within reasonable time, inconveniences
						and causes Lori to incur unwarranted storage fees or penalties, such costs shall be
						passed to the Transporter.
					</Typography>
					<h4>Consignor(Cargo Client) Responsibility</h4>
					<Typography>
						All of the Persons coming within the definition of Consignor as in previous clauses,
						including any principal of such Person, shall be jointly and severally liable to Lori
						for the due fulfillment of all obligations undertaken by the Consignor. The Consignor
						shall be liable for and shall indemnify Lori against all loss, damage, delay, fines,
						attorney fees and/or expenses arising from any breach of any of the warranties in all
						clauses or elsewhere in these Terms of Service and from any other clause whatsoever in
						connection with the Goods for which the Transporter is not responsible. The Consignor
						shall comply with all regulations or requirements of customs, port and other
						authorities, and shall bear and pay all duties, taxes, fines, imposts, expenses or
						losses (including, without prejudice to the generality of the foregoing Freight for any
						additional Transportation undertaken) incurred or suffered by reason of any failure to
						so comply, or by reason of any illegal, incorrect or insufficient declaration, marking,
						numbering or addressing of the Goods, and shall indemnify Lori in respect thereof. Cargo
						released into the care of the Consignor for unpacking or any other purpose whatsoever
						are at the sole risk of the Consignor until redelivered to Lori for Transportation. The
						Consignor shall indemnify Lori or its agents and Transporter for all loss of and/or
						damage and/or delay to such Cargo, and all liability claims from third parties or costs
						or fines resulting from Consignor’s use of such Cargo.
					</Typography>
					<h4>Dangerous Goods </h4>
					<Typography>
						No Goods which fall under classes one through class nine which are or may become of a
						dangerous, noxious, hazardous, flammable, or damaging nature (including radioactive
						material) or which are or may become liable to damage any Persons or property
						whatsoever, and whether or not so listed in any official or unofficial, international or
						national code, convention, listing or table shall be tendered to Lori for transportation
						without previously giving written notice of their nature, character, name, label and
						classification (if applicable) to Lori and obtaining their consent in writing and
						without distinctly marking the Goods and the Container or other covering on the outside
						so as to indicate the nature and character of any such Goods and so as to comply with
						any applicable laws, regulations or requirements. If any such Goods are delivered to
						Lori without obtaining its consent and/or such marking, or if in the opinion of Lori the
						Goods are or are liable to become of a dangerous, noxious, hazardous, flammable or
						damaging nature, they may at any time or place be unloaded, destroyed, disposed of,
						abandoned or rendered harmless without compensation to the Consignor and without
						prejudice to the Lori’s right to Freight. The Consignor warrants that such Goods are
						packed in a manner adequate to withstand the risks of Transportation having regard to
						their nature and in compliance with all laws, regulations or requirements which may be
						applicable to Lori. The Consignor shall indemnify Lori against all claims, liabilities,
						loss, damage, delay, costs, fines and/or expenses arising in consequence of the Carriage
						of such Goods, and/or arising from breach of any of the warranties in the above clauses,
						including any steps taken by Lori pursuant to the previous clause whether or not the
						Consignor was aware of the nature of such Goods. Nothing contained in this clause shall
						deprive Lori of any of his rights provided for elsewhere.
					</Typography>
					<h4>Notification, Discharge and Delivery</h4>
					<Typography>
						Any mention in the work-order and/or delivery note of parties to be notified of the
						arrival of the Goods is solely for information of Lori. Failure to give such
						notification shall not involve Lori in any liability nor relieve the Consignor of any
						obligation hereunder. The Consignor shall take delivery of the Goods within the time
						provided for in Lori’s applicable Tariff. If the Consignor fails to do so, the
						Transporter and Lori may without notice unpack the Goods if packed in containers and/or
						store the Goods ashore, afloat, in the open or under cover at the sole risk of the
						Consignor. Such storage shall constitute due delivery hereunder, and thereupon all
						liability whatsoever of the Transporter and Lori in respect of the Goods or that part
						thereof shall cease and the costs of such storage shall forthwith upon demand be paid by
						the Consignor to Lori. If the Transporter is obliged to discharge the Goods into the
						hands of any customs, port or other authority, such discharge shall constitute due
						delivery of the Goods to the Consignor under the delivery agreement. If the Goods are
						unclaimed within a reasonable time or whenever in Lori’s and Transporter’s opinion the
						Goods are likely to deteriorate, decay or become worthless, or incur charges whether for
						storage or otherwise in excess of their value, Lori may at its discretion and without
						prejudice to any other rights which it may have against the Consignor, without notice
						and without any responsibility attaching to them sell, abandon or otherwise dispose of
						the Goods at the sole risk and expense of the Consignor and apply any proceeds of sale
						in reduction of the sums due to Lori by the Consignor. Refusal by the Consignor to take
						delivery of the Goods in accordance with the terms of this clause and/or to mitigate any
						loss or damage thereto shall constitute a waiver by the Consignor to Lori of any claim
						whatsoever relating to the Goods or the Transportation thereof.
					</Typography>
					<h4>Methods and Routes of Transportation </h4>
					<Typography>
						Lori may at any time and without notice to the Consignor: (a) use any trucks available
						or storage whatsoever; (b) transfer the Goods from one conveyance to another including
						transshipping or carrying the same on a Truck other than the Truck named on the shared
						trucklist or by any other means of transport whatsoever and even though transshipment or
						forwarding of the Goods may not have been contemplated or provided for herein; (c)
						unpack and remove the Goods which have been packed into a Container or loaded onto a
						Truck and forward them via Container or bags or in bulk on available truck on Lori’s
						platform. (e) load and unload the Goods at any warehouse or port (whether or not any
						such port or warehouse is designated as the Port of Loading, discharge or) and store the
						Goods at any such port or warehouse; (f) comply with any orders or recommendations given
						by any government or authority or any Person or body acting purporting to act as or on
						behalf of such government or authority or having under the terms of the insurance on any
						conveyance employed by the Carrier the right to give orders or directions. The liberties
						set out in the aforementioned clauses may be invoked by Lori for any purpose whatsoever
						whether or not connected with the Transportation of the Goods, including but not limited
						to loading or unloading other goods, bunkering or embarking or disembarking any
						person(s), undergoing repairs and/or drydocking, towing or being towed, assisting other
						Transporters, making trial trips and adjusting instruments. Anything done or not done in
						accordance with terms herein or any delay arising therefrom shall be deemed to be within
						the contractual Transportation and shall not be deemed a breach thereof .
					</Typography>
					<h4>Matters Affecting Service Delivery</h4>
					<Typography>
						If at any time Lori is or is likely to be affected by any hindrance, risk, danger,
						delay, difficulty or disadvantage of whatsoever kind and howsoever arising which cannot
						be avoided by the exercise of reasonable endeavours, (even though the circumstances
						giving rise to such hindrance, risk, danger, delay, difficulty or disadvantage existed
						at the time this contract was entered into or the Goods were received for
						Transportation) Lori may at its sole discretion and without notice to the Consignor and
						whether or not the Transportation is commenced either: (a) Carry the Goods to the
						contracted warehouse, Port of Discharge, or Place of Delivery, whichever is applicable,
						by an alternative route to that indicated in the delivery agreement, loading order or
						that which is usual for Goods consigned to that Port of Discharge or Place of Delivery.
						If the Carrier elects to invoke the terms of this clause then, notwithstanding the
						provisions of clauses hereof, shall be entitled to charge such additional Freight as the
						Carrier may determine; or (b) Suspend the Transportation of the Goods and take custody
						of them under these Terms and Conditions and endeavour to forward them as soon as
						possible, but Lori makes no representations as to the maximum period of suspension and
						shall be entitled to charge such additional Freight and costs it may determine; or (c)
						Abandon the Transportation of the Goods and place them at the Consignor’s disposal at
						any place, warehouse, yard or port, which Lori may deem safe and convenient, whereupon
						the responsibility of the Transporter in respect of such Goods shall cease. The
						Transporter shall nevertheless be entitled to full Freight on the Goods received for the
						Transportation, and the Consignor shall pay any additional costs incurred by reason of
						the abandonment of the Goods. If the Transporter elects to use an alternative route or
						to suspend the Transportation, this shall not prejudice its right subsequently to
						abandon the Transportation.
					</Typography>
					<h4>Freight Fees and Expenses</h4>
					<Typography>
						Full Freight shall be payable based on particulars furnished by the Consignor. Lori may
						at any time instruct its Transporter to open the Goods or Container(s) and, if the
						Consignor’s particulars are incorrect, the Consignor and the Goods shall be liable for
						the correct Freight and any expenses incurred in examining, weighing, measuring, or
						valuing the Goods. Full Freight shall be considered completely earned on receipt of the
						Goods by the Transporter and shall be paid and non returnable in any event. All sums
						payable to Lori are due on demand and shall be paid in full in the Currency in which
						transportation occurs or, at the Lori’s option, in its equivalent in the currency of the
						Port of Loading or of Discharge or the Place of Receipt or of Delivery or as specified
						in Lori’s Tariff. The Consignor attention is drawn to the stipulations concerning
						currency in which the Freight is to be paid, rate of exchange, devaluation, additional
						Goods In Transit insurance premium and other contingencies relative to Freight in the
						applicable Tariff. In the event of any discrepancy between Freight (incl. charges etc)
						items in the loading and delivery orders and any Transporter invoices, the latter shall
						prevail. All Freight fees shall be paid without any set off, counterclaim, deduction or
						stay of execution at latest before delivery of the Goods. If the Consignor fails to pay
						the applicable Transportation fees when due, they shall be liable also for payment of
						service fee, interest due on any outstanding and/or overdue sum reasonable attorney fees
						and expenses incurred in collecting any sums due to Lori. Payment of Freight and charges
						to a Transporter, broker or anyone other than Lori or its authorised agent, shall not be
						deemed payment to Lori and shall be made at the Consignor’s sole risk.
					</Typography>
					<h4>Disclaimers</h4>
					<Typography>
						The following disclaimers are made on behalf of Lori , our affiliates, and each of our
						respective officers, directors, employees, agents, shareholders and suppliers. The Lori
						Platform is provided on an “as is” basis and without any warranty or condition, express,
						implied or statutory. We do not guarantee and do not promise any specific results from
						use of the Lori Platform and/or the services, including the ability to provide or
						receive services at any given location or time. We specifically disclaim any implied
						warranties of title, Consignorability, fitness for a particular purpose and
						non-infringement. Some jurisdictions do not allow the disclaimer of implied warranties,
						so the foregoing disclaimer may not apply to you. This warranty gives you specific legal
						rights and you may also have other legal rights that vary from jurisdiction to
						jurisdiction. We do not warrant that your use of the Lori Platform or services will be
						accurate, complete, reliable, current, secure, uninterrupted, always available, or
						error-free, or will meet your requirements, that any defects in the Lori Platform will
						be corrected, or that the Lori Client Platform is free of viruses or other harmful
						components. We disclaim liability for, and no warranty is made with respect to
						availability of the Lori Platform or services. We have no control over the quality or
						safety of the transportation that occurs as a result of the services. We cannot ensure
						that a Transporter or Consignor will complete an arranged transportation service. We
						cannot guarantee that each Transporter are who they claim to be. We do not procure
						Carriers Liability insurance for Transporters except for instances they have asked us to
						do so, nor are we responsible for consignments been shipped by Transporters on behalf of
						Consignors. By using the Lori Platform and participating in the services, you agree to
						accept such risks and agree that Lori is not responsible for the acts or omissions of
						either parties on the Lori Platform or participating in the services. It is possible for
						others to obtain information about you that you provide, publish or post to or through
						the Lori Platform (including any profile information you provide), send to other Users,
						or share during the services, and to use such information to harass or harm you. We are
						not responsible for the use of any personal information that you disclose to other Users
						on the Lori Platform or through the services. Please carefully select the type of
						information that you post on the Lori Platform or through the services or release to
						others. We disclaim all liability, regardless of the form of action, for the acts or
						omissions of other Users (including unauthorized users, or “hackers”). Opinions, advice,
						statements, offers, or other information or content made available through the Lori
						Platform, but not directly by us, are those of their respective authors, and should not
						necessarily be relied upon. Such authors are solely responsible for such content. Under
						no circumstances will we be responsible for any loss or damage resulting from your
						reliance on information or other content posted on the Lori Platform or otherwise
						disseminated by third parties. We reserve the right, but we have no obligation, to
						monitor the materials posted in the public areas of the Lori Platform and remove any
						such material that in our sole opinion violates, or is alleged to violate, the law or
						this agreement or which might be offensive, illegal, or that might violate the rights,
						harm, or threaten the safety of Users or other parties. The Lori Platform contains (or
						you may be sent through the Lori Platform) links to other websites owned and operated by
						third parties (“Third Party Sites”), as well as articles, photographs, text, graphics,
						pictures, designs, music, sound, video, information, applications, software and other
						content or items belonging to or originating from third parties (“Third Party Content”).
						Such Third Party Sites and Third Party Content are not investigated, monitored or
						checked for accuracy, appropriateness, or completeness by us, and we are not responsible
						for any Third Party Sites or Third Party Content accessed through the Lori Platform.
						Location data provided by the Lori Platform is for basic location purposes only and is
						not intended to be relied upon in situations where precise location information is
						needed or where erroneous, inaccurate or incomplete location data may lead to death,
						personal injury, or loss of consignments been shipped. Neither Lori, nor any of its
						content providers, guarantees the availability, accuracy, completeness, reliability, or
						timeliness of location data displayed by the Lori Platform. Any of your Information,
						including geolocational data, you upload, provide, or post on the Lori Platform may be
						accessible to Lori and certain Users of the Lori Platform.
					</Typography>
					<h4>Variation of the Contract and Validity</h4>
					<Typography>
						No servant or agent of the Transporter shall have the power to waive or vary any Terms
						and Conditions unless such waiver or variation is in writing and is specifically
						authorised or ratified in writing by Lori. In the event that anything herein contained
						is inconsistent with any applicable international convention or national law, which
						cannot be departed from by private contract, the provisions hereof shall to the extent
						of such inconsistency but no further be null and void.
					</Typography>
					<h4>Law and Jurisdiction </h4>
					<Typography>
						For shipments originating from Jurisdictions where Lori has operations, any dispute
						relating to transportation of Cargo, brought against Lori by Transporter or Consignor
						shall be governed by U.S. law and only U.S courts shall have exclusive jurisdiction to
						hear all disputes in respect thereof. Contrarily, Lori may commence proceedings against
						the Consignor or its Transporter at a competent court of a place of business of the
						Consignor, where Lori has operations.
					</Typography>
				</div>
				<Box textAlign="center" mt={2}>
					<CustomButton onClick={onAccept}>Accept</CustomButton>
				</Box>
			</>
		</ModalWrapper>
	);
};

import React, { } from 'react';
import {ModalWrapper} from 'components';
import { DropFileUpload } from 'containers';
import { useTranslation } from 'react-i18next';

interface TrucksBulkUploadProps {
	toggleModal: () => void;
	isOpened: boolean;
}

export const TruckBulkUpload = ({ isOpened, toggleModal }: TrucksBulkUploadProps) => {
	const { t: tB } = useTranslation('buttons');

	return (
		<ModalWrapper open={isOpened} onClose={toggleModal} withCloseBtn={false}>
			<div>
				<DropFileUpload
					toggleModal={toggleModal}
					name="truck-importy"
					label={tB('upload_trucks')}
					assetType="trucks"
				/>
			</div>
		</ModalWrapper>
	);
};

import { FieldTypes } from 'models';

interface FieldLists {
	checkboxFields?: string[];
	textFields?: string[];
	uploadFields?: string[];
	dateFields?: string[];
	numberFields?: string[];
	radioFields?: string[];
	selectFields?: string[];
}

export function getFieldType(
	name: string,
	{
		checkboxFields,
		textFields,
		uploadFields,
		dateFields,
		numberFields,
		radioFields,
		selectFields,
	}: FieldLists,
	defaultType: FieldTypes = FieldTypes.text
) {
	if (checkboxFields?.includes(name)) {
		return FieldTypes.checkbox;
	}
	if (textFields?.includes(name)) {
		return FieldTypes.text;
	}
	if (uploadFields?.includes(name)) {
		return FieldTypes.upload;
	}
	if (dateFields?.includes(name)) {
		return FieldTypes.date;
	}
	if (numberFields?.includes(name)) {
		return FieldTypes.number;
	}
	if (radioFields?.includes(name)) {
		return FieldTypes.radio;
	}
	if (selectFields?.includes(name)) {
		return FieldTypes.select;
	}

	return defaultType;
}

import { makeVar } from '@apollo/client';
import { isMobile } from 'mobile-device-detect';
import { CompanyData, CompanyNames, RegionType } from 'models';

const companyDetailsVar = makeVar<CompanyData | undefined>(undefined);
const isSuperAdminVar = makeVar<boolean>(false);
const isMobileBrowserVar = makeVar(isMobile);

export const state = {
	get company(): CompanyData {
		const { companyId, region } = companyDetailsVar() || {};

		return {
			companyId: companyId ? parseInt(companyId as string) : companyId,
			region,
		};
	},
	set company(value: CompanyData) {
		companyDetailsVar(value);
	},

	get region() {
		return this.company.region;
	},

	set region(region: RegionType | undefined) {
		companyDetailsVar({
			...this.company,
			region,
		});
	},

	get isSuperAdmin() {
		return isSuperAdminVar();
	},

	set isSuperAdmin(isSuperAdmin: boolean) {
		isSuperAdminVar(isSuperAdmin);
	},

	get companyId() {
		return this.company.companyId as number;
	},

	get isMobileBrowser() {
		return isMobileBrowserVar();
	},

	get isImperial() {
		return process.env.REACT_APP_COMPANY_NAME === CompanyNames.IMPERIAL;
	},
};

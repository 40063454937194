import { gql } from '@apollo/client';

export const fuelCard = gql`
	fragment fuelCard on FuelingData {
		id
		moveId
		lroId
		fuelStation
		truckReg
		transporterId
		fuelRequestAmount
		amountFuelled
		deplorifs
		fuelreqsent
		fuelType
		fuelStationPrice
		fuelPriceDiscount
		fuelDataValidated
		cityLevy
		fsCountry
		vatableExclusive
		fuelreqtra
		fuelLpo
		fsInvoice
		transporterInvoice
		stageNotes
		fsCity
        approvalState
	}
`;

import { gql } from '@apollo/client';

export const workOrderDetails = gql`
	fragment workOrderDetails on Order {
    id
    lroId
    cargoType
    commodity
    consignmentMetricTons
    createdOn
    currency
    deadlineToLift
    origin
    destination
    readyToLoad
    trucksNeeded
    totalRequested
    trucksShortlisted
    trucksMatched
    transporterRateType
    transporterPaymentTerms
    maximumFuelAmount
    totalLiters
    lowerBidMargin
    upperBidMargin
    marketRate
    packagingType
    phoneNumber
    trailerType
    truckType
    isBid
    bidPrice
    weight
    weightToLoad
    truckList {
      truckReg
      trailerReg
      driverId
      driverIdNumber
      driverName
      etaLoadingPoint
      createdOnLia
      jobRequestId
    }
	}
`;

import React from 'react';
import { CustomSwiper } from 'components/swiper';
import { ModalWrapper } from '../ModalWrapper';
import { Box, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LogoIcon } from 'components/icons';
import { CustomButton } from 'components/button';
import cn from 'classnames';

import analyticsSrc from 'assets/images/analytics.png';
import movesSrc from 'assets/images/moves.png';
import convenienceSrc from 'assets/images/convenience.png';
import { useStyles } from './SplashScreenModal.styles';

export function SplashScreenModal({ onClose }: { onClose: () => void }) {
	const { t } = useTranslation('modals');
	const { t: tB } = useTranslation('buttons');
	const classes = useStyles();

	const slideArray = [
		{
			title: t('analytics'),
			desc: t('analytics_desc'),
			src: analyticsSrc,
		},
		{
			title: t('moves'),
			desc: t('moves_desc'),
			src: movesSrc,
		},
		{
			title: t('convenience'),
			desc: t('convenience_desc'),
			src: convenienceSrc,
		},
		// {
		// 	title: t('monitoring'),
		// 	desc: t('monitoring_desc'),
		// 	src: monitoringSrc,
		// },
	];

	return (
		<ModalWrapper
			open={true}
			onClose={onClose}
			hideBackdrop
			withCloseBtn={false}
			withPaper={false}
			className={classes.bg}
		>
			<Container className={classes.container}>
				<Box mt={5} position="relative">
					<LogoIcon className="logo-main" />
					<CustomButton variant="text" size="small" className={classes.skipBtn} onClick={onClose}>
						{tB('skip')}
					</CustomButton>
				</Box>
				<CustomSwiper
					containerClassName={classes.content}
					onFinish={onClose}
					withStepper
					stepperClassName={classes.stepper}
					className={classes.swiper}
					trackMouse
				>
					{slideArray.map(({ title, desc, src }) => (
						<div key={title} className={classes.slide}>
							<h3 className={cn('title', classes.title)}>{title}</h3>
							<h4 className="subtitle subtitle--small">{desc}</h4>
							<div className={classes.imgWrapper} style={{ backgroundImage: `url(${src})` }} />
						</div>
					))}
				</CustomSwiper>
			</Container>
		</ModalWrapper>
	);
}

import React from 'react';
import OtpInput, { OTPInputProps } from 'react-otp-input';
import { COLORS, theme } from 'utils';

const styles = {
	input: {
		width: '2rem',
		height: '2rem',
	},
	container: {
		justifyContent: 'center',
		marginTop: theme.spacing(7),
	},
	focusStyle: {
		outlineColor: COLORS.primary,
	},
	separator: {
		width: '2rem',
	},
};

export interface OtpCodeInputProps extends Partial<OTPInputProps> {
	value?: string;
	onChange: (otp: string) => void;
}

export const OtpCodeInput = ({ value = '', onChange, ...props }: OtpCodeInputProps) => {
	return (
		<OtpInput
			value={value}
			onChange={onChange}
			numInputs={4}
			inputStyle={styles.input}
			focusStyle={styles.focusStyle}
			containerStyle={styles.container}
			separator={<span style={styles.separator} />}
			isInputNum
			{...props}
		/>
	);
};

/* eslint-disable */
// WARNING: This file is automatically generated. Do not edit.
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSONString: any;
};

export type ActivateSystemSettingInput = {
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
};

export enum AppSource {
  Lfa = 'LFA',
  Lia = 'LIA',
  Lta = 'LTA'
}

export type AssetAvailabilityInput = {
  comments?: InputMaybe<Scalars['String']>;
  driverIdNumber?: InputMaybe<Scalars['String']>;
  etaLoadingPoint?: InputMaybe<Scalars['DateTime']>;
  trailerReg?: InputMaybe<Scalars['String']>;
  trailerTypeId?: InputMaybe<Scalars['Int']>;
  truckReg: Scalars['String'];
};

export type AssignJobRequest = {
  __typename?: 'AssignJobRequest';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type CancelJobRequest = {
  __typename?: 'CancelJobRequest';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type CancelMove = {
  __typename?: 'CancelMove';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type CancelOrder = {
  __typename?: 'CancelOrder';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type CargoClient = ConsigneeNode & {
  __typename?: 'CargoClient';
  accountManager?: Maybe<Scalars['String']>;
  accountManagerEmail?: Maybe<Scalars['String']>;
  companyRegisteredName?: Maybe<Scalars['String']>;
  consigneeMoves?: Maybe<MoveConnection>;
  customerMoves?: Maybe<MoveConnection>;
  id: Scalars['ID'];
  isReceiver?: Maybe<Scalars['Boolean']>;
  isShipper?: Maybe<Scalars['Boolean']>;
};


export type CargoClientConsigneeMovesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type CargoClientCustomerMovesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export enum CargoType {
  BaggedAndBulk = 'BAGGED_AND_BULK',
  Container = 'CONTAINER',
  Fmcg = 'FMCG'
}

export type ConsigneeNode = {
  id: Scalars['ID'];
};

export type ContainerList = {
  __typename?: 'ContainerList';
  containerNumber?: Maybe<Scalars['String']>;
  containerSize?: Maybe<Scalars['String']>;
  emptyContainerDestination?: Maybe<Scalars['String']>;
  grossContainerWeight?: Maybe<Scalars['String']>;
};

export type CreateJobRequest = {
  __typename?: 'CreateJobRequest';
  failureCount?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type CreateJobRequestInput = {
  alternativeTransporterRate?: InputMaybe<Scalars['Float']>;
  assignedTo?: InputMaybe<Scalars['String']>;
  bidRate?: InputMaybe<Scalars['Float']>;
  comments?: InputMaybe<Scalars['String']>;
  cont2grwght?: InputMaybe<Scalars['Float']>;
  containerGrossWeight?: InputMaybe<Scalars['Float']>;
  containerNumber?: InputMaybe<Scalars['String']>;
  containerNumber2?: InputMaybe<Scalars['String']>;
  driverIdNumber?: InputMaybe<Scalars['String']>;
  etaLoadingPoint?: InputMaybe<Scalars['DateTime']>;
  isBid?: InputMaybe<Scalars['Boolean']>;
  preferredLoadingPoint?: InputMaybe<Scalars['String']>;
  preferredLoadingPointId?: InputMaybe<Scalars['Int']>;
  rate?: InputMaybe<Scalars['Float']>;
  rateType?: InputMaybe<RateType>;
  trailerReg?: InputMaybe<Scalars['String']>;
  trailerTypeId?: InputMaybe<Scalars['Int']>;
  transporterCurrency?: InputMaybe<Scalars['String']>;
  truckReg?: InputMaybe<Scalars['String']>;
  weightToLoad?: InputMaybe<Scalars['String']>;
};

export type CreateSystemSetting = {
  __typename?: 'CreateSystemSetting';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type CreateSystemSettingInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  region: Region;
  type: Type;
};

export type CreateTruckSupply = {
  __typename?: 'CreateTruckSupply';
  failureCount?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type DeactivateSystemSetting = {
  __typename?: 'DeactivateSystemSetting';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type Driver = DriverNode & {
  __typename?: 'Driver';
  id: Scalars['ID'];
  idNumber?: Maybe<Scalars['String']>;
  jobRequest?: Maybe<JobRequestConnection>;
  move?: Maybe<MoveConnection>;
  names?: Maybe<Scalars['String']>;
  onjourneyTruckId?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  transporterId?: Maybe<Scalars['Int']>;
  truckId?: Maybe<Scalars['Int']>;
};


export type DriverJobRequestArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type DriverMoveArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DriverNode = {
  id: Scalars['ID'];
};

export type Geo = GeoNode & {
  __typename?: 'Geo';
  country?: Maybe<Scalars['String']>;
  destinationMoves?: Maybe<MoveConnection>;
  emptyContainerDestinationMoves?: Maybe<MoveConnection>;
  geoCode?: Maybe<Scalars['String']>;
  geoId?: Maybe<Scalars['Int']>;
  geoName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  originMoves?: Maybe<MoveConnection>;
  region?: Maybe<Scalars['String']>;
};


export type GeoDestinationMovesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type GeoEmptyContainerDestinationMovesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type GeoOriginMovesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type GeoNode = {
  id: Scalars['ID'];
};

export type JobRequest = JobRequestNode & {
  __typename?: 'JobRequest';
  alternativeTransporterRate?: Maybe<Scalars['Float']>;
  assignedTo?: Maybe<Scalars['String']>;
  bidRate?: Maybe<Scalars['Float']>;
  clientAccountManager?: Maybe<Scalars['String']>;
  clientAccountManagerEmail?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  cont2grwght?: Maybe<Scalars['Int']>;
  containerGrossWeight?: Maybe<Scalars['Int']>;
  containerNumber?: Maybe<Scalars['String']>;
  containerNumber2?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  driver?: Maybe<Driver>;
  driverId?: Maybe<Scalars['Int']>;
  driverIdNumber?: Maybe<Scalars['String']>;
  driverPhone?: Maybe<Scalars['String']>;
  etaLoadingPoint?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isBid?: Maybe<Scalars['Boolean']>;
  isBidAccepted?: Maybe<Scalars['Boolean']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  isDuplicateRequest?: Maybe<Scalars['Boolean']>;
  isMatched?: Maybe<Scalars['Boolean']>;
  isMoveCancelled?: Maybe<Scalars['Boolean']>;
  isOnjourney?: Maybe<Scalars['Boolean']>;
  isSuspended?: Maybe<Scalars['Boolean']>;
  isTamNotified?: Maybe<Scalars['Boolean']>;
  isTransit?: Maybe<Scalars['Boolean']>;
  isUserNotified?: Maybe<Scalars['Boolean']>;
  lroId?: Maybe<Scalars['String']>;
  matchedBy?: Maybe<Scalars['String']>;
  moveId?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  preferredLoadingPoint?: Maybe<Scalars['String']>;
  preferredLoadingPointId?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['Float']>;
  rateType?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  trailer?: Maybe<Trailer>;
  trailerId?: Maybe<Scalars['Int']>;
  trailerReg?: Maybe<Scalars['String']>;
  trailerTypeId?: Maybe<Scalars['Int']>;
  transporterAccountManager?: Maybe<Scalars['String']>;
  transporterAccountManagerEmail?: Maybe<Scalars['String']>;
  transporterCurrency?: Maybe<Scalars['String']>;
  transporterEmail?: Maybe<Scalars['String']>;
  transporterId?: Maybe<Scalars['Int']>;
  transporterName?: Maybe<Scalars['String']>;
  transporterPaymentTerms?: Maybe<Scalars['Float']>;
  truckId?: Maybe<Scalars['Int']>;
  truckReg?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weightToLoad?: Maybe<Scalars['String']>;
};

export type JobRequestConnection = {
  __typename?: 'JobRequestConnection';
  edges: Array<Maybe<JobRequestEdge>>;
  pageInfo: PageInfo;
};

export type JobRequestConnectionsConnection = {
  __typename?: 'JobRequestConnectionsConnection';
  edges: Array<Maybe<JobRequestConnectionsEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type JobRequestConnectionsEdge = {
  __typename?: 'JobRequestConnectionsEdge';
  cursor: Scalars['String'];
  node?: Maybe<JobRequests>;
  other?: Maybe<Scalars['String']>;
};

export type JobRequestEdge = {
  __typename?: 'JobRequestEdge';
  cursor: Scalars['String'];
  node?: Maybe<JobRequest>;
};

export type JobRequestGlobalSearch = {
  containerNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  driverId?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  driverIdNumber?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lroIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Array<InputMaybe<AppSource>>>;
  trailerReg?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transporterId?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  truckReg?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  woids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type JobRequestNode = {
  id: Scalars['ID'];
};

export type JobRequests = JobRequestNode & {
  __typename?: 'JobRequests';
  alternativeTransporterRate?: Maybe<Scalars['Float']>;
  assignedTo?: Maybe<Scalars['String']>;
  bidRate?: Maybe<Scalars['Float']>;
  cargoType?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  consignee?: Maybe<Scalars['String']>;
  containerDetails?: Maybe<Array<Maybe<ContainerList>>>;
  containerNumber?: Maybe<Scalars['String']>;
  containerNumberTwo?: Maybe<Scalars['String']>;
  containerSize?: Maybe<Scalars['String']>;
  containerWeight?: Maybe<Scalars['Float']>;
  containerWeightTwo?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  destinationCountry?: Maybe<Scalars['String']>;
  driverIdNumber?: Maybe<Scalars['String']>;
  driverName?: Maybe<Scalars['String']>;
  driverPhone?: Maybe<Scalars['String']>;
  emptyContainerDestination?: Maybe<Scalars['String']>;
  etaLoadingPoint?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  idNumber?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isBid?: Maybe<Scalars['Boolean']>;
  isBidAccepted?: Maybe<Scalars['Boolean']>;
  loadingSite?: Maybe<Scalars['String']>;
  loriRate?: Maybe<Scalars['Float']>;
  lroId?: Maybe<Scalars['String']>;
  matchedBy?: Maybe<Scalars['String']>;
  moveId?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  offloadingSite?: Maybe<Scalars['String']>;
  originCountry?: Maybe<Scalars['String']>;
  preferredLoadingPoint?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  rateCurrencyType?: Maybe<Scalars['String']>;
  rateType?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  trailerCapacity?: Maybe<Scalars['Float']>;
  trailerReg?: Maybe<Scalars['String']>;
  trailerType?: Maybe<Scalars['String']>;
  transporterCurrency?: Maybe<Scalars['String']>;
  transporterName?: Maybe<Scalars['String']>;
  transporterPaymentPeriod?: Maybe<Scalars['String']>;
  transportType?: Maybe<Scalars['String']>;
  truckCreatedAt?: Maybe<Scalars['DateTime']>;
  truckReg?: Maybe<Scalars['String']>;
  truckSerial?: Maybe<Scalars['String']>;
  trucksNeeded?: Maybe<Scalars['Int']>;
  truckType?: Maybe<Scalars['String']>;
  weightToLoad?: Maybe<Scalars['String']>;
};

export enum Matched {
  IsMatched = 'IS_MATCHED',
  NotMatched = 'NOT_MATCHED'
}

export type MatchJobRequest = {
  __typename?: 'MatchJobRequest';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type MatchJobRequestInput = {
  id: Scalars['Int'];
  matchedBy: Scalars['String'];
};

export type Move = MoveNode & {
  __typename?: 'Move';
  alertDescription?: Maybe<Scalars['String']>;
  alertStatus?: Maybe<Scalars['String']>;
  bagsdel?: Maybe<Scalars['Int']>;
  bagsLoaded?: Maybe<Scalars['Int']>;
  barcoder?: Maybe<Scalars['DateTime']>;
  borderpenalty?: Maybe<Scalars['String']>;
  cargoinv?: Maybe<Scalars['String']>;
  cargoInvoiceNumber?: Maybe<Scalars['String']>;
  cargoOwnerInvoiceWeight?: Maybe<Scalars['Float']>;
  cargoRate?: Maybe<Scalars['Float']>;
  cargoType?: Maybe<Scalars['String']>;
  cdn?: Maybe<Scalars['String']>;
  clearing?: Maybe<Scalars['String']>;
  clientCurrency?: Maybe<Scalars['String']>;
  clientDeliveryNote?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['Int']>;
  cn?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  commodityId?: Maybe<Scalars['String']>;
  consignee?: Maybe<Scalars['String']>;
  consigneeAddress?: Maybe<Scalars['String']>;
  consigneeId?: Maybe<Scalars['Int']>;
  consigneeRec?: Maybe<CargoClient>;
  cont2grwght?: Maybe<Scalars['String']>;
  containerGrossWeight?: Maybe<Scalars['Int']>;
  containerNumber?: Maybe<Scalars['String']>;
  containerNumber2?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  customer?: Maybe<CargoClient>;
  dambags?: Maybe<Scalars['Int']>;
  deadlineToLift?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deliveryConfirmationStatus?: Maybe<Scalars['String']>;
  delqty?: Maybe<Scalars['Int']>;
  destination?: Maybe<Scalars['String']>;
  destination2Code?: Maybe<Scalars['String']>;
  destination3Code?: Maybe<Scalars['String']>;
  destinationCode?: Maybe<Scalars['String']>;
  destinationRec?: Maybe<Geo>;
  devaddress1?: Maybe<Scalars['String']>;
  devaddress2?: Maybe<Scalars['String']>;
  devaddress3?: Maybe<Scalars['String']>;
  diskm?: Maybe<Scalars['Int']>;
  driver?: Maybe<Driver>;
  driverId?: Maybe<Scalars['String']>;
  driverName?: Maybe<Scalars['String']>;
  driverPhone?: Maybe<Scalars['String']>;
  emptyContainerDestination?: Maybe<Scalars['String']>;
  emptyContainerDestinationRec?: Maybe<Geo>;
  emptyReturnChanged?: Maybe<Scalars['Boolean']>;
  emptyReturnDestinationId?: Maybe<Scalars['Int']>;
  etaToLoading?: Maybe<Scalars['String']>;
  etaToOffloading?: Maybe<Scalars['String']>;
  exitNoteStatus?: Maybe<Scalars['String']>;
  gatePassNumber?: Maybe<Scalars['String']>;
  guaranteeFormExpiryDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  importExport?: Maybe<Scalars['String']>;
  issues?: Maybe<Scalars['JSONString']>;
  lastKnownLocation?: Maybe<Scalars['String']>;
  lastKnownLocationTime?: Maybe<Scalars['String']>;
  llon?: Maybe<Scalars['String']>;
  llon1?: Maybe<Scalars['String']>;
  loadbico?: Maybe<Scalars['String']>;
  loaddoc?: Maybe<Scalars['String']>;
  loadedTonnage?: Maybe<Scalars['Float']>;
  loadingContactId?: Maybe<Scalars['Int']>;
  loriLoadingNumber?: Maybe<Scalars['String']>;
  lroId?: Maybe<Scalars['String']>;
  lst?: Maybe<Scalars['String']>;
  misbags?: Maybe<Scalars['Int']>;
  moveId: Scalars['ID'];
  offloadbico?: Maybe<Scalars['Int']>;
  offloadedTonnage?: Maybe<Scalars['Float']>;
  offloadingContactId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  origin?: Maybe<Scalars['String']>;
  originCode?: Maybe<Scalars['String']>;
  originRec?: Maybe<Geo>;
  penalties?: Maybe<Scalars['JSONString']>;
  penaltyamt?: Maybe<Scalars['Int']>;
  penaltycur?: Maybe<Scalars['String']>;
  penaltypayer?: Maybe<Scalars['String']>;
  podtoinv?: Maybe<Scalars['String']>;
  portInterchangeSlipNo?: Maybe<Scalars['String']>;
  preferedLoadingPoint?: Maybe<Scalars['String']>;
  primaryDeliveryConfirmationRecipientName?: Maybe<Scalars['String']>;
  primaryDeliveryConfirmationRecipientPhone?: Maybe<Scalars['String']>;
  qtyLoaded?: Maybe<Scalars['Int']>;
  receivername2?: Maybe<Scalars['String']>;
  rejbags?: Maybe<Scalars['Int']>;
  remarks?: Maybe<Scalars['String']>;
  rtoutliers?: Maybe<Scalars['String']>;
  salelpo?: Maybe<Scalars['String']>;
  sealNumber?: Maybe<Scalars['String']>;
  secondaryDeliveryConfirmationRecipientName?: Maybe<Scalars['String']>;
  secondaryDeliveryConfirmationRecipientPhone?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  t1Expiry?: Maybe<Scalars['DateTime']>;
  totalCargoAllocation?: Maybe<Scalars['Float']>;
  trailer?: Maybe<Trailer>;
  trailerCapacity?: Maybe<Scalars['String']>;
  trailerId?: Maybe<Scalars['String']>;
  trailerType?: Maybe<Scalars['String']>;
  transporterCurrency?: Maybe<Scalars['String']>;
  transporterId?: Maybe<Scalars['Int']>;
  transporterInvoiceNumber?: Maybe<Scalars['String']>;
  transporterPaymentTerms?: Maybe<Scalars['Float']>;
  transporterRate?: Maybe<Scalars['Float']>;
  transporterRateType?: Maybe<Scalars['String']>;
  transporterWeightInvoice?: Maybe<Scalars['Int']>;
  truckId?: Maybe<Scalars['Int']>;
  truckReg?: Maybe<Scalars['String']>;
  truckType?: Maybe<Scalars['String']>;
  turnBoyName?: Maybe<Scalars['String']>;
  turnBoyPhone?: Maybe<Scalars['String']>;
  unitLoaded?: Maybe<Scalars['String']>;
  varianceAllowedCargoOwner?: Maybe<Scalars['Float']>;
  varianceAllowedTransporter?: Maybe<Scalars['Float']>;
  varianceRateTransporterPerkg?: Maybe<Scalars['Float']>;
  varianceRateTransporterToUse?: Maybe<Scalars['String']>;
  vesselId?: Maybe<Scalars['Int']>;
  waybillinv?: Maybe<Scalars['String']>;
  weightToLoad?: Maybe<Scalars['String']>;
  workflowId?: Maybe<Scalars['Int']>;
};

export type MoveConnection = {
  __typename?: 'MoveConnection';
  edges: Array<Maybe<MoveEdge>>;
  pageInfo: PageInfo;
};

export type MoveConnectionsConnection = {
  __typename?: 'MoveConnectionsConnection';
  edges: Array<Maybe<MoveConnectionsEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MoveConnectionsEdge = {
  __typename?: 'MoveConnectionsEdge';
  cursor: Scalars['String'];
  node?: Maybe<Move>;
  other?: Maybe<Scalars['String']>;
};

export type MoveEdge = {
  __typename?: 'MoveEdge';
  cursor: Scalars['String'];
  node?: Maybe<Move>;
};

export type MoveNode = {
  id: Scalars['ID'];
};

export enum MoveSortEnum {
  AlertDescriptionAsc = 'ALERT_DESCRIPTION_ASC',
  AlertDescriptionDesc = 'ALERT_DESCRIPTION_DESC',
  AlertStatusAsc = 'ALERT_STATUS_ASC',
  AlertStatusDesc = 'ALERT_STATUS_DESC',
  BagsLoadedAsc = 'BAGS_LOADED_ASC',
  BagsLoadedDesc = 'BAGS_LOADED_DESC',
  BagsdelAsc = 'BAGSDEL_ASC',
  BagsdelDesc = 'BAGSDEL_DESC',
  BarcoderAsc = 'BARCODER_ASC',
  BarcoderDesc = 'BARCODER_DESC',
  BorderpenaltyAsc = 'BORDERPENALTY_ASC',
  BorderpenaltyDesc = 'BORDERPENALTY_DESC',
  CargoInvoiceNumberAsc = 'CARGO_INVOICE_NUMBER_ASC',
  CargoInvoiceNumberDesc = 'CARGO_INVOICE_NUMBER_DESC',
  CargoOwnerInvoiceWeightAsc = 'CARGO_OWNER_INVOICE_WEIGHT_ASC',
  CargoOwnerInvoiceWeightDesc = 'CARGO_OWNER_INVOICE_WEIGHT_DESC',
  CargoRateAsc = 'CARGO_RATE_ASC',
  CargoRateDesc = 'CARGO_RATE_DESC',
  CargoTypeAsc = 'CARGO_TYPE_ASC',
  CargoTypeDesc = 'CARGO_TYPE_DESC',
  CargoinvAsc = 'CARGOINV_ASC',
  CargoinvDesc = 'CARGOINV_DESC',
  CdnAsc = 'CDN_ASC',
  CdnDesc = 'CDN_DESC',
  ClearingAsc = 'CLEARING_ASC',
  ClearingDesc = 'CLEARING_DESC',
  ClientCurrencyAsc = 'CLIENT_CURRENCY_ASC',
  ClientCurrencyDesc = 'CLIENT_CURRENCY_DESC',
  ClientDeliveryNoteAsc = 'CLIENT_DELIVERY_NOTE_ASC',
  ClientDeliveryNoteDesc = 'CLIENT_DELIVERY_NOTE_DESC',
  ClientIdAsc = 'CLIENT_ID_ASC',
  ClientIdDesc = 'CLIENT_ID_DESC',
  CnAsc = 'CN_ASC',
  CnDesc = 'CN_DESC',
  CommentsAsc = 'COMMENTS_ASC',
  CommentsDesc = 'COMMENTS_DESC',
  CommodityIdAsc = 'COMMODITY_ID_ASC',
  CommodityIdDesc = 'COMMODITY_ID_DESC',
  ConsigneeAddressAsc = 'CONSIGNEE_ADDRESS_ASC',
  ConsigneeAddressDesc = 'CONSIGNEE_ADDRESS_DESC',
  ConsigneeIdAsc = 'CONSIGNEE_ID_ASC',
  ConsigneeIdDesc = 'CONSIGNEE_ID_DESC',
  Cont2GrwghtAsc = 'CONT2GRWGHT_ASC',
  Cont2GrwghtDesc = 'CONT2GRWGHT_DESC',
  ContainerGrossWeightAsc = 'CONTAINER_GROSS_WEIGHT_ASC',
  ContainerGrossWeightDesc = 'CONTAINER_GROSS_WEIGHT_DESC',
  ContainerNumber_2Asc = 'CONTAINER_NUMBER_2_ASC',
  ContainerNumber_2Desc = 'CONTAINER_NUMBER_2_DESC',
  ContainerNumberAsc = 'CONTAINER_NUMBER_ASC',
  ContainerNumberDesc = 'CONTAINER_NUMBER_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DambagsAsc = 'DAMBAGS_ASC',
  DambagsDesc = 'DAMBAGS_DESC',
  DeadlineToLiftAsc = 'DEADLINE_TO_LIFT_ASC',
  DeadlineToLiftDesc = 'DEADLINE_TO_LIFT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  DeliveryConfirmationStatusAsc = 'DELIVERY_CONFIRMATION_STATUS_ASC',
  DeliveryConfirmationStatusDesc = 'DELIVERY_CONFIRMATION_STATUS_DESC',
  DelqtyAsc = 'DELQTY_ASC',
  DelqtyDesc = 'DELQTY_DESC',
  Destination_2CodeAsc = 'DESTINATION_2_CODE_ASC',
  Destination_2CodeDesc = 'DESTINATION_2_CODE_DESC',
  Destination_3CodeAsc = 'DESTINATION_3_CODE_ASC',
  Destination_3CodeDesc = 'DESTINATION_3_CODE_DESC',
  DestinationCodeAsc = 'DESTINATION_CODE_ASC',
  DestinationCodeDesc = 'DESTINATION_CODE_DESC',
  Devaddress1Asc = 'DEVADDRESS1_ASC',
  Devaddress1Desc = 'DEVADDRESS1_DESC',
  Devaddress2Asc = 'DEVADDRESS2_ASC',
  Devaddress2Desc = 'DEVADDRESS2_DESC',
  Devaddress3Asc = 'DEVADDRESS3_ASC',
  Devaddress3Desc = 'DEVADDRESS3_DESC',
  DiskmAsc = 'DISKM_ASC',
  DiskmDesc = 'DISKM_DESC',
  DriverIdAsc = 'DRIVER_ID_ASC',
  DriverIdDesc = 'DRIVER_ID_DESC',
  DriverPhoneAsc = 'DRIVER_PHONE_ASC',
  DriverPhoneDesc = 'DRIVER_PHONE_DESC',
  EmptyReturnChangedAsc = 'EMPTY_RETURN_CHANGED_ASC',
  EmptyReturnChangedDesc = 'EMPTY_RETURN_CHANGED_DESC',
  EmptyReturnDestinationIdAsc = 'EMPTY_RETURN_DESTINATION_ID_ASC',
  EmptyReturnDestinationIdDesc = 'EMPTY_RETURN_DESTINATION_ID_DESC',
  EtaToLoadingAsc = 'ETA_TO_LOADING_ASC',
  EtaToLoadingDesc = 'ETA_TO_LOADING_DESC',
  EtaToOffloadingAsc = 'ETA_TO_OFFLOADING_ASC',
  EtaToOffloadingDesc = 'ETA_TO_OFFLOADING_DESC',
  ExitNoteStatusAsc = 'EXIT_NOTE_STATUS_ASC',
  ExitNoteStatusDesc = 'EXIT_NOTE_STATUS_DESC',
  GatePassNumberAsc = 'GATE_PASS_NUMBER_ASC',
  GatePassNumberDesc = 'GATE_PASS_NUMBER_DESC',
  GuaranteeFormExpiryDateAsc = 'GUARANTEE_FORM_EXPIRY_DATE_ASC',
  GuaranteeFormExpiryDateDesc = 'GUARANTEE_FORM_EXPIRY_DATE_DESC',
  ImportExportAsc = 'IMPORT_EXPORT_ASC',
  ImportExportDesc = 'IMPORT_EXPORT_DESC',
  IssuesAsc = 'ISSUES_ASC',
  IssuesDesc = 'ISSUES_DESC',
  LastKnownLocationAsc = 'LAST_KNOWN_LOCATION_ASC',
  LastKnownLocationDesc = 'LAST_KNOWN_LOCATION_DESC',
  LastKnownLocationTimeAsc = 'LAST_KNOWN_LOCATION_TIME_ASC',
  LastKnownLocationTimeDesc = 'LAST_KNOWN_LOCATION_TIME_DESC',
  LlonAsc = 'LLON_ASC',
  LlonDesc = 'LLON_DESC',
  Llon1Asc = 'LLON1_ASC',
  Llon1Desc = 'LLON1_DESC',
  LoadbicoAsc = 'LOADBICO_ASC',
  LoadbicoDesc = 'LOADBICO_DESC',
  LoaddocAsc = 'LOADDOC_ASC',
  LoaddocDesc = 'LOADDOC_DESC',
  LoadedTonnageAsc = 'LOADED_TONNAGE_ASC',
  LoadedTonnageDesc = 'LOADED_TONNAGE_DESC',
  LoadingContactIdAsc = 'LOADING_CONTACT_ID_ASC',
  LoadingContactIdDesc = 'LOADING_CONTACT_ID_DESC',
  LoriLoadingNumberAsc = 'LORI_LOADING_NUMBER_ASC',
  LoriLoadingNumberDesc = 'LORI_LOADING_NUMBER_DESC',
  LroIdAsc = 'LRO_ID_ASC',
  LroIdDesc = 'LRO_ID_DESC',
  LstAsc = 'LST_ASC',
  LstDesc = 'LST_DESC',
  MisbagsAsc = 'MISBAGS_ASC',
  MisbagsDesc = 'MISBAGS_DESC',
  MoveIdAsc = 'MOVE_ID_ASC',
  MoveIdDesc = 'MOVE_ID_DESC',
  OffloadbicoAsc = 'OFFLOADBICO_ASC',
  OffloadbicoDesc = 'OFFLOADBICO_DESC',
  OffloadedTonnageAsc = 'OFFLOADED_TONNAGE_ASC',
  OffloadedTonnageDesc = 'OFFLOADED_TONNAGE_DESC',
  OffloadingContactIdAsc = 'OFFLOADING_CONTACT_ID_ASC',
  OffloadingContactIdDesc = 'OFFLOADING_CONTACT_ID_DESC',
  OrderIdAsc = 'ORDER_ID_ASC',
  OrderIdDesc = 'ORDER_ID_DESC',
  OriginCodeAsc = 'ORIGIN_CODE_ASC',
  OriginCodeDesc = 'ORIGIN_CODE_DESC',
  PenaltiesAsc = 'PENALTIES_ASC',
  PenaltiesDesc = 'PENALTIES_DESC',
  PenaltyamtAsc = 'PENALTYAMT_ASC',
  PenaltyamtDesc = 'PENALTYAMT_DESC',
  PenaltycurAsc = 'PENALTYCUR_ASC',
  PenaltycurDesc = 'PENALTYCUR_DESC',
  PenaltypayerAsc = 'PENALTYPAYER_ASC',
  PenaltypayerDesc = 'PENALTYPAYER_DESC',
  PodtoinvAsc = 'PODTOINV_ASC',
  PodtoinvDesc = 'PODTOINV_DESC',
  PortInterchangeSlipNoAsc = 'PORT_INTERCHANGE_SLIP_NO_ASC',
  PortInterchangeSlipNoDesc = 'PORT_INTERCHANGE_SLIP_NO_DESC',
  PreferedLoadingPointAsc = 'PREFERED_LOADING_POINT_ASC',
  PreferedLoadingPointDesc = 'PREFERED_LOADING_POINT_DESC',
  PrimaryDeliveryConfirmationRecipientNameAsc = 'PRIMARY_DELIVERY_CONFIRMATION_RECIPIENT_NAME_ASC',
  PrimaryDeliveryConfirmationRecipientNameDesc = 'PRIMARY_DELIVERY_CONFIRMATION_RECIPIENT_NAME_DESC',
  PrimaryDeliveryConfirmationRecipientPhoneAsc = 'PRIMARY_DELIVERY_CONFIRMATION_RECIPIENT_PHONE_ASC',
  PrimaryDeliveryConfirmationRecipientPhoneDesc = 'PRIMARY_DELIVERY_CONFIRMATION_RECIPIENT_PHONE_DESC',
  QtyLoadedAsc = 'QTY_LOADED_ASC',
  QtyLoadedDesc = 'QTY_LOADED_DESC',
  Receivername2Asc = 'RECEIVERNAME2_ASC',
  Receivername2Desc = 'RECEIVERNAME2_DESC',
  RejbagsAsc = 'REJBAGS_ASC',
  RejbagsDesc = 'REJBAGS_DESC',
  RemarksAsc = 'REMARKS_ASC',
  RemarksDesc = 'REMARKS_DESC',
  RtoutliersAsc = 'RTOUTLIERS_ASC',
  RtoutliersDesc = 'RTOUTLIERS_DESC',
  SalelpoAsc = 'SALELPO_ASC',
  SalelpoDesc = 'SALELPO_DESC',
  SealNumberAsc = 'SEAL_NUMBER_ASC',
  SealNumberDesc = 'SEAL_NUMBER_DESC',
  SecondaryDeliveryConfirmationRecipientNameAsc = 'SECONDARY_DELIVERY_CONFIRMATION_RECIPIENT_NAME_ASC',
  SecondaryDeliveryConfirmationRecipientNameDesc = 'SECONDARY_DELIVERY_CONFIRMATION_RECIPIENT_NAME_DESC',
  SecondaryDeliveryConfirmationRecipientPhoneAsc = 'SECONDARY_DELIVERY_CONFIRMATION_RECIPIENT_PHONE_ASC',
  SecondaryDeliveryConfirmationRecipientPhoneDesc = 'SECONDARY_DELIVERY_CONFIRMATION_RECIPIENT_PHONE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  T1ExpiryAsc = 'T1_EXPIRY_ASC',
  T1ExpiryDesc = 'T1_EXPIRY_DESC',
  TotalCargoAllocationAsc = 'TOTAL_CARGO_ALLOCATION_ASC',
  TotalCargoAllocationDesc = 'TOTAL_CARGO_ALLOCATION_DESC',
  TrailerIdAsc = 'TRAILER_ID_ASC',
  TrailerIdDesc = 'TRAILER_ID_DESC',
  TransporterCurrencyAsc = 'TRANSPORTER_CURRENCY_ASC',
  TransporterCurrencyDesc = 'TRANSPORTER_CURRENCY_DESC',
  TransporterIdAsc = 'TRANSPORTER_ID_ASC',
  TransporterIdDesc = 'TRANSPORTER_ID_DESC',
  TransporterInvoiceNumberAsc = 'TRANSPORTER_INVOICE_NUMBER_ASC',
  TransporterInvoiceNumberDesc = 'TRANSPORTER_INVOICE_NUMBER_DESC',
  TransporterPaymentTermsAsc = 'TRANSPORTER_PAYMENT_TERMS_ASC',
  TransporterPaymentTermsDesc = 'TRANSPORTER_PAYMENT_TERMS_DESC',
  TransporterRateAsc = 'TRANSPORTER_RATE_ASC',
  TransporterRateDesc = 'TRANSPORTER_RATE_DESC',
  TransporterRateTypeAsc = 'TRANSPORTER_RATE_TYPE_ASC',
  TransporterRateTypeDesc = 'TRANSPORTER_RATE_TYPE_DESC',
  TransporterWeightInvoiceAsc = 'TRANSPORTER_WEIGHT_INVOICE_ASC',
  TransporterWeightInvoiceDesc = 'TRANSPORTER_WEIGHT_INVOICE_DESC',
  TruckIdAsc = 'TRUCK_ID_ASC',
  TruckIdDesc = 'TRUCK_ID_DESC',
  TruckRegAsc = 'TRUCK_REG_ASC',
  TruckRegDesc = 'TRUCK_REG_DESC',
  TruckTypeAsc = 'TRUCK_TYPE_ASC',
  TruckTypeDesc = 'TRUCK_TYPE_DESC',
  TurnBoyNameAsc = 'TURN_BOY_NAME_ASC',
  TurnBoyNameDesc = 'TURN_BOY_NAME_DESC',
  TurnBoyPhoneAsc = 'TURN_BOY_PHONE_ASC',
  TurnBoyPhoneDesc = 'TURN_BOY_PHONE_DESC',
  UnitLoadedAsc = 'UNIT_LOADED_ASC',
  UnitLoadedDesc = 'UNIT_LOADED_DESC',
  VarianceAllowedCargoOwnerAsc = 'VARIANCE_ALLOWED_CARGO_OWNER_ASC',
  VarianceAllowedCargoOwnerDesc = 'VARIANCE_ALLOWED_CARGO_OWNER_DESC',
  VarianceAllowedTransporterAsc = 'VARIANCE_ALLOWED_TRANSPORTER_ASC',
  VarianceAllowedTransporterDesc = 'VARIANCE_ALLOWED_TRANSPORTER_DESC',
  VarianceRateTransporterPerkgAsc = 'VARIANCE_RATE_TRANSPORTER_PERKG_ASC',
  VarianceRateTransporterPerkgDesc = 'VARIANCE_RATE_TRANSPORTER_PERKG_DESC',
  VarianceRateTransporterToUseAsc = 'VARIANCE_RATE_TRANSPORTER_TO_USE_ASC',
  VarianceRateTransporterToUseDesc = 'VARIANCE_RATE_TRANSPORTER_TO_USE_DESC',
  VesselIdAsc = 'VESSEL_ID_ASC',
  VesselIdDesc = 'VESSEL_ID_DESC',
  WaybillinvAsc = 'WAYBILLINV_ASC',
  WaybillinvDesc = 'WAYBILLINV_DESC',
  WeightToLoadAsc = 'WEIGHT_TO_LOAD_ASC',
  WeightToLoadDesc = 'WEIGHT_TO_LOAD_DESC',
  WorkflowIdAsc = 'WORKFLOW_ID_ASC',
  WorkflowIdDesc = 'WORKFLOW_ID_DESC'
}

export enum NextStage {
  Cancelled = 'CANCELLED'
}

export type Node = {
  id: Scalars['ID'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export enum RateType {
  Container = 'CONTAINER',
  Ton = 'TON',
  TonKm = 'TON_KM',
  Truckload = 'TRUCKLOAD'
}

export enum Region {
  Ea = 'EA',
  Sa = 'SA',
  Wa = 'WA'
}

export type RestoreMove = {
  __typename?: 'RestoreMove';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type RootMutation = {
  __typename?: 'RootMutation';
  assignJobRequest?: Maybe<AssignJobRequest>;
  cancelJobRequest?: Maybe<CancelJobRequest>;
  cancelMove?: Maybe<CancelMove>;
  cancelOrder?: Maybe<CancelOrder>;
  createJobRequest?: Maybe<CreateJobRequest>;
  createSystemSetting?: Maybe<CreateSystemSetting>;
  createTruckSupply?: Maybe<CreateTruckSupply>;
  deactivateSystemSetting?: Maybe<DeactivateSystemSetting>;
  matchJobRequest?: Maybe<MatchJobRequest>;
  restoreMove?: Maybe<RestoreMove>;
  suspendMove?: Maybe<SuspendMove>;
  unmatchJobRequest?: Maybe<UnMatchJobRequest>;
  updateBidStatus?: Maybe<UpdateBidStatus>;
  updateInbound?: Maybe<UpdateInbound>;
  updateJobRequest?: Maybe<UpdateJobRequest>;
  updateMove?: Maybe<UpdateMove>;
  updateSystemSettings?: Maybe<UpdateSystemSetting>;
};


export type RootMutationAssignJobRequestArgs = {
  assign?: InputMaybe<Scalars['Boolean']>;
  assignTo?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};


export type RootMutationCancelJobRequestArgs = {
  cancelledBy?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  source?: InputMaybe<Source>;
};


export type RootMutationCancelMoveArgs = {
  comments?: InputMaybe<Scalars['String']>;
  moveId: Scalars['Int'];
};


export type RootMutationCancelOrderArgs = {
  comments?: InputMaybe<Scalars['String']>;
  lroId: Scalars['String'];
};


export type RootMutationCreateJobRequestArgs = {
  createdBy: Scalars['String'];
  jobRequestList?: InputMaybe<Array<InputMaybe<CreateJobRequestInput>>>;
  lroId: Scalars['String'];
  numberOfTrucks?: InputMaybe<Scalars['Int']>;
  source: Source;
  transporterId: Scalars['Int'];
};


export type RootMutationCreateSystemSettingArgs = {
  settingsData?: InputMaybe<CreateSystemSettingInput>;
};


export type RootMutationCreateTruckSupplyArgs = {
  assetList: Array<InputMaybe<AssetAvailabilityInput>>;
  createdBy: Scalars['String'];
  source: Source;
  transporterId: Scalars['Int'];
};


export type RootMutationDeactivateSystemSettingArgs = {
  settingsData: ActivateSystemSettingInput;
};


export type RootMutationMatchJobRequestArgs = {
  jobRequestList: Array<InputMaybe<MatchJobRequestInput>>;
};


export type RootMutationRestoreMoveArgs = {
  moveIdList: Array<InputMaybe<Scalars['Int']>>;
};


export type RootMutationSuspendMoveArgs = {
  moveList: Array<InputMaybe<SuspendMoveInput>>;
};


export type RootMutationUnmatchJobRequestArgs = {
  moveList: Array<InputMaybe<UnMatchJobRequestInput>>;
};


export type RootMutationUpdateBidStatusArgs = {
  bidStatus: Scalars['Boolean'];
  id: Scalars['Int'];
  updatedBy: Scalars['String'];
};


export type RootMutationUpdateInboundArgs = {
  inbound?: InputMaybe<Scalars['Boolean']>;
  moveId: Scalars['Int'];
  truckReg: Scalars['String'];
};


export type RootMutationUpdateJobRequestArgs = {
  id: Scalars['Int'];
  jobRequestData: UpdateJobRequestInput;
};


export type RootMutationUpdateMoveArgs = {
  moveData: UpdateMoveInput;
  moveId: Scalars['Int'];
};


export type RootMutationUpdateSystemSettingsArgs = {
  id: Scalars['Int'];
  settingsData?: InputMaybe<UpdateSystemSettingInput>;
};

export type RootQuery = {
  __typename?: 'RootQuery';
  jobRequest?: Maybe<JobRequest>;
  jobRequests?: Maybe<JobRequestConnectionsConnection>;
  move?: Maybe<Move>;
  moves?: Maybe<MoveConnectionsConnection>;
  node?: Maybe<Node>;
  setting?: Maybe<SupplySetting>;
  settings?: Maybe<SupplySettingConnection>;
};


export type RootQueryJobRequestArgs = {
  driverId?: InputMaybe<Scalars['Int']>;
  driverIdNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  moveId?: InputMaybe<Scalars['Int']>;
  trailerId?: InputMaybe<Scalars['Int']>;
  trailerReg?: InputMaybe<Scalars['String']>;
  transporterId?: InputMaybe<Scalars['Int']>;
  truckId?: InputMaybe<Scalars['Int']>;
  truckReg?: InputMaybe<Scalars['String']>;
};


export type RootQueryJobRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  cargoType?: InputMaybe<CargoType>;
  driverIdNumberList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  globalSearch?: InputMaybe<JobRequestGlobalSearch>;
  isAllocated?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  matched?: InputMaybe<Matched>;
  moveIdList?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  region?: InputMaybe<Region>;
  requestIdList?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  search?: InputMaybe<Scalars['String']>;
  trailerRegList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transporterId?: InputMaybe<Scalars['Int']>;
  truckRegList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type RootQueryMoveArgs = {
  driverId?: InputMaybe<Scalars['Int']>;
  driverIdNumber?: InputMaybe<Scalars['String']>;
  moveId?: InputMaybe<Scalars['Int']>;
  trailerId?: InputMaybe<Scalars['Int']>;
  trailerReg?: InputMaybe<Scalars['String']>;
  transporterId?: InputMaybe<Scalars['Int']>;
  truckId?: InputMaybe<Scalars['Int']>;
  truckReg?: InputMaybe<Scalars['String']>;
};


export type RootQueryMovesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  consigneeId?: InputMaybe<Scalars['Int']>;
  customerId?: InputMaybe<Scalars['Int']>;
  driverIdList?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  driverIdNumberList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  lroId?: InputMaybe<Scalars['String']>;
  matched?: InputMaybe<Matched>;
  moveIdList?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<MoveSortEnum>>>;
  trailerIdList?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  transporterId?: InputMaybe<Scalars['Int']>;
  truckIdList?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  truckRegList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type RootQueryNodeArgs = {
  id: Scalars['ID'];
};


export type RootQuerySettingArgs = {
  id?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
};


export type RootQuerySettingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  dataType?: InputMaybe<Type>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  last?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Region>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SupplySettingSortEnum>>>;
};

export enum Source {
  LfaEa = 'LFA_EA',
  LfaSa = 'LFA_SA',
  LfaWa = 'LFA_WA',
  LiaEa = 'LIA_EA',
  LiaSa = 'LIA_SA',
  LiaWa = 'LIA_WA',
  LtaEa = 'LTA_EA',
  LtaSa = 'LTA_SA',
  LtaWa = 'LTA_WA'
}

export type SupplySetting = SupplySettingNode & {
  __typename?: 'SupplySetting';
  active?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dataType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SupplySettingConnection = {
  __typename?: 'SupplySettingConnection';
  edges: Array<Maybe<SupplySettingEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
};

export type SupplySettingEdge = {
  __typename?: 'SupplySettingEdge';
  cursor: Scalars['String'];
  node?: Maybe<SupplySetting>;
  other?: Maybe<Scalars['String']>;
};

export type SupplySettingNode = {
  id: Scalars['ID'];
};

export enum SupplySettingSortEnum {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DataTypeAsc = 'DATA_TYPE_ASC',
  DataTypeDesc = 'DATA_TYPE_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LabelAsc = 'LABEL_ASC',
  LabelDesc = 'LABEL_DESC',
  RegionAsc = 'REGION_ASC',
  RegionDesc = 'REGION_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type SuspendMove = {
  __typename?: 'SuspendMove';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type SuspendMoveInput = {
  comments?: InputMaybe<Scalars['String']>;
  moveId: Scalars['Int'];
};

export type Trailer = TrailerNode & {
  __typename?: 'Trailer';
  cargoCapacity?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  jobRequest?: Maybe<JobRequestConnection>;
  move?: Maybe<MoveConnection>;
  onjourneyTruckId?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['String']>;
  trailerReg?: Maybe<Scalars['String']>;
  trailerTypeId?: Maybe<Scalars['Int']>;
  trailerTypeRec?: Maybe<TrailerType>;
  transporterId?: Maybe<Scalars['Int']>;
  truckId?: Maybe<Scalars['Int']>;
};


export type TrailerJobRequestArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type TrailerMoveArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type TrailerConnection = {
  __typename?: 'TrailerConnection';
  edges: Array<Maybe<TrailerEdge>>;
  pageInfo: PageInfo;
};

export type TrailerEdge = {
  __typename?: 'TrailerEdge';
  cursor: Scalars['String'];
  node?: Maybe<Trailer>;
};

export type TrailerNode = {
  id: Scalars['ID'];
};

export type TrailerType = TrailerTypeNode & {
  __typename?: 'TrailerType';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  trailers?: Maybe<TrailerConnection>;
};


export type TrailerTypeTrailersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type TrailerTypeNode = {
  id: Scalars['ID'];
};

export enum Type {
  Boolean = 'BOOLEAN',
  Datetime = 'DATETIME',
  Float = 'FLOAT',
  Integer = 'INTEGER',
  String = 'STRING'
}

export type UnMatchJobRequest = {
  __typename?: 'UnMatchJobRequest';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type UnMatchJobRequestInput = {
  comments?: InputMaybe<Scalars['String']>;
  moveId: Scalars['Int'];
  nextStage?: InputMaybe<NextStage>;
  unmatchedBy?: InputMaybe<Scalars['String']>;
};

export type UpdateBidStatus = {
  __typename?: 'UpdateBidStatus';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateInbound = {
  __typename?: 'UpdateInbound';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateJobRequest = {
  __typename?: 'UpdateJobRequest';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateJobRequestInput = {
  alternativeTransporterRate?: InputMaybe<Scalars['Float']>;
  assignedTo?: InputMaybe<Scalars['String']>;
  bidRate?: InputMaybe<Scalars['Float']>;
  comments?: InputMaybe<Scalars['String']>;
  cont2grwght?: InputMaybe<Scalars['Float']>;
  containerGrossWeight?: InputMaybe<Scalars['Float']>;
  containerNumber?: InputMaybe<Scalars['String']>;
  containerNumber2?: InputMaybe<Scalars['String']>;
  driverIdNumber?: InputMaybe<Scalars['String']>;
  etaLoadingPoint?: InputMaybe<Scalars['DateTime']>;
  isBid?: InputMaybe<Scalars['Boolean']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  preferredLoadingPoint?: InputMaybe<Scalars['String']>;
  preferredLoadingPointId?: InputMaybe<Scalars['Int']>;
  rate?: InputMaybe<Scalars['Float']>;
  rateType?: InputMaybe<RateType>;
  trailerReg?: InputMaybe<Scalars['String']>;
  trailerTypeId?: InputMaybe<Scalars['Int']>;
  transporterCurrency?: InputMaybe<Scalars['String']>;
  truckReg?: InputMaybe<Scalars['String']>;
  weightToLoad?: InputMaybe<Scalars['String']>;
};

export type UpdateMove = {
  __typename?: 'UpdateMove';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateMoveInput = {
  containerGrossWeight?: InputMaybe<Scalars['Float']>;
  containerNumber?: InputMaybe<Scalars['String']>;
  containerNumber2?: InputMaybe<Scalars['String']>;
  unitLoaded?: InputMaybe<Scalars['Float']>;
};

export type UpdateSystemSetting = {
  __typename?: 'UpdateSystemSetting';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateSystemSettingInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  label?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Region>;
  type?: InputMaybe<Type>;
};

export type CreateTruckSupplyLiaSupplyMutationVariables = Exact<{
  assetList: Array<Maybe<AssetAvailabilityInput>> | Maybe<AssetAvailabilityInput>;
  createdBy: Scalars['String'];
  source: Source;
  transporterId: Scalars['Int'];
}>;


export type CreateTruckSupplyLiaSupplyMutation = { __typename?: 'RootMutation', createTruckSupply?: { __typename?: 'CreateTruckSupply', message?: string | null | undefined, status?: number | null | undefined, rowCount?: number | null | undefined, failureCount?: number | null | undefined } | null | undefined };


export const CreateTruckSupplyLiaSupplyDocument = gql`
    mutation createTruckSupplyLiaSupply($assetList: [AssetAvailabilityInput]!, $createdBy: String!, $source: Source!, $transporterId: Int!) {
  createTruckSupply(assetList: $assetList, createdBy: $createdBy, source: $source, transporterId: $transporterId) {
    message
    status
    rowCount
    failureCount
  }
}
    `;
export type CreateTruckSupplyLiaSupplyMutationFn = Apollo.MutationFunction<CreateTruckSupplyLiaSupplyMutation, CreateTruckSupplyLiaSupplyMutationVariables>;

/**
 * __useCreateTruckSupplyLiaSupplyMutation__
 *
 * To run a mutation, you first call `useCreateTruckSupplyLiaSupplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTruckSupplyLiaSupplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTruckSupplyLiaSupplyMutation, { data, loading, error }] = useCreateTruckSupplyLiaSupplyMutation({
 *   variables: {
 *      assetList: // value for 'assetList'
 *      createdBy: // value for 'createdBy'
 *      source: // value for 'source'
 *      transporterId: // value for 'transporterId'
 *   },
 * });
 */
export function useCreateTruckSupplyLiaSupplyMutation(baseOptions?: Apollo.MutationHookOptions<CreateTruckSupplyLiaSupplyMutation, CreateTruckSupplyLiaSupplyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTruckSupplyLiaSupplyMutation, CreateTruckSupplyLiaSupplyMutationVariables>(CreateTruckSupplyLiaSupplyDocument, options);
      }
export type CreateTruckSupplyLiaSupplyMutationHookResult = ReturnType<typeof useCreateTruckSupplyLiaSupplyMutation>;
export type CreateTruckSupplyLiaSupplyMutationResult = Apollo.MutationResult<CreateTruckSupplyLiaSupplyMutation>;
export type CreateTruckSupplyLiaSupplyMutationOptions = Apollo.BaseMutationOptions<CreateTruckSupplyLiaSupplyMutation, CreateTruckSupplyLiaSupplyMutationVariables>;
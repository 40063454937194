import { IClient, IAuthClient } from 'models';
import { AppEnum } from 'generate/types';
const {
  REACT_APP_LTA_SERIVICE,
} = process.env;

// @ts-ignore
const LTAClient: IClient = {
	url: `${REACT_APP_LTA_SERIVICE || ''}/lta-backend/api/graphql`,
};

// @ts-ignore
const LTAAuthClient: IAuthClient = {
	url: `${REACT_APP_LTA_SERIVICE || ''}/lta-backend/api/auth`,
	contextName: 'use-auth-endpoint',
};

const LTAAdminClient: IClient = {
	url: `${REACT_APP_LTA_SERIVICE || ''}/lta-backend/api/admin`,
	appName: AppEnum.Lta,
};

const LIAClient: IClient = {
	url: `${REACT_APP_LTA_SERIVICE || ''}/lia-backend/api/graphql`,
	appName: AppEnum.Lia,
};

const LIADemandClient: IClient = {
	url: `${REACT_APP_LTA_SERIVICE || ''}/lia-backend/api/graphql/`,
	appName: AppEnum.Lia,
};

const LIASupplyService: IClient = {
	url: `${REACT_APP_LTA_SERIVICE || ''}/lia-supply/api/graphql`,
	appName: AppEnum.Lia,
};

const LIALTAClient: IClient = {
	url: `${REACT_APP_LTA_SERIVICE || ''}/lta`,
	appName: AppEnum.Lta,
};

const LFAClient: IClient = {
	url: `${REACT_APP_LTA_SERIVICE || ''}/api/graphql`,
	appName: AppEnum.Lfa,
};

const NOTIFICATIONClient: IClient = {
	url: `${REACT_APP_LTA_SERIVICE || ''}/lia-notification/api/graphql`,
	appName: AppEnum.Lta,
};

const SupplyServiceClient: IClient = {
	url: `${REACT_APP_LTA_SERIVICE || ''}/assets-backend/api/graphql`,
	appName: AppEnum.Assets,
};

const DMSClient: IClient = {
	url: `${REACT_APP_LTA_SERIVICE || ''}/dms/api/graphql`,
	appName: AppEnum.Lia,
};

export const API = {
	// LTA
	LTAClient,

  // LIA Demand
  LIADemandClient,

	// LTAAuthClient
	LTAAuthClient,

	// LTAAdminClient
	LTAAdminClient,

	// LIA
	LIAClient,

	// LIALTA
	LIALTAClient,

	// LFA
	LFAClient,

	// NOTIFICATION
	NOTIFICATIONClient,

	SupplyServiceClient,

  // LIA -supply
  LIASupplyService,

  // DMS
  DMSClient
};

export const authContext = {
	clientName: API.LTAAuthClient.contextName,
};

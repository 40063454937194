import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import cn from 'classnames';
import { useStyles } from './FilterButton.styles';
import FilterListIcon from '@material-ui/icons/FilterList';

export const FilterButton: React.FC<ButtonProps> = ({
	className,
	children,
	variant = 'outlined',
	color = 'default',
	...props
}) => {
	const classes = useStyles();

	return (
		<Button className={cn(classes.root)} variant="outlined" {...props} data-qa="qa-filter-button">
			<FilterListIcon />
		</Button>
	);
};

import { gql } from '@apollo/client';

export const confirmedJobDetails = gql`
	fragment confirmedJobDetails on Move {
		origin
		destination

		readyToLoad
		etaLoadingPoint
		moveId
		trailerType
			truckType
	    ${/* category */ ''}
	    cargoType
		commodity
		weight
		readyToLoad
		truckReg
	    ${/* loadingDeadline */ ''}

		lowerBidMargin
		upperBidMargin

		currency
		
		phoneNumber
	}
`;

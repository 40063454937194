import { Box, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { ResetPasswordMutationVariables } from 'generate/types';
import { formatCamelToSnakeCase, getRequiredMessage, mapFieldsToInitialValues } from 'helpers';
import { CustomTextField, CustomPasswordField } from 'containers';
import { FormIdList, ResetPasswordFomNames } from 'models';
import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { CustomButton } from 'components/button';
import { useStyles } from './ExternalForm.styles';
import { useReadUserInfo } from 'hooks';
import { useMixpanel } from 'analytics';

interface ContactFormProps {
	onSubmit: (d: ResetPasswordMutationVariables) => void;
	loading: boolean;
}

const fields = [
	ResetPasswordFomNames.email,
	ResetPasswordFomNames.currentPassword,
	ResetPasswordFomNames.newPassword,
	ResetPasswordFomNames.confirmNewPassword,
];

export const ResetPasswordForm = ({ onSubmit, loading }: ContactFormProps) => {
	const { t } = useTranslation('form-fields');
	const { t: tE } = useTranslation('errors');
	const { t: tB } = useTranslation('buttons');
	const { t: tL } = useTranslation('login');
	const { email } = useReadUserInfo();
	const classes = useStyles();
	const mixpanel = useMixpanel();

	// ---------------------------- Mixpanel -----------------------------------//
	useEffect(() => {
		mixpanel.track('Reset password', { message: 'Reset password renders successfully ' });
	}, [mixpanel]);

	const initialValues = mapFieldsToInitialValues(fields, { email, currentPassword: '' });

	const validationSchema = useMemo(
		() =>
			Yup.object({
				...fields.reduce(
					(res, name) => ({
						...res,
						[name]: Yup.string().required(
							getRequiredMessage(tE, t(`reset_password.${formatCamelToSnakeCase(name)}`))
						),
					}),
					{}
				),
				[ResetPasswordFomNames.confirmNewPassword]: Yup.string()
					.required(
						getRequiredMessage(
							tE,
							t(
								`reset_password.${formatCamelToSnakeCase(ResetPasswordFomNames.confirmNewPassword)}`
							)
						)
					)
					.oneOf([Yup.ref(ResetPasswordFomNames.newPassword)], 'Passwords must match'),
			}),
		[tE, t]
	);

	return (
		<main className={classes.main}>
			<div className={classes.form}>
				<Typography variant="h6" component="h6" paragraph>
					{tL('reset_password')}
				</Typography>
				<Formik
					initialValues={initialValues as ResetPasswordMutationVariables}
					onSubmit={onSubmit}
					validationSchema={validationSchema}
					enableReinitialize
				>
					<Form id={FormIdList.resetPassword}>
						<CustomTextField
							name={ResetPasswordFomNames.email}
							label={t(`reset_password.${formatCamelToSnakeCase(ResetPasswordFomNames.email)}`)}
							type="email"
							autoComplete="off"
							readOnly
						/>
						<CustomPasswordField
							name={ResetPasswordFomNames.currentPassword}
							label={t(
								`reset_password.${formatCamelToSnakeCase(ResetPasswordFomNames.currentPassword)}`
							)}
							autoComplete="off"
						/>
						<CustomPasswordField
							name={ResetPasswordFomNames.newPassword}
							label={t(
								`reset_password.${formatCamelToSnakeCase(ResetPasswordFomNames.newPassword)}`
							)}
							autoComplete="off"
						/>
						<CustomPasswordField
							name={ResetPasswordFomNames.confirmNewPassword}
							label={t(
								`reset_password.${formatCamelToSnakeCase(ResetPasswordFomNames.confirmNewPassword)}`
							)}
							autoComplete="off"
						/>
						<Box display="flex" marginY={2} justifyContent="center">
							<CustomButton color="primary" autoWidth={true} type="submit" disabled={loading} className="capitalizeText">
								{tB('change_password')}
							</CustomButton>
						</Box>
					</Form>
				</Formik>
			</div>
		</main>
	);
};

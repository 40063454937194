import { MoveListContainer } from 'containers/move-list-container';
import { MoveCard } from 'components';
import { JobCategory } from 'generate/types';
import { getPathWithId } from 'helpers';
import { WithJobFilterProps } from 'models';
import { AppRouteNameList, AppRoutePathList } from 'router';
import { useHistory, useLocation } from 'react-router-dom';
import { useMixpanel } from 'analytics';

export function MyJobListCompleted(props: WithJobFilterProps) {
	const history = useHistory();
	const mixpanel = useMixpanel();
	const { pathname } = useLocation();

	const jobType = pathname?.split('/')?.reverse()?.join(' ')?.trim() || 'Job type';

	const handleOpenModal = (moveId: any) => {
		const nextPage = `${window.location.pathname}${
			AppRoutePathList[AppRouteNameList.availableDetails]
		}`;
		mixpanel.track(`User moves to “${nextPage?.replace(/\//g, ' ')}” page`, {
			message: `${jobType} page renders successfully`,
			ID: moveId,
			pathname,
		});
		history.push(getPathWithId(nextPage, moveId));
	};
	return (
		<MoveListContainer
			ItemTemplate={(props) => (
				<MoveCard {...props} jobCategory={JobCategory.Completed} handleClick={handleOpenModal} />
			)}
			jobCategory={JobCategory.Completed}
			{...props}
		/>
	);
}

interface Edge<T> {
	node?: T | null;
}

interface EdgeItem<T> extends Edge<T> {}

interface Edges<T> extends Array<EdgeItem<T> | null> {}

export function getPaginatedDataNodeList<
	K,
	T extends {
		edges?: Edges<K>;
	}
>(d?: Partial<T> | null): K[] {
	if (d && d.edges) {
		return (d.edges?.map((el) => el?.node).filter(Boolean) as K[]) || [];
	}
	return [];
}

import { ApolloClient, NormalizedCacheObject} from '@apollo/client';

import { API } from 'utils';

import { cache } from '../cache';
import { errorLink } from '../links/errorLink';

const { createUploadLink } = require("apollo-upload-client");

const httpLink = createUploadLink({
	uri: `${API.LIAClient.url}`,
});

const liaClientLink = errorLink().concat(httpLink);

export const liaClient = new ApolloClient<NormalizedCacheObject>({
	link: liaClientLink,
	cache,
	queryDeduplication: false,
	connectToDevTools: true,
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'cache-and-network',
		},
		query: {
			fetchPolicy: 'network-only',
		},
	},
});

import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from '../popover';
import { FilterButton } from 'components/filter-button';
import { CargoType } from 'generate/types';
import { ISelectOption, ISelectOptionList } from 'containers';
import Typography from '@material-ui/core/Typography';

interface FilterCargoTypeButtonProps {
	title: string;
	currentType?: CargoType;
	onChange: (type: CargoType) => void;
	handleClearFilters: () => void;
}

export const FilterCargoTypeButton = ({
	title,
	currentType,
	onChange,
	handleClearFilters,
}: FilterCargoTypeButtonProps) => {
	const [selected, setSelected] = useState<string | number>();
	const { t } = useTranslation('common');

	const cargoList: ISelectOptionList = useMemo(
		() =>
			Object.keys(CargoType).map(
				(key): ISelectOption => ({
					title: key,
					value: CargoType[key as keyof typeof CargoType],
				})
			),
		[]
	);

	const renderItem = useCallback(
		({ title }: ISelectOption) => <Typography>{t(title)}</Typography>,
		[t]
	);

	const handleSelect = useCallback(
		(item: ISelectOption['value']) => onChange(item as CargoType),
		[onChange]
	);

	const clearFilters = () => {
		handleClearFilters();
		setSelected('');
	};

	useEffect(() => {
		setSelected(currentType);
	}, [currentType]);

	return (
		<Popover
			ControlElement={(props) => (
				<FilterButton aria-controls="cargo-type-menu" aria-haspopup="true" disableRipple {...props} data-qa={`qa-${title}`}>
					{title}
				</FilterButton>
			)}
			itemList={cargoList}
			renderItem={renderItem}
			onSelectItem={handleSelect}
			handleClearFilters={clearFilters}
			selected={selected}
			id="cargo-type-menu"
		/>
	);
};

import { gql } from '@apollo/client';

export const getWorkOrder = gql`
	query getWorkOrder(
    $isBid: Boolean
    $bidPrice: Float
    $jobCategory: JobCategory!
    $lroId: String
    $moveId: Int
    $weight: Float
  ) {
    workOrder(
      isBid: $isBid
      bidPrice: $bidPrice
      jobCategory: $jobCategory
      lroId: $lroId
      moveId: $moveId
      weight: $weight
    ) {
      id
      lroId
      cargoType
      commodity
      consignmentMetricTons
      createdOn
      currency
      deadlineToLift
      origin
      driverIdNumber
      etaLoadingPoint
      destination
      trucksNeeded
      totalRequested
      trucksShortlisted
      trucksMatched
      transporterRateType
      transporterPaymentTerms
      maximumFuelAmount
      totalLiters
      lowerBidMargin
      upperBidMargin
      marketRate
      phoneNumber
      trailerType
      trailerTypes
      truckType
      isBid
      bidPrice
      packagingType
      weightToLoad
      weight
      readyToLoad
      lastKnownLocation
      trailerNumber
      truckReg
      moveId
      truckList {
        truckReg
        trailerReg
        driverId
        driverIdNumber
        driverName
        etaLoadingPoint
        createdOnLia
        jobRequestId
      }
    }
	}
`;

import { gql } from '@apollo/client';

export const getAccountDetails = gql`
	query getAccountDetails {
		account {
			bankName
			accountName
			accountNumber
			accountType
			bankCode
			branchName
			swiftCode
			bankAddress
			beneficiaryName
			beneficiaryAddress
			currency
		}
	}
`;

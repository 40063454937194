import React, { useEffect } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { useLocation } from 'react-router-dom';
import { useMixpanel } from 'analytics';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { Order } from 'generate/types';
import { useStyles } from './AvailableJobsModal.styles';
import { NewAvailableJobsModal } from './NewAvailableJob';
import { SubmittedAvailableJobsModal } from './SubmittedAvailableJobsModal';
import { MatchedAvailableJobs } from './MatchedAvailableJobs';

interface AvailableJobsModalProps {
	setOpen: (open: boolean) => void;
	open: boolean;
	item?: Order;
	onSuggestBtnClicked: (lroId: string, weight: string) => void;
	onAcceptBtnClicked: (lroId: string, weight: string) => void;
	onEditWorkOrder: (lroId: string) => void;
}
const NEW_AVAILABLE_JOBS = '/available/new';
const SUBMITTED_AVAILABLE_JOBS = '/available/submitted';

export function AvailableJobsModal({
	setOpen,
	open,
	item,
	onSuggestBtnClicked,
	onAcceptBtnClicked,
	onEditWorkOrder,
}: AvailableJobsModalProps) {
	const { pathname } = useLocation();
	const classes = useStyles();
	const mixpanel = useMixpanel();
	const jobType = pathname?.split('/')?.reverse()?.join(' ')?.trim() || 'Job type';

	useEffect(() => {
		if (open) {
			mixpanel.track(`Order clicked (${jobType} tab)`, {
				message: 'Modal opens successfully',
				pathname,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mixpanel, open]);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			className={classes.modal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
				className: classes.backdrop,
			}}
		>
			<Fade in={open}>
				<div>
					{pathname === NEW_AVAILABLE_JOBS ? (
						<NewAvailableJobsModal
							item={item}
							onSuggestBtnClicked={onSuggestBtnClicked}
							onAcceptBtnClicked={onAcceptBtnClicked}
						/>
					) : (
						<>
							{pathname === SUBMITTED_AVAILABLE_JOBS ? (
								<SubmittedAvailableJobsModal item={item} />
							) : (
								<MatchedAvailableJobs item={item} onEditWorkOrder={onEditWorkOrder} />
							)}
						</>
					)}
				</div>
			</Fade>
		</Modal>
	);
}

import React from 'react';
import cn from 'classnames';
import { IIconProps } from './IIconProps';

export const CloseIcon = ({ className, title = '', pointer = true, ...props }: IIconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="Close-icon"
			viewBox="0 0 32 32"
			className={cn(className, 'icon-bordered', {
				pointer: pointer,
			})}
			{...props}
		>
			<title>{title}</title>
			<path d="M25.313 8.563l-7.438 7.438 7.438 7.438-1.875 1.875-7.438-7.438-7.438 7.438-1.875-1.875 7.438-7.438-7.438-7.438 1.875-1.875 7.438 7.438 7.438-7.438z" />
		</svg>
	);
};

import { gql } from '@apollo/client';

export const fuelStationDetails = gql`
	fragment fuelStationDetails on FuelStation {
		id
		name
		code
		ownerId
		ownerName
		country
		email
		city
		phone
		geoId
	}
`;

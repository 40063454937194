import { makeStyles } from '@material-ui/core';
import { COLORS, theme } from 'utils';

const drawerCloseWidth = 49;
const drawerWidth = 241;

export const useStyles = makeStyles({
	drawer: {
		flexShrink: 0,
		whiteSpace: 'nowrap',
		backgroundColor: COLORS.primary,
		willChange: 'width',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.easeIn,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		width: drawerCloseWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.easeIn,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
	},
	toggleIcon: {
		marginLeft: 'auto',
		color: '#FFF',
	},
	adminSidebar: {
		'& .MuiListItemIcon-root': {
			minWidth: 33,
		},
	},
	logo: {
		fill: '#FFF',
		margin: theme.spacing(2),
		flexShrink: 0,
	},
	logoSmall: {
		fill: '#FFF',
		margin: theme.spacing(1),
	},
	listItem: {
		fontSize: 18,
		color: '#FFF',
		'& span': {
			color: '#FFF',
		},
		'& .MuiTypography-root': {
			fontWeight: 'bold',
		},
	},
	listItemLink: {
		color: '#FFF',
		'& span': {
			color: '#FFF',
		},
	},
	listItemActive: {
		backgroundColor: COLORS.secondary,
		'&:hover': {
			backgroundColor: COLORS.secondary,
		},
	},
});

import React from 'react';
import {
	Container,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Title,
	FormControlLabel,
	Checkbox,
	PageTitle,
} from './Notifications.styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { useGetNotificationsQuery, useMarkAsReadMutation } from 'generate/typesNotification';
import { notificationClient } from 'graphqlapi/clients/notificationClient';
import { Loader } from 'components';

export const Notifications = () => {
	const [expanded, setExpanded] = React.useState<Number>(-1);

	const handleChange = (panel: Number) => (_event: any, newExpanded: Boolean) => {
		setExpanded(newExpanded ? panel : -1);
	};

	const [markAsReadMutation, { loading: loadingToMarkAsRead }] = useMarkAsReadMutation({
		client: notificationClient,
	});

	const {
		data,
		loading: loadingNotifications,
		refetch: refetchNotifications,
	} = useGetNotificationsQuery({
		client: notificationClient,
		variables: {
			source: 'LTA',
		},
	});

	const handleMarkAsRead = (notificationId: string | undefined) => {
		if (notificationId)
			markAsReadMutation({
				variables: {
					notificationId: notificationId,
				},
			}).then(() => {
				refetchNotifications();
			});
	};
	return (
		<Container maxWidth="lg">
			<PageTitle>Notifications</PageTitle>
			<Loader isLoading={loadingNotifications || loadingToMarkAsRead}>
				{data && data.notifications && (
					<Container maxWidth="lg">
						{data.notifications.map((notification, index) => (
							<Accordion square expanded={expanded === index} onChange={handleChange(index)}>
								<AccordionSummary
									aria-controls="panel1d-content"
									id="panel1d-header"
									expandIcon={<ExpandMoreIcon />}
								>
									<Title>{notification?.title}</Title>
									<FormControlLabel
										control={<Checkbox name="checkedA" />}
										label="Mark as read"
										onClick={(event) => handleMarkAsRead(notification?.id)}
										onFocus={(event) => event.stopPropagation()}
									/>
								</AccordionSummary>
								<AccordionDetails>
									<ReactMarkdown
										children={notification?.message || ''}
										rehypePlugins={[rehypeRaw]}
										remarkPlugins={[remarkGfm]}
									/>
								</AccordionDetails>
							</Accordion>
						))}
					</Container>
				)}
			</Loader>
		</Container>
	);
};

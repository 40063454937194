import { IAppPathList, IAppRoute, IRoute } from 'models';

export function createAppRouteList(
	{ nested, name, requiredRoles }: IRoute,
	pathList: IAppPathList,
	basePath: string = ''
): IAppRoute[] {
	const path = basePath + pathList[name];
	const nestedPathList: IAppRoute[] = [];

	if (nested?.length) {
		nested.forEach((nestedRoute) =>
			nestedPathList.push(...createAppRouteList(nestedRoute, pathList, path))
		);
	}

	return [
		{
			path: path || '/',
			name,
			requiredRoles,
		},
		...nestedPathList,
	];
}

import { RegionType } from 'models';

export const COUNTRIES_ABBR = {
	SouthAfrica: 'za',
	Nigeria: 'NG',
	Kenya: 'KE',
	Uganda: 'UG',
	Tazania: 'TZ',
};

export const COUNTRIES_MAP_TO_REGION = {
	[RegionType.SA]: COUNTRIES_ABBR.SouthAfrica,
	[RegionType.EA]: COUNTRIES_ABBR.Kenya,
	[RegionType.WA]: COUNTRIES_ABBR.Nigeria,
	[RegionType.TZ]: COUNTRIES_ABBR.Tazania,
};

export const PREFERRED_COUNTRIES = [
	COUNTRIES_ABBR.Kenya,
	COUNTRIES_ABBR.Nigeria,
	COUNTRIES_ABBR.Uganda,
	COUNTRIES_ABBR.SouthAfrica,
	COUNTRIES_ABBR.Tazania,
];

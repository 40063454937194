import React from 'react';
import { ModalWrapper } from '../ModalWrapper';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

interface ProcessingModalProps {
  onClose: () => void;
}

export const ProcessingModal = ({ onClose }: ProcessingModalProps) => {
  const { t: tM } = useTranslation('modals');

  return (
    <ModalWrapper open={true} onClose={onClose}>
      <>
        <Typography>{tM('account_verification_title')}</Typography>
        <Typography>{tM('account_verification_message')}</Typography>
      </>
    </ModalWrapper>
  );
};

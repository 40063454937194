import { useMemo } from 'react';
import { AddDriver as AddDriverTemp } from 'components';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { AddDriverFomNames } from 'models';
import { formatCamelToSnakeCase, getRequiredMessage } from 'helpers';
import { REGULARS } from 'utils';
import { CreateDriverInput } from 'generate/typesSupplyService';
interface AddDriverProps {
	onSubmit: (data: CreateDriverInput) => void;
}

export const AddDriver = ({ onSubmit }: AddDriverProps) => {
	const { t } = useTranslation('form-fields');
	const { t: tE } = useTranslation('errors');

	const validationSchema = useMemo(
		() =>
			Yup.object({
				...Object.values(AddDriverFomNames).reduce(
					(res, name) => ({
						...res,
						[name]: Yup.string().required(
							getRequiredMessage(tE, t(`add_asset.${formatCamelToSnakeCase(name)}`))
						),
					}),
					{}
				),
				[AddDriverFomNames.driverPhone]: Yup.string()
					.matches(REGULARS.phoneNumber, tE('invalid_value'))
					.required(getRequiredMessage(tE, t('sign_up.company_phone'))),
			}),
		[tE, t]
	);

	const initialValues = useMemo(
		() => ({
			[AddDriverFomNames.driverPhone]: '',
			[AddDriverFomNames.idNumber]: '',
			[AddDriverFomNames.name]: '',
		}),
		[]
	);

	return (
		// @ts-ignore
		<Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
			<AddDriverTemp />
		</Formik>
	);
};

import { RadioGroupProps } from '@material-ui/core';
import { Field } from 'formik';
import React from 'react';
import { CustomRadio, RadioOptionList } from 'components';

interface CustomRadioFieldProps extends RadioGroupProps {
	options: RadioOptionList;
}

export const CustomRadioField = ({ options, name = '', ...props }: CustomRadioFieldProps) => {
	return <Field name={name} options={options} component={CustomRadio} {...props} />;
};

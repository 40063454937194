import { useEffect, useMemo } from 'react';
import { useReadUserInfo } from './useReadUserInfo';
import {
	Truck,
	TruckConnection,
	DriverConnection,
	Driver,
	useGetSupplyTrucksLazyQuery,
	useGetSupplyDriversLazyQuery,
  RegionEnum,
  LinkEnum,
  ActiveEnum,
  MatchEnum,
} from 'generate/typesSupplyService';
import { getPaginatedDataNodeList } from '../helpers';
import { supplyServiceClient } from 'graphqlapi';

export function useGetAllAvailableAssets(truckSearch: string, driverSearch: string, region: RegionEnum ) {
	const { transporter: transname } = useReadUserInfo();

	const [getSupplyTrucks, { data: truckList, loading: isTruckLoading,  }] =
		useGetSupplyTrucksLazyQuery({
			client: supplyServiceClient,
			variables: {
				first: 3,
				search: truckSearch,
        region,
        status: MatchEnum.Unmatched,
        active: ActiveEnum.Active,
			},
			fetchPolicy: 'network-only',
		});

	const [getSupplyDrivers, { data: driverList, loading: isDriverLoading }] =
		useGetSupplyDriversLazyQuery({
			client: supplyServiceClient,
			variables: {
				first: 3,
				search: driverSearch,
        region,
        status: LinkEnum.Unlinked,
        active: ActiveEnum.Active,
			},
			fetchPolicy: 'network-only',
		});

	const isAssetsLoading = useMemo(
		() => isTruckLoading || isDriverLoading,
		[isTruckLoading, isDriverLoading]
	);

	useEffect(() => {
		if (transname) {
			getSupplyTrucks();
			getSupplyDrivers();
		}
	}, [transname, getSupplyTrucks, getSupplyDrivers]);

	return {
		// @ts-ignore
		allTruckList: getPaginatedDataNodeList<Truck, TruckConnection>(truckList?.trucks),
		// @ts-ignore
		allDriverList: getPaginatedDataNodeList<Driver, DriverConnection>(driverList?.drivers),
		isAssetsLoading,
		isTruckLoading,
		isDriverLoading,
	};
}

import React, { useCallback, useState } from 'react';
import { Field, FieldProps } from 'formik';
import { PhoneInput, PhoneInputProps } from 'components';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

interface PhoneInputFieldProps extends PhoneInputProps {
	label: string;
	onChangeFlag?: (code?: string) => void;
}

export function PhoneInputField({
	name,
	className,
	label,
	onChangeFlag,
	...props
}: PhoneInputFieldProps) {
	const [isPhoneValid, setIsPhoneValid] = useState(true);
	const { t: tE } = useTranslation('errors');

	const handlePhoneNumberChange = useCallback(
		(isValid: boolean, value: string, fullNumber: string) =>
			isValid ? fullNumber.replace(/\s|-/g, '') : value,
		[]
	);

	return (
		<>
			<Field name={name}>
				{({
					field: { value },
					form: { setFieldTouched, setFieldValue, setFieldError },
					meta,
				}: FieldProps) => {
					if (!isPhoneValid && !meta.error) {
						setFieldError(name, 'Invalid number');
					}

          return (
						<div className="form-field">
							<PhoneInput
								{...props}
								className={className}
								inputClassName={cn({
									invalid: (meta.touched && meta.error) || !isPhoneValid,
								})}
								formatOnInit
								defaultValue={value}
								name={name}
								placeholder={label}
								onPhoneNumberBlur={(isValid) => {
									setFieldTouched(name, true);
									setIsPhoneValid(isValid);
								}}
								onPhoneNumberChange={(isValid, value, country, fullNumber) => {
									setIsPhoneValid(isValid);
									setFieldValue(name, handlePhoneNumberChange(isValid, value, fullNumber));
								}}
								onSelectFlag={(value, country, fullNumber, isValid) => {
									onChangeFlag && onChangeFlag(country.dialCode);
									setFieldValue(name, handlePhoneNumberChange(isValid, value, fullNumber));
								}}
							/>
							{meta.touched && (meta.error || !isPhoneValid) && (
								<Typography className="error-text">{meta.error || tE('invalid_value')}</Typography>
							)}
						</div>
					);
				}}
			</Field>
		</>
	);
}

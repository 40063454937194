import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
	{
		root: {
			backgroundColor: '#F9F9F9',
			border: '1px solid #263238',
			borderColor: '#7FC0B5',
			color: '#7FC0B5',
			height: 40,
			marginLeft: 5,
			padding: '5px 8px',
			width: 24,
		},
	},
	{ name: 'FilterButton' }
);

import React from 'react';
import { CustomCropper } from 'containers';
import {Box, Chip, Typography} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {ItemList, TermsModal} from 'components';
import { RecordItem, RecordList } from 'components/record-item';
import { CustomButton } from '../button';
import { useTranslation } from 'react-i18next';
import { SignOutIcon } from '../icons';
import {AppPathList} from "../../router";
import {Link} from "react-router-dom";
import {useStyles} from "./AccountTab.styles";
import cn from 'classnames';
import {useModalStateControls} from "../../hooks";
import messageSrc from 'assets/svg/message.svg';

export interface AccountTabProps {
	onUpdateAvatar: (d: File) => void;
	userName: string;
	isVerified: boolean;
	userAvatar?: string | null;
	infoList: RecordList;
	onSignOut: () => void;
	isLoading: boolean;
}

export const AccountTab = ({
	onUpdateAvatar,
	userName,
   	isVerified,
	infoList,
	onSignOut,
	userAvatar,
	isLoading,
}: AccountTabProps) => {
	const { t } = useTranslation('buttons');
	const { t : tC} = useTranslation('common');
	const classes = useStyles();
	const { isOpened, toggleModal } = useModalStateControls();

  return (
	  <Box display="flex" flexDirection="column" alignItems="start" alignContent="start" paddingBottom={4}>
		  <Box display="flex" flexDirection="row" alignItems="center" alignContent="start">
			  <CustomCropper onSubmit={onUpdateAvatar} src={userAvatar} isLoading={isLoading}/>
			  <Box display="flex" flexDirection="column" alignItems="start" alignContent="start">
				  <Typography  className={classes.title}>{userName}</Typography>
				  {isVerified && <Chip size="small" label="Verified"/>}
			  </Box>
		  </Box>
		  <Typography  className={classes.subTitle}>{tC('your_information')}</Typography>
		  <ItemList items={infoList} ItemTemplate={RecordItem}/>
		  <Link to={AppPathList.documents} className="profile-link max-width">
			  <Box display="flex" flexDirection="row" alignItems="center" alignContent="start"
				   justifyContent="space-between">
				  <Typography className="textBlack profile-link-text">{tC('documents_and_id')}</Typography>
				  <ArrowForwardIosIcon color="primary" className={classes.linkIcon}/>
			  </Box>
		  </Link>
		  <Link to={AppPathList.bankDetails} className="profile-link max-width">
			  <Box display="flex" flexDirection="row" alignItems="center" alignContent="start"
				   justifyContent="space-between">
				  <Typography className="textBlack profile-link-text">{tC('bank_details')}</Typography>
				  <ArrowForwardIosIcon color="primary" className={classes.linkIcon}/>
			  </Box>
		  </Link>
		  <Link to={AppPathList.assetManagement} className="profile-link max-width">
			  <Box display="flex" flexDirection="row" alignItems="center" alignContent="start"
				   justifyContent="space-between">
				  <Typography className="textBlack profile-link-text">{tC('asset_management')}</Typography>
				  <ArrowForwardIosIcon color="primary" className={classes.linkIcon}/>
			  </Box>
		  </Link>
		  <Link to={AppPathList.resetPassword} className="profile-link max-width">
			  <Box display="flex" flexDirection="row" alignItems="center" alignContent="start"
				   justifyContent="space-between">
				  <Typography className="textBlack profile-link-text">{tC('reset_password')}</Typography>
				  <ArrowForwardIosIcon color="primary" className={classes.linkIcon}/>
			  </Box>
		  </Link>
		  <Box width="100%" display="flex" flexDirection="row" alignItems="center" alignContent="center"
			   justifyContent="space-between">
			  <Typography className={cn(classes.subTitle)}>{tC('about_lori')}</Typography>
			  <a target="_blank" href="https://lorisystems.com/contact-us" className="profile-link"
				 rel="noreferrer">
				  <Box className={cn(classes.needHelpBox)} display="flex" flexDirection="row" alignItems="center" alignContent="start"
					   justifyContent="space-between">
					  <img src={messageSrc} alt="" id="messaging"/>
					  <Typography className={cn(classes.needHelpText)}>{tC('need_help')}</Typography>
				  </Box>
			  </a>
		  </Box>
		  <Box  className="profile-link max-width"  onClick={toggleModal} display="flex" flexDirection="row" alignItems="center" alignContent="start"
			   justifyContent="space-between">
			  <Typography className="textBlack profile-link-text">{tC('terms')}</Typography>
			  <ArrowForwardIosIcon color="primary" className={classes.linkIcon}/>
		  </Box>
		  <Box  className="profile-link max-width"  onClick={toggleModal} display="flex" flexDirection="row" alignItems="center" alignContent="start"
			   justifyContent="space-between">
			  <Typography className="textBlack profile-link-text">{tC('privacy_policy')}</Typography>
			  <ArrowForwardIosIcon color="primary" className={classes.linkIcon}/>
		  </Box>
		  <a target="_blank" href="https://lorisystems.com/contact-us" className="profile-link max-width" rel="noreferrer">
			  <Box display="flex" flexDirection="row" alignItems="center" alignContent="start"
				   justifyContent="space-between">
				  <Typography className="textBlack profile-link-text">{tC('have_a_question')}</Typography>
				  <ArrowForwardIosIcon color="primary" className={classes.linkIcon}/>
			  </Box>
		  </a>
		  <a target="_blank" href="https://lorisystems.com/contact-us" className="profile-link max-width" rel="noreferrer">
			  <Box display="flex" flexDirection="row" alignItems="center" alignContent="start"
				   justifyContent="space-between">
				  <Typography className="textBlack profile-link-text">{tC('contact_us')}</Typography>
				  <ArrowForwardIosIcon color="primary" className={classes.linkIcon}/>
			  </Box>
		  </a>
		  <CustomButton color="primary" variant="outlined" autoWidth={true} className="capitalizeText" startIcon={<SignOutIcon/>}
						onClick={onSignOut} data-qa="qa-sign-out">
			  {t('sign_out')}
		  </CustomButton>
		  <TermsModal isOpened={isOpened} onClose={toggleModal} onAccept={toggleModal}/>
	  </Box>
	);
};

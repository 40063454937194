import { makeStyles } from '@material-ui/core';

export const useTabStyles = makeStyles({
	root: {
		color: '#AEA8A8',
	},
	selected: {
		borderRadius: 21,
		backgroundColor: '#D6E3E9',
		borderBottom: 'none !important',
		color: '#000',
	},
});

export const useTabsStyles = makeStyles({
	indicator: {
		backgroundColor: 'transparent !important',
		displat: 'none  !important',
	},
	flexContainer: {},
});

export enum InsuranceFormNames {
	broker = 'broker',
	brokerContactName = 'brokerContactName',
	brokerTelNumber = 'brokerTelNumber',
	brokerEmail = 'brokerEmail',
	insuranceCompany = 'insuranceCompany',
	policyNumber = 'policyNumber',
	hasComprehensiveGitCover = 'hasComprehensiveGitCover',
	gitCover = 'gitCoverPerLoad',
	hasPublicLiabilityInsurance = 'hasPublicLiabilityInsurance',
}

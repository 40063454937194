import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreateCompanyMutation } from 'generate/typesAuth';
import { authContext, COMMON, REGULARS, COUNTRY_CODES } from 'utils';
import {
	getCountryCode,
	getMinLengthMessage,
	getRequiredMessage,
	mapFieldsToInitialValues,
	saveToSessionStorage,
} from 'helpers';
import * as Yup from 'yup';
import {
	CompanyData,
	CompanyInformationFields,
	CompanyInformationNames,
	FormStepProps,
	RegionType,
} from 'models';
import { CompanyInformationForm as CompanyInformationFormTemp } from 'components';
import { useTranslation } from 'react-i18next';
import { state } from 'state';
import { useLoaderContext, useResultContext } from 'hooks';
import { ApolloError } from '@apollo/client';

interface CompanyInformationFormProps extends FormStepProps {}

export const CompanyInformationForm = ({ afterSubmit }: CompanyInformationFormProps) => {
	const { t } = useTranslation('form-fields');
	const { t: tE } = useTranslation('errors');
	const { t: tM } = useTranslation('modals');

	const { openErrorResult } = useResultContext();
	const { setIsLoading } = useLoaderContext();

	const [isSA, setIsSA] = useState(true);

	const handleError = useCallback(
		(e?: ApolloError) => openErrorResult(e, { title: tM('oops') }),
		[openErrorResult, tM]
	);

	const [postNewCompany, { loading }] = useCreateCompanyMutation({
		context: authContext,
		onCompleted: ({ createCompany }) => {
			if (createCompany?.company) {
				const { region, id } = createCompany.company;
				const companyData: CompanyData = {
					companyId: id,
					region: region as RegionType,
				};
				saveToSessionStorage(COMMON.companyData, companyData);
				state.company = companyData;
				afterSubmit();
			}
		},
		onError: handleError,
	});

	const fields = useMemo(
		() => [
			CompanyInformationNames.companyEmail,
			CompanyInformationNames.companyName,
			CompanyInformationNames.companyMobile,
			CompanyInformationNames.fleet,
		],
		[]
	);

	const initialValues = useMemo(() => mapFieldsToInitialValues(fields), [fields]);

	const validationSchema = useMemo(() => {
		const fleetTypeValidation = isSA
			? {}
			: {
					[CompanyInformationNames.fleet]: Yup.string().required(
						getRequiredMessage(tE, t('sign_up.fleet_type'))
					),
			  };

		return Yup.object({
			...{
				[CompanyInformationNames.companyName]: Yup.string()
					.min(2, getMinLengthMessage(tE))
					.required(getRequiredMessage(tE, t('sign_up.company_name'))),
				[CompanyInformationNames.companyEmail]: Yup.string()
					.email()
					.required(getRequiredMessage(tE, t('sign_up.company_email'))),
				[CompanyInformationNames.companyMobile]: Yup.string()
					.matches(REGULARS.phoneNumber, tE('invalid_value'))
					.required(getRequiredMessage(tE, t('sign_up.company_phone'))),
			},
			...fleetTypeValidation,
		});
	}, [t, tE, isSA]);

	const onSubmit = useCallback(
		(variables: CompanyInformationFields) => {
			let countryCode = getCountryCode(variables[CompanyInformationNames.companyMobile])
			postNewCompany({
				variables: {
					...variables,
					countryCode: countryCode,
					fleet: isSA ? undefined : variables.fleet,
				},
			});
			saveToSessionStorage(COMMON.countryCode, countryCode);
		},
		[postNewCompany, isSA]
	);

	const handleChangeRegion = useCallback((dialCode?: string) => {
		setIsSA(dialCode ? COUNTRY_CODES.southAfrica.includes(+dialCode) : false);
	}, []);

	useEffect(() => {
		setIsLoading(loading);
	}, [setIsLoading, loading]);

	return (
		<CompanyInformationFormTemp
			onSubmit={onSubmit}
			validationSchema={validationSchema}
			initialValues={initialValues as CompanyInformationFields}
			onChangeRegion={handleChangeRegion}
			isSA={isSA}
		/>
	);
};

import { useCallback, useEffect, useState } from 'react';

export function useModalStateControls(initialState: boolean = false) {
	const [isOpened, setIsOpened] = useState(initialState);
	const [shouldToggle, setShouldToggle] = useState(false);

	const closeModal = useCallback(() => setIsOpened(false), []);
	const openModal = useCallback(() => setIsOpened(true), []);
	const toggleModal = useCallback(() => setShouldToggle(true), []);

	useEffect(() => {
		if (shouldToggle) {
			setIsOpened(!isOpened);
			setShouldToggle(false);
		}
	}, [shouldToggle, isOpened]);

	return {
		isOpened,
		closeModal,
		openModal,
		toggleModal,
	};
}

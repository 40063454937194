import React from 'react';
import { ErrorMessage, Field, FieldProps } from 'formik';
import { CustomInputProps } from 'components';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStyles } from './TruckCard.styles';

export interface ISelectOption {
	title: string;
	value: string | number;
}

export interface ISelectOptionList extends Array<ISelectOption> {}

interface CustomSelectFieldProps extends CustomInputProps {
	options?: ISelectOptionList | null;
	label: string;
	disabledValues?: ISelectOption['value'][];
	setSearchDriver: (value: string) => void;
}

export const CustomSelectField = ({
	name = '',
	options = [],
	disabled,
	disabledValues,
	label,
	setSearchDriver,
	...props
}: CustomSelectFieldProps) => {
	const classes = useStyles();
	const [value, setValue] = React.useState('');
	return (
		<Field name={name}>
			{({
				field,
				meta,
				form: { handleChange, values, setFieldValue, ...aforms },
				...args
			}: FieldProps) => {
				return (
					<Autocomplete
						{...props}
						{...field}
						name={name}
						id={name}
						style={{ marginTop: 20 }}
						fullWidth
						//@ts-ignore
						getOptionLabel={(option) => option.title}
						//@ts-ignore
						options={options}
						className={classes.selectInput}
						renderInput={(params) => (
							<TextField
								{...params}
								label={label}
								variant="outlined"
								name={name}
								helperText={<ErrorMessage name={name} />}
								value={value}
								onChange={(evt) => {
									setValue(evt.target.value);
									setSearchDriver(evt.target.value || '');
								}}
							/>
						)}
						onChange={(e, driver) => {
							setFieldValue(name, driver);
						}}
					/>
				);
			}}
		</Field>
	);
};

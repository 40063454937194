import { Fleet } from "generate/typesAuth";

export enum CompanyInformationNames {
	companyName = 'companyName',
	companyEmail = 'companyEmail',
	companyMobile = 'companyMobile',
	fleet = 'fleet',
}

export interface CompanyInformationFields extends Record<CompanyInformationNames, Fleet | string | number> {
	[CompanyInformationNames.companyName]: string;
	[CompanyInformationNames.companyEmail]: string;
	[CompanyInformationNames.fleet]: Fleet;
	[CompanyInformationNames.companyMobile]: string;
}

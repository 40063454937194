import React from 'react';
import { Container } from '@material-ui/core';
import { useStyles } from './Header.styles';
import { NavLinkList } from 'components/nav-link-list';

interface HeaderProps {
	hideNavigation?: boolean;
}

export const Header = ({ hideNavigation = false }: HeaderProps) => {
	const classes = useStyles();

	return <Container className={classes.container}>{!hideNavigation && <NavLinkList />}</Container>;
};

import { client } from 'graphqlapi';
import { DocumentNode } from 'graphql';

export function readClient<T, V = {}>(query: DocumentNode, variables?: V) {
	return (
		client.readQuery<T>({
			query,
			variables,
		}) || ({} as T)
	);
}

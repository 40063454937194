import { makeStyles } from '@material-ui/core';
import { STYLES } from 'utils';

export const useStyles = makeStyles({
	root: {
		minWidth: 275,
		boxShadow: '0px 4px 7px rgb(0 0 0 / 6%)',
		border: '1px solid #F9F9F9',
		borderRadius: 5,
	},
	content: {
		padding: 20,
	},
	matchedCommodity: {
		display: 'flex',
		width: '100%',
		paddingLeft: 10,
		paddingRight: 10,
		paddingTop: 10,
		color: '#2E466E',
		fontSize: 16,
	},
	tripRates: {
		alignItems: 'center',
		display: 'flex',
		marginBottom: 23,
	},
	tripCities: {
		alignItems: 'center',
		display: 'flex',
		marginBottom: 15,
	},
	priceDetails: {
		color: '#7FC0B5',
		fontSize: 18,
		fontWeight: 800,
	},
	truckDistanceType: {
		alignItems: 'center',
		color: '#9B9B9B',
		display: 'flex',
		fontSize: 10,
		marginTop: 5,
	},
	packagingType: {
		alignItems: 'center',
		color: '#9B9B9B',
		display: 'flex',
		fontSize: 10,
		marginBottom: 13,
	},
	load: {
		alignItems: 'center',
		display: 'flex',
	},
	commodity: {
		color: '#2C3032',
		fontSize: 16,
		fontWeight: 400,
		minWidth: 250,
		textAlign: 'left',
	},
	truckCount: {
		color: '#9B9B9B',
		fontSize: 12,
		fontWeight: 600,
		letterSpacing: 0.4,
		textAlign: 'right',
		width: '100%',
	},
	truckIconContainer: {
		background: '#FFF3E5',
		borderRadius: 128,
		height: 33,
		marginRight: 5,
		width: 33,
	},
	truckIcon: {
		width: 21,
		height: 14,
	},
	lastKnownLocation: {
		padding: 4,
		cursor: 'pointer'
	},
	lastKnownLocationIcon: {
		background: 'rgba(127, 192, 181, 0.10)',
		padding: 6,
	},
	addLocationButton: {
		borderRadius: 8,
		border: '1px solid #7FC0B5',
		background: 'rgba(127, 192, 181, 0.10)',
		height: 40,
		marginTop: 8,
		fontSize: 14,
		textTransform: "capitalize"
	},
	addLocationIcon: {
		marginRight: 12,
	},
	moveArrowImg: {
		width: 29,
		marginLeft: 10,
		marginRight: 10,
	},
	departure: {
		alignItems: 'center',
		color: '#2C3032',
		display: 'flex',
		fontSize: 14,
		fontWeight: 300,
		letterSpacing: 0.4,
	},
	destination: {
		alignItems: 'center',
		color: '#2C3032',
		display: 'flex',
		fontSize: 14,
		fontWeight: 300,
		letterSpacing: 0.4,
	},
	bottomLeft: {
		marginTop: 5,
		textAlign: 'left',

		'& p': {
			color: '#666666',
			fontWeight: STYLES.bold,
		},
	},
	truck: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',

		'& svg': {
			fontSize: '1rem',
		},

		'& p': {
			marginLeft: 6,
			color: '#E31010',
			fontWeight: 600,
			fontSize: '1rem',
		},
	},
	trucktype: {
		marginBottom: 5,
		textAlign: 'right',
		textTransform: 'lowercase',
	},
	tripType: {
		alignItems: 'center',
		display: 'flex',
		width: '100%',
		marginBottom: 20,
	},
	tripTypeSwatches: {
		alignItems: 'center',
		background: 'rgba(0, 255, 87, 0.08)',
		borderRadius: 32,
		color: '#32C1B0',
		display: 'flex',
		fontSize: 10,
		fontWeight: 600,
		height: 23,
		justifyContent: 'center',
		marginRight: 10,
		width: 80,
	},
	price: {
		color: '#263238',

		'& p:first-child': {
			marginRight: 6,
			fontSize: 20,
		},

		'& p:last-child': {
			fontSize: 14,
		},
	},
	marketRate: {
		color: '#263238',
		display: 'flex',
		alignItems: 'flex-end',

		'& h6:first-child': {
			marginRight: 6,
			lineHeight: '1 !important',
		},

		'& p:last-child': {
			fontSize: 14,
		},
	},
	distance: {
		color: '#263238',
		textAlign: 'right',
		fontWeight: 'normal',
	},
	deadline: {
		color: '#666666',
		textAlign: 'left',

		'& p': {
			fontSize: 12,
		},
	},
	jobActions: {
		color: '#666666',
		textAlign: 'left',

		'& p': {
			fontSize: 12,
		},
	},
	weight: {
		color: '#000000',
		textAlign: 'left',
	},
	wrapperButton: {
		border: 'none',
		background: 'none',
		padding: 0,
	},
	btnAccept: {
		alignItems: 'center',
		background: '#7FC0B5',
		borderRadius: 5,
		color: '#FFFFFF',
		fontSize: 14,
		fontWeight: 500,
		gap: 10,
		height: 40,
		justifyContent: 'center',
		letterSpacing: 0.15,
		marginBottom: 20,
		marginLeft: 20,
		textTransform: 'capitalize',
		width: 126,

		'&:hover': {
			backgroundColor: '#7FC0B5',
		},
	},
	btnSuggestPrice: {
		alignItems: 'center',
		background: 'transparent',
		borderRadius: 5,
		color: '#7FC0B5',
		fontSize: 12,
		fontWeight: 500,
		gap: 10,
		height: 40,
		justifyContent: 'center',
		letterSpacing: 0.15,
		marginBottom: 20,
		marginLeft: 3,
		textTransform: 'initial',
		width: 134,
	},
	btnReview: {
		height: 40,
		fontSize: 14,
		fontWeight: 500,
		letterSpacing: 0.15,
		marginBottom: 20,
		textTransform: 'capitalize',
		'&:hover': {
			backgroundColor: '#7FC0B5',
		},
	},
});

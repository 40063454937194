import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { JobCategory, useGetMoveListQuery, Move, MoveCardDetailsFragment } from 'generate/types';
import { ItemListWithInfiniteScroll } from '../item-list-with-infinite-scroll';
import { NetworkStatus } from '@apollo/client';
import { JobFilter } from 'models';
import { getPaginatedDataNodeList } from 'helpers';
import { useReadUserInfo } from 'hooks';
import capitalize from 'lodash/capitalize';
import { useMixpanel } from 'analytics';

interface JobListContainerProps {
	filter: JobFilter;
	ItemTemplate: React.FC<MoveCardDetailsFragment>;
	keyName?: string;
	jobCategory: JobCategory;
}

export function MoveListContainer<T extends JobListContainerProps>({
	ItemTemplate,
	filter,
	jobCategory,
	keyName = 'moveId',
}: T) {
	const [after, setAfter] = useState('');
	const [hasNextPage, setHasNextPage] = useState<boolean>();
	const dataKey = useMemo(() => 'myjobList', []) as 'myjobList';
	const { isSA } = useReadUserInfo();
	const mixpanel = useMixpanel();

	const customFilters = useMemo(
		() => (isSA ? { searchStr: filter.searchStr } : filter),
		[filter, isSA]
	);

	const { data, loading, fetchMore, networkStatus, refetch, error } = useGetMoveListQuery({
		variables: {
			...customFilters,
			jobCategory,
		},
		onCompleted: (res) => {
			if (res && res[dataKey] && res[dataKey] !== null) {
				const { hasNextPage, endCursor } = res[dataKey]?.pageInfo || {};
				const jobsCount = res[dataKey]?.edges?.length || 0;
				setHasNextPage(hasNextPage);
				setAfter(endCursor || '');
				// @ts-ignore
				const tab = (capitalize(jobCategory) || 'My Jobs')?.replaceAll('_', ' ');
				mixpanel.track(`${tab} Jobs Tab Renders`, {
					jobsCount,
					message: `${tab} page renders successfully`,
					jobCategory,
					filter: customFilters,
				});
			}
		},
		notifyOnNetworkStatusChange: true,
	});

	useEffect(() => {
		refetch({
			...customFilters,
			jobCategory,
		});
	}, [refetch, customFilters, jobCategory]);

	const jobList = useMemo(
		// @ts-ignore
		() => (error ? [] : getPaginatedDataNodeList<Move, any>(data && data[dataKey])),
		[data, dataKey, error]
	);

	useEffect(() => {
		setAfter('');
		setHasNextPage(undefined);
	}, [customFilters]);

	const onLoadMore = useCallback(
		() =>
			fetchMore &&
			fetchMore({
				variables: {
					after,
					...customFilters,
				},
			}),
		[fetchMore, after, customFilters]
	);

	return (
		<ItemListWithInfiniteScroll
			items={jobList}
			ItemTemplate={ItemTemplate}
			hasNextPage={!!hasNextPage}
			loading={loading || networkStatus === NetworkStatus.fetchMore}
			onLoadMore={onLoadMore}
			keyName={keyName}
		/>
	);
}

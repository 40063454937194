import { makeStyles } from '@material-ui/core';
import { theme } from 'utils';

export const useStyles = makeStyles({
	wrapper: {
		display: 'flex',
		padding: theme.spacing(1, 2, 1, 2),
		justifyContent: 'space-between',
		alignItems: 'center',
		[theme.breakpoints.down('xs')]: {
			flexWrap: 'wrap',
		},
		'& div': {
			display: 'flex',
			flexBasis: '33.33%',
		},
		'& div:last-child': {
			justifyContent: 'flex-end',
		},
		'& .capitalize': {
			textTransform: 'capitalize',
		},
	},
});

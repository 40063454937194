import { useState } from 'react';

export function useSearch() {
	const [searchStr, setSearchStr] = useState<string>('');

	return {
		searchStr,
		setSearchStr,
	};
}

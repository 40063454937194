import { TruckListTemp } from 'components';
import { TruckBulkUpload } from 'containers';
import { useGetSupplyTrucksQuery } from 'generate/typesSupplyService';
import { useModalStateControls, useReadUserInfo } from 'hooks';
import { AssetQueryContainer } from './AssetQueryContainer';
import { TruckCard } from './TruckCard';
import { supplyServiceClient } from 'graphqlapi/clients/supplyServiceClient';

export const TruckList = () => {
	const { isOpened, toggleModal } = useModalStateControls();
	const userInfo = useReadUserInfo();

	return (
		<AssetQueryContainer
			keyName="value"
			dataKey="trucks"
			useQuery={useGetSupplyTrucksQuery}
			recordKeyList={['truckReg', 'active']}
			ItemTemplate={TruckCard}
			// @ts-ignore
			options={{
				client: supplyServiceClient,
				variables: {
					transporterId: userInfo?.transporter_id,
          first: 100,
				},
			}}
		>
			<TruckListTemp onAddAsset={toggleModal} />
			<TruckBulkUpload isOpened={isOpened} toggleModal={toggleModal} />
		</AssetQueryContainer>
	);
};

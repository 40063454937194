import { gql } from '@apollo/client';
import { fuelStationDetails } from '../fragments';

export const getFuelStationList = gql`
	query getFuelStationList($moveId: Int!, $after: String, $first: Int) {
		fuelStations(moveId: $moveId, after: $after, first: $first) {
			pageInfo {
				hasNextPage
				endCursor
			}
			edges {
				node {
					...fuelStationDetails
				}
			}
		}
	}
	${fuelStationDetails}
`;

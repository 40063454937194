import React from 'react';
import { AccountDetailsFragment } from 'generate/typesAdmin';
import { Typography, List, ListItem } from '@material-ui/core';
import { formatCamelToSnakeCase } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/admin/user-details/UserDetails.styles';
import { COMMON } from 'utils';

interface UserAccountListProps {
	data?: AccountDetailsFragment | null;
}

export const TransporterAccountList = ({ data }: UserAccountListProps) => {
	const { t } = useTranslation('form-fields');
	const classes = useStyles();

	if (!data) {
		return null;
	}

	return (
		<List>
			{Object.entries(data)
				.filter(([k]) => k !== COMMON.typename)
				.map(([k, v]) => (
					<ListItem key={k} className={classes.accountListItem}>
						<Typography variant="h6">
							<b>{t(`sign_up.${formatCamelToSnakeCase(k)}`)}:&nbsp;</b>
						</Typography>
						<Typography>{v || COMMON.noDataProvided}</Typography>
					</ListItem>
				))}
		</List>
	);
};

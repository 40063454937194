import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import {useStyles} from './DocumentModal.styles';
import {CloseIcon} from "../../components";
import {Box, Paper} from "@material-ui/core";

interface DocumentModalProps {
    setOpen: (open: boolean) => void;
    open: boolean;
    documentLink: string;
}

export function DocumentModal({
                                  setOpen,
                                  open,
                                  documentLink,
                              }: DocumentModalProps) {
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Paper>

        <Modal
            open={open}
            onClose={handleClose}
            className={classes.modal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
                className: classes.backdrop,
            }}
        >
            <Fade in={open}>
                <Box display="flex" flexDirection="column" className={classes.container}>
                    <CloseIcon onClick={handleClose} className={classes.closeIcon}/>
                <iframe
                    title="View Document"
                    src={documentLink}
                    className={classes.iframe}
                />
                </Box>

            </Fade>
        </Modal>
        </Paper>
    );
}

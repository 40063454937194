import React, { useCallback, useMemo } from 'react';
import { CompanyDetailsForm as CompanyDetailsFormTemp } from 'components';
import { CompanyDetailsFields, CompanyDetailsNames } from 'models';
import { useTranslation } from 'react-i18next';
import { UpdateCompanyDetailsMutationVariables } from 'generate/types';
import { CreateCompanyDetailsMutationVariables } from 'generate/typesAuth';
import { DirectorDetailsInput } from 'generate/types';
import {
	formatCamelToSnakeCase,
	getFieldType,
	getMaxValueMessage,
	getMinValueMessage,
	getRequiredMessage,
	mapFieldsToInitialValues,
} from 'helpers';
import * as Yup from 'yup';

type CompanyDetailsFormVariables = CreateCompanyDetailsMutationVariables &
	UpdateCompanyDetailsMutationVariables;

interface CompanyDetailsFormProps {
	onSubmit: (variables: CompanyDetailsFormVariables) => void;
	data?: Partial<CompanyDetailsFields>;
}

const fieldsList = [
	CompanyDetailsNames.enterpriseType,
	CompanyDetailsNames.tradingName,
	CompanyDetailsNames.postalAddress,
	CompanyDetailsNames.physicalAddress,
	CompanyDetailsNames.registrationNumber,
	CompanyDetailsNames.dateEstablished,
	CompanyDetailsNames.numberOfDirectors,
];

const directorDetailList = [
	CompanyDetailsNames.directorName,
	CompanyDetailsNames.directorNumber,
	CompanyDetailsNames.directorResidentialAddress,
	CompanyDetailsNames.directorTelNumber,
];

export const initialDirectorDetail = directorDetailList.reduce(
	(res, k) => ({
		...res,
		[k]: '',
	}),
	{}
) as DirectorDetailsInput;

export const CompanyDetailsForm = ({ onSubmit, data }: CompanyDetailsFormProps) => {
	const { t } = useTranslation('form-fields');
	const { t: tE } = useTranslation('errors');

	const fields = useMemo(() => {
		const dateFields = [CompanyDetailsNames.dateEstablished];
		const numberFields = [CompanyDetailsNames.numberOfDirectors];

		return fieldsList.map((name) => ({
			name,
			type: getFieldType(name, { dateFields, numberFields }),
		}));
	}, []);

	const initialValues = useMemo(() => {
		return mapFieldsToInitialValues(
			fieldsList,
			data ||
				({
					[CompanyDetailsNames.numberOfDirectors]: 1,
					[CompanyDetailsNames.directorDetails]: new Array(1).fill(initialDirectorDetail),
				} as {} as Partial<CompanyDetailsFields>)
		);
	}, [data]);

	const onChangeDirectorCount = useCallback(
		(
			directorCount: number,
			directorDetailsData: Array<DirectorDetailsInput>,
			setValue: (field: string, value: unknown, shouldValidate?: boolean) => void
		) => {
			if (directorCount < 1 || directorCount > 5) return;
			const delta = directorDetailsData.length - directorCount;

			if (delta > 0) {
				setValue(CompanyDetailsNames.directorDetails, directorDetailsData.slice(0, delta));
			}

			if (delta < 0) {
				setValue(CompanyDetailsNames.directorDetails, [
					...directorDetailsData,
					...new Array(Math.abs(delta)).fill(initialDirectorDetail),
				]);
			}
		},
		[]
	);

	const validationSchema = useMemo(
		() =>
			Yup.object({
				...fields.reduce(
					(res, { name }) => ({
						...res,
						[name]: Yup.string().required(
							getRequiredMessage(tE, t(`company_details.${formatCamelToSnakeCase(name)}`))
						),
					}),
					{}
				),
				[CompanyDetailsNames.numberOfDirectors]: Yup.number()
					.required(
						getRequiredMessage(
							tE,
							t(`company_details.${formatCamelToSnakeCase(CompanyDetailsNames.numberOfDirectors)}`)
						)
					)
					.min(1, getMinValueMessage(tE))
					.max(5, getMaxValueMessage(tE)),
				[CompanyDetailsNames.directorDetails]: Yup.array().of(
					Yup.object().shape(
						// @ts-ignore
						directorDetailList.reduce(
							(res, name) => ({
								...res,
								[name]: Yup.string().required(
									getRequiredMessage(tE, t(`company_details.${formatCamelToSnakeCase(name)}`))
								),
							}),
							{}
						)
					)
				),
			}),
		[tE, t, fields]
	);

	return (
		<CompanyDetailsFormTemp
			onSubmit={onSubmit as (d: Partial<CompanyDetailsFields>) => void}
			validationSchema={validationSchema}
			initialValues={initialValues}
			fields={fields}
			directorDetailList={directorDetailList}
			onChangeDirectorCount={onChangeDirectorCount}
		/>
	);
};

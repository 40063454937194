import React, { ReactElement, useCallback } from 'react';
import { Menu, MenuProps, MenuItem } from '@material-ui/core';
import { ISelectOptionList, ISelectOption } from 'containers';
import { useTranslation } from 'react-i18next';
import { useStyles } from './Popover.styles';
import Typography from '@material-ui/core/Typography';

const DEFAULT_ANCHOR_ORIGIN: MenuProps['anchorOrigin'] = {
	vertical: 'bottom',
	horizontal: 'center',
};

const DEFAULT_TRANSFORM_ORIGIN: MenuProps['transformOrigin'] = {
	vertical: 'top',
	horizontal: 'center',
};

interface PopoverProps extends Partial<MenuProps> {
	ControlElement: React.FC<{ onClick: (event: React.MouseEvent<HTMLButtonElement>) => void }>;
	itemList: ISelectOptionList;
	renderItem: (item: ISelectOption) => ReactElement;
	selected?: string | number;
	onSelectItem?: (item: ISelectOption['value']) => void;
	handleClearFilters?: () => void;
}

export const Popover = ({
	ControlElement,
	itemList,
	renderItem,
	selected,
	onSelectItem,
	handleClearFilters,
	anchorOrigin = DEFAULT_ANCHOR_ORIGIN,
	transformOrigin = DEFAULT_TRANSFORM_ORIGIN,
	...props
}: PopoverProps) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const classes = useStyles();
	const { t } = useTranslation('common');
	const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	}, []);

	const handleClose = useCallback(() => setAnchorEl(null), []);

	const handleSelect = useCallback(
		(item: ISelectOption['value']) => {
			onSelectItem && onSelectItem(item);
			setAnchorEl(null);
		},
		[onSelectItem]
	);
	return (
		<>
			<ControlElement onClick={handleClick} />
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={anchorOrigin}
				transformOrigin={transformOrigin}
				getContentAnchorEl={null}
				keepMounted
				{...props}
			>
				{itemList && itemList.length > 1 && (
					<MenuItem
						onClick={() => {
							handleClearFilters && handleClearFilters();
							handleClose();
						}}
						className={classes.item}
						selected={selected === undefined}
						key="none"
					>
						<Typography>{t('None')}</Typography>
					</MenuItem>
				)}
				{itemList.map((item) => (
					<MenuItem
						data-qa={`qa-${item?.value}`}
						className={classes.item}
						onClick={() => handleSelect(item.value)}
						key={item.value}
						selected={item.value === selected}
					>
						{renderItem(item)}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

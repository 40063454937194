import React from 'react';
import cn from 'classnames';
import { IIconProps } from './IIconProps';

export const AddPhotoIcon = ({ className, title = '', pointer }: IIconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="Add-photo-icon"
			viewBox="0 0 32 32"
			className={cn(className, {
				pointer: pointer,
			})}
		>
			<title>{title}</title>
			<path d="M13.063 18.688q0-1.813 1.25-3.063t3-1.25q1.813 0 3.063 1.25t1.25 3.063q0 1.75-1.25 3t-3.063 1.25-3.031-1.219-1.219-3.031zM17.313 25.313q2.75 0 4.719-1.938t1.969-4.688-1.969-4.719-4.719-1.969-4.688 1.969-1.938 4.719 1.938 4.688 4.688 1.938zM8 13.313v-4h4v-4h9.313l2.438 2.688h4.25q1.063 0 1.875 0.813t0.813 1.875v16q0 1.063-0.813 1.844t-1.875 0.781h-21.313q-1.063 0-1.875-0.781t-0.813-1.844v-13.375h4zM4 5.313v-4h2.688v4h4v2.688h-4v4h-2.688v-4h-4v-2.688h4z" />
		</svg>
	);
};

import { DirectorDetailsInput } from 'generate/types';

export enum CompanyDetailsNames {
	enterpriseType = 'enterpriseType',
	tradingName = 'tradingName',
	postalAddress = 'postalAddress',
	physicalAddress = 'physicalAddress',
	registrationNumber = 'registrationNumber',
	dateEstablished = 'dateEstablished',
	vatRegistrationNumber = 'vatRegistrationNumber',
	numberOfDirectors = 'numberOfDirectors',

	directorDetails = 'directorDetails',

	directorName = 'directorName',
	directorNumber = 'directorNumber',
	directorResidentialAddress = 'directorResidentialAddress',
	directorTelNumber = 'directorTelNumber',
}
export interface CompanyDetailsFields {
	[CompanyDetailsNames.enterpriseType]: string;
	[CompanyDetailsNames.tradingName]: string;
	[CompanyDetailsNames.postalAddress]: string;
	[CompanyDetailsNames.physicalAddress]: string;
	[CompanyDetailsNames.registrationNumber]: string;
	[CompanyDetailsNames.dateEstablished]: string;
	[CompanyDetailsNames.vatRegistrationNumber]: string;
	[CompanyDetailsNames.numberOfDirectors]: string;
	[CompanyDetailsNames.directorDetails]: DirectorDetailsInput[];
}

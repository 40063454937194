import { makeStyles } from '@material-ui/core/styles';
import {COLORS} from 'utils';

export const useStyles = makeStyles({
	container: {
		paddingLeft: 2,
		paddingRight: 2,
	},
	card: {
		minWidth: 275,
		border: '1px solid #F9F9F9',
		margin: 8
	},
	primary: {
		color: COLORS.primary,
	},
	divider: {
		margin: '3px 0',
	},
	title: {
		color: '#2C3032',
		fontWeight: 400,
		fontSize: 16,
		letterSpacing: 0.4,
		marginLeft: 20,
		marginTop: 5,
	},
	label: {
		color: '#9B9B9B',
		fontSize: 12,
		fontWeight: 400,
		letterSpacing: 0.4,
		marginBottom: 6,
		marginLeft: 20,
	},
	closeIcon:{
		height: 15,
		width: 15,
		marginBottom: 15,
	},
});

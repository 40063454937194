import { REGULARS } from 'utils';

export function getDocumentName(str?: string | boolean | null) {
	if (!str || typeof str !== 'string') return str;
	const res = str.match(REGULARS.documentName);
	return res ? res[0] : '';
}

export function extractFileNameFromUrl(url?: string | null) {
    if (typeof url === "string") {
        const parsedUrl = new URL(url);
        const pathname = parsedUrl.pathname;
        const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
        return decodeURIComponent(fileName);
    } else return url

}

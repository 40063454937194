/* eslint-disable */
// WARNING: This file is automatically generated. Do not edit.
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  JSONDict: any;
  JSONString: any;
};

export enum ActionType {
  Allocate = 'ALLOCATE',
  Deallocate = 'DEALLOCATE',
  Match = 'MATCH',
  Unmatch = 'UNMATCH'
}

export type AllocateAllocation = {
  __typename?: 'AllocateAllocation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type CancelOrder = {
  __typename?: 'CancelOrder';
  order?: Maybe<OrderType>;
};

export type CancelOrderInput = {
  cancellationReason: Scalars['String'];
  cancelledBy: Scalars['String'];
};

export type CargoRateComparisonType = Node & {
  __typename?: 'CargoRateComparisonType';
  allClientMoves?: Maybe<Scalars['Int']>;
  cargoType: Scalars['String'];
  clientAvgCost?: Maybe<Scalars['Float']>;
  clientName?: Maybe<Scalars['String']>;
  clientTrakitId: Scalars['ID'];
  currency: Scalars['String'];
  geoCodeRoute?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lowerLimit?: Maybe<Scalars['Float']>;
  maxCost?: Maybe<Scalars['Float']>;
  median?: Maybe<Scalars['Float']>;
  minCost?: Maybe<Scalars['Float']>;
  rateType: Scalars['String'];
  route: Scalars['String'];
  upperLimit?: Maybe<Scalars['Float']>;
};

export type ClearingAgent = {
  __typename?: 'ClearingAgent';
  entid?: Maybe<Scalars['Int']>;
  entName?: Maybe<Scalars['String']>;
};

export type Client = {
  __typename?: 'Client';
  countryCode?: Maybe<Scalars['String']>;
  entid?: Maybe<Scalars['Int']>;
  entName?: Maybe<Scalars['String']>;
  fsid?: Maybe<Scalars['ID']>;
};

export type Commodity = {
  __typename?: 'Commodity';
  name?: Maybe<Scalars['String']>;
};

export type CompareCargoRate = {
  __typename?: 'CompareCargoRate';
  isWithinRange?: Maybe<Scalars['String']>;
  lowerRateLimit?: Maybe<Scalars['Float']>;
  medianRate?: Maybe<Scalars['Float']>;
  reason?: Maybe<Scalars['String']>;
  upperRateLimit?: Maybe<Scalars['Float']>;
};

export type ConfirmOrders = {
  __typename?: 'ConfirmOrders';
  confirmedOrderIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type Consignee = {
  __typename?: 'Consignee';
  entid?: Maybe<Scalars['Int']>;
  entName?: Maybe<Scalars['String']>;
};

export type Contact = {
  __typename?: 'Contact';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CreateDraftOrder = {
  __typename?: 'CreateDraftOrder';
  order?: Maybe<OrderType>;
};

export type CreateOrder = {
  __typename?: 'CreateOrder';
  order?: Maybe<OrderType>;
};

export type CreateOrderInput = {
  allocation?: InputMaybe<Scalars['JSONString']>;
  amount?: InputMaybe<Scalars['Int']>;
  clientId?: InputMaybe<Scalars['ID']>;
  customField?: InputMaybe<Scalars['JSONString']>;
  dateDeadlineToLift?: InputMaybe<Scalars['String']>;
  dateLoading?: InputMaybe<Scalars['String']>;
  destination: Scalars['ID'];
  isBackhaul?: InputMaybe<Scalars['Boolean']>;
  lroId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['ID']>;
  source: Scalars['String'];
  state: Scalars['String'];
};

export type CreateTemplate = {
  __typename?: 'CreateTemplate';
  order?: Maybe<OrderType>;
};

export type CreateTruckSupply = {
  __typename?: 'CreateTruckSupply';
  truckSupply?: Maybe<TruckSupplyType>;
};

export type CustomOrder = Node & {
  __typename?: 'CustomOrder';
  cargoType?: Maybe<Scalars['String']>;
  clearingAgent?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['Int']>;
  commodity?: Maybe<Scalars['String']>;
  confirmedLoadingDate?: Maybe<Scalars['Boolean']>;
  consignee?: Maybe<Scalars['String']>;
  containerDetails?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateDeadlineToLift?: Maybe<Scalars['DateTime']>;
  dateLoading?: Maybe<Scalars['DateTime']>;
  daysToDeadline?: Maybe<Scalars['Int']>;
  destination?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isBackhaul?: Maybe<Scalars['Boolean']>;
  lane?: Maybe<Scalars['String']>;
  loadingContact?: Maybe<Scalars['String']>;
  lroId?: Maybe<Scalars['ID']>;
  noGoTrailerTypes?: Maybe<Scalars['String']>;
  offloadingContact?: Maybe<Scalars['String']>;
  offloadingHours?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['ID']>;
  origin?: Maybe<Scalars['String']>;
  outstandingTrucks?: Maybe<Scalars['Int']>;
  outstandingWeight?: Maybe<Scalars['Int']>;
  packagingType?: Maybe<Scalars['String']>;
  pipeline?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  totalWeight?: Maybe<Scalars['Int']>;
  trailerTypes?: Maybe<Scalars['String']>;
  trucksMatched?: Maybe<Scalars['Int']>;
  trucksNeeded?: Maybe<Scalars['Int']>;
  vessel?: Maybe<Scalars['String']>;
};

export type CustomOrderConnection = {
  __typename?: 'CustomOrderConnection';
  edges: Array<Maybe<CustomOrderEdge>>;
  pageInfo: PageInfo;
};

export type CustomOrderEdge = {
  __typename?: 'CustomOrderEdge';
  cursor: Scalars['String'];
  node?: Maybe<CustomOrder>;
};

export type DeallocateAllocation = {
  __typename?: 'DeallocateAllocation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type Depot = {
  __typename?: 'Depot';
  country?: Maybe<Scalars['String']>;
  geoCode?: Maybe<Scalars['String']>;
  geoid?: Maybe<Scalars['Int']>;
  geoName?: Maybe<Scalars['String']>;
};

export type EmptyReturnDestinationDepot = {
  __typename?: 'EmptyReturnDestinationDepot';
  name?: Maybe<Scalars['String']>;
};

export type LinkAssetToTruck = {
  __typename?: 'LinkAssetToTruck';
  ok?: Maybe<Scalars['Boolean']>;
};

export type MatchingActionType = Node & {
  __typename?: 'MatchingActionType';
  actionedAt?: Maybe<Scalars['DateTime']>;
  actionType: ActionType;
  id: Scalars['ID'];
  jobRequestId: Scalars['Int'];
  lroId: Scalars['String'];
  payload?: Maybe<Scalars['JSONDict']>;
  truckSerial: Scalars['String'];
  userEmail: Scalars['String'];
  woid?: Maybe<Scalars['String']>;
};

export type MatchingActionTypeConnection = {
  __typename?: 'MatchingActionTypeConnection';
  edges: Array<Maybe<MatchingActionTypeEdge>>;
  pageInfo: PageInfo;
};

export type MatchingActionTypeEdge = {
  __typename?: 'MatchingActionTypeEdge';
  cursor: Scalars['String'];
  node?: Maybe<MatchingActionType>;
};

export enum MatchingActionTypeSortEnum {
  ActionTypeAsc = 'ACTION_TYPE_ASC',
  ActionTypeDesc = 'ACTION_TYPE_DESC',
  ActionedAtAsc = 'ACTIONED_AT_ASC',
  ActionedAtDesc = 'ACTIONED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LroIdAsc = 'LRO_ID_ASC',
  LroIdDesc = 'LRO_ID_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  SupplyIdAsc = 'SUPPLY_ID_ASC',
  SupplyIdDesc = 'SUPPLY_ID_DESC',
  TruckSerialAsc = 'TRUCK_SERIAL_ASC',
  TruckSerialDesc = 'TRUCK_SERIAL_DESC',
  UserEmailAsc = 'USER_EMAIL_ASC',
  UserEmailDesc = 'USER_EMAIL_DESC',
  WoidAsc = 'WOID_ASC',
  WoidDesc = 'WOID_DESC'
}

export type MatchTrucks = {
  __typename?: 'MatchTrucks';
  ok?: Maybe<Scalars['Boolean']>;
};

export type Node = {
  id: Scalars['ID'];
};

export type Order = {
  __typename?: 'Order';
  additionalDestinations?: Maybe<Array<Maybe<Depot>>>;
  allocation?: Maybe<Scalars['JSONString']>;
  amount?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customField?: Maybe<Scalars['JSONString']>;
  dateDeadlineToLift?: Maybe<Scalars['String']>;
  dateLoading?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['ID']>;
  isBackhaul?: Maybe<Scalars['Boolean']>;
  isContainerCoload?: Maybe<Scalars['Boolean']>;
  lroId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['ID']>;
  origin?: Maybe<Scalars['ID']>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  edges: Array<Maybe<OrderEdge>>;
  pageInfo: PageInfo;
};

export type OrderEdge = {
  __typename?: 'OrderEdge';
  cursor: Scalars['String'];
  node?: Maybe<OrderType>;
};

export type OrderInfoSnacks = {
  __typename?: 'OrderInfoSnacks';
  daysToDeadline?: Maybe<Scalars['Int']>;
  orderAge?: Maybe<Scalars['Int']>;
  totalMatchedWeight?: Maybe<Scalars['Int']>;
  trucksMatched?: Maybe<Scalars['Int']>;
  trucksNeeded?: Maybe<Scalars['Int']>;
  trucksOutstanding?: Maybe<Scalars['Int']>;
  weightLoaded?: Maybe<Scalars['Int']>;
  weightOutstanding?: Maybe<Scalars['Int']>;
};

export type OrderInput = {
  allocation?: InputMaybe<Scalars['JSONString']>;
  amount?: InputMaybe<Scalars['Int']>;
  clientId?: InputMaybe<Scalars['ID']>;
  customField?: InputMaybe<Scalars['JSONString']>;
  dateDeadlineToLift?: InputMaybe<Scalars['String']>;
  dateLoading?: InputMaybe<Scalars['String']>;
  destination?: InputMaybe<Scalars['ID']>;
  isBackhaul?: InputMaybe<Scalars['Boolean']>;
  lroId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['ID']>;
  source?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type OrdersFilter = {
  sourceApp?: InputMaybe<SourceAppTypeEnum>;
  states?: InputMaybe<Array<InputMaybe<OrderStateTypeEnum>>>;
};

export enum OrderSource {
  LcaEa = 'LCA_EA',
  LcaWa = 'LCA_WA',
  LfaEa = 'LFA_EA',
  LfaWa = 'LFA_WA',
  LiaEa = 'LIA_EA',
  LiaWa = 'LIA_WA',
  Trakit = 'TRAKIT',
  WhatsappEa = 'WHATSAPP_EA',
  WhatsappWa = 'WHATSAPP_WA'
}

export enum OrderState {
  Allocated = 'ALLOCATED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Consigned = 'CONSIGNED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Matched = 'MATCHED',
  Operational = 'OPERATIONAL',
  Opportunity = 'OPPORTUNITY',
  Template = 'TEMPLATE'
}

export enum OrderStateTypeEnum {
  Allocated = 'ALLOCATED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Consigned = 'CONSIGNED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Matched = 'MATCHED',
  Operational = 'OPERATIONAL',
  Opportunity = 'OPPORTUNITY',
  Template = 'TEMPLATE'
}

export enum OrderStatusTypeEnum {
  Unfulfilled = 'UNFULFILLED'
}

export type OrderType = Node & {
  __typename?: 'OrderType';
  additionalDestinations?: Maybe<Array<Maybe<Depot>>>;
  allocation?: Maybe<Scalars['JSONString']>;
  amount?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['Float']>;
  containerInfo?: Maybe<Array<Maybe<Scalars['JSONDict']>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customField?: Maybe<Scalars['JSONDict']>;
  dateDeadlineToLift?: Maybe<Scalars['DateTime']>;
  dateLoading?: Maybe<Scalars['DateTime']>;
  daysToDeadline?: Maybe<Scalars['Int']>;
  destination: Scalars['Float'];
  id: Scalars['ID'];
  isBackhaul?: Maybe<Scalars['Boolean']>;
  lroId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameUpper?: Maybe<Scalars['String']>;
  orderId: Scalars['Float'];
  origin?: Maybe<Scalars['Float']>;
  source: OrderSource;
  state: OrderState;
  trucksNeeded?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum OrderTypeSortEnum {
  AllocationAsc = 'ALLOCATION_ASC',
  AllocationDesc = 'ALLOCATION_DESC',
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  ClientIdAsc = 'CLIENT_ID_ASC',
  ClientIdDesc = 'CLIENT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CustomFieldAsc = 'CUSTOM_FIELD_ASC',
  CustomFieldDesc = 'CUSTOM_FIELD_DESC',
  DateDeadlineToLiftAsc = 'DATE_DEADLINE_TO_LIFT_ASC',
  DateDeadlineToLiftDesc = 'DATE_DEADLINE_TO_LIFT_DESC',
  DateLoadingAsc = 'DATE_LOADING_ASC',
  DateLoadingDesc = 'DATE_LOADING_DESC',
  DestinationAsc = 'DESTINATION_ASC',
  DestinationDesc = 'DESTINATION_DESC',
  LroIdAsc = 'LRO_ID_ASC',
  LroIdDesc = 'LRO_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OrderIdAsc = 'ORDER_ID_ASC',
  OrderIdDesc = 'ORDER_ID_DESC',
  OriginAsc = 'ORIGIN_ASC',
  OriginDesc = 'ORIGIN_DESC',
  SourceAsc = 'SOURCE_ASC',
  SourceDesc = 'SOURCE_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type PackagingType = {
  __typename?: 'PackagingType';
  name?: Maybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type RootMutation = {
  __typename?: 'RootMutation';
  allocateAllocation?: Maybe<AllocateAllocation>;
  cancelOrder?: Maybe<CancelOrder>;
  compareCargoRate?: Maybe<CompareCargoRate>;
  confirmOrders?: Maybe<ConfirmOrders>;
  createDraftOrder?: Maybe<CreateDraftOrder>;
  createOrder?: Maybe<CreateOrder>;
  createTemplate?: Maybe<CreateTemplate>;
  createTruckSupply?: Maybe<CreateTruckSupply>;
  deallocateAllocation?: Maybe<DeallocateAllocation>;
  linkAssetToTruck?: Maybe<LinkAssetToTruck>;
  matchTrucks?: Maybe<MatchTrucks>;
  supplyTruckAllocation?: Maybe<SupplyTruckAllocation>;
  unlinkAssetFromTruck?: Maybe<UnLinkAssetFromTruck>;
  unmatchTrucks?: Maybe<UnmatchTrucks>;
  updateOrder?: Maybe<UpdateOrder>;
  updateTruckSupply?: Maybe<UpdateTruckSupply>;
  updateWorkOrder?: Maybe<UpdateWorkOrder>;
};


export type RootMutationAllocateAllocationArgs = {
  jobRequestId: Scalars['ID'];
  lroId: Scalars['ID'];
};


export type RootMutationCancelOrderArgs = {
  input?: InputMaybe<CancelOrderInput>;
  orderId: Scalars['ID'];
};


export type RootMutationCompareCargoRateArgs = {
  cargoType: Scalars['String'];
  clientId: Scalars['Int'];
  currency: Scalars['String'];
  destinationDepotCode: Scalars['String'];
  originDepotCode: Scalars['String'];
  rate: Scalars['Float'];
  rateType: Scalars['String'];
};


export type RootMutationConfirmOrdersArgs = {
  orderIds: Array<InputMaybe<Scalars['Int']>>;
};


export type RootMutationCreateDraftOrderArgs = {
  input?: InputMaybe<CreateOrderInput>;
  shipper: Scalars['String'];
  workflow?: InputMaybe<Scalars['String']>;
};


export type RootMutationCreateOrderArgs = {
  input?: InputMaybe<CreateOrderInput>;
  shipper: Scalars['String'];
  workflow: Scalars['String'];
};


export type RootMutationCreateTemplateArgs = {
  input?: InputMaybe<CreateOrderInput>;
  shipper: Scalars['String'];
  workflow?: InputMaybe<Scalars['String']>;
};


export type RootMutationCreateTruckSupplyArgs = {
  alternativeTransporterRate?: InputMaybe<Scalars['String']>;
  bidPrice?: InputMaybe<Scalars['Float']>;
  cargoType?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  etaLoadingPoint: Scalars['DateTime'];
  extra?: InputMaybe<Scalars['JSONString']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  loadingSiteId?: InputMaybe<Scalars['Int']>;
  loriRate?: InputMaybe<Scalars['Float']>;
  lroId?: InputMaybe<Scalars['String']>;
  numTrucks: Scalars['Int'];
  rate?: InputMaybe<Scalars['Float']>;
  rateCurrencyType?: InputMaybe<Scalars['String']>;
  rateType?: InputMaybe<Scalars['String']>;
  source: SupplySourceType;
  trailerType: Scalars['String'];
  transporterEntid: Scalars['Int'];
  transporterName: Scalars['String'];
  transportType?: InputMaybe<Scalars['String']>;
  truckList?: InputMaybe<Array<InputMaybe<TruckInput>>>;
};


export type RootMutationDeallocateAllocationArgs = {
  email?: InputMaybe<Scalars['String']>;
  jobRequestId: Scalars['ID'];
};


export type RootMutationLinkAssetToTruckArgs = {
  linkAssetSerial: Scalars['String'];
  replace?: InputMaybe<Scalars['Boolean']>;
  truckSerial: Scalars['String'];
};


export type RootMutationMatchTrucksArgs = {
  jobRequestId: Scalars['Int'];
  lroId: Scalars['ID'];
  truckList: Array<InputMaybe<TruckMatchingInputType>>;
};


export type RootMutationSupplyTruckAllocationArgs = {
  allocationType: SupplyAllocationTypeEnum;
  jobRequestId: Scalars['Int'];
  truckData: TruckInput;
};


export type RootMutationUnlinkAssetFromTruckArgs = {
  truckSerial: Scalars['String'];
  unlinkAssetSerial: Scalars['String'];
};


export type RootMutationUnmatchTrucksArgs = {
  jobRequestId: Scalars['Int'];
  lroId: Scalars['ID'];
  truckList: Array<InputMaybe<TruckUnmatchingType>>;
  unmatchingReason: UnmatchingReasonEnum;
};


export type RootMutationUpdateOrderArgs = {
  input?: InputMaybe<OrderInput>;
  orderId: Scalars['ID'];
};


export type RootMutationUpdateTruckSupplyArgs = {
  alternativeTransporterRate?: InputMaybe<Scalars['String']>;
  bidPrice?: InputMaybe<Scalars['Float']>;
  cargoType?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  etaLoadingPoint?: InputMaybe<Scalars['DateTime']>;
  extra?: InputMaybe<Scalars['JSONString']>;
  jobRequestId: Scalars['Int'];
  loadingSiteId?: InputMaybe<Scalars['Int']>;
  loriRate?: InputMaybe<Scalars['Float']>;
  rate?: InputMaybe<Scalars['Float']>;
  rateCurrencyType?: InputMaybe<Scalars['String']>;
  rateType?: InputMaybe<Scalars['String']>;
  trailerType?: InputMaybe<Scalars['String']>;
  transporterEntid?: InputMaybe<Scalars['Int']>;
  transporterName?: InputMaybe<Scalars['String']>;
  transportType?: InputMaybe<Scalars['String']>;
};


export type RootMutationUpdateWorkOrderArgs = {
  workOrderData: WorkOrderInputType;
};

export type RootQuery = {
  __typename?: 'RootQuery';
  availableInfosnack?: Maybe<Scalars['Int']>;
  cargoRateComparison?: Maybe<CargoRateComparisonType>;
  clearingAgents?: Maybe<Array<Maybe<ClearingAgent>>>;
  clientCargoRate?: Maybe<Scalars['String']>;
  clients?: Maybe<Array<Maybe<Client>>>;
  commodities?: Maybe<Array<Maybe<Commodity>>>;
  consignees?: Maybe<Array<Maybe<Consignee>>>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  delayedInfosnack?: Maybe<Scalars['Int']>;
  depots?: Maybe<Array<Maybe<Depot>>>;
  emptyDestinationDepots?: Maybe<Array<Maybe<EmptyReturnDestinationDepot>>>;
  inboundInfosnack?: Maybe<Scalars['Int']>;
  lcaOrders?: Maybe<OrderConnection>;
  loadedInfosnack?: Maybe<Scalars['Int']>;
  matchingActions?: Maybe<MatchingActionTypeConnection>;
  newOrders?: Maybe<CustomOrderConnection>;
  node?: Maybe<Node>;
  numTrucksInbound?: Maybe<Scalars['Int']>;
  numTrucksLoaded?: Maybe<Scalars['Int']>;
  numTrucksLoading?: Maybe<Scalars['Int']>;
  onJourneyInfosnack?: Maybe<Scalars['Int']>;
  openOrdersCount?: Maybe<Scalars['Int']>;
  order?: Maybe<Order>;
  orderInfoSnacks?: Maybe<OrderInfoSnacks>;
  orders?: Maybe<OrderConnection>;
  orderTemplates?: Maybe<OrderConnection>;
  packagingTypes?: Maybe<Array<Maybe<PackagingType>>>;
  totalTrucksOutstanding?: Maybe<Scalars['Int']>;
  trailerSubTypes?: Maybe<Array<Maybe<TrailerSubType>>>;
  trailerTypes?: Maybe<Array<Maybe<TrailerType>>>;
  transporters?: Maybe<Array<Maybe<TransporterType>>>;
  trucksNeededByCargoType?: Maybe<TrucksNeededByCargoType>;
  truckSupplies?: Maybe<TruckSupplyTypeConnection>;
  truckSupply?: Maybe<TruckSupplyType>;
  unmatchedTodayInfosnack?: Maybe<Scalars['Int']>;
  vessels?: Maybe<Array<Maybe<Vessel>>>;
  workflows?: Maybe<Array<Maybe<Workflow>>>;
  workOrder?: Maybe<WorkOrderType>;
};


export type RootQueryCargoRateComparisonArgs = {
  cargoType: Scalars['String'];
  clientId: Scalars['Int'];
  currency: Scalars['String'];
  destinationDepotCode: Scalars['String'];
  originDepotCode: Scalars['String'];
  rateType: Scalars['String'];
};


export type RootQueryClearingAgentsArgs = {
  entId?: InputMaybe<Scalars['Int']>;
  location: Scalars['String'];
};


export type RootQueryClientCargoRateArgs = {
  clientName: Scalars['String'];
  destination: Scalars['String'];
  origin: Scalars['String'];
  trailerCapacity: Scalars['String'];
};


export type RootQueryClientsArgs = {
  entId?: InputMaybe<Scalars['Int']>;
  location: Scalars['String'];
};


export type RootQueryConsigneesArgs = {
  entId?: InputMaybe<Scalars['Int']>;
  location: Scalars['String'];
};


export type RootQueryContactsArgs = {
  contactType?: InputMaybe<Scalars['String']>;
  fsid: Scalars['ID'];
};


export type RootQueryDepotsArgs = {
  entId?: InputMaybe<Scalars['Int']>;
  geoId?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
};


export type RootQueryEmptyDestinationDepotsArgs = {
  location?: InputMaybe<Scalars['String']>;
};


export type RootQueryInboundInfosnackArgs = {
  entid?: InputMaybe<Scalars['Int']>;
};


export type RootQueryLcaOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  client?: InputMaybe<Scalars['String']>;
  clientId: Scalars['Int'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<OrderTypeSortEnum>>>;
  state?: InputMaybe<Scalars['String']>;
};


export type RootQueryLoadedInfosnackArgs = {
  entid?: InputMaybe<Scalars['Int']>;
};


export type RootQueryMatchingActionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<MatchingActionTypeSortEnum>>>;
};


export type RootQueryNewOrdersArgs = {
  accountManagerEmail?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['String']>;
  dateLoading?: InputMaybe<Scalars['Date']>;
  destination?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<Array<InputMaybe<OrdersFilter>>>;
  first?: InputMaybe<Scalars['Int']>;
  jobStatus?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  location: Scalars['String'];
  lroId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['Int']>;
  orderStatus?: InputMaybe<OrderStatusTypeEnum>;
  origin?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Scalars['String']>;
  sourceApp?: InputMaybe<SourceAppTypeEnum>;
  state?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<Array<InputMaybe<OrderStateTypeEnum>>>;
};


export type RootQueryNodeArgs = {
  id: Scalars['ID'];
};


export type RootQueryNumTrucksInboundArgs = {
  lroId: Scalars['String'];
};


export type RootQueryNumTrucksLoadedArgs = {
  lroId: Scalars['String'];
};


export type RootQueryNumTrucksLoadingArgs = {
  lroId: Scalars['String'];
};


export type RootQueryOrderArgs = {
  lroId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['ID']>;
};


export type RootQueryOrderInfoSnacksArgs = {
  lroId: Scalars['ID'];
};


export type RootQueryOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  location: Scalars['String'];
  sort?: InputMaybe<Array<InputMaybe<OrderTypeSortEnum>>>;
  states?: InputMaybe<Array<InputMaybe<OrderStateTypeEnum>>>;
};


export type RootQueryOrderTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<OrderTypeSortEnum>>>;
};


export type RootQueryTruckSuppliesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  cargoType?: InputMaybe<Scalars['String']>;
  createdByEmail?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  loadingSiteId?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  lroId?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<TruckSupplyTypeSortEnum>>>;
  trailerType?: InputMaybe<Scalars['String']>;
  transporterEntId?: InputMaybe<Scalars['Int']>;
  transportType?: InputMaybe<TransitTypeEnum>;
};


export type RootQueryTruckSupplyArgs = {
  id: Scalars['Int'];
};


export type RootQueryWorkOrderArgs = {
  woid: Scalars['Int'];
};

export enum SourceAppTypeEnum {
  Lca = 'LCA',
  Lia = 'LIA',
  Trakit = 'TRAKIT',
  Whatsapp = 'WHATSAPP'
}

export enum SupplyAllocationTypeEnum {
  Addition = 'ADDITION',
  Edit = 'EDIT',
  Removal = 'REMOVAL'
}

export enum SupplySourceEnum {
  LfaEa = 'LFA_EA',
  LfaWa = 'LFA_WA',
  LiaEa = 'LIA_EA',
  LiaWa = 'LIA_WA',
  LtaEa = 'LTA_EA',
  LtaSa = 'LTA_SA',
  LtaWa = 'LTA_WA'
}

export enum SupplySourceType {
  LfaEa = 'LFA_EA',
  LfaWa = 'LFA_WA',
  LiaEa = 'LIA_EA',
  LiaWa = 'LIA_WA',
  LtaEa = 'LTA_EA',
  LtaSa = 'LTA_SA',
  LtaWa = 'LTA_WA'
}

export type SupplyTruckAllocation = {
  __typename?: 'SupplyTruckAllocation';
  allocatedTruck?: Maybe<TruckType>;
  jobRequestId?: Maybe<Scalars['Int']>;
};

export type TrailerSubType = {
  __typename?: 'TrailerSubType';
  name?: Maybe<Scalars['String']>;
};

export type TrailerType = {
  __typename?: 'TrailerType';
  name?: Maybe<Scalars['String']>;
};

export enum TransitTypeEnum {
  Local = 'LOCAL',
  Transit = 'TRANSIT'
}

export enum TransporterPaymentPeriodEnum {
  FourteenDays = 'FOURTEEN_DAYS',
  FourtyFiveDays = 'FOURTY_FIVE_DAYS',
  SevenDays = 'SEVEN_DAYS',
  SixtyDays = 'SIXTY_DAYS',
  ThirtyDays = 'THIRTY_DAYS',
  TwentyOneDays = 'TWENTY_ONE_DAYS',
  ZeroDays = 'ZERO_DAYS'
}

export type TransporterType = {
  __typename?: 'TransporterType';
  id: Scalars['Int'];
  name: Scalars['String'];
  tracking: Scalars['String'];
};

export type TruckInput = {
  action?: InputMaybe<Scalars['String']>;
  driverEntId?: InputMaybe<Scalars['Int']>;
  entId?: InputMaybe<Scalars['Int']>;
  etaLoadingPoint?: InputMaybe<Scalars['DateTime']>;
  loadingSiteId?: InputMaybe<Scalars['Int']>;
  lroId?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  serial: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  trailerReg?: InputMaybe<Scalars['String']>;
  trailerType?: InputMaybe<Scalars['String']>;
  truckReg?: InputMaybe<Scalars['String']>;
  woid?: InputMaybe<Scalars['String']>;
  workOrderData?: InputMaybe<WorkOrderMatchingInfoInputType>;
};

export type TruckMatchingInputType = {
  etaLoadingPoint?: InputMaybe<Scalars['DateTime']>;
  serial: Scalars['String'];
};

export type TrucksNeededByCargoType = {
  __typename?: 'TrucksNeededByCargoType';
  baggedBulkLocal?: Maybe<Scalars['Int']>;
  baggedBulkTransit?: Maybe<Scalars['Int']>;
  containerLocal?: Maybe<Scalars['Int']>;
  containerTransit?: Maybe<Scalars['Int']>;
  fmcgLocal?: Maybe<Scalars['Int']>;
  fmcgTransit?: Maybe<Scalars['Int']>;
};

export type TruckSupplyType = Node & {
  __typename?: 'TruckSupplyType';
  allocatedTrucks?: Maybe<Array<Maybe<TruckType>>>;
  alternativeTransporterRate?: Maybe<Scalars['String']>;
  availableTrucks?: Maybe<Array<Maybe<TruckType>>>;
  bidPrice?: Maybe<Scalars['Float']>;
  cargoType?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  createdByEmail?: Maybe<Scalars['String']>;
  etaLoadingPoint: Scalars['DateTime'];
  extra?: Maybe<Scalars['JSONString']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  jobRequestId?: Maybe<Scalars['Int']>;
  loadingSiteId?: Maybe<Scalars['Int']>;
  loadingSiteName?: Maybe<Scalars['String']>;
  loriRate?: Maybe<Scalars['Float']>;
  lroId?: Maybe<Scalars['String']>;
  matchedTrucks?: Maybe<Array<Maybe<TruckType>>>;
  numTrucks: Scalars['Int'];
  rate?: Maybe<Scalars['Float']>;
  rateCurrencyType?: Maybe<Scalars['String']>;
  rateType?: Maybe<Scalars['String']>;
  source?: Maybe<SupplySourceEnum>;
  trailerType: Scalars['String'];
  transporterEntid: Scalars['Int'];
  transporterName?: Maybe<Scalars['String']>;
  transporterPaymentPeriod?: Maybe<Scalars['String']>;
  transportType?: Maybe<Scalars['String']>;
  truckList?: Maybe<Scalars['JSONString']>;
};

export type TruckSupplyTypeConnection = {
  __typename?: 'TruckSupplyTypeConnection';
  edges: Array<Maybe<TruckSupplyTypeEdge>>;
  pageInfo: PageInfo;
};

export type TruckSupplyTypeEdge = {
  __typename?: 'TruckSupplyTypeEdge';
  cursor: Scalars['String'];
  node?: Maybe<TruckSupplyType>;
};

export enum TruckSupplyTypeSortEnum {
  AlternativeTransporterRateAsc = 'ALTERNATIVE_TRANSPORTER_RATE_ASC',
  AlternativeTransporterRateDesc = 'ALTERNATIVE_TRANSPORTER_RATE_DESC',
  BidPriceAsc = 'BID_PRICE_ASC',
  BidPriceDesc = 'BID_PRICE_DESC',
  CargoTypeAsc = 'CARGO_TYPE_ASC',
  CargoTypeDesc = 'CARGO_TYPE_DESC',
  CommentsAsc = 'COMMENTS_ASC',
  CommentsDesc = 'COMMENTS_DESC',
  CreatedAsc = 'CREATED_ASC',
  CreatedByEmailAsc = 'CREATED_BY_EMAIL_ASC',
  CreatedByEmailDesc = 'CREATED_BY_EMAIL_DESC',
  CreatedDesc = 'CREATED_DESC',
  EtaLoadingPointAsc = 'ETA_LOADING_POINT_ASC',
  EtaLoadingPointDesc = 'ETA_LOADING_POINT_DESC',
  ExtraAsc = 'EXTRA_ASC',
  ExtraDesc = 'EXTRA_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  LoadingSiteIdAsc = 'LOADING_SITE_ID_ASC',
  LoadingSiteIdDesc = 'LOADING_SITE_ID_DESC',
  LoriRateAsc = 'LORI_RATE_ASC',
  LoriRateDesc = 'LORI_RATE_DESC',
  LroIdAsc = 'LRO_ID_ASC',
  LroIdDesc = 'LRO_ID_DESC',
  NumTrucksAsc = 'NUM_TRUCKS_ASC',
  NumTrucksDesc = 'NUM_TRUCKS_DESC',
  RateAsc = 'RATE_ASC',
  RateCurrencyTypeAsc = 'RATE_CURRENCY_TYPE_ASC',
  RateCurrencyTypeDesc = 'RATE_CURRENCY_TYPE_DESC',
  RateDesc = 'RATE_DESC',
  RateTypeAsc = 'RATE_TYPE_ASC',
  RateTypeDesc = 'RATE_TYPE_DESC',
  SourceAsc = 'SOURCE_ASC',
  SourceDesc = 'SOURCE_DESC',
  TrailerTypeAsc = 'TRAILER_TYPE_ASC',
  TrailerTypeDesc = 'TRAILER_TYPE_DESC',
  TransportTypeAsc = 'TRANSPORT_TYPE_ASC',
  TransportTypeDesc = 'TRANSPORT_TYPE_DESC',
  TransporterEntidAsc = 'TRANSPORTER_ENTID_ASC',
  TransporterEntidDesc = 'TRANSPORTER_ENTID_DESC',
  TransporterNameAsc = 'TRANSPORTER_NAME_ASC',
  TransporterNameDesc = 'TRANSPORTER_NAME_DESC',
  TruckListAsc = 'TRUCK_LIST_ASC',
  TruckListDesc = 'TRUCK_LIST_DESC'
}

export type TruckType = {
  __typename?: 'TruckType';
  action?: Maybe<Scalars['String']>;
  actionedAt?: Maybe<Scalars['String']>;
  actionedBy?: Maybe<Scalars['String']>;
  captive?: Maybe<Scalars['String']>;
  driverEntId?: Maybe<Scalars['Int']>;
  driverName?: Maybe<Scalars['String']>;
  driverNationalId?: Maybe<Scalars['String']>;
  driverPhoneNumber?: Maybe<Scalars['String']>;
  entId?: Maybe<Scalars['Int']>;
  etaLoadingPoint?: Maybe<Scalars['DateTime']>;
  jobRequestId?: Maybe<Scalars['Int']>;
  loadingSiteId?: Maybe<Scalars['Int']>;
  lroId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  serial: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  tracking?: Maybe<Scalars['String']>;
  trailerEntId?: Maybe<Scalars['Int']>;
  trailerReg?: Maybe<Scalars['String']>;
  trailerType?: Maybe<Scalars['String']>;
  transporterEntId?: Maybe<Scalars['String']>;
  transporterName?: Maybe<Scalars['String']>;
  truckReg?: Maybe<Scalars['String']>;
  woid?: Maybe<Scalars['String']>;
  workOrderData?: Maybe<WorkOrderMatchingInfoType>;
};

export type TruckUnmatchingType = {
  serial: Scalars['String'];
  woid: Scalars['String'];
};

export type UnLinkAssetFromTruck = {
  __typename?: 'UnLinkAssetFromTruck';
  ok?: Maybe<Scalars['Boolean']>;
};

export enum UnmatchingReasonEnum {
  EtaNotMet = 'ETA_NOT_MET',
  NoOrder = 'NO_ORDER',
  OperationalIssue = 'OPERATIONAL_ISSUE',
  OrderCancelled = 'ORDER_CANCELLED',
  TruckDiverted = 'TRUCK_DIVERTED',
  TruckDoubleMatched = 'TRUCK_DOUBLE_MATCHED',
  UnusedWorkorder = 'UNUSED_WORKORDER'
}

export type UnmatchTrucks = {
  __typename?: 'UnmatchTrucks';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateOrder = {
  __typename?: 'UpdateOrder';
  order?: Maybe<OrderType>;
};

export type UpdateTruckSupply = {
  __typename?: 'UpdateTruckSupply';
  truckSupply?: Maybe<TruckSupplyType>;
};

export type UpdateWorkOrder = {
  __typename?: 'UpdateWorkOrder';
  ok?: Maybe<Scalars['Boolean']>;
};

export type Vessel = {
  __typename?: 'Vessel';
  entCode?: Maybe<Scalars['String']>;
  entid?: Maybe<Scalars['Int']>;
  entName?: Maybe<Scalars['String']>;
};

export type Workflow = {
  __typename?: 'Workflow';
  code?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type WorkOrderInputType = {
  cargoRate?: InputMaybe<Scalars['Float']>;
  cargoRateCurrency?: InputMaybe<Scalars['String']>;
  cargoRateType?: InputMaybe<Scalars['String']>;
  destinationGeocode?: InputMaybe<Scalars['String']>;
  driverIdNumber?: InputMaybe<Scalars['String']>;
  originGeocode?: InputMaybe<Scalars['String']>;
  preferredLoadingPoint?: InputMaybe<Scalars['String']>;
  trailerReg?: InputMaybe<Scalars['String']>;
  transporterPaymentPeriod?: InputMaybe<TransporterPaymentPeriodEnum>;
  transporterRate?: InputMaybe<Scalars['Float']>;
  transporterRateCurrency?: InputMaybe<Scalars['String']>;
  transporterRateType?: InputMaybe<Scalars['String']>;
  woid: Scalars['ID'];
};

export type WorkOrderMatchingInfoInputType = {
  containerNumber?: InputMaybe<Scalars['String']>;
  containerNumberTwo?: InputMaybe<Scalars['String']>;
  containerSize?: InputMaybe<Scalars['String']>;
  containerWeight?: InputMaybe<Scalars['Float']>;
  containerWeightTwo?: InputMaybe<Scalars['Float']>;
  emptyContainerDestination?: InputMaybe<Scalars['String']>;
  weightToLoad?: InputMaybe<Scalars['Float']>;
};

export type WorkOrderMatchingInfoType = {
  __typename?: 'WorkOrderMatchingInfoType';
  containerNumber?: Maybe<Scalars['String']>;
  containerNumberTwo?: Maybe<Scalars['String']>;
  containerSize?: Maybe<Scalars['String']>;
  containerWeight?: Maybe<Scalars['Float']>;
  containerWeightTwo?: Maybe<Scalars['Float']>;
  emptyContainerDestination?: Maybe<Scalars['String']>;
  weightToLoad?: Maybe<Scalars['Float']>;
};

export type WorkOrderType = {
  __typename?: 'WorkOrderType';
  cargoRate?: Maybe<Scalars['Float']>;
  cargoRateCurrency?: Maybe<Scalars['String']>;
  cargoRateType?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  commodity?: Maybe<Scalars['String']>;
  consignee?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  driverIdNumber?: Maybe<Scalars['String']>;
  driverName?: Maybe<Scalars['String']>;
  driverPhoneNumber?: Maybe<Scalars['String']>;
  loadingTonnage?: Maybe<Scalars['Float']>;
  lroId?: Maybe<Scalars['ID']>;
  origin?: Maybe<Scalars['String']>;
  originCountry?: Maybe<Scalars['String']>;
  preferredLoadingPoint?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  trailerReg?: Maybe<Scalars['String']>;
  trailerType?: Maybe<Scalars['String']>;
  transporter?: Maybe<Scalars['String']>;
  transporterPaymentPeriod?: Maybe<Scalars['String']>;
  transporterRate?: Maybe<Scalars['Float']>;
  transporterRateCurrency?: Maybe<Scalars['String']>;
  transporterRateType?: Maybe<Scalars['String']>;
  truckSerial?: Maybe<Scalars['String']>;
  woid: Scalars['ID'];
};

export type CreateTruckSupplyMutationVariables = Exact<{
  etaLoadingPoint: Scalars['DateTime'];
  numTrucks: Scalars['Int'];
  transporterEntid: Scalars['Int'];
  source: SupplySourceType;
  trailerType: Scalars['String'];
  transporterName: Scalars['String'];
  truckList?: Maybe<Array<Maybe<TruckInput>> | Maybe<TruckInput>>;
}>;


export type CreateTruckSupplyMutation = { __typename?: 'RootMutation', createTruckSupply?: { __typename?: 'CreateTruckSupply', truckSupply?: { __typename?: 'TruckSupplyType', id: string } | null | undefined } | null | undefined };


export const CreateTruckSupplyDocument = gql`
    mutation createTruckSupply($etaLoadingPoint: DateTime!, $numTrucks: Int!, $transporterEntid: Int!, $source: SupplySourceType!, $trailerType: String!, $transporterName: String!, $truckList: [TruckInput]) {
  createTruckSupply(etaLoadingPoint: $etaLoadingPoint, numTrucks: $numTrucks, transporterEntid: $transporterEntid, source: $source, trailerType: $trailerType, transporterName: $transporterName, truckList: $truckList) {
    truckSupply {
      id
    }
  }
}
    `;
export type CreateTruckSupplyMutationFn = Apollo.MutationFunction<CreateTruckSupplyMutation, CreateTruckSupplyMutationVariables>;

/**
 * __useCreateTruckSupplyMutation__
 *
 * To run a mutation, you first call `useCreateTruckSupplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTruckSupplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTruckSupplyMutation, { data, loading, error }] = useCreateTruckSupplyMutation({
 *   variables: {
 *      etaLoadingPoint: // value for 'etaLoadingPoint'
 *      numTrucks: // value for 'numTrucks'
 *      transporterEntid: // value for 'transporterEntid'
 *      source: // value for 'source'
 *      trailerType: // value for 'trailerType'
 *      transporterName: // value for 'transporterName'
 *      truckList: // value for 'truckList'
 *   },
 * });
 */
export function useCreateTruckSupplyMutation(baseOptions?: Apollo.MutationHookOptions<CreateTruckSupplyMutation, CreateTruckSupplyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTruckSupplyMutation, CreateTruckSupplyMutationVariables>(CreateTruckSupplyDocument, options);
      }
export type CreateTruckSupplyMutationHookResult = ReturnType<typeof useCreateTruckSupplyMutation>;
export type CreateTruckSupplyMutationResult = Apollo.MutationResult<CreateTruckSupplyMutation>;
export type CreateTruckSupplyMutationOptions = Apollo.BaseMutationOptions<CreateTruckSupplyMutation, CreateTruckSupplyMutationVariables>;
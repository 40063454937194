import { useContext, useEffect, useMemo } from 'react';
import { FieldArray, Form, FormikErrors } from 'formik';
import { ISelectOptionList } from 'containers';
import Typography from '@material-ui/core/Typography';
import { FormIdList, SubmitTruckFormData, SubmitTruckFormNames } from 'models';
import { TruckCardWA } from 'components/truck-card';
import { Box } from '@material-ui/core';
import { getPickedAssets } from 'helpers';
import { useStyles } from '../sign-up-form/SignUpForm.styles';
import { ConfirmTruckListContext } from 'context';
import { useReadUserInfo } from '../../../hooks';
import { Loader } from 'components/loader';

interface SubmitTruckFormProps {
  errors?: FormikErrors<SubmitTruckFormData>;
  onChangeFilter: (v?: string) => void;
  setSearchDriver: (v: string) => void;
  truckListData: SubmitTruckFormData;
  driverList?: ISelectOptionList | null;
  trailerList?: ISelectOptionList | null;
  withSearchFilter?: boolean;
  isConfirming?: boolean;
  isDriverLoading?: boolean;
}

export const TruckListFormWA = ({
  isConfirming,
  errors,
  truckListData,
  driverList,
  trailerList,
  setSearchDriver,
  isDriverLoading,
}: SubmitTruckFormProps) => {
  const classes = useStyles();
  const { validateTruckList } = useContext(ConfirmTruckListContext);
  const { isWA } = useReadUserInfo();

  useEffect(() => {
    validateTruckList(truckListData);
  }, [truckListData, validateTruckList]);

  const { pickedDrivers, pickedTrailers, trucksAssets } = useMemo(
    () => getPickedAssets(truckListData),
    [truckListData]
  );

  return (
    <>
      <Form id={FormIdList.truckList} className={classes.overflow}>
        <div>
          <Box overflow='auto'>
            {errors?.truckListData && (
              <Box
                display='flex'
                alignItems='center'
                mt={1}
                flexDirection='column'
              >
                <Typography className='error-text'>
                  {errors?.truckListData}
                </Typography>
              </Box>
            )}
            <Loader isLoading={isDriverLoading} />
            {truckListData ? (
              <FieldArray
                name={SubmitTruckFormNames.truckListData}
                render={() =>
                  Object.keys(truckListData).map((truckReg) => {
                    const assets = trucksAssets[truckReg];
                    const isAssetsEditable = !truckListData['createdOnLia'];
                    return (
                      <TruckCardWA
                        truckReg={truckReg}
                        key={truckReg}
                        isChecked={truckListData[truckReg].checked}
                        isAssetsEditable={isAssetsEditable}
                        driverOptionList={driverList}
                        trailerOptionList={trailerList}
                        pickedDrivers={pickedDrivers}
                        pickedTrailers={pickedTrailers}
                        isDriversDisabled={false}
                        isTrailersDisabled={false}
                        truckAssets={assets}
                        isConfirming={isConfirming}
                        hideTrailers={isWA}
                        setSearchDriver={setSearchDriver}
                        isDriverLoading={isDriverLoading}
                      />
                    );
                  })
                }
              />
            ) : (
              <Typography>No trucks available...</Typography>
            )}
          </Box>
        </div>
      </Form>
    </>
  );
};

import { makeStyles } from '@material-ui/core/styles';
import { STYLES } from 'utils';
import { COLORS } from 'utils';

export const useStyles = makeStyles({
	loadData: {
		...STYLES.loadData,
	},
	link: {
		margin: '16px 0',
		width: '100%',
	},
	buttonWrapper: {
		flex: '1 1 0',
		flexDirection: 'column',
		alignItems: 'center',
		'@media (max-width:360px)': {
			width: '100%',
		},
	},
	button: {
		whiteSpace: 'nowrap',
		'@media (max-width:360px)': {
			width: '100%',
		},
	},
	buttonIcon: {
		width: '1rem',
		height: '1rem',
		fill: 'currentColor',
	},
	item: {
		border: `1px solid ${COLORS.white}`,
		backgroundColor: COLORS.white,
		borderRadius: 3,
		display: 'flex',
		justifyContent: 'space-between',
		padding: '8px 12px',
		'& + &': {
			marginTop: 8,
		},
	},
	value: {
		marginLeft: '1rem',
		width: '50%',
		color: COLORS.primary,
	},
	customButton: {
		height: 40,
		textTransform: "capitalize",
		fontSize: 14,
		fontWeight: 500
	},
	label: {
		color: COLORS.primary,
		fontSize: 12,
		fontWeight: 300,
		lineHeight: '14.4px'
	},
	marginRight: {
		marginRight: 24
	},
	textCapitalized: {
		textTransform: 'capitalize'
	}
});

import { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { NavLink, useLocation } from 'react-router-dom';
import { useStyles } from './NavLinkList.styles';
import { AppPathList, AppRouteNameList } from 'router';
import { useTranslation } from 'react-i18next';
import { useMixpanel } from 'analytics';

export function NavLinkList() {
	const { t } = useTranslation('available');
	const { t: tM } = useTranslation('myjobs');
	const classes = useStyles();
	const mixpanel = useMixpanel();

	const { pathname } = useLocation();

	const isAvailableRoute = useMemo(() => pathname.includes(AppRouteNameList.available), [pathname]);

	const availableLinkList = useMemo(
		() => [
			{ title: t('new_job'), to: AppPathList.availableNew },
			{ title: t('submitted_job'), to: AppPathList.availableSubmitted },
			{ title: t('matched_job'), to: AppPathList.availableMatched },
		],
		[t]
	);

	const myJobLinkList = useMemo(
		() => [
			{ title: tM('confirmed'), to: AppPathList.jobsConfirmed },
			{ title: tM('on_journey'), to: AppPathList.jobsActive },
			{ title: tM('completed'), to: AppPathList.jobsCompleted },
		],
		[tM]
	);

	const linkList = useMemo(
		() => (isAvailableRoute ? availableLinkList : myJobLinkList),
		[isAvailableRoute, availableLinkList, myJobLinkList]
	);

  return (
		<Box
			display="flex"
			justifyContent="space-around"
			mt={2}
			className={classes.navWrapper}
			alignItems="center"
		>
			{linkList.map(({ to, title }) => (
				<NavLink
					key={title}
					to={to}
					className={classes.navLink}
					activeClassName={classes.navLinkActive}
					id="navlink"
					onClick={() => {
						mixpanel.track(`${title} jobs clicks`);
					}}
					data-qa={`qa-${title}`}
				>
					{title}
				</NavLink>
			))}
		</Box>
	);
}

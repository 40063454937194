import { useEffect, useState } from 'react';

export function useSessionStorage<T>(key: string, initialValue: T): [T, (value: T) => void] {
	// Get from local storage then
	// parse stored json or return initialValue
	const readValue = () => {
		// Prevent build error "window is undefined" but keep keep working
		if (typeof window === 'undefined') {
			return initialValue;
		}

		try {
			const item = window.sessionStorage.getItem(key);
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			console.warn(`Error reading sessionStorage key “${key}”:`, error);
			return initialValue;
		}
	};

	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState<T>(readValue);

	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to sessionStorage.
	const setValue = (value: T) => {
		// Prevent build error "window is undefined" but keeps working
		if (typeof window == 'undefined') {
			console.warn(
				`Tried setting sessionStorage key “${key}” even though environment is not a client`
			);
		}

		try {
			// Allow value to be a function so we have the same API as useState
			const newValue = value instanceof Function ? value(storedValue) : value;

			// Save to local storage
			window.sessionStorage.setItem(key, JSON.stringify(newValue));

			// Save state
			setStoredValue(newValue);

			// We dispatch a custom event so every useSessionStorage hook are notified
			window.dispatchEvent(new Event('session-storage'));
		} catch (error) {
			console.warn(`Error setting sessionStorage key “${key}”:`, error);
		}
	};

	useEffect(() => {
		setStoredValue(readValue());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const handleStorageChange = () => {
			setStoredValue(readValue());
		};

		// this only works for other documents, not the current one
		window.addEventListener('storage', handleStorageChange);

		// this is a custom event, triggered in writeValueTosessionStorage
		window.addEventListener('session-storage', handleStorageChange);

		return () => {
			window.removeEventListener('storage', handleStorageChange);
			window.removeEventListener('session-storage', handleStorageChange);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return [storedValue, setValue];
}

export default useSessionStorage;

import { IRoute } from 'models';
import { AppRouteNameList } from '../AppRouteNameList';

export const NotificationsRoute: IRoute = {
	name: AppRouteNameList.notifications,
	nested: [
		{
			name: AppRouteNameList.notifications,
		},
	],
};

import { DriverListTempWA } from 'components';
import { DriverBulkUpload } from 'containers';
import { useModalStateControls } from 'hooks';
import React from 'react';

export const DriverListWA = () => {
	const { isOpened, toggleModal } = useModalStateControls();

	return (
		<>
			<DriverListTempWA onAddAsset={toggleModal} />
			<DriverBulkUpload isOpened={isOpened} toggleModal={toggleModal} />
		</>
	);
};

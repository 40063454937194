import React, { PropsWithChildren } from 'react';
import { useStyles } from './RecordItem.styles';
import { useTranslation } from 'react-i18next';
import {Box, Typography} from '@material-ui/core';
import { COMMON } from 'utils';
import cn from 'classnames';
import { EditRecordButtons } from '../edit-record-buttons';

export interface IRecordItem {
	recordKey: string;
	value: string | React.ElementType;
}

export interface RecordList extends Array<IRecordItem> {}

export interface RecordItemProps extends IRecordItem {
	editable?: boolean;
	withTitle?: boolean;
}

export const RecordItem = ({
	recordKey,
	value,
	editable,
	withTitle,
}: PropsWithChildren<RecordItemProps>) => {
	const classes = useStyles();
	const { t } = useTranslation('common');

	return (
		<Box paddingY={1} display="flex" flexDirection="column" alignItems="start" alignContent="start" overflow="auto">
			{!withTitle && <Typography className={classes.key}>{t(recordKey)}</Typography>}
			<Typography
				className={cn(classes.value, {
					[classes.key]: editable,
				})}
			>
				{value ?? COMMON.noDataProvided}
			</Typography>
			{editable && <EditRecordButtons />}
		</Box>
	);
};

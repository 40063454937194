import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from 'utils';

export const useStyles = makeStyles({
    title: {
        fontWeight: 600,
        fontSize: 16,
        color: COLORS.textBlack,
        marginTop: 24,
        marginBottom: 28,
    },
    autocompleteField: {
        marginTop: 16,
        marginBottom: 8
    }
});

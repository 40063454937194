import React, { useCallback } from 'react';
import cn from 'classnames';
import { DetailsList } from '../details-list';
import { Box, Divider } from '@material-ui/core';
import { formatToDate } from 'helpers';
import { useTranslation } from 'react-i18next';
import { Order } from 'generate/types';
import { useReadUserInfo } from 'hooks';
import { useStyles } from './AvailableJobDetailsSubmitted.styles';
import { DestinationBlock } from '../destination-block';
import { DATES, COMMON } from 'utils';
import Typography from '@material-ui/core/Typography';
import { useMixpanel } from 'analytics';

interface AvailableJobDetailsProps extends Partial<Order> {
	detailsList?: Partial<Order>;
}

export function AvailableJobDetailsSubmitted({
	cargoType,
	origin,
	destination,
	deadlineToLift,
	currency,
	marketRate,
	detailsList,
	transporterPaymentTerms,
	trucksShortlisted,
	lroId,
	transporterRateType,
	isBid,
	bidPrice,
}: AvailableJobDetailsProps) {
	const { t } = useTranslation('available');
	const { isSA, isEA } = useReadUserInfo();
	const classes = useStyles();
	const mixpanel = useMixpanel();

	const ContactManagerBlock = useCallback(
		() =>
			isSA ? (
				<Typography className={classes.link}>
					{t('south_contact')}&nbsp;
					<a
						href={`mailto:${COMMON.imperialAccountManager}?subject=Query on WOID: ${lroId}`}
						onClick={() => {
							mixpanel.track(
								`Navigates to mailto:${COMMON.imperialAccountManager}?subject=Query on WOID: ${lroId}`
							);
						}}
					>
						{t('operations')}
					</a>
					&nbsp;{t('team')}
				</Typography>
			) : (
				<Typography className={classes.link}>{t('contact_manager')}</Typography>
			),
		[isSA, classes.link, t, lroId, mixpanel]
	);

	return (
		<div className="overflow">
			<Box textAlign="center">
				<b className={classes.confirmText}>
					{t('shortlisted_trucks', { truckCount: trucksShortlisted || 0 })}
				</b>
				<br />
				<b>{t('confirm_details_shortly')}</b>
				<ContactManagerBlock />
			</Box>
			<Divider />
			<h4 className={cn('title')}>{cargoType}</h4>
			<DestinationBlock from={origin} to={destination} showOrigin />
			<Divider />
			<Typography className={classes.loadData}>
				{t('ready_to_load')}: {formatToDate(deadlineToLift, DATES.loadDateFormatNoTime)}
			</Typography>
			<DetailsList {...detailsList} />
			<Box mt={2} textAlign="center">
				<span>{currency}&nbsp;</span>
				<span className="color-confirm">{isBid ? bidPrice : marketRate}</span>
				<span>{isEA && ' + VAT'}&nbsp;</span>
				<span>{transporterRateType}</span>
			</Box>
			<Box textAlign="center" mt={2}>
				<Typography className={classes.note}>
					{isSA ? t('job_details_note_start_digital_freight') : t('job_details_note_start')}
					<b className="color-confirm"> {transporterPaymentTerms} days, </b>
					{t('job_details_note_end')}
				</Typography>
			</Box>
		</div>
	);
}

import React, { forwardRef } from 'react';
import { Box } from '@material-ui/core';
import truckBackground from 'assets/svg/truck_bg.svg';
import { Loader } from 'components';
import cn from 'classnames';
import { useStyles } from './ItemList.styles';
import Typography from '@material-ui/core/Typography';

export interface ItemListProps<T = {}> {
	items: Array<T>;
	ItemTemplate: React.ElementType;
	isLoading?: boolean;
	keyName?: string;
	listItemClassname?: string;
	listClassname?: string;
	loadingPlaceholder?: string;
}

// eslint-disable-next-line
export const ItemList = forwardRef<HTMLUListElement, ItemListProps<Record<string, any>>>(
	(
		{
			items = [],
			ItemTemplate,
			isLoading = false,
			keyName,
			listItemClassname,
			listClassname,
			loadingPlaceholder = 'No items available yet',
			...props
		},
		ref
	) => {
		const classes = useStyles();
		return (
			<div className={classes.listContainer} data-qa="qa-list-items">
				<div>
					<ul className={cn(classes.list, listClassname)} ref={ref as any}>
						{items.map((item, index) => (
							<li
								key={keyName ? (item[keyName] as string) : index}
								className={cn(classes.listItem, listItemClassname)}
								data-qa={`qa-item-${index}`}
							>
								<ItemTemplate {...item} {...props} />
							</li>
						))}
					</ul>
					<Loader isLoading={isLoading}>
						{
							!items.length && (
								<>
									<Box className={classes.container}>
										<Box className={classes.imgContainer}>
											<img
												className={classes.truckBg}
												src={truckBackground}
												alt="no-data-available"
											/>
										</Box>
									</Box>
									<Typography className={classes.caption}>
										{loadingPlaceholder}
									</Typography>
								</>
							)
						}
					</Loader>
				</div>
			</div>
		);
	}
);

import { gql } from '@apollo/client';

export const getSupplyTrucks = gql`
	query getSupplyTrucks(
		$first: Int
		$after: String
		$before: String
		$search: String
		$transporterId: Int
    $active: ActiveEnum
    $status: MatchEnum
    $region: RegionEnum
	) {
		trucks(
			first: $first
			after: $after
			before: $before
			search: $search
			transporterId: $transporterId
      active: $active
      status: $status
      region: $region
      sort: CREATED_AT_DESC
		) {
			pageInfo {
				hasNextPage
				endCursor
				hasNextPage
			}
			edges {
				node {
					truckReg
					transporterId
					active
					id
					match
				}
			}
		}
	}
`;

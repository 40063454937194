/* eslint-disable */
// WARNING: This file is automatically generated. Do not edit.
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  GenericScalar: any;
  JSONDict: any;
  JSONString: any;
  Upload: any;
};

export enum ActionType {
  Allocate = 'ALLOCATE',
  Deallocate = 'DEALLOCATE',
  Match = 'MATCH',
  Unmatch = 'UNMATCH'
}

export type AddFuelStation = {
  __typename?: 'AddFuelStation';
  fuelStation?: Maybe<FuelStationType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AllocateAllocation = {
  __typename?: 'AllocateAllocation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ApproveFuelRequest = {
  __typename?: 'ApproveFuelRequest';
  success?: Maybe<Scalars['Boolean']>;
};

export type BulkUploadMutation = {
  __typename?: 'BulkUploadMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type CancelExtraneousCharge = {
  __typename?: 'CancelExtraneousCharge';
  extraneousCharge?: Maybe<ExtraneousChargesType>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CancelFuelRequest = {
  __typename?: 'CancelFuelRequest';
  fuelRequest?: Maybe<CreateFuelRequestType>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CancelOrder = {
  __typename?: 'CancelOrder';
  order?: Maybe<OrderType>;
};

export type CancelOrderInput = {
  cancellationReason: Scalars['String'];
  cancelledBy: Scalars['String'];
  source?: InputMaybe<Scalars['String']>;
};

export type CargoRateComparisonType = Node & {
  __typename?: 'CargoRateComparisonType';
  allClientMoves?: Maybe<Scalars['Int']>;
  cargoType: Scalars['String'];
  clientAvgCost?: Maybe<Scalars['Float']>;
  clientName?: Maybe<Scalars['String']>;
  clientTrakitId: Scalars['ID'];
  currency: Scalars['String'];
  geoCodeRoute?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lowerLimit?: Maybe<Scalars['Float']>;
  maxCost?: Maybe<Scalars['Float']>;
  median?: Maybe<Scalars['Float']>;
  minCost?: Maybe<Scalars['Float']>;
  rateType: Scalars['String'];
  route: Scalars['String'];
  upperLimit?: Maybe<Scalars['Float']>;
};

export type ClearingAgent = {
  __typename?: 'ClearingAgent';
  entid?: Maybe<Scalars['Int']>;
  entName?: Maybe<Scalars['String']>;
};

export type Client = {
  __typename?: 'Client';
  countryCode?: Maybe<Scalars['String']>;
  entid?: Maybe<Scalars['Int']>;
  entName?: Maybe<Scalars['String']>;
  fsid?: Maybe<Scalars['ID']>;
};

export type Commodity = {
  __typename?: 'Commodity';
  name?: Maybe<Scalars['String']>;
};

export type CompareCargoRate = {
  __typename?: 'CompareCargoRate';
  isWithinRange?: Maybe<Scalars['String']>;
  lowerRateLimit?: Maybe<Scalars['Float']>;
  medianRate?: Maybe<Scalars['Float']>;
  reason?: Maybe<Scalars['String']>;
  upperRateLimit?: Maybe<Scalars['Float']>;
};

export type ConfirmOrders = {
  __typename?: 'ConfirmOrders';
  confirmedOrderIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type Consignee = {
  __typename?: 'Consignee';
  entid?: Maybe<Scalars['Int']>;
  entName?: Maybe<Scalars['String']>;
};

export type Contact = {
  __typename?: 'Contact';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CreateDraftOrder = {
  __typename?: 'CreateDraftOrder';
  order?: Maybe<OrderType>;
};

export type CreateExtraneousCharge = {
  __typename?: 'CreateExtraneousCharge';
  extraneousCharge?: Maybe<ExtraneousChargesType>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateFuelRequest = {
  __typename?: 'CreateFuelRequest';
  fuelRequest?: Maybe<CreateFuelRequestType>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateFuelRequestInput = {
  fsCountry?: InputMaybe<Scalars['String']>;
  fuelreqtra?: InputMaybe<Scalars['String']>;
  fuelRequestAmount?: InputMaybe<Scalars['Int']>;
  fuelStationCode?: InputMaybe<Scalars['String']>;
  fuelStationId?: InputMaybe<Scalars['Int']>;
  fuelType?: InputMaybe<Scalars['String']>;
  lroId?: InputMaybe<Scalars['Int']>;
  transporterId?: InputMaybe<Scalars['Int']>;
  truckReg?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type CreateFuelRequestType = {
  __typename?: 'CreateFuelRequestType';
  amountFuelled?: Maybe<Scalars['Float']>;
  approvalDate?: Maybe<Scalars['DateTime']>;
  approvalState?: Maybe<Scalars['String']>;
  cancelled?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  deplorifs?: Maybe<Scalars['String']>;
  fsCountry?: Maybe<Scalars['String']>;
  fsInvoice?: Maybe<Scalars['String']>;
  fuelDataValidated?: Maybe<Scalars['DateTime']>;
  fuelLpo?: Maybe<Scalars['String']>;
  fuelreqsent?: Maybe<Scalars['DateTime']>;
  fuelreqtra?: Maybe<Scalars['DateTime']>;
  fuelRequestAmount?: Maybe<Scalars['Float']>;
  fuelstation?: Maybe<FuelStationType>;
  fuelStation?: Maybe<Scalars['String']>;
  fuelStationCode?: Maybe<Scalars['String']>;
  fuelStationId?: Maybe<Scalars['Int']>;
  fuelType?: Maybe<Scalars['String']>;
  lroId?: Maybe<Scalars['Int']>;
  move?: Maybe<MoveType>;
  moveId: Scalars['ID'];
  transporter?: Maybe<TransportersType>;
  transporterId?: Maybe<Scalars['Int']>;
  transporterInvoice?: Maybe<Scalars['String']>;
  truckReg?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type CreateIssue = {
  __typename?: 'CreateIssue';
  issue?: Maybe<CreateIssueType>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type CreateIssueInput = {
  assignee?: InputMaybe<Scalars['String']>;
  businessDomainId: Scalars['Int'];
  details?: InputMaybe<Scalars['String']>;
  moveId: Scalars['String'];
  reportedAt?: InputMaybe<Scalars['DateTime']>;
  typeId: Scalars['Int'];
  userId?: InputMaybe<Scalars['String']>;
  userName?: InputMaybe<Scalars['String']>;
};

export type CreateIssueType = {
  __typename?: 'CreateIssueType';
  assignee?: Maybe<Scalars['String']>;
  businessDomainId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isResolved?: Maybe<Scalars['Boolean']>;
  issueType?: Maybe<IssueTypesType>;
  moveId?: Maybe<Scalars['String']>;
  reportedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  typeId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type CreateLiaOrder = {
  __typename?: 'CreateLiaOrder';
  order?: Maybe<LiaOrderType>;
};

export type CreateMove = {
  __typename?: 'CreateMove';
  move?: Maybe<MoveType>;
};

export type CreateMovesChekPoints = {
  __typename?: 'CreateMovesChekPoints';
  moveCheckpoint?: Maybe<MoveCheckPointsType>;
};

export type CreateOrder = {
  __typename?: 'CreateOrder';
  order?: Maybe<OrderType>;
};

export type CreateOrderInput = {
  allocation?: InputMaybe<Scalars['JSONString']>;
  amount?: InputMaybe<Scalars['Int']>;
  clientId?: InputMaybe<Scalars['ID']>;
  customField?: InputMaybe<Scalars['JSONString']>;
  dateDeadlineToLift?: InputMaybe<Scalars['String']>;
  dateLoading?: InputMaybe<Scalars['String']>;
  destination: Scalars['ID'];
  isBackhaul?: InputMaybe<Scalars['Boolean']>;
  lroId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['ID']>;
  source: Scalars['String'];
  state: Scalars['String'];
};

export type CreateTemplate = {
  __typename?: 'CreateTemplate';
  order?: Maybe<OrderType>;
};

export type CreateTruckSupply = {
  __typename?: 'CreateTruckSupply';
  truckSupply?: Maybe<TruckSupplyType>;
};

export type CurrenciesType = {
  __typename?: 'CurrenciesType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type CustomOrder = Node & {
  __typename?: 'CustomOrder';
  cargoType?: Maybe<Scalars['String']>;
  clearingAgent?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['Int']>;
  commodity?: Maybe<Scalars['String']>;
  confirmedLoadingDate?: Maybe<Scalars['Boolean']>;
  consignee?: Maybe<Scalars['String']>;
  containerDetails?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateDeadlineToLift?: Maybe<Scalars['DateTime']>;
  dateLoading?: Maybe<Scalars['DateTime']>;
  daysToDeadline?: Maybe<Scalars['Int']>;
  destination?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isBackhaul?: Maybe<Scalars['Boolean']>;
  lane?: Maybe<Scalars['String']>;
  loadingContact?: Maybe<Scalars['String']>;
  lroId?: Maybe<Scalars['ID']>;
  noGoTrailerTypes?: Maybe<Scalars['String']>;
  offloadingContact?: Maybe<Scalars['String']>;
  offloadingHours?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['ID']>;
  origin?: Maybe<Scalars['String']>;
  originId?: Maybe<Scalars['String']>;
  outstandingTrucks?: Maybe<Scalars['Int']>;
  outstandingWeight?: Maybe<Scalars['Int']>;
  packagingType?: Maybe<Scalars['String']>;
  pipeline?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  totalWeight?: Maybe<Scalars['Int']>;
  trailerTypes?: Maybe<Scalars['String']>;
  trucksMatched?: Maybe<Scalars['Int']>;
  trucksNeeded?: Maybe<Scalars['Int']>;
  vessel?: Maybe<Scalars['String']>;
};

export type CustomOrderConnection = {
  __typename?: 'CustomOrderConnection';
  edges: Array<Maybe<CustomOrderEdge>>;
  pageInfo: PageInfo;
};

export type CustomOrderEdge = {
  __typename?: 'CustomOrderEdge';
  cursor: Scalars['String'];
  node?: Maybe<CustomOrder>;
};

export type DeallocateAllocation = {
  __typename?: 'DeallocateAllocation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type Depot = {
  __typename?: 'Depot';
  country?: Maybe<Scalars['String']>;
  geoCode?: Maybe<Scalars['String']>;
  geoid?: Maybe<Scalars['Int']>;
  geoName?: Maybe<Scalars['String']>;
};

export type DocumentTypesType = {
  __typename?: 'DocumentTypesType';
  documentTypes?: Maybe<MoveDocumentTypeConnection>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};


export type DocumentTypesTypeDocumentTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DriverType = {
  __typename?: 'DriverType';
  id: Scalars['String'];
  idNumber?: Maybe<Scalars['String']>;
  names?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  portPass?: Maybe<Scalars['String']>;
  portPassExpiryDate?: Maybe<Scalars['DateTime']>;
  portPassPermanentLori?: Maybe<Scalars['String']>;
  portPassTempLori?: Maybe<Scalars['String']>;
};

export type EditExtraneousCharge = {
  __typename?: 'EditExtraneousCharge';
  extraneousCharge?: Maybe<ExtraneousChargesType>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type EmptyReturnDestinationDepot = {
  __typename?: 'EmptyReturnDestinationDepot';
  name?: Maybe<Scalars['String']>;
};

export type ExtraneousChargeInput = {
  amount?: InputMaybe<Scalars['Float']>;
  currencyId?: InputMaybe<Scalars['Int']>;
  moveId?: InputMaybe<Scalars['Int']>;
  payeeId?: InputMaybe<Scalars['Int']>;
  payerId?: InputMaybe<Scalars['Int']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type ExtraneousChargesType = {
  __typename?: 'ExtraneousChargesType';
  amount?: Maybe<Scalars['Float']>;
  cancelled?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrenciesType>;
  currencyId?: Maybe<Scalars['Int']>;
  id: Scalars['Float'];
  move?: Maybe<MoveType>;
  moveId?: Maybe<Scalars['Float']>;
  payee?: Maybe<PayerPayeeType>;
  payeeId?: Maybe<Scalars['Int']>;
  payer?: Maybe<PayerPayeeType>;
  payerId?: Maybe<Scalars['Int']>;
  type?: Maybe<ExtraneousChargeTypesType>;
  typeId?: Maybe<Scalars['Int']>;
};

export type ExtraneousChargeTypesType = {
  __typename?: 'ExtraneousChargeTypesType';
  extraneousChargeTypes?: Maybe<Array<Maybe<ExtraneousChargesType>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type FieldUpadateEvent = {
  __typename?: 'FieldUpadateEvent';
  clerkId?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
  moveId?: Maybe<Scalars['String']>;
  timeCode?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type FinancingFuelBulkUploadMutation = {
  __typename?: 'FinancingFuelBulkUploadMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type FuelingEventConnection = {
  __typename?: 'FuelingEventConnection';
  edges: Array<Maybe<FuelingEventEdge>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type FuelingEventEdge = {
  __typename?: 'FuelingEventEdge';
  cursor: Scalars['String'];
  node?: Maybe<FuelingEventType>;
};

export type FuelingEventType = Node & {
  __typename?: 'FuelingEventType';
  amountFuelled?: Maybe<Scalars['Float']>;
  approvalDate?: Maybe<Scalars['DateTime']>;
  approvalState?: Maybe<Scalars['String']>;
  cancelled?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  deplorifs?: Maybe<Scalars['String']>;
  fsCountry?: Maybe<Scalars['String']>;
  fsInvoice?: Maybe<Scalars['String']>;
  fuelApprovedAmount?: Maybe<Scalars['Int']>;
  fuelCharge?: Maybe<Scalars['Int']>;
  fuelDataValidated?: Maybe<Scalars['DateTime']>;
  fuelLpo?: Maybe<Scalars['String']>;
  fuelRates?: Maybe<FuelRatesType>;
  fuelreqsent?: Maybe<Scalars['DateTime']>;
  fuelreqtra?: Maybe<Scalars['DateTime']>;
  fuelRequestAmount?: Maybe<Scalars['Float']>;
  fuelstation?: Maybe<FuelStationType>;
  fuelStation?: Maybe<Scalars['String']>;
  fuelStationCode?: Maybe<Scalars['String']>;
  fuelStationId?: Maybe<Scalars['Int']>;
  fuelType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lroId?: Maybe<Scalars['Int']>;
  move?: Maybe<MoveType>;
  moveId: Scalars['ID'];
  percentage?: Maybe<Scalars['Int']>;
  percentageOfTransporterCost?: Maybe<Scalars['Int']>;
  transporter?: Maybe<TransportersType>;
  transporterId?: Maybe<Scalars['Int']>;
  transporterInvoice?: Maybe<Scalars['String']>;
  truckReg?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export enum FuelingEventTypeSortEnum {
  AmountFuelledAsc = 'AMOUNT_FUELLED_ASC',
  AmountFuelledDesc = 'AMOUNT_FUELLED_DESC',
  ApprovalDateAsc = 'APPROVAL_DATE_ASC',
  ApprovalDateDesc = 'APPROVAL_DATE_DESC',
  ApprovalStateAsc = 'APPROVAL_STATE_ASC',
  ApprovalStateDesc = 'APPROVAL_STATE_DESC',
  CancelledAsc = 'CANCELLED_ASC',
  CancelledAtAsc = 'CANCELLED_AT_ASC',
  CancelledAtDesc = 'CANCELLED_AT_DESC',
  CancelledDesc = 'CANCELLED_DESC',
  DeplorifsAsc = 'DEPLORIFS_ASC',
  DeplorifsDesc = 'DEPLORIFS_DESC',
  FsCountryAsc = 'FS_COUNTRY_ASC',
  FsCountryDesc = 'FS_COUNTRY_DESC',
  FsInvoiceAsc = 'FS_INVOICE_ASC',
  FsInvoiceDesc = 'FS_INVOICE_DESC',
  FuelDataValidatedAsc = 'FUEL_DATA_VALIDATED_ASC',
  FuelDataValidatedDesc = 'FUEL_DATA_VALIDATED_DESC',
  FuelLpoAsc = 'FUEL_LPO_ASC',
  FuelLpoDesc = 'FUEL_LPO_DESC',
  FuelRequestAmountAsc = 'FUEL_REQUEST_AMOUNT_ASC',
  FuelRequestAmountDesc = 'FUEL_REQUEST_AMOUNT_DESC',
  FuelStationAsc = 'FUEL_STATION_ASC',
  FuelStationCodeAsc = 'FUEL_STATION_CODE_ASC',
  FuelStationCodeDesc = 'FUEL_STATION_CODE_DESC',
  FuelStationDesc = 'FUEL_STATION_DESC',
  FuelStationIdAsc = 'FUEL_STATION_ID_ASC',
  FuelStationIdDesc = 'FUEL_STATION_ID_DESC',
  FuelTypeAsc = 'FUEL_TYPE_ASC',
  FuelTypeDesc = 'FUEL_TYPE_DESC',
  FuelreqsentAsc = 'FUELREQSENT_ASC',
  FuelreqsentDesc = 'FUELREQSENT_DESC',
  FuelreqtraAsc = 'FUELREQTRA_ASC',
  FuelreqtraDesc = 'FUELREQTRA_DESC',
  LroIdAsc = 'LRO_ID_ASC',
  LroIdDesc = 'LRO_ID_DESC',
  MoveIdAsc = 'MOVE_ID_ASC',
  MoveIdDesc = 'MOVE_ID_DESC',
  TransporterIdAsc = 'TRANSPORTER_ID_ASC',
  TransporterIdDesc = 'TRANSPORTER_ID_DESC',
  TransporterInvoiceAsc = 'TRANSPORTER_INVOICE_ASC',
  TransporterInvoiceDesc = 'TRANSPORTER_INVOICE_DESC',
  TruckRegAsc = 'TRUCK_REG_ASC',
  TruckRegDesc = 'TRUCK_REG_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC'
}

export type FuelRateBulkUploadMutation = {
  __typename?: 'FuelRateBulkUploadMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type FuelRatesType = {
  __typename?: 'FuelRatesType';
  city: Scalars['String'];
  cityLevies?: Maybe<Scalars['Int']>;
  fuelStation: Scalars['String'];
  fuelType: Scalars['String'];
  newStartDate: Scalars['DateTime'];
  previousStartDate?: Maybe<Scalars['DateTime']>;
  pricePerLitre?: Maybe<Scalars['Float']>;
  vatableExclusive?: Maybe<Scalars['Int']>;
};

export type FuelStationInput = {
  city?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  geoId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['ID']>;
  ownerName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
};

export type FuelStationType = {
  __typename?: 'FuelStationType';
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fuelStationDiscount?: Maybe<Scalars['Int']>;
  geoId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['Int']>;
  ownerName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GeoType = {
  geoCode?: InputMaybe<Scalars['String']>;
  geoId?: InputMaybe<Scalars['Int']>;
};

export type GlobalSearchType = {
  clientIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  containerNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  depots?: InputMaybe<Array<InputMaybe<GeoType>>>;
  lroIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transporterIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  truckRegs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  woIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type IssueType = {
  __typename?: 'IssueType';
  assignee?: Maybe<Scalars['String']>;
  businessDomainId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isResolved?: Maybe<Scalars['Boolean']>;
  issueType?: Maybe<IssueTypesType>;
  moveId?: Maybe<Scalars['String']>;
  reportedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  typeId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type IssueTypeCategoryType = {
  __typename?: 'IssueTypeCategoryType';
  id: Scalars['ID'];
  issueTypeCategories?: Maybe<Array<Maybe<IssueTypesType>>>;
  name?: Maybe<Scalars['String']>;
};

export type IssueTypesType = {
  __typename?: 'IssueTypesType';
  id: Scalars['ID'];
  issueType?: Maybe<Scalars['String']>;
  issueTypeCategory?: Maybe<IssueTypeCategoryType>;
  issueTypeCategoryId?: Maybe<Scalars['Int']>;
  issueTypes?: Maybe<Array<Maybe<IssueType>>>;
};

export type LiaOderInputType = {
  cargoRate?: InputMaybe<Scalars['String']>;
  cargoRateType?: InputMaybe<Scalars['String']>;
  cargoTypeId?: InputMaybe<Scalars['String']>;
  clearingAgentId?: InputMaybe<Scalars['Int']>;
  clientId?: InputMaybe<Scalars['ID']>;
  commodity?: InputMaybe<Scalars['String']>;
  commodityType?: InputMaybe<Scalars['Int']>;
  consigneeId?: InputMaybe<Scalars['Int']>;
  consignmentMetricTons?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  createdFrom?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  deadlineToLift?: InputMaybe<Scalars['String']>;
  destinationCode?: InputMaybe<Scalars['String']>;
  issues?: InputMaybe<Scalars['String']>;
  loadingContactId?: InputMaybe<Scalars['Int']>;
  loadingDate?: InputMaybe<Scalars['String']>;
  lroId?: InputMaybe<Scalars['String']>;
  noGoTypes?: InputMaybe<Scalars['String']>;
  offloadingHours?: InputMaybe<Scalars['String']>;
  originCode?: InputMaybe<Scalars['String']>;
  originCountry?: InputMaybe<Scalars['String']>;
  truckPreference?: InputMaybe<Scalars['String']>;
  vesselId?: InputMaybe<Scalars['Int']>;
  workflowId?: InputMaybe<Scalars['Int']>;
};

export type LiaOrderType = Node & {
  __typename?: 'LiaOrderType';
  cargoRate?: Maybe<Scalars['String']>;
  cargoRateType?: Maybe<Scalars['String']>;
  cargoTypeId?: Maybe<Scalars['String']>;
  clearingAgentId?: Maybe<Scalars['Float']>;
  clientId?: Maybe<Scalars['Float']>;
  commodity?: Maybe<Scalars['String']>;
  commodityType?: Maybe<Scalars['Int']>;
  consigneeId?: Maybe<Scalars['Float']>;
  consignmentMetricTons?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  createdFrom?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  deadlineToLift?: Maybe<Scalars['DateTime']>;
  destinationCode: Scalars['String'];
  destinationCodeId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  issues?: Maybe<Scalars['String']>;
  loadingContactId?: Maybe<Scalars['Float']>;
  loadingDate?: Maybe<Scalars['DateTime']>;
  lroId: Scalars['String'];
  moves?: Maybe<MoveTypeConnection>;
  noGoTypes?: Maybe<Scalars['String']>;
  offloadingHours?: Maybe<Scalars['String']>;
  originCode?: Maybe<Scalars['String']>;
  originCodeId?: Maybe<Scalars['Int']>;
  originCountry?: Maybe<Scalars['String']>;
  truckPreference?: Maybe<Scalars['String']>;
  vesselId?: Maybe<Scalars['Float']>;
  workflowId?: Maybe<Scalars['Int']>;
};


export type LiaOrderTypeMovesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type LiaOrderTypeConnection = {
  __typename?: 'LiaOrderTypeConnection';
  edges: Array<Maybe<LiaOrderTypeEdge>>;
  pageInfo: PageInfo;
};

export type LiaOrderTypeEdge = {
  __typename?: 'LiaOrderTypeEdge';
  cursor: Scalars['String'];
  node?: Maybe<LiaOrderType>;
};

export enum LiaOrderTypeSortEnum {
  CargoRateAsc = 'CARGO_RATE_ASC',
  CargoRateDesc = 'CARGO_RATE_DESC',
  CargoRateTypeAsc = 'CARGO_RATE_TYPE_ASC',
  CargoRateTypeDesc = 'CARGO_RATE_TYPE_DESC',
  CargoTypeIdAsc = 'CARGO_TYPE_ID_ASC',
  CargoTypeIdDesc = 'CARGO_TYPE_ID_DESC',
  ClearingAgentIdAsc = 'CLEARING_AGENT_ID_ASC',
  ClearingAgentIdDesc = 'CLEARING_AGENT_ID_DESC',
  ClientIdAsc = 'CLIENT_ID_ASC',
  ClientIdDesc = 'CLIENT_ID_DESC',
  CommodityAsc = 'COMMODITY_ASC',
  CommodityDesc = 'COMMODITY_DESC',
  CommodityTypeAsc = 'COMMODITY_TYPE_ASC',
  CommodityTypeDesc = 'COMMODITY_TYPE_DESC',
  ConsigneeIdAsc = 'CONSIGNEE_ID_ASC',
  ConsigneeIdDesc = 'CONSIGNEE_ID_DESC',
  ConsignmentMetricTonsAsc = 'CONSIGNMENT_METRIC_TONS_ASC',
  ConsignmentMetricTonsDesc = 'CONSIGNMENT_METRIC_TONS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CreatedFromAsc = 'CREATED_FROM_ASC',
  CreatedFromDesc = 'CREATED_FROM_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  DeadlineToLiftAsc = 'DEADLINE_TO_LIFT_ASC',
  DeadlineToLiftDesc = 'DEADLINE_TO_LIFT_DESC',
  DestinationCodeAsc = 'DESTINATION_CODE_ASC',
  DestinationCodeDesc = 'DESTINATION_CODE_DESC',
  DestinationCodeIdAsc = 'DESTINATION_CODE_ID_ASC',
  DestinationCodeIdDesc = 'DESTINATION_CODE_ID_DESC',
  IssuesAsc = 'ISSUES_ASC',
  IssuesDesc = 'ISSUES_DESC',
  LoadingContactIdAsc = 'LOADING_CONTACT_ID_ASC',
  LoadingContactIdDesc = 'LOADING_CONTACT_ID_DESC',
  LoadingDateAsc = 'LOADING_DATE_ASC',
  LoadingDateDesc = 'LOADING_DATE_DESC',
  LroIdAsc = 'LRO_ID_ASC',
  LroIdDesc = 'LRO_ID_DESC',
  NoGoTypesAsc = 'NO_GO_TYPES_ASC',
  NoGoTypesDesc = 'NO_GO_TYPES_DESC',
  OffloadingHoursAsc = 'OFFLOADING_HOURS_ASC',
  OffloadingHoursDesc = 'OFFLOADING_HOURS_DESC',
  OriginCodeAsc = 'ORIGIN_CODE_ASC',
  OriginCodeDesc = 'ORIGIN_CODE_DESC',
  OriginCodeIdAsc = 'ORIGIN_CODE_ID_ASC',
  OriginCodeIdDesc = 'ORIGIN_CODE_ID_DESC',
  OriginCountryAsc = 'ORIGIN_COUNTRY_ASC',
  OriginCountryDesc = 'ORIGIN_COUNTRY_DESC',
  TruckPreferenceAsc = 'TRUCK_PREFERENCE_ASC',
  TruckPreferenceDesc = 'TRUCK_PREFERENCE_DESC',
  VesselIdAsc = 'VESSEL_ID_ASC',
  VesselIdDesc = 'VESSEL_ID_DESC',
  WorkflowIdAsc = 'WORKFLOW_ID_ASC',
  WorkflowIdDesc = 'WORKFLOW_ID_DESC'
}

export type LinkAssetToTruck = {
  __typename?: 'LinkAssetToTruck';
  ok?: Maybe<Scalars['Boolean']>;
};

export type MatchingActionType = Node & {
  __typename?: 'MatchingActionType';
  actionedAt?: Maybe<Scalars['DateTime']>;
  actionType: ActionType;
  id: Scalars['ID'];
  lroId: Scalars['String'];
  payload?: Maybe<Scalars['JSONDict']>;
  supplyId: Scalars['Int'];
  truckSerial: Scalars['String'];
  userEmail: Scalars['String'];
  woid?: Maybe<Scalars['String']>;
};

export type MatchingActionTypeConnection = {
  __typename?: 'MatchingActionTypeConnection';
  edges: Array<Maybe<MatchingActionTypeEdge>>;
  pageInfo: PageInfo;
};

export type MatchingActionTypeEdge = {
  __typename?: 'MatchingActionTypeEdge';
  cursor: Scalars['String'];
  node?: Maybe<MatchingActionType>;
};

export enum MatchingActionTypeSortEnum {
  ActionTypeAsc = 'ACTION_TYPE_ASC',
  ActionTypeDesc = 'ACTION_TYPE_DESC',
  ActionedAtAsc = 'ACTIONED_AT_ASC',
  ActionedAtDesc = 'ACTIONED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LroIdAsc = 'LRO_ID_ASC',
  LroIdDesc = 'LRO_ID_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  SupplyIdAsc = 'SUPPLY_ID_ASC',
  SupplyIdDesc = 'SUPPLY_ID_DESC',
  TruckSerialAsc = 'TRUCK_SERIAL_ASC',
  TruckSerialDesc = 'TRUCK_SERIAL_DESC',
  UserEmailAsc = 'USER_EMAIL_ASC',
  UserEmailDesc = 'USER_EMAIL_DESC',
  WoidAsc = 'WOID_ASC',
  WoidDesc = 'WOID_DESC'
}

export type MatchTrucks = {
  __typename?: 'MatchTrucks';
  ok?: Maybe<Scalars['Boolean']>;
};

export type MoveCheckPointsInput = {
  arbod1?: InputMaybe<Scalars['DateTime']>;
  arbod2?: InputMaybe<Scalars['DateTime']>;
  arbod3?: InputMaybe<Scalars['DateTime']>;
  arbod4?: InputMaybe<Scalars['DateTime']>;
  ardes1?: InputMaybe<Scalars['DateTime']>;
  ardes2?: InputMaybe<Scalars['DateTime']>;
  arload1?: InputMaybe<Scalars['DateTime']>;
  assetname?: InputMaybe<Scalars['String']>;
  barcoder?: InputMaybe<Scalars['DateTime']>;
  carrtder?: InputMaybe<Scalars['DateTime']>;
  cdetder?: InputMaybe<Scalars['DateTime']>;
  clearstn1?: InputMaybe<Scalars['DateTime']>;
  clearstn2?: InputMaybe<Scalars['DateTime']>;
  depbod1?: InputMaybe<Scalars['DateTime']>;
  depbod2?: InputMaybe<Scalars['DateTime']>;
  depbod3?: InputMaybe<Scalars['DateTime']>;
  depbod4?: InputMaybe<Scalars['DateTime']>;
  etaToLoading?: InputMaybe<Scalars['String']>;
  gainlp1?: InputMaybe<Scalars['DateTime']>;
  gainlp2?: InputMaybe<Scalars['DateTime']>;
  gaipplp1?: InputMaybe<Scalars['DateTime']>;
  gaoutlp1?: InputMaybe<Scalars['DateTime']>;
  gaoutlp2?: InputMaybe<Scalars['DateTime']>;
  gaoutppl1?: InputMaybe<Scalars['DateTime']>;
  gbhlp?: InputMaybe<Scalars['DateTime']>;
  gimy1?: InputMaybe<Scalars['DateTime']>;
  gindest1?: InputMaybe<Scalars['DateTime']>;
  gindest2?: InputMaybe<Scalars['DateTime']>;
  gomy1?: InputMaybe<Scalars['DateTime']>;
  goutdes1?: InputMaybe<Scalars['DateTime']>;
  goutdes2?: InputMaybe<Scalars['DateTime']>;
  icdslipdiscus?: InputMaybe<Scalars['DateTime']>;
  icdsliprecd?: InputMaybe<Scalars['DateTime']>;
  icdsliprecf?: InputMaybe<Scalars['DateTime']>;
  icdsliprechq?: InputMaybe<Scalars['DateTime']>;
  invpdcust?: InputMaybe<Scalars['DateTime']>;
  invtocust?: InputMaybe<Scalars['DateTime']>;
  loadingValidationDate?: InputMaybe<Scalars['DateTime']>;
  lroId?: InputMaybe<Scalars['String']>;
  match?: InputMaybe<Scalars['DateTime']>;
  moveId?: InputMaybe<Scalars['ID']>;
  podcpr?: InputMaybe<Scalars['DateTime']>;
  podrhq?: InputMaybe<Scalars['DateTime']>;
  rvsinvdate?: InputMaybe<Scalars['DateTime']>;
  t1rec?: InputMaybe<Scalars['DateTime']>;
  traninvrec?: InputMaybe<Scalars['DateTime']>;
  tranpd?: InputMaybe<Scalars['DateTime']>;
  vempreoffinfo?: InputMaybe<Scalars['DateTime']>;
  wbslrhq?: InputMaybe<Scalars['DateTime']>;
  wbsofhq?: InputMaybe<Scalars['DateTime']>;
};

export type MoveCheckPointsType = Node & {
  __typename?: 'MoveCheckPointsType';
  arbod1?: Maybe<Scalars['DateTime']>;
  arbod2?: Maybe<Scalars['DateTime']>;
  arbod3?: Maybe<Scalars['DateTime']>;
  arbod4?: Maybe<Scalars['DateTime']>;
  ardes1?: Maybe<Scalars['DateTime']>;
  ardes2?: Maybe<Scalars['DateTime']>;
  arload1?: Maybe<Scalars['DateTime']>;
  assetname?: Maybe<Scalars['String']>;
  carrtder?: Maybe<Scalars['DateTime']>;
  cdetder?: Maybe<Scalars['DateTime']>;
  clearstn1?: Maybe<Scalars['DateTime']>;
  clearstn2?: Maybe<Scalars['DateTime']>;
  depbod1?: Maybe<Scalars['DateTime']>;
  depbod2?: Maybe<Scalars['DateTime']>;
  depbod3?: Maybe<Scalars['DateTime']>;
  depbod4?: Maybe<Scalars['DateTime']>;
  endJourneyValidationDate?: Maybe<Scalars['DateTime']>;
  gainlp1?: Maybe<Scalars['DateTime']>;
  gainlp2?: Maybe<Scalars['DateTime']>;
  gaipplp1?: Maybe<Scalars['DateTime']>;
  gaoutlp1?: Maybe<Scalars['DateTime']>;
  gaoutlp2?: Maybe<Scalars['DateTime']>;
  gaoutppl1?: Maybe<Scalars['DateTime']>;
  gbhlp?: Maybe<Scalars['DateTime']>;
  gimy1?: Maybe<Scalars['DateTime']>;
  gindest1?: Maybe<Scalars['DateTime']>;
  gindest2?: Maybe<Scalars['DateTime']>;
  gomy1?: Maybe<Scalars['DateTime']>;
  goutdes1?: Maybe<Scalars['DateTime']>;
  goutdes2?: Maybe<Scalars['DateTime']>;
  icdslipdiscus?: Maybe<Scalars['DateTime']>;
  icdsliprecd?: Maybe<Scalars['DateTime']>;
  icdsliprecf?: Maybe<Scalars['DateTime']>;
  icdsliprechq?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  invpdcust?: Maybe<Scalars['DateTime']>;
  invtocust?: Maybe<Scalars['DateTime']>;
  loadingValidationDate?: Maybe<Scalars['DateTime']>;
  lroId?: Maybe<Scalars['String']>;
  match?: Maybe<Scalars['DateTime']>;
  moveId: Scalars['Float'];
  podcpr?: Maybe<Scalars['DateTime']>;
  podrhq?: Maybe<Scalars['DateTime']>;
  rvsinvdate?: Maybe<Scalars['DateTime']>;
  t1rec?: Maybe<Scalars['DateTime']>;
  traninvrec?: Maybe<Scalars['DateTime']>;
  tranpd?: Maybe<Scalars['DateTime']>;
  vempreoffinfo?: Maybe<Scalars['DateTime']>;
  wbslrhq?: Maybe<Scalars['DateTime']>;
  wbsofhq?: Maybe<Scalars['DateTime']>;
};

export type MoveDocumentInput = {
  documentTypeId: Scalars['Int'];
  moveId: Scalars['String'];
};

export type MoveDocumentType = Node & {
  __typename?: 'MoveDocumentType';
  documentType?: Maybe<DocumentTypesType>;
  documentTypeId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  moveId?: Maybe<Scalars['String']>;
  uploadDt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type MoveDocumentTypeConnection = {
  __typename?: 'MoveDocumentTypeConnection';
  edges: Array<Maybe<MoveDocumentTypeEdge>>;
  pageInfo: PageInfo;
};

export type MoveDocumentTypeEdge = {
  __typename?: 'MoveDocumentTypeEdge';
  cursor: Scalars['String'];
  node?: Maybe<MoveDocumentType>;
};

export type MoveInput = {
  bagsdel?: InputMaybe<Scalars['String']>;
  bagsLoaded?: InputMaybe<Scalars['String']>;
  cargoInvoiceNumber?: InputMaybe<Scalars['String']>;
  cargoRate?: InputMaybe<Scalars['String']>;
  cargoTypeId?: InputMaybe<Scalars['String']>;
  clientCurrency?: InputMaybe<Scalars['String']>;
  clientDeliveryNote?: InputMaybe<Scalars['String']>;
  commodityId?: InputMaybe<Scalars['String']>;
  consigneeAddress?: InputMaybe<Scalars['String']>;
  consigneeId?: InputMaybe<Scalars['Int']>;
  containerGrossWeight?: InputMaybe<Scalars['Float']>;
  containerNumber?: InputMaybe<Scalars['String']>;
  containerNumber2?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  dambags?: InputMaybe<Scalars['String']>;
  deliveryConfirmationStatus?: InputMaybe<Scalars['String']>;
  delqty?: InputMaybe<Scalars['String']>;
  destination2Code?: InputMaybe<Scalars['String']>;
  destination3Code?: InputMaybe<Scalars['String']>;
  destinationCode?: InputMaybe<Scalars['String']>;
  driverId?: InputMaybe<Scalars['String']>;
  driverPhone?: InputMaybe<Scalars['String']>;
  emptyReturnDestinationId?: InputMaybe<Scalars['String']>;
  etaToLoading?: InputMaybe<Scalars['String']>;
  etaToOffloading?: InputMaybe<Scalars['String']>;
  gatePassNumber?: InputMaybe<Scalars['String']>;
  guaranteeFormExpiryDate?: InputMaybe<Scalars['String']>;
  importExport?: InputMaybe<Scalars['String']>;
  lastKnownLocation?: InputMaybe<Scalars['String']>;
  llon?: InputMaybe<Scalars['String']>;
  loadbico?: InputMaybe<Scalars['String']>;
  loadingContactId?: InputMaybe<Scalars['String']>;
  lroId?: InputMaybe<Scalars['String']>;
  misbags?: InputMaybe<Scalars['String']>;
  moveId?: InputMaybe<Scalars['ID']>;
  offloadedTonnage?: InputMaybe<Scalars['String']>;
  offloadingContactId?: InputMaybe<Scalars['String']>;
  originCode?: InputMaybe<Scalars['String']>;
  portInterchangeSlipNo?: InputMaybe<Scalars['String']>;
  primaryDeliveryConfirmationRecipientName?: InputMaybe<Scalars['String']>;
  primaryDeliveryConfirmationRecipientPhone?: InputMaybe<Scalars['String']>;
  qtyLoaded?: InputMaybe<Scalars['String']>;
  sealNumber?: InputMaybe<Scalars['String']>;
  secondaryDeliveryConfirmationRecipientName?: InputMaybe<Scalars['String']>;
  secondaryDeliveryConfirmationRecipientPhone?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  trailerId?: InputMaybe<Scalars['String']>;
  transporterCurrency?: InputMaybe<Scalars['String']>;
  transporterId?: InputMaybe<Scalars['String']>;
  transporterInvoiceNumber?: InputMaybe<Scalars['String']>;
  transporterRate?: InputMaybe<Scalars['Float']>;
  transporterRateType?: InputMaybe<Scalars['String']>;
  transporterWeightInvoice?: InputMaybe<Scalars['Int']>;
  truckReg?: InputMaybe<Scalars['String']>;
  unitLoaded?: InputMaybe<Scalars['String']>;
  varianceAllowedCargoOwner?: InputMaybe<Scalars['String']>;
  varianceAllowedTransporter?: InputMaybe<Scalars['Int']>;
  varianceRateTransporterPerkg?: InputMaybe<Scalars['Int']>;
  varianceRateTransporterToUse?: InputMaybe<Scalars['String']>;
  vesselId?: InputMaybe<Scalars['Int']>;
  workflowId?: InputMaybe<Scalars['Int']>;
};

export type MoveType = Node & {
  __typename?: 'MoveType';
  bagsdel?: Maybe<Scalars['Int']>;
  bagsLoaded?: Maybe<Scalars['Int']>;
  barcoder?: Maybe<Scalars['String']>;
  borderpenalty?: Maybe<Scalars['String']>;
  cargoinv?: Maybe<Scalars['String']>;
  cargoInvoiceNumber?: Maybe<Scalars['String']>;
  cargoRate?: Maybe<Scalars['Float']>;
  cargoType?: Maybe<Scalars['String']>;
  cargoTypeId?: Maybe<Scalars['Int']>;
  cdn?: Maybe<Scalars['String']>;
  clientCurrency?: Maybe<Scalars['String']>;
  clientDeliveryNote?: Maybe<Scalars['String']>;
  cn?: Maybe<Scalars['String']>;
  commodityId?: Maybe<Scalars['String']>;
  consigneeAddress?: Maybe<Scalars['String']>;
  consigneeId?: Maybe<Scalars['Float']>;
  cont2grwght?: Maybe<Scalars['String']>;
  containerGrossWeight?: Maybe<Scalars['Int']>;
  containerNumber?: Maybe<Scalars['String']>;
  containerNumber2?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  dambags?: Maybe<Scalars['Int']>;
  deadlineToLift?: Maybe<Scalars['DateTime']>;
  deliveryConfirmationStatus?: Maybe<Scalars['String']>;
  delqty?: Maybe<Scalars['Int']>;
  destination2Code?: Maybe<Scalars['String']>;
  destination3Code?: Maybe<Scalars['String']>;
  destinationCode: Scalars['String'];
  destinationId?: Maybe<Scalars['Int']>;
  devaddress1?: Maybe<Scalars['String']>;
  devaddress2?: Maybe<Scalars['String']>;
  devaddress3?: Maybe<Scalars['String']>;
  driverId?: Maybe<Scalars['String']>;
  driverPhone?: Maybe<Scalars['String']>;
  emptyReturnDestinationId?: Maybe<Scalars['String']>;
  etaToLoading?: Maybe<Scalars['String']>;
  etaToOffloading?: Maybe<Scalars['String']>;
  exitNoteStatus?: Maybe<Scalars['String']>;
  gatePassNumber?: Maybe<Scalars['String']>;
  guaranteeFormExpiryDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  importExport?: Maybe<Scalars['String']>;
  lastKnownLocation?: Maybe<Scalars['String']>;
  llon?: Maybe<Scalars['String']>;
  llon1?: Maybe<Scalars['String']>;
  loadbico?: Maybe<Scalars['String']>;
  loaddoc?: Maybe<Scalars['String']>;
  loadedTonnage?: Maybe<Scalars['Int']>;
  loadingContactId?: Maybe<Scalars['String']>;
  lroId?: Maybe<Scalars['String']>;
  lst?: Maybe<Scalars['String']>;
  misbags?: Maybe<Scalars['Int']>;
  moveId: Scalars['Float'];
  offloadbico?: Maybe<Scalars['Int']>;
  offloadedTonnage?: Maybe<Scalars['Float']>;
  offloadingContactId?: Maybe<Scalars['String']>;
  operation?: Maybe<ValidateMoveType>;
  originCode?: Maybe<Scalars['String']>;
  originId?: Maybe<Scalars['Int']>;
  penaltyamt?: Maybe<Scalars['Int']>;
  penaltypayer?: Maybe<Scalars['String']>;
  portInterchangeSlipNo?: Maybe<Scalars['String']>;
  primaryDeliveryConfirmationRecipientName?: Maybe<Scalars['String']>;
  primaryDeliveryConfirmationRecipientPhone?: Maybe<Scalars['String']>;
  proposedOrder?: Maybe<LiaOrderType>;
  qtyLoaded?: Maybe<Scalars['Int']>;
  receivername2?: Maybe<Scalars['String']>;
  rtoutliers?: Maybe<Scalars['String']>;
  salelpo?: Maybe<Scalars['String']>;
  sealNumber?: Maybe<Scalars['String']>;
  secondaryDeliveryConfirmationRecipientName?: Maybe<Scalars['String']>;
  secondaryDeliveryConfirmationRecipientPhone?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  t1Expiry?: Maybe<Scalars['String']>;
  totalCargoAllocation?: Maybe<Scalars['Int']>;
  trailerId?: Maybe<Scalars['String']>;
  transporter?: Maybe<TransportersType>;
  transporterCurrency?: Maybe<Scalars['String']>;
  transporterId?: Maybe<Scalars['Float']>;
  transporterInvoiceNumber?: Maybe<Scalars['String']>;
  transporterPaymentTerms?: Maybe<Scalars['Float']>;
  transporterRate?: Maybe<Scalars['Float']>;
  transporterRateType?: Maybe<Scalars['String']>;
  transporterWeightInvoice?: Maybe<Scalars['Int']>;
  truckReg?: Maybe<Scalars['String']>;
  turnBoyName?: Maybe<Scalars['String']>;
  turnBoyPhone?: Maybe<Scalars['String']>;
  unitLoaded?: Maybe<Scalars['String']>;
  varianceAllowedCargoOwner?: Maybe<Scalars['String']>;
  varianceAllowedTransporter?: Maybe<Scalars['Int']>;
  varianceRateTransporterPerkg?: Maybe<Scalars['Int']>;
  varianceRateTransporterToUse?: Maybe<Scalars['String']>;
  vesselId?: Maybe<Scalars['Float']>;
  waybillinv?: Maybe<Scalars['String']>;
  workflowId?: Maybe<Scalars['Int']>;
};

export type MoveTypeConnection = {
  __typename?: 'MoveTypeConnection';
  edges: Array<Maybe<MoveTypeEdge>>;
  pageInfo: PageInfo;
};

export type MoveTypeConnectionsConnection = {
  __typename?: 'MoveTypeConnectionsConnection';
  edges: Array<Maybe<MoveTypeConnectionsEdge>>;
  pageInfo: PageInfo;
};

export type MoveTypeConnectionsEdge = {
  __typename?: 'MoveTypeConnectionsEdge';
  cursor: Scalars['String'];
  node?: Maybe<MoveType>;
};

export type MoveTypeEdge = {
  __typename?: 'MoveTypeEdge';
  cursor: Scalars['String'];
  node?: Maybe<MoveType>;
};

export enum MoveTypeSortEnum {
  BagsLoadedAsc = 'BAGS_LOADED_ASC',
  BagsLoadedDesc = 'BAGS_LOADED_DESC',
  BagsdelAsc = 'BAGSDEL_ASC',
  BagsdelDesc = 'BAGSDEL_DESC',
  BarcoderAsc = 'BARCODER_ASC',
  BarcoderDesc = 'BARCODER_DESC',
  BorderpenaltyAsc = 'BORDERPENALTY_ASC',
  BorderpenaltyDesc = 'BORDERPENALTY_DESC',
  CargoInvoiceNumberAsc = 'CARGO_INVOICE_NUMBER_ASC',
  CargoInvoiceNumberDesc = 'CARGO_INVOICE_NUMBER_DESC',
  CargoRateAsc = 'CARGO_RATE_ASC',
  CargoRateDesc = 'CARGO_RATE_DESC',
  CargoTypeAsc = 'CARGO_TYPE_ASC',
  CargoTypeDesc = 'CARGO_TYPE_DESC',
  CargoTypeIdAsc = 'CARGO_TYPE_ID_ASC',
  CargoTypeIdDesc = 'CARGO_TYPE_ID_DESC',
  CargoinvAsc = 'CARGOINV_ASC',
  CargoinvDesc = 'CARGOINV_DESC',
  CdnAsc = 'CDN_ASC',
  CdnDesc = 'CDN_DESC',
  ClientCurrencyAsc = 'CLIENT_CURRENCY_ASC',
  ClientCurrencyDesc = 'CLIENT_CURRENCY_DESC',
  ClientDeliveryNoteAsc = 'CLIENT_DELIVERY_NOTE_ASC',
  ClientDeliveryNoteDesc = 'CLIENT_DELIVERY_NOTE_DESC',
  CnAsc = 'CN_ASC',
  CnDesc = 'CN_DESC',
  CommodityIdAsc = 'COMMODITY_ID_ASC',
  CommodityIdDesc = 'COMMODITY_ID_DESC',
  ConsigneeAddressAsc = 'CONSIGNEE_ADDRESS_ASC',
  ConsigneeAddressDesc = 'CONSIGNEE_ADDRESS_DESC',
  ConsigneeIdAsc = 'CONSIGNEE_ID_ASC',
  ConsigneeIdDesc = 'CONSIGNEE_ID_DESC',
  Cont2GrwghtAsc = 'CONT2GRWGHT_ASC',
  Cont2GrwghtDesc = 'CONT2GRWGHT_DESC',
  ContainerGrossWeightAsc = 'CONTAINER_GROSS_WEIGHT_ASC',
  ContainerGrossWeightDesc = 'CONTAINER_GROSS_WEIGHT_DESC',
  ContainerNumber_2Asc = 'CONTAINER_NUMBER_2_ASC',
  ContainerNumber_2Desc = 'CONTAINER_NUMBER_2_DESC',
  ContainerNumberAsc = 'CONTAINER_NUMBER_ASC',
  ContainerNumberDesc = 'CONTAINER_NUMBER_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DambagsAsc = 'DAMBAGS_ASC',
  DambagsDesc = 'DAMBAGS_DESC',
  DeadlineToLiftAsc = 'DEADLINE_TO_LIFT_ASC',
  DeadlineToLiftDesc = 'DEADLINE_TO_LIFT_DESC',
  DeliveryConfirmationStatusAsc = 'DELIVERY_CONFIRMATION_STATUS_ASC',
  DeliveryConfirmationStatusDesc = 'DELIVERY_CONFIRMATION_STATUS_DESC',
  DelqtyAsc = 'DELQTY_ASC',
  DelqtyDesc = 'DELQTY_DESC',
  Destination_2CodeAsc = 'DESTINATION_2_CODE_ASC',
  Destination_2CodeDesc = 'DESTINATION_2_CODE_DESC',
  Destination_3CodeAsc = 'DESTINATION_3_CODE_ASC',
  Destination_3CodeDesc = 'DESTINATION_3_CODE_DESC',
  DestinationCodeAsc = 'DESTINATION_CODE_ASC',
  DestinationCodeDesc = 'DESTINATION_CODE_DESC',
  DestinationIdAsc = 'DESTINATION_ID_ASC',
  DestinationIdDesc = 'DESTINATION_ID_DESC',
  Devaddress1Asc = 'DEVADDRESS1_ASC',
  Devaddress1Desc = 'DEVADDRESS1_DESC',
  Devaddress2Asc = 'DEVADDRESS2_ASC',
  Devaddress2Desc = 'DEVADDRESS2_DESC',
  Devaddress3Asc = 'DEVADDRESS3_ASC',
  Devaddress3Desc = 'DEVADDRESS3_DESC',
  DriverIdAsc = 'DRIVER_ID_ASC',
  DriverIdDesc = 'DRIVER_ID_DESC',
  DriverPhoneAsc = 'DRIVER_PHONE_ASC',
  DriverPhoneDesc = 'DRIVER_PHONE_DESC',
  EmptyReturnDestinationIdAsc = 'EMPTY_RETURN_DESTINATION_ID_ASC',
  EmptyReturnDestinationIdDesc = 'EMPTY_RETURN_DESTINATION_ID_DESC',
  EtaToLoadingAsc = 'ETA_TO_LOADING_ASC',
  EtaToLoadingDesc = 'ETA_TO_LOADING_DESC',
  EtaToOffloadingAsc = 'ETA_TO_OFFLOADING_ASC',
  EtaToOffloadingDesc = 'ETA_TO_OFFLOADING_DESC',
  ExitNoteStatusAsc = 'EXIT_NOTE_STATUS_ASC',
  ExitNoteStatusDesc = 'EXIT_NOTE_STATUS_DESC',
  GatePassNumberAsc = 'GATE_PASS_NUMBER_ASC',
  GatePassNumberDesc = 'GATE_PASS_NUMBER_DESC',
  GuaranteeFormExpiryDateAsc = 'GUARANTEE_FORM_EXPIRY_DATE_ASC',
  GuaranteeFormExpiryDateDesc = 'GUARANTEE_FORM_EXPIRY_DATE_DESC',
  ImportExportAsc = 'IMPORT_EXPORT_ASC',
  ImportExportDesc = 'IMPORT_EXPORT_DESC',
  LastKnownLocationAsc = 'LAST_KNOWN_LOCATION_ASC',
  LastKnownLocationDesc = 'LAST_KNOWN_LOCATION_DESC',
  LlonAsc = 'LLON_ASC',
  LlonDesc = 'LLON_DESC',
  Llon1Asc = 'LLON1_ASC',
  Llon1Desc = 'LLON1_DESC',
  LoadbicoAsc = 'LOADBICO_ASC',
  LoadbicoDesc = 'LOADBICO_DESC',
  LoaddocAsc = 'LOADDOC_ASC',
  LoaddocDesc = 'LOADDOC_DESC',
  LoadedTonnageAsc = 'LOADED_TONNAGE_ASC',
  LoadedTonnageDesc = 'LOADED_TONNAGE_DESC',
  LoadingContactIdAsc = 'LOADING_CONTACT_ID_ASC',
  LoadingContactIdDesc = 'LOADING_CONTACT_ID_DESC',
  LroIdAsc = 'LRO_ID_ASC',
  LroIdDesc = 'LRO_ID_DESC',
  LstAsc = 'LST_ASC',
  LstDesc = 'LST_DESC',
  MisbagsAsc = 'MISBAGS_ASC',
  MisbagsDesc = 'MISBAGS_DESC',
  MoveIdAsc = 'MOVE_ID_ASC',
  MoveIdDesc = 'MOVE_ID_DESC',
  OffloadbicoAsc = 'OFFLOADBICO_ASC',
  OffloadbicoDesc = 'OFFLOADBICO_DESC',
  OffloadedTonnageAsc = 'OFFLOADED_TONNAGE_ASC',
  OffloadedTonnageDesc = 'OFFLOADED_TONNAGE_DESC',
  OffloadingContactIdAsc = 'OFFLOADING_CONTACT_ID_ASC',
  OffloadingContactIdDesc = 'OFFLOADING_CONTACT_ID_DESC',
  OriginCodeAsc = 'ORIGIN_CODE_ASC',
  OriginCodeDesc = 'ORIGIN_CODE_DESC',
  OriginIdAsc = 'ORIGIN_ID_ASC',
  OriginIdDesc = 'ORIGIN_ID_DESC',
  PenaltyamtAsc = 'PENALTYAMT_ASC',
  PenaltyamtDesc = 'PENALTYAMT_DESC',
  PenaltypayerAsc = 'PENALTYPAYER_ASC',
  PenaltypayerDesc = 'PENALTYPAYER_DESC',
  PortInterchangeSlipNoAsc = 'PORT_INTERCHANGE_SLIP_NO_ASC',
  PortInterchangeSlipNoDesc = 'PORT_INTERCHANGE_SLIP_NO_DESC',
  PrimaryDeliveryConfirmationRecipientNameAsc = 'PRIMARY_DELIVERY_CONFIRMATION_RECIPIENT_NAME_ASC',
  PrimaryDeliveryConfirmationRecipientNameDesc = 'PRIMARY_DELIVERY_CONFIRMATION_RECIPIENT_NAME_DESC',
  PrimaryDeliveryConfirmationRecipientPhoneAsc = 'PRIMARY_DELIVERY_CONFIRMATION_RECIPIENT_PHONE_ASC',
  PrimaryDeliveryConfirmationRecipientPhoneDesc = 'PRIMARY_DELIVERY_CONFIRMATION_RECIPIENT_PHONE_DESC',
  QtyLoadedAsc = 'QTY_LOADED_ASC',
  QtyLoadedDesc = 'QTY_LOADED_DESC',
  Receivername2Asc = 'RECEIVERNAME2_ASC',
  Receivername2Desc = 'RECEIVERNAME2_DESC',
  RtoutliersAsc = 'RTOUTLIERS_ASC',
  RtoutliersDesc = 'RTOUTLIERS_DESC',
  SalelpoAsc = 'SALELPO_ASC',
  SalelpoDesc = 'SALELPO_DESC',
  SealNumberAsc = 'SEAL_NUMBER_ASC',
  SealNumberDesc = 'SEAL_NUMBER_DESC',
  SecondaryDeliveryConfirmationRecipientNameAsc = 'SECONDARY_DELIVERY_CONFIRMATION_RECIPIENT_NAME_ASC',
  SecondaryDeliveryConfirmationRecipientNameDesc = 'SECONDARY_DELIVERY_CONFIRMATION_RECIPIENT_NAME_DESC',
  SecondaryDeliveryConfirmationRecipientPhoneAsc = 'SECONDARY_DELIVERY_CONFIRMATION_RECIPIENT_PHONE_ASC',
  SecondaryDeliveryConfirmationRecipientPhoneDesc = 'SECONDARY_DELIVERY_CONFIRMATION_RECIPIENT_PHONE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  T1ExpiryAsc = 'T1_EXPIRY_ASC',
  T1ExpiryDesc = 'T1_EXPIRY_DESC',
  TotalCargoAllocationAsc = 'TOTAL_CARGO_ALLOCATION_ASC',
  TotalCargoAllocationDesc = 'TOTAL_CARGO_ALLOCATION_DESC',
  TrailerIdAsc = 'TRAILER_ID_ASC',
  TrailerIdDesc = 'TRAILER_ID_DESC',
  TransporterCurrencyAsc = 'TRANSPORTER_CURRENCY_ASC',
  TransporterCurrencyDesc = 'TRANSPORTER_CURRENCY_DESC',
  TransporterIdAsc = 'TRANSPORTER_ID_ASC',
  TransporterIdDesc = 'TRANSPORTER_ID_DESC',
  TransporterInvoiceNumberAsc = 'TRANSPORTER_INVOICE_NUMBER_ASC',
  TransporterInvoiceNumberDesc = 'TRANSPORTER_INVOICE_NUMBER_DESC',
  TransporterPaymentTermsAsc = 'TRANSPORTER_PAYMENT_TERMS_ASC',
  TransporterPaymentTermsDesc = 'TRANSPORTER_PAYMENT_TERMS_DESC',
  TransporterRateAsc = 'TRANSPORTER_RATE_ASC',
  TransporterRateDesc = 'TRANSPORTER_RATE_DESC',
  TransporterRateTypeAsc = 'TRANSPORTER_RATE_TYPE_ASC',
  TransporterRateTypeDesc = 'TRANSPORTER_RATE_TYPE_DESC',
  TransporterWeightInvoiceAsc = 'TRANSPORTER_WEIGHT_INVOICE_ASC',
  TransporterWeightInvoiceDesc = 'TRANSPORTER_WEIGHT_INVOICE_DESC',
  TruckRegAsc = 'TRUCK_REG_ASC',
  TruckRegDesc = 'TRUCK_REG_DESC',
  TurnBoyNameAsc = 'TURN_BOY_NAME_ASC',
  TurnBoyNameDesc = 'TURN_BOY_NAME_DESC',
  TurnBoyPhoneAsc = 'TURN_BOY_PHONE_ASC',
  TurnBoyPhoneDesc = 'TURN_BOY_PHONE_DESC',
  UnitLoadedAsc = 'UNIT_LOADED_ASC',
  UnitLoadedDesc = 'UNIT_LOADED_DESC',
  VarianceAllowedCargoOwnerAsc = 'VARIANCE_ALLOWED_CARGO_OWNER_ASC',
  VarianceAllowedCargoOwnerDesc = 'VARIANCE_ALLOWED_CARGO_OWNER_DESC',
  VarianceAllowedTransporterAsc = 'VARIANCE_ALLOWED_TRANSPORTER_ASC',
  VarianceAllowedTransporterDesc = 'VARIANCE_ALLOWED_TRANSPORTER_DESC',
  VarianceRateTransporterPerkgAsc = 'VARIANCE_RATE_TRANSPORTER_PERKG_ASC',
  VarianceRateTransporterPerkgDesc = 'VARIANCE_RATE_TRANSPORTER_PERKG_DESC',
  VarianceRateTransporterToUseAsc = 'VARIANCE_RATE_TRANSPORTER_TO_USE_ASC',
  VarianceRateTransporterToUseDesc = 'VARIANCE_RATE_TRANSPORTER_TO_USE_DESC',
  VesselIdAsc = 'VESSEL_ID_ASC',
  VesselIdDesc = 'VESSEL_ID_DESC',
  WaybillinvAsc = 'WAYBILLINV_ASC',
  WaybillinvDesc = 'WAYBILLINV_DESC',
  WorkflowIdAsc = 'WORKFLOW_ID_ASC',
  WorkflowIdDesc = 'WORKFLOW_ID_DESC'
}

export type Node = {
  id: Scalars['ID'];
};

export type OperationInput = {
  consigneeAddress?: InputMaybe<Scalars['String']>;
  consigneeId?: InputMaybe<Scalars['String']>;
  destinationGeoCode?: InputMaybe<Scalars['String']>;
  driverId?: InputMaybe<Scalars['String']>;
  driverPhone?: InputMaybe<Scalars['String']>;
  lastKnownLocation?: InputMaybe<Scalars['String']>;
};

export type OperationType = Node & {
  __typename?: 'OperationType';
  allMoveCount?: Maybe<Scalars['Int']>;
  arbod1?: Maybe<Scalars['DateTime']>;
  arbod2?: Maybe<Scalars['DateTime']>;
  ardes1?: Maybe<Scalars['DateTime']>;
  arload?: Maybe<Scalars['DateTime']>;
  assetserial?: Maybe<Scalars['String']>;
  bagsdel?: Maybe<Scalars['Int']>;
  bagsLoaded?: Maybe<Scalars['Int']>;
  barcoder?: Maybe<Scalars['DateTime']>;
  cargoCapacity?: Maybe<Scalars['Int']>;
  cargoClientAccountManager?: Maybe<Scalars['String']>;
  cargoInvoiceNumber?: Maybe<Scalars['String']>;
  cargoOwnerInvoiceWeight?: Maybe<Scalars['Float']>;
  cargoRate?: Maybe<Scalars['String']>;
  cargoRateType?: Maybe<Scalars['String']>;
  cargoType?: Maybe<Scalars['String']>;
  cargoTypeId?: Maybe<Scalars['Float']>;
  carrtder?: Maybe<Scalars['DateTime']>;
  cdetder?: Maybe<Scalars['DateTime']>;
  clearingAgent?: Maybe<Scalars['String']>;
  clientCurrency?: Maybe<Scalars['String']>;
  clientDeliveryNote?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['Float']>;
  clientName?: Maybe<Scalars['String']>;
  commodity?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Int']>;
  consigneeAddress?: Maybe<Scalars['String']>;
  consigneeId?: Maybe<Scalars['Float']>;
  consigneeName?: Maybe<Scalars['String']>;
  consignmentMetricTons?: Maybe<Scalars['String']>;
  containerGrossWeight?: Maybe<Scalars['Int']>;
  containerNumber?: Maybe<Scalars['String']>;
  containerNumber2?: Maybe<Scalars['String']>;
  controlDate?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Scalars['String']>;
  currentStage?: Maybe<Scalars['String']>;
  currentStageFlag?: Maybe<Scalars['String']>;
  damagedBags?: Maybe<Scalars['String']>;
  demurrage?: Maybe<Scalars['String']>;
  departureFuelingStation?: Maybe<Scalars['DateTime']>;
  depbod1?: Maybe<Scalars['DateTime']>;
  depbod2?: Maybe<Scalars['DateTime']>;
  destination?: Maybe<Scalars['String']>;
  destination2?: Maybe<Scalars['String']>;
  destination2Code?: Maybe<Scalars['String']>;
  destination3?: Maybe<Scalars['String']>;
  destination3Code?: Maybe<Scalars['String']>;
  destinationCity?: Maybe<Scalars['String']>;
  destinationCountry?: Maybe<Scalars['String']>;
  destinationGeoCode?: Maybe<Scalars['String']>;
  driver?: Maybe<DriverType>;
  driverId?: Maybe<Scalars['String']>;
  driverName?: Maybe<Scalars['String']>;
  driverPhone?: Maybe<Scalars['String']>;
  emptyReturnChanged?: Maybe<Scalars['Boolean']>;
  emptyReturnCostExternal?: Maybe<Scalars['String']>;
  emptyReturnDestination?: Maybe<Scalars['String']>;
  emptyReturnDestinationName?: Maybe<Scalars['String']>;
  endJourneyValidationDate?: Maybe<Scalars['DateTime']>;
  etaToLoading?: Maybe<Scalars['String']>;
  etaToOffloading?: Maybe<Scalars['String']>;
  fuelRequestTime?: Maybe<Scalars['DateTime']>;
  gainlp?: Maybe<Scalars['DateTime']>;
  gaoutlp?: Maybe<Scalars['DateTime']>;
  gatePassNumber?: Maybe<Scalars['String']>;
  gindest1?: Maybe<Scalars['DateTime']>;
  goutdes?: Maybe<Scalars['String']>;
  goutdes1?: Maybe<Scalars['DateTime']>;
  guaranteeFormValidationDate?: Maybe<Scalars['DateTime']>;
  hours?: Maybe<Scalars['Int']>;
  icdslipdiscus?: Maybe<Scalars['DateTime']>;
  icdsliprecd?: Maybe<Scalars['DateTime']>;
  icdsliprechq?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  importExport?: Maybe<Scalars['String']>;
  invoiceReadiness?: Maybe<Scalars['Boolean']>;
  invpdcust?: Maybe<Scalars['DateTime']>;
  invtocust?: Maybe<Scalars['DateTime']>;
  lastKnownLocation?: Maybe<Scalars['String']>;
  lastKnownLocationTime?: Maybe<Scalars['DateTime']>;
  llon?: Maybe<Scalars['String']>;
  loadbico?: Maybe<Scalars['String']>;
  loadingDate?: Maybe<Scalars['DateTime']>;
  loadingValidationDate?: Maybe<Scalars['DateTime']>;
  lroId?: Maybe<Scalars['String']>;
  match?: Maybe<Scalars['DateTime']>;
  missingBags?: Maybe<Scalars['String']>;
  motorboy?: Maybe<Scalars['String']>;
  moveId: Scalars['Float'];
  offloadedTonnage?: Maybe<Scalars['Float']>;
  oldestEventDepartureFuelingStation?: Maybe<Scalars['DateTime']>;
  origin?: Maybe<Scalars['String']>;
  originCity?: Maybe<Scalars['String']>;
  originCountry?: Maybe<Scalars['String']>;
  originGeocode?: Maybe<Scalars['String']>;
  podcpr?: Maybe<Scalars['DateTime']>;
  podrhq?: Maybe<Scalars['DateTime']>;
  portInterchangeSlipNo?: Maybe<Scalars['String']>;
  portPassCharge?: Maybe<Scalars['String']>;
  portPassCost?: Maybe<Scalars['String']>;
  qtyLoaded?: Maybe<Scalars['Int']>;
  qtyOffloaded?: Maybe<Scalars['Int']>;
  receiver?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  rejectedBags?: Maybe<Scalars['Int']>;
  remarks?: Maybe<Scalars['String']>;
  rvsinvdate?: Maybe<Scalars['DateTime']>;
  sealNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  subtotalCargoOwner?: Maybe<Scalars['String']>;
  subtotalTransporter?: Maybe<Scalars['String']>;
  sumFuelledAmount?: Maybe<Scalars['Float']>;
  t1Expiry?: Maybe<Scalars['DateTime']>;
  t1rec?: Maybe<Scalars['DateTime']>;
  trailer?: Maybe<TrailersType>;
  trailerNumber?: Maybe<Scalars['String']>;
  trailerType?: Maybe<Scalars['String']>;
  traninvrec?: Maybe<Scalars['DateTime']>;
  tranpd?: Maybe<Scalars['DateTime']>;
  transcogs?: Maybe<Scalars['Float']>;
  transportAccountManager?: Maybe<Scalars['String']>;
  transporterAccountManager?: Maybe<Scalars['String']>;
  transporterCurrency?: Maybe<Scalars['String']>;
  transporterId?: Maybe<Scalars['Float']>;
  transporterInvoiceNumber?: Maybe<Scalars['String']>;
  transporterName?: Maybe<Scalars['String']>;
  transporterPaymentTerms?: Maybe<Scalars['Float']>;
  transporterRate?: Maybe<Scalars['Float']>;
  transporterRateType?: Maybe<Scalars['String']>;
  transporterWeightInvoice?: Maybe<Scalars['Int']>;
  truck?: Maybe<TrucksType>;
  truckReg?: Maybe<Scalars['String']>;
  truckType?: Maybe<Scalars['String']>;
  turnBoyName?: Maybe<Scalars['String']>;
  turnBoyPhone?: Maybe<Scalars['String']>;
  unitLoaded?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  varianceAllowedCargoOwner?: Maybe<Scalars['String']>;
  varianceAllowedTransporter?: Maybe<Scalars['Int']>;
  varianceRateTransporterPerkg?: Maybe<Scalars['Int']>;
  varianceRateTransporterToUse?: Maybe<Scalars['String']>;
  vempreoffinfo?: Maybe<Scalars['DateTime']>;
  vessel?: Maybe<Scalars['String']>;
  wbslrhq?: Maybe<Scalars['DateTime']>;
  wbsofhq?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  workflowName?: Maybe<Scalars['String']>;
};

export type OperationTypeConnection = {
  __typename?: 'OperationTypeConnection';
  edges: Array<Maybe<OperationTypeEdge>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type OperationTypeEdge = {
  __typename?: 'OperationTypeEdge';
  cursor: Scalars['String'];
  node?: Maybe<OperationType>;
};

export enum OperationTypeSortEnum {
  Arbod1Asc = 'ARBOD1_ASC',
  Arbod1Desc = 'ARBOD1_DESC',
  Arbod2Asc = 'ARBOD2_ASC',
  Arbod2Desc = 'ARBOD2_DESC',
  Ardes1Asc = 'ARDES1_ASC',
  Ardes1Desc = 'ARDES1_DESC',
  ArloadAsc = 'ARLOAD_ASC',
  ArloadDesc = 'ARLOAD_DESC',
  AssetserialAsc = 'ASSETSERIAL_ASC',
  AssetserialDesc = 'ASSETSERIAL_DESC',
  BagsLoadedAsc = 'BAGS_LOADED_ASC',
  BagsLoadedDesc = 'BAGS_LOADED_DESC',
  BagsdelAsc = 'BAGSDEL_ASC',
  BagsdelDesc = 'BAGSDEL_DESC',
  BarcoderAsc = 'BARCODER_ASC',
  BarcoderDesc = 'BARCODER_DESC',
  CargoCapacityAsc = 'CARGO_CAPACITY_ASC',
  CargoCapacityDesc = 'CARGO_CAPACITY_DESC',
  CargoClientAccountManagerAsc = 'CARGO_CLIENT_ACCOUNT_MANAGER_ASC',
  CargoClientAccountManagerDesc = 'CARGO_CLIENT_ACCOUNT_MANAGER_DESC',
  CargoInvoiceNumberAsc = 'CARGO_INVOICE_NUMBER_ASC',
  CargoInvoiceNumberDesc = 'CARGO_INVOICE_NUMBER_DESC',
  CargoOwnerInvoiceWeightAsc = 'CARGO_OWNER_INVOICE_WEIGHT_ASC',
  CargoOwnerInvoiceWeightDesc = 'CARGO_OWNER_INVOICE_WEIGHT_DESC',
  CargoRateAsc = 'CARGO_RATE_ASC',
  CargoRateDesc = 'CARGO_RATE_DESC',
  CargoRateTypeAsc = 'CARGO_RATE_TYPE_ASC',
  CargoRateTypeDesc = 'CARGO_RATE_TYPE_DESC',
  CargoTypeAsc = 'CARGO_TYPE_ASC',
  CargoTypeDesc = 'CARGO_TYPE_DESC',
  CargoTypeIdAsc = 'CARGO_TYPE_ID_ASC',
  CargoTypeIdDesc = 'CARGO_TYPE_ID_DESC',
  CarrtderAsc = 'CARRTDER_ASC',
  CarrtderDesc = 'CARRTDER_DESC',
  CdetderAsc = 'CDETDER_ASC',
  CdetderDesc = 'CDETDER_DESC',
  ClearingAgentAsc = 'CLEARING_AGENT_ASC',
  ClearingAgentDesc = 'CLEARING_AGENT_DESC',
  ClientCurrencyAsc = 'CLIENT_CURRENCY_ASC',
  ClientCurrencyDesc = 'CLIENT_CURRENCY_DESC',
  ClientDeliveryNoteAsc = 'CLIENT_DELIVERY_NOTE_ASC',
  ClientDeliveryNoteDesc = 'CLIENT_DELIVERY_NOTE_DESC',
  ClientIdAsc = 'CLIENT_ID_ASC',
  ClientIdDesc = 'CLIENT_ID_DESC',
  ClientNameAsc = 'CLIENT_NAME_ASC',
  ClientNameDesc = 'CLIENT_NAME_DESC',
  CommodityAsc = 'COMMODITY_ASC',
  CommodityDesc = 'COMMODITY_DESC',
  ConsigneeAddressAsc = 'CONSIGNEE_ADDRESS_ASC',
  ConsigneeAddressDesc = 'CONSIGNEE_ADDRESS_DESC',
  ConsigneeIdAsc = 'CONSIGNEE_ID_ASC',
  ConsigneeIdDesc = 'CONSIGNEE_ID_DESC',
  ConsigneeNameAsc = 'CONSIGNEE_NAME_ASC',
  ConsigneeNameDesc = 'CONSIGNEE_NAME_DESC',
  ConsignmentMetricTonsAsc = 'CONSIGNMENT_METRIC_TONS_ASC',
  ConsignmentMetricTonsDesc = 'CONSIGNMENT_METRIC_TONS_DESC',
  ContainerGrossWeightAsc = 'CONTAINER_GROSS_WEIGHT_ASC',
  ContainerGrossWeightDesc = 'CONTAINER_GROSS_WEIGHT_DESC',
  ContainerNumber_2Asc = 'CONTAINER_NUMBER_2_ASC',
  ContainerNumber_2Desc = 'CONTAINER_NUMBER_2_DESC',
  ContainerNumberAsc = 'CONTAINER_NUMBER_ASC',
  ContainerNumberDesc = 'CONTAINER_NUMBER_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CreatedOnAsc = 'CREATED_ON_ASC',
  CreatedOnDesc = 'CREATED_ON_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  CurrentStageAsc = 'CURRENT_STAGE_ASC',
  CurrentStageDesc = 'CURRENT_STAGE_DESC',
  CurrentStageFlagAsc = 'CURRENT_STAGE_FLAG_ASC',
  CurrentStageFlagDesc = 'CURRENT_STAGE_FLAG_DESC',
  DamagedBagsAsc = 'DAMAGED_BAGS_ASC',
  DamagedBagsDesc = 'DAMAGED_BAGS_DESC',
  DepartureFuelingStationAsc = 'DEPARTURE_FUELING_STATION_ASC',
  DepartureFuelingStationDesc = 'DEPARTURE_FUELING_STATION_DESC',
  Depbod1Asc = 'DEPBOD1_ASC',
  Depbod1Desc = 'DEPBOD1_DESC',
  Depbod2Asc = 'DEPBOD2_ASC',
  Depbod2Desc = 'DEPBOD2_DESC',
  Destination_2Asc = 'DESTINATION_2_ASC',
  Destination_2CodeAsc = 'DESTINATION_2_CODE_ASC',
  Destination_2CodeDesc = 'DESTINATION_2_CODE_DESC',
  Destination_2Desc = 'DESTINATION_2_DESC',
  Destination_3Asc = 'DESTINATION_3_ASC',
  Destination_3CodeAsc = 'DESTINATION_3_CODE_ASC',
  Destination_3CodeDesc = 'DESTINATION_3_CODE_DESC',
  Destination_3Desc = 'DESTINATION_3_DESC',
  DestinationAsc = 'DESTINATION_ASC',
  DestinationCityAsc = 'DESTINATION_CITY_ASC',
  DestinationCityDesc = 'DESTINATION_CITY_DESC',
  DestinationCountryAsc = 'DESTINATION_COUNTRY_ASC',
  DestinationCountryDesc = 'DESTINATION_COUNTRY_DESC',
  DestinationDesc = 'DESTINATION_DESC',
  DestinationGeoCodeAsc = 'DESTINATION_GEO_CODE_ASC',
  DestinationGeoCodeDesc = 'DESTINATION_GEO_CODE_DESC',
  DriverIdAsc = 'DRIVER_ID_ASC',
  DriverIdDesc = 'DRIVER_ID_DESC',
  DriverNameAsc = 'DRIVER_NAME_ASC',
  DriverNameDesc = 'DRIVER_NAME_DESC',
  DriverPhoneAsc = 'DRIVER_PHONE_ASC',
  DriverPhoneDesc = 'DRIVER_PHONE_DESC',
  EmptyReturnChangedAsc = 'EMPTY_RETURN_CHANGED_ASC',
  EmptyReturnChangedDesc = 'EMPTY_RETURN_CHANGED_DESC',
  EmptyReturnCostExternalAsc = 'EMPTY_RETURN_COST_EXTERNAL_ASC',
  EmptyReturnCostExternalDesc = 'EMPTY_RETURN_COST_EXTERNAL_DESC',
  EmptyReturnDestinationAsc = 'EMPTY_RETURN_DESTINATION_ASC',
  EmptyReturnDestinationDesc = 'EMPTY_RETURN_DESTINATION_DESC',
  EmptyReturnDestinationNameAsc = 'EMPTY_RETURN_DESTINATION_NAME_ASC',
  EmptyReturnDestinationNameDesc = 'EMPTY_RETURN_DESTINATION_NAME_DESC',
  EndJourneyValidationDateAsc = 'END_JOURNEY_VALIDATION_DATE_ASC',
  EndJourneyValidationDateDesc = 'END_JOURNEY_VALIDATION_DATE_DESC',
  EtaToLoadingAsc = 'ETA_TO_LOADING_ASC',
  EtaToLoadingDesc = 'ETA_TO_LOADING_DESC',
  EtaToOffloadingAsc = 'ETA_TO_OFFLOADING_ASC',
  EtaToOffloadingDesc = 'ETA_TO_OFFLOADING_DESC',
  FuelRequestTimeAsc = 'FUEL_REQUEST_TIME_ASC',
  FuelRequestTimeDesc = 'FUEL_REQUEST_TIME_DESC',
  GainlpAsc = 'GAINLP_ASC',
  GainlpDesc = 'GAINLP_DESC',
  GaoutlpAsc = 'GAOUTLP_ASC',
  GaoutlpDesc = 'GAOUTLP_DESC',
  GatePassNumberAsc = 'GATE_PASS_NUMBER_ASC',
  GatePassNumberDesc = 'GATE_PASS_NUMBER_DESC',
  Gindest1Asc = 'GINDEST1_ASC',
  Gindest1Desc = 'GINDEST1_DESC',
  GoutdesAsc = 'GOUTDES_ASC',
  GoutdesDesc = 'GOUTDES_DESC',
  Goutdes1Asc = 'GOUTDES1_ASC',
  Goutdes1Desc = 'GOUTDES1_DESC',
  GuaranteeFormValidationDateAsc = 'GUARANTEE_FORM_VALIDATION_DATE_ASC',
  GuaranteeFormValidationDateDesc = 'GUARANTEE_FORM_VALIDATION_DATE_DESC',
  HoursAsc = 'HOURS_ASC',
  HoursDesc = 'HOURS_DESC',
  IcdslipdiscusAsc = 'ICDSLIPDISCUS_ASC',
  IcdslipdiscusDesc = 'ICDSLIPDISCUS_DESC',
  IcdsliprecdAsc = 'ICDSLIPRECD_ASC',
  IcdsliprecdDesc = 'ICDSLIPRECD_DESC',
  IcdsliprechqAsc = 'ICDSLIPRECHQ_ASC',
  IcdsliprechqDesc = 'ICDSLIPRECHQ_DESC',
  ImportExportAsc = 'IMPORT_EXPORT_ASC',
  ImportExportDesc = 'IMPORT_EXPORT_DESC',
  InvpdcustAsc = 'INVPDCUST_ASC',
  InvpdcustDesc = 'INVPDCUST_DESC',
  InvtocustAsc = 'INVTOCUST_ASC',
  InvtocustDesc = 'INVTOCUST_DESC',
  LastKnownLocationAsc = 'LAST_KNOWN_LOCATION_ASC',
  LastKnownLocationDesc = 'LAST_KNOWN_LOCATION_DESC',
  LastKnownLocationTimeAsc = 'LAST_KNOWN_LOCATION_TIME_ASC',
  LastKnownLocationTimeDesc = 'LAST_KNOWN_LOCATION_TIME_DESC',
  LlonAsc = 'LLON_ASC',
  LlonDesc = 'LLON_DESC',
  LoadbicoAsc = 'LOADBICO_ASC',
  LoadbicoDesc = 'LOADBICO_DESC',
  LoadingDateAsc = 'LOADING_DATE_ASC',
  LoadingDateDesc = 'LOADING_DATE_DESC',
  LoadingValidationDateAsc = 'LOADING_VALIDATION_DATE_ASC',
  LoadingValidationDateDesc = 'LOADING_VALIDATION_DATE_DESC',
  LroIdAsc = 'LRO_ID_ASC',
  LroIdDesc = 'LRO_ID_DESC',
  MatchAsc = 'MATCH_ASC',
  MatchDesc = 'MATCH_DESC',
  MissingBagsAsc = 'MISSING_BAGS_ASC',
  MissingBagsDesc = 'MISSING_BAGS_DESC',
  MotorboyAsc = 'MOTORBOY_ASC',
  MotorboyDesc = 'MOTORBOY_DESC',
  MoveIdAsc = 'MOVE_ID_ASC',
  MoveIdDesc = 'MOVE_ID_DESC',
  OffloadedTonnageAsc = 'OFFLOADED_TONNAGE_ASC',
  OffloadedTonnageDesc = 'OFFLOADED_TONNAGE_DESC',
  OldestEventDepartureFuelingStationAsc = 'OLDEST_EVENT_DEPARTURE_FUELING_STATION_ASC',
  OldestEventDepartureFuelingStationDesc = 'OLDEST_EVENT_DEPARTURE_FUELING_STATION_DESC',
  OriginAsc = 'ORIGIN_ASC',
  OriginCityAsc = 'ORIGIN_CITY_ASC',
  OriginCityDesc = 'ORIGIN_CITY_DESC',
  OriginCountryAsc = 'ORIGIN_COUNTRY_ASC',
  OriginCountryDesc = 'ORIGIN_COUNTRY_DESC',
  OriginDesc = 'ORIGIN_DESC',
  OriginGeocodeAsc = 'ORIGIN_GEOCODE_ASC',
  OriginGeocodeDesc = 'ORIGIN_GEOCODE_DESC',
  PodcprAsc = 'PODCPR_ASC',
  PodcprDesc = 'PODCPR_DESC',
  PodrhqAsc = 'PODRHQ_ASC',
  PodrhqDesc = 'PODRHQ_DESC',
  PortInterchangeSlipNoAsc = 'PORT_INTERCHANGE_SLIP_NO_ASC',
  PortInterchangeSlipNoDesc = 'PORT_INTERCHANGE_SLIP_NO_DESC',
  QtyLoadedAsc = 'QTY_LOADED_ASC',
  QtyLoadedDesc = 'QTY_LOADED_DESC',
  QtyOffloadedAsc = 'QTY_OFFLOADED_ASC',
  QtyOffloadedDesc = 'QTY_OFFLOADED_DESC',
  ReceiverAsc = 'RECEIVER_ASC',
  ReceiverDesc = 'RECEIVER_DESC',
  RegionAsc = 'REGION_ASC',
  RegionDesc = 'REGION_DESC',
  RejectedBagsAsc = 'REJECTED_BAGS_ASC',
  RejectedBagsDesc = 'REJECTED_BAGS_DESC',
  RemarksAsc = 'REMARKS_ASC',
  RemarksDesc = 'REMARKS_DESC',
  RvsinvdateAsc = 'RVSINVDATE_ASC',
  RvsinvdateDesc = 'RVSINVDATE_DESC',
  SealNumberAsc = 'SEAL_NUMBER_ASC',
  SealNumberDesc = 'SEAL_NUMBER_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SubtotalCargoOwnerAsc = 'SUBTOTAL_CARGO_OWNER_ASC',
  SubtotalCargoOwnerDesc = 'SUBTOTAL_CARGO_OWNER_DESC',
  SubtotalTransporterAsc = 'SUBTOTAL_TRANSPORTER_ASC',
  SubtotalTransporterDesc = 'SUBTOTAL_TRANSPORTER_DESC',
  SumFuelledAmountAsc = 'SUM_FUELLED_AMOUNT_ASC',
  SumFuelledAmountDesc = 'SUM_FUELLED_AMOUNT_DESC',
  T1ExpiryAsc = 'T1_EXPIRY_ASC',
  T1ExpiryDesc = 'T1_EXPIRY_DESC',
  T1RecAsc = 'T1REC_ASC',
  T1RecDesc = 'T1REC_DESC',
  TrailerNumberAsc = 'TRAILER_NUMBER_ASC',
  TrailerNumberDesc = 'TRAILER_NUMBER_DESC',
  TrailerTypeAsc = 'TRAILER_TYPE_ASC',
  TrailerTypeDesc = 'TRAILER_TYPE_DESC',
  TraninvrecAsc = 'TRANINVREC_ASC',
  TraninvrecDesc = 'TRANINVREC_DESC',
  TranpdAsc = 'TRANPD_ASC',
  TranpdDesc = 'TRANPD_DESC',
  TranscogsAsc = 'TRANSCOGS_ASC',
  TranscogsDesc = 'TRANSCOGS_DESC',
  TransportAccountManagerAsc = 'TRANSPORT_ACCOUNT_MANAGER_ASC',
  TransportAccountManagerDesc = 'TRANSPORT_ACCOUNT_MANAGER_DESC',
  TransporterAccountManagerAsc = 'TRANSPORTER_ACCOUNT_MANAGER_ASC',
  TransporterAccountManagerDesc = 'TRANSPORTER_ACCOUNT_MANAGER_DESC',
  TransporterCurrencyAsc = 'TRANSPORTER_CURRENCY_ASC',
  TransporterCurrencyDesc = 'TRANSPORTER_CURRENCY_DESC',
  TransporterIdAsc = 'TRANSPORTER_ID_ASC',
  TransporterIdDesc = 'TRANSPORTER_ID_DESC',
  TransporterInvoiceNumberAsc = 'TRANSPORTER_INVOICE_NUMBER_ASC',
  TransporterInvoiceNumberDesc = 'TRANSPORTER_INVOICE_NUMBER_DESC',
  TransporterNameAsc = 'TRANSPORTER_NAME_ASC',
  TransporterNameDesc = 'TRANSPORTER_NAME_DESC',
  TransporterPaymentTermsAsc = 'TRANSPORTER_PAYMENT_TERMS_ASC',
  TransporterPaymentTermsDesc = 'TRANSPORTER_PAYMENT_TERMS_DESC',
  TransporterRateAsc = 'TRANSPORTER_RATE_ASC',
  TransporterRateDesc = 'TRANSPORTER_RATE_DESC',
  TransporterRateTypeAsc = 'TRANSPORTER_RATE_TYPE_ASC',
  TransporterRateTypeDesc = 'TRANSPORTER_RATE_TYPE_DESC',
  TransporterWeightInvoiceAsc = 'TRANSPORTER_WEIGHT_INVOICE_ASC',
  TransporterWeightInvoiceDesc = 'TRANSPORTER_WEIGHT_INVOICE_DESC',
  TruckRegAsc = 'TRUCK_REG_ASC',
  TruckRegDesc = 'TRUCK_REG_DESC',
  TruckTypeAsc = 'TRUCK_TYPE_ASC',
  TruckTypeDesc = 'TRUCK_TYPE_DESC',
  TurnBoyNameAsc = 'TURN_BOY_NAME_ASC',
  TurnBoyNameDesc = 'TURN_BOY_NAME_DESC',
  TurnBoyPhoneAsc = 'TURN_BOY_PHONE_ASC',
  TurnBoyPhoneDesc = 'TURN_BOY_PHONE_DESC',
  UnitLoadedAsc = 'UNIT_LOADED_ASC',
  UnitLoadedDesc = 'UNIT_LOADED_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  VarianceAllowedCargoOwnerAsc = 'VARIANCE_ALLOWED_CARGO_OWNER_ASC',
  VarianceAllowedCargoOwnerDesc = 'VARIANCE_ALLOWED_CARGO_OWNER_DESC',
  VarianceAllowedTransporterAsc = 'VARIANCE_ALLOWED_TRANSPORTER_ASC',
  VarianceAllowedTransporterDesc = 'VARIANCE_ALLOWED_TRANSPORTER_DESC',
  VarianceRateTransporterPerkgAsc = 'VARIANCE_RATE_TRANSPORTER_PERKG_ASC',
  VarianceRateTransporterPerkgDesc = 'VARIANCE_RATE_TRANSPORTER_PERKG_DESC',
  VarianceRateTransporterToUseAsc = 'VARIANCE_RATE_TRANSPORTER_TO_USE_ASC',
  VarianceRateTransporterToUseDesc = 'VARIANCE_RATE_TRANSPORTER_TO_USE_DESC',
  VempreoffinfoAsc = 'VEMPREOFFINFO_ASC',
  VempreoffinfoDesc = 'VEMPREOFFINFO_DESC',
  VesselAsc = 'VESSEL_ASC',
  VesselDesc = 'VESSEL_DESC',
  WbslrhqAsc = 'WBSLRHQ_ASC',
  WbslrhqDesc = 'WBSLRHQ_DESC',
  WbsofhqAsc = 'WBSOFHQ_ASC',
  WbsofhqDesc = 'WBSOFHQ_DESC',
  WorkflowIdAsc = 'WORKFLOW_ID_ASC',
  WorkflowIdDesc = 'WORKFLOW_ID_DESC',
  WorkflowNameAsc = 'WORKFLOW_NAME_ASC',
  WorkflowNameDesc = 'WORKFLOW_NAME_DESC'
}

export type Order = {
  __typename?: 'Order';
  additionalDestinations?: Maybe<Array<Maybe<Depot>>>;
  allocation?: Maybe<Scalars['JSONString']>;
  amount?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customField?: Maybe<Scalars['JSONString']>;
  dateDeadlineToLift?: Maybe<Scalars['String']>;
  dateLoading?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['ID']>;
  isBackhaul?: Maybe<Scalars['Boolean']>;
  isContainerCoload?: Maybe<Scalars['Boolean']>;
  lroId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['ID']>;
  origin?: Maybe<Scalars['ID']>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  edges: Array<Maybe<OrderEdge>>;
  pageInfo: PageInfo;
};

export type OrderEdge = {
  __typename?: 'OrderEdge';
  cursor: Scalars['String'];
  node?: Maybe<OrderType>;
};

export type OrderInfoSnacks = {
  __typename?: 'OrderInfoSnacks';
  daysToDeadline?: Maybe<Scalars['Int']>;
  orderAge?: Maybe<Scalars['Int']>;
  totalMatchedWeight?: Maybe<Scalars['Int']>;
  trucksMatched?: Maybe<Scalars['Int']>;
  trucksNeeded?: Maybe<Scalars['Int']>;
  trucksOutstanding?: Maybe<Scalars['Int']>;
  weightLoaded?: Maybe<Scalars['Int']>;
  weightOutstanding?: Maybe<Scalars['Int']>;
};

export type OrderInput = {
  allocation?: InputMaybe<Scalars['JSONString']>;
  amount?: InputMaybe<Scalars['Int']>;
  clientId?: InputMaybe<Scalars['ID']>;
  customField?: InputMaybe<Scalars['JSONString']>;
  dateDeadlineToLift?: InputMaybe<Scalars['String']>;
  dateLoading?: InputMaybe<Scalars['String']>;
  destination?: InputMaybe<Scalars['ID']>;
  isBackhaul?: InputMaybe<Scalars['Boolean']>;
  lroId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['ID']>;
  source?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type OrdersFilter = {
  sourceApp?: InputMaybe<SourceAppTypeEnum>;
  states?: InputMaybe<Array<InputMaybe<OrderStateTypeEnum>>>;
};

export enum OrderSource {
  LcaEa = 'LCA_EA',
  LcaSa = 'LCA_SA',
  LcaWa = 'LCA_WA',
  LfaEa = 'LFA_EA',
  LfaWa = 'LFA_WA',
  LiaEa = 'LIA_EA',
  LiaSa = 'LIA_SA',
  LiaWa = 'LIA_WA',
  Trakit = 'TRAKIT',
  WhatsappEa = 'WHATSAPP_EA',
  WhatsappWa = 'WHATSAPP_WA'
}

export enum OrderState {
  Allocated = 'ALLOCATED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Consigned = 'CONSIGNED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Matched = 'MATCHED',
  Operational = 'OPERATIONAL',
  Opportunity = 'OPPORTUNITY',
  Template = 'TEMPLATE'
}

export enum OrderStateTypeEnum {
  Allocated = 'ALLOCATED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Consigned = 'CONSIGNED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Matched = 'MATCHED',
  Operational = 'OPERATIONAL',
  Opportunity = 'OPPORTUNITY',
  Template = 'TEMPLATE'
}

export enum OrderStatusTypeEnum {
  Unfulfilled = 'UNFULFILLED'
}

export type OrderType = Node & {
  __typename?: 'OrderType';
  additionalDestinations?: Maybe<Array<Maybe<Depot>>>;
  allocation?: Maybe<Scalars['JSONString']>;
  amount?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['Float']>;
  containerInfo?: Maybe<Array<Maybe<Scalars['JSONDict']>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customField?: Maybe<Scalars['JSONDict']>;
  dateDeadlineToLift?: Maybe<Scalars['DateTime']>;
  dateLoading?: Maybe<Scalars['DateTime']>;
  daysToDeadline?: Maybe<Scalars['Int']>;
  destination: Scalars['Float'];
  id: Scalars['ID'];
  isBackhaul?: Maybe<Scalars['Boolean']>;
  lroId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameUpper?: Maybe<Scalars['String']>;
  orderId: Scalars['Float'];
  origin?: Maybe<Scalars['Float']>;
  proposedOrder?: Maybe<LiaOrderType>;
  source: OrderSource;
  state: OrderState;
  trucksNeeded?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum OrderTypeSortEnum {
  AllocationAsc = 'ALLOCATION_ASC',
  AllocationDesc = 'ALLOCATION_DESC',
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  ClientIdAsc = 'CLIENT_ID_ASC',
  ClientIdDesc = 'CLIENT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CustomFieldAsc = 'CUSTOM_FIELD_ASC',
  CustomFieldDesc = 'CUSTOM_FIELD_DESC',
  DateDeadlineToLiftAsc = 'DATE_DEADLINE_TO_LIFT_ASC',
  DateDeadlineToLiftDesc = 'DATE_DEADLINE_TO_LIFT_DESC',
  DateLoadingAsc = 'DATE_LOADING_ASC',
  DateLoadingDesc = 'DATE_LOADING_DESC',
  DestinationAsc = 'DESTINATION_ASC',
  DestinationDesc = 'DESTINATION_DESC',
  LroIdAsc = 'LRO_ID_ASC',
  LroIdDesc = 'LRO_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OrderIdAsc = 'ORDER_ID_ASC',
  OrderIdDesc = 'ORDER_ID_DESC',
  OriginAsc = 'ORIGIN_ASC',
  OriginDesc = 'ORIGIN_DESC',
  SourceAsc = 'SOURCE_ASC',
  SourceDesc = 'SOURCE_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type PackagingType = {
  __typename?: 'PackagingType';
  name?: Maybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PayerPayeeType = {
  __typename?: 'PayerPayeeType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type RootMutation = {
  __typename?: 'RootMutation';
  addFuelStation?: Maybe<AddFuelStation>;
  allocateAllocation?: Maybe<AllocateAllocation>;
  ApproveFuelRequest?: Maybe<ApproveFuelRequest>;
  bulkUpload?: Maybe<BulkUploadMutation>;
  cancelExtraneousCharge?: Maybe<CancelExtraneousCharge>;
  cancelFuelRequest?: Maybe<CancelFuelRequest>;
  cancelOrder?: Maybe<CancelOrder>;
  compareCargoRate?: Maybe<CompareCargoRate>;
  confirmOrders?: Maybe<ConfirmOrders>;
  createDraftOrder?: Maybe<CreateDraftOrder>;
  createExtraneousCharge?: Maybe<CreateExtraneousCharge>;
  createFuelRequest?: Maybe<CreateFuelRequest>;
  createIssue?: Maybe<CreateIssue>;
  createLiaOrder?: Maybe<CreateLiaOrder>;
  createMove?: Maybe<CreateMove>;
  createMoveCheckpoints?: Maybe<CreateMovesChekPoints>;
  createOrder?: Maybe<CreateOrder>;
  createTemplate?: Maybe<CreateTemplate>;
  createTruckSupply?: Maybe<CreateTruckSupply>;
  deallocateAllocation?: Maybe<DeallocateAllocation>;
  editExtraneousCharge?: Maybe<EditExtraneousCharge>;
  financingFuelBulkUpload?: Maybe<FinancingFuelBulkUploadMutation>;
  fuelRateBulkUpload?: Maybe<FuelRateBulkUploadMutation>;
  linkAssetToTruck?: Maybe<LinkAssetToTruck>;
  matchTrucks?: Maybe<MatchTrucks>;
  supplyTruckAllocation?: Maybe<SupplyTruckAllocation>;
  unlinkAssetFromTruck?: Maybe<UnLinkAssetFromTruck>;
  unmatchTrucks?: Maybe<UnmatchTrucks>;
  updateBidStatus?: Maybe<UpdateBidStatus>;
  updateFuelRequest?: Maybe<UpdateFuelRequest>;
  updateMove?: Maybe<UpdateMove>;
  updateMoveCheckpoints?: Maybe<UpdateMovesChekPoints>;
  updateOperation?: Maybe<UpdateOperation>;
  updateOrder?: Maybe<UpdateOrder>;
  updateTruckSupply?: Maybe<UpdateTruckSupply>;
  updateWorkOrder?: Maybe<UpdateWorkOrder>;
  uploadDocuments?: Maybe<UploadDocumentsMutation>;
  validateFuelRequest?: Maybe<ValidateFuelRequest>;
  validateMove?: Maybe<ValidateMove>;
};


export type RootMutationAddFuelStationArgs = {
  input?: InputMaybe<FuelStationInput>;
};


export type RootMutationAllocateAllocationArgs = {
  lroId: Scalars['ID'];
  supplyId: Scalars['ID'];
};


export type RootMutationApproveFuelRequestArgs = {
  approvalState: Scalars['String'];
  moveId: Scalars['ID'];
};


export type RootMutationBulkUploadArgs = {
  file: Scalars['Upload'];
  mappedColumns: Scalars['GenericScalar'];
};


export type RootMutationCancelExtraneousChargeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type RootMutationCancelFuelRequestArgs = {
  moveId: Scalars['ID'];
};


export type RootMutationCancelOrderArgs = {
  input?: InputMaybe<CancelOrderInput>;
  orderId: Scalars['ID'];
};


export type RootMutationCompareCargoRateArgs = {
  cargoType: Scalars['String'];
  clientId: Scalars['Int'];
  currency: Scalars['String'];
  destinationDepotCode: Scalars['String'];
  originDepotCode: Scalars['String'];
  rate: Scalars['Float'];
  rateType: Scalars['String'];
};


export type RootMutationConfirmOrdersArgs = {
  orderIds: Array<InputMaybe<Scalars['Int']>>;
};


export type RootMutationCreateDraftOrderArgs = {
  input?: InputMaybe<CreateOrderInput>;
  shipper: Scalars['String'];
  workflow?: InputMaybe<Scalars['String']>;
};


export type RootMutationCreateExtraneousChargeArgs = {
  input?: InputMaybe<ExtraneousChargeInput>;
};


export type RootMutationCreateFuelRequestArgs = {
  input?: InputMaybe<CreateFuelRequestInput>;
};


export type RootMutationCreateIssueArgs = {
  issue?: InputMaybe<CreateIssueInput>;
};


export type RootMutationCreateLiaOrderArgs = {
  liaOrder?: InputMaybe<LiaOderInputType>;
};


export type RootMutationCreateMoveArgs = {
  input?: InputMaybe<MoveInput>;
};


export type RootMutationCreateMoveCheckpointsArgs = {
  input?: InputMaybe<MoveCheckPointsInput>;
  moveId: Scalars['ID'];
};


export type RootMutationCreateOrderArgs = {
  input?: InputMaybe<CreateOrderInput>;
  shipper: Scalars['String'];
  workflow: Scalars['String'];
};


export type RootMutationCreateTemplateArgs = {
  input?: InputMaybe<CreateOrderInput>;
  shipper: Scalars['String'];
  workflow?: InputMaybe<Scalars['String']>;
};


export type RootMutationCreateTruckSupplyArgs = {
  alternativeTransporterRate?: InputMaybe<Scalars['String']>;
  bidPrice?: InputMaybe<Scalars['Float']>;
  cargoType?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  etaLoadingPoint: Scalars['DateTime'];
  extra?: InputMaybe<Scalars['JSONString']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isBid?: InputMaybe<Scalars['Boolean']>;
  loadingSiteId?: InputMaybe<Scalars['Int']>;
  loriRate?: InputMaybe<Scalars['Float']>;
  lroId?: InputMaybe<Scalars['String']>;
  numTrucks: Scalars['Int'];
  rate?: InputMaybe<Scalars['Float']>;
  rateCurrencyType?: InputMaybe<Scalars['String']>;
  rateType?: InputMaybe<Scalars['String']>;
  source: SupplySourceType;
  trailerType: Scalars['String'];
  transporterEntid: Scalars['Int'];
  transporterName: Scalars['String'];
  transportType?: InputMaybe<Scalars['String']>;
  truckList?: InputMaybe<Array<InputMaybe<TruckInput>>>;
};


export type RootMutationDeallocateAllocationArgs = {
  email?: InputMaybe<Scalars['String']>;
  supplyId: Scalars['ID'];
};


export type RootMutationEditExtraneousChargeArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<ExtraneousChargeInput>;
};


export type RootMutationFinancingFuelBulkUploadArgs = {
  file: Scalars['Upload'];
  mappedColumns: Scalars['GenericScalar'];
};


export type RootMutationFuelRateBulkUploadArgs = {
  file: Scalars['Upload'];
  mappedColumns: Scalars['GenericScalar'];
};


export type RootMutationLinkAssetToTruckArgs = {
  linkAssetSerial: Scalars['String'];
  replace?: InputMaybe<Scalars['Boolean']>;
  truckSerial: Scalars['String'];
};


export type RootMutationMatchTrucksArgs = {
  lroId: Scalars['ID'];
  supplyId: Scalars['Int'];
  truckList: Array<InputMaybe<TruckMatchingInputType>>;
};


export type RootMutationSupplyTruckAllocationArgs = {
  allocationType: SupplyAllocationTypeEnum;
  email?: InputMaybe<Scalars['String']>;
  supplyId: Scalars['Int'];
  truckData: TruckInput;
};


export type RootMutationUnlinkAssetFromTruckArgs = {
  truckSerial: Scalars['String'];
  unlinkAssetSerial: Scalars['String'];
};


export type RootMutationUnmatchTrucksArgs = {
  email?: InputMaybe<Scalars['String']>;
  lroId: Scalars['ID'];
  supplyId: Scalars['Int'];
  truckList: Array<InputMaybe<TruckUnmatchingType>>;
  unmatchingReason: UnmatchingReasonEnum;
};


export type RootMutationUpdateBidStatusArgs = {
  bidStatus: Scalars['Boolean'];
  supplyId: Scalars['Int'];
};


export type RootMutationUpdateFuelRequestArgs = {
  input?: InputMaybe<UpdateFuelRequestInput>;
  moveId: Scalars['ID'];
};


export type RootMutationUpdateMoveArgs = {
  input?: InputMaybe<MoveInput>;
  moveId: Scalars['ID'];
};


export type RootMutationUpdateMoveCheckpointsArgs = {
  input?: InputMaybe<MoveCheckPointsInput>;
  moveId: Scalars['ID'];
};


export type RootMutationUpdateOperationArgs = {
  input?: InputMaybe<OperationInput>;
  moveId: Scalars['ID'];
};


export type RootMutationUpdateOrderArgs = {
  input?: InputMaybe<OrderInput>;
  orderId: Scalars['ID'];
};


export type RootMutationUpdateTruckSupplyArgs = {
  alternativeTransporterRate?: InputMaybe<Scalars['String']>;
  bidPrice?: InputMaybe<Scalars['Float']>;
  cargoType?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  etaLoadingPoint?: InputMaybe<Scalars['DateTime']>;
  extra?: InputMaybe<Scalars['JSONString']>;
  isBid?: InputMaybe<Scalars['Boolean']>;
  loadingSiteId?: InputMaybe<Scalars['Int']>;
  loriRate?: InputMaybe<Scalars['Float']>;
  rate?: InputMaybe<Scalars['Float']>;
  rateCurrencyType?: InputMaybe<Scalars['String']>;
  rateType?: InputMaybe<Scalars['String']>;
  supplyId: Scalars['Int'];
  trailerType?: InputMaybe<Scalars['String']>;
  transporterEntid?: InputMaybe<Scalars['Int']>;
  transporterName?: InputMaybe<Scalars['String']>;
  transportType?: InputMaybe<Scalars['String']>;
};


export type RootMutationUpdateWorkOrderArgs = {
  workOrderData: WorkOrderInputType;
};


export type RootMutationUploadDocumentsArgs = {
  files: Scalars['Upload'];
  input?: InputMaybe<MoveDocumentInput>;
  lroid: Scalars['String'];
  woid: Scalars['String'];
};


export type RootMutationValidateFuelRequestArgs = {
  fuelId: Scalars['ID'];
};


export type RootMutationValidateMoveArgs = {
  moveId: Scalars['ID'];
};

export type RootQuery = {
  __typename?: 'RootQuery';
  allExtraneousCharges?: Maybe<Array<Maybe<ExtraneousChargesType>>>;
  allTrucks?: Maybe<Array<Maybe<TrucksType>>>;
  allWorkflows?: Maybe<Array<Maybe<WorkflowsType>>>;
  availableInfosnack?: Maybe<Scalars['Int']>;
  cargoRateComparison?: Maybe<CargoRateComparisonType>;
  clearingAgents?: Maybe<Array<Maybe<ClearingAgent>>>;
  clientCargoRate?: Maybe<Scalars['String']>;
  clients?: Maybe<Array<Maybe<Client>>>;
  commodities?: Maybe<Array<Maybe<Commodity>>>;
  consignees?: Maybe<Array<Maybe<Consignee>>>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  currencies?: Maybe<Array<Maybe<CurrenciesType>>>;
  delayedInfosnack?: Maybe<Scalars['Int']>;
  depots?: Maybe<Array<Maybe<Depot>>>;
  documentTypes?: Maybe<Array<Maybe<DocumentTypesType>>>;
  emptyDestinationDepots?: Maybe<Array<Maybe<EmptyReturnDestinationDepot>>>;
  extraneousCharge?: Maybe<Array<Maybe<ExtraneousChargesType>>>;
  extraneousChargeTypes?: Maybe<Array<Maybe<ExtraneousChargeTypesType>>>;
  fieldUpdateEventDetails?: Maybe<Array<Maybe<FieldUpadateEvent>>>;
  fuelingEvent?: Maybe<FuelingEventType>;
  fuelingEvents?: Maybe<FuelingEventConnection>;
  fuelingEventsByApprovalState?: Maybe<Array<Maybe<FuelingEventType>>>;
  fuelStations?: Maybe<Array<Maybe<FuelStationType>>>;
  inboundInfosnack?: Maybe<Scalars['Int']>;
  issues?: Maybe<Array<Maybe<IssueType>>>;
  issueTypeCategories?: Maybe<Array<Maybe<IssueTypeCategoryType>>>;
  issueTypes?: Maybe<Array<Maybe<IssueTypesType>>>;
  lcaOrders?: Maybe<OrderConnection>;
  liaOrders?: Maybe<LiaOrderTypeConnection>;
  loadedInfosnack?: Maybe<Scalars['Int']>;
  matchingActions?: Maybe<MatchingActionTypeConnection>;
  moveDocuments?: Maybe<Array<Maybe<MoveDocumentType>>>;
  moves?: Maybe<MoveTypeConnectionsConnection>;
  newOrders?: Maybe<CustomOrderConnection>;
  node?: Maybe<Node>;
  numTrucksInbound?: Maybe<Scalars['Int']>;
  numTrucksLoaded?: Maybe<Scalars['Int']>;
  numTrucksLoading?: Maybe<Scalars['Int']>;
  onJourneyInfosnack?: Maybe<Scalars['Int']>;
  openOrdersCount?: Maybe<Scalars['Int']>;
  operation?: Maybe<OperationType>;
  operations?: Maybe<OperationTypeConnection>;
  order?: Maybe<Order>;
  orderInfoSnacks?: Maybe<OrderInfoSnacks>;
  orders?: Maybe<OrderConnection>;
  orderTemplates?: Maybe<OrderConnection>;
  packagingTypes?: Maybe<Array<Maybe<PackagingType>>>;
  payersPayees?: Maybe<Array<Maybe<PayerPayeeType>>>;
  sensitiveFlagTypes?: Maybe<Array<Maybe<SensitiveFlagTypesType>>>;
  timeSensitiveFlagsConfigs?: Maybe<Array<Maybe<TimeSensitiveFlagsConfigType>>>;
  totalTrucksOutstanding?: Maybe<Scalars['Int']>;
  trailerSubTypes?: Maybe<Array<Maybe<TrailerSubType>>>;
  trailerTypes?: Maybe<Array<Maybe<TrailerType>>>;
  transporters?: Maybe<Array<Maybe<TransporterType>>>;
  transportersLia?: Maybe<Array<Maybe<TransportersType>>>;
  trucksNeededByCargoType?: Maybe<TrucksNeededByCargoType>;
  truckSupplies?: Maybe<TruckSupplyTypeConnection>;
  truckSupply?: Maybe<TruckSupplyType>;
  unmatchedTodayInfosnack?: Maybe<Scalars['Int']>;
  vessels?: Maybe<Array<Maybe<Vessel>>>;
  workflows?: Maybe<Array<Maybe<Workflow>>>;
  workOrder?: Maybe<WorkOrderType>;
};


export type RootQueryCargoRateComparisonArgs = {
  cargoType: Scalars['String'];
  clientId: Scalars['Int'];
  currency: Scalars['String'];
  destinationDepotCode: Scalars['String'];
  originDepotCode: Scalars['String'];
  rateType: Scalars['String'];
};


export type RootQueryClearingAgentsArgs = {
  entId?: InputMaybe<Scalars['Int']>;
  location: Scalars['String'];
};


export type RootQueryClientCargoRateArgs = {
  clientName: Scalars['String'];
  destination: Scalars['String'];
  origin: Scalars['String'];
  trailerCapacity: Scalars['String'];
};


export type RootQueryClientsArgs = {
  entId?: InputMaybe<Scalars['Int']>;
  location: Scalars['String'];
};


export type RootQueryConsigneesArgs = {
  entId?: InputMaybe<Scalars['Int']>;
  location: Scalars['String'];
};


export type RootQueryContactsArgs = {
  contactType?: InputMaybe<Scalars['String']>;
  fsid: Scalars['ID'];
};


export type RootQueryDepotsArgs = {
  entId?: InputMaybe<Scalars['Int']>;
  geoId?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
};


export type RootQueryDocumentTypesArgs = {
  region?: InputMaybe<Scalars['String']>;
};


export type RootQueryEmptyDestinationDepotsArgs = {
  location?: InputMaybe<Scalars['String']>;
};


export type RootQueryExtraneousChargeArgs = {
  moveId: Scalars['Int'];
};


export type RootQueryFieldUpdateEventDetailsArgs = {
  woid: Scalars['String'];
};


export type RootQueryFuelingEventArgs = {
  fuelingId: Scalars['Int'];
};


export type RootQueryFuelingEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  approvalState?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['Int']>;
  dateRange?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  fuelId?: InputMaybe<Scalars['Int']>;
  globalSearch?: InputMaybe<GlobalSearchType>;
  isValide?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  lroId?: InputMaybe<Scalars['String']>;
  originCode?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<FuelingEventTypeSortEnum>>>;
  transporterEntId?: InputMaybe<Scalars['String']>;
  truckReg?: InputMaybe<Scalars['String']>;
  woId?: InputMaybe<Scalars['Int']>;
  workflowId?: InputMaybe<Scalars['String']>;
};


export type RootQueryFuelingEventsByApprovalStateArgs = {
  approvalState: Scalars['String'];
};


export type RootQueryInboundInfosnackArgs = {
  entid?: InputMaybe<Scalars['Int']>;
};


export type RootQueryIssuesArgs = {
  moveId: Scalars['String'];
};


export type RootQueryIssueTypesArgs = {
  issueTypeCategoryId?: InputMaybe<Scalars['Int']>;
};


export type RootQueryLcaOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  client?: InputMaybe<Scalars['String']>;
  clientId: Scalars['Int'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<OrderTypeSortEnum>>>;
  state?: InputMaybe<Scalars['String']>;
};


export type RootQueryLiaOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<LiaOrderTypeSortEnum>>>;
};


export type RootQueryLoadedInfosnackArgs = {
  entid?: InputMaybe<Scalars['Int']>;
};


export type RootQueryMatchingActionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<MatchingActionTypeSortEnum>>>;
};


export type RootQueryMoveDocumentsArgs = {
  moveId: Scalars['String'];
};


export type RootQueryMovesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<MoveTypeSortEnum>>>;
};


export type RootQueryNewOrdersArgs = {
  accountManagerEmail?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['String']>;
  dateLoading?: InputMaybe<Scalars['Date']>;
  destination?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<Array<InputMaybe<OrdersFilter>>>;
  first?: InputMaybe<Scalars['Int']>;
  globalSearch?: InputMaybe<GlobalSearchType>;
  jobStatus?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  location: Scalars['String'];
  lroId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['Int']>;
  orderStatus?: InputMaybe<OrderStatusTypeEnum>;
  origin?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Scalars['String']>;
  sourceApp?: InputMaybe<SourceAppTypeEnum>;
  state?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<Array<InputMaybe<OrderStateTypeEnum>>>;
};


export type RootQueryNodeArgs = {
  id: Scalars['ID'];
};


export type RootQueryNumTrucksInboundArgs = {
  lroId: Scalars['String'];
};


export type RootQueryNumTrucksLoadedArgs = {
  lroId: Scalars['String'];
};


export type RootQueryNumTrucksLoadingArgs = {
  lroId: Scalars['String'];
};


export type RootQueryOperationArgs = {
  moveId: Scalars['Int'];
};


export type RootQueryOperationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  cargoTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clientId?: InputMaybe<Scalars['Int']>;
  createdByEmail?: InputMaybe<Scalars['String']>;
  destinationCode?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  globalSearch?: InputMaybe<GlobalSearchType>;
  last?: InputMaybe<Scalars['Int']>;
  loadingSiteId?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  lroId?: InputMaybe<Scalars['String']>;
  notValidated?: InputMaybe<Scalars['Boolean']>;
  originCode?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<OperationTypeSortEnum>>>;
  status?: InputMaybe<Scalars['String']>;
  trailerType?: InputMaybe<Scalars['String']>;
  transporterEntId?: InputMaybe<Scalars['String']>;
  transportType?: InputMaybe<Scalars['String']>;
  truckReg?: InputMaybe<Scalars['String']>;
  woId?: InputMaybe<Scalars['Int']>;
  workflowId?: InputMaybe<Scalars['String']>;
};


export type RootQueryOrderArgs = {
  lroId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['ID']>;
};


export type RootQueryOrderInfoSnacksArgs = {
  lroId: Scalars['ID'];
};


export type RootQueryOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<OrdersFilter>>>;
  first?: InputMaybe<Scalars['Int']>;
  globalSearch?: InputMaybe<GlobalSearchType>;
  last?: InputMaybe<Scalars['Int']>;
  location: Scalars['String'];
  sort?: InputMaybe<Array<InputMaybe<OrderTypeSortEnum>>>;
  states?: InputMaybe<Array<InputMaybe<OrderStateTypeEnum>>>;
};


export type RootQueryOrderTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<OrderTypeSortEnum>>>;
};


export type RootQueryTimeSensitiveFlagsConfigsArgs = {
  region: Scalars['String'];
};


export type RootQueryTruckSuppliesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  cargoType?: InputMaybe<Scalars['String']>;
  createdByEmail?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  globalSearch?: InputMaybe<GlobalSearchType>;
  last?: InputMaybe<Scalars['Int']>;
  loadingSiteId?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  lroId?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<TruckSupplyTypeSortEnum>>>;
  trailerType?: InputMaybe<Scalars['String']>;
  transporterEntId?: InputMaybe<Scalars['Int']>;
  transportType?: InputMaybe<TransitTypeEnum>;
};


export type RootQueryTruckSupplyArgs = {
  id: Scalars['Int'];
};


export type RootQueryWorkOrderArgs = {
  woid: Scalars['Int'];
};

export type SensitiveFlagTypesType = {
  __typename?: 'SensitiveFlagTypesType';
  id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
};

export enum SourceAppTypeEnum {
  Lca = 'LCA',
  Lia = 'LIA',
  Trakit = 'TRAKIT',
  Whatsapp = 'WHATSAPP'
}

export enum SupplyAllocationTypeEnum {
  Addition = 'ADDITION',
  Edit = 'EDIT',
  Removal = 'REMOVAL'
}

export enum SupplySourceEnum {
  LcaSa = 'LCA_SA',
  LfaEa = 'LFA_EA',
  LfaWa = 'LFA_WA',
  LiaEa = 'LIA_EA',
  LiaSa = 'LIA_SA',
  LiaWa = 'LIA_WA',
  LtaEa = 'LTA_EA',
  LtaSa = 'LTA_SA',
  LtaWa = 'LTA_WA'
}

export enum SupplySourceType {
  LcaSa = 'LCA_SA',
  LfaEa = 'LFA_EA',
  LfaWa = 'LFA_WA',
  LiaEa = 'LIA_EA',
  LiaSa = 'LIA_SA',
  LiaWa = 'LIA_WA',
  LtaEa = 'LTA_EA',
  LtaSa = 'LTA_SA',
  LtaWa = 'LTA_WA'
}

export type SupplyTruckAllocation = {
  __typename?: 'SupplyTruckAllocation';
  allocatedTruck?: Maybe<TruckType>;
  supplyId?: Maybe<Scalars['Int']>;
};

export type TimeSensitiveFlagsConfigType = {
  __typename?: 'TimeSensitiveFlagsConfigType';
  fuelRequestMaxDays?: Maybe<Scalars['Int']>;
  fuelRequestMinDays?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  inboundMatchedMax?: Maybe<Scalars['Int']>;
  inboundMatchedMin?: Maybe<Scalars['Int']>;
  insideLoadingPointMax?: Maybe<Scalars['Int']>;
  insideLoadingPointMin?: Maybe<Scalars['Int']>;
  insideOffloadingPointMax?: Maybe<Scalars['Int']>;
  insideOffloadingPointMin?: Maybe<Scalars['Int']>;
  outsideLoadingPointMax?: Maybe<Scalars['Int']>;
  outsideLoadingPointMin?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  sensitiveType?: Maybe<SensitiveFlagTypesType>;
  typeId?: Maybe<Scalars['Int']>;
};

export type TrailersType = {
  __typename?: 'TrailersType';
  serial: Scalars['String'];
  trailerSubType?: Maybe<Scalars['String']>;
  trailerType?: Maybe<Scalars['String']>;
};

export type TrailerSubType = {
  __typename?: 'TrailerSubType';
  name?: Maybe<Scalars['String']>;
};

export type TrailerType = {
  __typename?: 'TrailerType';
  name?: Maybe<Scalars['String']>;
};

export enum TransitTypeEnum {
  Local = 'LOCAL',
  Transit = 'TRANSIT'
}

export enum TransporterPaymentPeriodEnum {
  FourteenDays = 'FOURTEEN_DAYS',
  FourtyFiveDays = 'FOURTY_FIVE_DAYS',
  SevenDays = 'SEVEN_DAYS',
  SixtyDays = 'SIXTY_DAYS',
  ThirtyDays = 'THIRTY_DAYS',
  TwentyOneDays = 'TWENTY_ONE_DAYS',
  ZeroDays = 'ZERO_DAYS'
}

export type TransportersType = {
  __typename?: 'TransportersType';
  accountManager?: Maybe<Scalars['String']>;
  accountManagerEmail?: Maybe<Scalars['String']>;
  companyEmail?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  trakitEntityId?: Maybe<Scalars['Int']>;
};

export type TransporterType = {
  __typename?: 'TransporterType';
  id: Scalars['Int'];
  name: Scalars['String'];
  tracking: Scalars['String'];
};

export type TruckInput = {
  action?: InputMaybe<Scalars['String']>;
  driverEntId?: InputMaybe<Scalars['Int']>;
  driverIdNumber?: InputMaybe<Scalars['String']>;
  entId?: InputMaybe<Scalars['Int']>;
  etaLoadingPoint?: InputMaybe<Scalars['DateTime']>;
  loadingSiteId?: InputMaybe<Scalars['Int']>;
  lroId?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  serial: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  trailerReg?: InputMaybe<Scalars['String']>;
  trailerType?: InputMaybe<Scalars['String']>;
  truckReg?: InputMaybe<Scalars['String']>;
  woid?: InputMaybe<Scalars['String']>;
  workOrderData?: InputMaybe<WorkOrderMatchingInfoInputType>;
};

export type TruckMatchingInputType = {
  etaLoadingPoint?: InputMaybe<Scalars['DateTime']>;
  serial: Scalars['String'];
};

export type TrucksNeededByCargoType = {
  __typename?: 'TrucksNeededByCargoType';
  baggedBulkLocal?: Maybe<Scalars['Int']>;
  baggedBulkTransit?: Maybe<Scalars['Int']>;
  containerLocal?: Maybe<Scalars['Int']>;
  containerTransit?: Maybe<Scalars['Int']>;
  fmcgLocal?: Maybe<Scalars['Int']>;
  fmcgTransit?: Maybe<Scalars['Int']>;
};

export type TrucksType = {
  __typename?: 'TrucksType';
  cargoCapacity?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  ectsExpiry?: Maybe<Scalars['DateTime']>;
  ectsProvider?: Maybe<Scalars['String']>;
  gitInsurance?: Maybe<Scalars['String']>;
  insuranceExpiry?: Maybe<Scalars['DateTime']>;
  insuranceProvider?: Maybe<Scalars['String']>;
  kwatos?: Maybe<Scalars['String']>;
  linkedTo?: Maybe<Scalars['Int']>;
  loadingType?: Maybe<Scalars['String']>;
  loriCaptiveScheme?: Maybe<Scalars['String']>;
  loriInsuranceScheme?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  ntsaInspectionExpiry?: Maybe<Scalars['DateTime']>;
  onBoardingStatus?: Maybe<Scalars['String']>;
  portPass?: Maybe<Scalars['String']>;
  portPassExpiry?: Maybe<Scalars['DateTime']>;
  registeredAtIcd?: Maybe<Scalars['String']>;
  roadWorthinessCertificate?: Maybe<Scalars['String']>;
  serial: Scalars['String'];
  tglTransitPassComesa?: Maybe<Scalars['String']>;
  tglTransitPassExpiry?: Maybe<Scalars['String']>;
  trailerReg?: Maybe<Scalars['String']>;
  transitPass?: Maybe<Scalars['String']>;
  transporterId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  vettedBy?: Maybe<Scalars['String']>;
  vettedDate?: Maybe<Scalars['DateTime']>;
  yom?: Maybe<Scalars['String']>;
};

export type TruckSupplyType = Node & {
  __typename?: 'TruckSupplyType';
  allocatedTrucks?: Maybe<Array<Maybe<TruckType>>>;
  alternativeTransporterRate?: Maybe<Scalars['String']>;
  availableTrucks?: Maybe<Array<Maybe<TruckType>>>;
  bidPrice?: Maybe<Scalars['Float']>;
  cargoType?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  createdByEmail?: Maybe<Scalars['String']>;
  etaLoadingPoint: Scalars['DateTime'];
  extra?: Maybe<Scalars['JSONString']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isBid?: Maybe<Scalars['Boolean']>;
  isBidAccepted?: Maybe<Scalars['Boolean']>;
  loadingSiteId?: Maybe<Scalars['Int']>;
  loadingSiteName?: Maybe<Scalars['String']>;
  loriRate?: Maybe<Scalars['Float']>;
  lroId?: Maybe<Scalars['String']>;
  matchedTrucks?: Maybe<Array<Maybe<TruckType>>>;
  numTrucks: Scalars['Int'];
  proposedOrder?: Maybe<LiaOrderType>;
  rate?: Maybe<Scalars['Float']>;
  rateCurrencyType?: Maybe<Scalars['String']>;
  rateType?: Maybe<Scalars['String']>;
  source?: Maybe<SupplySourceEnum>;
  supplyId?: Maybe<Scalars['Int']>;
  trailerType: Scalars['String'];
  transporterEntid: Scalars['Int'];
  transporterName?: Maybe<Scalars['String']>;
  transporterPaymentPeriod?: Maybe<Scalars['String']>;
  transportType?: Maybe<Scalars['String']>;
  truckList?: Maybe<Scalars['JSONString']>;
};

export type TruckSupplyTypeConnection = {
  __typename?: 'TruckSupplyTypeConnection';
  edges: Array<Maybe<TruckSupplyTypeEdge>>;
  pageInfo: PageInfo;
};

export type TruckSupplyTypeEdge = {
  __typename?: 'TruckSupplyTypeEdge';
  cursor: Scalars['String'];
  node?: Maybe<TruckSupplyType>;
};

export enum TruckSupplyTypeSortEnum {
  AlternativeTransporterRateAsc = 'ALTERNATIVE_TRANSPORTER_RATE_ASC',
  AlternativeTransporterRateDesc = 'ALTERNATIVE_TRANSPORTER_RATE_DESC',
  BidPriceAsc = 'BID_PRICE_ASC',
  BidPriceDesc = 'BID_PRICE_DESC',
  CargoTypeAsc = 'CARGO_TYPE_ASC',
  CargoTypeDesc = 'CARGO_TYPE_DESC',
  CommentsAsc = 'COMMENTS_ASC',
  CommentsDesc = 'COMMENTS_DESC',
  CreatedAsc = 'CREATED_ASC',
  CreatedByEmailAsc = 'CREATED_BY_EMAIL_ASC',
  CreatedByEmailDesc = 'CREATED_BY_EMAIL_DESC',
  CreatedDesc = 'CREATED_DESC',
  EtaLoadingPointAsc = 'ETA_LOADING_POINT_ASC',
  EtaLoadingPointDesc = 'ETA_LOADING_POINT_DESC',
  ExtraAsc = 'EXTRA_ASC',
  ExtraDesc = 'EXTRA_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  IsBidAcceptedAsc = 'IS_BID_ACCEPTED_ASC',
  IsBidAcceptedDesc = 'IS_BID_ACCEPTED_DESC',
  IsBidAsc = 'IS_BID_ASC',
  IsBidDesc = 'IS_BID_DESC',
  LoadingSiteIdAsc = 'LOADING_SITE_ID_ASC',
  LoadingSiteIdDesc = 'LOADING_SITE_ID_DESC',
  LoriRateAsc = 'LORI_RATE_ASC',
  LoriRateDesc = 'LORI_RATE_DESC',
  LroIdAsc = 'LRO_ID_ASC',
  LroIdDesc = 'LRO_ID_DESC',
  NumTrucksAsc = 'NUM_TRUCKS_ASC',
  NumTrucksDesc = 'NUM_TRUCKS_DESC',
  RateAsc = 'RATE_ASC',
  RateCurrencyTypeAsc = 'RATE_CURRENCY_TYPE_ASC',
  RateCurrencyTypeDesc = 'RATE_CURRENCY_TYPE_DESC',
  RateDesc = 'RATE_DESC',
  RateTypeAsc = 'RATE_TYPE_ASC',
  RateTypeDesc = 'RATE_TYPE_DESC',
  SourceAsc = 'SOURCE_ASC',
  SourceDesc = 'SOURCE_DESC',
  TrailerTypeAsc = 'TRAILER_TYPE_ASC',
  TrailerTypeDesc = 'TRAILER_TYPE_DESC',
  TransportTypeAsc = 'TRANSPORT_TYPE_ASC',
  TransportTypeDesc = 'TRANSPORT_TYPE_DESC',
  TransporterEntidAsc = 'TRANSPORTER_ENTID_ASC',
  TransporterEntidDesc = 'TRANSPORTER_ENTID_DESC',
  TransporterNameAsc = 'TRANSPORTER_NAME_ASC',
  TransporterNameDesc = 'TRANSPORTER_NAME_DESC',
  TruckListAsc = 'TRUCK_LIST_ASC',
  TruckListDesc = 'TRUCK_LIST_DESC'
}

export type TruckType = {
  __typename?: 'TruckType';
  action?: Maybe<Scalars['String']>;
  actionedAt?: Maybe<Scalars['String']>;
  actionedBy?: Maybe<Scalars['String']>;
  captive?: Maybe<Scalars['String']>;
  driverEntId?: Maybe<Scalars['Int']>;
  driverIdNumber?: Maybe<Scalars['String']>;
  driverName?: Maybe<Scalars['String']>;
  driverNationalId?: Maybe<Scalars['String']>;
  driverPhoneNumber?: Maybe<Scalars['String']>;
  entId?: Maybe<Scalars['Int']>;
  etaLoadingPoint?: Maybe<Scalars['DateTime']>;
  loadingSiteId?: Maybe<Scalars['Int']>;
  lroId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  serial: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  supplyId?: Maybe<Scalars['Int']>;
  tracking?: Maybe<Scalars['String']>;
  trailerEntId?: Maybe<Scalars['Int']>;
  trailerReg?: Maybe<Scalars['String']>;
  trailerType?: Maybe<Scalars['String']>;
  transporterEntId?: Maybe<Scalars['String']>;
  transporterName?: Maybe<Scalars['String']>;
  truckReg?: Maybe<Scalars['String']>;
  woid?: Maybe<Scalars['String']>;
  workOrderData?: Maybe<WorkOrderMatchingInfoType>;
};

export type TruckUnmatchingType = {
  serial: Scalars['String'];
  woid: Scalars['String'];
};

export type UnLinkAssetFromTruck = {
  __typename?: 'UnLinkAssetFromTruck';
  ok?: Maybe<Scalars['Boolean']>;
};

export enum UnmatchingReasonEnum {
  EtaNotMet = 'ETA_NOT_MET',
  NoOrder = 'NO_ORDER',
  OperationalIssue = 'OPERATIONAL_ISSUE',
  OrderCancelled = 'ORDER_CANCELLED',
  TruckDiverted = 'TRUCK_DIVERTED',
  TruckDoubleMatched = 'TRUCK_DOUBLE_MATCHED',
  UnusedWorkorder = 'UNUSED_WORKORDER'
}

export type UnmatchTrucks = {
  __typename?: 'UnmatchTrucks';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateBidStatus = {
  __typename?: 'UpdateBidStatus';
  bidStatus?: Maybe<Scalars['Boolean']>;
  supplyId?: Maybe<Scalars['Int']>;
};

export type UpdateFuelRequest = {
  __typename?: 'UpdateFuelRequest';
  fuelRequest?: Maybe<CreateFuelRequestType>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateFuelRequestInput = {
  amountFuelled?: InputMaybe<Scalars['Int']>;
  deplorifs?: InputMaybe<Scalars['String']>;
  fsInvoice?: InputMaybe<Scalars['String']>;
  fuelDataValidated?: InputMaybe<Scalars['String']>;
  fuelLpo?: InputMaybe<Scalars['String']>;
  fuelreqsent?: InputMaybe<Scalars['String']>;
  fuelreqtra?: InputMaybe<Scalars['String']>;
  fuelRequestAmount?: InputMaybe<Scalars['Int']>;
  fuelStation?: InputMaybe<Scalars['String']>;
  fuelStationId?: InputMaybe<Scalars['Int']>;
  fuelType?: InputMaybe<Scalars['String']>;
};

export type UpdateMove = {
  __typename?: 'UpdateMove';
  move?: Maybe<MoveType>;
};

export type UpdateMovesChekPoints = {
  __typename?: 'UpdateMovesChekPoints';
  moveCheckpoint?: Maybe<MoveCheckPointsType>;
};

export type UpdateOperation = {
  __typename?: 'UpdateOperation';
  operation?: Maybe<OperationType>;
};

export type UpdateOrder = {
  __typename?: 'UpdateOrder';
  order?: Maybe<OrderType>;
};

export type UpdateTruckSupply = {
  __typename?: 'UpdateTruckSupply';
  truckSupply?: Maybe<TruckSupplyType>;
};

export type UpdateWorkOrder = {
  __typename?: 'UpdateWorkOrder';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UploadDocumentsMutation = {
  __typename?: 'UploadDocumentsMutation';
  moveDocument?: Maybe<MoveDocumentType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ValidateFuelRequest = {
  __typename?: 'ValidateFuelRequest';
  success?: Maybe<Scalars['Boolean']>;
  validatedFuelRequest?: Maybe<ValidateFuelRequestType>;
};

export type ValidateFuelRequestType = {
  __typename?: 'ValidateFuelRequestType';
  amountFuelled?: Maybe<Scalars['Float']>;
  approvalDate?: Maybe<Scalars['DateTime']>;
  approvalState?: Maybe<Scalars['String']>;
  cancelled?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  deplorifs?: Maybe<Scalars['String']>;
  fsCountry?: Maybe<Scalars['String']>;
  fsInvoice?: Maybe<Scalars['String']>;
  fuelDataValidated?: Maybe<Scalars['DateTime']>;
  fuelLpo?: Maybe<Scalars['String']>;
  fuelreqsent?: Maybe<Scalars['DateTime']>;
  fuelreqtra?: Maybe<Scalars['DateTime']>;
  fuelRequestAmount?: Maybe<Scalars['Float']>;
  fuelstation?: Maybe<FuelStationType>;
  fuelStation?: Maybe<Scalars['String']>;
  fuelStationCode?: Maybe<Scalars['String']>;
  fuelStationId?: Maybe<Scalars['Int']>;
  fuelType?: Maybe<Scalars['String']>;
  lroId?: Maybe<Scalars['Int']>;
  move?: Maybe<MoveType>;
  moveId: Scalars['ID'];
  transporter?: Maybe<TransportersType>;
  transporterId?: Maybe<Scalars['Int']>;
  transporterInvoice?: Maybe<Scalars['String']>;
  truckReg?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type ValidateMove = {
  __typename?: 'ValidateMove';
  success?: Maybe<Scalars['Boolean']>;
  validatedMove?: Maybe<ValidateMoveType>;
};

export type ValidateMoveType = {
  __typename?: 'ValidateMoveType';
  arbod1?: Maybe<Scalars['DateTime']>;
  arbod2?: Maybe<Scalars['DateTime']>;
  ardes1?: Maybe<Scalars['DateTime']>;
  arload?: Maybe<Scalars['DateTime']>;
  assetserial?: Maybe<Scalars['String']>;
  bagsdel?: Maybe<Scalars['Int']>;
  bagsLoaded?: Maybe<Scalars['Int']>;
  barcoder?: Maybe<Scalars['DateTime']>;
  cargoCapacity?: Maybe<Scalars['Int']>;
  cargoClientAccountManager?: Maybe<Scalars['String']>;
  cargoInvoiceNumber?: Maybe<Scalars['String']>;
  cargoOwnerInvoiceWeight?: Maybe<Scalars['Float']>;
  cargoRate?: Maybe<Scalars['String']>;
  cargoRateType?: Maybe<Scalars['String']>;
  cargoType?: Maybe<Scalars['String']>;
  cargoTypeId?: Maybe<Scalars['Float']>;
  carrtder?: Maybe<Scalars['DateTime']>;
  cdetder?: Maybe<Scalars['DateTime']>;
  clearingAgent?: Maybe<Scalars['String']>;
  clientCurrency?: Maybe<Scalars['String']>;
  clientDeliveryNote?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['Float']>;
  clientName?: Maybe<Scalars['String']>;
  commodity?: Maybe<Scalars['String']>;
  consigneeAddress?: Maybe<Scalars['String']>;
  consigneeId?: Maybe<Scalars['Float']>;
  consigneeName?: Maybe<Scalars['String']>;
  consignmentMetricTons?: Maybe<Scalars['String']>;
  containerGrossWeight?: Maybe<Scalars['Int']>;
  containerNumber?: Maybe<Scalars['String']>;
  containerNumber2?: Maybe<Scalars['String']>;
  controlDate?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Scalars['String']>;
  currentStage?: Maybe<Scalars['String']>;
  currentStageFlag?: Maybe<Scalars['String']>;
  damagedBags?: Maybe<Scalars['String']>;
  departureFuelingStation?: Maybe<Scalars['DateTime']>;
  depbod1?: Maybe<Scalars['DateTime']>;
  depbod2?: Maybe<Scalars['DateTime']>;
  destination?: Maybe<Scalars['String']>;
  destination2?: Maybe<Scalars['String']>;
  destination2Code?: Maybe<Scalars['String']>;
  destination3?: Maybe<Scalars['String']>;
  destination3Code?: Maybe<Scalars['String']>;
  destinationCity?: Maybe<Scalars['String']>;
  destinationCountry?: Maybe<Scalars['String']>;
  destinationGeoCode?: Maybe<Scalars['String']>;
  driver?: Maybe<DriverType>;
  driverId?: Maybe<Scalars['String']>;
  driverName?: Maybe<Scalars['String']>;
  driverPhone?: Maybe<Scalars['String']>;
  emptyReturnChanged?: Maybe<Scalars['Boolean']>;
  emptyReturnCostExternal?: Maybe<Scalars['String']>;
  emptyReturnDestination?: Maybe<Scalars['String']>;
  emptyReturnDestinationName?: Maybe<Scalars['String']>;
  endJourneyValidationDate?: Maybe<Scalars['DateTime']>;
  etaToLoading?: Maybe<Scalars['String']>;
  etaToOffloading?: Maybe<Scalars['String']>;
  fuelRequestTime?: Maybe<Scalars['DateTime']>;
  gainlp?: Maybe<Scalars['DateTime']>;
  gaoutlp?: Maybe<Scalars['DateTime']>;
  gatePassNumber?: Maybe<Scalars['String']>;
  gindest1?: Maybe<Scalars['DateTime']>;
  goutdes?: Maybe<Scalars['String']>;
  goutdes1?: Maybe<Scalars['DateTime']>;
  guaranteeFormValidationDate?: Maybe<Scalars['DateTime']>;
  hours?: Maybe<Scalars['Int']>;
  icdslipdiscus?: Maybe<Scalars['DateTime']>;
  icdsliprecd?: Maybe<Scalars['DateTime']>;
  icdsliprechq?: Maybe<Scalars['DateTime']>;
  importExport?: Maybe<Scalars['String']>;
  invpdcust?: Maybe<Scalars['DateTime']>;
  invtocust?: Maybe<Scalars['DateTime']>;
  lastKnownLocation?: Maybe<Scalars['String']>;
  lastKnownLocationTime?: Maybe<Scalars['DateTime']>;
  llon?: Maybe<Scalars['String']>;
  loadbico?: Maybe<Scalars['String']>;
  loadingDate?: Maybe<Scalars['DateTime']>;
  loadingValidationDate?: Maybe<Scalars['DateTime']>;
  lroId?: Maybe<Scalars['String']>;
  match?: Maybe<Scalars['DateTime']>;
  missingBags?: Maybe<Scalars['String']>;
  motorboy?: Maybe<Scalars['String']>;
  moveId: Scalars['Float'];
  offloadedTonnage?: Maybe<Scalars['Float']>;
  oldestEventDepartureFuelingStation?: Maybe<Scalars['DateTime']>;
  origin?: Maybe<Scalars['String']>;
  originCity?: Maybe<Scalars['String']>;
  originCountry?: Maybe<Scalars['String']>;
  originGeocode?: Maybe<Scalars['String']>;
  podcpr?: Maybe<Scalars['DateTime']>;
  podrhq?: Maybe<Scalars['DateTime']>;
  portInterchangeSlipNo?: Maybe<Scalars['String']>;
  qtyLoaded?: Maybe<Scalars['Int']>;
  qtyOffloaded?: Maybe<Scalars['Int']>;
  receiver?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  rejectedBags?: Maybe<Scalars['Int']>;
  remarks?: Maybe<Scalars['String']>;
  rvsinvdate?: Maybe<Scalars['DateTime']>;
  sealNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  subtotalCargoOwner?: Maybe<Scalars['String']>;
  subtotalTransporter?: Maybe<Scalars['String']>;
  sumFuelledAmount?: Maybe<Scalars['Float']>;
  t1Expiry?: Maybe<Scalars['DateTime']>;
  t1rec?: Maybe<Scalars['DateTime']>;
  trailer?: Maybe<TrailersType>;
  trailerNumber?: Maybe<Scalars['String']>;
  trailerType?: Maybe<Scalars['String']>;
  traninvrec?: Maybe<Scalars['DateTime']>;
  tranpd?: Maybe<Scalars['DateTime']>;
  transcogs?: Maybe<Scalars['Float']>;
  transportAccountManager?: Maybe<Scalars['String']>;
  transporterAccountManager?: Maybe<Scalars['String']>;
  transporterCurrency?: Maybe<Scalars['String']>;
  transporterId?: Maybe<Scalars['Float']>;
  transporterInvoiceNumber?: Maybe<Scalars['String']>;
  transporterName?: Maybe<Scalars['String']>;
  transporterPaymentTerms?: Maybe<Scalars['Float']>;
  transporterRate?: Maybe<Scalars['Float']>;
  transporterRateType?: Maybe<Scalars['String']>;
  transporterWeightInvoice?: Maybe<Scalars['Int']>;
  truck?: Maybe<TrucksType>;
  truckReg?: Maybe<Scalars['String']>;
  truckType?: Maybe<Scalars['String']>;
  turnBoyName?: Maybe<Scalars['String']>;
  turnBoyPhone?: Maybe<Scalars['String']>;
  unitLoaded?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  varianceAllowedCargoOwner?: Maybe<Scalars['String']>;
  varianceAllowedTransporter?: Maybe<Scalars['Int']>;
  varianceRateTransporterPerkg?: Maybe<Scalars['Int']>;
  varianceRateTransporterToUse?: Maybe<Scalars['String']>;
  vempreoffinfo?: Maybe<Scalars['DateTime']>;
  vessel?: Maybe<Scalars['String']>;
  wbslrhq?: Maybe<Scalars['DateTime']>;
  wbsofhq?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  workflowName?: Maybe<Scalars['String']>;
};

export type Vessel = {
  __typename?: 'Vessel';
  entCode?: Maybe<Scalars['String']>;
  entid?: Maybe<Scalars['Int']>;
  entName?: Maybe<Scalars['String']>;
};

export type Workflow = {
  __typename?: 'Workflow';
  code?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type WorkflowsType = {
  __typename?: 'WorkflowsType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type WorkOrderInputType = {
  cargoRate?: InputMaybe<Scalars['Float']>;
  cargoRateCurrency?: InputMaybe<Scalars['String']>;
  cargoRateType?: InputMaybe<Scalars['String']>;
  consigneeDeliveryDetails?: InputMaybe<Scalars['String']>;
  consigneeName?: InputMaybe<Scalars['String']>;
  destination1?: InputMaybe<Scalars['String']>;
  destination2?: InputMaybe<Scalars['String']>;
  destination3?: InputMaybe<Scalars['String']>;
  destination4?: InputMaybe<Scalars['String']>;
  destinationGeocode?: InputMaybe<Scalars['String']>;
  driverIdNumber?: InputMaybe<Scalars['String']>;
  driverLastLocation?: InputMaybe<Scalars['String']>;
  originGeocode?: InputMaybe<Scalars['String']>;
  preferredLoadingPoint?: InputMaybe<Scalars['String']>;
  trailerReg?: InputMaybe<Scalars['String']>;
  transporterPaymentPeriod?: InputMaybe<TransporterPaymentPeriodEnum>;
  transporterRate?: InputMaybe<Scalars['Float']>;
  transporterRateCurrency?: InputMaybe<Scalars['String']>;
  transporterRateType?: InputMaybe<Scalars['String']>;
  woid: Scalars['ID'];
};

export type WorkOrderMatchingInfoInputType = {
  containerNumber?: InputMaybe<Scalars['String']>;
  containerNumberTwo?: InputMaybe<Scalars['String']>;
  containerSize?: InputMaybe<Scalars['String']>;
  containerWeight?: InputMaybe<Scalars['Float']>;
  containerWeightTwo?: InputMaybe<Scalars['Float']>;
  emptyContainerDestination?: InputMaybe<Scalars['String']>;
  weightToLoad?: InputMaybe<Scalars['Float']>;
};

export type WorkOrderMatchingInfoType = {
  __typename?: 'WorkOrderMatchingInfoType';
  containerNumber?: Maybe<Scalars['String']>;
  containerNumberTwo?: Maybe<Scalars['String']>;
  containerSize?: Maybe<Scalars['String']>;
  containerWeight?: Maybe<Scalars['Float']>;
  containerWeightTwo?: Maybe<Scalars['Float']>;
  emptyContainerDestination?: Maybe<Scalars['String']>;
  weightToLoad?: Maybe<Scalars['Float']>;
};

export type WorkOrderType = {
  __typename?: 'WorkOrderType';
  cargoRate?: Maybe<Scalars['Float']>;
  cargoRateCurrency?: Maybe<Scalars['String']>;
  cargoRateType?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  commodity?: Maybe<Scalars['String']>;
  consignee?: Maybe<Scalars['String']>;
  consigneeDeliveryDetails?: Maybe<Scalars['String']>;
  consigneeName?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  destination1?: Maybe<Scalars['String']>;
  destination2?: Maybe<Scalars['String']>;
  destination3?: Maybe<Scalars['String']>;
  destination4?: Maybe<Scalars['String']>;
  driverIdNumber?: Maybe<Scalars['String']>;
  driverLastLocation?: Maybe<Scalars['String']>;
  driverName?: Maybe<Scalars['String']>;
  driverPhoneNumber?: Maybe<Scalars['String']>;
  loadingTonnage?: Maybe<Scalars['Float']>;
  lroId?: Maybe<Scalars['ID']>;
  origin?: Maybe<Scalars['String']>;
  originCountry?: Maybe<Scalars['String']>;
  preferredLoadingPoint?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  trailerReg?: Maybe<Scalars['String']>;
  trailerType?: Maybe<Scalars['String']>;
  transporter?: Maybe<Scalars['String']>;
  transporterPaymentPeriod?: Maybe<Scalars['String']>;
  transporterRate?: Maybe<Scalars['Float']>;
  transporterRateCurrency?: Maybe<Scalars['String']>;
  transporterRateType?: Maybe<Scalars['String']>;
  truckSerial?: Maybe<Scalars['String']>;
  woid: Scalars['ID'];
};

export type CreateIssueMutationVariables = Exact<{
  assignee?: Maybe<Scalars['String']>;
  businessDomainId: Scalars['Int'];
  details?: Maybe<Scalars['String']>;
  moveId: Scalars['String'];
  reportedAt?: Maybe<Scalars['DateTime']>;
  typeId: Scalars['Int'];
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
}>;


export type CreateIssueMutation = { __typename?: 'RootMutation', createIssue?: { __typename?: 'CreateIssue', message?: string | null | undefined, status?: number | null | undefined } | null | undefined };

export type GetIssueTypeCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIssueTypeCategoriesQuery = { __typename?: 'RootQuery', issueTypeCategories?: Array<{ __typename?: 'IssueTypeCategoryType', id: string, name?: string | null | undefined } | null | undefined> | null | undefined };

export type GetIssueTypesQueryVariables = Exact<{
  issueTypeCategoryId?: Maybe<Scalars['Int']>;
}>;


export type GetIssueTypesQuery = { __typename?: 'RootQuery', issueTypes?: Array<{ __typename?: 'IssueTypesType', id: string, issueType?: string | null | undefined, issueTypeCategoryId?: number | null | undefined } | null | undefined> | null | undefined };


export const CreateIssueDocument = gql`
    mutation createIssue($assignee: String, $businessDomainId: Int!, $details: String, $moveId: String!, $reportedAt: DateTime, $typeId: Int!, $userId: String, $userName: String) {
  createIssue(issue: {assignee: $assignee, businessDomainId: $businessDomainId, details: $details, moveId: $moveId, reportedAt: $reportedAt, typeId: $typeId, userId: $userId, userName: $userName}) {
    message
    status
  }
}
    `;
export type CreateIssueMutationFn = Apollo.MutationFunction<CreateIssueMutation, CreateIssueMutationVariables>;

/**
 * __useCreateIssueMutation__
 *
 * To run a mutation, you first call `useCreateIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIssueMutation, { data, loading, error }] = useCreateIssueMutation({
 *   variables: {
 *      assignee: // value for 'assignee'
 *      businessDomainId: // value for 'businessDomainId'
 *      details: // value for 'details'
 *      moveId: // value for 'moveId'
 *      reportedAt: // value for 'reportedAt'
 *      typeId: // value for 'typeId'
 *      userId: // value for 'userId'
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function useCreateIssueMutation(baseOptions?: Apollo.MutationHookOptions<CreateIssueMutation, CreateIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIssueMutation, CreateIssueMutationVariables>(CreateIssueDocument, options);
      }
export type CreateIssueMutationHookResult = ReturnType<typeof useCreateIssueMutation>;
export type CreateIssueMutationResult = Apollo.MutationResult<CreateIssueMutation>;
export type CreateIssueMutationOptions = Apollo.BaseMutationOptions<CreateIssueMutation, CreateIssueMutationVariables>;
export const GetIssueTypeCategoriesDocument = gql`
    query getIssueTypeCategories {
  issueTypeCategories {
    id
    name
  }
}
    `;

/**
 * __useGetIssueTypeCategoriesQuery__
 *
 * To run a query within a React component, call `useGetIssueTypeCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssueTypeCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssueTypeCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIssueTypeCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetIssueTypeCategoriesQuery, GetIssueTypeCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIssueTypeCategoriesQuery, GetIssueTypeCategoriesQueryVariables>(GetIssueTypeCategoriesDocument, options);
      }
export function useGetIssueTypeCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIssueTypeCategoriesQuery, GetIssueTypeCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIssueTypeCategoriesQuery, GetIssueTypeCategoriesQueryVariables>(GetIssueTypeCategoriesDocument, options);
        }
export type GetIssueTypeCategoriesQueryHookResult = ReturnType<typeof useGetIssueTypeCategoriesQuery>;
export type GetIssueTypeCategoriesLazyQueryHookResult = ReturnType<typeof useGetIssueTypeCategoriesLazyQuery>;
export type GetIssueTypeCategoriesQueryResult = Apollo.QueryResult<GetIssueTypeCategoriesQuery, GetIssueTypeCategoriesQueryVariables>;
export const GetIssueTypesDocument = gql`
    query getIssueTypes($issueTypeCategoryId: Int) {
  issueTypes(issueTypeCategoryId: $issueTypeCategoryId) {
    id
    issueType
    issueTypeCategoryId
  }
}
    `;

/**
 * __useGetIssueTypesQuery__
 *
 * To run a query within a React component, call `useGetIssueTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssueTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssueTypesQuery({
 *   variables: {
 *      issueTypeCategoryId: // value for 'issueTypeCategoryId'
 *   },
 * });
 */
export function useGetIssueTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetIssueTypesQuery, GetIssueTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIssueTypesQuery, GetIssueTypesQueryVariables>(GetIssueTypesDocument, options);
      }
export function useGetIssueTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIssueTypesQuery, GetIssueTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIssueTypesQuery, GetIssueTypesQueryVariables>(GetIssueTypesDocument, options);
        }
export type GetIssueTypesQueryHookResult = ReturnType<typeof useGetIssueTypesQuery>;
export type GetIssueTypesLazyQueryHookResult = ReturnType<typeof useGetIssueTypesLazyQuery>;
export type GetIssueTypesQueryResult = Apollo.QueryResult<GetIssueTypesQuery, GetIssueTypesQueryVariables>;
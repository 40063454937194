import { gql } from '@apollo/client';

export const updateTrailer = gql`
	mutation updateTrailer($id: Int!, $active: Boolean!) {
		updateTrailer(id: $id, trailerData: { active: $active }) {
			status
			message
		}
	}
`;

import React, { useCallback } from 'react';
import { UserControlButtons as UserControlButtonsTemp } from 'components';
import { UserDetailsFragment, RoleEnum } from 'generate/typesAdmin';
import { IUserControlActions } from 'models';

interface UserControlButtonsProps extends IUserControlActions {
	classname?: string;
	username: UserDetailsFragment['username'];
	email: UserDetailsFragment['email'];
	isSuspended?: boolean;
	role: RoleEnum;
}

export const TransporterControlButtons = ({
	classname,
	email,
	isSuspended,
	role,
	onActivateExternalTransporter,
	onActivateUser,
	onSuspendUser,
}: UserControlButtonsProps) => {
	const handleActivateUser = useCallback(() => {
		onActivateUser(email);
	}, [email, onActivateUser]);

	const handleActivateExternalTransporter = useCallback(() => {
		onActivateExternalTransporter(email);
	}, [email, onActivateExternalTransporter]);

	const handleSuspendUser = useCallback(() => {
		onSuspendUser(email, isSuspended);
	}, [email, isSuspended, onSuspendUser]);

	return (
		<UserControlButtonsTemp
			onActivateExternalTransporter={handleActivateExternalTransporter}
			onActivateUser={handleActivateUser}
			onSuspendUser={handleSuspendUser}
			isSuspended={isSuspended}
			classname={classname}
			role={role}
		/>
	);
};

import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';
import { CustomUploadFieldLta } from 'containers';
import { FormIdList, UploadDocumentationFormProps, UploadDocumentationNames } from 'models';
import { formatCamelToSnakeCase, mapFieldsToInitialValues } from 'helpers';
import { useTranslation } from 'react-i18next';
import { SIGN_UP_EXTENSION_LIST } from 'utils';
import { useStyles } from '../SignUpForm.styles';
import cn from 'classnames';

const requireFields = [
	UploadDocumentationNames.directorIdentification,
	UploadDocumentationNames.certificateOfIncorporation,
	UploadDocumentationNames.kraPin,
	UploadDocumentationNames.proofOfDirectorship,
	UploadDocumentationNames.kraTcc,
	UploadDocumentationNames.carriersLiability,
];

const fields = [...requireFields, UploadDocumentationNames.carriersLiabilityExpiry];

export const UploadDocumentationFormEast = ({ data, onSubmit }: UploadDocumentationFormProps) => {
	const { t } = useTranslation('form-fields');

	const classes = useStyles();

	const initialValues = useMemo(() => mapFieldsToInitialValues(fields, data || {}), [data]);

	return (
		<Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
			<Form id={FormIdList.uploadDocumentation} className={cn(classes.formWrapper, {})}>
				{requireFields.map((name) => (
					<CustomUploadFieldLta
						key={name}
						name={name}
						label={t(`upload_documents.${formatCamelToSnakeCase(name)}`)}
						accept={SIGN_UP_EXTENSION_LIST}
					/>
				))}
			</Form>
		</Formik>
	);
};

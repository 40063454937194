import React, { useMemo } from 'react';
import { useStyles as orderCardStyles } from '../job-card/OrderCard.styles';
import { COMMON } from 'utils';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import truckIcon from "../../assets/svg/truck.svg";
import moveArrow from "../../assets/svg/move_arrow.svg";

interface DestinationBlockProps {
	to?: string | null;
	showOrigin?: boolean;
	from?: string | null;
}

export const DestinationBlock = ({ to, from, showOrigin }: DestinationBlockProps) => {
	const classes = orderCardStyles();
	const destination = useMemo(() => to || COMMON.noDataProvided, [to]);
	const origin = useMemo(() => from || COMMON.noDataProvided, [from]);

	return (
		<div className={classes.tripCities}>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				className={classes.truckIconContainer}
			>
				<img className={classes.truckIcon} src={truckIcon} alt="truck-icon"/>
			</Box>
			<Typography component='p' className={classes.departure}>
				{origin}
			</Typography>
			<Box>
				<img className={classes.moveArrowImg} src={moveArrow} alt="move-arrow"/>
			</Box>
			<Typography component="p" className={classes.destination}>
				{destination}
			</Typography>
		</div>
	);
};

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {IconProps, InputLabel} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import clsx from 'clsx';
import {ErrorMessage, Field, FieldProps} from 'formik';
import React from 'react';
import {useStyles} from './styles';

interface CustomTextFieldProps {
    name: string;
    placeholder?: string;
    labelWidth?: number;
    type: string;
    label: string;
    endAdornment?: IconProps;
    autoComplete?: string;
    readOnly?: boolean;
}

export function CustomTextField({
        name = '',
        labelWidth = 70,
        placeholder = '',
        type = 'text',
        endAdornment,
        label = '',
        ...props
    }: CustomTextFieldProps) {
    const classes = useStyles();

    return (
        <Field name={name}>
            {({field, meta}: FieldProps) => {
                const error = !!meta.error && meta.touched;
                return (
                    <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                        <InputLabel htmlFor={`input-${name}`}>{label}</InputLabel>
                        <OutlinedInput
                            {...field}
                            {...props}
                            id={`input-${name}`}
                            placeholder={placeholder}
                            type={type}
                            className={classes.root}
                            error={!!meta.error && meta.touched}
                            endAdornment={
                                endAdornment ? (
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" edge="end">
                                            {endAdornment}
                                        </IconButton>
                                    </InputAdornment>
                                ) : null
                            }
                            label={label}
                        />
                        <FormHelperText error={error}>
                            {error && (
                                <ErrorMessage name={name}/>
                            )}
                        </FormHelperText>
                    </FormControl>
                );
            }}
        </Field>
    );
}

import { makeStyles } from '@material-ui/core/styles';
import { theme, STYLES } from 'utils';

export const useStyles = makeStyles({
	wrapper: {
		display: 'flex',
		overflow: 'auto',
		alignItems: 'center',
		justifyContent: 'center',
	},
	modal: {
		outline: 'none',
		borderRadius: 10,
		textAlign: 'center',
		padding: '30px 15px',
		position: 'relative',
		boxSizing: 'border-box',
		width: theme.breakpoints.values.sm,
		display: 'flex',
		flexDirection: 'column',
		maxHeight: '90%',
		[theme.breakpoints.down('xs')]: {
			width: 'calc(100vw - 32px)',
			maxHeight: '100%',
		},
		'@media (max-height: 400px)': {
			maxHeight: '100%',
			width: 'calc(100vw - 32px)',
		},
	},
	closeIcon: STYLES.close,
	backdrop: {
		backgroundColor: 'rgba(0, 0, 0, 0.4)',
		backdropFilter: 'blur(1px)'
	},
});

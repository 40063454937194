import { gql } from '@apollo/client';

export const trailerDetails = gql`
	fragment trailerDetails on Trailer {
		trailerRegistration
		trailerType
		trailerId
		transporter
		trailerCapacity
		active
	}
`;

import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils';

export const useStyles = makeStyles({
	btn: {
		height: '40px',
		textTransform: 'capitalize',
		fontSize: 14,
		fontWeight: 500,
	},
	cancelBtn: {
		marginTop: 14,
		borderColor: COLORS.red,
		color: COLORS.red,
		'&:hover': {
			color: COLORS.warning,
			borderColor: COLORS.warning,
		},
	},
	cancelFuelRequest: {
		color: COLORS.red,
		fontSize: 17,
		lineHeight: '18px',
		fontWeight: 600,
		textAlign: 'center',
	},
	modalDismissButton: {
		backgroundColor: COLORS.red,
		'&:hover': {
			backgroundColor: COLORS.red,
		},
	},
	cancelFuelRequestDialog: {
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		backdropFilter: 'blur(1px)',
	},
});

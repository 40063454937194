import { IRoute } from 'models';
import { AppRouteNameList } from '../AppRouteNameList';

export const AuxiliaryServicesRoute: IRoute = {
	name: AppRouteNameList.auxiliaryServices,
	nested: [
		{
			name: AppRouteNameList.requestFuel,
		},
	],
};

import { gql } from '@apollo/client';

export const getContactDetails = gql`
	query getContactDetails {
		contact {
			id
			operationsContactName
			operationsTelNumber
			mobileNumber
			operationsEmail
			adminContactName
			adminTelNumber
			adminEmail
		}
	}
`;

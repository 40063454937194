import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
	locationWrapper: {
		display: 'flex',
		marginTop: 10,
		paddingBottom: 10,
	},
	location: {
		display: 'flex',
		alignItems: 'center',
		width: '50%',
	},
	icon: {
		height: '80%',
		flexShrink: 0,
	},
	locationDesc: {
		overflow: 'hidden',
	},
	locationText: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
});

import React from 'react';
import { DetailsList } from '../details-list';
import { Box } from '@material-ui/core';
import { UploadDocuments } from 'components/modals';
import {
  useGetDocumentTypesQuery,
  useGetDocumentsQuery,
} from 'generate/typesDMS';
import { dmsClient } from 'graphqlapi';
import { useParams } from 'react-router-dom';
import { useReadUserInfo } from 'hooks';
import { OrderStages } from 'models';
import {CustomButton} from "../button";
import {trackSubmitMixPanelEvent} from "../../utils";
import cn from "classnames";
import {useStyles} from "./MyJobDetails.styles";
import {useTranslation} from "react-i18next";
import uploadIconSrc from 'assets/icons/upload-blue.png';
export interface MyJobDetailsCompletedProps {
  detailsList: Record<string, string | React.ReactElement>;
  isOpened?: boolean;
  toggleModal: () => void;
}

export const MyJobDetailsCompleted = React.memo(function MyJobDetailsCompleted({
  detailsList,
  isOpened,
  toggleModal,
}: MyJobDetailsCompletedProps) {
  const { id } = useParams<{ id: string }>();
  const { region } = useReadUserInfo();
  const classes = useStyles();
  const { t } = useTranslation('myjobs');

  const { data: documentTypesData, loading: documentTypesIsloading } =
    useGetDocumentTypesQuery({
      client: dmsClient,
      variables: {
        region,
        stage: OrderStages.completed,
      },
      fetchPolicy: 'cache-and-network',
    });

  const { data: transporterDocumentsData, refetch: refetchDocuments } =
    useGetDocumentsQuery({
      client: dmsClient,
      variables: {
        moveId: parseInt(id),
      },
      onCompleted: () => {},
      fetchPolicy: 'cache-and-network',
    });

    return (
    <div className='overflow'>
      <DetailsList {...detailsList} />
      <Box mt={2} display='flex' justifyContent='center'>
        <CustomButton
          data-qa="qa-upload-documents"
          onClick={() => {
            toggleModal();
            trackSubmitMixPanelEvent(
              'Documents upload on the completed jobs tab'
            );
          }}
          className={cn(classes.button, classes.link)}
          variant='outlined'
          disabled={documentTypesIsloading}
          startIcon={
            <img
              src={uploadIconSrc}
              alt='Upload'
              className={classes.buttonIcon}
            />
          }
        >
          {t('upload_documents')}
        </CustomButton>
      </Box>
      {isOpened && (
        <UploadDocuments
          // @ts-ignore
          transporterDocuments={transporterDocumentsData?.getDocuments || []}
          onClose={toggleModal}
          // @ts-ignore
          documentTypes={documentTypesData?.documentTypes || []}
          refetchDocuments={refetchDocuments}
        />
      )}
    </div>
  );
});

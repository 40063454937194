import { CustomButton } from 'components';
import {Box, IconButton} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AppPathList } from 'router';
import { AddAssetProps } from 'models';
import { useMixpanel } from 'analytics';
import {Link} from "react-router-dom";
import VerticalAlignTop from "@material-ui/icons/VerticalAlignTop";


interface TruckListTempProps extends AddAssetProps {}

export const TruckListTemp = ({ onAddAsset }: TruckListTempProps) => {
	const { t } = useTranslation('buttons');
	const mixpanel = useMixpanel();

	return (
		<>
			<Box mt={2} textAlign="center">
				<Link
					to={AppPathList.addTruck}
					className="link flexGrow"
					onClick={() => {
						mixpanel.track('Add truck button clicked');
					}}>
					<CustomButton className="capitalizeText">
						{t('add_trucks')}
					</CustomButton>
				</Link>
			</Box>
			<Box mt={2} textAlign="center" paddingBottom={2}>
				<IconButton size="small" color="primary" className="text-small" onClick={onAddAsset}>
					<Box mr={1}><VerticalAlignTop  className="subtitle"/></Box>
					{t('upload_details')}
				</IconButton>
			</Box>
			{/*<Box my={2} paddingBottom={4} textAlign="center">
				<ButtonLink
					onClick={() => {
						mixpanel.track('Clicked on the Unassigned trucks button');
					}}
					to={AppPathList.availableTrucks}
					variant="outlined"
				>
					{t('unassigned_trucks')}
				</ButtonLink>
			</Box>*/}
		</>
	);
};

import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils';

export const useStyles = makeStyles({
	wrapper: {
		overflow: 'hidden',
	},
	label: {
		cursor: 'pointer',
		borderRadius: '50%',
		margin: '20px 20px 20px 1px',
		position: 'relative',
	},
	withShadow: {
		boxShadow: `0 0 0 1px ${COLORS.primary}`,
	},
	addIcon: {
		position: 'absolute',
		right: -7,
		top: 0,
		width: 13,
		fill: COLORS.primary,
	},
	img: {
		width: 70,
		height: 70,
	},
	btn: {
		width: 'auto',
		'&:first-child': {
			marginRight: 'auto',
		},
		'& + &': {
			marginLeft: '1rem',
		},
	},
});

import React from 'react';
import cn from 'classnames';
import { IIconProps } from './IIconProps';

export const EditIcon = ({ className, title = '', pointer }: IIconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="Edit-icon"
			viewBox="0 0 32 32"
			className={cn('icon', className, {
				pointer: pointer,
			})}
		>
			<title>{title}</title>
			<path d="M14.553 2.981h-10.126c-1.198 0-2.285 0.487-3.068 1.272s-1.272 1.87-1.272 3.068v20.251c0 1.198 0.488 2.285 1.272 3.068s1.87 1.271 3.068 1.271h20.251c1.198 0 2.285-0.488 3.068-1.271s1.271-1.87 1.271-3.068v-10.126c0-0.798-0.648-1.447-1.447-1.447s-1.447 0.648-1.447 1.447v10.126c0 0.399-0.161 0.759-0.424 1.023s-0.623 0.424-1.023 0.424h-20.251c-0.399 0-0.759-0.161-1.023-0.424s-0.424-0.623-0.424-1.023v-20.251c0-0.399 0.161-0.759 0.424-1.023s0.623-0.424 1.023-0.424h10.126c0.798 0 1.447-0.648 1.447-1.447s-0.648-1.447-1.447-1.447zM24.38 1.235l-13.742 13.742c-0.176 0.175-0.314 0.405-0.38 0.673l-1.447 5.786c-0.056 0.217-0.061 0.46 0 0.702 0.194 0.775 0.979 1.247 1.755 1.053l5.786-1.447c0.242-0.059 0.477-0.187 0.673-0.38l13.742-13.742c0.881-0.881 1.322-2.040 1.322-3.192s-0.441-2.312-1.322-3.192-2.041-1.324-3.194-1.324-2.312 0.441-3.192 1.322zM26.425 3.281c0.317-0.317 0.729-0.474 1.147-0.474s0.83 0.158 1.147 0.474 0.474 0.729 0.474 1.147-0.158 0.83-0.474 1.147l-13.458 13.458-3.058 0.765 0.765-3.058z" />
		</svg>
	);
};

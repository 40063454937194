import React, { PropsWithChildren } from 'react';
import { state } from 'state';
import { useMixpanel } from 'analytics';

interface LinkToCallProps {
	className?: string;
	phoneNumber?: string;
}

export const LinkToCall = ({
	phoneNumber,
	className,
	children,
}: PropsWithChildren<LinkToCallProps>) => {
	const mixpanel = useMixpanel();

	return (
		<a
			className={className}
			href={`${state.isMobileBrowser ? 'callto' : 'tel'}:${phoneNumber}`}
			onClick={() => {
				mixpanel.track(`Navigates to ${state.isMobileBrowser ? 'callto' : 'tel'}:${phoneNumber}`);
			}}
		>
			{children}
		</a>
	);
};

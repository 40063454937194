import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
	icon: {
		width: 24,
		fill: '#7FC0B5',
		padding: '0 10px',
	},
	input: {
		paddingLeft: 0,
		'&::placeholder': {
			color: '#7FC0B5 !important',
			fontSize: 17,
			fontWeight: 300,
			letterSpacing: 0.210909,
		},
		'& input': {
			backgroundColor: 'transparent',
			fontSize: 17,
			fontWeight: 300,
			height: '40px !important',
			letterSpacing: 0.210909,
			padding: '0 !important',
			appearance: 'none',

			'&::placeholder': {
				color: '#7FC0B5 !important',
				fontSize: 17,
				fontWeight: 300,
				letterSpacing: 0.210909,
			},
		},
		backgroundColor: '#F9F9F9',
		height: 40,
		borderRadius: 5,

		'&:after, &:before, &:hover': {
			border: 'none !important',
			transition: 'none !important',
		},
	},
});


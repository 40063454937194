import { useMemo } from 'react';
import { ErrorMessage, FastField, Field, FieldProps } from 'formik';
import { MenuItem } from '@material-ui/core';
import { CustomInput, CustomInputProps } from 'components';

export interface ISelectOption {
	title: string;
	value: string | number;
}

export interface ISelectOptionList extends Array<ISelectOption> {}

interface CustomSelectFieldProps extends CustomInputProps {
	options?: ISelectOptionList | null;
	label: string;
	fast?: boolean;
	disabledValues?: ISelectOption['value'][];
}

export const CustomSelectField = ({
	name = '',
	options = [],
	fast,
	disabled,
	disabledValues,
	...props
}: CustomSelectFieldProps) => {
	const Wrapper = useMemo(() => (fast ? FastField : Field), [fast]);

	return (
		<Wrapper name={name}>
			{({ field, meta }: FieldProps) => {
				return (
					<CustomInput
						{...props}
						{...field}
						qaType="text"
						name={name}
						error={!!meta.error && meta.touched}
						helperText={<ErrorMessage name={name} />}
						select
						disabled={disabled || !options?.length}
						SelectProps={{
							MenuProps: {
								anchorOrigin: {
									vertical: 'bottom',
									horizontal: 'left',
								},
								getContentAnchorEl: null,
							},
						}}
					>
						{ options?.map(({ title, value }) => (
							<MenuItem
								qa-type="list-option"
								value={value}
								key={value}
								disabled={disabledValues?.includes(value)}
							>
								{title}
							</MenuItem>
						))}
					</CustomInput>
				);
			}}
		</Wrapper>
	);
};

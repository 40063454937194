import { IRoute } from 'models';
import { AppRouteNameList } from '../AppRouteNameList';

export const AccountRoute: IRoute = {
	name: AppRouteNameList.account,
	nested: [
		{
			name: AppRouteNameList.availableTrucks,
		},
		{
			name: AppRouteNameList.bankDetails,
		},
		{
			name: AppRouteNameList.updateBankDetails,
		},
		{
			name: AppRouteNameList.documents,
		},
		{
			name: AppRouteNameList.resetPassword,
		},
		{
			name: AppRouteNameList.assetManagement,
		},
		{
			name: AppRouteNameList.addTruck,
		},
		{
			name: AppRouteNameList.addDriver,
		},
		{
			name: AppRouteNameList.addTrailer,
		},
	],
};

import React from 'react';
import { SearchBlock, SearchBlockProps } from 'components/search-block';
import { Box } from '@material-ui/core';

interface AdminSearchBlockProps extends SearchBlockProps {}

export const AdminSearchBlock: React.FC<AdminSearchBlockProps> = ({ onChangeSearchString }) => {
	return (
		<Box paddingX={3} paddingY={2} maxWidth={400}>
			<SearchBlock onChangeSearchString={onChangeSearchString} />
		</Box>
	);
};

import { gql } from '@apollo/client';

export const confirmJob = gql`
	mutation confirmJob($assets: [ConfirmAssetList]!) {
		confirmJob(assets: $assets) {
			message
			status
		}
	}
`;

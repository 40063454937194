import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	moveTypeWrapper: {
		backgroundColor: '#F9F9F9',
		borderRadius: 5,
		display: 'flex',
		height: 40,
		justifyContent: 'space-around',
		marginBottom: 14,
		maxWidth: 192,
	},
	list: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-around',
		width: 'calc(100% - 40px)',
	},
	button: {
		alignItems: 'center',
		borderRadius: 5,
		color: '#2C3032',
		fontSize: 16,
		fontWeight: 300,
		height: 34,
		letterSpacing: 0.15,
		margin: '0 15px',
		textTransform: 'capitalize',
		width: 70,

		'&:hover': {
			backgroundColor: '#F9F9F9',
		},
	},
	active: {
		backgroundColor: '#FFFEFE',
		boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.04)',
		color: '#FFC46C',
		fontSize: 16,
		fontWeight: 500,
		height: 34,
		letterSpacing: 0.15,
		margin: '0 15px',
		width: 70,

		'&:hover': {
			backgroundColor: '#FFFEFE',
		},
	},
});

import React, { forwardRef } from 'react';
import { Loader } from 'components';
import cn from 'classnames';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		list: {
			height: '100%',
		},
		listItem: {
			backgroundColor: '#F5F5F5',
		},
		root: {
			flexGrow: 1,
		},
		paper: {
			padding: '1.25rem 6px',
			textAlign: 'center',
			color: theme.palette.text.secondary,
			marginBottom: '3rem',
			backgroundColor: '#F5F5F5',
		},
	})
);

export interface ItemListPaperProps<T = {}> {
	items: Array<T>;
	ItemTemplate: React.ElementType;
	isLoading?: boolean;
	keyName?: string;
	listItemClassname?: string;
	listClassname?: string;
	loadingPlaceholder?: string;
}

// eslint-disable-next-line
export const ItemListPaper = forwardRef<HTMLUListElement, ItemListPaperProps<Record<string, any>>>(
	(
		{
			items = [],
			ItemTemplate,
			isLoading = false,
			keyName,
			listItemClassname,
			listClassname,
			loadingPlaceholder = 'No data available...',
			...props
		},
		ref
	) => {
		const classes = useStyles();
		return (
			<Grid container>
				<Grid item xs={12} spacing={10}>
					<Paper className={classes.paper} elevation={0} variant="outlined">
						<ul className={cn(classes.list, listClassname)} ref={ref as any}>
							{items.map((item, index) => (
								<li
									key={keyName ? (item[keyName] as string) : index}
									className={cn(classes.listItem, listItemClassname)}
								>
									<ItemTemplate {...item} {...props} />
								</li>
							))}
							<Loader isLoading={isLoading}>
								{!items.length && <Typography>{loadingPlaceholder}</Typography>}
							</Loader>
						</ul>
					</Paper>
				</Grid>
			</Grid>
		);
	}
);

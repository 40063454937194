import {ModalWrapper} from 'components';
import { DropFileUpload } from 'containers';
import React, { } from 'react';
import { useTranslation } from 'react-i18next';
interface DriverBulkUploadProps {
  toggleModal: () => void;
  isOpened: boolean;
}

export const TrailerBulkUpload = ({ isOpened, toggleModal }: DriverBulkUploadProps) => {
  const { t: tB } = useTranslation('buttons');

  return (
      <ModalWrapper open={isOpened} onClose={toggleModal} withCloseBtn={false}>
        <div>
          <DropFileUpload
              toggleModal={toggleModal}
              name='trailer-importy'
              label={tB('upload_trailers')}
              assetType='trailers'
          />
        </div>
      </ModalWrapper>
  );
};


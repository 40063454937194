import React from 'react';
import { Form, Formik } from 'formik';
import { FormIdList, FormStepTempProps, PersonalInformationNames } from 'models';
import { CustomCheckboxField, CustomInputField } from 'containers';
import { formatCamelToSnakeCase } from 'helpers';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { CreateProfileMutationVariables } from 'generate/typesAuth';
import { CustomButton } from 'components/button';
import { useReadUserInfo } from 'hooks';
import { useStyles } from './SignUpForm.styles';

interface PersonalInformationFormProps extends FormStepTempProps<CreateProfileMutationVariables> {
	isVettingConsentProvided?: boolean;
}

export const PersonalInformationForm = ({
	onSubmit,
	validationSchema,
	initialValues,
	isVettingConsentProvided,
}: PersonalInformationFormProps) => {
	const { t } = useTranslation('form-fields');
	const { t: tB } = useTranslation('buttons');
	const { user_id } = useReadUserInfo();
	const classes = useStyles();

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validationSchema}
			enableReinitialize
		>
			<Form id={FormIdList.personalInformation} className={classes.formWrapper}>
				<Box textAlign="left">
					<CustomCheckboxField
						name={PersonalInformationNames.vetting}
						label={t(
							`personal_information.${formatCamelToSnakeCase(PersonalInformationNames.vetting)}`
						)}
						disabled={isVettingConsentProvided}
					/>
				</Box>
				<CustomInputField
					name={PersonalInformationNames.firstName}
					label={t(
						`personal_information.${formatCamelToSnakeCase(PersonalInformationNames.firstName)}`
					)}
				/>
				<CustomInputField
					name={PersonalInformationNames.lastName}
					label={t(
						`personal_information.${formatCamelToSnakeCase(PersonalInformationNames.lastName)}`
					)}
				/>
				<CustomInputField
					name={PersonalInformationNames.ID}
					label={t(`personal_information.${formatCamelToSnakeCase(PersonalInformationNames.ID)}`)}
				/>
				{user_id && (
					<Box display="flex" marginY={2} justifyContent="center">
						<CustomButton type="submit">{tB('submit')}</CustomButton>
					</Box>
				)}
			</Form>
		</Formik>
	);
};

import { makeStyles } from '@material-ui/core/styles';
import { theme } from 'utils';

export const useStyles = makeStyles({
	btn: {
		fontSize: '1rem',
		padding: 0,
		'& + &': {
			marginLeft: theme.spacing(2),
		},
	},
});

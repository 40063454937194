import { useMemo } from 'react';
import { MyJobDetailsActive as MyJobDetailsActiveTemp, Loader } from 'components';
import { JobCategory, useGetWorkOrderQuery } from 'generate/types';
import { useParams } from 'react-router-dom';
import { formatToDate, mapToDetailsList } from 'helpers';

const detailsList = [
	'moveId',
	'truckReg',
	'truckType',
	'cargoType',
	'commodity',
	'weight',
];

export const MyJobDetailsActive = () => {
	const { id } = useParams<{ id: string }>();

  const { data, loading } = useGetWorkOrderQuery({
    variables: {
      jobCategory: JobCategory.OnJourney,
      moveId: parseInt(id)
    },
  });

	const detailsListData = useMemo(() => {
		const { lastKnownLocation, ...rest } = data?.workOrder || {};

		return mapToDetailsList(
			{
				...rest,
				trucks_last_position: lastKnownLocation,
			},
			// @ts-ignore
			detailsList,
			{
				readyToLoad: formatToDate,
			}
		);
	}, [data]);
	return (
		<Loader isLoading={loading}>
      {/* @ts-ignore */}
			<MyJobDetailsActiveTemp detailsList={detailsListData} {...data?.workOrder} />
		</Loader>
	);
};

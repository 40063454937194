import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiTypography from '@material-ui/core/Typography';
import MuiContainer from '@material-ui/core/Container';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox from '@material-ui/core/Checkbox';

import { withStyles } from '@material-ui/core';

export const Accordion = withStyles({
	root: {
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
		width: '100%',
	},
	expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
	root: {
		marginBottom: -1,
		minHeight: 30,
		justifyContent: 'flex-start',
		'&$expanded': {
			minHeight: 30,
		},
	},
	content: {
		flexGrow: 0,
		justifyContent: 'flex-start',
		'&$expanded': {
			margin: '12px 0',
		},
	},
	expanded: {},
	expandIcon: {
		marginLeft: 5,
	},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		fontSize: 14,
	},
}))(MuiAccordionDetails);

export const Title = withStyles((theme) => ({
	root: {
		fontSize: 16,
	},
}))(MuiTypography);
export const Description = withStyles((theme) => ({}))(MuiTypography);

export const Container = withStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		padding: '30px 20px 30px 5px',
	},
}))(MuiContainer);

export const FormControlLabel = withStyles((theme) => ({
	root: {
		position: 'absolute',
		right: -40,
		top: 5,
	},
	label: {
		fontSize: 12,
		color: 'grey',
		fontStyle: 'italic',
	},
}))(MuiFormControlLabel);

export const Checkbox = withStyles((theme) => ({
	root: {},
}))(MuiCheckbox);

export const PageTitle = withStyles((theme) => ({
	root: {
		fontSize: 22,
		paddingLeft: 30,
	},
}))(MuiTypography);

import { gql } from '@apollo/client';

export const activeJobDetails = gql`
	fragment activeJobDetails on Move {
		origin
		destination

		readyToLoad
		
		moveId
		truckReg
		trailerType
		truckType
		trailerNumber
		
		lastKnownLocation
		
	    ${/* category */ ''}
	    ${/* lastPosition */ ''}
	    ${/* journeyStatus */ ''}
	    ${/* etaDestination */ ''}
	}
`;

import React from 'react';
import {
	useUpdateInsuranceDetailsMutation,
	useGetInsuranceDetailsQuery,
	UpdateInsuranceDetailsMutationVariables,
} from 'generate/types';
import { InsuranceForm } from '../InsuranceForm';
import { Loader } from 'components';
import { getInsuranceDetails } from 'graphqlapi';
import { useResultContext } from 'hooks';

export const UpdateInsuranceForm = () => {
	const { data, loading } = useGetInsuranceDetailsQuery();
	const { openResultCb } = useResultContext();

	const [postInsurance] = useUpdateInsuranceDetailsMutation({
		onCompleted: openResultCb,
		refetchQueries: [{ query: getInsuranceDetails }],
	});

	const onSubmit = (variables: unknown) =>
		postInsurance({
			variables: variables as UpdateInsuranceDetailsMutationVariables,
		});

	return (
		<Loader isLoading={loading}>
			<InsuranceForm onSubmit={onSubmit} data={data?.insurance} />
		</Loader>
	);
};

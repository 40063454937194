import { useState } from 'react';
import { IUser } from 'models/users/IUser';

const { REACT_APP_LTA_SERIVICE } = process.env;

export const useAuth = () => {
  const [user, setUser] = useState<IUser | null>(null)
  const [loading, setLoading] = useState<boolean>(true);

	const AUTH_URL = `${REACT_APP_LTA_SERIVICE || ''}/auth`;
	// const AUTH_URL = `https://127.0.0.1:5000/auth`;

  const signOut = async () => {
    fetch(`${AUTH_URL}/logout`, { credentials: 'include' })
      .then(response => {
        return new Promise(resolve =>
          response.json().then(json =>
            resolve({
              status: response.status,
              ok: response.ok,
              text: response.text,
              json,
            }),
          ),
        );
      })
      .then(({ status, text }: any) => {
        if (status === 200)
          setUser(null);

        if ([400, 401, 403].includes(status))
          console.log(text);

        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  const getUserInfo = async () => {
    fetch(`${AUTH_URL}/userinfo`, { credentials: 'include' })
      .then(response => {
        return new Promise(resolve =>
          response.json().then(json =>
            resolve({
              status: response.status,
              ok: response.ok,
              text: response.text,
              json,
            }),
          ),
        );
      })
      .then(({ status, json, text }: any) => {
        if (status === 200)
          setUser({ ...user, ...json });

        if ([400, 401, 403].includes(status)) {
          setUser(null);
          console.log(text);
          window.location.replace(`${AUTH_URL}/login`);
        }

        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
        window.location.replace(`${AUTH_URL}/login`);
      });
  };

  return { loading, signOut, user, getUserInfo };
};

import React, { PropsWithChildren } from 'react';
import { LoaderContextProvider, ResultContextProvider } from 'context';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from 'utils';

export const Providers = ({ children }: PropsWithChildren<unknown>) => {
	return (
		<MuiThemeProvider theme={theme}>
			<ResultContextProvider>
				<LoaderContextProvider>{children}</LoaderContextProvider>
			</ResultContextProvider>
		</MuiThemeProvider>
	);
};

import { SubmitTruckFormData } from 'models';
import { Truck } from 'generate/typesSupplyService';
import { TruckList } from 'generate/types';

export function mapTruckListToInitialValues(truckList?: Truck[]) {
	return (truckList || []).reduce(
		(res, truck) => ({
			...res,
			[truck?.truckReg as string]: {
				checked: false,
				driverData: '',
				trailerReg: '',
				truckReg: truck?.truckReg,
				etaLoadingPoint: '',
			},
		}),
		{}
	) as SubmitTruckFormData;
}

export function mapSubmittedTruckListToInitialValues(truckList?: (TruckList | null)[] | null) {
	return (truckList || []).reduce((res, truck) => {
		const driverData = JSON.stringify({
			driverName: truck?.driverName,
			names: truck?.driverName,
			driverIdNumber: truck?.driverIdNumber,
			idNumber: truck?.driverIdNumber,
		})
		return {
			...res,
			[truck?.truckReg as string]: {
				checked: true,
				driverData,
				trailerReg: truck?.trailerReg,
				truckReg: truck?.truckReg,
        // @ts-ignore
        jobRequestId: truck?.jobRequestId,
			},
		};
	}, {}) as SubmitTruckFormData;
}

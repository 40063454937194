import { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { CustomTabs } from '../custom-tabs';
import { RequestFuelTab } from './RequestFuelTab';
import { useStyles } from '../account/MyAssetsTab.styles';
import { FuelRequestsTab } from './FuelRequestsTab';

export const RequestFuel = () => {
	const { t } = useTranslation('buttons');
	const classes = useStyles();
	const tabList = useMemo(
		() => [
			{
				label: t('fuel_request'),
				value: t('fuel_request'),
				Component: RequestFuelTab,
			},
			{
				label: t('pending'),
				value: t('pending'),
				Component: FuelRequestsTab,
				props: { approvalState: 'not_approved' },
			},
			{
				label: t('approved'),
				value: t('approved'),
				Component: FuelRequestsTab,
				props: { approvalState: 'approved' },
			},
		],
		[t]
	);

	return (
		<Box padding="0 3px">
			<CustomTabs
				tabList={tabList}
				classes={{
					root: classes.tabs,
					indicator: classes.indicator,
				}}
				tabClasses={{
					root: classes.tab,
					selected: classes.tabSelected,
				}}
			/>
		</Box>
	);
};

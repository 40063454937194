import { gql } from '@apollo/client';

export const passwordResetLink = gql`
	mutation passwordResetLink($email: String!) {
		passwordResetLink(email: $email) {
			ok
			message
		}
	}
`;

export function mapFieldsToInitialValues<
	K extends string,
	T extends Partial<Record<K, string | boolean | number | null | unknown[]>>
>(listOfKeys: Array<K>, formData: T = {} as T, keysWhiteList?: Array<keyof T>) {
	return listOfKeys.reduce((res, key) => {
		if (keysWhiteList && keysWhiteList.includes(key)) {
			return {
				...res,
			};
		}
		return {
			...res,
			[key]: formData[key] ?? '',
		};
	}, formData);
}

import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import Fetch from 'i18next-fetch-backend';
import { initReactI18next } from 'react-i18next';
import { IS_PRODUCTION } from 'utils';

const localesPath = `/locales/{{lng}}/{{ns}}.json?${process.env.REACT_APP_VERSION_NUMBER}`;

const detectionOptions = {
	order: ['localStorage', 'navigator'],
};

i18n
	.use(initReactI18next)
	.use(LngDetector)
	.use(Fetch)
	.init({
		defaultNS: 'common',
		ns: ['common'],
		detection: detectionOptions,
		whitelist: ['en'],
		interpolation: { escapeValue: false },
		debug: !IS_PRODUCTION,
		fallbackLng: 'en',
		load: 'languageOnly',
		backend: {
			loadPath: localesPath,
		},
	});

import { User } from 'generate/types';
import { Source } from 'generate/typesLIASupply';

export function getLIASupplySourceType(region: User['region']) {
	if (!region) return Source.LtaEa;

	switch (region) {
		case 'WA':
			return Source.LtaWa;
		case 'SA':
			return Source.LtaSa;
		default:
			return Source.LtaEa;
	}
}
import React, { createContext, useContext, useEffect } from 'react';
import { IUser } from 'models/users/IUser';
import { useAuth } from 'hooks/UserHook';

interface IAuthContext {
    getUserInfo: () => void;
    loading: boolean;
    signOut: () => void;
    user: IUser | null;
}

const AuthContext = createContext<IAuthContext | null>(null);
const useAuthContext = () => useContext(AuthContext);

const AdminProvider: React.FC<React.ReactNode> = ({ children }) => {
    const auth = useAuth();

    useEffect(() => {
        if (!auth?.user) {
            auth.getUserInfo();
        }
    }, [auth]);

    return <AuthContext.Provider value={auth}>
        {children}
    </AuthContext.Provider>
}

export { useAuthContext, AdminProvider }

import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Swipeable, SwipeableOptions, SwipeableProps } from 'react-swipeable';
import cn from 'classnames';
import { Directions } from 'models';
import { Stepper } from '../stepper';

interface TestProps extends PropsWithChildren<SwipeableProps & SwipeableOptions> {
	setActiveSlide?: (activeSlide: number) => void;
	activeSlide?: number;
	goNextSlide?: () => void;
	onSwipeForward?: (activeSlide: number) => void;
	onSwipeBack?: (activeSlide: number) => void;
	onFinish?: () => void;
	getSlidesCount?: (slideCount: number) => void;
	isNextAllowed?: boolean;
	withStepper?: boolean;
	stepperClassName?: string;
	containerClassName?: string;
	minSlideAvailable?: number;
}

export function CustomSwiper({
	setActiveSlide,
	activeSlide = 0,
	onSwipeForward,
	onSwipeBack,
	children,
	getSlidesCount,
	onFinish,
	withStepper,
	stepperClassName,
	containerClassName,
	minSlideAvailable = 0,
	...props
}: TestProps) {
	const slideRef = useRef<HTMLDivElement>(null);
	const [direction, setDirection] = useState<Directions>();
	const [prevActiveSlide, setPrevActiveSlide] = useState(activeSlide);
	const [localActiveSlide, setLocalActiveSlide] = useState(activeSlide);

	const activeSlideNumber = useMemo(
		() => (setActiveSlide ? activeSlide : localActiveSlide),
		[setActiveSlide, activeSlide, localActiveSlide]
	);

	const changeActiveSlideNumber = useCallback(
		(n: number) => (setActiveSlide ? setActiveSlide(n) : setLocalActiveSlide(n)),
		[setActiveSlide, setLocalActiveSlide]
	);

	useEffect(() => {
		getSlidesCount && getSlidesCount(React.Children.count(children));
	}, [children, getSlidesCount]);

	useEffect(() => {
		if (activeSlideNumber > prevActiveSlide) {
			setDirection(Directions.right);
		} else if (activeSlideNumber < prevActiveSlide) {
			setDirection(Directions.left);
		}
		setPrevActiveSlide(activeSlideNumber);
	}, [activeSlideNumber, prevActiveSlide]);

	function handleSwipeRight() {
		if (activeSlideNumber > minSlideAvailable) {
			if (onSwipeBack) {
				onSwipeBack(activeSlide);
			} else {
				changeActiveSlideNumber(activeSlideNumber - 1);
			}
		}
	}

	function handleSwipeLeft() {
		if (activeSlideNumber < React.Children.count(children) - 1) {
			if (onSwipeForward) {
				onSwipeForward(activeSlide);
			} else {
				changeActiveSlideNumber(activeSlideNumber + 1);
			}
		} else {
			onFinish && onFinish();
		}
	}

	return (
		<div className={cn(containerClassName)}>
			<Swipeable
				key={activeSlideNumber}
				onSwipedRight={handleSwipeRight}
				onSwipedLeft={handleSwipeLeft}
				{...props}
			>
				<div
					ref={slideRef}
					className={cn({
						'slide-to-left': direction === Directions.right,
						'slide-to-right': direction === Directions.left,
					})}
				>
					{React.Children.map(children, (slide) => slide)?.filter(
						(_, idx) => idx === (setActiveSlide ? activeSlide : localActiveSlide)
					)}
				</div>
			</Swipeable>
			{withStepper && (
				<Stepper
					steps={React.Children.count(children)}
					activeStep={activeSlideNumber}
					className={stepperClassName}
					onClick={changeActiveSlideNumber}
				/>
			)}
		</div>
	);
}

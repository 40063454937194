import { gql } from '@apollo/client';

export const newJobDetails = gql`
	fragment newJobDetails on Move {
		cargoType
		origin
		destination
		readyToLoad
		currency
		trucksNeeded
		trailerType
		transporterRate
		transporterRateType
		weight
		commodity
		moveId
	}
`;

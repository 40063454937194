import { makeStyles } from '@material-ui/core/styles';
import { COLORS, theme } from 'utils';

export const useStyles = makeStyles({
	bordered: {
		borderTop: `1px solid ${COLORS.gray}`,
	},
	padded: {
		padding: theme.spacing(0, 2, 0, 2),
	},
	userDetailsBlock: {
		padding: theme.spacing(0, 2, 0, 2),
		borderTop: `1px solid ${COLORS.gray}`,
	},
	cell: {
		borderBottom: 0,
	},
	row: {
		'&+&': {
			borderTop: `1px solid ${COLORS.gray}`,
		},
	},
	accountListItem: {
		alignItems: 'baseline',
	},
});

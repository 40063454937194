import { gql } from '@apollo/client';

export const setAssetStatus = gql`
	mutation setAssetStatus(
		$isActive: Boolean!
		$serial: String!
		$transporter: String!
		$assetType: String!
	) {
		setAssetStatus(
			isActive: $isActive
			serial: $serial
			transporter: $transporter
			assetType: $assetType
		) {
			message
			serial
			isActive
			status
		}
	}
`;

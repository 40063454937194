import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { AppPathList } from 'router';
import { CustomSwiper } from 'components/swiper';
import { theme } from 'utils';
import { FormFooter } from '../form-footer';
import { state } from 'state';
import { mapSignUpFormInfo, mapSignUpFormSlides } from 'helpers';
import { FormStepProps, RegionType } from 'models';
import { useStyles } from '../ExternalForm.styles';
import {Stepper} from "../../stepper";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {SkipButton} from '../../skip-button';

interface SignUpFormProps extends FormStepProps {
	activeStep: number;
	setActiveStep: (n: number) => void;
	stepCount: number;
	setStepCount: (stepCount: number) => void;
}

export function SignUpForm({
	activeStep,
	afterSubmit,
	setActiveStep,
	stepCount,
	setStepCount,
}: SignUpFormProps) {
	const { t } = useTranslation('login');
	const classes = useStyles();

	const isSouthAfrica = state.region === RegionType.SA;

	const [formInfoMap, slides] = useMemo(
		() => [mapSignUpFormInfo(isSouthAfrica, t), mapSignUpFormSlides(isSouthAfrica)],
		[t, isSouthAfrica]
	);

	function mockSwipe() {}

	const footerText = useMemo(
		() => [
			{
				question: t('have_account'),
				link: {
					text: t('login'),
					url: AppPathList.login,
				},
			},
		],
		[t]
	);

	return (
		<main className={classes.main}>
			<div className={classes.form}>
				<Box mt={2} mb={2} textAlign="center" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
					<Stepper steps={stepCount} activeStep={activeStep} onClick={setActiveStep} />
					{activeStep > 0 &&
					<SkipButton
						type="submit"
						form={formInfoMap[activeStep]?.id}
						color="primary"
						className="textInitial text-medium"
						endIcon={<ArrowForwardIosIcon/>}
					>
						Skip for now
					</SkipButton>}
				</Box>
				<Typography variant="h6" component="h6" align="left" className="textPrimary">
					{formInfoMap[activeStep]?.title}
				</Typography>
				<Typography variant="body1" align="left" className="textSecondary">
					{formInfoMap[activeStep]?.sub_title}
				</Typography>
				<Box maxWidth={theme.breakpoints.values.sm} marginX="auto">
					<CustomSwiper
						setActiveSlide={setActiveStep}
						activeSlide={activeStep}
						onSwipeForward={mockSwipe}
						onSwipeBack={mockSwipe}
						getSlidesCount={setStepCount}
					>
						{slides.map((Slide, idx) => (
							<Slide afterSubmit={afterSubmit} key={idx} />
						))}
					</CustomSwiper>
				</Box>
				<FormFooter
					activeStep={activeStep}
					stepCount={stepCount}
					formId={formInfoMap[activeStep]?.id}
					text={footerText}
				/>
			</div>
		</main>
	);
}

import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useStyles } from './Sidebar.styles';
import { LogoIcon } from 'components/icons';
import { INavItem } from 'models';
import {
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
} from '@material-ui/core';
import { ChevronLeft, Menu, ExpandLess, ExpandMore } from '@material-ui/icons';
import cn from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { AppPathList } from 'router';
import { useMixpanel } from 'analytics';

interface AdminSidebarProps {
	open?: boolean;
	toggleOpen: () => void;
	navList: INavItem[];
}

export const AdminSidebar = ({ open, toggleOpen, navList }: AdminSidebarProps) => {
	const classes = useStyles();
	const { pathname } = useLocation();
	const mixpanel = useMixpanel();

	useEffect(() => {
		mixpanel.trackLinks('navlink-admin', `Clicked ${pathname} url`);
	}, [pathname, mixpanel]);

	const AdminSubListRoutes = useMemo(() => [AppPathList.transporters, AppPathList.assets], []);

	const [sublistState, setSublistState] = useState(
		AdminSubListRoutes.reduce(
			(res, path) => ({
				...res,
				[path]: false,
			}),
			{}
		) as Record<string, boolean>
	);

	useEffect(() => {
		const sublistActivePath = AdminSubListRoutes.find((path) => pathname.includes(path));

		if (sublistActivePath) {
			setSublistState((prevState) => ({
				...prevState,
				[sublistActivePath]: true,
			}));
		}
	}, [AdminSubListRoutes, pathname]);

	const handleClick = useCallback((subListType?: string) => {
		if (subListType) {
			setSublistState((prevState) => ({
				...prevState,
				[subListType]: !prevState[subListType],
			}));
		}
	}, []);

	const renderNavItem = useCallback(
		({ text, icon, to = '' }: INavItem) => (
			<ListItem
				component={NavLink}
				to={to}
				key={text}
				className={to ? classes.listItemLink : classes.listItem}
				activeClassName={classes.listItemActive}
			>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText primary={text} />
			</ListItem>
		),
		[classes]
	);

	const renderNavList = useCallback(
		({ text, icon, to, nested, sublistType }: INavItem) => {
			if (to) {
				return renderNavItem({ text, icon, to });
			} else if (nested) {
				return (
					<>
						<ListItem
							onClick={() => handleClick(sublistType)}
							className={classes.listItem}
							button
							key={text}
						>
							<ListItemIcon>{icon}</ListItemIcon>
							<ListItemText primary={text} />
							{sublistType && (sublistState[sublistType] ? <ExpandLess /> : <ExpandMore />)}
						</ListItem>
						<Collapse
							key={text + to}
							in={!!sublistType && sublistState[sublistType]}
							timeout="auto"
							unmountOnExit
						>
							{nested.map(renderNavList)}
						</Collapse>
					</>
				);
			}

			return null;
		},
		[classes, handleClick, renderNavItem, sublistState]
	);

	return (
		<Drawer
			variant="permanent"
			className={cn(classes.drawer, classes.adminSidebar, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open,
			})}
			classes={{
				paper: cn({
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				}),
			}}
		>
			<IconButton onClick={toggleOpen} className={classes.toggleIcon}>
				{open ? <ChevronLeft /> : <Menu />}
			</IconButton>
			<LogoIcon
				className={cn(classes.logo, {
					[classes.logoSmall]: !open,
				})}
			/>
			<List component="div">{navList.map(renderNavList)}</List>
		</Drawer>
	);
};

import React, { forwardRef } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import cn from 'classnames';
import { useMixpanel } from 'analytics';
import { useLocation } from 'react-router-dom';
import capitalize from 'lodash/capitalize';
import lowerCase from 'lodash/lowerCase';

export interface CustomButtonProps extends ButtonProps {
	component?: React.ElementType;
	autoWidth?: boolean;
	mixPanelEventName?: string;
	mixPanelEventProperties?: any;
	trackMixpanel?: boolean;
}

export const CustomButton = forwardRef<HTMLButtonElement, CustomButtonProps>(
	(
		{
			className,
			children,
			mixPanelEventName,
			mixPanelEventProperties,
			variant = 'contained',
			color = 'primary',
			autoWidth=false,
			onClick,
			trackMixpanel = true,
			...props
		},
		ref
	) => {
		const mixpanel = useMixpanel();
		const { pathname } = useLocation();
		return (
			<Button
				{...props}
				className={cn(className)}
				ref={ref as any}
				// buttonRef={ref}
				variant={variant}
				color={color}
				fullWidth={autoWidth}
				onClick={(evt) => {
					if (onClick) {
						onClick(evt);
						if (typeof mixPanelEventName === 'string') {
							mixpanel.track(mixPanelEventName, {
								pathname,
								...mixPanelEventProperties,
							});
						} else {
							if (trackMixpanel && typeof children === 'string') {
								mixpanel.track(`${capitalize(children)} event (Click on ${lowerCase(children)})`, {
									pathname,
								});
							}
						}
					}
				}}
			>
				{children}
			</Button>
		);
	}
);

/* eslint-disable */
// WARNING: This file is automatically generated. Do not edit.
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};

export type DeleteDocument = {
  __typename?: 'DeleteDocument';
  document?: Maybe<DocumentType>;
};

export type DocumentInput = {
  documentTypeId: Scalars['Int'];
  driverId?: InputMaybe<Scalars['Int']>;
  files: Scalars['Upload'];
  fuelId?: InputMaybe<Scalars['Int']>;
  issueId?: InputMaybe<Scalars['Int']>;
  moveId?: InputMaybe<Scalars['Int']>;
  source: Scalars['String'];
  truckId?: InputMaybe<Scalars['Int']>;
};

export type DocumentType = {
  __typename?: 'DocumentType';
  cargoClientId?: Maybe<Scalars['Int']>;
  clearingAgentId?: Maybe<Scalars['Int']>;
  documentType?: Maybe<DocumentTypeType>;
  documentTypeId: Scalars['Int'];
  documentTypeLabel?: Maybe<Scalars['String']>;
  driverId?: Maybe<Scalars['Int']>;
  fuelId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isArchived?: Maybe<Scalars['Boolean']>;
  issueId?: Maybe<Scalars['Int']>;
  moveId?: Maybe<Scalars['Int']>;
  presignedUrl?: Maybe<Scalars['String']>;
  s3Key: Scalars['String'];
  source: Scalars['String'];
  trailerId?: Maybe<Scalars['Int']>;
  transporterId?: Maybe<Scalars['Int']>;
  truckId?: Maybe<Scalars['Int']>;
  uploadDate?: Maybe<Scalars['String']>;
};

export type DocumentTypeType = {
  __typename?: 'DocumentTypeType';
  category: Scalars['String'];
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  stages?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootMutation = {
  __typename?: 'RootMutation';
  deleteDocument?: Maybe<DeleteDocument>;
  uploadDocuments?: Maybe<UploadDocuments>;
};


export type RootMutationDeleteDocumentArgs = {
  documentId: Scalars['ID'];
};


export type RootMutationUploadDocumentsArgs = {
  input?: InputMaybe<DocumentInput>;
};

export type RootQuery = {
  __typename?: 'RootQuery';
  documentTypes?: Maybe<Array<Maybe<DocumentTypeType>>>;
  getDocuments?: Maybe<Array<Maybe<DocumentType>>>;
};


export type RootQueryDocumentTypesArgs = {
  region?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Scalars['String']>;
};


export type RootQueryGetDocumentsArgs = {
  driverId?: InputMaybe<Scalars['Int']>;
  fuelId?: InputMaybe<Scalars['Int']>;
  issueId?: InputMaybe<Scalars['Int']>;
  moveId?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Scalars['String']>;
  truckId?: InputMaybe<Scalars['Int']>;
};

export type UploadDocuments = {
  __typename?: 'UploadDocuments';
  success?: Maybe<Scalars['Boolean']>;
};

export type UploadDocumentsMutationVariables = Exact<{
  input: DocumentInput;
}>;


export type UploadDocumentsMutation = { __typename?: 'RootMutation', uploadDocuments?: { __typename?: 'UploadDocuments', success?: boolean | null | undefined } | null | undefined };

export type GetDocumentTypesQueryVariables = Exact<{
  stage?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
}>;


export type GetDocumentTypesQuery = { __typename?: 'RootQuery', documentTypes?: Array<{ __typename?: 'DocumentTypeType', id: string, label?: string | null | undefined, region?: string | null | undefined, stages?: Array<string | null | undefined> | null | undefined } | null | undefined> | null | undefined };

export type GetDocumentsQueryVariables = Exact<{
  moveId?: Maybe<Scalars['Int']>;
  truckId?: Maybe<Scalars['Int']>;
  driverId?: Maybe<Scalars['Int']>;
  fuelId?: Maybe<Scalars['Int']>;
  issueId?: Maybe<Scalars['Int']>;
  stage?: Maybe<Scalars['String']>;
}>;


export type GetDocumentsQuery = { __typename?: 'RootQuery', getDocuments?: Array<{ __typename?: 'DocumentType', cargoClientId?: number | null | undefined, clearingAgentId?: number | null | undefined, documentTypeId: number, documentTypeLabel?: string | null | undefined, driverId?: number | null | undefined, fuelId?: number | null | undefined, id: string, isArchived?: boolean | null | undefined, issueId?: number | null | undefined, moveId?: number | null | undefined, presignedUrl?: string | null | undefined, source: string, trailerId?: number | null | undefined, transporterId?: number | null | undefined, truckId?: number | null | undefined, uploadDate?: string | null | undefined, documentType?: { __typename?: 'DocumentTypeType', label?: string | null | undefined, id: string, category: string, region?: string | null | undefined, stages?: Array<string | null | undefined> | null | undefined } | null | undefined } | null | undefined> | null | undefined };

export type GetTransporterDocumentsQueryVariables = Exact<{
  moveId?: Maybe<Scalars['Int']>;
  stage?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
}>;


export type GetTransporterDocumentsQuery = { __typename?: 'RootQuery', documentTypes?: Array<{ __typename?: 'DocumentTypeType', id: string, label?: string | null | undefined, region?: string | null | undefined } | null | undefined> | null | undefined, getDocuments?: Array<{ __typename?: 'DocumentType', documentTypeId: number, documentTypeLabel?: string | null | undefined, driverId?: number | null | undefined, fuelId?: number | null | undefined, id: string, isArchived?: boolean | null | undefined, issueId?: number | null | undefined, moveId?: number | null | undefined, presignedUrl?: string | null | undefined, source: string, trailerId?: number | null | undefined, transporterId?: number | null | undefined, truckId?: number | null | undefined, uploadDate?: string | null | undefined } | null | undefined> | null | undefined };


export const UploadDocumentsDocument = gql`
    mutation uploadDocuments($input: DocumentInput!) {
  uploadDocuments(input: $input) {
    success
  }
}
    `;
export type UploadDocumentsMutationFn = Apollo.MutationFunction<UploadDocumentsMutation, UploadDocumentsMutationVariables>;

/**
 * __useUploadDocumentsMutation__
 *
 * To run a mutation, you first call `useUploadDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentsMutation, { data, loading, error }] = useUploadDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<UploadDocumentsMutation, UploadDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadDocumentsMutation, UploadDocumentsMutationVariables>(UploadDocumentsDocument, options);
      }
export type UploadDocumentsMutationHookResult = ReturnType<typeof useUploadDocumentsMutation>;
export type UploadDocumentsMutationResult = Apollo.MutationResult<UploadDocumentsMutation>;
export type UploadDocumentsMutationOptions = Apollo.BaseMutationOptions<UploadDocumentsMutation, UploadDocumentsMutationVariables>;
export const GetDocumentTypesDocument = gql`
    query getDocumentTypes($stage: String, $region: String) {
  documentTypes(stage: $stage, region: $region) {
    id
    label
    region
    stages
  }
}
    `;

/**
 * __useGetDocumentTypesQuery__
 *
 * To run a query within a React component, call `useGetDocumentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentTypesQuery({
 *   variables: {
 *      stage: // value for 'stage'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useGetDocumentTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>(GetDocumentTypesDocument, options);
      }
export function useGetDocumentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>(GetDocumentTypesDocument, options);
        }
export type GetDocumentTypesQueryHookResult = ReturnType<typeof useGetDocumentTypesQuery>;
export type GetDocumentTypesLazyQueryHookResult = ReturnType<typeof useGetDocumentTypesLazyQuery>;
export type GetDocumentTypesQueryResult = Apollo.QueryResult<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>;
export const GetDocumentsDocument = gql`
    query getDocuments($moveId: Int, $truckId: Int, $driverId: Int, $fuelId: Int, $issueId: Int, $stage: String) {
  getDocuments(moveId: $moveId, truckId: $truckId, driverId: $driverId, fuelId: $fuelId, issueId: $issueId, stage: $stage) {
    cargoClientId
    clearingAgentId
    documentType {
      label
      id
      category
      region
      stages
    }
    documentTypeId
    documentTypeLabel
    driverId
    fuelId
    id
    isArchived
    issueId
    moveId
    presignedUrl
    source
    trailerId
    transporterId
    truckId
    uploadDate
  }
}
    `;

/**
 * __useGetDocumentsQuery__
 *
 * To run a query within a React component, call `useGetDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentsQuery({
 *   variables: {
 *      moveId: // value for 'moveId'
 *      truckId: // value for 'truckId'
 *      driverId: // value for 'driverId'
 *      fuelId: // value for 'fuelId'
 *      issueId: // value for 'issueId'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useGetDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GetDocumentsQuery, GetDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentsQuery, GetDocumentsQueryVariables>(GetDocumentsDocument, options);
      }
export function useGetDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentsQuery, GetDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentsQuery, GetDocumentsQueryVariables>(GetDocumentsDocument, options);
        }
export type GetDocumentsQueryHookResult = ReturnType<typeof useGetDocumentsQuery>;
export type GetDocumentsLazyQueryHookResult = ReturnType<typeof useGetDocumentsLazyQuery>;
export type GetDocumentsQueryResult = Apollo.QueryResult<GetDocumentsQuery, GetDocumentsQueryVariables>;
export const GetTransporterDocumentsDocument = gql`
    query getTransporterDocuments($moveId: Int, $stage: String, $region: String) {
  documentTypes(stage: $stage, region: $region) {
    id
    label
    region
  }
  getDocuments(moveId: $moveId, stage: $stage) {
    documentTypeId
    documentTypeLabel
    driverId
    fuelId
    id
    isArchived
    issueId
    moveId
    presignedUrl
    source
    trailerId
    transporterId
    truckId
    uploadDate
  }
}
    `;

/**
 * __useGetTransporterDocumentsQuery__
 *
 * To run a query within a React component, call `useGetTransporterDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransporterDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransporterDocumentsQuery({
 *   variables: {
 *      moveId: // value for 'moveId'
 *      stage: // value for 'stage'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useGetTransporterDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GetTransporterDocumentsQuery, GetTransporterDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransporterDocumentsQuery, GetTransporterDocumentsQueryVariables>(GetTransporterDocumentsDocument, options);
      }
export function useGetTransporterDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransporterDocumentsQuery, GetTransporterDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransporterDocumentsQuery, GetTransporterDocumentsQueryVariables>(GetTransporterDocumentsDocument, options);
        }
export type GetTransporterDocumentsQueryHookResult = ReturnType<typeof useGetTransporterDocumentsQuery>;
export type GetTransporterDocumentsLazyQueryHookResult = ReturnType<typeof useGetTransporterDocumentsLazyQuery>;
export type GetTransporterDocumentsQueryResult = Apollo.QueryResult<GetTransporterDocumentsQuery, GetTransporterDocumentsQueryVariables>;
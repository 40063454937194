import { makeStyles } from '@material-ui/core';
import { COLORS } from 'utils';

export const useStyles = makeStyles({
	wrapper: {
		position: 'relative',
	},
	icon: {
		width: 19,
		fill: '#FFFFFF',
		display: 'block',
	},
	dot: {
		position: 'absolute',
		top: 2,
		right: 2,
		content: '',
		display: 'block',
		width: 7,
		height: 7,
		borderRadius: 7,
		backgroundColor: COLORS.danger,
	},
});

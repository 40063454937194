import { gql } from '@apollo/client';

export const getIssueTypes = gql`
  query getIssueTypes($issueTypeCategoryId: Int) {
    issueTypes(issueTypeCategoryId: $issueTypeCategoryId) {
      id
      issueType
      issueTypeCategoryId
    }
  }
`;

import { makeStyles } from '@material-ui/core';
import { COLORS, theme } from 'utils';

export const useStyles = makeStyles({
	guestList: {
		marginTop: 0,
	},
	guestItem: {
		borderBottom: `1px solid ${COLORS.gray}`,
		marginBottom: 0,
		'&:first-of-type': {
			borderTop: `1px solid ${COLORS.gray}`,
		},
	},
	wrapper: {
		display: 'flex',
		padding: theme.spacing(1, 2, 1, 2),
		alignItems: 'center',
		[theme.breakpoints.down('xs')]: {
			flexWrap: 'wrap',
		},
	},
	link: {
		margin: '5px auto 5px 0',
		wordBreak: 'break-word',
		paddingRight: 5,
	},
	controls: {
		maxWidth: 260,
		display: 'flex',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			flexGrow: 1,
			maxWidth: 'initial',
		},
	},
	list: {
		height: '100%',
		marginTop: '1rem',
	},
	listItem: {
		backgroundColor: '#fff',
	},
});

import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			padding: '2px 2px',
			display: 'flex',
			alignItems: 'center',
			width: 400,
			boxShadow: 'none',
			border: `1px solid ${theme.palette.grey[300]}`,
			margin: '20px 0',
		},
		input: {
			marginLeft: theme.spacing(1),
			flex: 1,
		},
		iconButton: {
			padding: '5px',
		},
		divider: {
			height: 28,
			margin: 4,
		},
	})
);

import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils';

export const useStyles = makeStyles({
	card: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	border: {
		borderRadius: 4,
		border: `1px solid #A59F9F`,
		padding: '6px 10px',
	},
	key: {
		color: COLORS.grey3,
		fontSize: 16,
		fontWeight: 500,
		lineHeight: '16px',
		marginBottom: 8,
	},
	value: {
		fontSize: 16,
		fontWeight: 300,
		lineHeight: '16px',
		color: COLORS.textBlack,
	},
});

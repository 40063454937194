import { gql } from '@apollo/client';

export const updateDriver = gql`
	mutation updateDriver($id: Int!, $active: Boolean!) {
		updateDriver(id: $id, driverData: { active: $active }) {
			status
			message
		}
	}
`;

import { gql } from '@apollo/client';

export const getInsuranceDetails = gql`
	query getInsuranceDetails {
		insurance {
			id
			broker
			brokerContactName
			brokerTelNumber
			brokerEmail
			insuranceCompany
			policyNumber
			hasComprehensiveGitCover
			gitCoverPerLoad
			hasPublicLiabilityInsurance
		}
	}
`;

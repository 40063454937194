import { TrailerListTemp } from 'components';
import { TrailerBulkUpload } from 'containers';
import { useModalStateControls, useReadUserInfo } from 'hooks';
import { useGetSupplyTrailersQuery } from 'generate/typesSupplyService';
import { AssetQueryContainer } from './AssetQueryContainer';
import { TrailerCard } from './TrailerCard';
import { supplyServiceClient } from 'graphqlapi/clients/supplyServiceClient';

export const TrailerList = () => {
	const { isOpened, toggleModal } = useModalStateControls();
	const userInfo = useReadUserInfo();

	return (
		<AssetQueryContainer
			keyName="value"
			dataKey="trailers"
			useQuery={useGetSupplyTrailersQuery}
			recordKeyList={['trailerReg', 'active']}
			ItemTemplate={TrailerCard}
			options={{
				client: supplyServiceClient,
				variables: {
					transporterId: userInfo?.transporter_id,
          first: 100
				},
			}}
		>
			<TrailerListTemp onAddAsset={toggleModal} />
			<TrailerBulkUpload isOpened={isOpened} toggleModal={toggleModal} />
		</AssetQueryContainer>
	);
};

import { readClient } from 'helpers';
import { getUserInfo } from 'graphqlapi/lta';
import { User } from 'generate/types';
import { RegionType } from '../models';

export function useReadUserInfo() {
	const { userInfo } = readClient<{ userInfo: User }>(getUserInfo);

	return (
		{
			...userInfo,
			isFirstLogin: userInfo?.first_login,
			region: userInfo?.region as RegionType | null | undefined,
			isWA: userInfo?.region === RegionType.WA,
			isSA: userInfo?.region === RegionType.SA,
			isEA: userInfo?.region === RegionType.EA,
			verified: userInfo?.verified,
		} || {}
	);
}

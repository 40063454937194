import React from 'react';
import quickTransportSrc from 'assets/images/quick_transport_logo.svg';

export function LoriLogo() {

    return (
        <header>
            <div className="max-form-width center-horizontal">
                <img className="logo-main" src={quickTransportSrc} alt="logo" id="main-logo"/>
            </div>
        </header>
    );
}

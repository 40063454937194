import { gql } from '@apollo/client';

export const uploadDocuments = gql`
	mutation uploadDocuments(
		$companyId: Int!
		$directorIdentification: Upload
		$certificateOfIncorporation: Upload
		$kraPin: Upload
		$tin: Upload
		$kraTcc: Upload
		$proofOfDirectorship: Upload
		$carriersLiability: Upload
		$carriersLiabilityExpiry: Date
		$companyName: String
		$carrierResolution: Upload
		$gitCoverConfirmation: Upload
		$gitCoverExpiry: Date
		$insurancePolicies: Upload
		$beeCertificate: Upload
		$bankLetter: Upload
		$isVatRegistered: Boolean
		$vatRegistrationNumber: String
		$vatCertificate: Upload
		$registrationCertificate: Upload
		$taxClearanceCertificate: Upload
		$companyLetterhead: Upload
		$directorsIds: Upload
		$letterOfGoodStanding: Upload
		$vehicleRegistrationDocuments: Upload
		$hasQualityManagementCert: Boolean
		$qualityManagementCertificate: Upload
		$clientMutationId: String
	) {
		createDocuments(
			input: {
				directorIdentification: $directorIdentification
				certificateOfIncorporation: $certificateOfIncorporation
				kraPin: $kraPin
				tin: $tin
				kraTcc: $kraTcc
				proofOfDirectorship: $proofOfDirectorship
				carriersLiability: $carriersLiability
				carriersLiabilityExpiry: $carriersLiabilityExpiry
				companyId: $companyId
				companyName: $companyName
				carrierResolution: $carrierResolution
				gitCoverConfirmation: $gitCoverConfirmation
				gitCoverExpiry: $gitCoverExpiry
				insurancePolicies: $insurancePolicies
				beeCertificate: $beeCertificate
				bankLetter: $bankLetter
				isVatRegistered: $isVatRegistered
				vatRegistrationNumber: $vatRegistrationNumber
				vatCertificate: $vatCertificate
				registrationCertificate: $registrationCertificate
				taxClearanceCertificate: $taxClearanceCertificate
				companyLetterhead: $companyLetterhead
				directorsIds: $directorsIds
				letterOfGoodStanding: $letterOfGoodStanding
				vehicleRegistrationDocuments: $vehicleRegistrationDocuments
				hasQualityManagementCert: $hasQualityManagementCert
				qualityManagementCertificate: $qualityManagementCertificate
				clientMutationId: $clientMutationId
			}
		) {
			documents {
				id
			}
			ok
			message
		}
	}
`;

import React from 'react';
import cn from 'classnames';
import { IIconProps } from './IIconProps';

export const LocationIcon = ({ className, pointer, title = 'location' }: IIconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="LocationIcon"
			viewBox="0 0 26 26"
			className={cn(className, {
				pointer: pointer,
			})}
		>
			<title>{title}</title>
			<path d="M13,6.1401367c-2.2060547,0-4,1.7944336-4,4s1.7939453,4,4,4s4-1.7944336,4-4   S15.2060547,6.1401367,13,6.1401367z M13,13.1401367c-1.6542969,0-3-1.3457031-3-3s1.3457031-3,3-3s3,1.3457031,3,3   S14.6542969,13.1401367,13,13.1401367z" />
			<path d="M13.0136719,1c-0.0107422-0.0004883-0.0195313-0.0004883-0.0302734,0   c-0.0117188-0.0004883-0.0205078-0.0009766-0.0302734,0c-5.0244141,0.0249023-9.1035156,4.1157227-9.1035156,9.1401367   c0,2.3852539,1.0380859,4.3549805,1.9150391,5.5927734c0.0048828,0.0083008,0.0107422,0.0166016,0.0175781,0.0249023   l6.5810547,8.9370117C12.5039063,24.8867188,12.7617188,25,13,25s0.4628906-0.1132813,0.6035156-0.3051758l6.5810547-8.9370117   c0.0068359-0.0083008,0.0126953-0.0166016,0.0175781-0.0249023c0.8769531-1.237793,1.9150391-3.2075195,1.9150391-5.5927734   C22.1171875,5.1157227,18.0380859,1.0249023,13.0136719,1z M18.9677734,14.8808594c0,0.0004883,0,0.0004883,0,0.0004883   l-5.984375,8.1264648l-5.984375-8.1264648c0,0,0,0,0-0.0004883c-0.7529297-1.0585938-1.6494141-2.7402344-1.6494141-4.7407227   c0-4.2075195,3.4228516-7.6313477,7.6337891-7.6401367c4.2109375,0.0087891,7.6337891,3.4326172,7.6337891,7.6401367   C20.6171875,12.140625,19.7207031,13.8222656,18.9677734,14.8808594z" />
		</svg>
	);
};

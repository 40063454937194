import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	wrapper: {
		'&:hover': {
			opacity: 1,
			backgroundColor: 'transparent',
		},
		'&:hover $imgActive': {
			opacity: 1,
		},
		'& .MuiButton-label': {
			display: 'block',
		},
	},
	imgWrapper: {
		position: 'relative',
		height: '1rem',
	},
	img: {
		height: '100%',
		position: 'absolute',
		top: 0,
		left: '50%',
		transform: 'translateX(-50%)',
	},
	imgActive: {
		transition: '.1s ease-out',
		'&.active': {
			opacity: 1,
		},
	},
	text: {
		marginTop: 5,
		fontSize: 12,
		textTransform: 'capitalize',
		lineHeight: 1.4,
		color: '#669D94',

		'&.active': {
			color: '#fff',
		},
	},
	disabled: {
		pointerEvents: 'none',
	},
});

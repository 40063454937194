import React, { useEffect, useState } from 'react';
import { SignUpForm as SignUpFormTemp } from 'components';
import { state } from 'state';
import { getFromSessionStorage, removeFromSessionStorage, saveToSessionStorage } from 'helpers';
import { COMMON } from 'utils';
import { useDidUpdateEffect, useResultContext } from '../../hooks';
import { AppPathList } from '../../router';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const SignUpForm = () => {
	const { t: tM } = useTranslation('modals');
	const { push } = useHistory();

	const { toggleResult, isResultOpened } = useResultContext();

	const [stepCount, setStepCount] = useState(0);

	const [activeStep, setActiveStep] = useState(() => {
		const res = getFromSessionStorage(COMMON.activeStep);
		return res ? parseInt(res) : 0;
	});

	useEffect(() => {
		state.company = getFromSessionStorage(COMMON.companyData);

		return () => {
			removeFromSessionStorage(COMMON.companyData);
			removeFromSessionStorage(COMMON.activeStep);
		};
	}, []);

	useEffect(() => {
		saveToSessionStorage(COMMON.activeStep, activeStep);
	}, [activeStep]);

	function handleAfterSubmit() {
		if (activeStep === stepCount) {
		} else {
			setActiveStep(activeStep + 1);
		}
	}

	useEffect(() => {
		if (stepCount && stepCount === activeStep) {
			toggleResult({
				text: tM('sign_up_success'),
				type: 'success',
			});
		}
	}, [activeStep, toggleResult, stepCount, tM]);

	useDidUpdateEffect(() => {
		if (!isResultOpened) {
			push(AppPathList.login);
		}
	}, [isResultOpened, push]);

	return (
		<SignUpFormTemp
			activeStep={activeStep}
			afterSubmit={handleAfterSubmit}
			stepCount={stepCount}
			setStepCount={setStepCount}
			setActiveStep={setActiveStep}
		/>
	);
};

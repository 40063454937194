export const EXTENSIONS = {
	pdf: 'application/pdf',
	csv: '.csv',
	xlsOld: 'application/vnd.ms-excel',
	xls: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	images: 'image/*',
};

export const SIGN_UP_EXTENSION_LIST = [EXTENSIONS.pdf, EXTENSIONS.images].join(',');

export const TRUCK_LIST_DATA_EXTENSION_LIST = [
	EXTENSIONS.pdf,
	EXTENSIONS.xlsOld,
	EXTENSIONS.xls,
].join(',');

import { gql } from '@apollo/client';
import { userInfo } from '../fragments';

export const getUserInfo = gql`
	query getUserInfo {
		userInfo {
			...userInfo
		}
	}
	${userInfo}
`;

export function formatCustomNumber(params: any, precision = 2) {
	let currency: number;
	try {
		let regex
		if (precision === 0) {
			regex = /\B(?=(?:\d{3})+(?!\d))/g
		} else {
			regex = /\d(?=(\d{3})+\.)/g
		}

		// @ts-ignore
		currency = Number(params)
			.toFixed(precision)
			.replace(regex, '$&,');
	} catch (error) {
		currency = Number(params);
		console.error({ error });
	}
	return currency;
}

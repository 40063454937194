import React from 'react';
import cn from 'classnames';
import { IIconProps } from './IIconProps';

export const SignOutIcon = ({ className, title = '', pointer = true, ...props }: IIconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="SignOutIcon"
			viewBox="0 0 32 32"
			className={cn(className, 'icon', {
				pointer: pointer,
			})}
			{...props}
		>
			<title>{title}</title>
			<path d="M18 30c0-1.105-0.895-2-2-2h-12v-24h12c1.105 0 2-0.894 2-2s-0.895-2-2-2h-14c-1.105 0-2 0.894-2 2v28c0 1.105 0.895 2 2 2h14c1.105 0 2-0.895 2-2zM12 14c-1.104 0-2 0.895-2 2s0.896 2 2 2h12v6l8-8-8-8v6h-12z" />
		</svg>
	);
};

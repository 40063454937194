import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		capturedImage: {
			display: 'block',
			width: 'calc(100% + 30px)',
			margin: '-30px -15px 15px -15px',
			backgroundColor: '#ddd',
		},
		video: {
			display: 'block',
			width: 'calc(100% + 30px)',
			margin: '-30px -15px 15px -15px',
			backgroundColor: '#ddd',
		},
		heightAuto: {
			height: "auto"
		}
	})
);

import React from 'react';
import { ModalWrapper } from '../ModalWrapper';
import { useTranslation } from 'react-i18next';
import { CustomUploadFieldLfa } from 'containers';
import { useStyles } from './UploadDocuments.styles';
import { Formik, Form } from 'formik';

import { SIGN_UP_EXTENSION_LIST } from 'utils';
import { CustomButton } from 'components/button';
import {Box, Link} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { DocumentTypeType, DocumentType } from 'generate/typesDMS';
import checkCircle from 'assets/icons/check_circle.svg';

interface UploadDocumentsProps {
  onClose: () => void;
  documentTypes: [DocumentTypeType] | [];
  transporterDocuments: [DocumentType] | [];
}

export const UploadDocuments = ({
  onClose,
  documentTypes,
  transporterDocuments,
}: UploadDocumentsProps) => {
  const { t } = useTranslation('job');
  const { t: tM } = useTranslation('myjobs');
  const { t: tB } = useTranslation('buttons');
  const { t: tC } = useTranslation('common');
  const classes = useStyles();

    const documentTypesWithDocuments = [];
    for (const documentType of documentTypes) {
        const matchingDocuments = transporterDocuments.filter((document: DocumentType) => Number(document.documentTypeId) === Number(documentType.id));
        const newUpload: boolean = matchingDocuments.length <= 0;
        documentTypesWithDocuments.push({...documentType, documents: matchingDocuments, newUpload: newUpload});
    }

  return (
    // @ts-ignore
    // @ts-ignore
    <ModalWrapper open={true} onClose={onClose} withCloseBtn={true} className={classes.modal}
                  contentClassName={classes.modalContent}>
      <Formik
        onSubmit={() => {}}
        initialValues={{}}
        enableReinitialize
      >
        <Form>
          <Typography className={classes.heading}>
            {tM('upload_documents')}
          </Typography>
          {/* @ts-ignore */}
          {documentTypesWithDocuments.map(({ label, id, documents, newUpload }) => (
              <Box display='flex' flexDirection='column' marginTop={2}>
                  <Typography className={classes.title}>
                      {label}
                  </Typography>
                  {/*List uploaded docs if any*/}
                  {documents.map((document, index)=> (
                      <Box className={classes.container} marginTop={2} display="flex"
                           flexDirection="row" alignItems='center'>
                          <img
                              src={checkCircle}
                              alt={t('upload')}
                              width={30}
                              className={classes.icon}
                          />
                        <Box display="flex" alignItems="start" flexDirection="column">
                          <Typography className={classes.successLabel}>
                            {tC('uploaded')}
                          </Typography>
                          <Typography className={classes.label}>
                            {document.documentTypeLabel} - {index + 1}
                          </Typography>
                          <Link href={document.presignedUrl!!}  target="_blank" rel="noopener">
                            <Typography className={classes.label}>
                              {tC('click_to_view')}
                            </Typography>
                          </Link>
                        </Box>
                      </Box>
                  ))}
                  <CustomUploadFieldLfa
                      key={id}
                      name={id}
                      newUpload={newUpload}
                      accept={SIGN_UP_EXTENSION_LIST}
                      // @ts-ignore
                      label={t(label)}
                  />
              </Box>

          ))}
          <Box marginTop={2}>
            <CustomButton autoWidth onClick={onClose}>
              {tB('done')}
            </CustomButton>
          </Box>
        </Form>
      </Formik>
    </ModalWrapper>
  );
};

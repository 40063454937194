import React, { PropsWithChildren } from 'react';
import { Container, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	content: {
		display: 'flex',
		flexGrow: 1,
		backgroundColor: 'transparent',
		padding: '14px 0 0',
		overflowY: 'auto',
	},
	container: {
		height: '100%',
		flexDirection: 'column',
	},
});

export const ContentWrapper = ({ children }: PropsWithChildren<{}>) => {
	const classes = useStyles();

	return (
		<main className={classes.content}>
			<Container className={classes.container}>
				<>{children}</>
			</Container>
		</main>
	);
};

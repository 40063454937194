import React from 'react';
import {
	TableContainer,
	Table,
	TableRow,
	TableCell,
	TableBody,
	TableRowProps,
} from '@material-ui/core';
import { IColumnList, IRow } from 'models';
import { TableCellProps } from '@material-ui/core/TableCell/TableCell';

export interface CustomTableProps<T> {
	data: IRow[];
	columns: IColumnList<T>;
	renderCell: (name: keyof T, cellData: unknown) => React.ReactElement;
	tableCellProps?: TableCellProps;
	tableRowProps?: TableRowProps;
}

export function CustomTable<T>({
	columns,
	data,
	renderCell,
	tableCellProps = {},
	tableRowProps = {},
}: CustomTableProps<T>) {
	const classes = {} as Record<string, string>;

	return (
		<TableContainer>
			<Table className={classes.table} size="small" aria-label="a dense table">
				<TableBody>
					{data.map((row, i) => (
						<TableRow key={i} {...tableRowProps}>
							{columns.map(({ name, props = {} }) => (
								<TableCell key={name as unknown as string} {...tableCellProps} {...props}>
									{renderCell(name, row[name as keyof typeof row])}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

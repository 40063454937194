import { CompanyData, SessionStorageKeyType } from 'models';
import { COMMON } from 'utils';

export function getFromSessionStorage(k: COMMON.companyData): CompanyData;
// eslint-disable-next-line no-redeclare
export function getFromSessionStorage(k: SessionStorageKeyType): string;
// eslint-disable-next-line no-redeclare
export function getFromSessionStorage(key: SessionStorageKeyType) {
	const res = sessionStorage.getItem(key);
	return res ? JSON.parse(res) : res;
}

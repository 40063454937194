import { gql } from '@apollo/client';

export const getCompanyDetails = gql`
	query getCompanyDetails {
		companyDetails {
			id
			tradingName
			enterpriseType
			postalAddress
			physicalAddress
			registrationNumber
			dateEstablished
			numberOfDirectors
			company {
				directors {
					edges {
						node {
							directorName
							directorNumber
							directorResidentialAddress
							directorTelNumber
						}
					}
				}
			}
		}
	}
`;

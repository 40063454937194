import { gql } from '@apollo/client';

export const fuelRequestPayload = gql`
	fragment fuelRequestPayload on FuelRequest {
		fuelStationCode
		truckReg
		fuelAmount
		requestDate
	}
`;

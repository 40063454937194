import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useMixpanel } from 'analytics';
import Typography from '@material-ui/core/Typography';
import { Order } from 'generate/types';
import { useReadUserInfo } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useStyles } from './AvailableJobsModal.styles';

interface NewAvailableJobsModalProps {
  item?: Order;
  onSuggestBtnClicked: (lroId: string, weight: string) => void;
  onAcceptBtnClicked: (lroId: string, weight: string) => void;
  onCardClicked?: (props: Order | any) => void;
}

export function NewAvailableJobsModal({
  item,
  onSuggestBtnClicked,
  onAcceptBtnClicked,
}: NewAvailableJobsModalProps) {
  const { t } = useTranslation('available');
  const { t: tBtn } = useTranslation('buttons');
  const { t: tJob } = useTranslation('job');
  const { t: tModals } = useTranslation('modals');

  const mixpanel = useMixpanel();

  const { isSA, isEA } = useReadUserInfo();
  const classes = useStyles();

  useEffect(() => {
    mixpanel.track('New available jobs', {
      message: 'New available jobs page renders successfully ',
    });
  }, [mixpanel]);

  return (
    <div className={classes.paper}>
      <div className={classes.confirmation}>
        <Typography variant='h6' component='h6' color='textPrimary'>
          {tModals('confirmation')}
        </Typography>
      </div>
      <div>
        <Typography variant='h6' component='h6' color='textSecondary'>
          {item?.commodity}
        </Typography>
      </div>
      <div className={classes.priceNewCard}>
        <div className={classes.priceDetails}>
          <div className={classes.truckDistanceType}>
            <Typography variant='subtitle1' component='p' color='textPrimary'>
              {item?.currency}&nbsp;
            </Typography>
            <Typography variant='h5' component='h1' color='textPrimary'>
              <strong>{item?.marketRate}</strong>&nbsp;
            </Typography>
            <Typography variant='subtitle1' component='p' color='textPrimary'>
              {isEA && ' + VAT'}
            </Typography>
          </div>
          <div>
            <Typography variant='subtitle1' component='p' color='textPrimary'>
              {item?.transporterRateType}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.deadline}>
        <Typography variant='subtitle1' component='p' color='inherit'>
          {tJob('deadline_to_load')} : {item?.deadlineToLift}
        </Typography>
      </div>
      <ul className={classes.orderDisclaimer}>
        <li className='new' data-qa="qa-price-approval">
          <Typography variant='body2' component='p' color='inherit'>
            {t('price_approval_message')}
          </Typography>
        </li>
        <li className='new' data-qa="qa-new-job-detail">
          <Typography variant='body2' component='p' color='inherit'>
            &nbsp;
            {isSA
              ? t('job_details_note_start_digital_freight')
              : t('job_details_note_start')}
            &nbsp;
            <strong>
              {item?.transporterPaymentTerms ?? 0} {t('days')},
            </strong>
            &nbsp;
            {t('job_details_note_end')}
          </Typography>
        </li>
      </ul>
      <div className={classes.btnBottom}>
        <Button
          data-qa="qa-suggest-price"
          color='primary'
          onClick={() => {
            onSuggestBtnClicked(item?.lroId || '', item?.weightToLoad || '');
            mixpanel.track('Suggest price clicks', {
              message: 'Suggest price clicked',
              ID: item?.lroId,
              orderValue: item?.marketRate,
              currency: item?.currency,
            });
          }}
        >
          {tBtn('suggest_price')}
        </Button>
        <Button
          data-qa="qa-accept"
          variant='contained'
          color='secondary'
          onClick={() => {
            onAcceptBtnClicked(item?.lroId || '', item?.weightToLoad || '');
            mixpanel.track('Accept now clicks', {
              message: 'Accept order clicked',
              ID: item?.lroId,
              orderValue: item?.marketRate,
              currency: item?.currency,
            });
          }}
        >
          {tBtn('accept')}
        </Button>
      </div>
    </div>
  );
}

export enum IDocumentTableNames {
	directorIdentification = 'directorIdentification',
	certificateOfIncorporation = 'certificateOfIncorporation',
	kraPin = 'kraPin',
	tin = 'tin',
	kraTcc = 'kraTcc',
	proofOfDirectorship = 'proofOfDirectorship',
	carriersLiability = 'carriersLiability',
	carrierResolution = 'carrierResolution',
	gitCoverConfirmation = 'gitCoverConfirmation',
	insurancePolicies = 'insurancePolicies',
	beeCertificate = 'beeCertificate',
	bankLetter = 'bankLetter',
	vatCertificate = 'vatCertificate',
	registrationCertificate = 'registrationCertificate',
	taxClearanceCertificate = 'taxClearanceCertificate',
	companyLetterhead = 'companyLetterhead',
	directorsIds = 'directorsIds',
	letterOfGoodStanding = 'letterOfGoodStanding',
	vehicleRegistrationDocuments = 'vehicleRegistrationDocuments',
	qualityManagementCertificate = 'qualityManagementCertificate',
}

export const IDocumentTableColumns = {
	documentName: 'documentName',
	fileName: 'fileName',
	viewLink: 'viewLink',
	// downloadLink: 'downloadLink',
};

import React, { useMemo } from 'react';
import IntlTelInput, { IntlTelInputProps } from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import cn from 'classnames';
import { COUNTRIES_ABBR, COUNTRIES_MAP_TO_REGION, PREFERRED_COUNTRIES } from 'utils';
import { useReadUserInfo } from '../../../hooks';
import { RegionType } from '../../../models';
import { state } from '../../../state';
import './phoneInput.styles.scss';

export interface PhoneInputProps extends IntlTelInputProps {
	className?: string;
	name: string;
}

export const PhoneInput = ({
	className,
	name,
	inputClassName,
	preferredCountries = PREFERRED_COUNTRIES,
	defaultCountry,
	...props
}: PhoneInputProps) => {
	const { region } = useReadUserInfo();

	const defCountry = useMemo(() => {
		if (region && region in RegionType) {
			return COUNTRIES_MAP_TO_REGION[region as RegionType];
		}

		if (state.isImperial) {
			return COUNTRIES_ABBR.SouthAfrica;
		}

		return undefined;
	}, [region]);

  return (
		<IntlTelInput
			containerClassName={cn('intl-tel-input', className)}
			inputClassName={cn('phone-input', inputClassName)}
			fieldId={name}
			fieldName={name}
			separateDialCode
			defaultCountry={defaultCountry || defCountry}
			preferredCountries={preferredCountries}
			{...props}
		/>
	);
};

import { PropsWithChildren, useEffect } from 'react';
import { state } from 'state';
import { Footer, Loader, ResultModal } from 'components';
import { useGetUserInfoQuery } from 'generate/types';
import { RoleTypes } from 'models';
import { ProcessingModal } from 'components';
import { useLoaderContext, useModalStateControls, useResultContext } from 'hooks';
import { PWAUserPrompt } from 'components/pwa-user-prompt/PwaUserPrompt';

export function Main({ children }: PropsWithChildren<{}>) {
	const { loading, data } = useGetUserInfoQuery();
	const { toggleModal, isOpened } = useModalStateControls();
	const { isResultOpened, closeResult, resultData } = useResultContext();
	const { isLoading } = useLoaderContext();

	useEffect(() => {
		if (data?.userInfo?.role) {
			const { role, first_login } = data.userInfo;

			if (role[0] === RoleTypes.guest && !!first_login) {
				toggleModal();
			}
		}
	}, [data, toggleModal]);

	return (
		<Loader isLoading={loading}>
			<div className="container-internal">
				{children}
				{state.isMobileBrowser && <PWAUserPrompt></PWAUserPrompt>}
				<Footer />
				{isOpened && <ProcessingModal onClose={toggleModal} />}
			</div>
			<ResultModal isOpened={isResultOpened} onClose={closeResult} {...resultData} />
			<Loader overflow isLoading={isLoading} />
		</Loader>
	);
}

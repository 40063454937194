import React, { useState, useEffect } from 'react';
import { useAuth, useReadUserInfo, useResultContext, useLoaderContext } from 'hooks';
import { getRecordList, getUserName, getIsVerified } from 'helpers';
import { AccountTab as AccountTabTemp } from 'components';
import {
	useUploadAvatarMutation,
	useUpdateAvatarMutation,
	useGetAvatarQuery,
} from 'generate/types';

export const ProfileInfo = () => {
	const [userAvatar, setUserAvatar] = useState<string>();
	const { openErrorResult } = useResultContext();
	const { setIsLoading } = useLoaderContext();

	const { transporter, first_name, last_name, verified, ...user } = useReadUserInfo();
	const { logOutQuery } = useAuth();

	const { refetch: refetchAvatar, loading } = useGetAvatarQuery({
		onCompleted: ({ getAvatar }) => {
			if (getAvatar?.avatar) {
				setUserAvatar(getAvatar.avatar);
			}
		},
		onError: openErrorResult,
	});

	const [uploadAvatar, { loading: uploadAvatarLoading }] = useUploadAvatarMutation();

	const [updateAvatar, { loading: updateAvatarLoading }] = useUpdateAvatarMutation({
		onCompleted: async ({ updateAvatar }) => {
			if (updateAvatar?.ok) {
				const {
					data: { getAvatar },
				} = await refetchAvatar();
				setUserAvatar('');
				if (getAvatar?.avatar) {
					setUserAvatar(getAvatar.avatar);
				}
			}
		},
	});

	const updateAvatarMutation = (avatar: File) => {
		if (userAvatar) {
			updateAvatar({
				variables: { avatar },
			});
		} else {
			uploadAvatar({
				variables: { avatar },
			});
		}
	};
	const infoList = getRecordList(user, ['email', 'company_mobile', 'region', ]);

	const isLoading = updateAvatarLoading || uploadAvatarLoading || loading;

	useEffect(() => {
		setIsLoading(isLoading);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	return (
		<AccountTabTemp
			userAvatar={userAvatar}
			onUpdateAvatar={updateAvatarMutation}
			userName={getUserName(transporter, first_name, last_name)}
			isVerified={getIsVerified(verified)}
			infoList={infoList}
			onSignOut={logOutQuery}
			isLoading={isLoading}
		/>
	);
};

import { useCallback, useRef } from 'react';
import { Form, Formik } from 'formik';
import { CustomInputField, CustomSelectField, ISelectOptionList } from 'containers';
import { BankDetailsFields, BankDetailsNames, FormIdList, FormStepTempProps } from 'models';
import { useTranslation } from 'react-i18next';
import { CustomCheckboxField } from 'containers';
import { Box } from '@material-ui/core';
import { CustomButton } from 'components/button';
import { TermsModal } from 'components/modals';
import { useStyles } from './SignUpForm.styles';
import { useReadUserInfo, useResultContext } from 'hooks';
import Typography from '@material-ui/core/Typography';
import { useGetBankDetailsQuery } from 'generate/types';
import { useMixpanel } from 'analytics';
import { authContext, COMMON } from 'utils';
import { getFromSessionStorage } from 'helpers';

interface BankDetailsFormProps extends FormStepTempProps<BankDetailsFields> {
	toggleModal: () => void;
	showTerms: boolean;
	isNewUser?: boolean;
	isSA?: boolean;
}

export const BankDetailsForm = ({
	onSubmit,
	validationSchema,
	initialValues,
	toggleModal,
	showTerms,
	isNewUser,
	isSA,
}: BankDetailsFormProps) => {
	const { t } = useTranslation('form-fields');
	const { t: tB } = useTranslation('buttons');
	const mixpanel = useMixpanel();
	const { openErrorResult } = useResultContext();

	const classes = useStyles();

	const { user_id, country_iso_code } = useReadUserInfo();

	const enum CountryCodeEnum {
		KE = "254",
		UG = "256",
		NG = "234",
		TZ = "255",
		ZA = "27",
	  };

	const renderCountryCode = (param: string) => {
		switch(param) {
			case "KE":
				return CountryCodeEnum.KE;
			case "UG":
				return CountryCodeEnum.UG;
			case "NG":
				return CountryCodeEnum.NG;
			case "TZ":
				return CountryCodeEnum.TZ;
			case "ZA":
				return CountryCodeEnum.ZA;
			default:
			  return "";
		  }
	};

	const sessionStorageCountyCode = getFromSessionStorage(COMMON.countryCode);

	const userInfoCountryCode = country_iso_code ?  renderCountryCode(country_iso_code) : "";

	const { data: bankData } = useGetBankDetailsQuery({
		context: authContext,
		variables: {
			countryCode: sessionStorageCountyCode ? sessionStorageCountyCode : userInfoCountryCode,
		},
		onError: openErrorResult,
		onCompleted: () => {
			mixpanel.track('Bank details fetched for country code', { userInfoCountryCode });
		},
	});

	const stringifiedBanksData = JSON.stringify(bankData?.banks?.edges);
	const banksArrayData = stringifiedBanksData ?  JSON.parse(stringifiedBanksData) : [];

	const bankNamesOptions  = banksArrayData.map((bankData: { node: { bankName: string; bankCode: string; }; }) => {
		const bankObject = {
			title: bankData.node?.bankName as string,
			value: bankData.node?.bankCode as string,
		}
		return bankObject
	}) as unknown as ISelectOptionList;

	const checkBoxRef = useRef<HTMLInputElement>(null);

	const onAccept = useCallback(() => {
		if (checkBoxRef.current && !checkBoxRef.current.checked) {
			checkBoxRef.current.click();
		}
		toggleModal();
	}, [checkBoxRef, toggleModal]);

	return (
		<>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				validationSchema={validationSchema}
				enableReinitialize
			>
				<Form id={FormIdList.bankDetails} className={classes.formWrapper}>
					{ sessionStorageCountyCode || userInfoCountryCode ? (
						<CustomSelectField name={BankDetailsNames.bankName} label={t('sign_up.bank_name')} options={bankNamesOptions}/>
					) : (
						<CustomInputField name={BankDetailsNames.bankName} label={t('sign_up.bank_name')} />

					)}
					<CustomInputField
						name={BankDetailsNames.accountNumber}
						label={t('sign_up.account_number')}
					/>
					<CustomInputField name={BankDetailsNames.accountName} label={t('sign_up.account_name')} />
					<CustomInputField name={BankDetailsNames.bankCode} label={t('sign_up.bank_code')} />
					<CustomInputField name={BankDetailsNames.branchName} label={t('sign_up.branch_name')} />
					<CustomInputField name={BankDetailsNames.swiftCode} label={t('sign_up.swift_code')} />
					<CustomInputField name={BankDetailsNames.accountType} label={t('sign_up.account_type')} />
					{isNewUser && !isSA && (
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							flexDirection="column"
							mt={3}
						>
							<CustomCheckboxField
								name={BankDetailsNames.terms}
								inputRef={checkBoxRef}
								label={
									<Typography>
										<span className={classes.termsText}>Accept</span>
										<CustomButton className={classes.termsBtn} variant="text" onClick={toggleModal}>
											{t('sign_up.terms')}
										</CustomButton>
									</Typography>
								}
							/>
						</Box>
					)}
					{user_id && (
						<Box display="flex" marginY={2} justifyContent="center">
							<CustomButton type="submit">{tB('submit')}</CustomButton>
						</Box>
					)}
					{!isSA && <TermsModal onClose={toggleModal} isOpened={showTerms} onAccept={onAccept} />}
				</Form>
			</Formik>
		</>
	);
};

import { TruckListTempWA } from 'components';
import { TruckBulkUpload } from 'containers';
import { useModalStateControls } from 'hooks';

export const TruckListWA = () => {
	const { isOpened, toggleModal } = useModalStateControls();

	return (
		<>
			<TruckListTempWA onAddAsset={toggleModal} />
			<TruckBulkUpload isOpened={isOpened} toggleModal={toggleModal} />
		</>
	);
};

import { makeStyles } from '@material-ui/core/styles';
import { theme } from 'utils';

export const useStyles = makeStyles({
	root: {
    flexGrow: 1,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  select: {
    margin: 0,
    width: '100%',
  },
  modal: {
    overflowY: 'auto',
    maxWidth: 'calc(70vh - 150px)',
    margin: 'auto',
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    outline: 0,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  description: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    justifyContent: 'left',
    marginBottom: theme.spacing(4),
  },
  buttonGroup: {
    paddingLeft: '0 !important',
  },
  label: {
    color: theme.palette.primary.light,
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 15,
    '@media (max-width:600px)': {
      marginTop: 0,
    },
  },
  gridLabel: {
    padding: 'inherit',
  },
  gridField: {
    padding: '0 !important',
    paddingBottom: '20px !important',
  },
});

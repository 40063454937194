import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { useStyles } from './AssetListItem.styles';
import { Trailer } from 'generate/typesSupplyService';
import { CustomButton } from 'components/button';
import { useTranslation } from 'react-i18next';
import { RedButton } from 'components/button';

interface AssetListItemProps extends Trailer {
  onActivateAsset: (value: Trailer) => void;
}

export const TrailerListItem = ({
  active,
  id,
  transporterId,
  trailerReg,
  cargoCapacity,
  trailerType,
  onActivateAsset,
}: AssetListItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation('buttons');

  return (
    <Box className={classes.wrapper}>
      <div className='capitalize'>
        <Typography>{trailerReg}</Typography>
      </div>
      <div className='capitalize'>
        <Typography>{trailerType}</Typography>
      </div>
      <div className='capitalize'>
        <Typography>{cargoCapacity}</Typography>
      </div>
      <div className='capitalize'>
        <Typography>{transporterId}</Typography>
      </div>
      <Box maxWidth='100px'>
        {active ? (
          <RedButton
            style={{ width: 100 }}
            onClick={() =>
              onActivateAsset({
                active,
                id,
              } as Trailer)
            }
          >
						{t('suspend')}
          </RedButton>
        ) : (
          <CustomButton
            color='primary'
            onClick={() =>
              onActivateAsset({
                active,
                id,
              } as Trailer)
            }
          >
						{t('activate')}
          </CustomButton>
        )}
      </Box>
    </Box>
  );
};

export enum ErrorTypes {
	// LTA errors
	INVALID_GRANT = 'INVALID_GRANT',
	INVALID_TOKEN = 'INVALID_TOKEN',
	MISSING_TOKEN = 'MISSING_TOKEN',
	UNAUTHORIZED_ACCESS = 'UNAUTHORIZED_ACCESS',
	UNAUTHORIZED = 'UNAUTHORIZED',

	// LIA & LFA errors
	COGNITO_MISSING_TOKEN = 'Authorization Required',
	COGNITO_EXPIRED_TOKEN = 'Invalid Cognito Authentication Token',
}

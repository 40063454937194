import React, { useRef } from 'react';
import { Field, FieldProps } from 'formik';
import { CustomInputProps } from 'components';
import { Box, Button, CircularProgress } from '@material-ui/core';
import selectFileSrc from 'assets/svg/select_file.svg';
import { useStyles } from './styles';

interface DropFileUploadFieldProps {
	setColumnNames: (name: []) => void;
	setDocument: (file: Blob) => void;
}

interface CustomInputFieldContainerProps extends CustomInputProps, DropFileUploadFieldProps {
	accept?: string;
	multiple?: boolean;
	isUploading?: boolean;
}

interface CustomInputFieldProps<T> extends CustomInputFieldContainerProps {
	onUpload: (name: T, file: File | FileList) => void;
}

export function DropFileUploadField<T extends string>({
	onUpload,
	name = '',
	accept,
	multiple,
	isUploading,
	setColumnNames,
	setDocument,
}: CustomInputFieldProps<T>) {
	const labelRef = useRef<HTMLInputElement>(null);
	const classes = useStyles();

	function handleClick() {
		if (labelRef.current) {
			labelRef.current.click();
		}
	}

	return (
		<Field name={name}>
			{({ field: { value }, meta, form }: FieldProps) => {
				const handleOnInput = (e: React.ChangeEvent<HTMLInputElement>) => {
					if (!e.target.files?.length) return;
					if (multiple) return; // we will ignore multiple files for now
					const file = e.target.files[0];
					if (file) {
						setColumnNames([]);
						setDocument(file);
						form.setFieldValue(name, file.name);
					}
				};

				return (
					<>
						<input
							id={name}
							type="file"
							ref={labelRef}
							accept={accept}
							hidden
							onChange={handleOnInput}
							multiple={multiple}
						/>
						<Box display="flex" justifyContent="center">
							{!isUploading &&
								<Button
									color="primary"
									onClick={handleClick}
									className={classes.heightAuto}
									disableRipple
								>
									<img src={selectFileSrc} alt="select-file" id="select-file"/>
								</Button>
							}
							{isUploading && <CircularProgress size="1rem" />}
						</Box>
					</>
				);
			}}
		</Field>
	);
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { CustomButton } from '../button';
import { AddAssetProps } from 'models';

interface DriverListTempProps extends AddAssetProps {}

export const DriverListTempWA = ({ onAddAsset }: DriverListTempProps) => {
	const { t } = useTranslation('buttons');

	return (
		<>
			<Box mt="auto" />
			<Box my={2} textAlign="center" style={{ width: '100%' }}>
				<CustomButton onClick={onAddAsset} variant="outlined">
					{t('add_driver')}
				</CustomButton>
			</Box>
		</>
	);
};

import { gql } from '@apollo/client';

export const getSupplyTruckTypes = gql`
	query getSupplyTruckTypes {
		truckTypes {
			edges {
				node {
					id
					name
				}
			}
		}
	}
`;

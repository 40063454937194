import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useReadUserInfo, useResultContext, useGetAssets } from 'hooks';
import { liaSupplyClient } from 'graphqlapi';
import { FormIdList } from 'models';
import { Box } from '@material-ui/core';
import { CustomButton, Loader } from 'components';
import { AssetAvailabilityInput, useCreateTruckSupplyLiaSupplyMutation } from 'generate/typesLIASupply';
import { getLIASupplySourceType } from 'helpers';
import { TruckSubmitList } from 'containers';
import { useMixpanel } from 'analytics';
import { addHours } from 'date-fns';

export const AvailableTrucks = () => {
	const { t: tB } = useTranslation('buttons');
	const { t } = useTranslation('modals');
	const mixpanel = useMixpanel();

	const { openErrorResult, toggleResult } = useResultContext();
	const { region } = useReadUserInfo();

	const userInfo = useReadUserInfo();
	useEffect(() => {
		mixpanel.track('User arriving to the  “Available Trucks” page', {
			message: 'Page renders successfully ',
		});
	}, [mixpanel]);

	const [submitTruckList, { loading }] = useCreateTruckSupplyLiaSupplyMutation({
		client: liaSupplyClient,
		onCompleted: ({ createTruckSupply }) => {
			mixpanel.track('Submit unassigned trucks event', {
				message: 'Submit unassigned trucks successfully',
			});
			toggleResult({
				title: t('success'),
				text: createTruckSupply?.message || tB('unassigned_trucks_successful'),
			})
		},
		onError: (error) => {
			mixpanel.track('Submit unassigned trucks failure event', {
				message: error?.message || 'No error message',
			});
			openErrorResult();
		},
	});

	const handleSubmitList = useCallback(
		(truckListData: AssetAvailabilityInput[]) => {
			const truckList: AssetAvailabilityInput[] = truckListData.map(
        // @ts-ignore
				({ truckReg, driverData, trailerReg, etaLoadingPoint }) => {
          const driver = JSON.parse(driverData);
          return ({
            truckReg,
            trailerReg,
            driverIdNumber: driver?.idNumber || undefined,
            etaLoadingPoint,
          })
        }
			);

      mixpanel.track('Unassigned Trucks event', {
        numTrucks: truckList.length,
        etaLoadingPoint: addHours(new Date(), 1).toISOString(),
      });

	submitTruckList({
        variables: {
					assetList: truckList,
          // @ts-ignore
          createdBy: userInfo?.transporter || undefined,
					source: getLIASupplySourceType(region),
          // @ts-ignore
					transporterId: userInfo?.transporter_id || undefined,
				},
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[region, submitTruckList, userInfo]
	);

	return (
		<Loader isLoading={loading}>
      {/* @ts-ignore */}
			<TruckSubmitList onSubmit={handleSubmitList} getAssetsHook={useGetAssets} />
			<Box display="flex" alignItems="center" mt={5} mb={2} flexDirection="column">
				<CustomButton type="submit" form={FormIdList.truckList} data-qa="qa-submit-trucks">
					{tB('submit_trucks')}
				</CustomButton>
			</Box>
		</Loader>
	);
};



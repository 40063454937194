import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { CustomMoveInput, CustomInputProps } from '../custom-input';
import { SearchIcon } from 'components/icons';
import { useStyles } from './CustomSearch.styles';
import { useDebounce } from 'hooks';
import { useMixpanel } from 'analytics';
import { useLocation } from 'react-router-dom';

interface CustomSearchProps extends CustomInputProps {
	onChangeValue: (s: string) => void;
}

export const CustomSearch = ({ name, onChangeValue, ...props }: CustomSearchProps) => {
	const classes = useStyles();

	const [searchValue, setSearchValue] = useState<string>('');
	const debouncedValue = useDebounce(searchValue, 500);
	const { pathname } = useLocation();
	const mixpanel = useMixpanel();
	const jobType = pathname?.split('/')?.reverse()?.join(' ')?.trim() || 'Event';
	const handleChange = useCallback(
		({ target: { value } }: ChangeEvent<HTMLInputElement>) => setSearchValue(value),
		[]
	);

	useEffect(() => {
		if (onChangeValue && typeof debouncedValue === 'string') {
			const searchString = debouncedValue?.trim();
			onChangeValue(searchString);
			if (searchString.length > 2) {
				mixpanel.track(`Search ${jobType}`, { searchString, pathname, jobType });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedValue]);

	return (
		<CustomMoveInput
			InputProps={{
				startAdornment: <SearchIcon className={classes.icon} />,
				className: classes.input,
			}}
			value={searchValue}
			onChange={handleChange}
			name={name || 'search'}
			{...props}
		/>
	);
};

import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';
import { CustomUploadFieldLta } from 'containers';
import { FormIdList, UploadDocumentationFormProps, UploadDocumentationNames } from 'models';
import { formatCamelToSnakeCase, mapFieldsToInitialValues } from 'helpers';
import { useTranslation } from 'react-i18next';
import { SIGN_UP_EXTENSION_LIST } from 'utils';

const fields = [
	UploadDocumentationNames.directorIdentification,
	UploadDocumentationNames.certificateOfIncorporation,
	UploadDocumentationNames.tin,
	UploadDocumentationNames.proofOfDirectorship,
];

export const UploadDocumentationFormWest = ({ data, onSubmit }: UploadDocumentationFormProps) => {
	const { t } = useTranslation('form-fields');

	const initialValues = useMemo(() => mapFieldsToInitialValues(fields, data || {}), [data]);

	return (
		<Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
			<Form id={FormIdList.uploadDocumentation}>
				{fields.map((name) => (
					<CustomUploadFieldLta
						key={name}
						name={name}
						label={t(`upload_documents.${formatCamelToSnakeCase(name)}`)}
						accept={SIGN_UP_EXTENSION_LIST}
					/>
				))}
			</Form>
		</Formik>
	);
};

import queryString from 'query-string';
import { useMemo } from "react";
import { useLocation, useParams } from 'react-router-dom';

export function useQueryParams() {
  const params = useParams();
  const location = useLocation();

  return useMemo(() => ({
    ...queryString.parse(location.search),
    ...params
  }), [params, location]);
};

export default useQueryParams;
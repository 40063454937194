import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import { COLORS } from 'utils';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			padding: 16,
			borderRadius: 4,
			border: '1px dashed #CCC',
			background: COLORS.grey1,
			width: '100%'
		},
		typography: {
			padding: theme.spacing(2),
		},
		input: {
			'& .MuiInputBase-input': {
				cursor: 'pointer',
			},
			'& .MuiFormLabel-root.Mui-disabled': {
				color: COLORS.label,
			},
		},
		icon: {
			cursor: 'pointer',
			display: 'flex',
			margin: 1
		},
		button: {
			cursor: 'pointer',
			display: 'flex',
			flexDirection: 'column',
			height: "auto",
			padding: 0,
			marginTop: 3
		},
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: COLORS.white,
		},
		title: {
			textAlign: 'left',
			fontSize: '15px',
			color: COLORS.primary,
			textTransform: 'none',
			fontWeight: 800,
			width: '100%'
		},
		uploadedFile: {
			textAlign: 'left',
			fontSize: '15px',
			color: COLORS.primary,
			textTransform: 'none',
			fontWeight: 700,
			marginLeft: 4
		},
		label: {
			textAlign: 'left',
			fontSize: '14px',
			color: COLORS.textLabel,
			textTransform: 'none',
			fontWeight: 800,
			marginTop: 2
		},
		fileType: {
			textAlign: 'left',
			fontSize: '1rem',
			color: COLORS.textLabel,
			fontWeight: 500,
		},
		uploadProgress: {
			textAlign: 'left',
			fontSize: '14px',
			color: COLORS.textLabel,
			fontWeight: 800,
			textTransform: 'capitalize',
			marginTop: 12
		},
	})
);

import React, { useMemo } from 'react';
import { Account, CreateAccountMutationVariables } from 'generate/typesAuth';
import { mapFieldsToInitialValues, getRequiredMessage } from 'helpers';
import { BankDetailsFields, BankDetailsNames } from 'models';
import * as Yup from 'yup';
import { BankDetailsForm as BankDetailsFormTemp } from 'components';
import { RegionType } from 'models';
import { useTranslation } from 'react-i18next';
import { useModalStateControls, useReadUserInfo } from 'hooks';
import { state } from 'state';

interface BankDetailsFormProps {
	onSubmit: (d: Partial<CreateAccountMutationVariables>) => void;
	data?: Partial<Account> | null;
	isNewUser?: boolean;
}

export const BankDetailsForm = ({ onSubmit, data = {}, isNewUser }: BankDetailsFormProps) => {
	const { t } = useTranslation('form-fields');
	const { t: tE } = useTranslation('errors');

	// Data for registered user on Account management page
	const { region: userRegion } = useReadUserInfo();
	// Data for new user on Sign Up flow
	const { region: companyRegion } = state;

	const region = useMemo(() => userRegion || companyRegion, [userRegion, companyRegion]);

	const isSA = useMemo(() => region === RegionType.SA, [region]);

	const fields = useMemo(() => {
		const commonFields = [
			BankDetailsNames.bankName,
			BankDetailsNames.accountName,
			BankDetailsNames.accountNumber,
			BankDetailsNames.accountType,
			BankDetailsNames.bankCode,
			BankDetailsNames.branchName,
			BankDetailsNames.swiftCode,
			BankDetailsNames.bankAddress,
			BankDetailsNames.beneficiaryName,
			BankDetailsNames.beneficiaryAddress,
		];

		return isSA ? commonFields : [...commonFields, BankDetailsNames.terms];
	}, [isSA]);

	const { isOpened, toggleModal } = useModalStateControls();

	const initialValues = useMemo(
		() => mapFieldsToInitialValues(fields, { terms: !isNewUser, ...data } as BankDetailsFields),
		[fields, data, isNewUser]
	);

	const validationSchema = useMemo(
		() =>
			isSA
				? null
				: Yup.object({
						[BankDetailsNames.terms]: Yup.boolean().oneOf(
							[true],
							getRequiredMessage(tE, t('sign_up.terms'))
						),
				  }),
		[t, tE, isSA]
	);

	return (
		<BankDetailsFormTemp
			onSubmit={onSubmit}
			validationSchema={validationSchema}
			initialValues={initialValues}
			toggleModal={toggleModal}
			showTerms={isOpened}
			isNewUser={isNewUser}
			isSA={isSA}
		/>
	);
};

import React from 'react';
import cn from 'classnames';
import { TextField, OutlinedTextFieldProps } from '@material-ui/core';
import { QaType } from 'models';
import { makeStyles } from '@material-ui/core/styles';

export interface CustomMoveInputProps extends Partial<OutlinedTextFieldProps> {
	qaType?: QaType;
	variant?: OutlinedTextFieldProps['variant'];
}

const useStyles = makeStyles({
	selectInput: {
		'& input': {
			boxSizing: 'border-box',
		},
	},
});

export const CustomMoveInput = React.forwardRef<HTMLDivElement, CustomMoveInputProps>(
	(
		{
			id,
			name,
			margin = 'normal',
			qaType = 'text',
			type = 'text',
			fullWidth = true,
			className,
			autoComplete = 'off',
			select,
			label,
			...props
		},
		ref
	) => {
		const classes = useStyles();

		return (
			<TextField
				inputRef={ref}
				name={name}
				id={id || name}
				className={cn(className, {
					[classes.selectInput]: select,
				})}
				select={select}
				margin={margin}
				type={type}
				fullWidth={fullWidth}
				title={(typeof label === 'string' && label) || ''}
				label={label}
				autoComplete={autoComplete}
				inputProps={{
					'qa-id': `input-${name}`,
					'qa-type': qaType,
					min: 1,
				}}
				{...props}
			/>
		);
	}
);

import { gql } from '@apollo/client';

export const addTrailer = gql`
	mutation addTrailer($trailerReg: String!, $trailerType: String!, $trailerCapacity: Float!) {
		addTrailer(
			input: {
				trailerReg: $trailerReg
				trailerType: $trailerType
				trailerCapacity: $trailerCapacity
			}
		) {
			ok
			message
		}
	}
`;

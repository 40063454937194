import { makeStyles, Theme } from '@material-ui/core';
// @ts-ignore
export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
	},
	padding: {
		padding: theme.spacing(3),
	},
	demo1: {
		backgroundColor: theme.palette.background.paper,
	},
	antdTab: {
		textTransform: 'none',
		minWidth: 72,
		fontSize: '18px',
    // @ts-ignore
		fontWeight: theme.typography.fontWeightMedium,
		marginRight: theme.spacing(4),
		'&:hover': {
			color: '#40a9ff',
			opacity: 1,
		},
		'&$selected': {
			color: '#096dd9',
    // @ts-ignore
			fontWeight: theme.typography.h2,
		},
		'&:focus': {
			color: '#40a9ff',
		},
	},
	selected: {},
	tabsRoot: {
		borderBottom: '1px solid #e8e8e8',
	},
	tabChildren: {
		padding: 20,
		display: 'flex',
		flexDirection: 'column',
	},
	tabIndicator: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'transparent',
		'& .MuiTabs-indicator': {
			width: '100%',
			backgroundColor: '#096dd9',
		},
	},
}));

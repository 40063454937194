export enum PersonalInformationNames {
	vetting = 'vettingConsent',

	firstName = 'firstName',
	lastName = 'lastName',
	ID = 'idNumber',
}

export interface PersonalInformationFields {
	[PersonalInformationNames.firstName]: string;
	[PersonalInformationNames.lastName]: string;
	[PersonalInformationNames.ID]: string;
}

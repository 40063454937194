import { gql } from '@apollo/client';

export const moveCardDetails = gql`
	fragment moveCardDetails on Move {
		cargoType
		commodity
		consignee
		deadlineToLift
		destinationCity
		destinationCountry
		id
		etaLoadingPoint
		lroId
		marketRate
		moveId
		origin
		originCity
		originCountry
		originGeocode
		readyToLoad
		transportType
		transporterRateType
		transporterPaymentTerms
		trailerType
		truckType
		truckReg
		weight
		trucksNeeded
		currency
		destination
		lastKnownLocation
	}
`;

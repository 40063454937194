import { gql } from '@apollo/client';

export const bulkUploadTrucks = gql`
	mutation bulkUploadTrucks($file: Upload!, $transporterId: Int!, $country: String!, $source: Source!) {
		bulkUploadTrucks(file: $file, transporterId: $transporterId, country: $country, source: $source) {
			message
			status
		}
	}
`;

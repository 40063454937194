import React, { createContext, PropsWithChildren } from 'react';
import { useLoaderControls } from 'hooks';

interface ILoaderContext {
	isLoading?: boolean;
	setIsLoading: (isLoading: boolean) => void;
}

export const LoaderContext = createContext<ILoaderContext>({
	setIsLoading: () => {},
});

export const LoaderContextProvider = ({ children }: PropsWithChildren<{}>) => {
	const { setIsLoading, isLoading } = useLoaderControls();

	return (
		<LoaderContext.Provider
			value={{
				isLoading,
				setIsLoading,
			}}
		>
			{children}
		</LoaderContext.Provider>
	);
};

import { useMemo } from 'react';
import { AddTrailer as AddTrailerTemp, Loader } from 'components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AddTrailerFomNames } from 'models';
import {
	formatCamelToSnakeCase,
	getSupplyOptionListFromPrimitives,
	getRequiredMessage,
} from 'helpers';
import { useTranslation } from 'react-i18next';
import { trackSubmitMixPanelEvent } from 'utils/trackSubmitMixPanelEvent';
import {
	CreateTrailerInput,
	TrailerTypes,
	useGetTrailerTypesQuery,
} from 'generate/typesSupplyService';
import { supplyServiceClient } from 'graphqlapi/clients/supplyServiceClient';
import { getPaginatedDataNodeList } from 'helpers';

interface AddTrailerProps {
	onSubmit: (data: CreateTrailerInput) => void;
	isSubmitting?: boolean;
}

export const AddTrailer = ({ onSubmit, isSubmitting }: AddTrailerProps) => {
	const { t } = useTranslation('form-fields');
	const { t: tE } = useTranslation('errors');

	const { data, loading } = useGetTrailerTypesQuery({
		client: supplyServiceClient,
	});

	const validationSchema = useMemo(
		() =>
			Yup.object({
				...Object.values(AddTrailerFomNames).reduce(
					(res, name) => ({
						...res,
						[name]: Yup.string().required(
							getRequiredMessage(tE, t(`add_asset.${formatCamelToSnakeCase(name)}`))
						),
					}),
					{}
				),
				[AddTrailerFomNames.trailerCapacity]: Yup.number().required(
					getRequiredMessage(
						tE,
						t(`add_asset.${formatCamelToSnakeCase(AddTrailerFomNames.trailerCapacity)}`)
					)
				),
			}),
		[tE, t]
	);

	const typeList = useMemo(() => {
		if (data?.trailerTypes) {
			const trailerTypes = getPaginatedDataNodeList<TrailerTypes, any>(data?.trailerTypes);
			// @ts-ignore
			return getSupplyOptionListFromPrimitives(trailerTypes);
		}
		return [];
	}, [data]);

	return (
		<Loader isLoading={loading || isSubmitting} overflow={isSubmitting}>
			<Formik
				initialValues={{}}
				onSubmit={(v) => {
					trackSubmitMixPanelEvent('Submit trailers event');
					onSubmit(v as CreateTrailerInput);
				}}
				validationSchema={validationSchema}
			>
				<AddTrailerTemp typeList={typeList} />
			</Formik>
		</Loader>
	);
};

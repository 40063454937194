import { useMemo } from 'react';
import { useGetAvailableAssets } from 'hooks';
import { getOptionListValues, getActiveOptionList } from 'helpers';
import { AssetList, WorkOrderDetailsFragment } from 'generate/types';
import { TruckConfirmListForm } from '../truck-confirm-list-form';

export const TruckConfirmList = ({
	onSubmit,
	isLoading: isDataLoading,
	withSearchFilter,
	isSubmitting,
	truckList,
}: {
	onSubmit: (truckList: AssetList[] | WorkOrderDetailsFragment['truckList']) => void;
	isLoading?: boolean;
	isSubmitting?: boolean;
	withSearchFilter?: boolean;
	truckList: AssetList[] | WorkOrderDetailsFragment['truckList'];
}) => {

  const { driverList, trailerList, isAssetsLoading } = useGetAvailableAssets();

	const isLoading = useMemo(
		() => isAssetsLoading || isDataLoading,
		[isAssetsLoading, isDataLoading]
	);

	const driverOptionList = useMemo(() => {
		return driverList && getOptionListValues(driverList, ['names', 'idNumber'], ['idNumber']);
	}, [driverList]);

	const trailerOptionList = useMemo(
		() =>
			trailerList && getActiveOptionList(trailerList, ['trailerReg', 'trailerType'], 'trailerReg'),
		[trailerList]
	);
	return (
		<TruckConfirmListForm
      // @ts-ignore
			truckList={truckList}
			trailerList={trailerOptionList}
			driverList={driverOptionList}
			onSubmit={onSubmit}
			isLoading={isLoading}
			isSubmitting={isSubmitting}
			withSearchFilter={withSearchFilter}
		/>
	);
};

import React, { useMemo } from 'react';
import { Form } from 'formik';
import { CustomInputField, CustomSelectField } from 'containers';
import { AddTrailerFomNames } from 'models';
import { formatCamelToSnakeCase } from 'helpers';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { CustomButton } from '../../button';
import {ButtonLink} from "../../custom-link";
import {AppPathList} from "../../../router";
import Typography from "@material-ui/core/Typography";

interface AddTrailerProps {
	typeList: any;
}

export const AddTrailer = ({ typeList }: AddTrailerProps) => {
	const { t } = useTranslation('form-fields');
	const { t: tB } = useTranslation('buttons');

	const fields = useMemo(
		() =>
			Object.values(AddTrailerFomNames).reduce(
				(res, name) => ({
					...res,
					[name]: {
						name,
						label: formatCamelToSnakeCase(name),
					},
				}),
				{} as Record<AddTrailerFomNames, { name: string; label: string }>
			),
		[]
	);

	return (
		<Form className="container-internal">
			<Box mt={2} mb={1}>
				<Typography className="subtitle--large textBlack">
					{t('add_asset.add_trailer')}
				</Typography>
			</Box>
			<CustomInputField
				required
				name={fields.trailerReg.name}
				label={t(`add_asset.${fields.trailerReg.label}`)}
			/>
			<CustomSelectField
				required
				name={fields.trailerType.name}
				label={t(`add_asset.${fields.trailerType.label}`)}
				options={typeList}
			/>
			<CustomInputField
				required
				name={fields.trailerCapacity.name}
				label={t(`add_asset.${fields.trailerCapacity.label}`)}
				type="number"
			/>
			<Box mt="auto">
				<CustomButton className="capitalizeText" type="submit">{tB('submit')}</CustomButton>
			</Box>
			<Box mt={2} paddingBottom={4}>
				<ButtonLink
					to={AppPathList.assetManagement}
					variant="outlined" color="primary"
					autoWidth={true} className="capitalizeText link flexGrow">
					{tB('cancel')}
				</ButtonLink>
			</Box>
		</Form>
	);
};

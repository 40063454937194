import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
	AvailableJobDetailsSubmitted as AvailableJobDetailsSubmittedTemp,
	Loader,
} from 'components';
import {
  JobCategory,
  useGetWorkOrderQuery,
} from 'generate/types';
export function AvailableJobDetailsSubmitted() {
  const { id } = useParams<{ id: string }>();

  const { data: workOrder, loading: isWorkOrderLoading } = useGetWorkOrderQuery(
    {
      fetchPolicy: 'no-cache',
      variables: {
        jobCategory: JobCategory.Submitted,
        lroId: id,
      },
    }
  );

	const [detailsListData, rest] = useMemo(() => {
		if (workOrder?.workOrder) {
			const { truckType, trucksShortlisted, weight, commodity, ...rest } = workOrder?.workOrder;

			return [
				{
					trailerType: truckType,
					trucksSubmitted: trucksShortlisted,
					weight,
					commodity,
				},
				{
					...rest,
					trucksShortlisted,
				},
			];
		}

		return [];
	}, [workOrder?.workOrder]);

	return (
		<Loader isLoading={isWorkOrderLoading}>
			{/* @ts-ignore */}
			<AvailableJobDetailsSubmittedTemp detailsList={detailsListData} {...rest} />
		</Loader>
	);
}

import { useReadUserInfo } from '../useReadUserInfo';
import { useCallback, useMemo } from 'react';
import { mapSubmittedTruckListToInitialValues, mapTruckListToInitialValues } from 'helpers';
import * as Yup from 'yup';
import { RegionType, SubmitTruckFormData } from 'models';
import { ConfirmAssetList } from 'generate/types';
import { Truck } from 'generate/typesSupplyService';

export function useTruckListForm({
	truckList,
	submittedTruckList,
	onSubmit,
}: {
	truckList?: Truck[];
	submittedTruckList?: [ConfirmAssetList];
	onSubmit: (truckList: [ConfirmAssetList]) => void;
}) {
	const { region } = useReadUserInfo();

	const trailerRegValidation = useMemo(
		() =>
			region !== RegionType.WA
				? {
						trailerReg: Yup.string().when('checked', {
							is: true,
							then: Yup.string().required('Required'),
						}),
				  }
				: {},
		[region]
	);

  const initialValues = useMemo(
		() =>
			submittedTruckList && submittedTruckList?.length
				? //@ts-ignore
				  mapSubmittedTruckListToInitialValues(submittedTruckList)
				: mapTruckListToInitialValues(truckList),
		[submittedTruckList, truckList]
	);

  const validationSchema = useMemo(
		() =>
			Yup.object(
				(truckList || []).reduce(
					(res, truck) => ({
						...res,
						[truck?.truckReg as string]: Yup.object().shape({
							checked: Yup.boolean(),
							...trailerRegValidation,
							driverData: Yup.string().when('checked' && 'isNotSA', {
								is: region !== RegionType.SA,
								then: Yup.string().required('Required'),
							}),
							etaLoadingPoint: Yup.date().when('checked', {
								is: true,
								then: Yup.date().required('Required'),
							}),
						}),
					}),
					{} as Yup.ObjectSchemaDefinition<{}>
				)
			),
		[truckList, trailerRegValidation, region]
	);

	const handleSubmit = useCallback(
		(data: SubmitTruckFormData) => {
      // @ts-ignore
			onSubmit(Object.values(data).filter(({ checked }) => checked));
		},
		[onSubmit]
	);

	const validateMinTrucks = useCallback((truckData: SubmitTruckFormData) => {
		const isValid = Object.values(truckData || {}).some(({ checked }) => checked);

		if (!isValid) {
			return {
				truckListData: 'Must have trucks',
			};
		}
	}, []);

	return {
		initialValues,
		validationSchema,
		handleSubmit,
		validateMinTrucks,
	};
}

import { gql } from '@apollo/client';

export const useGetTrailerTypes = gql`
	query getTrailerTypes {
		trailerTypes {
			edges {
				node {
					name
					id
					trailerType {
						cargoCapacity
					}
				}
			}
		}
	}
`;

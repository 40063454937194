export enum BankDetailsNames {
	bankName = 'bankName',
	accountName = 'accountName',
	accountNumber = 'accountNumber',
	accountType = 'accountType',
	bankCode = 'bankCode',
	branchName = 'branchName',
	swiftCode = 'swiftCode',
	bankAddress = 'bankAddress',
	beneficiaryName = 'beneficiaryName',
	beneficiaryAddress = 'beneficiaryAddress',
	terms = 'terms',
}

export interface BankDetailsFields extends Partial<Record<BankDetailsNames, string | boolean>> {
	[BankDetailsNames.bankName]: string;
	[BankDetailsNames.accountName]: string;
	[BankDetailsNames.accountNumber]: string;
	[BankDetailsNames.accountType]: string;
	[BankDetailsNames.bankCode]: string;
	[BankDetailsNames.branchName]: string;
	[BankDetailsNames.swiftCode]: string;
	[BankDetailsNames.bankAddress]: string;
	[BankDetailsNames.beneficiaryName]: string;
	[BankDetailsNames.beneficiaryAddress]: string;
	[BankDetailsNames.beneficiaryAddress]: string;
	[BankDetailsNames.terms]: boolean;
}

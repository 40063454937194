import { gql } from '@apollo/client';
import { userDetails } from '../fragments';

export const getUsers = gql`
	query getUsers(
		$role: RoleEnum!
		$searchStr: String
		$before: String
		$after: String
		$first: Int
		$last: Int
	) {
		getUsers(
			role: $role
			searchStr: $searchStr
			before: $before
			after: $after
			first: $first
			last: $last
		) {
			pageInfo {
				hasNextPage
				endCursor
			}
			edges {
				node {
					...userDetails
				}
			}
		}
	}
	${userDetails}
`;

export enum AppRouteNameList {
	root = 'root',

	available = 'available',
	availableNew = 'availableNew',
	availableSubmitted = 'availableSubmitted',
	availableMatched = 'availableMatched',

	availableDetails = 'availableDetails',
	availableSubmittedDetails = 'availableSubmittedDetails',
	availableMatchedDetails = 'availableMatchedDetails',

	addLocation = 'addLocation',
	previousLocations = 'previousLocations',

	submitTruck = 'submitTruck',
	suggestPrice = 'suggestPrice',

	jobs = 'jobs',

	jobsConfirmed = 'jobsConfirmed',
	jobsActive = 'jobsActive',
	jobsCompleted = 'jobsCompleted',

	jobsConfirmedDetails = 'jobsConfirmedDetails',
	jobsActiveDetails = 'jobsActiveDetails',
	jobsCompletedDetails = 'jobsCompletedDetails',

	auxiliaryServices = 'auxiliaryServices',
	requestFuel = 'requestFuel',

	notifications = 'notifications',

	payment = 'payment',

	account = 'account',
	bankDetails = 'bankDetails',
	updateBankDetails = 'updateBankDetails',
	assetManagement = 'assetManagement',
	documents = 'documents',
	availableTrucks = 'availableTrucks',
	addTruck = 'addTruck',
	addDriver = 'addDriver',
	addTrailer = 'addTrailer',

	login = 'login',
	signUp = 'signUp',
	forgetPassword = 'forgetPassword',
	resetPassword = 'resetPassword',

	// Admin dashboard
	admin = 'admin',
	oAuthlogin = 'oAuthLogin',

	transporters = 'transporters',
	newTransporters = 'newTransporters',
	activeTransporters = 'activeTransporters',
	externalTransporters = 'externalTransporters',
	transporterDetails = 'transporterDetails',

	assets = 'assets',
	trucks = 'trucks',
	trailers = 'trailers',
	drivers = 'drivers',
}

import { gql } from '@apollo/client';

export const accountDetails = gql`
	fragment accountDetails on Account {
		bankName
		accountName
		accountNumber
		accountType
		bankCode
		branchName
		swiftCode
		bankAddress
		beneficiaryName
		beneficiaryAddress
		currency
	}
`;

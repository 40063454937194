import { makeStyles } from '@material-ui/core';
import { COLORS } from 'utils';

export const useStyles = makeStyles({
	btn: {
		width: 25,
		height: 25,
		backgroundColor: '#FFFFFF',
		'&:hover': {
			backgroundColor: '#FFFFFF',
		},
	},
	icon: {
		width: '1rem',
		height: '1rem',
		fill: COLORS.primary,
	},
});

import React, { useMemo } from 'react';
import { ModalWrapper } from '../ModalWrapper';
import { useStyles } from './ResultModal.styles';
import SuccessIcon from 'assets/svg/success.svg';
import ErrorIcon from 'assets/icons/cancel.png';
import Typography from '@material-ui/core/Typography';

import cn from 'classnames';
import ReactDOM from 'react-dom';
import {CustomButton} from "../../button";

export interface ResultModalDataProps {
	title?: string;
	text?: string;
	type?: 'success' | 'error';
}

interface ResultModalProps extends ResultModalDataProps {
	onClose: () => void;
	isOpened?: boolean;
}

export const ResultModal = ({
	onClose,
	isOpened = false,
	text,
	type = 'success',
}: ResultModalProps) => {
	const classes = useStyles();

	const isSuccess = useMemo(() => type === 'success', [type]);

	return ReactDOM.createPortal(
		<ModalWrapper
			open={isOpened}
			onClose={onClose}
			withCloseBtn={false}
			contentClassName={classes.content}
		>
			<>
				<img
					src={isSuccess ? SuccessIcon : ErrorIcon}
					alt={isSuccess ? 'Success' : 'Error'}
					className={classes.img}
				/>
				<Typography paragraph className={cn(classes.text, {
					[classes.error]: !isSuccess,
				})}>{text}</Typography>
				<CustomButton onClick={onClose} color="primary">DONE</CustomButton>
			</>
		</ModalWrapper>,
		document.getElementById('modal-root') as Element
	);
};

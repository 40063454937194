import { makeStyles } from '@material-ui/core';
import { STYLES } from 'utils';

export const useStyles = makeStyles({
	termsText: {
		verticalAlign: 'middle',
	},
	termsBtn: {
		width: 'auto',
		textTransform: 'capitalize',
	},
	fullWidth: {
		width: '100%',
	},
	radioGroup: {
		flexDirection: 'row',
		'& label + label': {
			marginLeft: '1rem',
		},
	},
	formWrapper: {
		maxWidth: 600,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	overflow: STYLES.overflow,
});

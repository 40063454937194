import { CheckboxProps, FormControlLabel } from '@material-ui/core';
import { ErrorMessage, Field, FieldProps } from 'formik';
import React, { useState } from 'react';
import { CustomCheckbox } from 'components';
import cn from 'classnames';

interface CustomCheckboxFieldProps extends CheckboxProps {
	label?: string | React.ReactElement;
	labelClassname?: string;
}

export const CustomCheckboxField = ({
	label,
	labelClassname,
	name = '',
	...props
}: CustomCheckboxFieldProps) => {
	const [error, setError] = useState<string>();

	return (
		<>
			<FormControlLabel
				label={label}
				className={cn(labelClassname)}
				control={
					<Field name={name}>
						{({ field, meta }: FieldProps) => {
							if (!!meta.error && meta.touched) {
								setError(meta.error);
							} else {
								setError(undefined);
							}
							return (
								<>
									<CustomCheckbox
										{...field}
										{...props}
										name={name}
										inputProps={{
											// @ts-ignore
											'qa-id': `input-${name}`,
											'qa-type': 'checkbox',
										}}
										checked={!!meta.value}
									/>
								</>
							);
						}}
					</Field>
				}
			/>
			{error && <ErrorMessage name={name} component="p" className="error-text" />}
		</>
	);
};

import { makeStyles } from '@material-ui/core';
import { COLORS, STYLES, theme } from 'utils';

export const useStyles = makeStyles({
	loadData: {
		...STYLES.loadData,
	},
	note: {
		fontSize: '1rem',
	},
	confirmText: {
		color: COLORS.confirm,
	},
	link: {
		color: COLORS.primary,
    // @ts-ignore
		...theme.typography.subtitle2,
	},
});

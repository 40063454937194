import React from 'react';
import cn from 'classnames';
import { IIconProps } from './IIconProps';

export const UserIcon = ({ className, title = '', pointer }: IIconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="User-icon"
			viewBox="0 0 32 32"
			className={cn(className, {
				pointer: pointer,
			})}
			fill="#FFFFFF"
		>
			<title>{title}</title>
			<path d="M15.908 4.953c-4.005 0.052-7.232 3.311-7.232 7.324 0 0.022 0 0.043 0 0.065l-0-0.003c0.023 2.369 1.168 4.466 2.927 5.789l0.019 0.014c-3.351 1.66-5.614 5.057-5.614 8.982 0 0.025 0 0.050 0 0.074l-0-0.004c0.009 0.489 0.051 0.96 0.125 1.421l-0.008-0.057c0.22 0.178 0.476 0.373 0.738 0.56l0.049 0.033c-0.141-0.586-0.227-1.262-0.238-1.956l-0-0.007c-0-0.020-0-0.044-0-0.067 0-3.802 2.275-7.072 5.537-8.524l0.059-0.024c1.070 0.646 2.362 1.029 3.743 1.029 0.006 0 0.012 0 0.017-0h-0.001c1.366-0.009 2.642-0.391 3.732-1.048l-0.033 0.018c3.271 1.454 5.526 4.646 5.594 8.372l0 0.009 0.001 0.157c-0.003 0.718-0.086 1.414-0.241 2.084l0.012-0.063c0.315-0.212 0.569-0.395 0.817-0.585l-0.038 0.028c0.071-0.438 0.113-0.945 0.116-1.461v-0.003l-0.001-0.168c-0.067-3.855-2.307-7.171-5.545-8.782l-0.058-0.026c0.019-0.012 0.013-0.008 0.007-0.004l-0.025 0.016c1.799-1.348 2.952-3.473 2.955-5.867v-0.001l-0.001-0.123c-0.069-3.992-3.321-7.201-7.323-7.201-0.033 0-0.065 0-0.097 0.001l0.005-0zM15.916 5.619c0.025-0 0.054-0.001 0.084-0.001 3.638 0 6.594 2.918 6.657 6.54l0 0.006 0.001 0.112c0 3.667-2.965 6.642-6.629 6.658h-0.002c-0.008 0-0.018 0-0.028 0-3.658 0-6.626-2.949-6.658-6.599l-0-0.003c-0-0.017-0-0.036-0-0.056 0-3.648 2.933-6.61 6.57-6.658l0.004-0z" />
		</svg>
	);
};

export const FUEL_TYPES = [
    {
        name: 'Diesel',
        value: 'DIESEL',
    },
    {
        name: 'Petrol',
        value: 'PETROL',
    },
];


import { gql } from '@apollo/client';

export const getSupplyDrivers = gql`
	query getSupplyDrivers(
		$first: Int
		$after: String
		$before: String
		$search: String
		$transporterId: Int
    $status: LinkEnum
    $region: RegionEnum
    $active: ActiveEnum
	) {
		drivers(
			first: $first
			after: $after
			before: $before
			search: $search
			transporterId: $transporterId
      status: $status
      region: $region
      active: $active
      sort: CREATED_AT_DESC
		) {
			pageInfo {
				hasNextPage
				endCursor
				hasNextPage
			}
			edges {
				node {
					names
					idNumber
					names
					country
					active
					phone
					transporterId
					id
					active
				}
			}
		}
	}
`;

// eslint-disable-next-line no-restricted-imports
import mixpanel, { Dict, RequestOptions, Callback } from 'mixpanel-browser';
import { useMemo } from 'react';

const noopTrack: typeof mixpanel.track = () => {};
const noopTrackLink: typeof mixpanel.track_links = () => {};
const identify: typeof mixpanel.identify = () => {};

/**
 * Returns an object to allow us to expose more of Mixpanel's API in future.
 *
 * NOTE: Extra level of function indirection ensures `isLoaded` is evaluated not just the first time.
 */
export const useMixpanel = () => {
	return useMemo(
		() => ({
			...mixpanel,
			track: (
				eventName: string,
				properties?: Dict,
				optionsOrCallback?: RequestOptions | Callback,
				callback?: Callback
			) => {
				const track = mixpanel ? mixpanel.track.bind(mixpanel) : noopTrack;
				return track(eventName, properties, optionsOrCallback, callback);
			},
			trackLinks: (eventName: string, action: string) => {
				const trackLinks = mixpanel ? mixpanel.track_links.bind(mixpanel) : noopTrackLink;
				return trackLinks(eventName, action);
			},
			identify: (id: any) => {
				return mixpanel && mixpanel?.identify ? mixpanel?.identify(id) : identify;
			},
		}),
		[]
	);
};

export { mixpanel };

import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomButton, Loader, SuggestPriceForm } from 'components';
import { Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

import { useDidUpdateEffect, useResultContext } from 'hooks';
import { FormIdList, SuggestPriceFormData, SuggestPriceFormNames } from 'models';
import * as Yup from 'yup';
import { AssetList, useSubmitBidMutation } from 'generate/types';
import { TruckSubmitList, TruckSubmitListWA } from '../truck-submit-list';
import { mapToTruckList } from 'helpers';
import { AppPathList } from 'router';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMixpanel } from 'analytics';
import { useReadUserInfo } from 'hooks';
import {
  JobCategory,
  useGetWorkOrderQuery,
} from 'generate/types';

export const SuggestPrice = () => {
  const [bidPrice, setBidPrice] = useState<number>();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation('available');
  const { t: tM } = useTranslation('modals');
  const { t: tE } = useTranslation('errors');
  const { t: tB } = useTranslation('buttons');
  const { isWA } = useReadUserInfo();
  const mixpanel = useMixpanel();
  const location: any = useLocation();

  const { push } = useHistory();

  const { data: workOrder, loading: isWorkOrderLoading } = useGetWorkOrderQuery(
    {
      fetchPolicy: 'no-cache',
      variables: {
        jobCategory: JobCategory.New,
        lroId: id,
        weight: parseFloat(location.state?.weight),
      },
    }
  );

  const { openResult, isResultOpened, openErrorResult } = useResultContext();

  const order = useMemo(() => workOrder?.workOrder, [workOrder?.workOrder])

  const [submitBid, { error, loading: isSubmitting }] = useSubmitBidMutation({
    onCompleted: () => openResult({
      title: tM('job_submitted'),
      text: tM('job_submitted_text'),
    }),
    onError: openErrorResult,
  });

  const initialValues = useMemo(() => {
    if (order) {
      return {
        [SuggestPriceFormNames.rate]: order?.transporterRateType as string,
        [SuggestPriceFormNames.price]: order?.marketRate as number,
        [SuggestPriceFormNames.currency]: order?.currency as string,
      };
    }
    return {} as SuggestPriceFormData;
  }, [order]);

  const validationSchema = useMemo(() => {
    return Yup.object({
      [SuggestPriceFormNames.price]: Yup.number()
        .required()
        .positive(tE('negative_bid'))
        .max(order?.upperBidMargin as number, tE('too_high_bid')),
    });
  }, [tE, order]);

  const handleSubmitBid = useCallback(({ price }: SuggestPriceFormData) => setBidPrice(price), []);

  const handleSubmitTrucks = useCallback(
    (truckListData: AssetList[]) => {
      if (location.state?.weight) {
        truckListData = truckListData.map((item) => {
          item.weight = location.state?.weight;
          return item;
        });
      }

      const truckList = mapToTruckList(truckListData);
      submitBid({
        variables: {
          bidPrice: bidPrice || 0,
          lroId: id,
          assetList: truckList,
        },
      });
      mixpanel.track(`Suggest price changes`, {
        ...initialValues,
        trailerType: order?.trailerType || '',
        priceChanges: bidPrice,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bidPrice, submitBid, order]
  );

  useDidUpdateEffect(() => {
    if (!isResultOpened && !error) {
      push(AppPathList.availableNew);
    }
  }, [isResultOpened, push, error]);

  const isLoading = useMemo(
    () => isWorkOrderLoading || !order || isSubmitting,
    [isWorkOrderLoading, order, isSubmitting]
  );

  return (
    <Loader isLoading={isLoading}>
      {bidPrice ? (
        <>
          {isWA ? (
            <TruckSubmitListWA onSubmit={handleSubmitTrucks} isLoading={false} />
          ) : (
            // @ts-ignore
            <TruckSubmitList onSubmit={handleSubmitTrucks} isLoading={false} />
          )}
          <Box display="flex" alignItems="center" marginBottom='0.5rem' marginTop='0.5rem' flexDirection="column">
            <CustomButton type="submit" form={FormIdList.truckList}>
              {tB('submit_list')}
            </CustomButton>
          </Box>
        </>
      ) : (
        <Box textAlign="center" pb={3} className="overflow">
          <b>{t('have_better_price')}</b>
          <Typography className="text-small">{t('give_price')}</Typography>
          <Box mt={1}>
            <Alert severity="warning">{t('bid_disclaimer')}</Alert>
          </Box>
          <SuggestPriceForm
            onSubmit={handleSubmitBid}
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
          />
        </Box>
      )}
    </Loader>
  );
};

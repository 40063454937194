export const AssignToList = [
	{
		value: 'fred@lorisystems.com',
		text: 'Fred Opon',
	},
	{
		value: 'malach@lorisystems.com',
		text: 'Malach Atemba',
	},
	{
		value: 'edna.momanyi@lorisystems.com',
		text: 'Edna Ochoki',
	},
	{
		value: 'caroline@lorisystems.com',
		text: 'Carol Kyalo',
	},
	{
		value: 'nantaba.musisi@lorisystems.com',
		text: 'Janet Nantaba',
	},
	{
		value: 'manisuli@lorisystems.com',
		text: 'Manisuli Mugoya',
	},
	{
		value: 'elisa.ruyooka@lorisystems.com',
		text: 'Elisa Ruyooka',
	},
	{
		value: 'support@lorisystems.com',
		text: 'Customer Service',
	},
	{
		value: 'onboarding@lorisystems.com',
		text: 'Onboarding',
	},
];

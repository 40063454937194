import { JobCategory, useGetWorkOrderQuery } from 'generate/types';
import { useParams } from 'react-router-dom';

export function useGetOrderDetails() {
	const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useGetWorkOrderQuery({
		fetchPolicy: 'network-only',
    variables: {
      jobCategory: JobCategory.OnJourney,
      moveId: parseInt(id)
    },
		onError: (error) => {
			return error;
		},
  });

	return { order: data?.workOrder, isOrderLoading: loading, orderError: error };
}

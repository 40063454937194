export function mapToDetailsList<T>(
	data: T | undefined,
	list: Array<keyof T>,
	format?: Partial<Record<keyof T, (v: T[keyof T]) => string | undefined>>
) {
	return list.reduce((res, k) => {
		let v: unknown = data && data[k];

		if (format && format[k]) {
			// @ts-ignore
			v = format[k](v);
		}

		return {
			...res,
			[k]: v,
		};
	}, {});
}

export function getDataURL(file: File | Blob): Promise<string | undefined> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result?.toString());
		reader.onerror = (error) => reject(error);
	});
}

export default getDataURL;

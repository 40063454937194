import React, { useMemo } from 'react';
import { ContactForm as ContactFormTemp } from 'components';
import { ContactFormNames } from 'models';
import * as Yup from 'yup';
import { CreateContactMutationVariables } from 'generate/typesAuth';
import { Contact } from 'generate/types';
import { mapFieldsToInitialValues } from 'helpers';

interface ContactFormProps {
	onSubmit: (d: CreateContactMutationVariables) => void;
	data?: Partial<Contact> | null;
}

const fields = [
	ContactFormNames.operationsContactName,
	ContactFormNames.operationsTelNumber,
	ContactFormNames.mobileNumber,
	ContactFormNames.operationsEmail,
	ContactFormNames.adminContactName,
	ContactFormNames.adminTelNumber,
	ContactFormNames.adminEmail,
];

export const ContactForm = ({ onSubmit, data }: ContactFormProps) => {
	const initialValues = useMemo(() => mapFieldsToInitialValues(fields, data || {}), [data]);

	const validationSchema = useMemo(() => Yup.object({}), []);

	return (
		<ContactFormTemp
			initialValues={initialValues as CreateContactMutationVariables}
			onSubmit={onSubmit}
			validationSchema={validationSchema}
		/>
	);
};

import { FormIdList } from 'models';
import { TFunction } from 'i18next';

export function mapSignUpFormInfo(isSouthAfrica: boolean, t: TFunction) {
	return isSouthAfrica
		? [
				{
					title: t('cargo_moves'),
					sub_title: t('sign_up'),
					id: FormIdList.companyInfo,
				},
				{
					title: t('personal_information'),
					sub_title: t('personal_information'),
					id: FormIdList.personalInformation,
				},
				{
					title: t('company_details'),
					sub_title: t('company_details'),
					id: FormIdList.companyDetails,
				},
				{
					title: t('upload_documentation'),
					sub_title: t('upload_documentation_subtitle'),
					id: FormIdList.uploadDocumentation,
				},
				{
					title: t('contact_information'),
					sub_title: t('contact_information'),
					id: FormIdList.contactInformation,
				},
				{
					title: t('insurance_details'),
					sub_title: t('insurance_details'),
					id: FormIdList.insuranceDetails,
				},
				{
					title: t('bank_details'),
					sub_title: t('bank_details_subtitle'),
					id: FormIdList.bankDetails,
				},
		  ]
		: [
				{
					title: t('cargo_moves'),
					sub_title: t('sign_up'),
					id: FormIdList.companyInfo,
				},
				{
					title: t('upload_documentation'),
					sub_title: t('upload_documentation_subtitle'),
					id: FormIdList.uploadDocumentation,
				},
				{
					title: t('bank_details'),
					sub_title: t('bank_details_subtitle'),
					id: FormIdList.bankDetails,
				},
		  ];
}

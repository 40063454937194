import { useEffect, useMemo } from 'react';
import { useReadUserInfo } from './useReadUserInfo';
import {
	useGetSupplyTrucksLazyQuery,
	useGetSupplyTrailersLazyQuery,
	useGetSupplyDriversLazyQuery,
	Truck,
	TruckConnection,
	Trailer,
	TrailerConnection,
	DriverConnection,
	Driver,
  MatchEnum,
  ActiveEnum,
  LinkEnum,
} from 'generate/typesSupplyService';
import { getPaginatedDataNodeList } from '../helpers';
import { supplyServiceClient } from 'graphqlapi';

export function useGetAssets() {
	const { transporter: transname, transporter_id: transporterId } = useReadUserInfo();

	const [getSupplyTrucks, { data: truckList, loading: isTruckLoading }] =
		useGetSupplyTrucksLazyQuery({
			variables: {
				transporterId,
				first: 100,
        status: MatchEnum.Unmatched,
        active: ActiveEnum.Active,
			},
			fetchPolicy: 'network-only',
			client: supplyServiceClient,
		});
	const [getSupplyTrailers, { data: trailerList, loading: isTrailerLoading }] =
		useGetSupplyTrailersLazyQuery({
			variables: {
				transporterId,
				first: 100,
        status: LinkEnum.Unlinked,
        active: ActiveEnum.Active,
			},
			fetchPolicy: 'network-only',
			client: supplyServiceClient,
		});

	const [getSupplyDrivers, { data: driverList, loading: isDriverLoading }] =
		useGetSupplyDriversLazyQuery({
			variables: {
				transporterId,
				first: 100,
        status: LinkEnum.Unlinked,
        active: ActiveEnum.Active,
			},
			fetchPolicy: 'network-only',
			client: supplyServiceClient,
		});

	const isAssetsLoading = useMemo(
		() => isTruckLoading || isTrailerLoading || isDriverLoading,
		[isTruckLoading, isTrailerLoading, isDriverLoading]
	);

	useEffect(() => {
		if (transname) {
			getSupplyTrucks();
			getSupplyTrailers();
			getSupplyDrivers();
		}
	}, [transname, getSupplyTrailers, getSupplyTrucks, getSupplyDrivers]);

	return {
		// @ts-ignore
		truckList: getPaginatedDataNodeList<Truck, TruckConnection>(truckList?.trucks),
		// @ts-ignore
		trailerList: getPaginatedDataNodeList<Trailer, TrailerConnection>(trailerList?.trailers),
		// @ts-ignore
		driverList: getPaginatedDataNodeList<Driver, DriverConnection>(driverList?.drivers),
		isAssetsLoading,
	};
}

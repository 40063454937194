import {
	AvailableJobsRoute,
	LoginRoute,
	JobsRoute,
	PaymentRoute,
	AccountRoute,
	AdminRoute,
	AuxiliaryServicesRoute,
	NotificationsRoute,
} from './routes';
import { AppRoutePathList } from './AppRoutePathList';
import { IAppPathList } from 'models';
// with import form 'helpers' the PrivateRoute.test.tsx breaks
import { createAppRouteList } from '../helpers/router/createAppRouteList';

export const appRoutes = [
	LoginRoute,
	AvailableJobsRoute,
	JobsRoute,
	PaymentRoute,
	AccountRoute,
	AdminRoute,
	AuxiliaryServicesRoute,
	NotificationsRoute,
];

export const AppPathList = appRoutes
	.map((route) => createAppRouteList(route, AppRoutePathList))
	.flat()
	.reduce(
		(res: Partial<IAppPathList>, appRoute) => ({
			...res,
			[appRoute.name]: appRoute.path,
		}),
		{}
	) as IAppPathList;

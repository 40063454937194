import { makeStyles } from '@material-ui/core';
import { COLORS, STYLES, theme } from 'utils';

export const useStyles = makeStyles({
	loadData: {
		...STYLES.loadData,
	},
	note: {
		fontSize: '1rem',
	},
	confirmText: {
		color: COLORS.primary,
		fontSize: 14,
		fontWeight: 600,
		lineHeight: '16px'
	},
	title: {
		color: COLORS.primary,
		fontSize: 12,
		fontWeight: 300,
		lineHeight: '14px',
		marginBottom: 8
	},
	trucksMatched: {
		color: COLORS.primary,
		fontSize: 12,
		fontWeight: 700,
		lineHeight: '14px',
		marginBottom: 8
	},
	link: {
		color: COLORS.primary,
    // @ts-ignore
		...theme.typography.subtitle2,
	},
	btn: {
		height: '40px',
		textTransform: 'capitalize',
		fontSize: 14,
		fontWeight: 500
	},
	cancelBtn: {
		marginTop: 14,
		borderColor: COLORS.red,
		color: COLORS.red
	},
	cancelJob: {
		color: COLORS.red,
		fontSize: 16,
		lineHeight: '18px',
		fontWeight: 600,
		textAlign: "center"
	},
	modalDismissButton: {
		backgroundColor:  COLORS.red,
	},
	cancelJobDialog: {
		backgroundColor:  'rgba(0, 0, 0, 0.4)',
		backdropFilter: 'blur(1px)'
	},
});

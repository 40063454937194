import { Order } from "generate/types";

export function mapToDriverMessageList(
  moveData: Partial<Order>,
  driverMessageKeys: Array<keyof Order>
) {
  return driverMessageKeys.reduce(
    (res, k) => ({
      ...res,
      [k]: moveData[k],
    }),
    {}
  );
}
//k === 'etaLoadingPoint'
//	? formatToDate(moveData[k] + 'Z', DATES.loadDateFormat)
//	: moveData[k],
//        import { formatToDate } from '../format';
//import { DATES } from 'utils';

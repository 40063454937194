import { gql } from '@apollo/client';

export const truckDetails = gql`
	fragment truckDetails on Truck {
		truckReg
		transporter
		active
		assetId
	}
`;

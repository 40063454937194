import React, { PropsWithChildren, useMemo } from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import { getPaginatedDataNodeList, getRecordList } from 'helpers';
import { IRecordItem, RecordItem, ItemList } from 'components';
import {
	GetSupplyDriversQuery,
	GetSupplyTrucksQuery,
	useGetSupplyDriversQuery,
	useGetSupplyTrucksQuery,
	GetSupplyTrailersQuery,
	useGetSupplyTrailersQuery,
} from 'generate/typesSupplyService';
import IconButton from "@material-ui/core/IconButton";
import {useStyles} from "./AssetQueryContainer.styles";

type TrucksProps = {
	useQuery: typeof useGetSupplyTrucksQuery;
	dataKey: keyof GetSupplyTrucksQuery;
	recordKeyList: string[];
};

type DriversProps = {
	useQuery: typeof useGetSupplyDriversQuery;
	dataKey: keyof GetSupplyDriversQuery;
	recordKeyList: string[];
};

type TrailersProps = {
	useQuery: typeof useGetSupplyTrailersQuery;
	dataKey: keyof GetSupplyTrailersQuery;
	recordKeyList: string[];
};

type AssetQueryContainerProps =
	| TrucksProps
	| DriversProps
	| TrailersProps;

export function AssetQueryContainer<
	T extends PropsWithChildren<
		AssetQueryContainerProps & {
			keyName: string;
			ItemTemplate?: React.ElementType;
			options: any;
		}
	>
>({ useQuery, dataKey, recordKeyList, children, keyName, ItemTemplate, options }: T) {
	const { data, refetch, loading } = useQuery(options);
	const classes = useStyles();
	const infoList = useMemo(() => {
		const dataNode = data && data[dataKey as keyof typeof data];
		if (dataNode) {
			// @ts-ignore
			const formattedData = getPaginatedDataNodeList(dataNode);
			// @ts-ignore
			return ItemTemplate
				? formattedData
				: // @ts-ignore
				  getRecordList(formattedData, recordKeyList);
		}
		return [];
	}, [data, dataKey, recordKeyList, ItemTemplate]);

	const DefaultItemTemplate = useMemo(
		() => (props: IRecordItem) => <RecordItem withTitle {...props} />,
		[]
	);
	const handleRefetch = React.useCallback(() => {
		refetch()
	},[refetch])

	return (
		<>
			<IconButton className={classes.refreshIconButton} onClick={handleRefetch}>
				<RefreshIcon/>
			</IconButton>
			<ItemList
				// @ts-ignore
				items={infoList}
				ItemTemplate={ItemTemplate || DefaultItemTemplate}
				isLoading={loading}
				keyName={keyName}
			/>
			{children}
		</>
	);
}

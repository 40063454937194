import { gql } from '@apollo/client';

export const createCompany = gql`
	mutation createCompany(
		$companyName: String!
		$companyEmail: String!
		$companyMobile: String!
		$fleet: Fleet
		$countryCode: String!
	) {
		createCompany(companyData: {
        companyName: $companyName
        companyEmail: $companyEmail
        companyMobile: $companyMobile
        fleet: $fleet
        countryCode: $countryCode
      }
		) {
      company {
        id
        companyName
        region
        transporterId
      },
			ok
			message
		}
	}
`;
